import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStopwatch as IntervalIcon } from '@fortawesome/free-solid-svg-icons';

import { formmaterSecondToHumanizeTime } from '~utils/formatter';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { WorkIntervalInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const WorkIntervalInfo = ({
  interval,
  fontSize = 14,
  fontWeight,
  withTooltip = false,
  useCustomIcon = false,
}: WorkIntervalInfoProps): ReactElement => {
  const Icon = useMemo(
    () =>
      useCustomIcon ? (
        <img src={toAbsoluteUrl('/icons/icon_clock.svg')} alt="Time Icon" />
      ) : (
        <FontAwesomeIcon icon={IntervalIcon} />
      ),
    [useCustomIcon],
  );

  const text = useMemo(
    () => formmaterSecondToHumanizeTime(interval ?? 0),
    [interval],
  );

  const tooltipMessage = useMemo(
    () => (withTooltip ? 'Tiempo de viaje estimado' : undefined),
    [withTooltip],
  );

  return (
    <DescriptionWithIconBox
      icon={Icon}
      description={text}
      size={fontSize}
      iconTooltip={tooltipMessage}
      fontWeight={fontWeight}
    />
  );
};

export default memo(WorkIntervalInfo);

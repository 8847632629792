import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const HelpIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M14,.77A13.31,13.31,0,1,1,.6,14.06,13.33,13.33,0,0,1,14,.77m0,2.05A11.26,11.26,0,1,0,25.17,14.16,11.27,11.27,0,0,0,13.94,2.82" />
    {/* eslint-disable-next-line max-len */}
    <path d="M13.87,7.45a3.84,3.84,0,0,1,3.51,2.31,3.2,3.2,0,0,1-.55,3.58,15.75,15.75,0,0,1-1.35,1.36,2.38,2.38,0,0,0-.91,1.89,1,1,0,0,1-1,1,1,1,0,0,1-1-1.07,4,4,0,0,1,1.37-3.15l1.07-1a1.52,1.52,0,0,0,.58-1.67A1.79,1.79,0,0,0,14,9.53,2,2,0,0,0,11.59,11a1,1,0,1,1-2-.59,4,4,0,0,1,4.24-3" />
    <path d="M13.63,20.75a1,1,0,0,1-1-1,1,1,0,0,1,2,0,1,1,0,0,1-1,1" />
  </SvgIcon>
);

export default HelpIcon;

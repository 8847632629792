import { useRef, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { replace } from 'lodash';

import { Nullable } from '~globals/types';
import { useLazyRequest } from '~hooks/index';
import { getEvidencesItemPDF } from '~services/item';
import { downloadFileByRequest } from '~utils/download';

interface DownloadEvidencesItemPDFReturn {
  loading: boolean;
  onDownloadPDF: (trackingCode: string) => Promise<void>;
}

export const useDownloadEvidencesItemPDF =
  (): DownloadEvidencesItemPDFReturn => {
    const currentTrackingCode = useRef<Nullable<string>>(null);
    const { enqueueSnackbar } = useSnackbar();

    const [, loadingDownload, , executeDownload] = useLazyRequest({
      request: getEvidencesItemPDF,
      withPostSuccess: (response) => {
        if (response.headers && response.data) {
          const parseRouteCode = currentTrackingCode.current
            ? replace(currentTrackingCode.current, /-/g, '')
            : '';

          const defaultFileName = `comprobante-visita-${parseRouteCode}.pdf`;

          downloadFileByRequest(
            response.headers,
            response.data,
            defaultFileName,
          );

          enqueueSnackbar(
            `Se ha descargado el comprobante de visita correctamente`,
            { variant: 'success' },
          );
        } else {
          enqueueSnackbar(
            'No se pudo descargar el comprobante de visita, intente nuevamente',
            { variant: 'error' },
          );
        }
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'No se pudo descargar el comprobante de visita, intente nuevamente',
          { variant: 'error' },
        );
      },
    });

    const onDownloadPDF = useCallback(
      async (trackingCode: string) => {
        currentTrackingCode.current = trackingCode;
        await executeDownload({ trackingCode, includeMap: true });
        currentTrackingCode.current = trackingCode;
      },
      [executeDownload],
    );

    return { loading: loadingDownload, onDownloadPDF };
  };

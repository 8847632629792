import {
  BusinessProfileManagementOperationTypes,
  BusinessProfileOrderManagementCollectTypes,
  BusinessProfileOrderCollectedTransferTypes,
  BusinessProfileOrderCentralizationTransferTypes,
  BusinessProfileOrderCentralizationnShipmentTypes,
  BusinessProfileOrderDistributionShipmentTypes,
  BusinessProfileTypes,
  PartnerCompanyTypes,
} from '~globals/types/enums';
import { BUSINESS_PROFILES } from '~constants/businessProfiles';

const isEqualValue = (currentValue: string, comparateValue: string) =>
  currentValue === comparateValue;

const comparateOrderDistributionShipment = (
  currentValue: string[],
  comparateValue: string[],
): boolean => {
  if (currentValue.length !== comparateValue.length) return false;

  const currentValueSorted = currentValue.slice().sort();
  const comparateValueSorted = comparateValue.slice().sort();

  for (let i = 0; i < currentValueSorted.length; i++) {
    if (currentValueSorted[i] !== comparateValueSorted[i]) return false;
  }

  return true;
};

export const getBusinessProfileId = ({
  operationType,
  orderManagementCollect,
  orderCollectedTransfer,
  orderCentralizationTransfer,
  orderCentralizationShipment,
  orderDistributionShipment,
}: {
  operationType: string;
  orderManagementCollect: string;
  orderCollectedTransfer: string;
  orderCentralizationTransfer: string;
  orderCentralizationShipment: string;
  orderDistributionShipment: string[];
}): BusinessProfileTypes | undefined => {
  // Case: 1
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.OnlySell,
    ) ||
    (isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.SellAndManagement,
    ) &&
      comparateOrderDistributionShipment(orderDistributionShipment, [
        BusinessProfileOrderDistributionShipmentTypes.MyStore,
      ]))
  ) {
    return BusinessProfileTypes.SalesOnly;
  }

  // Case: 4
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.SellAndManagement,
    ) &&
    comparateOrderDistributionShipment(orderDistributionShipment, [
      BusinessProfileOrderDistributionShipmentTypes.Buyers,
    ])
  ) {
    return BusinessProfileTypes.SalesAndDirectDistribution;
  }

  // Case: 3
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.SellAndManagement,
    ) &&
    comparateOrderDistributionShipment(orderDistributionShipment, [
      BusinessProfileOrderDistributionShipmentTypes.ThirdParty,
    ])
  ) {
    return BusinessProfileTypes.SalesAndIndirectDistribution;
  }

  // Case: 4
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.SellAndManagement,
    ) &&
    comparateOrderDistributionShipment(orderDistributionShipment, [
      BusinessProfileOrderDistributionShipmentTypes.Buyers,
      BusinessProfileOrderDistributionShipmentTypes.ThirdParty,
    ])
  ) {
    return BusinessProfileTypes.SalesAndDistributionBothDirectAndIndirect;
  }

  // Case: 5
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.SellAndManagement,
    ) &&
    comparateOrderDistributionShipment(orderDistributionShipment, [
      BusinessProfileOrderDistributionShipmentTypes.Buyers,
      BusinessProfileOrderDistributionShipmentTypes.MyStore,
    ])
  ) {
    return BusinessProfileTypes.SalesDirectDistributionAndCollection;
  }

  // Case: 6
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.SellAndManagement,
    ) &&
    comparateOrderDistributionShipment(orderDistributionShipment, [
      BusinessProfileOrderDistributionShipmentTypes.ThirdParty,
      BusinessProfileOrderDistributionShipmentTypes.MyStore,
    ])
  ) {
    return BusinessProfileTypes.SalesIndirectDistributionAndCollection;
  }

  // Case: 7
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.SellAndManagement,
    ) &&
    comparateOrderDistributionShipment(orderDistributionShipment, [
      BusinessProfileOrderDistributionShipmentTypes.Buyers,
      BusinessProfileOrderDistributionShipmentTypes.ThirdParty,
      BusinessProfileOrderDistributionShipmentTypes.MyStore,
    ])
  ) {
    return BusinessProfileTypes.SalesDirectAndIndirectDistributionAndCollection;
  }

  // Case: 8
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.NotSell,
    ) &&
    isEqualValue(
      orderManagementCollect,
      BusinessProfileOrderManagementCollectTypes.Collect,
    ) &&
    isEqualValue(
      orderCollectedTransfer,
      BusinessProfileOrderCollectedTransferTypes.ToBuyers,
    )
  ) {
    return BusinessProfileTypes.CollectionAndDirectDistribution;
  }

  // Case: 9
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.NotSell,
    ) &&
    isEqualValue(
      orderManagementCollect,
      BusinessProfileOrderManagementCollectTypes.Collect,
    ) &&
    (isEqualValue(
      orderCollectedTransfer,
      BusinessProfileOrderCollectedTransferTypes.ToWarehouse,
    ) ||
      isEqualValue(
        orderCollectedTransfer,
        BusinessProfileOrderCollectedTransferTypes.Both,
      )) &&
    isEqualValue(
      orderCentralizationTransfer,
      BusinessProfileOrderCentralizationTransferTypes.OnlyBuyers,
    )
  ) {
    return BusinessProfileTypes.CollectionAndIndirectDistribution;
  }

  // Case: 10
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.NotSell,
    ) &&
    isEqualValue(
      orderManagementCollect,
      BusinessProfileOrderManagementCollectTypes.Collect,
    ) &&
    isEqualValue(
      orderCollectedTransfer,
      BusinessProfileOrderCollectedTransferTypes.ToWarehouse,
    ) &&
    isEqualValue(
      orderCentralizationTransfer,
      BusinessProfileOrderCentralizationTransferTypes.BuyersAndCarries,
    )
  ) {
    return BusinessProfileTypes.CollectionIndirectDistributionAndOutsourcingOfSomeOrders;
  }

  // Case: 11
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.NotSell,
    ) &&
    isEqualValue(
      orderManagementCollect,
      BusinessProfileOrderManagementCollectTypes.Collect,
    ) &&
    isEqualValue(
      orderCollectedTransfer,
      BusinessProfileOrderCollectedTransferTypes.Both,
    ) &&
    isEqualValue(
      orderCentralizationTransfer,
      BusinessProfileOrderCentralizationTransferTypes.BuyersAndCarries,
    )
  ) {
    return BusinessProfileTypes.CollectionDirectAndIndirectDistributionAndOutsourcingOfSomeOrders;
  }

  // Case: 12
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.NotSell,
    ) &&
    isEqualValue(
      orderManagementCollect,
      BusinessProfileOrderManagementCollectTypes.NotCollect,
    ) &&
    isEqualValue(
      orderCentralizationShipment,
      BusinessProfileOrderCentralizationnShipmentTypes.Buyers,
    )
  ) {
    return BusinessProfileTypes.DirectDistributionWithoutCollection;
  }

  // Case: 13
  if (
    isEqualValue(
      operationType,
      BusinessProfileManagementOperationTypes.NotSell,
    ) &&
    isEqualValue(
      orderManagementCollect,
      BusinessProfileOrderManagementCollectTypes.NotCollect,
    ) &&
    isEqualValue(
      orderCentralizationShipment,
      BusinessProfileOrderCentralizationnShipmentTypes.BuyersAndCarries,
    )
  ) {
    return BusinessProfileTypes.DirectDistributionWithoutCollectionAndOutsourcingOfSomeOrders;
  }

  return undefined;
};

export const getBusinessProfileInfo = (
  profileId: BusinessProfileTypes,
): { name: string; description: string } => BUSINESS_PROFILES[profileId];

export const getCompanyTypeIdByBusinessProfileId = (
  profileId: BusinessProfileTypes,
): PartnerCompanyTypes => {
  if (
    [
      BusinessProfileTypes.SalesOnly,
      BusinessProfileTypes.SalesAndIndirectDistribution,
      BusinessProfileTypes.SalesIndirectDistributionAndCollection,
    ].includes(profileId)
  ) {
    return PartnerCompanyTypes.Giver;
  }

  return PartnerCompanyTypes.Carrier;
};

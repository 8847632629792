import { ReactElement, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSubscription, useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { getPlanGeneralInfo } from '~utils/plans';
import { isAdminUser } from '~utils/user';
import { getStringifyUrlQueryString } from '~utils/router';
import { PATHS } from '~constants/paths';

import { SubscriptionPlanInfoChipProps } from './types';
import { SubscriptionPlanInfoChipRoot } from './styles';

const defaultColor = '#00b0ff';

const SubscriptionPlanInfoChip = ({
  size = 'small',
}: SubscriptionPlanInfoChipProps): ReactElement => {
  const subscription = useSubscription();
  const { user } = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const currentPlanName = useMemo(
    () => subscription?.badgeText,
    [subscription],
  );

  const customColor = useMemo(() => {
    const currentPlanInfo = getPlanGeneralInfo(currentPlanName);

    return currentPlanInfo?.highlightColor ?? defaultColor;
  }, [currentPlanName]);

  const handleClick = useMemo(() => {
    if (!isAdminUser(user)) return undefined;

    return () => {
      const url = getStringifyUrlQueryString({
        url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
        query: {
          currentTab: 'subscriptions',
        },
      });

      navigate(url);
    };
  }, [navigate, user]);

  return (
    <SubscriptionPlanInfoChipRoot
      label={currentPlanName}
      size={size}
      variant="filled"
      customColor={customColor}
      onClick={handleClick}
    />
  );
};

export default SubscriptionPlanInfoChip;

import { styled, Badge, badgeClasses } from '@mui/material';

import { SubscriptionAvailableRoutesStateBadgeProps } from './types';
import { FONT_SIZES } from '../utils';

export const SubscriptionAvailableRoutesStateBadgeRoot = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'size',
})<Pick<SubscriptionAvailableRoutesStateBadgeProps, 'size'>>(
  ({ theme, size }) => {
    const fontSize = FONT_SIZES[size ?? 'small'];

    return {
      [`& .${badgeClasses.badge}`]: {
        visibility: 'visible',

        ...(typeof fontSize === 'number' && {
          fontSize: theme.typography.pxToRem(fontSize),
          transform: `scale(1) translate(${fontSize + 2}px, -50%)`,
        }),
      },
    };
  },
);

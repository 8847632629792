import { styled, Button, buttonClasses } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { darken } from '@mui/material/styles';

import { SubscriptionPaymentProviders } from '~globals/types/commons';

export const SubscriptionPaymentModalFormPanel = styled(TabPanel)({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  flex: 1,
  overflowY: 'auto',
});

export const SubscriptionPaymentModalFormContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-between',
    flex: 1,
  }),
);

export const SubscriptionPaymentModalFormProviderButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'provider',
})<{ provider: SubscriptionPaymentProviders }>(({ theme, provider }) => {
  const backgroundColor = provider === 'mercadopago' ? '#009ee3' : '#0070ba';
  const imgScale = provider === 'mercadopago' ? 1.6 : 1.1;

  return {
    borderRadius: Number(theme.shape.borderRadius) / 2,
    padding: theme.spacing(1.75),
    minWidth: 280,
    lineHeight: 1.25,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    backgroundColor,
    margin: theme.spacing(2, 0),

    '&:hover': {
      backgroundColor: darken(backgroundColor, 0.2),
    },

    '& img': {
      height: 20,
      transform: `scale(${imgScale})`,
    },

    [`&.${buttonClasses.disabled}`]: {
      color: theme.palette.common.white,
      backgroundColor,
      opacity: 0.38,
    },
  };
});

import { ReactElement, useMemo, cloneElement } from 'react';
import { PaginationOptions } from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationCtxOptions,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';

import {
  DataTableContainer,
  PaginationContainer,
  PaginationSizesContainer,
  PaginationPagesContainer,
} from './styles';
import PaginationSizePerPageList from './PaginationSizePerPageList';
import PaginationTotalResults from './PaginationTotalResults';
import PaginationButtonPage, {
  PaginationButtonPageProps,
} from './PaginationButtonPage';

interface PaginationProps {
  pagination?: PaginationCtxOptions;
  children: ReactElement;
}

const defaultSizePerPageList: PaginationOptions['sizePerPageList'] = [
  { text: '25', value: 25 },
  { text: '50', value: 50 },
  { text: '100', value: 100 },
];

const defaultPaginationOptions: PaginationCtxOptions = {
  options: {
    custom: true,
    page: 1,
    sizePerPage: 25,
    totalSize: 0,
    pageStartIndex: 1,
    paginationSize: 4,
    showTotal: true,
    sizePerPageList: defaultSizePerPageList,
    withFirstAndLast: true,
    firstPageText: 'Primera',
    prePageText: 'Anterior',
    nextPageText: 'Siguiente',
    lastPageText: 'Última',
    nextPageTitle: 'Siguiente página',
    prePageTitle: 'Página anterior',
    firstPageTitle: 'Primera página',
    lastPageTitle: 'Última página',
    sizePerPageRenderer: (options) => (
      <PaginationSizePerPageList {...options} />
    ),
    paginationTotalRenderer: (from, to, size) => (
      <PaginationTotalResults from={from} to={to} size={size} />
    ),
    pageButtonRenderer: ({ onPageChange, ...restOptions }) => (
      <PaginationButtonPage
        onPageChange={onPageChange as PaginationButtonPageProps['onPageChange']}
        {...restOptions}
        key={restOptions.page}
      />
    ),
  },
};

const Pagination = ({
  pagination: paginationProps,
  children,
}: PaginationProps): ReactElement => {
  const pagination = useMemo<PaginationCtxOptions>(() => {
    const options: PaginationOptions = {
      ...defaultPaginationOptions.options,
      ...paginationProps?.options,
    };

    return paginationFactory(options);
  }, [paginationProps]);

  if (!paginationProps) {
    return (
      <DataTableContainer withPagination className="data-table-container">
        {children}
      </DataTableContainer>
    );
  }

  return (
    <PaginationProvider pagination={pagination}>
      {({ paginationProps: paginationChildProps, paginationTableProps }) => (
        <DataTableContainer withPagination className="data-table-container">
          {cloneElement(children, paginationTableProps)}

          <PaginationContainer>
            <PaginationSizesContainer>
              <SizePerPageDropdownStandalone {...paginationChildProps} />

              {paginationChildProps.showTotal && (
                <PaginationTotalStandalone {...paginationChildProps} />
              )}
            </PaginationSizesContainer>

            <PaginationPagesContainer>
              <PaginationListStandalone {...paginationChildProps} />
            </PaginationPagesContainer>
          </PaginationContainer>
        </DataTableContainer>
      )}
    </PaginationProvider>
  );
};

export default Pagination;

import { styled, Card, Chip, Typography, Button } from '@mui/material';
import {
  StarRounded as StartIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import { darken } from '@mui/material/styles';

import { SubscriptionPlanPriceCardDirection } from './types';

const gridTemplateAreas = {
  header: 'Subscription-plan-price-card-header-area',
  content: 'Subscription-plan-price-card-content-area',
  actions: 'Subscription-plan-price-card-actions-area',
};

export const SubscriptionPlanPriceCardRoot = styled(Card, {
  shouldForwardProp: (prop) =>
    prop !== 'direction' &&
    prop !== 'isRecommended' &&
    prop !== 'highlightColor' &&
    prop !== 'withShadow',
})<{
  direction: SubscriptionPlanPriceCardDirection;
  isRecommended: boolean;
  highlightColor: string;
  withShadow: boolean;
}>(({ theme, direction, isRecommended, highlightColor, withShadow }) => ({
  position: 'relative',
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateAreas: `
    "${gridTemplateAreas.header}"
    "${gridTemplateAreas.content}"
    "${gridTemplateAreas.actions}"
  `,
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'visible',
  marginTop: 0,
  width: '100%',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: '1fr',

  ...(direction === 'horizontal' && {
    marginTop: theme.spacing(2),
    gridTemplateAreas: `
      "${gridTemplateAreas.header} ${gridTemplateAreas.content}"
      "${gridTemplateAreas.actions} ${gridTemplateAreas.content}"
    `,
    gridTemplateRows: '1fr auto',
    gridTemplateColumns: 'auto 1fr',
  }),

  ...(isRecommended && {
    borderColor: highlightColor,

    ...(withShadow && {
      boxShadow: theme.shadows[4],
    }),
  }),
}));

export const SubscriptionPlanPriceCardHeader = styled('div')(({ theme }) => ({
  gridArea: gridTemplateAreas.header,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  paddingBottom: 0,
}));

export const SubscriptionPlanPriceCardRatingIcon = styled(StartIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
  }),
);

export const SubscriptionPlanPriceCardCheckIcon = styled(CheckIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(17),
    marginTop: 2,
  }),
);

export const SubscriptionPlanPriceCardRecommendedChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'customColor' && prop !== 'isBadge',
})<{ customColor: string; isBadge: boolean }>(
  ({ theme, customColor, isBadge }) => ({
    fontSize: theme.typography.pxToRem(9),
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 20,
    backgroundColor: customColor,
    color: theme.palette.getContrastText(customColor),

    ...(isBadge && {
      position: 'absolute',
      top: theme.spacing(-1.5),
      right: theme.spacing(2),
    }),
  }),
);

export const SubscriptionPlanPriceCardContent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'direction',
})<{ direction: SubscriptionPlanPriceCardDirection }>(
  ({ theme, direction }) => ({
    gridArea: gridTemplateAreas.content,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    minWidth: 0,

    ...(direction === 'horizontal' && {
      justifyContent: 'center',
      minWidth: 404,
    }),
  }),
);

export const SubscriptionPlanPriceCardFeatureTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isLarge',
})<{ isLarge: boolean }>(({ theme, isLarge }) => ({
  ...theme.typography.caption,

  ...(isLarge && {
    ...theme.typography.body1,
  }),
}));

export const SubscriptionPlanPriceCardActions = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hidden',
})<{ hidden: boolean }>(({ theme, hidden }) => ({
  gridArea: gridTemplateAreas.actions,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  paddingTop: 0,
  visibility: 'visible',

  ...(hidden && {
    visibility: 'hidden',
  }),
}));

export const SubscriptionPlanPriceCardActionsButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{ customColor: string }>(({ customColor }) => ({
  backgroundColor: customColor,

  '&:hover': {
    backgroundColor: darken(customColor, 0.2),
  },
}));

import { styled, Card, CardContent, Chip } from '@mui/material';
import { grey, red } from '@mui/material/colors';

export const SubscriptionPlanCardRoot = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
}));

export const SubscriptionPlanCardHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  paddingBottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

export const SubscriptionPlanCardHeaderStatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isExpired' && prop !== 'hasRenewal',
})<{ isExpired: boolean; hasRenewal?: boolean }>(
  ({ theme, isExpired, hasRenewal }) => ({
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    minWidth: 100,
    backgroundColor: '#C0FFE2',
    color: '#009245',
    borderColor: '#09D177',

    ...(isExpired && {
      backgroundColor: grey[200],
      color: grey[700],
      borderColor: grey[500],
    }),

    ...(hasRenewal && {
      backgroundColor: red[200],
      color: red[700],
      borderColor: red[500],
    }),
  }),
);

export const SubscriptionPlanCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'grid',
  gridTemplateColumns: '1fr max-content',
  gap: theme.spacing(2),
  alignItems: 'center',

  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
}));

export const SubscriptionPlanCardRowInfoRoot = styled('div')(({ theme }) => ({
  ...theme.typography.caption,
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',

  '& svg': {
    width: '1em',
    height: '1em',
  },
}));

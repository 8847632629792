import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  INVITATION_CODE: 'invitationCode',
} as const;

export const initialValues = {
  [FIELDS_NAME.INVITATION_CODE]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.INVITATION_CODE]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
});

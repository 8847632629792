import { ReactElement } from 'react';
import { Typography, Stack, Link, useMediaQuery, Theme } from '@mui/material';
import { isMobile as isMobileBrowser } from 'react-device-detect';

import { useToggle } from '~hooks/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { APP_STORES_INFO } from '~constants/appStoresInfo';

import {
  DialogMobileNotPerformanceRoot,
  DialogMobileNotPerformanceContent,
  DialogMobileNotPerformanceMedia,
  DialogMobileNotPerformanceActionButton,
  DialogMobileNotPerformanceStoreImg,
} from './styles';

const DialogMobileNotPerformance = (): ReactElement => {
  const isMobileMediaQuery = useMediaQuery<Theme>(({ breakpoints }) =>
    breakpoints.down('md'),
  );

  const [open, toggleOpen] = useToggle(isMobileBrowser || isMobileMediaQuery);

  return (
    <DialogMobileNotPerformanceRoot
      open={open}
      aria-labelledby="dialog-mobile-not-performance-title"
      aria-describedby="dialog-mobile-not-performance-description"
      fullWidth
    >
      <DialogMobileNotPerformanceContent>
        <DialogMobileNotPerformanceMedia
          src={toAbsoluteUrl('/images/image_nomobile.png')}
          alt="Mobile not performance"
        />

        <Typography
          component="h2"
          variant="h6"
          fontWeight="bold"
          textAlign="center"
          id="dialog-mobile-not-performance-title"
        >
          Experiencia optimizada para pantalla ancha
        </Typography>

        <Typography
          component="p"
          variant="body2"
          color="text.secondary"
          textAlign="center"
          id="dialog-mobile-not-performance-description"
        >
          La plataforma se encuentra optimizada para ser visualizada a una
          resolución mayor. Te recomendamos acceder directamente desde la
          pantalla de su PC.
        </Typography>

        <Stack spacing={0.5}>
          <DialogMobileNotPerformanceActionButton
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            href="https://routix.io/"
          >
            Salir
          </DialogMobileNotPerformanceActionButton>

          <DialogMobileNotPerformanceActionButton
            variant="text"
            color="primary"
            size="small"
            fullWidth
            onClick={() => toggleOpen(false)}
          >
            Deseo continuar desde este dispositivo
          </DialogMobileNotPerformanceActionButton>
        </Stack>

        <Stack spacing={0.5} alignItems="center">
          <Stack>
            <Typography
              component="p"
              variant="caption"
              color="text.secondary"
              textAlign="center"
            >
              ¿Sos Chofer?
            </Typography>

            <Typography
              component="p"
              variant="caption"
              color="text.secondary"
              textAlign="center"
            >
              Descarga la app para choferes
            </Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Link
              href={APP_STORES_INFO.PLAY_STORE.URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DialogMobileNotPerformanceStoreImg
                src={APP_STORES_INFO.PLAY_STORE.IMAGE_URL}
                alt={APP_STORES_INFO.PLAY_STORE.NAME}
              />
            </Link>

            <Link
              href={APP_STORES_INFO.APP_STORE.URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DialogMobileNotPerformanceStoreImg
                src={APP_STORES_INFO.APP_STORE.IMAGE_URL}
                alt={APP_STORES_INFO.APP_STORE.NAME}
              />
            </Link>
          </Stack>
        </Stack>
      </DialogMobileNotPerformanceContent>
    </DialogMobileNotPerformanceRoot>
  );
};

export default DialogMobileNotPerformance;

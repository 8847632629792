import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { parseQueryString } from '~utils/router';

export const useParseLocationQuery = <T>(): T => {
  const { search } = useLocation();

  const parseLocationQuery = useMemo(
    () => parseQueryString<T>(search),
    [search],
  );

  return parseLocationQuery;
};

import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation as ErrorIcon } from '@fortawesome/free-solid-svg-icons';
import { red } from '@mui/material/colors';
import moment from 'moment';

import { formatterDate } from '~utils/index';

import { ExpirationDateInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';
import { DescriptionWithIconBoxText } from '../DescriptionWithIconBox/styles';

const tooltipMessage = 'Pedido vencido';

const ExpirationDateInfo = ({
  expirationDate,
  fontSize = 14,
  fontWeight,
}: ExpirationDateInfoProps): ReactElement => {
  const hasExpired = useMemo(() => {
    if (expirationDate) {
      const now = moment();
      const expiration = moment(expirationDate);

      return expiration.isBefore(now, 'day');
    }

    return false;
  }, [expirationDate]);

  const parseExpirationDate = useMemo(
    () =>
      expirationDate
        ? formatterDate(expirationDate, { format: 'DD/MM/YYYY' })
        : 'Sin vencimiento',
    [expirationDate],
  );

  if (!hasExpired)
    return (
      <DescriptionWithIconBoxText variant="inherit" fontWeight={fontWeight}>
        {parseExpirationDate}
      </DescriptionWithIconBoxText>
    );

  return (
    <DescriptionWithIconBox
      icon={<FontAwesomeIcon icon={ErrorIcon} />}
      iconTooltip={tooltipMessage}
      iconPosition="left"
      iconColor={red['500']}
      description={parseExpirationDate}
      size={fontSize}
      fontWeight={fontWeight}
    />
  );
};

export default memo(ExpirationDateInfo);

import { styled, LinearProgress } from '@mui/material';

export const StopsProgressInfoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size?: number }>(({ theme, size }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 150,
  gap: theme.spacing(1),
  fontSize: theme.typography.pxToRem(size ?? 14),
}));

export const StopsProgressInfoIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ theme, size }) => ({
  '& svg': {
    color: theme.palette.action.active,
    fontSize: size ?? 'inherit',
  },
}));

export const StopsProgressInfoLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ theme, size }) => ({
  flex: 1,
  borderRadius: theme.spacing(1),
  height: size - 4,
}));

import {
  ReactElement,
  // ChangeEvent,
  ElementRef,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useFormik, FormikHelpers } from 'formik';
import {
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
// import { TimePicker } from '@mui/x-date-pickers';
import Map, {
  NavigationControl,
  FullscreenControl,
  Marker,
  MarkerDragEvent,
} from 'react-map-gl';
import { throttle } from 'lodash';

import { hasError } from '~utils/index';
import { PlaceType } from '~hooks/usePlacesAutocomplete';
import { TOKEN } from '~utils/mapbox';

import { ConfigDepositFormProps, ConfigDepositFormValues } from './types';
import { FIELDS_NAME, getInitialValues, validationSchema } from './utils';
import PlacesAutocomplete from '../PlacesAutocomplete';
import { ModeSateliteControl } from '../MapCustomControl';
import { Marker as MarkerIcon } from '../Icons';
import {
  ConfigDepositFormMapContainer,
  ConfigDepositFormSubmitButton,
} from './styles';
import MapMarkerDragHint from '../MapMarkerDragHint';

const ConfigDepositForm = ({
  onSubmit,
  loadingSubmit,
  data,
  type = 'create',
}: ConfigDepositFormProps): ReactElement => {
  const isCreate = useMemo(() => type === 'create', [type]);

  const executeSubmit = useCallback(
    async (
      values: ConfigDepositFormValues,
      { setSubmitting }: FormikHelpers<ConfigDepositFormValues>,
    ) => {
      await onSubmit(values);

      setSubmitting(false);
    },
    [onSubmit],
  );

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = useFormik({
    initialValues: getInitialValues(data),
    validationSchema,
    onSubmit: executeSubmit,
  });

  const refAutocomplete = useRef<ElementRef<typeof PlacesAutocomplete>>(null);

  const getCoordAddres = useMemo(
    () =>
      throttle<google.maps.Geocoder['geocode']>((request, callback) => {
        new window.google.maps.Geocoder().geocode(request, callback);
      }, 200),
    [],
  );

  const handleChangeAddress = useCallback(
    (newValue: PlaceType | null) => {
      setFieldValue(FIELDS_NAME.ADDRESS, newValue?.description ?? '');
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, null);

      getCoordAddres({ placeId: newValue?.place_id }, (results) => {
        if (results) {
          setFieldValue(
            FIELDS_NAME.ADDRESS_COORDS,
            results[0].geometry.location.toJSON(),
          );
        }
      });
    },
    [getCoordAddres, setFieldValue],
  );

  const handleBlurAddress = useCallback((): void => {
    setFieldTouched(FIELDS_NAME.ADDRESS, true);
  }, [setFieldTouched]);

  const handleOnDragEndEvent = useCallback(
    (e: MarkerDragEvent) => {
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
      setFieldTouched(FIELDS_NAME.ADDRESS_COORDS);
    },
    [setFieldValue, setFieldTouched],
  );

  // const handleChangeDate = useCallback(
  //   (fieldName: string) =>
  //     (value: Date | null): void => {
  //       setFieldValue(fieldName, value);
  //     },
  //   [setFieldValue],
  // );

  // const handleBlurDate = useCallback(
  //   (fieldName: string) => (): void => {
  //     setFieldTouched(fieldName, true);
  //   },
  //   [setFieldTouched],
  // );

  // const isDisabledTimeWindowDates = useMemo(
  //   () => values[FIELDS_NAME.TIME_WINDOW_ALL_DAY],
  //   [values],
  // );

  // const handleChangeTimeWindowAllDay = useCallback(
  //   (event: ChangeEvent<HTMLInputElement>) => {
  //     const { checked } = event.target;

  //     setFieldValue(FIELDS_NAME.TIME_WINDOW_ALL_DAY, checked);
  //     setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_FROM, null);
  //     setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_TO, null);
  //     setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_FROM, null);
  //     setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_TO, null);
  //   },
  //   [setFieldValue],
  // );

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit}>
      <Stack direction="row" spacing={2}>
        <Stack spacing={2} flex={1}>
          <Stack spacing={2}>
            <Typography component="h6" fontWeight="bold">
              Información del depósito
            </Typography>

            <Stack spacing={2} direction="row">
              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps(FIELDS_NAME.ENABLED)}
                    checked={values[FIELDS_NAME.ENABLED]}
                  />
                }
                label="Activo"
              />
            </Stack>

            <TextField
              label="Nombre"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.TITLE)}
              {...getFieldProps(FIELDS_NAME.TITLE)}
            />

            <PlacesAutocomplete
              ref={refAutocomplete}
              id="customer-places-autocomplete"
              onChange={(_e, newVal) => handleChangeAddress(newVal)}
              onBlur={handleBlurAddress}
              error={hasError(touched, errors, FIELDS_NAME.ADDRESS)}
              initialValueRequest={data?.address ?? undefined}
            />

            <TextField
              label="Notas de dirección (piso, depto, instrucciones)"
              fullWidth
              multiline
              rows={3}
              error={hasError(touched, errors, FIELDS_NAME.ADDRESS_NOTES)}
              {...getFieldProps(FIELDS_NAME.ADDRESS_NOTES)}
            />

            <Stack direction="row" spacing={2}>
              {/* <TextField
                label="Radio"
                fullWidth
                error={hasError(touched, errors, FIELDS_NAME.ADDRESS_RADIUS)}
                {...getFieldProps(FIELDS_NAME.ADDRESS_RADIUS)}
              /> */}

              <TextField
                label="Tiempo estimado en parada (minutos)"
                fullWidth
                autoComplete="off"
                type="number"
                helperText="Si es '0' el depósito se tomará como visitado automaticamente"
                error={hasError(touched, errors, FIELDS_NAME.ADDRESS_WAIT_TIME)}
                {...getFieldProps(FIELDS_NAME.ADDRESS_WAIT_TIME)}
              />
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Typography component="h6" fontWeight="bold">
              Información de contacto del receptor
            </Typography>

            <Stack direction="row" spacing={2}>
              <TextField
                label="Nombre completo"
                fullWidth
                error={hasError(touched, errors, FIELDS_NAME.CONTACT_NAME)}
                {...getFieldProps(FIELDS_NAME.CONTACT_NAME)}
              />

              <TextField
                label="Teléfono"
                placeholder="Cod de área sin 0 + Nro sin 15"
                fullWidth
                type="tel"
                error={hasError(touched, errors, FIELDS_NAME.CONTACT_PHONE)}
                helperText="Cod de área sin 0 + Nro sin 15"
                {...getFieldProps(FIELDS_NAME.CONTACT_PHONE)}
              />
            </Stack>

            {/* <Stack spacing={2}>
              <Typography>Ventana horaria</Typography>

              <Stack direction="row" spacing={2}>
                <Stack spacing={2} flex={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <TimePicker
                      value={values[FIELDS_NAME.TIME_WINDOW_ONE_FROM]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          onBlur={handleBlurDate(
                            FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                          )}
                          error={hasError(
                            touched,
                            errors,
                            FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                          )}
                        />
                      )}
                    />

                    <Typography variant="caption">Hasta</Typography>

                    <TimePicker
                      value={values[FIELDS_NAME.TIME_WINDOW_ONE_TO]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_ONE_TO,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          onBlur={handleBlurDate(
                            FIELDS_NAME.TIME_WINDOW_ONE_TO,
                          )}
                          error={hasError(
                            touched,
                            errors,
                            FIELDS_NAME.TIME_WINDOW_ONE_TO,
                          )}
                        />
                      )}
                    />
                  </Stack>

                  <Stack direction="row" spacing={1} alignItems="center">
                    <TimePicker
                      value={values[FIELDS_NAME.TIME_WINDOW_SECOND_FROM]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          onBlur={handleBlurDate(
                            FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                          )}
                          error={hasError(
                            touched,
                            errors,
                            FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                          )}
                        />
                      )}
                    />

                    <Typography variant="caption">Hasta</Typography>

                    <TimePicker
                      value={values[FIELDS_NAME.TIME_WINDOW_SECOND_TO]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          onBlur={handleBlurDate(
                            FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                          )}
                          error={hasError(
                            touched,
                            errors,
                            FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                          )}
                        />
                      )}
                    />
                  </Stack>
                </Stack>

                <Stack spacing={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps(FIELDS_NAME.TIME_WINDOW_ALL_DAY)}
                        checked={values[FIELDS_NAME.TIME_WINDOW_ALL_DAY]}
                        onChange={handleChangeTimeWindowAllDay}
                      />
                    }
                    label="Todo el día"
                  />
                </Stack>
              </Stack>
            </Stack> */}
          </Stack>
        </Stack>

        <Stack spacing={2} flex={0.7}>
          {values[FIELDS_NAME.ADDRESS_COORDS] && (
            <ConfigDepositFormMapContainer>
              <Map
                mapboxAccessToken={TOKEN}
                mapStyle="mapbox://styles/mapbox/light-v10"
                initialViewState={{
                  longitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lng,
                  latitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lat,
                  zoom: 14,
                }}
                attributionControl={false}
              >
                <FullscreenControl position="bottom-left" />
                <NavigationControl position="bottom-left" showCompass={false} />
                <ModeSateliteControl position="bottom-left" />

                <Marker
                  longitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lng}
                  latitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lat}
                  onDragEnd={handleOnDragEndEvent}
                  draggable
                  anchor="bottom"
                >
                  <MarkerIcon color="primary" fontSize="large" />
                </Marker>
                <MapMarkerDragHint />
              </Map>
            </ConfigDepositFormMapContainer>
          )}
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <ConfigDepositFormSubmitButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loadingSubmit || isSubmitting}
          disabled={!isCreate && !dirty}
        >
          {isCreate ? 'Crear' : 'Guardar'}
        </ConfigDepositFormSubmitButton>
      </Stack>
    </Stack>
  );
};

export default ConfigDepositForm;

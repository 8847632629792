import { styled, Tabs, tabClasses } from '@mui/material';

export const AuthContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '400px auto',
  minHeight: '100vh',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr 0',
  },
}));

export const AuthContentContainer = styled('main')(({ theme }) => ({
  padding: theme.spacing(7.5, 7),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto',
  maxHeight: '100vh',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const AuthLogo = styled('img')(({ theme }) => ({
  width: 130,
  objectFit: 'contain',
  marginBottom: theme.spacing(3),
}));

export const AuthTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: ' 100%',

  [`& .${tabClasses.root}`]: {
    textTransform: 'none',
    flex: 1,
  },
}));

export const AuthContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
});

export const AuthBackgroundImg = styled('img')({
  width: '100%',
  height: '100vh',
  objectFit: 'cover',
});

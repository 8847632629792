import { ReactElement, useMemo, useCallback } from 'react';
import {
  DialogContentText,
  Button,
  Typography,
  Stack,
  Link,
} from '@mui/material';
import {
  Lock as LockIcon,
  WarningRounded as WarningIcon,
} from '@mui/icons-material/';
import { useNavigate } from 'react-router-dom';
import { isNull } from 'lodash';

import { useSubscriptionFeatures } from '~hooks/index';
import { Subscription } from '~components/index';
import { PATHS } from '~constants/paths';
import { getStringifyUrlQueryString } from '~utils/router';
import { getPlanGeneralInfo } from '~utils/plans';

import { AccessFeatureModalProps } from './types';
import {
  AccessFeatureModalContainer,
  AccessFeatureModalHeader,
  AccessFeatureModalContent,
  AccessFeatureModalActions,
} from './styles';

const AccessFeatureModal = ({
  open,
  onClose,
  data: { validFeature, validateAvailableRoutesState },
}: AccessFeatureModalProps): ReactElement => {
  const navigate = useNavigate();

  const { getNextPlanFeature } = useSubscriptionFeatures();

  const nextPlanInfo = useMemo(() => {
    if (!isNull(validFeature)) {
      const nextPlanName = getNextPlanFeature(validFeature);

      if (nextPlanName) {
        const nexPlanInfo = getPlanGeneralInfo(nextPlanName);
        return nexPlanInfo;
      }
    }
  }, [validFeature, getNextPlanFeature]);

  const TitleIcon = useMemo(
    () => (validateAvailableRoutesState ? WarningIcon : LockIcon),
    [validateAvailableRoutesState],
  );

  const handleRedirectToNewOrder = useCallback(() => {
    const url = getStringifyUrlQueryString({
      url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
      query: {
        currentTab: 'plans-packages',
      },
    });

    navigate(url);

    onClose();
  }, [navigate, onClose]);

  return (
    <AccessFeatureModalContainer open={open} maxWidth={false}>
      <AccessFeatureModalHeader isError={validateAvailableRoutesState}>
        <TitleIcon fontSize="small" />

        <Typography
          variant="body1"
          component="h2"
          fontWeight="bold"
          color="inherit"
        >
          ACTUALIZA TU PLAN PARA DISFRUTAR DE ESTA CARACTERÍSTICA
        </Typography>
      </AccessFeatureModalHeader>

      <AccessFeatureModalContent dividers>
        {!validateAvailableRoutesState && (
          <DialogContentText color="text.primary" variant="body2">
            Tu plan actual no posee acceso a esta función, por lo que te
            recomendamos estas opciones para que puedas tener acceso a ella.
          </DialogContentText>
        )}

        {validateAvailableRoutesState && (
          <Subscription.AvailableRoutesState.Alert
            withButtons={false}
            withCloseAction={false}
          />
        )}

        <Stack spacing={1}>
          {nextPlanInfo && (
            <Subscription.PlanPriceCard
              {...nextPlanInfo}
              direction="horizontal"
              withShadow={false}
              isRecommended
              featureTitle={undefined}
              onSubscribe={handleRedirectToNewOrder}
              paymentType="monthly"
            />
          )}

          <Typography variant="caption" color="text.secondary">
            *Esta función es opcional adquiriendo un pack adicional
          </Typography>
        </Stack>
      </AccessFeatureModalContent>

      <AccessFeatureModalActions>
        <Typography variant="caption" component="span">
          ¿No estás seguro que plan te conviene?{' '}
          <Link
            href="https://routix.io/planes"
            target="_blank"
            underline="hover"
            fontWeight="bold"
          >
            Mira la comparativa de planes
          </Link>
        </Typography>

        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>
      </AccessFeatureModalActions>
    </AccessFeatureModalContainer>
  );
};

export default AccessFeatureModal;

import {
  styled,
  Box,
  CircularProgress,
  circularProgressClasses,
  Typography,
} from '@mui/material';

import { Link } from '~components/index';

import { StatisticsProgressOwnerState } from './types';

export const StatisticsProgressContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  marginRight: 6,
  marginBottom: 6,
  flexDirection: 'column',
  alignItems: 'center',
});

export const StatisticsProgressContent = styled(Box)({
  position: 'relative',
});

export const StatisticsProgressFullProgress = styled(CircularProgress)(
  ({ theme }) => ({
    color: theme.palette.grey[400],
  }),
);

export const StatisticsProgressDymanicProgress = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<StatisticsProgressOwnerState>(({ theme, ownerState }) => ({
  position: 'absolute',
  top: -2.5,
  left: -2.5,

  ...(ownerState.themeColor &&
    ownerState.themeColor !== 'inherit' && {
      color: theme.palette[ownerState.themeColor].main,
    }),

  ...(ownerState.customColor && {
    color: ownerState.customColor,
  }),

  ...(ownerState.isCompleted &&
    ownerState.completedColor &&
    ownerState.completedColor !== 'inherit' && {
      color: theme.palette[ownerState.completedColor].main,
    }),

  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: 'round',
  },
}));

export const StatisticsProgressLegendContainer = styled(Box)({
  top: -2.5,
  left: -2.5,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StatisticsProgressLegendText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<StatisticsProgressOwnerState>(({ theme, ownerState }) => ({
  lineHeight: 0.8,
  fontSize: theme.typography.pxToRem(26),

  ...(ownerState.size === 'xSmall' && {
    fontSize: theme.typography.pxToRem(16),
  }),

  ...(ownerState.size === 'small' && {
    fontSize: theme.typography.pxToRem(22),
  }),

  ...(ownerState.size === 'large' && {
    fontSize: theme.typography.pxToRem(30),
  }),
}));

export const StatisticsProgressLabelText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<StatisticsProgressOwnerState>(({ theme, ownerState }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,

  ...(ownerState.size === 'xSmall' && {
    fontSize: theme.typography.pxToRem(10),
  }),

  ...(ownerState.size === 'small' && {
    fontSize: theme.typography.pxToRem(12),
  }),

  ...(ownerState.size === 'large' && {
    fontSize: theme.typography.pxToRem(16),
  }),
}));

export const StatisticsProgressLabelLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<StatisticsProgressOwnerState>(({ theme, ownerState }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,

  ...(ownerState.size === 'small' && {
    fontSize: theme.typography.pxToRem(10),
  }),

  ...(ownerState.size === 'small' && {
    fontSize: theme.typography.pxToRem(12),
  }),

  ...(ownerState.size === 'large' && {
    fontSize: theme.typography.pxToRem(16),
  }),
}));

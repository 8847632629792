import { ReactElement, useMemo, useEffect } from 'react';
import { Box, Collapse, List } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import classNames from 'classnames';

import { useToggle } from '~hooks/index';

import { RouteErrorsListProps } from './types';
import {
  classes,
  RouteErrorsListContainer,
  RouteErrorsListListButton,
  RouteErrorsListListItemText,
  RouteErrorsListListItem,
  RouteErrorsListListIcon,
} from './styles';

const RouteErrorsList = ({
  errors,
  title = 'Errores encontrados en la ruta',
}: RouteErrorsListProps): ReactElement => {
  const hasErrors = useMemo(() => Boolean(errors?.length), [errors]);
  const [expanded, toogleExpanded] = useToggle(true);

  useEffect(() => {
    if (!hasErrors) {
      toogleExpanded(true);
    }
  }, [hasErrors, toogleExpanded]);

  return (
    <Box>
      <Collapse in={hasErrors} timeout="auto">
        <RouteErrorsListContainer>
          <List disablePadding>
            <RouteErrorsListListButton
              onClick={() => toogleExpanded()}
              className={classNames({ [classes.expanded]: expanded })}
            >
              <RouteErrorsListListItemText
                primary={title}
                primaryTypographyProps={{
                  variant: 'body2',
                  fontWeight: 'bold',
                }}
              />

              <KeyboardArrowDownIcon className={classes.expandIcon} />
            </RouteErrorsListListButton>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <List disablePadding>
                {errors?.map((error, index) => (
                  <RouteErrorsListListItem key={`route-error-${index}`}>
                    <RouteErrorsListListIcon>
                      <HighlightOffIcon fontSize="inherit" />
                    </RouteErrorsListListIcon>

                    <RouteErrorsListListItemText
                      primary={error}
                      primaryTypographyProps={{
                        variant: 'caption',
                        fontWeight: 500,
                      }}
                    />
                  </RouteErrorsListListItem>
                ))}
              </List>
            </Collapse>
          </List>
        </RouteErrorsListContainer>
      </Collapse>
    </Box>
  );
};

export default RouteErrorsList;

import { Reducer, useReducer } from 'react';

const toggleReducer = (state: boolean, nextValue?: boolean) =>
  typeof nextValue === 'boolean' ? nextValue : !state;

export type UseToggleReturn = [boolean, (nextValue?: boolean) => void];

export const useToggle = (initialValue = false): UseToggleReturn =>
  useReducer<Reducer<boolean, boolean | undefined>>(
    toggleReducer,
    initialValue,
  );

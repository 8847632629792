import { styled, Card, CardContent } from '@mui/material';
import { lighten } from '@mui/material/styles';

export const AccessFeatureAlertCard = styled(Card)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.main, 0.9),
  border: `1px solid ${theme.palette.primary.main}`,
}));

export const AccessFeatureAlertCardContent = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: theme.spacing(1),

    '&:last-child': {
      paddingBottom: theme.spacing(1.5),
    },
  }),
);

export const AccessFeatureAlertCardActions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

import { ReactElement, useMemo } from 'react';
import { TableContainer, Table as TableMui } from '@mui/material';

import {
  TableProps,
  TableValidRow,
  TableContextValue,
  TableColumn,
  TableClasses,
  TableSelectable,
} from './types';
import TableProvider, { defaultSelectableOptions } from './TableContext';
import TableHeader from './Header';
import TableBody from './Body';
import TableFooter from './Footer';

const Table = <Row extends TableValidRow>({
  data,
  columns,
  classes,
  stickyHeader: stickyHeaderProps,
  enabledFooter,
  selectable: selectableProp,
  extractorKeyValue,
}: TableProps<Row>): ReactElement => {
  const contextValue = useMemo<TableContextValue>(() => {
    const selectableOptions = {
      ...defaultSelectableOptions,
      ...(selectableProp ?? {}),
    } as TableSelectable<TableValidRow>;

    return {
      columns: columns as TableColumn<TableValidRow>[],
      data,
      classes: classes as TableClasses<TableValidRow>,
      selectable: {
        enabled: Boolean(selectableProp),
        ...selectableOptions,
      },
      extractorKeyValue,
    };
  }, [classes, columns, data, extractorKeyValue, selectableProp]);

  const stickyHeaderMaxHeight = useMemo(() => {
    if (stickyHeaderProps?.enabled && stickyHeaderProps.maxHeight) {
      return stickyHeaderProps.maxHeight;
    }

    return undefined;
  }, [stickyHeaderProps]);

  return (
    <TableProvider value={contextValue}>
      <TableContainer
        className={classes?.container}
        sx={{ maxHeight: stickyHeaderMaxHeight }}
      >
        <TableMui
          stickyHeader={stickyHeaderProps?.enabled}
          className={classes?.table}
        >
          <TableHeader />

          <TableBody />

          {enabledFooter && <TableFooter />}
        </TableMui>
      </TableContainer>
    </TableProvider>
  );
};

export default Table;

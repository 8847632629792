import API from '~config/api';
import {
  ApiPromise,
  DataPaginateResponse,
  DataResponse,
  DataMessageResponse,
  ErrorResponse,
  PaginationRequestParams,
  MapCoordinates,
} from '~globals/types';

import {
  SearchRoute,
  RouteFilter,
  RouteFilterExport,
  RouteCreateBody,
  RouteAssignDriverBody,
  RouteAssignDriverResponse,
  RouteLogHistory,
  RouteItemFeedback,
  RouteSummary,
  RouteStats,
  RouteAddItemsBody,
  RouteRodmapParams,
  RouteUpdateConfigBody,
} from './types';
import {
  ApiTransactionRoutes,
  ApiTransactionExtendedGenerateRoute,
} from '../apiTransaction/types';

export const ROUTE_ENPOINTS = {
  searchRoutes: '/api/v1/Route/route/search',
  generateRoute: '/api/v1/Route/route/generate',
  optimizeRoute: '/api/v1/Planning/Route/optimize',
  getRoute: (routeId: string): string => `/api/v1/Route/Route/${routeId}`,
  assignDriver: '/api/v1/Route/Route/assignDriver',
  getLogHistoryRoute: (routeId: string): string =>
    `/api/v1/Route/Route/Log/${routeId}`,
  getFeedbackRouteItem: (routeItemId: string): string =>
    `/api/v1/Route/Route/RouteItemFeedback/${routeItemId}`,
  getTemplateCSV: '/api/v1/Route/Item/ImportFromCSV/Template',
  removeRoute: '/api/v1/Route/Route/Remove',
  addRouteItems: '/api/v1/Route/RouteItem/Add',
  removeRouteItem: (routeItemId: string): string =>
    `/api/v1/Route/RouteItem/remove/${routeItemId}`,
  removeRouteItems: '/api/v1/Route/RouteItem/remove',
  getCoordsPathByRoute: (routeId: string): string =>
    `/api/v1/Route/Coords/Path/${routeId}`,
  getCoordsTrackingByRoute: (routeId: string): string =>
    `/api/v1/Route/Coords/Tracking/${routeId}`,
  getSummaryRoute: (routeId: string): string =>
    `/api/v1/Route/Route/Summary/${routeId}`,
  updateOrderItems: '/api/v1/Route/RouteItem/UpdateOrder',
  exportRoadmapPDF: '/api/v1/Report/ItemsPDF',
  updateRouteConfig: '/api/v1/Route/Route/UpdateRouteConfig',
};

export const searchRoutes = <
  IncludeApiTransaction extends boolean | undefined = false,
>({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<RouteFilter<IncludeApiTransaction>>): ApiPromise<
  DataPaginateResponse<SearchRoute<IncludeApiTransaction>[], RouteStats>,
  ErrorResponse
> =>
  API.post<
    DataPaginateResponse<SearchRoute<IncludeApiTransaction>[], RouteStats>,
    ErrorResponse
  >(ROUTE_ENPOINTS.searchRoutes, {
    ...filters,
    pagination: {
      offset: page,
      size: sizePerPage,
    },
  });

export const searchRoutesToExport = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<RouteFilterExport>): ApiPromise<
  string,
  ErrorResponse
> =>
  API.post<string, ErrorResponse>(
    ROUTE_ENPOINTS.searchRoutes,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
    { responseType: 'blob', timeout: 120000 },
  );

export const generateRoute = (
  body: RouteCreateBody,
): ApiPromise<
  DataResponse<ApiTransactionExtendedGenerateRoute>,
  ErrorResponse
> =>
  API.post<DataResponse<ApiTransactionExtendedGenerateRoute>, ErrorResponse>(
    ROUTE_ENPOINTS.generateRoute,
    body,
  );

export const optimizeRoute = (
  routeId: string,
): ApiPromise<DataMessageResponse, ErrorResponse> =>
  API.post<DataMessageResponse, ErrorResponse>(ROUTE_ENPOINTS.optimizeRoute, {
    routeId,
  });

export const getRoute = (
  routeId: string,
): ApiPromise<DataResponse<ApiTransactionRoutes>, ErrorResponse> =>
  API.get<DataResponse<ApiTransactionRoutes>, ErrorResponse>(
    ROUTE_ENPOINTS.getRoute(routeId),
  );

export const getLogHistoryRoute = (
  routeId: string,
): ApiPromise<DataResponse<RouteLogHistory[]>, ErrorResponse> =>
  API.get<DataResponse<RouteLogHistory[]>, ErrorResponse>(
    ROUTE_ENPOINTS.getLogHistoryRoute(routeId),
  );

export const assignDriver = (
  body: RouteAssignDriverBody,
): ApiPromise<DataResponse<RouteAssignDriverResponse>, ErrorResponse> =>
  API.post<DataResponse<RouteAssignDriverResponse>, ErrorResponse>(
    ROUTE_ENPOINTS.assignDriver,
    body,
  );

export const getFeedbackRouteItem = (
  routeItemId: string,
): ApiPromise<DataResponse<RouteItemFeedback>, ErrorResponse> =>
  API.get<DataResponse<RouteItemFeedback>, ErrorResponse>(
    ROUTE_ENPOINTS.getFeedbackRouteItem(routeItemId),
  );

export const removeRoute = (body: {
  routeId: string;
  removeItems: boolean;
}): ApiPromise<DataResponse<ApiTransactionRoutes>, ErrorResponse> =>
  API.post<DataResponse<ApiTransactionRoutes>, ErrorResponse>(
    ROUTE_ENPOINTS.removeRoute,
    body,
  );

export const addRouteItemsInRoute = (
  body: RouteAddItemsBody,
): ApiPromise<DataMessageResponse, ErrorResponse> =>
  API.post<DataMessageResponse, ErrorResponse>(
    ROUTE_ENPOINTS.addRouteItems,
    body,
  );

export const removeRouteItemInRoute = (
  routeItemId: string,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    ROUTE_ENPOINTS.removeRouteItem(routeItemId),
  );

export const removeRouteItemsInRoute = (
  routeItemsIds: string[],
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(ROUTE_ENPOINTS.removeRouteItems, {
    routeItems: routeItemsIds,
  });

export const getCoordsPathByRoute = (
  routeId: string,
): ApiPromise<DataResponse<MapCoordinates>, ErrorResponse> =>
  API.get<DataResponse<MapCoordinates>, ErrorResponse>(
    ROUTE_ENPOINTS.getCoordsPathByRoute(routeId),
  );

export const getCoordsTrackingByRoute = (
  routeId: string,
): ApiPromise<DataResponse<MapCoordinates>, ErrorResponse> =>
  API.get<DataResponse<MapCoordinates>, ErrorResponse>(
    ROUTE_ENPOINTS.getCoordsTrackingByRoute(routeId),
  );

export const getSummaryRoute = (
  routeId: string,
): ApiPromise<DataResponse<RouteSummary>, ErrorResponse> =>
  API.get<DataResponse<RouteSummary>, ErrorResponse>(
    ROUTE_ENPOINTS.getSummaryRoute(routeId),
  );

export const updateOrderItems = (
  routeItemsIds: string[],
): ApiPromise<DataResponse<{ msg: string }>, ErrorResponse> =>
  API.put<DataResponse<{ msg: string }>, ErrorResponse>(
    ROUTE_ENPOINTS.updateOrderItems,
    { routeItemsId: routeItemsIds },
  );

export const exportRoadmapPDF = (
  body: RouteRodmapParams,
): ApiPromise<Blob, ErrorResponse> =>
  API.post<Blob, ErrorResponse>(ROUTE_ENPOINTS.exportRoadmapPDF, body, {
    responseType: 'blob',
    timeout: 120000,
  });

export const updateRouteConfig = (
  body: RouteUpdateConfigBody,
): ApiPromise<DataResponse<ApiTransactionRoutes>, ErrorResponse> =>
  API.put<DataResponse<ApiTransactionRoutes>, ErrorResponse>(
    ROUTE_ENPOINTS.updateRouteConfig,
    body,
  );

import * as Yup from 'yup';
import moment from 'moment';

import { VALIDATIONS_ERROR } from '~constants/index';
import { Nullable } from '~globals/types';
import { formatterTime } from '~utils/index';
import { SecurityDeliveryLevel } from '~globals/types/enums';
import { CustomerBase } from '~services/customer/types';

export const FIELDS_NAME = {
  LEGAL_NAME: 'legalName',
  CODE: 'code',
  ADDRESS: 'address',
  ADDRESS_COORDS: 'addressCoords',
  ADDRESS_NOTES: 'addressNotes',
  ACCESS_CODE: 'accessCode',
  CONTACT_NAME: 'contactName',
  CONTACT_IDENTIFICATION: 'contactIdentification',
  CONTACT_PHONE: 'contactPhone',
  CONTACT_MOBILE: 'contactMobile',
  CONTACT_EMAIL: 'contactEmail',
  CUSTOM_FIELD1: 'customField1',
  CUSTOM_FIELD2: 'customField2',
  CUSTOM_FIELD3: 'customField3',
  VALIDATE_CONTACT_IDENTIFICATION: 'validateContactIdentification',
  VALIDATE_CONTACT_SECURITY_CODE: 'validateContactSecurityCode',
  TIME_WINDOW_ONE_FROM: 'timeWindowOneFrom',
  TIME_WINDOW_ONE_TO: 'timeWindowOneTo',
  TIME_WINDOW_SECOND_FROM: 'timeWindowSecondFrom',
  TIME_WINDOW_SECOND_TO: 'timeWindowSecondTo',
  TIME_WINDOW_ALL_DAY: 'timeWindowAllDay',
  ZIP_CODE: 'zipCode',
} as const;

const parseTimeWindowValue = (value: Nullable<string>): Nullable<Date> =>
  value
    ? new Date(formatterTime(value, moment.HTML5_FMT.DATETIME_LOCAL_MS))
    : null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (
  data?: Nullable<CustomerBase>,
  isEdit = false,
) => ({
  [FIELDS_NAME.LEGAL_NAME]: data?.legalName ?? '',
  [FIELDS_NAME.CODE]: data?.code ?? '',
  [FIELDS_NAME.ADDRESS]: data?.address ?? '',
  [FIELDS_NAME.ADDRESS_COORDS]: (data?.latitude && data.longitude
    ? {
        lat: data.latitude,
        lng: data.longitude,
      }
    : null) as Nullable<google.maps.LatLngLiteral>,
  [FIELDS_NAME.ADDRESS_NOTES]: data?.addressNotes ?? '',
  [FIELDS_NAME.ACCESS_CODE]: data?.accessCode ?? '',
  [FIELDS_NAME.CONTACT_NAME]: data?.contactName ?? '',
  [FIELDS_NAME.CONTACT_IDENTIFICATION]: data?.contactIdentification ?? '',
  [FIELDS_NAME.CONTACT_PHONE]: data?.contactPhone ?? '',
  [FIELDS_NAME.CONTACT_MOBILE]: data?.contactMobile ?? '',
  [FIELDS_NAME.CONTACT_EMAIL]: data?.contactEmail ?? '',
  [FIELDS_NAME.CUSTOM_FIELD1]: data?.customField1 ?? '',
  [FIELDS_NAME.CUSTOM_FIELD2]: data?.customField2 ?? '',
  [FIELDS_NAME.CUSTOM_FIELD3]: data?.customField3 ?? '',
  [FIELDS_NAME.VALIDATE_CONTACT_IDENTIFICATION]:
    data?.validateContactIdentification ?? false,
  [FIELDS_NAME.VALIDATE_CONTACT_SECURITY_CODE]: Boolean(
    data?.securityDeliveryLevel ?? SecurityDeliveryLevel.Low,
  ),
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: parseTimeWindowValue(
    data?.timeWindowFrom1 ?? (isEdit ? null : '09:00:00'),
  ),
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: parseTimeWindowValue(
    data?.timeWindowTo1 ?? (isEdit ? null : '18:00:00'),
  ),
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: parseTimeWindowValue(
    data?.timeWindowFrom2 ?? null,
  ),
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: parseTimeWindowValue(
    data?.timeWindowTo2 ?? null,
  ),
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: data?.timeWindowAllDay ?? false,
  [FIELDS_NAME.ZIP_CODE]: data?.zipCode ?? '',
});

export const validationTimeWindowDate = Yup.date()
  .nullable()
  .when(FIELDS_NAME.TIME_WINDOW_ALL_DAY, {
    is: false,
    then: (schema) =>
      schema
        .typeError(VALIDATIONS_ERROR.INVALID_DATE)
        .required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  });

const validationApproveDocument = Yup.string().when(
  FIELDS_NAME.VALIDATE_CONTACT_IDENTIFICATION,
  {
    is: true,
    then: (schema) => schema.required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  },
);

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.LEGAL_NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.CODE]: Yup.string().notRequired(),
  [FIELDS_NAME.ADDRESS]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.ADDRESS_COORDS]: Yup.mixed().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.ADDRESS_NOTES]: Yup.string().notRequired(),
  [FIELDS_NAME.ACCESS_CODE]: Yup.string().notRequired(),
  [FIELDS_NAME.CONTACT_NAME]: validationApproveDocument,
  [FIELDS_NAME.CONTACT_IDENTIFICATION]: validationApproveDocument,
  [FIELDS_NAME.CONTACT_PHONE]: Yup.string().notRequired(),
  [FIELDS_NAME.CONTACT_MOBILE]: Yup.string().notRequired(),
  [FIELDS_NAME.CONTACT_EMAIL]: Yup.string()
    .email(VALIDATIONS_ERROR.EMAIL)
    .notRequired(),
  [FIELDS_NAME.CUSTOM_FIELD1]: Yup.string().notRequired(),
  [FIELDS_NAME.CUSTOM_FIELD2]: Yup.string().notRequired(),
  [FIELDS_NAME.CUSTOM_FIELD3]: Yup.string().notRequired(),
  [FIELDS_NAME.VALIDATE_CONTACT_IDENTIFICATION]: Yup.boolean(),
  [FIELDS_NAME.VALIDATE_CONTACT_SECURITY_CODE]: Yup.boolean(),
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: validationTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: validationTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: Yup.date().nullable().notRequired(),
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: Yup.date().nullable().notRequired(),
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: Yup.boolean(),
  [FIELDS_NAME.ZIP_CODE]: Yup.string().notRequired(),
});

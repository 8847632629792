import { ReactElement, memo } from 'react';

import { usePartnerCompanyStatusInfo } from '~hooks/index';

import { PartnerCompanyStatusChipProps } from './types';
import { PartnerCompanyStatusChipChippRoot } from './styles';

const PartnerCompanyStatusChip = ({
  stateId,
  size = 'medium',
  variant = 'filled',
}: PartnerCompanyStatusChipProps): ReactElement => {
  const { description, color } = usePartnerCompanyStatusInfo(stateId);

  return (
    <PartnerCompanyStatusChipChippRoot
      variant={variant}
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(PartnerCompanyStatusChip);

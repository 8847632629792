import { FC, createContext, useContext } from 'react';

import { ItemCreateExtended } from '~services/item/types';
import { Nullable } from '~globals/types';

interface DialogDetailsOrderContextContextValue {
  data: Nullable<ItemCreateExtended>;
  reloadData: () => void;
  onClose: () => void;
}

const DialogDetailsOrderContextContext =
  createContext<DialogDetailsOrderContextContextValue>({
    data: null,
    reloadData: () => {},
    onClose: () => {},
  });

export const useDialogDetailsOrderContextContext =
  (): DialogDetailsOrderContextContextValue =>
    useContext(DialogDetailsOrderContextContext);

const DialogDetailsOrderContextProvider: FC<{
  value: DialogDetailsOrderContextContextValue;
}> = ({ children, value }) => (
  <DialogDetailsOrderContextContext.Provider value={value}>
    {children}
  </DialogDetailsOrderContextContext.Provider>
);

export default DialogDetailsOrderContextProvider;

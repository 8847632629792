import { useImporterOrdersProgressListener } from '~hooks/useImporterOrdersProgressListener';
import { Nullable } from '~globals/types';

interface ImporterCustomerProgressListenerParams {
  runListenerUpdates: boolean;
  onProgress?: () => void;
  onCompleted?: () => void;
}

export type ImporterCustomerProgressListenerStatus = Nullable<
  'inProgress' | 'completed'
>;

export interface ImporterCustomerProgressNotification {
  progress: number;
  step: number;
  total: number;
}

interface ImporterCustomerProgressListenerReturn {
  status: ImporterCustomerProgressListenerStatus;
  updateImporterStatus: (
    newStatus: ImporterCustomerProgressListenerStatus,
  ) => void;
  data: ImporterCustomerProgressNotification;
  reset: () => void;
}

export const useImporterCustomersProgressListener = ({
  runListenerUpdates = false,
  onProgress,
  onCompleted,
}: ImporterCustomerProgressListenerParams): ImporterCustomerProgressListenerReturn => {
  const {
    data: { progress, step, total },
    ...restImporter
  } = useImporterOrdersProgressListener({
    runListenerUpdates,
    onProgress,
    onCompleted,
  });

  return {
    data: {
      progress,
      step,
      total,
    },
    ...restImporter,
  };
};

import { useMemo } from 'react';
import { get } from 'lodash';
import {
  faArrowUp as ChargeIcon,
  faArrowDown as DischargeIcon,
  faListCheck as TaskIcon,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { grey, blue, purple } from '@mui/material/colors';

import { ItemTypes } from '~globals/types/enums';

interface ItemTypeInfoItem {
  icon: IconDefinition;
  color: string;
  tooltipMessage: string;
}

type ItemTypeList = {
  [state in ItemTypes]: ItemTypeInfoItem;
};

export const useItemTypeInfo = (stateId: ItemTypes): ItemTypeInfoItem => {
  const stateList = useMemo<ItemTypeList>(
    () => ({
      [ItemTypes.Charge]: {
        icon: ChargeIcon,
        color: purple['500'],
        tooltipMessage: 'Carga',
      },
      [ItemTypes.Discharge]: {
        icon: DischargeIcon,
        color: blue['500'],
        tooltipMessage: 'Descarga',
      },
      [ItemTypes.Task]: {
        icon: TaskIcon,
        color: grey['600'],
        tooltipMessage: 'Tarea',
      },
    }),
    [],
  );

  const currentInfo = useMemo<ItemTypeInfoItem>(
    () => get(stateList, stateId),
    [stateId, stateList],
  );

  return currentInfo;
};

import * as Yup from 'yup';
import { find } from 'lodash';

import { VALIDATIONS_ERROR } from '~constants/index';
import { DriverCompanyBase } from '~services/driver/types';

export const FIELDS_NAME = {
  CURRENT_DRIVER: 'currentDriver',
} as const;

export const initialValues = {
  [FIELDS_NAME.CURRENT_DRIVER]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.CURRENT_DRIVER]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
});

export const findSelectedDriver = (
  drivers: DriverCompanyBase[],
  selectedDriverId: string,
): DriverCompanyBase | undefined => find(drivers, { id: selectedDriverId });

import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { useStepperGenerateRoutePlanningStepperContext } from '../../SteppetGenerateRouteContext';
import { getSetenceByDictionary } from '../../utils';
import { StepperGenerateRouteDictionary } from '../../types';

export const dictionary: StepperGenerateRouteDictionary = {
  title: {
    generate: 'Plan de viaje generado.',
    generateCollect: 'Plan de colecta generado.',
    optimize: 'Ruta optimizada.',
  },
  description: {
    generate:
      'Hemos generado las mejores rutas y optimizado la secuencia de paradas.',
    generateCollect:
      'Hemos generado las mejores rutas y optimizado la secuencia de paradas.',
    optimize:
      'Hemos optimizado el recorrido y actualizado los tiempos estimados de viaje.',
  },
  error: {
    generate:
      // eslint-disable-next-line max-len
      'Los siguientes pedidos no se han podido incluir en el plan de viaje, A continuación se enumeran los posibles errores.',
    generateCollect:
      // eslint-disable-next-line max-len
      'Los siguientes pedidos no se han podido incluir en el plan de colecta, A continuación se enumeran los posibles errores.',
    optimize:
      // eslint-disable-next-line max-len
      'Los siguientes pedidos no se han podido incluir en la ruta, A continuación se enumeran los posibles errores.',
  },
};

export const StepperGenerateRouteStepGenerated = (): ReactElement => {
  const { type, showErrors } = useStepperGenerateRoutePlanningStepperContext();

  return (
    <Stack>
      <Typography component="h4" variant="h6" color="primary">
        {getSetenceByDictionary(dictionary, 'title', type)}
      </Typography>

      <Typography component="p" variant="body2">
        {getSetenceByDictionary(dictionary, 'description', type)}
      </Typography>

      {showErrors && (
        <Typography
          component="p"
          sx={{ marginTop: 1 }}
          fontWeight="bold"
          variant="body2"
        >
          {getSetenceByDictionary(dictionary, 'error', type)}
        </Typography>
      )}
    </Stack>
  );
};

export default StepperGenerateRouteStepGenerated;

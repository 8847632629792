import { ReactNode, ReactElement, useState, useCallback, useMemo } from 'react';
import {
  styled,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  ListItemIcon,
  Checkbox,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import classNames from 'classnames';

import { useToggle } from '~hooks/index';

type Layers =
  | 'markers'
  | 'trips'
  | 'vehicles'
  | 'routeItemState'
  | 'orders'
  | 'warehouses'
  | 'zones';

type LayersSelectors = Record<Layers, boolean>;

interface LayersListItem {
  name: Layers;
  label: string;
  enabled: boolean;
}

type LayersControlHidden = {
  [Property in keyof LayersSelectors as `hidden${Capitalize<
    string & Property
  >}`]?: LayersSelectors[Property];
};

type LayerControlEnabled = {
  [Property in keyof LayersSelectors as `enabled${Capitalize<
    string & Property
  >}`]?: LayersSelectors[Property];
};

interface LayersControlProps extends LayersControlHidden {
  children: (layers: LayerControlEnabled) => ReactNode;
}

export const classes = {
  expanded: 'LayersControl-expanded',
  expandIcon: 'LayersControl-expandIcon',
};

export const LayersControlContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 160,
  background: theme.palette.common.white,
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1.25),
  boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const LayersControlListItemButton = styled(ListItemButton)(
  ({ theme }) => ({
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(
      ['border-radius', 'background-color'],
      {
        duration: theme.transitions.duration.shortest,
      },
    ),

    [`& .${classes.expandIcon}`]: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.action.active,
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },

    [`&.${classes.expanded}`]: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

      [`& .${classes.expandIcon}`]: {
        transform: 'rotate(180deg)',
      },
    },
  }),
);

export const LayersControlListItemText = styled(ListItemText)({
  margin: 0,
});

const LayersControlListIcon = styled(ListItemIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  minWidth: 'auto',
}));

const LayersControlListItemCheckbox = styled(Checkbox)({
  padding: 0,
});

const LayersControl = ({
  hiddenMarkers = false,
  hiddenTrips = false,
  hiddenVehicles = false,
  hiddenRouteItemState = false,
  hiddenOrders = true,
  hiddenWarehouses = true,
  hiddenZones = true,
  children,
}: LayersControlProps): ReactElement => {
  const [expanded, toogleExpanded] = useToggle(true);

  const layersList = useMemo<LayersListItem[]>(
    () => [
      {
        name: 'markers',
        label: 'Marcadores',
        enabled: !hiddenMarkers,
      },
      {
        name: 'trips',
        label: 'Rutas',
        enabled: !hiddenTrips,
      },
      {
        name: 'vehicles',
        label: 'Vehículos',
        enabled: !hiddenVehicles,
      },
      {
        name: 'routeItemState',
        label: 'Estados de visita',
        enabled: !hiddenRouteItemState,
      },
      {
        name: 'orders',
        label: 'Pedidos',
        enabled: !hiddenOrders,
      },
      {
        name: 'warehouses',
        label: 'Depósitos',
        enabled: !hiddenWarehouses,
      },
      {
        name: 'zones',
        label: 'Zonas logísticas',
        enabled: !hiddenZones,
      },
    ],
    [
      hiddenMarkers,
      hiddenTrips,
      hiddenVehicles,
      hiddenRouteItemState,
      hiddenOrders,
      hiddenWarehouses,
      hiddenZones,
    ],
  );

  const [checkedLayers, setCheckedLayers] = useState<LayersSelectors>({
    markers: true,
    trips: true,
    vehicles: true,
    routeItemState: true,
    orders: true,
    warehouses: true,
    zones: true,
  });

  const toggleLayer = useCallback((name: Layers) => {
    setCheckedLayers((prevLayers) => ({
      ...prevLayers,
      [name]: !prevLayers[name],
    }));
  }, []);

  return (
    <>
      <LayersControlContainer>
        <List disablePadding>
          <LayersControlListItemButton
            onClick={() => toogleExpanded()}
            className={classNames({ [classes.expanded]: expanded })}
          >
            <LayersControlListItemText
              primary="Capas"
              primaryTypographyProps={{
                variant: 'caption',
                fontWeight: 'bold',
              }}
            />

            <KeyboardArrowDown className={classes.expandIcon} />
          </LayersControlListItemButton>

          <Collapse in={expanded} timeout="auto">
            <List disablePadding>
              {layersList.map(
                ({ name, label, enabled }) =>
                  enabled && (
                    <LayersControlListItemButton
                      key={`layers-control-item-${name}`}
                      onClick={() => toggleLayer(name)}
                    >
                      <LayersControlListIcon>
                        <LayersControlListItemCheckbox
                          checked={checkedLayers[name]}
                          tabIndex={-1}
                          disableRipple
                          size="small"
                        />
                      </LayersControlListIcon>

                      <LayersControlListItemText
                        primary={label}
                        primaryTypographyProps={{
                          variant: 'caption',
                        }}
                      />
                    </LayersControlListItemButton>
                  ),
              )}
            </List>
          </Collapse>
        </List>
      </LayersControlContainer>

      {children({
        enabledMarkers: checkedLayers.markers,
        enabledTrips: checkedLayers.trips,
        enabledVehicles: checkedLayers.vehicles,
        enabledRouteItemState: checkedLayers.routeItemState,
        enabledOrders: checkedLayers.orders,
        enabledWarehouses: checkedLayers.warehouses,
        enabledZones: checkedLayers.zones,
      })}
    </>
  );
};

export default LayersControl;

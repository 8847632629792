import { ReactElement, useState, useCallback, useMemo } from 'react';
import { Typography, IconButton, Link, Stack, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { defaultTo } from 'lodash';
import moment from 'moment';

import { Menu, LoadingFallback, ActiveRouteCard } from '~components/index';
import { MenuProps } from '~components/Menu/types';
import { PATHS } from '~constants/index';
import { useLazyRequest, useInfiniteScroll } from '~hooks/index';
import { searchRoutes } from '~services/route';
import { RouteFilter, SearchRoute, RouteStats } from '~services/route/types';
import {
  PaginationRequestParams,
  DataPaginateResponse,
  ErrorResponse,
} from '~globals/types';
import { RouteStateTypes } from '~globals/types/enums';
import { formatterDate } from '~utils/formatter';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import {
  HEIGHT_ACTIVE_ROUTE_CARD,
  ActiveRoutesMenuContainer,
  ActiveRoutesMenuHeader,
  ActiveRoutesMenuContent,
  ActiveRoutesMenuContentList,
} from './styles';

const defaultSizePerPage = 10;

const currentDate = new Date(moment().startOf('day').format());
const afterOneWeekDay = new Date(moment().add(1, 'week').endOf('day').format());

const defaultRouteStateTypesId = [
  RouteStateTypes.New,
  RouteStateTypes.Assigned,
  RouteStateTypes.Approve,
  RouteStateTypes.InProgress,
];

const ActiveRoutesMenu = ({
  onClose,
  ...restProps
}: Omit<MenuProps, 'children'>): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [activeRoutes, setActiveRoutes] = useState<SearchRoute<true>[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);

  const [
    ,
    loadingFetchActiveRoutes,
    errorFetchActiveRoutes,
    executeFetchActiveRoutes,
  ] = useLazyRequest<
    PaginationRequestParams<RouteFilter<true>>,
    DataPaginateResponse<SearchRoute<true>[], RouteStats>,
    ErrorResponse
  >({
    request: searchRoutes,
    withPostSuccess: (response) => {
      const {
        data: { pagination, results },
      } = response.data as DataPaginateResponse<
        SearchRoute<true>[],
        RouteStats
      >;

      const newActiveRoutes = [...activeRoutes, ...results];
      setActiveRoutes(newActiveRoutes);

      const totalSize = defaultTo(pagination?.count, results.length);
      setHasNextPage(newActiveRoutes.length < totalSize);

      setPage((prevPage) => prevPage + 1);
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo cargar los datos', { variant: 'error' });
    },
  });

  const onLoadMore = useCallback(() => {
    executeFetchActiveRoutes({
      pagination: {
        page,
        sizePerPage: defaultSizePerPage,
      },
      filters: {
        dateFrom: formatterDate(currentDate, {
          format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
        }),
        dateTo: formatterDate(afterOneWeekDay, {
          format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
        }),
        routeCode: null,
        driversId: null,
        vehiclesId: null,
        routeStateTypesId: defaultRouteStateTypesId,
        includeApiTransaction: true,
      },
    });
  }, [executeFetchActiveRoutes, page]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loadingFetchActiveRoutes,
    hasNextPage,
    onLoadMore,
    disabled: !!errorFetchActiveRoutes,
    rootMargin: `0px 0px ${HEIGHT_ACTIVE_ROUTE_CARD}px 0px`,
  });

  const showLoading = useMemo(
    () => loadingFetchActiveRoutes || hasNextPage,
    [loadingFetchActiveRoutes, hasNextPage],
  );

  const notActiveRoutes = useMemo(
    () => !loadingFetchActiveRoutes && !hasNextPage && !activeRoutes.length,
    [loadingFetchActiveRoutes, hasNextPage, activeRoutes],
  );

  return (
    <Menu onClose={onClose} {...restProps}>
      <ActiveRoutesMenuContainer>
        <ActiveRoutesMenuHeader>
          <Typography component="h3" variant="subtitle1" fontWeight="medium">
            MIS RUTAS ACTIVAS
          </Typography>

          <IconButton
            size="small"
            aria-label="Close"
            onClick={onClose}
            color="inherit"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </ActiveRoutesMenuHeader>

        <ActiveRoutesMenuContent>
          <ActiveRoutesMenuContentList ref={rootRef}>
            {activeRoutes.map((route) => (
              <ActiveRouteCard
                key={`active-route-${route.routeId}-vehicle${route.vehicleId}`}
                route={route}
                onClick={onClose}
              />
            ))}

            {showLoading && <LoadingFallback size={50} />}

            {notActiveRoutes && (
              <Stack spacing={1}>
                <Typography
                  variant="h6"
                  textAlign="center"
                  my="auto"
                  fontWeight="bold"
                >
                  No se encontraron rutas activas
                </Typography>

                <Box
                  component="img"
                  src={toAbsoluteUrl('/icons/icon_empty_routes.png')}
                  alt="Empty Routes Icon"
                  sx={{ height: 80, objectFit: 'contain' }}
                />
              </Stack>
            )}

            <div ref={sentryRef} />
          </ActiveRoutesMenuContentList>

          <Link
            color="primary"
            variant="caption"
            fontWeight="bold"
            component={RouterLink}
            to={PATHS.ROUTES.BASE}
            onClick={onClose}
          >
            Ver todas mi rutas...
          </Link>
        </ActiveRoutesMenuContent>
      </ActiveRoutesMenuContainer>
    </Menu>
  );
};

export default ActiveRoutesMenu;

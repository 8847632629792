import { styled, Button, IconButton, Link, ButtonProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export const HeaderContainer = styled('header')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: '1fr auto',
  marginBottom: theme.spacing(3),
}));

export const HeaderCardInfoColumn = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'grid',
  gridAutoFlow: 'column',
  gap: theme.spacing(1.25),
  width: 'max-content',
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  fontWeight: theme.typography.fontWeightBold,
}));

export const HeaderButtonExternalLink = styled((props: ButtonProps<'a'>) => (
  <Button {...props} component={Link} />
))(({ theme }) => ({
  borderRadius: 28,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  fontWeight: theme.typography.fontWeightBold,
}));

export const HeaderButtonRouterlLink = styled(
  (props: ButtonProps<'a'> & RouterLinkProps) => (
    <Button {...props} component={RouterLink} />
  ),
)(({ theme }) => ({
  borderRadius: 28,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  fontWeight: theme.typography.fontWeightBold,
}));

export const HeaderIconButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'withImage',
})<{ withImage?: boolean }>(({ theme, variant, withImage }) => ({
  minWidth: 0,
  padding: theme.spacing(0.75),
  borderRadius: 28,
  position: 'relative',

  ...(withImage && {
    padding: theme.spacing(0.5),
  }),

  ...(variant &&
    variant === 'outlined' && {
      backgroundColor: theme.palette.background.default,

      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    }),
}));

export const HeaderIconButtonIcon = styled(FontAwesomeIcon)({
  fontSize: 17,
});

export const HeaderIconButtonImg = styled('img')({
  width: 21,
  height: 21,
});

export const HeaderButtonAvatar = styled(IconButton)({
  padding: 3,
});

import { ReactElement, memo } from 'react';

import { useOrderReceivedStateInfo } from '~hooks/index';

import { OrderReceivedStateChipProps } from './types';
import { OrderReceivedStateChipRoot } from './styles';

const OrderReceiveStateChip = ({
  collectItemStateTypeId,
  size = 'medium',
}: OrderReceivedStateChipProps): ReactElement => {
  const { description, color } = useOrderReceivedStateInfo(
    collectItemStateTypeId,
  );

  return (
    <OrderReceivedStateChipRoot
      variant="filled"
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(OrderReceiveStateChip);

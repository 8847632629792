import { round } from 'lodash';

import { InitialSetupWizardContextStepId } from './InitialSetupWizarContext';

export const getProgressValue = (
  isCarrier: boolean,
  stepNum: number,
): number => {
  const progressVal = isCarrier ? 25 : 33.333;

  return round(progressVal * stepNum, 2);
};

export const getAriaDescribedBy = (
  step: InitialSetupWizardContextStepId,
): string => `initial-setup-wizard-content-step-${step}`;

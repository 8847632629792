import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { addItemTaskInItem } from '~services/item';
import { ItemTask } from '~services/item/types';

import { useLazyRequest } from './useLazyRequest';

interface AddItemTaskParams {
  onSuccess?: (itemTask: ItemTask) => void;
  onError?: () => void;
}

interface AddItemTaskReturn {
  loading: boolean;
  handleAddItemTask: (itemTask: ItemTask) => void;
}

export const useAddItemTask = (
  options?: AddItemTaskParams,
): AddItemTaskReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loading, , executeRequest] = useLazyRequest({
    request: addItemTaskInItem,
    withPostSuccess: (response) => {
      const currentItenTask = response.data?.data;

      if (currentItenTask) {
        options?.onSuccess?.(currentItenTask);

        enqueueSnackbar('Artículo / Tarea agregado correctamente', {
          variant: 'success',
        });
      }
    },
    withPostFailure: () => {
      options?.onError?.();

      enqueueSnackbar(
        'No se pudo agregar el artículo / tarea, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const handleAddItemTask = useCallback(
    async (itemTask: ItemTask) => {
      await executeRequest(itemTask);
    },
    [executeRequest],
  );

  return { loading, handleAddItemTask };
};

import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  NAME: 'name',
  TYPE: 'type',
  AVERAGE_CONSUPTION: 'averageConsuption',
  CAPACITY_ONE: 'capacityOne',
  CAPACITY_TWO: 'capacityTwo',
} as const;

export const initialValues = {
  [FIELDS_NAME.NAME]: '',
  [FIELDS_NAME.TYPE]: '0',
  [FIELDS_NAME.AVERAGE_CONSUPTION]: '9.9',
  [FIELDS_NAME.CAPACITY_ONE]: 1000,
  [FIELDS_NAME.CAPACITY_TWO]: 1000,
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.TYPE]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.CAPACITY_ONE]: Yup.number()
    .min(1, VALIDATIONS_ERROR.MIN(1))
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.AVERAGE_CONSUPTION]: Yup.string().notRequired(),
  [FIELDS_NAME.CAPACITY_TWO]: Yup.number()
    .moreThan(0, VALIDATIONS_ERROR.MORE_THAN(0))
    .required(VALIDATIONS_ERROR.REQUIRED),
});

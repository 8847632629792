import { styled, Box } from '@mui/material';

export const MapMarkerDragHintContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 30,
  right: 0,
  flexDirection: 'row',
  background: theme.palette.common.white,
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),
  boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
  zIndex: 1,
}));

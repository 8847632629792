import { ReactElement, useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import {
  SelectorOptionContainer,
  SelectorOptionFooter,
  SelectorOptionSeparator,
  SelectorOptionSubTitle,
  SelectorOptionTitle,
} from './styles';
import { useSubscriptionFeatures } from '~app/hooks';
import { PlanFeaturesTypes } from '~globals/types/enums';

const WhatsappTypeSelector = (): ReactElement => {
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const hasWhatsappLiteAccess = useMemo(
    () => hasAccessToFeature(PlanFeaturesTypes.NotifWhatsapp),
    [hasAccessToFeature],
  );

  const hasWhatsappPlusAccess = useMemo(
    () => hasAccessToFeature(PlanFeaturesTypes.NotifWhatappBusiness),
    [hasAccessToFeature],
  );

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: '0.875rem' }}>
        Canal seleccionado para la comunicación
      </Typography>
      <Stack direction="row" spacing={2}>
        <SelectorOptionContainer selected={hasWhatsappLiteAccess}>
          <SelectorOptionTitle selected={hasWhatsappLiteAccess}>
            WhatsApp Lite
          </SelectorOptionTitle>
          <SelectorOptionSubTitle>
            Comunicación autogestionada
          </SelectorOptionSubTitle>
          <SelectorOptionSeparator />
          <SelectorOptionFooter>No requiere linea propia</SelectorOptionFooter>
        </SelectorOptionContainer>
        <SelectorOptionContainer selected={hasWhatsappPlusAccess}>
          <SelectorOptionTitle selected={hasWhatsappPlusAccess}>
            WhatsApp Linea Propia
          </SelectorOptionTitle>
          <SelectorOptionSubTitle>
            Comunicación usando su propia marca personal
          </SelectorOptionSubTitle>
          <Button
            disabled
            variant="contained"
            sx={{ marginTop: 1, marginBottom: 1.25 }}
          >
            Próximamente
          </Button>
          <SelectorOptionFooter>Requiere linea propia</SelectorOptionFooter>
        </SelectorOptionContainer>
      </Stack>
    </Stack>
  );
};

export default WhatsappTypeSelector;

import { styled, Chip, chipClasses } from '@mui/material';

export const AccessFeatureNextPlanChipRoot = styled(Chip)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(9),
  height: 16,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.common.white,

  [`&.${chipClasses.clickable}:hover`]: {
    backgroundColor: theme.palette.common.white,
  },

  [`& .${chipClasses.label}`]: {
    padding: theme.spacing(0, 0.5),
  },
}));

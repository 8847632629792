import { ReactElement, useCallback, useMemo } from 'react';
import { Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { faChevronLeft as GoBackIcon } from '@fortawesome/free-solid-svg-icons';

import { TitleWithBreadcrumbsProps } from './types';
import {
  TitleWithBreadcrumbsContainer,
  TitleWithBreadcrumbsTitleContainer,
  TitleWithBreadcrumbsTitleIconButton,
  TitleWithBreadcrumbsTitleIconButtonIcon,
} from './styles';
import Breadcrumbs from '../Breadcrumbs';

const TitleWithBreadcrumbs = ({
  title: titleProps,
  disableTitleTypograhpy = false,
  breadcrumbsItems,
  showGoBack = false,
}: TitleWithBreadcrumbsProps): ReactElement => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const title = useMemo(() => {
    if (disableTitleTypograhpy) return titleProps;

    return (
      <Typography
        variant="h5"
        component="h2"
        color="text.primary"
        fontWeight="bold"
      >
        {titleProps}
      </Typography>
    );
  }, [disableTitleTypograhpy, titleProps]);

  return (
    <TitleWithBreadcrumbsContainer>
      <TitleWithBreadcrumbsTitleContainer>
        {showGoBack && (
          <Tooltip title="Ir atrás" arrow>
            <TitleWithBreadcrumbsTitleIconButton
              color="inherit"
              variant="contained"
              onClick={handleGoBack}
            >
              <TitleWithBreadcrumbsTitleIconButtonIcon icon={GoBackIcon} />
            </TitleWithBreadcrumbsTitleIconButton>
          </Tooltip>
        )}

        {title}
      </TitleWithBreadcrumbsTitleContainer>

      <Breadcrumbs items={breadcrumbsItems} />
    </TitleWithBreadcrumbsContainer>
  );
};

export default TitleWithBreadcrumbs;

import { useCallback, memo } from 'react';
import mapboxgl from 'mapbox-gl';
import { useControl, ControlPosition, MapRef } from 'react-map-gl';
import { controlLocale } from '~constants/mapControlLocale';

import MapboxGLButtonControl from './MapboxGLButtonControl';

export interface ShowTripViewControlProps {
  currentBounds: mapboxgl.LngLatBounds;
  position?: ControlPosition;
}

export const ShowTripViewControl = ({
  currentBounds,
  position,
}: ShowTripViewControlProps): null => {
  const showTrip = useCallback(
    (map: MapRef) => {
      map.fitBounds(currentBounds, { padding: 100, maxZoom: 12 });
    },
    [currentBounds],
  );

  useControl(
    ({ map }) =>
      new MapboxGLButtonControl({
        className: 'mapbox-gl-trip',
        title: controlLocale.ShowTrip,
        eventHandler: () => showTrip(map),
      }),
    { position },
  );

  return null;
};

export default memo(ShowTripViewControl);

import { ReactElement, useMemo } from 'react';

import { useSubscriptionFeatures } from '~hooks/index';
import { Nullable } from '~globals/types';

import { AccessFeatureHiddenProps } from './types';

const AccessFeatureHidden = ({
  validFeature,
  availableToDate,
  type = 'notAccess',
  validDemo = false,
  children,
}: AccessFeatureHiddenProps): Nullable<ReactElement> => {
  const { validateFeatureAccess, validateFeatureAvailableToDate } =
    useSubscriptionFeatures();

  const isDemo = useMemo(
    () =>
      !validateFeatureAccess(validFeature) &&
      validateFeatureAvailableToDate(availableToDate),
    [
      availableToDate,
      validFeature,
      validateFeatureAccess,
      validateFeatureAvailableToDate,
    ],
  );

  const isHidden = useMemo(() => {
    if (isDemo) return type === 'notAccess' ? !validDemo : validDemo;

    const isValidFeature = validateFeatureAccess(validFeature);

    return type === 'notAccess' ? !isValidFeature : isValidFeature;
  }, [isDemo, type, validDemo, validateFeatureAccess, validFeature]);

  return isHidden ? null : children;
};

export default AccessFeatureHidden;

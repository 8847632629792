import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { RootState } from '../../types';
import { PlansState } from './types';

const initialState: PlansState = {
  data: [],
};

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action: PayloadAction<PlansState['data']>) => {
      state.data = action.payload;
    },
    resetsPlans: () => initialState,
  },
});

export const { setPlans, resetsPlans } = plansSlice.actions;

const persistPlansConfig: PersistConfig<PlansState> = {
  key: 'routix-plans',
  storage,
};

const persistPlansReducer = persistReducer(
  persistPlansConfig,
  plansSlice.reducer,
);

export const selectPlans = (state: RootState): PlansState => state.plans;

export default persistPlansReducer;

import { ReactElement, useEffect, useState } from 'react';
import { MenuItem, SelectChangeEvent, Stack, Typography } from '@mui/material';
import {
  DecreaseButton,
  IncreaseButton,
  TimeFactorSelect,
  TitleTypography,
  ToleranceTimePickerContainer,
} from './styles';
import { ToleranceTimePickerProps } from './types';
import NewFeatureBadge from '../NewFeatureBagde';

const ToleraceTimePicker = ({
  initialTime,
  onChange,
  title,
}: ToleranceTimePickerProps): ReactElement => {
  const [count, setCount] = useState<number>(
    initialTime < 60 ? initialTime : initialTime / 60,
  );
  const [timeFactor, setTimeFactor] = useState<number>(
    initialTime < 60 ? 1 : 60,
  );

  useEffect(() => {
    onChange(count * timeFactor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, timeFactor]);

  const onIncreaseCount = (): void => {
    if (count === 24 && timeFactor === 60) return;
    if (count === 55) {
      setTimeFactor(60);
      setCount(1);
      return;
    }
    setCount((prevCount) => prevCount + (timeFactor === 60 ? 1 : 5));
  };

  const onDecreaseCount = (): void => {
    if (count === 5 && timeFactor !== 60) return;
    if (count === 1 && timeFactor === 60) {
      setTimeFactor(1);
      setCount(55);
      return;
    }
    setCount((prevCount) => prevCount - (timeFactor === 60 ? 1 : 5));
  };

  const onTimeFactorChange = (event: SelectChangeEvent<unknown>): void => {
    const newFactor = parseInt(event.target.value as string);
    setTimeFactor(newFactor);
    if (count > 24 && newFactor === 60) return setCount(1);
    if (count <= 24 && newFactor === 1) return setCount(5);
  };

  return (
    <ToleranceTimePickerContainer>
      <Stack direction="row" justifyContent="space-between">
        <TitleTypography>{title ?? ''}</TitleTypography>
        <NewFeatureBadge activeForVersion="1.13.0" />
      </Stack>
      <Stack spacing={1} direction="row">
        <Stack sx={{ alignItems: 'center', ml: 1 }} direction="row">
          <Stack mr={1}>
            <IncreaseButton onClick={onIncreaseCount}>+</IncreaseButton>
            <DecreaseButton onClick={onDecreaseCount}>-</DecreaseButton>
          </Stack>
          <Typography width={20} textAlign="center">
            {count}
          </Typography>
        </Stack>
        <TimeFactorSelect
          fullWidth
          disableUnderline
          variant="standard"
          value={timeFactor.toString()}
          onChange={onTimeFactorChange}
        >
          <MenuItem key="0" value="60">
            horas
          </MenuItem>
          <MenuItem key="1" value="1">
            minutos
          </MenuItem>
        </TimeFactorSelect>
      </Stack>
    </ToleranceTimePickerContainer>
  );
};

export default ToleraceTimePicker;

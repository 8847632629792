import { useMemo } from 'react';
import { get } from 'lodash';
import { useTheme } from '@mui/material';
import { blue } from '@mui/material/colors';

import { RouteItemStateType } from '~globals/types/enums';

interface OrderOnAgendaStateInfoItem {
  description: string;
  color: string;
}

type OrderOnAgendaStateType =
  | RouteItemStateType.OnAgenda
  | RouteItemStateType.InProgress;

type OrderOnAgendaStateList = {
  [state in OrderOnAgendaStateType]: OrderOnAgendaStateInfoItem;
};

export const useOrderOnAgendaStateInfo = (
  stateId: OrderOnAgendaStateType,
): OrderOnAgendaStateInfoItem => {
  const currentTheme = useTheme();

  const stateList = useMemo<OrderOnAgendaStateList>(
    () => ({
      [RouteItemStateType.OnAgenda]: {
        description: 'En agenda',
        color: blue[700],
      },
      [RouteItemStateType.InProgress]: {
        description: 'En proceso',
        color: currentTheme.palette.primary.main,
      },
    }),
    [currentTheme],
  );

  const currentInfo = useMemo<OrderOnAgendaStateInfoItem>(
    () => get(stateList, stateId),
    [stateId, stateList],
  );

  return currentInfo;
};

import { FC, createContext, useContext, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { chain, isEmpty, trimStart, includes } from 'lodash';

import {
  useRequest,
  useLazyRequest,
  useImporterOrdersProgressListener,
} from '~hooks/index';
import { getAdminIntegrations } from '~services/admin/integration';
import { importItemsFromIntegrations } from '~services/item';
import { AdminIntegration } from '~services/admin/integration/types';
import {
  ItemImportFromIntegrationsResponse,
  ItemImportFromIntegrationsBody,
} from '~services/item/types';
import { IntegrationProvidersTypes } from '~globals/types/enums';
import { DataPaginateResponse } from '~globals/types';
import {
  ImporterOrdersProgressListenerStatus,
  ImporterOrdersProgressNotification,
} from '~hooks/useImporterOrdersProgressListener';

interface DialogImportOrdersFromIntegrationContextParams {
  onSubmitSuccess: () => void;
  onClose: () => void;
}

export type DialogImportOrdersFromIntegrationContextPanelsId =
  | 'notIntegrations'
  | 'presentation'
  | 'progress';

interface DialogImportOrdersFromIntegrationContextCurrentIntegrations {
  ids: IntegrationProvidersTypes[];
  isPresentMeli: boolean;
  loading: boolean;
}

interface DialogImportOrdersFromIntegrationContextImporterRequestItemsData {
  descriptions: string[];
}

interface DialogImportOrdersFromIntegrationContextImporterRequest {
  executeImport: (data: ItemImportFromIntegrationsBody) => Promise<void>;
  loading: boolean;
  isCompletedRequest: boolean;
  itemsData: DialogImportOrdersFromIntegrationContextImporterRequestItemsData;
}

interface DialogImportOrdersFromIntegrationContextImporterProgress {
  runListener: boolean;
  status: ImporterOrdersProgressListenerStatus;
  data: ImporterOrdersProgressNotification;
}

interface DialogImportOrdersFromIntegrationContextValue {
  activeTab: DialogImportOrdersFromIntegrationContextPanelsId;
  currentIntegrations: DialogImportOrdersFromIntegrationContextCurrentIntegrations;
  importerRequest: DialogImportOrdersFromIntegrationContextImporterRequest;
  importerProgress: DialogImportOrdersFromIntegrationContextImporterProgress;
}

const DialogImportOrdersFromIntegrationContext =
  createContext<DialogImportOrdersFromIntegrationContextValue>({
    activeTab: 'notIntegrations',
    currentIntegrations: {
      ids: [],
      isPresentMeli: false,
      loading: true,
    },
    importerRequest: {
      executeImport: () => Promise.resolve(),
      loading: false,
      isCompletedRequest: false,
      itemsData: { descriptions: [] },
    },
    importerProgress: {
      runListener: false,
      status: null,
      data: {
        progress: 0,
        step: 0,
        total: 0,
        integrationType: undefined,
      },
    },
  });

export const useDialogImportOrdersFromIntegrationContext =
  (): DialogImportOrdersFromIntegrationContextValue =>
    useContext(DialogImportOrdersFromIntegrationContext);

const DialogImportOrdersFromIntegrationProvider: FC<
  DialogImportOrdersFromIntegrationContextParams
> = ({ children, onSubmitSuccess, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] =
    useState<DialogImportOrdersFromIntegrationContextPanelsId>(
      'notIntegrations',
    );

  const [currentIntegrationsIds, setCurrentIntegrationsIds] = useState<
    IntegrationProvidersTypes[]
  >([]);

  const [
    isPresentMeliInCurrentIntegrations,
    setIsPresentMeliInCurrentIntegrations,
  ] = useState(false);

  const [, loadingCurrentIntegrationsIds] = useRequest({
    request: getAdminIntegrations,
    payload: null,
    withPostSuccess: (response) => {
      const {
        data: { results },
      } = response.data as DataPaginateResponse<AdminIntegration[]>;

      const parseCurrentIntegrations = chain(results)
        .map('integrationTypeId')
        .uniq()
        .value();

      const isPresentMeli = includes(
        parseCurrentIntegrations,
        IntegrationProvidersTypes.MercadoLibre,
      );

      setCurrentIntegrationsIds(parseCurrentIntegrations);
      setIsPresentMeliInCurrentIntegrations(isPresentMeli);

      if (parseCurrentIntegrations.length > 0) setActiveTab('presentation');
    },
  });

  const [runImporterProgressListener, setImporterProgressListener] =
    useState(false);

  const [isCompletedRequest, setIsCompletedRequest] = useState(false);
  const [importedItemsData, setImportedItemsData] =
    useState<DialogImportOrdersFromIntegrationContextImporterRequestItemsData>({
      descriptions: [],
    });

  const [, loadingExecuteImportItems, , executeImportItems] = useLazyRequest({
    request: importItemsFromIntegrations,
    withPostSuccess: (response) => {
      const { message, operations } = response.data
        ?.data as ItemImportFromIntegrationsResponse;

      if (operations.quantity >= 1) {
        const responseAlerts = chain(message)
          .split(/\n/)
          .slice(1)
          .uniqWith(isEmpty)
          .map(trimStart)
          .value();

        setImportedItemsData({
          descriptions: responseAlerts,
        });

        onSubmitSuccess();
      } else {
        enqueueSnackbar('No se registran operaciones para importar', {
          variant: 'info',
        });

        onClose();
      }

      setIsCompletedRequest(true);
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      setIsCompletedRequest(true);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const handleImport = useCallback(
    async (data: ItemImportFromIntegrationsBody) => {
      setImporterProgressListener(true);
      setIsCompletedRequest(false);
      await executeImportItems(data);
    },
    [executeImportItems],
  );

  const { status: statusProgress, data: dataProgress } =
    useImporterOrdersProgressListener({
      runListenerUpdates: runImporterProgressListener,
      onProgress: () => setActiveTab('progress'),
    });

  return (
    <DialogImportOrdersFromIntegrationContext.Provider
      value={{
        activeTab,
        currentIntegrations: {
          ids: currentIntegrationsIds,
          isPresentMeli: isPresentMeliInCurrentIntegrations,
          loading: loadingCurrentIntegrationsIds,
        },
        importerRequest: {
          executeImport: handleImport,
          loading: loadingExecuteImportItems,
          isCompletedRequest,
          itemsData: importedItemsData,
        },
        importerProgress: {
          runListener: runImporterProgressListener,
          status: statusProgress,
          data: dataProgress,
        },
      }}
    >
      {children}
    </DialogImportOrdersFromIntegrationContext.Provider>
  );
};

export default DialogImportOrdersFromIntegrationProvider;

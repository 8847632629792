import { ReactElement, useMemo } from 'react';
import { Tooltip } from '@mui/material';

import { useAccessFeatureModalContext } from '~app/contexts/AccessFeatureModalContext';
import { useSubscriptionFeatures } from '~hooks/index';
import { Nullable } from '~globals/types';
import { formatterDate } from '~utils/formatter';

import { ACCESS_ALERT_TEXT } from '../utils';
import AccessFeatureHidden from '../Hidden';

import { AccessFeatureNextPlanChipProps } from './types';
import { AccessFeatureNextPlanChipRoot } from './styles';

const AccessFeatureNextPlanChip = ({
  validFeature,
  availableToDate,
  openAccessFeatureModalOnClick = false,
  variant = 'outlined',
  ...restProps
}: AccessFeatureNextPlanChipProps): Nullable<ReactElement> => {
  const { handleOpen: handleOpenAccesFeatureModal } =
    useAccessFeatureModalContext();
  const {
    getNextPlanFeature,
    validateFeatureAccess,
    validateFeatureAvailableToDate,
  } = useSubscriptionFeatures();

  const isDemo = useMemo(
    () =>
      !validateFeatureAccess(validFeature) &&
      validateFeatureAvailableToDate(availableToDate),
    [
      availableToDate,
      validFeature,
      validateFeatureAccess,
      validateFeatureAvailableToDate,
    ],
  );

  const title = useMemo(() => {
    if (isDemo && availableToDate) {
      return `Función de prueba disponible hasta el ${formatterDate(
        availableToDate,
      )}. Disponible en planes superiores o de pago.`;
    }

    return ACCESS_ALERT_TEXT;
  }, [availableToDate, isDemo]);

  const label = useMemo(
    () => (isDemo ? 'DEMO' : getNextPlanFeature(validFeature)),
    [getNextPlanFeature, isDemo, validFeature],
  );

  const handlerClick = useMemo(() => {
    if (!isDemo && openAccessFeatureModalOnClick) {
      return () =>
        handleOpenAccesFeatureModal({
          validFeature,
          validateAvailableRoutesState: false,
        });
    }

    return undefined;
  }, [
    openAccessFeatureModalOnClick,
    handleOpenAccesFeatureModal,
    isDemo,
    validFeature,
  ]);

  return (
    <AccessFeatureHidden
      validFeature={validFeature}
      availableToDate={availableToDate}
      type="hasAccess"
    >
      <Tooltip title={title}>
        <AccessFeatureNextPlanChipRoot
          label={label}
          variant={variant}
          color="error"
          onClick={handlerClick}
          {...restProps}
        />
      </Tooltip>
    </AccessFeatureHidden>
  );
};

export default AccessFeatureNextPlanChip;

import * as Yup from 'yup';

import { PartnerCompanyTypes } from '~globals/types/enums';
import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  COMPANY_TYPE: 'companyType',
  REFERENCE: 'reference',
} as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (companyTypeId: PartnerCompanyTypes) => ({
  [FIELDS_NAME.COMPANY_TYPE]: companyTypeId,
  [FIELDS_NAME.REFERENCE]: '',
});

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.COMPANY_TYPE]: Yup.number().notRequired(),
  [FIELDS_NAME.REFERENCE]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
});

export const STATUS_CODES = {
  invalidCredentials: -100,
  userExist: -203,
  userNotExist: -104,
  validateEmail: -109,
  validateAccount: -110,
  emailAlreadySent: -111,
  invalidPassword: -201,
  codeNotValid: -208,
  tooManyRetriesCode: -209,
  ok: 200,
  unauthorized: 401,
  clientError: 409,
};

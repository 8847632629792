import { ReactElement, memo } from 'react';

import { useOrderFinalizedStateInfo } from '~hooks/index';

import { OrderFinalizedStateChipProps } from './types';
import { OrderFinalizedStateChipRoot } from './styles';

const OrderFinalizedStateChip = ({
  stateId,
  size = 'medium',
}: OrderFinalizedStateChipProps): ReactElement => {
  const { description, color } = useOrderFinalizedStateInfo(stateId);

  return (
    <OrderFinalizedStateChipRoot
      variant="filled"
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(OrderFinalizedStateChip);

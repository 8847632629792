import { ReactElement, useMemo } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';

import {
  StepperGenerateRoute,
  GenerateRouteCompletedTabs,
} from '~components/index';
import { isCompletedWorkProgressId } from '~components/StepperGenerateRoute/utils';
import { useGenerateRouteStepperData, useUtcOffset } from '~hooks/index';

import { useDialogGenerateRoutesContext } from '../../DialogGenerateRoutesContext';
import { isRouteCollect } from '../../utils';

const DialogGenerateRoutesStepperTab = (): ReactElement => {
  const formatterDateUtcOffset = useUtcOffset();

  const {
    type,
    stepper: {
      currentApiTransactionId: { value: currentApiTransactionId },
    },
    onSuccessComplete: onSuccessCompleteStepper,
  } = useDialogGenerateRoutesContext();

  const {
    data,
    loadingData,
    activeStep,
    workProgressId,
    indicators,
    loadingIndicators,
    totalStops,
    totalVehicles,
    errors,
  } = useGenerateRouteStepperData({
    apiTransactionId: currentApiTransactionId as string,
    onSuccessComplete: onSuccessCompleteStepper,
  });

  const prefixTypeTitle = useMemo(
    () => (isRouteCollect(type) ? 'Plan de colecta' : 'Plan de viaje'),
    [type],
  );

  const title = useMemo(
    () =>
      `${prefixTypeTitle} ${data?.name ?? ''} (${formatterDateUtcOffset(
        data?.scheduledDateTime,
        'DD/MM/YYYY HH:mm',
      )})`,
    [data, formatterDateUtcOffset, prefixTypeTitle],
  );

  const stepperType = useMemo(
    () => (isRouteCollect(type) ? 'generateCollect' : 'generate'),
    [type],
  );

  if (loadingData) {
    return (
      <Stack flex={1} justifyContent="center" alignItems="center">
        <CircularProgress color="primary" disableShrink size={60} />
      </Stack>
    );
  }

  return (
    <Stack spacing={2} flex={1}>
      <Typography variant="h6" component="h2" color="text.primary">
        {title}
      </Typography>

      <Stack spacing={2}>
        <StepperGenerateRoute
          activeStep={activeStep}
          workProgressId={workProgressId}
          errors={errors}
          type={stepperType}
          showErrors={false}
          light={false}
        />

        {isCompletedWorkProgressId(workProgressId) && (
          <GenerateRouteCompletedTabs
            workProgressId={workProgressId}
            indicators={indicators}
            loadingIndicators={loadingIndicators}
            errors={errors}
            totalStops={totalStops}
            totalVehicles={totalVehicles}
            type={stepperType}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default DialogGenerateRoutesStepperTab;

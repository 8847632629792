import { ReactElement, memo } from 'react';
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material';

import { useTableContext } from '../TableContext';

import { useTableHeaderSelectable } from './useTableHeaderSelectable';
import { useTableHeader } from './useTableHeader';

const TableHeader = (): ReactElement => {
  const {
    data,
    columns,
    classes,
    selectable: { enabled: enabledSelectable, ...restSelectableData },
    extractorKeyValue,
  } = useTableContext();

  const { hidden: hiddenSelectable, getCheckBoxProps } =
    useTableHeaderSelectable(extractorKeyValue, restSelectableData, data);

  const { getCellProps } = useTableHeader();

  return (
    <TableHead className={classes?.header}>
      <TableRow className={classes?.headerRow} tabIndex={0}>
        {enabledSelectable && (
          <TableCell padding="checkbox">
            {!hiddenSelectable && (
              <Checkbox color="primary" {...getCheckBoxProps()} />
            )}
          </TableCell>
        )}

        {columns.map((column, columnIndex) => (
          <TableCell
            key={`header-${columnIndex}`}
            component="th"
            {...getCellProps(column, columnIndex)}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};

export default memo(TableHeader);

import { FC, createContext, useContext, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { camelCaseSerializer } from '~utils/serializer';
import useSound from 'use-sound';

import { Nullable, UncapitalizeInterface } from '~globals/types/commons';
import {
  NotificationAlert,
  NotificationAlertLink,
} from '~globals/types/notifications';
import { NotificationAlertSeverity } from '~globals/types/enums';
import { useSignalRContext } from '~contexts/SignalRContext';
import { useClientOnMethod } from '~hooks/useSignalR';
import { METHODS_NAME } from '~constants/signalR';
import { AlertSnackbarContent } from '~components/index';
import { formatterDate } from '~utils/formatter';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

export interface Alert
  extends Omit<UncapitalizeInterface<NotificationAlert>, 'link'> {
  link?: UncapitalizeInterface<NotificationAlertLink>;
}

interface AlertsContextValue {
  currentAlerts: Alert[];
  lastAlert: Nullable<Alert>;
  lastUpdateDate: Nullable<string>;
}

const AlertsContext = createContext<AlertsContextValue>({
  currentAlerts: [],
  lastAlert: null,
  lastUpdateDate: null,
});

const showSnackbarEnabledList = [
  NotificationAlertSeverity.High,
  NotificationAlertSeverity.Exception,
];

export const useAlertsContext = (): AlertsContextValue =>
  useContext(AlertsContext);

const AlertsProvider: FC = ({ children }) => {
  const { hubConnection } = useSignalRContext();
  const { enqueueSnackbar } = useSnackbar();
  const [play] = useSound(toAbsoluteUrl('/sounds/routix_alert.mp3'));
  const [currentAlerts, setCurrentAlerts] = useState<
    AlertsContextValue['currentAlerts']
  >([]);
  const [lastAlert, setLastAlert] =
    useState<AlertsContextValue['lastAlert']>(null);
  const [lastUpdateDate, setLastUpdateDate] =
    useState<AlertsContextValue['lastUpdateDate']>(null);

  const updateAlerts = useCallback(
    (alert: string) => {
      const alertInfo = camelCaseSerializer.serialize(
        JSON.parse(alert),
      ) as Alert;

      setCurrentAlerts((prevAlerts) => [alertInfo, ...prevAlerts]);
      setLastAlert(alertInfo);
      setLastUpdateDate(
        formatterDate(new Date(), { format: 'YYYY-MM-DDTHH:mm:ss' }),
      );

      if (showSnackbarEnabledList.includes(alertInfo.severity)) {
        play();
        enqueueSnackbar(alertInfo.title, {
          persist: true,
          content: (key, currentTitle) => (
            <AlertSnackbarContent
              id={key}
              messageDateTime={alertInfo.messageDateTime}
              title={currentTitle}
              message={alertInfo.message}
              type={alertInfo.type}
              link={alertInfo.link}
              initialExpanded={alertInfo.initialExpanded}
            />
          ),
        });
      }
    },
    [enqueueSnackbar, play],
  );

  useClientOnMethod(hubConnection, METHODS_NAME.ALERT, updateAlerts);

  return (
    <AlertsContext.Provider
      value={{
        currentAlerts,
        lastAlert,
        lastUpdateDate,
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsProvider;

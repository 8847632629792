import { styled, Card, CardContent, Chip } from '@mui/material';

export const DissatisfiedReviewsCardContainer = styled(Card)({
  width: '100%',
});

export const DissatisfiedReviewsCardContent = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(2),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const DissatisfiedReviewsCardBadge = styled(Chip)(({ theme }) => ({
  minWidth: 100,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'uppercase',
}));

export const DissatisfiedReviewsCardIconImg = styled('img')({
  height: 80,
  width: 100,
});

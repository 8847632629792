import {
  styled,
  ListSubheader,
  ListItem,
  ListItemText,
  listItemTextClasses,
} from '@mui/material';

export const PlanningIndicatorsListSubHeader = styled(ListSubheader)(
  ({ theme }) => ({
    ...theme.typography.h6,
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }),
);

export const PlanningIndicatorsListItem = styled(ListItem)(({ theme }) => ({
  '&:not(:last-child)': {
    [`& .${listItemTextClasses.root}`]: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export const PlanningIndicatorsListText = styled(ListItemText)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  margin: 0,
  paddingBottom: theme.spacing(1),

  [`& .${listItemTextClasses.primary}`]: {
    flex: 1,
  },

  [`& .${listItemTextClasses.secondary}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { deleteAdminIntegration } from '~services/admin/integration';
import { getIntegrationProviderData } from '~utils/integrations';
import { IntegrationProvidersTypes } from '~globals/types/enums';

import { useLazyRequest } from './useLazyRequest';

export const useIntegrationAdminDelete = (
  onSuccessDelete?: () => void,
): {
  loading: boolean;
  handleDeleteIntegration: (integrationId: string) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingDeleteIntegration, , executeDeleteIntegration] =
    useLazyRequest({
      request: deleteAdminIntegration,
      withPostSuccess: (response) => {
        const integrationResponse = response.data?.data;

        const providerInfo = getIntegrationProviderData(
          integrationResponse?.integrationTypeId as IntegrationProvidersTypes,
        );

        const message = `La integración ${providerInfo?.name} fue eliminada correctamente`;

        enqueueSnackbar(message, { variant: 'success' });

        onSuccessDelete?.();
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'No se pudo elimnar la integración, intente nuevamente',
          { variant: 'error' },
        );
      },
    });

  const handleDeleteIntegration = useCallback(
    async (integrationId: string) => {
      await executeDeleteIntegration(integrationId);
    },
    [executeDeleteIntegration],
  );

  return { loading: loadingDeleteIntegration, handleDeleteIntegration };
};

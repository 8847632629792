import { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

import { PaidMembershipBadgeProps } from './types';
import { PaidMembershipBadgeRoot } from './styles';

const TOOLTIP_TITLE_DEMO =
  'Función de prueba disponible por tiempo límitado. Disponible en planes superiores o de pago.';

const PaidMembershipBadge = ({
  visible,
  label = 'DEMO',
  variant = 'outlined',
  tooltipTitle = TOOLTIP_TITLE_DEMO,
  ...rest
}: PaidMembershipBadgeProps): ReactElement => {
  if (!visible) return <></>;

  return (
    <Tooltip title={tooltipTitle}>
      <PaidMembershipBadgeRoot
        label={label}
        variant={variant}
        color="error"
        {...rest}
      />
    </Tooltip>
  );
};

export default PaidMembershipBadge;

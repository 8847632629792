import { useRef, useState, useLayoutEffect, useCallback } from 'react';

const serializer = JSON.stringify;
const deserializer = JSON.parse;

interface UseLocalStorageParams<T> {
  key?: string;
  initialValue?: T;
}

interface UseLocalStorageReturn<T> {
  state: T | undefined;
  set: (newState: T) => void;
  remove: () => void;
}

export const useLocalStorage = <T>({
  key,
  initialValue,
}: UseLocalStorageParams<T>): UseLocalStorageReturn<T> => {
  const initializer = useRef((storagKey?: string) => {
    try {
      if (storagKey) {
        const localStorageValue = localStorage.getItem(storagKey);

        if (localStorageValue !== null) {
          return deserializer(localStorageValue);
        }

        if (initialValue) {
          localStorage.setItem(storagKey, serializer(initialValue));
        }

        return initialValue;
      }

      return initialValue;
    } catch {
      return initialValue;
    }
  });

  const [state, setState] = useState<T | undefined>(() =>
    initializer.current(key),
  );

  useLayoutEffect(() => setState(initializer.current(key)), [key]);

  const set = useCallback(
    (newState: T) => {
      try {
        if (key) {
          const value = serializer(newState);

          localStorage.setItem(key, value);
        }
        setState(newState);
      } catch {
        console.log('No Setter');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key],
  );

  const remove = useCallback(() => {
    try {
      if (key) {
        localStorage.removeItem(key);
      }
      setState(undefined);
    } catch {
      console.log('No Remove');
    }
  }, [key]);

  return { state, set, remove };
};

import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { useStepperGenerateRoutePlanningStepperContext } from '../../SteppetGenerateRouteContext';
import { getSetenceByDictionary } from '../../utils';
import { StepperGenerateRouteDictionary } from '../../types';

export const dictionary: StepperGenerateRouteDictionary = {
  title: {
    generate: 'No se pudo generar el plan de viaje.',
    generateCollect: 'No se pudo generar el plan de colecta.',
    optimize: 'No se pudo optimizar la ruta.',
  },
  description: {
    generate: 'A continuación se enumeran los posibles errores.',
    generateCollect: 'A continuación se enumeran los posibles errores.',
    optimize: 'A continuación se enumeran los posibles errores.',
  },
  error: {
    generate: '',
    generateCollect: '',
    optimize: '',
  },
};

export const StepperGenerateRouteStepError = (): ReactElement => {
  const { type, showErrors } = useStepperGenerateRoutePlanningStepperContext();

  return (
    <Stack>
      <Typography component="h4" variant="h6" color="primary">
        {getSetenceByDictionary(dictionary, 'title', type)}
      </Typography>

      {showErrors && (
        <Typography component="p" variant="body2">
          {getSetenceByDictionary(dictionary, 'description', type)}
        </Typography>
      )}
    </Stack>
  );
};

export default StepperGenerateRouteStepError;

import { useState, useCallback, useEffect, useRef } from 'react';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';

import { Nullable } from '~globals/types';
import { useSignalRContext } from '~contexts/SignalRContext';
import {
  SignalRHubConnectionData,
  SignalRHubMethodCallback,
} from '~contexts/SignalRContext/types';
import { buildConnection } from '~utils/signarlR';

import { useMountedState } from './useMountedState';

interface HubConnectedProps {
  url: string;
  token: Nullable<string>;
  connectEnabled?: boolean;
}

type HubMethodState<D> = {
  loading: boolean;
  data: Nullable<D>;
  error: Nullable<Error>;
};

interface HubMethodStateReturn<D> {
  state: HubMethodState<D>;
  method: SignalRHubMethodCallback<Promise<D | undefined>>;
}

type HubConnectionData = Pick<
  SignalRHubConnectionData,
  'hubConnection' | 'hubConnectionState' | 'error'
>;

export const useHubConnection = ({
  url,
  connectEnabled = true,
  token,
}: HubConnectedProps): HubConnectionData => {
  const isMounted = useMountedState();

  const refConnection = useRef<Nullable<HubConnection>>(null);
  const [hubConnectionState, setHubConnectionState] =
    useState<HubConnectionState>(HubConnectionState.Disconnected);
  const [error, setError] = useState<Nullable<Error>>(null);

  const startConnection = useCallback(async () => {
    setHubConnectionState(HubConnectionState.Connecting);
    try {
      await refConnection.current?.start();
      setHubConnectionState(HubConnectionState.Connected);
    } catch (err) {
      setHubConnectionState(HubConnectionState.Disconnected);
      setError(err as Error);
    }
  }, []);

  const establishConnection = useCallback(async () => {
    if (!connectEnabled) return;

    const connection = buildConnection(url, token);

    connection.onreconnecting(() =>
      setHubConnectionState(HubConnectionState.Reconnecting),
    );
    connection.onreconnected(() =>
      setHubConnectionState(HubConnectionState.Connected),
    );
    connection.onclose(async () => {
      setHubConnectionState(HubConnectionState.Reconnecting);
      await startConnection();
    });

    refConnection.current = connection;

    await startConnection();
  }, [connectEnabled, url, token, startConnection]);

  const stopConnection = useCallback(async () => {
    setHubConnectionState(HubConnectionState.Disconnecting);
    try {
      await refConnection.current?.stop();
      setHubConnectionState(HubConnectionState.Disconnected);
    } catch (err) {
      setHubConnectionState(HubConnectionState.Disconnected);
      setError(err as Error);
    }
  }, []);

  useEffect(() => {
    if (isMounted()) {
      establishConnection();
    }

    return () => {
      stopConnection();
    };
  }, [isMounted, establishConnection, stopConnection]);

  return {
    hubConnection: refConnection.current,
    hubConnectionState,
    error,
  };
};

export const useClientOnMethod = (
  hubConnection: Nullable<HubConnection>,
  methodName: string,
  methodCallback: SignalRHubMethodCallback<void>,
): void => {
  useEffect(() => {
    hubConnection?.on(methodName, methodCallback);

    return () => {
      hubConnection?.off(methodName, methodCallback);
    };
  }, [hubConnection, methodCallback, methodName]);
};

export const useClientOnMethodMultiple = (
  methodName: string,
  methodPrefix: string,
  methodCallback: SignalRHubMethodCallback<void>,
): void => {
  const { addMethodListened } = useSignalRContext();

  useEffect(() => {
    addMethodListened({
      name: methodName,
      prefix: methodPrefix,
      callback: methodCallback,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useInvokeMethod = <D>(
  hubConnection: Nullable<HubConnection>,
  methodName: string,
): HubMethodStateReturn<D> => {
  const isMounted = useMountedState();
  const [state, setState] = useState<HubMethodState<D>>({
    loading: false,
    data: null,
    error: null,
  });

  const updateState = useCallback(
    (value: Partial<HubMethodState<D>>) => {
      if (isMounted()) {
        setState((prevState) => ({ ...prevState, ...value }));
      }
    },
    [isMounted],
  );

  const invokeMethod: SignalRHubMethodCallback<Promise<D | undefined>> =
    useCallback(
      async (args) => {
        updateState({ loading: true });

        try {
          if (hubConnection) {
            const data = await hubConnection.invoke<D>(methodName, ...args);

            updateState({ data, error: null });

            return data;
          } else {
            throw new Error('HubConnection is not defined');
          }
        } catch (err) {
          updateState({ data: null, error: err as Error });
        } finally {
          updateState({ loading: false });
        }
      },
      [hubConnection, methodName, updateState],
    );

  return { state, method: invokeMethod };
};

import { ChangeEvent, useCallback, useState } from 'react';
import { useField } from 'formik';
import { uniqueId, forEach, gt, isEqual, reject, size } from 'lodash';

import { FileObj } from '~globals/types';

interface ParamsUploadFile {
  nameField: string;
  multiple?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useHandleFile = ({
  nameField,
  multiple = false,
}: ParamsUploadFile) => {
  const [
    { value: currentFiles },
    { error, touched },
    { setValue, setTouched },
  ] = useField<FileObj[]>(nameField);

  const [loading, setLoading] = useState(false);

  const createObjFileBlob = useCallback(
    (file: File): FileObj => ({
      id: uniqueId(`${nameField}-`),
      file,
    }),
    [nameField],
  );

  const handleAddFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { type, files } = event.target;

      setLoading(true);

      if (isEqual(type, 'file') && gt(size(files), 0)) {
        const filesList: FileObj[] = multiple ? [...currentFiles] : [];

        forEach(files, (item) => {
          filesList.push(createObjFileBlob(item));
        });

        setValue(filesList);
      }

      setTouched(true);
      setLoading(false);
    },
    [createObjFileBlob, currentFiles, multiple, setTouched, setValue],
  );

  const handleRemoveFile = useCallback(
    (id: FileObj['id']) => {
      const filterList = reject(currentFiles, (file) => isEqual(file.id, id));

      setValue(filterList);
    },
    [currentFiles, setValue],
  );

  return {
    loading,
    files: currentFiles,
    error,
    touched,
    handleAddFile,
    handleRemoveFile,
  };
};

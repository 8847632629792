import { ReactElement } from 'react';

import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { PATHS } from '~constants/index';

import {
  classes,
  LateralMenuContainer,
  LateralMenuLogoWrapper,
  LateralMenuLogoLink,
  LateralMenuLogoMinial,
  LateralMenuLogoWhite,
  LateralMenuContentWrapper,
  LateralMenuList,
  LateralMenuListDivider,
} from './styles';
import LateralMenuListItem from './ListItem';

import { MENU_PRINCIPAL, MENU_SECONDARIES } from './items';

const LateralMenu = (): ReactElement => (
  <LateralMenuContainer>
    <LateralMenuLogoWrapper className={classes.logoWrapper}>
      <LateralMenuLogoLink to={PATHS.HOME.BASE}>
        <LateralMenuLogoMinial
          src={toAbsoluteUrl('/logo_minimal.svg')}
          alt="Routix Logo Minimal"
          className={classes.logoMinial}
        />

        <LateralMenuLogoWhite
          src={toAbsoluteUrl('/logo_bw.png')}
          alt="Routix Logo White"
          className={classes.logoWhite}
        />
      </LateralMenuLogoLink>
    </LateralMenuLogoWrapper>

    <LateralMenuContentWrapper className={classes.contentWrapper}>
      <LateralMenuList>
        {MENU_PRINCIPAL.map(({ data, permissions, subItem }) => (
          <LateralMenuListItem
            key={`menu-items-principal-${data.title}`}
            data={data}
            permissions={permissions}
            subItem={subItem}
          />
        ))}
      </LateralMenuList>

      <LateralMenuListDivider />

      <LateralMenuList>
        {MENU_SECONDARIES.map(({ data, permissions, subItem }) => (
          <LateralMenuListItem
            key={`menu-items-principal-${data.title}`}
            data={data}
            permissions={permissions}
            subItem={subItem}
          />
        ))}
      </LateralMenuList>
    </LateralMenuContentWrapper>
  </LateralMenuContainer>
);

export default LateralMenu;

import { useMemo } from 'react';
import { get, isNull } from 'lodash';
import { useTheme } from '@mui/material';

import { Nullable } from '~globals/types';
import { CollectItemStateTypes } from '~globals/types/enums';
import { useTypePartnerCompany } from '~hooks/index';
import { green, red } from '@mui/material/colors';

interface OrderPendingStateInfoItem {
  description: string;
  color: string;
}

const OrderPendingStateNew = -1;
const OrderPendingStateNotAssigned = -2;

type OrderPendingStateType =
  | typeof OrderPendingStateNew
  | typeof OrderPendingStateNotAssigned
  | CollectItemStateTypes;

type OrderPendingStateList = {
  [state in OrderPendingStateType]: OrderPendingStateInfoItem;
};

interface UseOrderPendingParams {
  carrierCompanyId: Nullable<string>;
  collectItemStateTypeId: Nullable<CollectItemStateTypes>;
}

export const useOrderPendingStateInfo = ({
  carrierCompanyId,
  collectItemStateTypeId,
}: UseOrderPendingParams): OrderPendingStateInfoItem => {
  const currentTheme = useTheme();
  const { isGiver } = useTypePartnerCompany();

  const stateList = useMemo<OrderPendingStateList>(
    () => ({
      [OrderPendingStateNotAssigned]: {
        description: 'A Asignar',
        color: currentTheme.palette.tertiary.main,
      },
      [OrderPendingStateNew]: {
        description: 'Nuevo',
        color: currentTheme.palette.primary.main,
      },
      [CollectItemStateTypes.ToValidate]: {
        description: 'Sin Colecta',
        color: currentTheme.palette.secondary.main,
      },
      [CollectItemStateTypes.ToCollect]: {
        description: 'A colectar',
        color: currentTheme.palette.secondary.main,
      },
      [CollectItemStateTypes.InCollect]: {
        description: 'En colecta',
        color: currentTheme.palette.primary.main,
      },
      [CollectItemStateTypes.Validated]: {
        description: 'Colectado',
        color: green[700],
      },
      [CollectItemStateTypes.NotValidated]: {
        description: 'No colectado',
        color: red[700],
      },
    }),
    [currentTheme],
  );

  const hasCarrier = useMemo(
    () => !isNull(carrierCompanyId),
    [carrierCompanyId],
  );

  const currentInfo = useMemo<OrderPendingStateInfoItem>(() => {
    let type: OrderPendingStateType =
      collectItemStateTypeId ?? CollectItemStateTypes.ToValidate;

    if (!hasCarrier)
      type = isGiver ? OrderPendingStateNotAssigned : OrderPendingStateNew;

    return get(stateList, type);
  }, [collectItemStateTypeId, hasCarrier, stateList, isGiver]);

  return currentInfo;
};

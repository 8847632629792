import { styled, Avatar, Typography } from '@mui/material';

export const UserInfoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ theme, size }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontSize: theme.typography.pxToRem(size),
}));

export const UserInfoAvatar = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: number }>(({ size }) => ({
  width: size * 1.7,
  height: size * 1.7,
}));

export const UserInfoTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

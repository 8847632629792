import { TransactionMoneyTypes } from '~globals/types/enums';

interface PlurarizeParams {
  singular: string;
  plural?: string;
  count?: number;
  includeCount?: boolean;
}

export const pluralize = ({
  singular,
  plural = `${singular}s`,
  count = 0,
  includeCount = false,
}: PlurarizeParams): string => {
  const sentence = count === 1 ? singular : plural;

  return includeCount ? `${count} ${sentence}` : sentence;
};

const getRandomHex = (): string =>
  Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, '0');

export const getRandomColor = (): string => {
  let hexColor: string;
  const disabledColors: string[] = ['#ffffff'];

  do {
    const hexRed = getRandomHex();
    const hexGreen = getRandomHex();
    const hexBlue = getRandomHex();

    hexColor = `#${hexRed}${hexGreen}${hexBlue}`;
  } while (disabledColors.includes(hexColor));

  return hexColor;
};

export const getCurrencyType = (moneyType: TransactionMoneyTypes): string =>
  moneyType === TransactionMoneyTypes.ARS ? 'ARS' : 'USD';

export const isEqualArrays = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};

import { ReactElement, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTag as TagIcon,
  faCalendar as CalendarIcon,
} from '@fortawesome/free-solid-svg-icons';

import {
  VehicleInfo,
  RouteStateChip,
  StopsProgressInfo,
  DistanceInfo,
  DescriptionWithIconBox,
  UserInfo,
} from '~components/index';
import { isRouteStopOrderComplete } from '~utils/route';
import { replaceParamsPath } from '~utils/router';
import { PATHS } from '~constants/index';
import { useUtcOffset } from '~hooks/index';

import { ActiveRouteCardProps } from './types';
import {
  ActiveRouteCardContainer,
  ActiveRouteCardContent,
  ActiveRouteCardVehicleInfoBox,
  ActiveRouteCardVehicleInfoBoxTitleContainer,
  ActiveRouteCardRouteInfoBox,
  ActiveRouteCardRouteInfoBoxRow,
} from './styles';

const ActiveRouteCard = ({
  route,
  onClick,
}: ActiveRouteCardProps): ReactElement => {
  const formatterDateUtcOffset = useUtcOffset();

  const pathRedirect = useMemo(
    () =>
      replaceParamsPath(PATHS.ROUTES.VEHICLE_PLANNING, {
        ':apiTransactionId': route.apiTransactionId,
        ':routeId': route.routeId,
      }),
    [route.apiTransactionId, route.routeId],
  );

  const fullNameDriver = useMemo((): string => {
    const driver = route.driver;

    if (!driver) {
      return 'Sin Asignar';
    }

    return `${driver.lastName}, ${driver.firstName}`;
  }, [route.driver]);

  const stopsProgressData = useMemo(() => {
    return route.routeItems.reduce(
      (acc, routeItem) => {
        if (isRouteStopOrderComplete(routeItem.routeItemStateTypeId))
          acc.completed += 1;

        acc.total += 1;

        return acc;
      },
      { total: 0, completed: 0 },
    );
  }, [route.routeItems]);

  return (
    <ActiveRouteCardContainer
      variant="outlined"
      to={pathRedirect}
      onClick={onClick}
    >
      <ActiveRouteCardContent>
        <ActiveRouteCardVehicleInfoBox>
          <ActiveRouteCardVehicleInfoBoxTitleContainer>
            <VehicleInfo
              vehicle={route.vehicle.name}
              fontSize={14}
              color={route.vehicle.color}
            />
          </ActiveRouteCardVehicleInfoBoxTitleContainer>

          <RouteStateChip stateId={route.routeStateTypeId} size="small" />
        </ActiveRouteCardVehicleInfoBox>

        <ActiveRouteCardRouteInfoBox>
          <ActiveRouteCardRouteInfoBoxRow>
            <DescriptionWithIconBox
              icon={<FontAwesomeIcon icon={TagIcon} />}
              description={`#${route.routeCode.toUpperCase()}`}
              size={11}
              fontWeight={500}
            />

            <DistanceInfo distance={route.totalKm} fontSize={11} />

            <DescriptionWithIconBox
              icon={<FontAwesomeIcon icon={CalendarIcon} />}
              description={formatterDateUtcOffset(
                route.scheduledDateTime,
                'DD/MM/YYYY HH:mm',
              )}
              size={11}
            />
          </ActiveRouteCardRouteInfoBoxRow>

          <ActiveRouteCardRouteInfoBoxRow justifyContent="space-between">
            <UserInfo
              name={fullNameDriver}
              urlAvatar={route.driver?.avatarFile?.url}
              fontSize={11}
            />

            <StopsProgressInfo
              max={stopsProgressData.total}
              value={stopsProgressData.completed}
              enabledIcon
              fontSize={11}
            />
          </ActiveRouteCardRouteInfoBoxRow>
        </ActiveRouteCardRouteInfoBox>
      </ActiveRouteCardContent>
    </ActiveRouteCardContainer>
  );
};

export default ActiveRouteCard;

import * as Yup from 'yup';

import { FileObj } from '~globals/types';
import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  FILES: 'files',
} as const;

export const initialValues = {
  [FIELDS_NAME.FILES]: [] as FileObj[],
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.FILES]: Yup.array()
    .of(Yup.mixed())
    .min(1, VALIDATIONS_ERROR.REQUIRED),
});

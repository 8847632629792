import { styled, LinearProgress, linearProgressClasses } from '@mui/material';

export const SubscriptionAvailableRoutesStateInfoRoot = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
});

export const SubscriptionAvailableRoutesStateInfoHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontSize: 9,
  justifyContent: 'space-between',
});

export const SubscriptionAvailableRoutesStateInfoProgress = styled(
  LinearProgress,
  { shouldForwardProp: (prop) => prop !== 'customColor' },
)<{ customColor: string }>(({ customColor }) => ({
  height: 5,
  borderRadius: 5,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: customColor,
  },
}));

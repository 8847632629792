import { FC, createContext, useContext, useState } from 'react';

import { Nullable } from '~globals/types';
import { PartnerCompanyInvitationData } from '~services/partnerCompany/types';

import { DialogInvitationPartnerCompanyProps } from './types';

export type DialogInvitationPartnerCompanyContextPanelsId =
  | 'validated'
  | 'confirmation';

interface DialogInvitationPartnerCompanyContextValue
  extends DialogInvitationPartnerCompanyProps {
  activeTab: DialogInvitationPartnerCompanyContextPanelsId;
  setActiveTab: (newTab: DialogInvitationPartnerCompanyContextPanelsId) => void;
  validatedData: Nullable<PartnerCompanyInvitationData>;
  setValidatedData: (newData: Nullable<PartnerCompanyInvitationData>) => void;
}

const DialogInvitationPartnerCompanyContext =
  createContext<DialogInvitationPartnerCompanyContextValue>({
    open: false,
    onClose: () => {},
    activeTab: 'validated',
    setActiveTab: () => {},
    validatedData: null,
    setValidatedData: () => {},
    onValidated: undefined,
    onConfirmation: undefined,
    companyName: undefined,
    defaultInvitationCode: undefined,
    onOpenDialogAssociateCompany: undefined,
  });

export const useDialogInvitationPartnerCompanyContext =
  (): DialogInvitationPartnerCompanyContextValue =>
    useContext(DialogInvitationPartnerCompanyContext);

const DialogInvitationPartnerCompanyContexProvider: FC<
  DialogInvitationPartnerCompanyProps
> = ({
  open,
  onClose,
  onValidated,
  onConfirmation,
  companyName,
  defaultInvitationCode,
  onOpenDialogAssociateCompany,
  children,
}) => {
  const [activeTab, setActiveTab] =
    useState<DialogInvitationPartnerCompanyContextPanelsId>('validated');

  const [validatedData, setValidatedData] =
    useState<Nullable<PartnerCompanyInvitationData>>(null);

  return (
    <DialogInvitationPartnerCompanyContext.Provider
      value={{
        open,
        onClose,
        activeTab,
        setActiveTab,
        validatedData,
        setValidatedData,
        onValidated,
        onConfirmation,
        companyName,
        defaultInvitationCode,
        onOpenDialogAssociateCompany,
      }}
    >
      {children}
    </DialogInvitationPartnerCompanyContext.Provider>
  );
};

export default DialogInvitationPartnerCompanyContexProvider;

import { ReactElement, memo } from 'react';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useItemTaskStatusInfo } from '~hooks/index';
import { Nullable } from '~globals/types';
import { DescriptionWithIconBoxTooltipIcon } from '~components/DescriptionWithIconBox';

import { ItemTaskStatusChipProps } from './types';

const ItemTaskStatusChip = ({
  statusId,
  size = 16,
}: ItemTaskStatusChipProps): Nullable<ReactElement> => {
  const { icon, color, tooltipMessage } = useItemTaskStatusInfo(statusId);

  if (!icon || !color || !tooltipMessage) return null;

  return (
    <Tooltip title={tooltipMessage} arrow>
      <DescriptionWithIconBoxTooltipIcon>
        <FontAwesomeIcon icon={icon} color={color} fontSize={size} />
      </DescriptionWithIconBoxTooltipIcon>
    </Tooltip>
  );
};

export default memo(ItemTaskStatusChip);

import {
  styled,
  Tooltip,
  TooltipProps,
  listItemIconClasses,
  listItemTextClasses,
  Chip,
  chipClasses,
} from '@mui/material';
import { CSSProperties } from 'react';

const fontSizeTooltip = 11;

export const SkillsListInfoTooltip = styled(
  ({ className, ...restProps }: TooltipProps) => (
    <Tooltip {...restProps} classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${listItemIconClasses.root}`]: {
    minWidth: 'auto',
    marginRight: theme.spacing(1),
    color: 'inherit',
    fontSize: theme.typography.pxToRem(fontSizeTooltip - 3),
  },

  [`& .${listItemTextClasses.root}`]: {
    margin: 0,
    fontSize: theme.typography.pxToRem(fontSizeTooltip),
  },
}));

export const SkillsListInfoChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{
  ownerState: { fontSize: number; fontWeight?: CSSProperties['fontWeight'] };
}>(({ theme, ownerState }) => ({
  fontSize: ownerState.fontSize,
  fontWeight: ownerState.fontWeight,

  [`& .${chipClasses.label}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

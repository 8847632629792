import { ProviderProps, ReactElement, createContext, useContext } from 'react';

import { TableContextValue, TableSelectable, TableValidRow } from './types';

export const defaultSelectableOptions: Required<
  TableSelectable<TableValidRow>
> = {
  mode: 'checkbox',
  clickToSelect: false,
  hideSelectAll: false,
  selectedIds: [],
  nonSelectableIds: [],
  nonSelectableClasses: 'table-row-disabled',
  onSelect: () => {},
  onSelectAll: () => {},
};

const TableContext = createContext<TableContextValue>({
  columns: [],
  data: [],
  classes: undefined,
  selectable: {
    enabled: false,
    ...defaultSelectableOptions,
  },
  extractorKeyValue: '',
});

export const useTableContext = (): TableContextValue =>
  useContext(TableContext);

const TableProvider = ({
  value,
  children,
}: ProviderProps<TableContextValue>): ReactElement => (
  <TableContext.Provider value={value}>{children}</TableContext.Provider>
);

export default TableProvider;

import { FormikErrors, FormikTouched } from 'formik';

import { PropertyPath, has, get } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Touched = FormikTouched<Record<string, any>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Errors = FormikErrors<Record<string, any>>;

export const hasError = <T extends Touched, E extends Errors>(
  touched: T,
  errors: E,
  pathField: PropertyPath,
): boolean => has(touched, pathField) && has(errors, pathField);

export const getHelperText = <T extends Touched, E extends Errors>(
  touched: T,
  errors: E,
  pathField: PropertyPath,
  defaultHelper = '',
): string =>
  hasError(touched, errors, pathField) ? get(errors, pathField) : defaultHelper;

import moment from 'moment';

import { PlanFeaturesTypes } from '~globals/types/enums';

export const FEATURES_AVAILABLES_TO_DATES: Record<
  `${PlanFeaturesTypes}`,
  Date | undefined
> = {
  Mgm_CrossCompanyModule: undefined,
  Mgm_IntegrationModule: undefined,
  Mgm_GenerateTickets: moment('2024-06-15').toDate(),
  Mgm_ItemTask: undefined,
  Mgm_SecurityTools: undefined,
  Mgm_ExternalGPS: undefined,
  Mgm_APIAccess: undefined,
  Mgm_WhiteLabel: undefined,
  Mgm_ItemMap: moment('2024-08-16').toDate(),
  Mgm_ChangeOrder: undefined,
  Tracking_PortalModule: undefined,
  Tracking_EPOD: undefined,
  Report_Advanced: undefined,
  Report_CustomSatisfaction: undefined,
  Report_Heatmap: undefined,
  Report_CrossCompany: undefined,
  Notif_CustomSatisfaction: undefined,
  Notif_Email: undefined,
  Notif_Whatsapp: undefined,
  Notif_WhatappBusiness: undefined,
  Limit_NotPro: undefined,
  Limit_Paid: undefined,
};

export const LIMIT_NOT_PRO_VERSION_DATES: Record<string, Date> = {
  '1.18.2': moment('2024-10-15').toDate(),
};

export const LIMIT_PAID_VERSION_DATES: Record<string, Date> = {
  '1.18.2': moment('2024-10-15').toDate(),
};

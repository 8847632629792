import { useState, useCallback } from 'react';
import writeText from 'copy-to-clipboard';

import { Nullable } from '~globals/types';
import { logDevelopmentError } from '~utils/env';

import { useMountedState } from './useMountedState';

export interface CopyToClipboardState {
  noUserInteraction: boolean;
  copied: boolean;
  value: Nullable<string>;
  error: Nullable<Error>;
}

export const useCopyToClipboard = (): [
  CopyToClipboardState,
  (value: string) => void,
] => {
  const isMounted = useMountedState();
  const [state, setState] = useState<CopyToClipboardState>({
    noUserInteraction: true,
    copied: false,
    value: null,
    error: null,
  });

  const copyToClipboard = useCallback(
    (value: string) => {
      if (!isMounted()) return;

      let noUserInteraction: CopyToClipboardState['noUserInteraction'] = true;

      try {
        if (typeof value !== 'string') {
          const error = new Error(
            `Cannot copy typeof ${typeof value} to clipboard, must be a string`,
          );

          logDevelopmentError(error);

          setState({
            noUserInteraction: true,
            copied: false,
            value,
            error,
          });

          return;
        }

        if (value === '') {
          const error = new Error('Cannot copy empty value to clipboard');

          logDevelopmentError(error);

          setState({
            noUserInteraction: true,
            copied: false,
            value,
            error,
          });

          return;
        }

        noUserInteraction = writeText(value);

        setState({
          noUserInteraction,
          copied: true,
          value,
          error: null,
        });
      } catch (err) {
        const error = err as Error;

        setState({
          noUserInteraction,
          copied: false,
          value,
          error,
        });
      } finally {
        setTimeout(() => {
          setState({
            noUserInteraction,
            copied: false,
            value: null,
            error: null,
          });
        }, 2000);
      }
    },
    [isMounted],
  );

  return [state, copyToClipboard];
};

import { useMemo } from 'react';
import { get } from 'lodash';
import {
  faCircleCheck as SuccesCircleIcon,
  faCircleInfo as ErrorCircleIcon,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { red, green } from '@mui/material/colors';

import { ItemCollectStatusTypes } from '~globals/types/enums';
import { Nullable } from '~globals/types';

interface ItemCollectStatusInfoItem {
  icon: Nullable<IconDefinition>;
  color: Nullable<string>;
  tooltipMessage: Nullable<string>;
}

type ItemCollectStatusList = {
  [state in ItemCollectStatusTypes]: ItemCollectStatusInfoItem;
};

export const useItemCollectStatusInfo = (
  statusId: ItemCollectStatusTypes,
): ItemCollectStatusInfoItem => {
  const stateList = useMemo<ItemCollectStatusList>(
    () => ({
      [ItemCollectStatusTypes.Pending]: {
        icon: null,
        color: null,
        tooltipMessage: null,
      },
      [ItemCollectStatusTypes.Collected]: {
        icon: SuccesCircleIcon,
        color: green['500'],
        tooltipMessage: 'Colectado',
      },
      [ItemCollectStatusTypes.NotCollected]: {
        icon: ErrorCircleIcon,
        color: red['500'],
        tooltipMessage: 'No colectado',
      },
    }),
    [],
  );

  const currentInfo = useMemo<ItemCollectStatusInfoItem>(
    () => get(stateList, statusId),
    [statusId, stateList],
  );

  return currentInfo;
};

import { ReactElement, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useItemTypeInfo } from '~hooks/index';

import { OperationInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const OperationInfo = ({
  typeId,
  description,
  fontSize = 14,
  iconPosition = 'left',
  fontWeight,
}: OperationInfoProps): ReactElement => {
  const { icon, color, tooltipMessage } = useItemTypeInfo(typeId);

  return (
    <DescriptionWithIconBox
      icon={<FontAwesomeIcon icon={icon} />}
      iconTooltip={tooltipMessage}
      iconPosition={iconPosition}
      iconColor={color}
      description={description}
      size={fontSize}
      fontWeight={fontWeight}
    />
  );
};

export default memo(OperationInfo);

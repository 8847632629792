import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { TimeWindowInfo } from '~components/index';

import { DialogDetailsOrderContentRowItem } from '../../styles';
import { useDialogDetailsOrderContextContext } from '../../DialogDetailsOrderContext';

const DialogDetailsOrderData = (): ReactElement => {
  const { data } = useDialogDetailsOrderContextContext();

  return (
    <Stack spacing={2}>
      {data?.trackingCode && (
        <DialogDetailsOrderContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Nro. de Tracking
          </Typography>

          <Typography variant="body2">{data.trackingCode}</Typography>
        </DialogDetailsOrderContentRowItem>
      )}

      <DialogDetailsOrderContentRowItem>
        <Typography variant="body2" fontWeight="bold">
          Dirección
        </Typography>

        <Typography variant="body2">{data?.address}</Typography>
      </DialogDetailsOrderContentRowItem>

      <DialogDetailsOrderContentRowItem>
        <Typography variant="body2" fontWeight="bold">
          Ventana horaria
        </Typography>

        <TimeWindowInfo
          allDay={data?.timeWindowAllDay ?? false}
          windowFrom1={data?.timeWindowFrom1 ?? null}
          windowFrom2={data?.timeWindowFrom2 ?? null}
          windowTo1={data?.timeWindowTo1 ?? null}
          windowTo2={data?.timeWindowTo2 ?? null}
        />
      </DialogDetailsOrderContentRowItem>

      {data?.contactName && (
        <DialogDetailsOrderContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Recibe
          </Typography>

          <Typography variant="body2">{data.contactName}</Typography>

          {data.contactIdentification && (
            <Typography variant="body2">
              {`Documento: ${data.contactIdentification}`}
            </Typography>
          )}

          {(data.contactPhone || data.contactMobile) && (
            <Typography variant="body2">
              {`Teléfono de contacto: ${
                data.contactPhone ?? data.contactMobile
              }`}
            </Typography>
          )}

          {data.contactEmail && (
            <Typography variant="body2">
              {`Email: ${data.contactEmail}`}
            </Typography>
          )}
        </DialogDetailsOrderContentRowItem>
      )}

      {data?.requestPaymentOnDestiny && (
        <DialogDetailsOrderContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Metodo de pago
          </Typography>

          <Typography variant="body2">Pago contraentrega</Typography>

          <Typography variant="body2">
            {`Monto a abonar: ${data.cashOnDeliveryAmount ?? 'N/A'}`}
          </Typography>
        </DialogDetailsOrderContentRowItem>
      )}

      {data?.deliveryNotes && (
        <DialogDetailsOrderContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Comentarios
          </Typography>

          <Typography variant="body2">{data.deliveryNotes}</Typography>
        </DialogDetailsOrderContentRowItem>
      )}

      {data?.invoice && (
        <DialogDetailsOrderContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Factura
          </Typography>

          <Typography variant="body2">{data.invoice}</Typography>
        </DialogDetailsOrderContentRowItem>
      )}

      {data?.shipment && (
        <DialogDetailsOrderContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Remito
          </Typography>

          <Typography variant="body2">{data.shipment}</Typography>
        </DialogDetailsOrderContentRowItem>
      )}
    </Stack>
  );
};

export default DialogDetailsOrderData;

import { ReactElement, ElementType } from 'react';
import { Typography, Button, Link, Stack } from '@mui/material';
import { TabContext } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
import { Close as CloseIcon } from '@mui/icons-material';

import {
  DialogImportFromAssociatedCompanyContextPanelsId,
  useDialogImportFromAssociatedCompanyContext,
} from './DialogImportFromAssociatedCompanyContext';
import {
  DialogImportFromAssociatedCompanyNotAssociatedCompanies,
  DialogImportFromAssociatedCompanyImporter,
} from './Tabs';
import {
  DialogImportFromAssociatedCompanyHeader,
  DialogImportFromAssociatedCompanyHeaderCloseIcon,
  DialogImportFromAssociatedCompanyContentContainer,
  DialogImportFromAssociatedCompanyTabPanel,
  DialogImportFromAssociatedCompanyActions,
} from './styles';

interface DialogImportFromAssociatedCompanyTabPanelItem {
  id: DialogImportFromAssociatedCompanyContextPanelsId;
  panel: ElementType;
}

const TABS_PANELS: DialogImportFromAssociatedCompanyTabPanelItem[] = [
  {
    id: 'notAssociatedCompanies',
    panel: DialogImportFromAssociatedCompanyNotAssociatedCompanies,
  },
  {
    id: 'importer',
    panel: DialogImportFromAssociatedCompanyImporter,
  },
];

const DialogImportFromAssociatedCompanyContent = (): ReactElement => {
  const {
    onClose,
    activeTab,
    selectedOrders: { selectedRowsIds: selectedOrdersIds },
    importRequest: { executeImport, loading: loadingImport },
  } = useDialogImportFromAssociatedCompanyContext();

  return (
    <>
      <DialogImportFromAssociatedCompanyHeader>
        <Typography component="h2" variant="h6">
          Importar desde empresa asociada
        </Typography>

        <DialogImportFromAssociatedCompanyHeaderCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogImportFromAssociatedCompanyHeaderCloseIcon>
      </DialogImportFromAssociatedCompanyHeader>

      <DialogImportFromAssociatedCompanyContentContainer dividers>
        <TabContext value={activeTab}>
          {TABS_PANELS.map(({ id, panel: Panel }) => (
            <DialogImportFromAssociatedCompanyTabPanel
              key={`dialog-import-orders-from-associated-company-panel-${id}`}
              value={id}
            >
              <Panel />
            </DialogImportFromAssociatedCompanyTabPanel>
          ))}
        </TabContext>
      </DialogImportFromAssociatedCompanyContentContainer>

      <DialogImportFromAssociatedCompanyActions>
        <Typography variant="caption" component="span">
          <Link
            href="https://routix.tawk.help/article/rutas-de-colecta-y-distribución-de-terceros"
            target="_blank"
            underline="hover"
            fontWeight="bold"
          >
            Haz clic aquí
          </Link>{' '}
          para tener ayuda sobre la colecta manual.
        </Typography>

        <Stack spacing={2} direction="row">
          <Button color="secondary" onClick={onClose}>
            Cerrar
          </Button>

          {activeTab === 'importer' && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={executeImport}
              loading={loadingImport}
              disabled={selectedOrdersIds.length < 1}
            >
              Marcar como colectados
            </LoadingButton>
          )}
        </Stack>
      </DialogImportFromAssociatedCompanyActions>
    </>
  );
};

export default DialogImportFromAssociatedCompanyContent;

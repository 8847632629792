import { ReactElement } from 'react';
import { Typography, Link } from '@mui/material';
import moment from 'moment';

// eslint-disable-next-line import/extensions
import pck from '../../../../package.json';

import { FooterContainer, FooterNameContainer } from './styles';

const Footer = (): ReactElement => (
  <FooterContainer>
    <FooterNameContainer>
      <Typography component="span" color="text.secondary" variant="body2">
        {moment().get('year')}
      </Typography>

      <Link
        href="https://www.antorchadigital.com/"
        target="_blank"
        rel="noopener noreferrer"
        variant="body2"
        color="text.primary"
        underline="hover"
      >
        Antorcha Digital
      </Link>
    </FooterNameContainer>

    <Typography component="span" color="text.secondary" variant="body2">
      {`Versión ${pck.version}`}
    </Typography>
  </FooterContainer>
);

export default Footer;

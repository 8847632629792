import { ReactElement } from 'react';
import { Button, Stack, Typography, Grid } from '@mui/material';
import { amber } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown as CrownIcon } from '@fortawesome/free-solid-svg-icons';

import { SCHEDULE_DEMO_URL } from '~constants/commons';

import { CustomPlanInfoCardProps } from './types';
import { CustomPlanInfoCardRoot, CustomPlanInfoCardCheckIcon } from './styles';

const SubscriptionCustomPlanInfoCard = ({
  name,
  features,
}: CustomPlanInfoCardProps): ReactElement => (
  <CustomPlanInfoCardRoot>
    <Stack spacing={1} flex={1}>
      <Stack spacing={1} direction="row" alignItems="baseline">
        <Typography variant="h4" fontWeight="bold" fontSize={26}>
          {name}
        </Typography>

        <FontAwesomeIcon icon={CrownIcon} color={amber[500]} fontSize={20} />
      </Stack>

      <Typography variant="caption" fontWeight="bold">
        Un plan diseñado para satisfacer todos los requerimientos de tu empresa
      </Typography>

      <Stack maxWidth={500}>
        <Grid container spacing={0}>
          {features.map((feature, index) => (
            <Grid
              key={`custom-plan-info-card-${name}-feature-${index}`}
              item
              xs={6}
              alignItems="flex-start"
            >
              <CustomPlanInfoCardCheckIcon />

              <Typography variant="caption">{feature}</Typography>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>

    <Stack>
      <Button
        variant="outlined"
        color="primary"
        component="a"
        href={SCHEDULE_DEMO_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Contactar al equipo de ventas
      </Button>
    </Stack>
  </CustomPlanInfoCardRoot>
);

export default SubscriptionCustomPlanInfoCard;

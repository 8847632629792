import { styled, CardContent, Box } from '@mui/material';

export const FleetStatisticsWidgetCardContent = styled(CardContent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const FleetStatisticsContentStatistics = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  justifyContent: 'center',
}));

export const FleetStatisticsItemWithImageContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
  }),
);

export const FleetStatisticsItemWithImageContainerImg = styled('img')({
  width: 90,
  height: 80,
});

import * as Yup from 'yup';

import { RouteCalculationModeType } from '~globals/types/enums';
import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  NAME: 'name',
  SCHEDULE_DATE: 'scheduleDate',
  STARTING_POINT: 'startingPoint',
  ARRIVAL_POINT: 'arrivalPoint',
  CALCULATION_MODE: 'calculationMode',
  LOAD_BALACING_VEHICLES: 'loadingBalancingVehicles',
  MULTIPLE_ROUTES_PER_VEHICLE: 'multipleRoutesPerVehicle',
} as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = ({ scheduleDate }: { scheduleDate: Date }) => ({
  [FIELDS_NAME.NAME]: '',
  [FIELDS_NAME.SCHEDULE_DATE]: scheduleDate as Date | null,
  [FIELDS_NAME.STARTING_POINT]: '',
  [FIELDS_NAME.ARRIVAL_POINT]: '',
  [FIELDS_NAME.CALCULATION_MODE]: String(RouteCalculationModeType.Faster),
  [FIELDS_NAME.LOAD_BALACING_VEHICLES]: false,
  [FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE]: false,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validationSchema = (isRouteCollect: boolean) =>
  Yup.object().shape({
    [FIELDS_NAME.NAME]: Yup.string().notRequired(),
    [FIELDS_NAME.SCHEDULE_DATE]: Yup.date()
      .nullable()
      .typeError(VALIDATIONS_ERROR.INVALID_DATE)
      .required(VALIDATIONS_ERROR.REQUIRED),
    [FIELDS_NAME.STARTING_POINT]: Yup.string().required(
      VALIDATIONS_ERROR.REQUIRED,
    ),
    [FIELDS_NAME.ARRIVAL_POINT]: isRouteCollect
      ? Yup.string().required(VALIDATIONS_ERROR.REQUIRED)
      : Yup.string().notRequired(),
    [FIELDS_NAME.CALCULATION_MODE]: Yup.string().required(
      VALIDATIONS_ERROR.REQUIRED,
    ),
    [FIELDS_NAME.LOAD_BALACING_VEHICLES]: Yup.boolean(),
    [FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE]: Yup.boolean(),
  });

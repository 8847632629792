import { ReactElement, ElementType, useCallback } from 'react';
import { DialogTitle, Button } from '@mui/material';
import { TabContext } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import moment from 'moment';

import { formatterDate, getItemValue } from '~utils/index';
import { IntegrationDeliveryTypes } from '~globals/types/enums';

import { FIELDS_NAME, getInitialValues, getValidationSchema } from './utils';
import { DialogImportOrdersFromIntegrationProps } from './types';
import {
  DialogImportOrdersFromIntegrationContextPanelsId,
  useDialogImportOrdersFromIntegrationContext,
} from './DialogImportOrdersFromIntegrationContext';
import {
  DialogImportOrdersFromIntegrationNotIntegrations,
  DialogImportOrdersFromIntegrationPresentation,
  DialogImportOrdersFromIntegrationProgress,
} from './Tabs';
import {
  DialogImportOrdersFromIntegrationContentContainer,
  DialogImportOrdersFromIntegrationTabPanel,
  DialogImportOrdersFromIntegrationActions,
} from './styles';

interface DialogImportOrdersFromIntegrationTabPanelItem {
  id: DialogImportOrdersFromIntegrationContextPanelsId;
  panel: ElementType;
}

const TABS_PANELS: DialogImportOrdersFromIntegrationTabPanelItem[] = [
  {
    id: 'notIntegrations',
    panel: DialogImportOrdersFromIntegrationNotIntegrations,
  },
  { id: 'presentation', panel: DialogImportOrdersFromIntegrationPresentation },
  { id: 'progress', panel: DialogImportOrdersFromIntegrationProgress },
];

const DialogImportOrdersFromIntegrationContent = ({
  onClose,
}: Pick<DialogImportOrdersFromIntegrationProps, 'onClose'>): ReactElement => {
  const {
    activeTab,
    currentIntegrations: { isPresentMeli },
    importerRequest: {
      executeImport: handleImport,
      loading: loadingExecuteImportItems,
    },
  } = useDialogImportOrdersFromIntegrationContext();

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      await handleImport({
        dateFrom: getItemValue(values, FIELDS_NAME.DATE_FROM, (val) =>
          formatterDate(val, {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          }),
        ),
        dateTo: getItemValue(values, FIELDS_NAME.DATE_TO, (val) =>
          formatterDate(val, {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          }),
        ),
        deliveryTypes: getItemValue(
          values,
          FIELDS_NAME.DELIVERY_TYPE,
          (val): IntegrationDeliveryTypes[] =>
            isPresentMeli ? [val as IntegrationDeliveryTypes] : [],
          [] as IntegrationDeliveryTypes[],
        ),
      });

      setSubmitting(false);
    },
    [handleImport, isPresentMeli],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(isPresentMeli),
    validationSchema: getValidationSchema(isPresentMeli),
    onSubmit,
  });

  const { submitForm, isSubmitting } = formikBag;

  return (
    <FormikProvider value={formikBag}>
      <DialogTitle>Importar Pedidos</DialogTitle>

      <DialogImportOrdersFromIntegrationContentContainer dividers>
        <TabContext value={activeTab}>
          {TABS_PANELS.map(({ id, panel: Panel }) => (
            <DialogImportOrdersFromIntegrationTabPanel
              key={`dialog-import-orders-from-integration-panel-${id}`}
              value={id}
            >
              <Panel />
            </DialogImportOrdersFromIntegrationTabPanel>
          ))}
        </TabContext>
      </DialogImportOrdersFromIntegrationContentContainer>

      <DialogImportOrdersFromIntegrationActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        {activeTab === 'presentation' && (
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={submitForm}
            loading={loadingExecuteImportItems || isSubmitting}
          >
            Importar
          </LoadingButton>
        )}
      </DialogImportOrdersFromIntegrationActions>
    </FormikProvider>
  );
};

export default DialogImportOrdersFromIntegrationContent;

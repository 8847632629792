import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { FileUpload } from '~components/index';
import { hasError } from '~utils/formHelpers';
import { useLazyRequest } from '~hooks/index';
import { uploadFiles } from '~services/files';
import { FileResponse } from '~services/files/types';
import { associateFilesToItem } from '~services/item';
import { DataResultsResponse } from '~globals/types';

import { DialogUploadItemFilesProps } from './types';
import {
  DialogUploadItemFilesContainer,
  DialogUploadItemFilesActions,
} from './styles';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const DialogUploadItemFiles = ({
  open,
  onClose,
  onSubmitSuccess,
  accept = 'image/*, application/pdf',
  multiple = false,
  orderId,
}: DialogUploadItemFilesProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingAssociateFilesToItem, , executeAssociateFilesToItem] =
    useLazyRequest({
      request: associateFilesToItem,
      withPostSuccess: () => {
        enqueueSnackbar('Archivo/s subido/s correctamente', {
          variant: 'success',
        });

        onSubmitSuccess();

        onClose();
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'No se pudo subir el/los archivo/s, intente nuevamente',
          { variant: 'error' },
        );
      },
    });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      try {
        const { file, notes } = values;
        const { data: dataUploadFile } = await uploadFiles(file);

        const {
          data: { results },
        } = dataUploadFile as DataResultsResponse<FileResponse[]>;

        await executeAssociateFilesToItem({
          itemId: orderId,
          fileNotes: [{ fileId: results[0].fileId, notes }],
        });
      } catch (error) {
        enqueueSnackbar(
          'No se pudo subir el/los archivo/s, intente nuevamente',
          { variant: 'error' },
        );
      } finally {
        setSubmitting(false);
      }
    },
    [executeAssociateFilesToItem, orderId, enqueueSnackbar],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    resetForm,
    submitForm,
    isSubmitting,
    dirty,
    isValid,
    values,
    touched,
    errors,
    getFieldProps,
  } = formikBag;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const disabledSubmit = useMemo(
    () => !dirty || !isValid || !values[FIELDS_NAME.FILE],
    [dirty, isValid, values],
  );

  return (
    <FormikProvider value={formikBag}>
      <DialogUploadItemFilesContainer
        open={open}
        onClose={onClose}
        maxWidth="xs"
      >
        <DialogTitle>Agregar archivos</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={2}>
            <FileUpload
              name={FIELDS_NAME.FILE}
              label="SUBIR ARCHIVOS"
              accept={accept}
              multiple={multiple}
            />

            <TextField
              label="Notas"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.NOTES)}
              {...getFieldProps(FIELDS_NAME.NOTES)}
            />
          </Stack>
        </DialogContent>

        <DialogUploadItemFilesActions>
          <Button color="secondary" onClick={onClose}>
            Cerrar
          </Button>

          <LoadingButton
            variant="contained"
            color="primary"
            onClick={submitForm}
            loading={loadingAssociateFilesToItem || isSubmitting}
            disabled={disabledSubmit}
          >
            Agregar
          </LoadingButton>
        </DialogUploadItemFilesActions>
      </DialogUploadItemFilesContainer>
    </FormikProvider>
  );
};

export default DialogUploadItemFiles;

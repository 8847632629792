import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { PublicLayoutProps } from './types';
import { PublicContainer, PublicLogo, PublicContent } from './styles';

const PublicLayout = ({
  enabledLogo = true,
}: PublicLayoutProps): ReactElement => (
  <PublicContainer>
    {enabledLogo && (
      <PublicLogo src={toAbsoluteUrl('/logo.svg')} alt="Routix Logo" />
    )}

    <PublicContent>
      <Outlet />
    </PublicContent>
  </PublicContainer>
);

export default PublicLayout;

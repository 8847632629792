import { Button, Stack, styled, Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(3),
}));

export const SubtitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.body2,
}));

export const OptionsContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

export const PrimaryOptionButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  height: theme.spacing(25),
  width: theme.spacing(40),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
}));

export const AlternativeOptionButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(25),
  width: theme.spacing(40),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#f6f2fc',
  borderColor: '#f6f2fc',
}));

export const ButtonImage = styled('img')(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  objectFit: 'contain',
}));

export const ButtonTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  ...theme.typography.body2,
  fontWeight: theme.typography.fontWeightBold,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  textWrap: 'balance',
}));

export const ButtonSubtitleText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  ...theme.typography.caption,
  textWrap: 'balance',
}));

import {
  IntegrationProvidersTypes,
  IntegrationDeliveryTypes,
} from '~globals/types/enums';
import { SelectOption } from '~globals/types';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

export const integrationsProviders = [
  {
    type: IntegrationProvidersTypes.MercadoLibre,
    name: 'MercadoLibre',
    logo: {
      url: toAbsoluteUrl('/images/mp_logo.png'),
      alt: 'MercadoLibre Logo',
    },
    logoSmall: {
      url: toAbsoluteUrl('/images/mp_small_logo.png'),
      alt: 'MercadoLibre Logo Small',
    },
    imgPrompt: {
      url: toAbsoluteUrl('/images/mp_prompt.png'),
      alt: 'Autorize Routix on MercadoLibre',
    },
  },
  {
    type: IntegrationProvidersTypes.TiendaNube,
    name: 'Tienda Nube',
    logo: {
      url: toAbsoluteUrl('/images/tn_logo.png'),
      alt: 'Tienda Nube Logo',
    },
    logoSmall: {
      url: toAbsoluteUrl('/images/tn_small_logo.png'),
      alt: 'Tienda Nube Logo Small',
    },
    imgPrompt: {
      url: toAbsoluteUrl('/images/tn_prompt.png'),
      alt: 'Autorize Routix on Tienda Nube',
    },
  },
  {
    type: IntegrationProvidersTypes.WooCommerce,
    name: 'WooCommerce',
    logo: {
      url: toAbsoluteUrl('/images/woo_logo.png'),
      alt: 'WooCommerce Logo',
    },
    logoSmall: {
      url: toAbsoluteUrl('/images/woo_small_logo.png'),
      alt: 'WooCommerce Logo Small',
    },
    imgPrompt: {
      url: toAbsoluteUrl('/images/woo_prompt.png'),
      alt: 'Autorize Routix on WooCommerce',
    },
  },
];

export const MERCADO_LIBRE_SHIPPING_OPTIONS: SelectOption[] = [
  {
    label: 'Mercado Envíos',
    value: IntegrationDeliveryTypes.DropOff,
  },
  {
    label: 'Mercado Envíos Places',
    value: IntegrationDeliveryTypes.XdDropOff,
  },
  {
    label: 'Mercado Envíos Colecta',
    value: IntegrationDeliveryTypes.CrossDocking,
  },
  {
    label: 'Mercado Envíos Flex',
    value: IntegrationDeliveryTypes.SelfService,
  },
  {
    label: 'Mercado Envíos Full',
    value: IntegrationDeliveryTypes.Fulfillment,
  },
  {
    label: 'Otros envíos',
    value: IntegrationDeliveryTypes.Custom,
  },
];

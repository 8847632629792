import {
  styled,
  FormControl,
  InputBase,
  Typography,
  Chip,
} from '@mui/material';

export const FileUploadV2Formcontrol = styled(FormControl)({
  alignItems: 'center',
});

export const FileUploadV2Input = styled(InputBase)({
  display: 'none',
});

export const FileUploadV2LabelImg = styled('img')({
  width: 150,
});

export const FileUploadV2LabelImgText = styled(Typography)({
  position: 'absolute',
  bottom: 2,
});

export const FileUploadV2Chip = styled(Chip)({
  maxWidth: 200,
});

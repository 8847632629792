import { ReactElement, useCallback } from 'react';
import { SelectChangeEvent, MenuItem, Typography } from '@mui/material';
import {
  CountryIso2,
  usePhoneInput,
  parseCountry,
  defaultCountries as countries,
} from 'react-international-phone';

import { PhoneFieldProps } from './types';
import {
  PhoneFieldTextField,
  PhoneFieldCountryAdorment,
  PhoneFieldCountrySelect,
  PhoneFieldCountrySelectEmoji,
  PhoneFieldCountrySelectListItemEmoji,
} from './styles';

// Default Argentina
const defaultCountry: CountryIso2 = 'ar';

const PhoneField = ({
  value: valueProp,
  onChange,
  size,
  ...restProps
}: PhoneFieldProps): ReactElement => {
  const {
    phone: currentPhoneValue,
    handlePhoneValueChange,
    inputRef,
    country: currentCountry,
    setCountry,
  } = usePhoneInput({
    defaultCountry,
    value: valueProp,
    countries,
    onChange,
  });

  const handleChangeCurrentCountry = useCallback(
    (e: SelectChangeEvent<CountryIso2>) => {
      setCountry(e.target.value);
    },
    [setCountry],
  );

  return (
    <PhoneFieldTextField
      value={currentPhoneValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      size={size}
      InputProps={{
        startAdornment: (
          <PhoneFieldCountryAdorment position="start">
            <PhoneFieldCountrySelect
              MenuProps={{
                PaperProps: {
                  sx: {
                    marginTop: size === 'small' ? 0.5 : 1.5,
                  },
                },
              }}
              value={currentCountry}
              onChange={handleChangeCurrentCountry}
              renderValue={(countryIso2) => (
                <PhoneFieldCountrySelectEmoji iso2={countryIso2} />
              )}
            >
              {countries.map((country) => {
                const { iso2, name, dialCode } = parseCountry(country);

                return (
                  <MenuItem key={iso2} value={iso2}>
                    <PhoneFieldCountrySelectListItemEmoji iso2={iso2} />

                    <Typography marginRight={1}>{name}</Typography>

                    <Typography color="text.secondary">+{dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </PhoneFieldCountrySelect>
          </PhoneFieldCountryAdorment>
        ),
      }}
      {...restProps}
    />
  );
};

export default PhoneField;

import { styled, Box, Typography } from '@mui/material';

import { classes as classesDescriptionWithIconBox } from '../DescriptionWithIconBox/styles';

const fontSize = 10;

export const OrderClientInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),
}));

export const OrderClientInfoTitle = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 'normal',
});

export const OrderClientInfoOperationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.75),

  [`& .${classesDescriptionWithIconBox.container}`]: {
    color: theme.palette.text.secondary,
    fontSize,
    fontWeight: theme.typography.fontWeightBold,

    [`& .${classesDescriptionWithIconBox.iconContainer} svg`]: {
      fontSize: fontSize + 2,
    },
  },
}));

import { ReactElement, memo } from 'react';
import {
  TableFooter as TableFooterMui,
  TableRow,
  TableCell,
} from '@mui/material';

import { useTableContext } from '../TableContext';

import { useTableFooter } from './useTableFooter';

const TableFooter = (): ReactElement => {
  const { data, columns, classes } = useTableContext();

  const { getCellProps } = useTableFooter(data);

  return (
    <TableFooterMui className={classes?.footer}>
      <TableRow className={classes?.footerRow} tabIndex={0}>
        {columns.map((column, columnIndex) => (
          <TableCell
            key={`footer-${columnIndex}`}
            {...getCellProps(column, columnIndex)}
          />
        ))}
      </TableRow>
    </TableFooterMui>
  );
};

export default memo(TableFooter);

import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const SettingsIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M20.68,25.05a2.69,2.69,0,0,1-1.89-.8.8.8,0,0,0-.86-.23.82.82,0,0,0-.59.64,1.46,1.46,0,0,0,0,.3,2.6,2.6,0,0,1-2,2.47.88.88,0,0,1-.27.06,24.66,24.66,0,0,1-2.72,0,2.5,2.5,0,0,1-2.17-2.52.87.87,0,0,0-.52-.9.85.85,0,0,0-1,.25,2.61,2.61,0,0,1-3.72,0c-.43-.42-.85-.85-1.27-1.28a2.6,2.6,0,0,1,0-3.71.84.84,0,0,0,.25-1A.87.87,0,0,0,3,17.74,2.59,2.59,0,0,1,.44,15.11V13.28a2.6,2.6,0,0,1,2.61-2.64A.86.86,0,0,0,4,10a.9.9,0,0,0-.3-.85A2.6,2.6,0,0,1,3.31,5.9a3.25,3.25,0,0,1,.4-.5L5,4.14a2.6,2.6,0,0,1,3.75,0,.85.85,0,0,0,1.49-.61A2.6,2.6,0,0,1,12.93.86a20,20,0,0,1,2.19.05,2.55,2.55,0,0,1,2.2,2.54,1.46,1.46,0,0,0,0,.3.85.85,0,0,0,1.46.38,2.54,2.54,0,0,1,2.84-.59,3.08,3.08,0,0,1,.9.58c.45.41.87.86,1.3,1.29a2.6,2.6,0,0,1,0,3.72.86.86,0,0,0-.25,1,.89.89,0,0,0,.89.53,2.59,2.59,0,0,1,2.59,2.63q0,.9,0,1.8a2.61,2.61,0,0,1-2.63,2.68.83.83,0,0,0-.89.67.88.88,0,0,0,.29.85,2.58,2.58,0,0,1,.36,3.22,2.89,2.89,0,0,1-.38.47c-.43.45-.87.89-1.31,1.32a2.61,2.61,0,0,1-1.85.77M18.17,22.2A2.66,2.66,0,0,1,20,23a.84.84,0,0,0,1.27,0c.42-.41.83-.82,1.24-1.24a.83.83,0,0,0,0-1.27A2.64,2.64,0,0,1,24.43,16a.85.85,0,0,0,.89-.89V13.33a.85.85,0,0,0-.92-.92A2.51,2.51,0,0,1,22,10.81a2.51,2.51,0,0,1,.55-2.87.86.86,0,0,0,0-1.32l-1.19-1.2a.85.85,0,0,0-1.3,0A2.53,2.53,0,0,1,17.17,6a2.56,2.56,0,0,1-1.63-2.46.83.83,0,0,0-.89-.88H12.88a.83.83,0,0,0-.89.89A2.53,2.53,0,0,1,10.38,6,2.53,2.53,0,0,1,7.49,5.4a.83.83,0,0,0-1.27,0L5.06,6.56A.87.87,0,0,0,5,8a2.58,2.58,0,0,1,.72,1.78,2.62,2.62,0,0,1-2.61,2.65.85.85,0,0,0-.93.95c0,.58,0,1.15,0,1.73a.83.83,0,0,0,.86.88,2.53,2.53,0,0,1,2.47,1.61A2.53,2.53,0,0,1,5,20.47a.85.85,0,0,0,0,1.28L6.2,23a.85.85,0,0,0,1.3,0,2.53,2.53,0,0,1,2.89-.57A2.54,2.54,0,0,1,12,24.85a.84.84,0,0,0,.91.89c.55,0,1.09,0,1.64,0s1-.29,1-1a2.64,2.64,0,0,1,2.63-2.55" />
    {/* eslint-disable-next-line max-len */}
    <path d="M7.55,14.2a6.22,6.22,0,1,1,6.27,6.21A6.22,6.22,0,0,1,7.55,14.2m10.66,0a4.45,4.45,0,1,0-4.41,4.44,4.45,4.45,0,0,0,4.41-4.44" />
  </SvgIcon>
);

export default SettingsIcon;

import {
  ReactElement,
  ChangeEvent,
  FocusEvent,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  DialogTitle,
  DialogContent,
  Button,
  Stack,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  Select,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockIcon from '@mui/icons-material/Lock';
import { usePaymentInputs } from 'react-payment-inputs';
import { useFormik, FormikHelpers, FormikErrors } from 'formik';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import {
  FIELDS_NAME,
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
  ERROR_MESSAGES,
  DOCUMENTS_OPTIONS,
  CARD_TYPES_OPTIONS,
  VALID_CARD_ISSUERS,
  parseCardIssuer,
  deleteSpacesString,
} from '~components/PaymentForm/utils';
import CardPreview from '~components/PaymentForm/CardPreview';
import { PaymentFormGroupField } from '~components/PaymentForm/styles';
import { PaymentFormValues } from '~components/PaymentForm/types';
import { hasError, getHelperText } from '~utils/formHelpers';
import { updateMPPayment } from '~services/subscription/payment';
import { SubscriptionMercadoPagoCardPaymentData } from '~services/subscription/payment/types';
import { useLazyRequest } from '~hooks/index';

import { SubscriptionEditPaymentModalProps } from './types';
import {
  SubscriptionEditPaymentModalRoot,
  SubscriptionEditPaymentModalActions,
} from './styles';

const SubscriptionEditPaymentModal = ({
  companyPlanId,
  open,
  onClose,
}: SubscriptionEditPaymentModalProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs({
      autoFocus: false,
      errorMessages: ERROR_MESSAGES,
      cardNumberValidator: ({ cardType: cardIssuer }) => {
        if (VALID_CARD_ISSUERS.includes(cardIssuer.type)) {
          return '';
        }

        return 'La tarjeta debe ser Visa, Mastercard o American Express';
      },
    });

  const [, loadingEditPayment, , executeEditPayment] = useLazyRequest({
    request: updateMPPayment,
    withPostSuccess: () => {
      enqueueSnackbar('Su método de pago fue modificado correctamente', {
        variant: 'success',
      });

      onClose();
    },
    withPostFailure: (err) => {
      const message =
        err.data?.data?.message ?? 'Ha ocurrido un error, intente nuevamente';

      enqueueSnackbar(message, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: PaymentFormValues,
      { setSubmitting }: FormikHelpers<PaymentFormValues>,
    ) => {
      const paymenData: SubscriptionMercadoPagoCardPaymentData = {
        cardCredit: parseCardIssuer(meta.cardType.type),
        cardCVV: values[FIELDS_NAME.CARD_CVV],
        cardExpiration: moment(
          deleteSpacesString(values[FIELDS_NAME.CARD_EXPIRATION]),
          'MM/YY',
        ).format('MM/YYYY'),
        cardNumber: deleteSpacesString(values[FIELDS_NAME.CARD_NUMBER]),
        cardTypeId: Number(values[FIELDS_NAME.CARD_TYPE]),
        email: values[FIELDS_NAME.EMAIL],
        holderName: values[FIELDS_NAME.HOLDERNAME],
        identificationId: values[FIELDS_NAME.IDENTIFICATION_NUMBER],
        identificationTypeId: values[FIELDS_NAME.IDENTIFICATION_TYPE],
      };

      await executeEditPayment({
        companyPlanId,
        billingData: paymenData,
      });

      setSubmitting(false);
    },
    [executeEditPayment, meta.cardType, companyPlanId],
  );

  const validate = useCallback(() => {
    const errors: FormikErrors<PaymentFormValues> = {};

    if (meta.erroredInputs.cardNumber) {
      errors[FIELDS_NAME.CARD_NUMBER] = meta.erroredInputs.cardNumber;
    }

    if (meta.erroredInputs.expiryDate) {
      errors[FIELDS_NAME.CARD_EXPIRATION] = meta.erroredInputs.expiryDate;
    }

    if (meta.erroredInputs.cvc) {
      errors[FIELDS_NAME.CARD_CVV] = meta.erroredInputs.cvc;
    }

    return errors;
  }, [meta.erroredInputs]);

  const {
    errors,
    touched,
    submitForm,
    handleChange,
    handleBlur: handleBlurFormik,
    setFieldValue,
    values,
    getFieldProps,
    isSubmitting,
  } = useFormik({
    validate,
    validationSchema: VALIDATION_SCHEMA,
    initialValues: INITIAL_VALUES,
    onSubmit,
  });

  const [fieldFocused, setFieldFocused] = useState<string | undefined>(
    undefined,
  );

  const handleChangeHolderName = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value.toUpperCase();

      setFieldValue(FIELDS_NAME.HOLDERNAME, newValue);
    },
    [setFieldValue],
  );

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFieldFocused(event.target.name);
    },
    [],
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleBlurFormik(event);
      setFieldFocused(undefined);
    },
    [handleBlurFormik],
  );

  const cvvPlaceholder = useMemo(() => {
    let placeholder = '123';

    if (meta.cardType?.type === 'amex') placeholder += '4';

    return placeholder;
  }, [meta.cardType]);

  return (
    <SubscriptionEditPaymentModalRoot open={open}>
      <DialogTitle>Modificar método de pago</DialogTitle>

      <DialogContent dividers>
        <Stack spacing={2}>
          <CardPreview
            fieldFocused={fieldFocused}
            cardValues={{
              number: deleteSpacesString(values[FIELDS_NAME.CARD_NUMBER]),
              expiration: deleteSpacesString(
                values[FIELDS_NAME.CARD_EXPIRATION],
              ),
              cvv: values[FIELDS_NAME.CARD_CVV],
              holderName: values[FIELDS_NAME.HOLDERNAME],
            }}
          />

          <Stack spacing={3} direction="row">
            <TextField
              {...getCardNumberProps({
                // @ts-expect-error: Let's ignore a compile error like this unreachable code
                refKey: 'inputRef',
                onChange: handleChange,
                onBlur: handleBlur,
                onFocus: handleFocus,
              })}
              label="Número de tarjeta"
              placeholder="1234 1234 1234 1234"
              name={FIELDS_NAME.CARD_NUMBER}
              error={hasError(touched, errors, FIELDS_NAME.CARD_NUMBER)}
              helperText={getHelperText(
                touched,
                errors,
                FIELDS_NAME.CARD_NUMBER,
              )}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{ flex: 1 }}
            />

            <FormControl variant="outlined">
              <FormLabel sx={{ fontSize: 12 }}>Tipo de tarjeta</FormLabel>

              <RadioGroup row {...getFieldProps(FIELDS_NAME.CARD_TYPE)}>
                {CARD_TYPES_OPTIONS.map((option) => (
                  <FormControlLabel
                    key={`card-types-${option.value}`}
                    value={option.value}
                    control={<Radio size="small" />}
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Stack>

          <Stack spacing={3} direction="row">
            <TextField
              {...getExpiryDateProps({
                // @ts-expect-error: Let's ignore a compile error like this unreachable code
                refKey: 'inputRef',
                onChange: handleChange,
                onBlur: handleBlur,
                onFocus: handleFocus,
              })}
              label="Fecha de expiración"
              placeholder="MM/AA"
              name={FIELDS_NAME.CARD_EXPIRATION}
              error={hasError(touched, errors, FIELDS_NAME.CARD_EXPIRATION)}
              helperText={getHelperText(
                touched,
                errors,
                FIELDS_NAME.CARD_EXPIRATION,
              )}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              {...getCVCProps({
                // @ts-expect-error: Let's ignore a compile error like this unreachable code
                refKey: 'inputRef',
                onChange: handleChange,
                onBlur: handleBlur,
                onFocus: handleFocus,
              })}
              label="Código de seguridad"
              placeholder={cvvPlaceholder}
              name={FIELDS_NAME.CARD_CVV}
              error={hasError(touched, errors, FIELDS_NAME.CARD_CVV)}
              helperText={getHelperText(touched, errors, FIELDS_NAME.CARD_CVV)}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Stack>

          <TextField
            {...getFieldProps(FIELDS_NAME.HOLDERNAME)}
            onChange={handleChangeHolderName}
            onBlur={handleBlur}
            onFocus={handleFocus}
            label="Nombre del titular como aparece en la tarjeta"
            placeholder="María Lopez"
            name={FIELDS_NAME.HOLDERNAME}
            error={hasError(touched, errors, FIELDS_NAME.HOLDERNAME)}
            helperText={getHelperText(touched, errors, FIELDS_NAME.HOLDERNAME)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />

          <PaymentFormGroupField
            {...getFieldProps(FIELDS_NAME.IDENTIFICATION_NUMBER)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            label="Documento del titular"
            placeholder="99999999"
            name={FIELDS_NAME.IDENTIFICATION_NUMBER}
            error={hasError(touched, errors, FIELDS_NAME.IDENTIFICATION_NUMBER)}
            helperText={getHelperText(
              touched,
              errors,
              FIELDS_NAME.IDENTIFICATION_NUMBER,
            )}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Select
                    {...getFieldProps(FIELDS_NAME.IDENTIFICATION_TYPE)}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    fullWidth
                  >
                    {DOCUMENTS_OPTIONS.map((option) => (
                      <MenuItem
                        key={`documents-options-${option.value}`}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 9 }}
          />

          <TextField
            {...getFieldProps(FIELDS_NAME.EMAIL)}
            onBlur={handleBlur}
            onFocus={handleFocus}
            label="E-mail"
            placeholder="ejemplo@email.com"
            name={FIELDS_NAME.EMAIL}
            error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
            helperText={getHelperText(touched, errors, FIELDS_NAME.EMAIL)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogContent>

      <SubscriptionEditPaymentModalActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loadingEditPayment || isSubmitting}
          startIcon={<LockIcon />}
          sx={{ maxWidth: 150 }}
        >
          Modificar
        </LoadingButton>
      </SubscriptionEditPaymentModalActions>
    </SubscriptionEditPaymentModalRoot>
  );
};

export default SubscriptionEditPaymentModal;

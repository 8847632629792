import { useState, useCallback } from 'react';

export interface UseSelectionUtils<T> {
  add: (currentId: T) => void;
  addMultiple: (currentIds: T[]) => void;
  toggle: (currentId: T) => void;
  has: (currentId: T) => boolean;
  reset: () => void;
  isEmpty: () => boolean;
}

export interface UseSelectionReturn<T> {
  selectedIds: T[];
  utils: UseSelectionUtils<T>;
}

export const useSelectionIds = <T extends string | number>(
  initialIds: T[],
): UseSelectionReturn<T> => {
  const [selectedIds, setSelectedIds] = useState<T[]>(initialIds);

  const add = useCallback((currentId: T) => {
    setSelectedIds((prevSelectedIds) => [...prevSelectedIds, currentId]);
  }, []);

  const addMultiple = useCallback((currentIds: T[]) => {
    setSelectedIds((prevSelectedIds) => {
      const set = new Set<T>([...prevSelectedIds, ...currentIds]);

      return Array.from(set);
    });
  }, []);

  const toggle = useCallback((currentId: T) => {
    setSelectedIds((prevSelectedIds) => {
      const set = new Set<T>(prevSelectedIds);

      if (set.has(currentId)) {
        set.delete(currentId);
      } else {
        set.add(currentId);
      }

      return Array.from(set);
    });
  }, []);

  const has = useCallback(
    (currentId: T) => selectedIds.includes(currentId),
    [selectedIds],
  );

  const isEmpty = useCallback(() => selectedIds.length === 0, [selectedIds]);

  const reset = useCallback(() => {
    setSelectedIds(initialIds);
  }, [initialIds]);

  return {
    selectedIds,
    utils: {
      add,
      addMultiple,
      toggle,
      has,
      isEmpty,
      reset,
    },
  };
};

import { useScript } from './useScript';

const scriptInitializedId = 'tawk-initialized';
const scriptInitializedSrc =
  'https://embed.tawk.to/636a41d8daff0e1306d655bc/1h5hu8o30';

const scriptCallbackId = 'tawk-callback';
const scriptCalbackInnerHtml = `
  var Tawk_API=Tawk_API||{};
  Tawk_API.customStyle = { zIndex: 1201 }
  var Tawk_LoadStart=new Date();
`;

export const useLoadTawk = (): void => {
  useScript({
    id: scriptInitializedId,
    position: document.querySelector('head'),
    src: scriptInitializedSrc,
    async: true,
    charset: 'utf-8',
    crossOrigin: '*',
    validateOnlyProduction: false,
  });

  useScript({
    id: scriptCallbackId,
    position: document.querySelector('head'),
    innerHTML: scriptCalbackInnerHtml,
    type: 'text/javascript',
    validateOnlyProduction: false,
  });
};

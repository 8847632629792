import { ReactElement, useCallback, useMemo } from 'react';
import {
  Stack,
  DialogContent,
  DialogActions,
  Typography,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useLazyRequest, useAppSelector } from '~hooks/index';
import { associatePartnerCompany } from '~services/partnerCompany';
import { PartnerCompany } from '~services/partnerCompany/types';
import { selectAuth } from '~store/slices/auth/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { isCarrierPartnerCompany } from '~utils/partnerCompany';
import { PartnerCompanyTypes } from '~globals/types/enums';

// eslint-disable-next-line max-len
import { useDialogInvitationPartnerCompanyContext } from '../../DialogInvitationPartnerCompanyContext';
import {
  DialogInvitationPartnerCompanyConfirmationHeader,
  DialogInvitationPartnerCompanyConfirmationHeaderAvatar,
  DialogInvitationPartnerCompanyConfirmationHeaderIcon,
  DialogInvitationPartnerCompanyConfirmationChip,
} from './styles';

const DialogInvitationPartnerCompanyConfirmation = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAppSelector(selectAuth);

  const { onClose, onConfirmation, validatedData } =
    useDialogInvitationPartnerCompanyContext();

  const [, loading, , executeConfirmCompany] = useLazyRequest({
    request: associatePartnerCompany,
    withPostSuccess: (response) => {
      const confirmationData = response.data?.data as PartnerCompany;

      enqueueSnackbar('La asociación se ha aceptado correctamente', {
        variant: 'success',
      });

      onConfirmation?.(confirmationData);
      onClose();
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const handleAcceptCompany = useCallback(async () => {
    if (validatedData?.invitationCode) {
      await executeConfirmCompany(validatedData.invitationCode);
    }
  }, [validatedData, executeConfirmCompany]);

  const isCarrierOwnerInvitationCompany = useMemo(
    () =>
      isCarrierPartnerCompany(
        validatedData?.partnerCompanyTypeId as PartnerCompanyTypes,
      ),
    [validatedData],
  );

  const ownerInvitationCompany = useMemo(
    () =>
      isCarrierOwnerInvitationCompany
        ? validatedData?.giverCompany
        : validatedData?.carrierCompany,
    [isCarrierOwnerInvitationCompany, validatedData],
  );

  const ownerInvitationCompanyTypeDescription = useMemo(
    () =>
      isCarrierOwnerInvitationCompany
        ? 'Empresa Dador de carga o vendedor'
        : 'Empresa Transportista o logística',
    [isCarrierOwnerInvitationCompany],
  );

  return (
    <>
      <DialogContent dividers>
        <Stack spacing={4} alignItems="center">
          <DialogInvitationPartnerCompanyConfirmationHeader>
            <DialogInvitationPartnerCompanyConfirmationHeaderAvatar
              src={user?.company.logoFile?.url}
              alt={user?.company.name}
            />

            <DialogInvitationPartnerCompanyConfirmationHeaderIcon
              src={toAbsoluteUrl('/icons/icon_plus.svg')}
              alt="Plus Icon"
            />

            <DialogInvitationPartnerCompanyConfirmationHeaderAvatar
              src={ownerInvitationCompany?.logoFile?.url}
              alt={ownerInvitationCompany?.name}
            />
          </DialogInvitationPartnerCompanyConfirmationHeader>

          <Stack spacing={0.5} alignItems="center">
            <Typography
              component="p"
              variant="body2"
              color="secondary.main"
              fontWeight="800"
            >
              Te estás asociando con
            </Typography>

            <Typography component="p" variant="h5" fontWeight="800">
              {ownerInvitationCompany?.name}
            </Typography>

            <DialogInvitationPartnerCompanyConfirmationChip
              label={ownerInvitationCompanyTypeDescription}
              variant="outlined"
              color="primary"
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Typography variant="caption" component="span">
          <Link
            href="https://routix.tawk.help/article/asociar-empresas"
            target="_blank"
            underline="hover"
            fontWeight="bold"
          >
            Haz clic aquí
          </Link>{' '}
          para tener ayuda.
        </Typography>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleAcceptCompany}
          loading={loading}
        >
          Aceptar
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DialogInvitationPartnerCompanyConfirmation;

import { ReactElement, ReactNode, useCallback } from 'react';
import { TextField, MenuItem, ListItemText } from '@mui/material';

import { COMPANY_TYPE_INFO } from '~constants/companyTypesInfo';

import { CompanyTypesFieldProps } from './types';
import { getCompanyTypeFieldLabel } from './utils';

const CompanyTypesField = (props: CompanyTypesFieldProps): ReactElement => {
  const renderValue = useCallback((selectedRole: unknown): ReactNode => {
    const currentValue = getCompanyTypeFieldLabel(Number(selectedRole));

    return currentValue;
  }, []);

  return (
    <TextField
      {...props}
      select
      SelectProps={{
        renderValue,
        MenuProps: {
          PaperProps: {
            style: {
              width: 250,
            },
          },
        },
      }}
    >
      {COMPANY_TYPE_INFO.map((companyType) => (
        <MenuItem
          key={`company-type-field-${companyType.companyTypeId}`}
          value={String(companyType.companyTypeId)}
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          <ListItemText
            primary={`${companyType.label} ${companyType.subLabel}`}
            secondary={companyType.scopeDescription}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CompanyTypesField;

import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const ReceivedIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <g>
      {/* eslint-disable-next-line max-len */}
      <path d="M29,18.8c0-0.8-0.4-1.2-1.2-1.2c-2.3,0-4.6,0-6.9,0c-0.7,0-1,0.3-1.2,1c-0.4,2-2,3.4-4,3.4c0,0,0,0,0,0c-2,0-3.6-1.4-4-3.4c-0.1-0.7-0.5-1-1.2-1c-2.3,0-4.6,0-7,0c-0.8,0-1.2,0.4-1.2,1.2l0,1.1c0,2,0,3.9,0,5.9c0,0.3,0,0.7,0.1,1c0.4,1.8,2,3,3.8,3c1.3,0,2.6,0,3.8,0c0.9,0,1.8,0,2.7,0l2.7,0l2.8,0c2.2,0,4.3,0,6.5,0c2.4,0,4-1.7,4-4c0-1.9,0-3.7,0-5.6L29,18.8z M27.1,21.1c0,1.5,0,3,0,4.5c0,1.4-0.8,2.1-2.2,2.1c-6.1,0-12.2,0-18.3,0c-1.3,0-2.1-0.8-2.1-2.1c0-1.5,0-3,0-4.6l0-1.4c0,0,0-0.1,0-0.1l1.4,0c1.3,0,2.7,0,4,0c0.1,0,0.1,0,0.2,0.1c0.8,2.5,3.1,4.2,5.7,4.2c0,0,0,0,0,0c2.6,0,4.9-1.7,5.7-4.2c0-0.1,0-0.1,0.2-0.1c1.3,0,2.6,0,3.8,0H27c0,0,0,0,0.1,0c0,0,0,0.1,0,0.1L27.1,21.1z" />
      {/* eslint-disable-next-line max-len */}
      <path d="M14,14l0.9,0.9c0.2,0.2,0.5,0.3,0.7,0.3c0,0,0,0,0,0c0.3,0,0.5-0.1,0.8-0.4l0.1-0.1c0.3-0.3,0.7-0.7,1-1l3-3c0.8-0.8,0.4-1.3,0.2-1.6c-0.2-0.2-0.4-0.3-0.7-0.3c0,0,0,0,0,0c-0.3,0-0.6,0.2-0.9,0.4l-0.4,0.4c-0.6,0.6-1.2,1.2-1.8,1.8c-0.1,0.1-0.2,0.2-0.3,0.3V4.4c0-0.5-0.1-0.8-0.3-1c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-1,0.1-1,1.3l0,7.5l-0.2-0.2c-0.8-0.8-1.6-1.6-2.4-2.4C11.8,9,11.4,8.9,11,9c-0.4,0.1-0.7,0.5-0.6,0.9c0,0.3,0.2,0.6,0.4,0.8C11.9,11.8,13,12.9,14,14z" />
      {/* eslint-disable-next-line max-len */}
      <path d="M3.9,15.3c0.1,0,0.2,0,0.3,0c0.5-0.2,0.6-0.6,0.7-0.9l0.9-3.8c0.5-2,0.9-4,1.4-6c0-0.1,0-0.1,0-0.1c0,0,0,0,0.1,0c0.6,0,1.3,0,1.9,0c0.6,0,1.1-0.4,1.1-1c0-0.3-0.1-0.5-0.3-0.7C9.8,2.6,9.5,2.5,9.1,2.5c-0.3,0-0.6,0-0.9,0l-1.6,0c-0.8,0-1.1,0.2-1.3,1l-1.9,8.3c-0.2,0.8-0.3,1.5-0.5,2.3c-0.1,0.4,0,0.7,0.3,0.9C3.4,15.2,3.6,15.3,3.9,15.3z" />
      {/* eslint-disable-next-line max-len */}
      <path d="M22.5,4.5l0.4,0c0.5,0,0.9,0,1.4,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c0.6,2.5,1.1,5,1.7,7.5l0.5,2.3c0.1,0.6,0.5,0.9,1,0.9c0.1,0,0.1,0,0.2,0c0.3-0.1,0.5-0.2,0.6-0.4c0.2-0.2,0.2-0.5,0.1-0.9c-0.8-3.6-1.6-7.1-2.4-10.7c-0.1-0.6-0.5-0.8-1.1-0.9c-0.3,0-0.6,0-0.9,0l-1.7,0c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.3,0.4-0.3,0.7C21.3,4.1,21.7,4.5,22.5,4.5z" />
    </g>
  </SvgIcon>
);

export default ReceivedIcon;

import { ReactElement } from 'react';
import { Typography, Stack } from '@mui/material';
import { uniqueId } from 'lodash';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { BannerProps } from './types';
import {
  BannerRoot,
  BannerBackground,
  BannerContent,
  BannerButton,
  BannerMedia,
} from './styles';

const Banner = ({
  title,
  description,
  actions,
  image,
}: BannerProps): ReactElement => {
  return (
    <BannerRoot variant="outlined">
      <BannerBackground
        src={toAbsoluteUrl('/images/bg_banner_plug.png')}
        alt="Banner Plug Background"
      />

      <BannerContent>
        <Stack>
          <Typography
            variant="body1"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {title}
          </Typography>

          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ minHeight: 40.5 }}
          >
            {description}
          </Typography>
        </Stack>

        <Stack spacing={1} direction="row">
          {actions.map(({ text, onClick }) => (
            <BannerButton
              key={uniqueId('banner-action-')}
              variant="text"
              color="primary"
              size="small"
              onClick={onClick}
            >
              {text}
            </BannerButton>
          ))}
        </Stack>
      </BannerContent>

      <BannerMedia {...image} />
    </BannerRoot>
  );
};

export default Banner;

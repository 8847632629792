import { styled, Alert, alertClasses } from '@mui/material';
import { lighten } from '@mui/material/styles';

export const InitialSetupWizardLoadVehicleInfoAlert = styled(Alert)(
  ({ theme }) => ({
    ...theme.typography.caption,
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
    border: `1px solid ${theme.palette.warning.light}`,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,

    [`& .${alertClasses.message}`]: {
      padding: 0,
    },
  }),
);

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { removeItemTaskInItem } from '~services/item';
import { ItemTask } from '~services/item/types';

import { useLazyRequest } from './useLazyRequest';

interface RemoveItemTaskParams {
  onSuccess?: (itemTask: ItemTask) => void;
  onError?: () => void;
}

interface RemoveItemTaskReturn {
  loading: boolean;
  handleRemoveItemTask: (itemTaskId: string) => void;
}

export const useRemoveItemTask = (
  options?: RemoveItemTaskParams,
): RemoveItemTaskReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loading, , executeRequest] = useLazyRequest({
    request: removeItemTaskInItem,
    withPostSuccess: (response) => {
      const currentItenTask = response.data?.data;

      if (currentItenTask) {
        options?.onSuccess?.(currentItenTask);

        // eslint-disable-next-line max-len
        const message = `El artículo / tarea ${currentItenTask?.description} fue eliminado correctamente`;

        enqueueSnackbar(message, { variant: 'success' });
      }
    },
    withPostFailure: () => {
      options?.onError?.();

      enqueueSnackbar(
        'No se pudo eliminar el artículo / tarea, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const handleRemoveItemTask = useCallback(
    async (itemTaskId: string) => {
      await executeRequest(itemTaskId);
    },
    [executeRequest],
  );

  return { loading, handleRemoveItemTask };
};

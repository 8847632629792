import * as Yup from 'yup';
// import moment from 'moment';

import { VALIDATIONS_ERROR } from '~constants/index';
import { Nullable } from '~globals/types';
import { AdminWarehouse } from '~services/admin/warehouse/types';
// import { formatterTime } from '~utils/index';

export const FIELDS_NAME = {
  ENABLED: 'enabled',
  TITLE: 'title',
  ADDRESS: 'address',
  ADDRESS_COORDS: 'addressCoords',
  ADDRESS_NOTES: 'addressNotes',
  // ADDRESS_RADIUS: 'addressRadius',
  ADDRESS_WAIT_TIME: 'addresWaitTime',
  CONTACT_NAME: 'contactName',
  CONTACT_PHONE: 'contactPhone',
  // TIME_WINDOW_ONE_FROM: 'timeWindowOneFrom',
  // TIME_WINDOW_ONE_TO: 'timeWindowOneTo',
  // TIME_WINDOW_SECOND_FROM: 'timeWindowSecondFrom',
  // TIME_WINDOW_SECOND_TO: 'timeWindowSecondTo',
  // TIME_WINDOW_ALL_DAY: 'timeWindowAllDay',
} as const;

// const parseTimeWindowValue = (value: Nullable<string>): Nullable<Date> =>
//   value
//     ? new Date(formatterTime(value, moment.HTML5_FMT.DATETIME_LOCAL_MS))
//     : null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (data?: Nullable<AdminWarehouse>) => ({
  [FIELDS_NAME.ENABLED]: data?.enabled ?? true,
  [FIELDS_NAME.TITLE]: data?.title ?? '',
  [FIELDS_NAME.ADDRESS]: data?.address ?? '',
  [FIELDS_NAME.ADDRESS_COORDS]: (data?.latitude && data.longitude
    ? {
        lat: data.latitude,
        lng: data.longitude,
      }
    : null) as Nullable<google.maps.LatLngLiteral>,
  [FIELDS_NAME.ADDRESS_NOTES]: data?.addressNotes ?? '',
  // [FIELDS_NAME.ADDRESS_RADIUS]: String(data?.radius ?? ''),
  [FIELDS_NAME.ADDRESS_WAIT_TIME]: String((data?.serviceDuration ?? 0) / 60),
  [FIELDS_NAME.CONTACT_NAME]: data?.contactName ?? '',
  [FIELDS_NAME.CONTACT_PHONE]: data?.contactPhone ?? '',
  // [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: data?.timeWindowAllDay
  //   ? ''
  //   : parseTimeWindowValue(data?.timeWindowFrom1 ?? '06:00:00'),
  // [FIELDS_NAME.TIME_WINDOW_ONE_TO]: data?.timeWindowAllDay
  //   ? ''
  //   : parseTimeWindowValue(data?.timeWindowFrom1 ?? '22:00:00'),
  // [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: parseTimeWindowValue(
  //   data?.timeWindowFrom2 ?? null,
  // ),
  // [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: parseTimeWindowValue(
  //   data?.timeWindowTo2 ?? null,
  // ),
  // [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: data?.timeWindowAllDay ?? false,
});

// export const validationTimeWindowDate = Yup.date()
//   .nullable()
//   .when(FIELDS_NAME.TIME_WINDOW_ALL_DAY, {
//     is: false,
//     then: (schema) =>
//       schema
//         .typeError(VALIDATIONS_ERROR.INVALID_DATE)
//         .required(VALIDATIONS_ERROR.REQUIRED),
//     otherwise: (schema) => schema.notRequired(),
//   });

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ENABLED]: Yup.boolean(),
  [FIELDS_NAME.TITLE]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.ADDRESS]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.ADDRESS_COORDS]: Yup.mixed().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.ADDRESS_NOTES]: Yup.string().notRequired(),
  // [FIELDS_NAME.ADDRESS_RADIUS]: Yup.string().required(
  //   VALIDATIONS_ERROR.REQUIRED,
  // ),
  [FIELDS_NAME.ADDRESS_WAIT_TIME]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.CONTACT_NAME]: Yup.string(),
  [FIELDS_NAME.CONTACT_PHONE]: Yup.string(),
  // [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: validationTimeWindowDate,
  // [FIELDS_NAME.TIME_WINDOW_ONE_TO]: validationTimeWindowDate,
  // [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: Yup.date().nullable().notRequired(),
  // [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: Yup.date().nullable().notRequired(),
  // [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: Yup.boolean(),
});

import { SubscriptionPlanPriceCardProps } from '~components/Subscription/PlanPriceCard/types';
import { CustomPlanInfoCardProps } from '~components/Subscription/CustomPlanInfoCard/types';

export type PlanPriceData = Omit<
  SubscriptionPlanPriceCardProps,
  'direction' | 'withShadow' | 'onSubscribe'
>;

export const PLANS_PRICE_DATA: PlanPriceData[] = [
  {
    name: 'START',
    providersPlansId: {
      mercadopago: {
        annual: 'A2F42345-FD50-4FD1-901F-2DD8E8F8A2F1',
        monthly: 'A2F42345-FD50-4FD1-901F-2DD8E8F8A2F1',
      },
      paypal: {
        annual: 'A2F42345-FD50-4FD1-901F-2DD8E8F8A2F1',
        monthly: 'A2F42345-FD50-4FD1-901F-2DD8E8F8A2F1',
      },
    },
    rating: 1,
    isFree: true,
    isRecommended: false,
    features: [
      'Ruteo eficiente de la flota',
      'Seguimiento de vehículos en tiempo real',
      'Indicadores y KPIs en rutas',
      'App para choferes con evidencia digital',
      'Integración con las principales tiendas online',
      'Archivo y reporte de hasta 2 meses',
      <>
        Hasta <b>20 rutas</b> mensuales
      </>,
    ],
    highlightColor: '#00b0ff',
    starsColors: '#00b0ff',
    aclarationColor: '#000',
  },
  {
    name: 'PLUS',
    providersPlansId: {
      mercadopago: {
        annual: '5930A756-569C-47FD-BD2F-250936C09053',
        monthly: '7BB045F4-B247-473E-AF56-2C4E74019F33',
      },
      paypal: {
        annual: '5930A756-569C-47FD-BD2F-250936C09053',
        monthly: '7BB045F4-B247-473E-AF56-2C4E74019F33',
      },
    },
    rating: 2,
    isFree: false,
    currentPrice: {
      monthly: 119,
      annual: 99,
    },
    isRecommended: true,
    featureTitle: 'Todo lo incluido en el plan START +',
    features: [
      // 'Actualizaciones de estado por WhatsApp*',
      'Generacion de etiquetas de pedidos',
      'Vista avanzada de mapa de pedidos y colecta',
      'Portal de seguimiento de pedidos',
      'Estadisticas y reportes avanzados',
      'Archivo y reportes de hasta 6 meses',
      <>
        Hasta <b>150 rutas</b> mensuales
      </>,
    ],
    highlightColor: '#5618b6',
    starsColors: '#5618b6',
    aclarationColor: '#5618b6',
    disabledByPlans: ['PREMIUM'],
  },
  {
    name: 'PREMIUM',
    providersPlansId: {
      mercadopago: {
        annual: 'BF50268C-6ACE-4AF7-B0C3-4441BA99A7DA',
        monthly: '19554CEE-29A6-4EF9-9C0D-20D59A2C4FCD',
      },
      paypal: {
        annual: 'BF50268C-6ACE-4AF7-B0C3-4441BA99A7DA',
        monthly: '19554CEE-29A6-4EF9-9C0D-20D59A2C4FCD',
      },
    },
    rating: 3,
    isFree: false,
    currentPrice: {
      monthly: 270,
      annual: 239,
    },
    isRecommended: false,
    featureTitle: 'Todo lo incluido en el plan PLUS +',
    features: [
      // 'Personalización de imagen',
      // 'Accesos a API e integraciones avanzadas',
      // 'Incluye packs WhatsApp y Marca Blanca',
      'Seguimiento y notificaciones por WhatsApp',
      'Asistencia técnica prioritaria',
      'Archivo y reportes de hasta 2 años',
      <>
        Hasta <b>500 rutas</b> mensuales
      </>,
    ],
    highlightColor: '#000',
    starsColors: '#faaf00',
    aclarationColor: '#000',
  },
];

export const CUSTOM_PLANS: CustomPlanInfoCardProps[] = [
  {
    name: 'CORPORATE',
    features: [
      'Soporte técnico especializado',
      'Reporteria personalizada',
      'Integraciones personalizadas',
      <>
        Más de <b>1000 rutas</b> mensuales
      </>,
    ],
  },
];

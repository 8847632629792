import { useMemo, useCallback } from 'react';
import { includes, chain, indexOf } from 'lodash';
import moment from 'moment';

import {
  useSubscription,
  usePlansDetails,
  useTypePartnerCompany,
} from '~hooks/index';
import { Nullable } from '~globals/types';
import { PlanFeaturesTypes } from '~globals/types/enums';

type HandlerValidateSubscriptionFeatureAccess = (
  validFeature: `${PlanFeaturesTypes}`,
) => boolean;

type HandlerValidateSubscriptionFeatureAvailableToDate = (
  availableToDate?: Date,
) => boolean;

type HandlerHasAccessFeature = (
  validFeature: `${PlanFeaturesTypes}`,
  availableToDate?: Date,
) => boolean;

type HandlerGetNextPlanFeature = (
  currentFeature: `${PlanFeaturesTypes}`,
) => Nullable<string>;

interface SubscriptionFeatures {
  currentFeatures: `${PlanFeaturesTypes}`[];
  validateFeatureAccess: HandlerValidateSubscriptionFeatureAccess;
  validateFeatureAvailableToDate: HandlerValidateSubscriptionFeatureAvailableToDate;
  hasAccessToFeature: HandlerHasAccessFeature;
  getNextPlanFeature: HandlerGetNextPlanFeature;
}

const BASE_PLANS = ['START', 'ACADEMIC', 'PRO'];

export const useSubscriptionFeatures = (): SubscriptionFeatures => {
  const subscription = useSubscription();
  const { featuresByPlan } = usePlansDetails();
  const { isGiver } = useTypePartnerCompany();

  const currentFeatures = useMemo(
    () => subscription?.features ?? [],
    [subscription],
  );

  const validateFeatureAccess =
    useCallback<HandlerValidateSubscriptionFeatureAccess>(
      (validFeature) =>
        isGiver ? true : includes(currentFeatures, validFeature),
      [isGiver, currentFeatures],
    );

  const validateFeatureAvailableToDate =
    useCallback<HandlerValidateSubscriptionFeatureAvailableToDate>(
      (availableToDate) => {
        if (availableToDate) {
          const now = moment().startOf('day');
          const available = moment(availableToDate);

          return now.isSameOrBefore(available, 'day');
        }

        return false;
      },
      [],
    );

  const hasAccessToFeature = useCallback<HandlerHasAccessFeature>(
    (validFeature, availableToDate) => {
      if (!validateFeatureAccess(validFeature)) {
        return validateFeatureAvailableToDate(availableToDate);
      }

      return true;
    },
    [validateFeatureAccess, validateFeatureAvailableToDate],
  );

  const getNextPlanFeature = useCallback<HandlerGetNextPlanFeature>(
    (currentFeature) => {
      if (validateFeatureAccess(currentFeature)) return null;

      const currentPlansByFeature = chain(featuresByPlan)
        .get(currentFeature)
        .filter((plan) => !includes(BASE_PLANS, plan))
        .value();

      const indexNextPlan = indexOf(
        currentPlansByFeature,
        subscription?.description,
      );

      return currentPlansByFeature[indexNextPlan + 1] ?? null;
    },
    [featuresByPlan, subscription?.description, validateFeatureAccess],
  );

  return {
    currentFeatures,
    validateFeatureAvailableToDate,
    validateFeatureAccess,
    hasAccessToFeature,
    getNextPlanFeature,
  };
};

import { styled, Card } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
// import { darken } from '@mui/material/styles';

export const CustomPlanInfoCardRoot = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

export const CustomPlanInfoCardCheckIcon = styled(CheckIcon)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(17),
  color: '#000',
  marginTop: 2,
  marginRight: theme.spacing(1),
}));

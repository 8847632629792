import { ReactElement, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { isUndefined } from 'lodash';

import { useRequest } from '~hooks/index';
import { getItemByIds } from '~services/item';
import { ItemExtended } from '~services/item/types';

import DialogGenerateRoutesProvider from './DialogGenerateRoutesContext';
import { DialogGenerateRoutesProps } from './types';
import DialogGenerateRoutesContent from './DialogGenerateRoutesContent';
import {
  DialogGenerateRoutesContainer,
  DialogGenerateRoutesLoadingContainer,
} from './styles';

const DialogGenerateRoutes = ({
  open,
  onClose,
  orderIds,
  orderInfo,
  type = 'route',
  onSuccessComplete,
}: DialogGenerateRoutesProps): ReactElement => {
  const [ordersInfo, setOrdersInfo] = useState<ItemExtended[]>(orderInfo ?? []);

  const [, loadingOrdersInfo] = useRequest({
    initialLoading: false,
    request: getItemByIds,
    payload: orderIds,
    isValidToRequest: isUndefined(orderInfo),
    withPostSuccess: (response) => {
      const currentOrderInfo = response.data?.data.results as ItemExtended[];

      setOrdersInfo(currentOrderInfo);
    },
  });

  return (
    <DialogGenerateRoutesContainer open={open}>
      {loadingOrdersInfo && (
        <DialogGenerateRoutesLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogGenerateRoutesLoadingContainer>
      )}

      {!loadingOrdersInfo && (
        <DialogGenerateRoutesProvider
          value={{ ordersInfo, onClose, type, onSuccessComplete }}
        >
          <DialogGenerateRoutesContent />
        </DialogGenerateRoutesProvider>
      )}
    </DialogGenerateRoutesContainer>
  );
};

export default DialogGenerateRoutes;

import { useMemo, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { notValidatedPartnerItems } from '~services/partnerCompany';
import { PartnerCompanyItem } from '~services/partnerCompany/types';
import { Nullable } from '~globals/types';

import { useLazyRequest } from './useLazyRequest';

export const useNotValidatedPartnerItems = (
  onSuccessNotValidated?: () => void,
  currentPartnerItem?: Nullable<PartnerCompanyItem>,
): {
  loading: boolean;
  handleNotValidatedPartnerItems: (
    itemsIds: Array<string | number>,
  ) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const messageSuccess = useMemo(() => {
    if (currentPartnerItem) {
      // eslint-disable-next-line max-len
      return `El pedido ${currentPartnerItem.trackingCode} fue marcado como no colectado correctamente`;
    }

    return 'Pedidos marcados como no colectados correctamente';
  }, [currentPartnerItem]);

  const messageError = useMemo(() => {
    if (currentPartnerItem) {
      return 'No se pudo marcar como no colectado el pedido, intente nuevamente';
    }

    return 'No se pudo marcar como no colectados los pedidos, intente nuevamente';
  }, [currentPartnerItem]);

  const [, loadingNotValidatedPartnerItem, , executeNotValidatedPartnerItem] =
    useLazyRequest({
      request: notValidatedPartnerItems,
      withPostSuccess: () => {
        enqueueSnackbar(messageSuccess, { variant: 'success' });

        onSuccessNotValidated?.();
      },
      withPostFailure: () => {
        enqueueSnackbar(messageError, { variant: 'error' });
      },
    });

  const handleNotValidatedPartnerItems = useCallback(
    async (itemsIds: Array<string | number>) => {
      await executeNotValidatedPartnerItem(itemsIds);
    },
    [executeNotValidatedPartnerItem],
  );

  return {
    loading: loadingNotValidatedPartnerItem,
    handleNotValidatedPartnerItems,
  };
};

import { useCallback, useEffect } from 'react';
import confetti, { Options as ConfettiOptions } from 'canvas-confetti';

type AnimatedConfettiDirection = 'left' | 'right';

export const useAnimatedConfetti = (): void => {
  const fireConfetti = useCallback(
    (particleRatio: number, options: ConfettiOptions) => {
      const defaultParticleCount = options.particleCount ?? 500;

      confetti({
        ...options,
        particleCount: Math.floor(defaultParticleCount * particleRatio),
      });
    },
    [],
  );

  const animatedConfetti = useCallback(
    (direction: AnimatedConfettiDirection) => {
      const isLeft = direction === 'left';
      const origin: ConfettiOptions['origin'] = { x: isLeft ? 0 : 1, y: 1 };
      const angle = isLeft ? 50 : 100;

      fireConfetti(0.25, {
        spread: 26,
        startVelocity: 55,
        origin,
        angle,
      });

      fireConfetti(0.2, {
        spread: 60,
        origin,
        angle,
      });

      fireConfetti(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin,
        angle,
      });

      fireConfetti(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin,
        angle,
      });

      fireConfetti(0.1, {
        spread: 120,
        startVelocity: 45,
        origin,
        angle,
      });
    },
    [fireConfetti],
  );

  useEffect(() => {
    animatedConfetti('left');
    animatedConfetti('right');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

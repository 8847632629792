import { ReactElement, useState, useCallback } from 'react';
import {
  DialogTitle,
  DialogContentText,
  Stack,
  Alert,
  AlertTitle,
  List,
  ListItem,
  Button,
} from '@mui/material';
import { FiberManualRecord as DotIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { StepperGenerateRoute } from '~components/index';
import { useLazyRequest } from '~hooks/index';
import { useSignalRContext } from '~contexts/SignalRContext';
import { useClientOnMethod } from '~hooks/useSignalR';
import { optimizeRoute } from '~services/route';
import {
  NotificationApiTransactionWorkProgressId,
  NotificationApiTransactionRejectOperation,
  NotificationRouteOptimizeTransaction,
} from '~globals/types/notifications';
import { METHODS_NAME } from '~constants/signalR';

import { DialogOptimizeRouteProps } from './types';
import {
  DialogOptimizeRouteContainer,
  DialogOptimizeRouteContent,
  DialogOptimizeRouteActions,
  DialogOptimizeRouteInfoListItemListIcon,
  DialogOptimizeRouteInfoListItemText,
} from './styles';

const infoTextList = [
  'Quitar paradas que superen la capacidad máxima del vehículo o de la jornada laboral.',
  'Reordenar las paradas para lograr una mejor eficiencia de combustible y tiempos.',
  'Consumir 1 ruta del plan vigente.',
  'Modificar la información referida al plan original.',
];

const DialogOptimizeRoute = ({
  open,
  onClose,
  onCompletedOptimize,
  onGenerateNewRoute,
  routeId,
}: DialogOptimizeRouteProps): ReactElement => {
  const { hubConnection } = useSignalRContext();
  const { enqueueSnackbar } = useSnackbar();

  const [enabledStepper, setEnabledStepper] = useState(false);
  const [isInProcessOptimize, setIsInProcessOptimize] = useState(false);

  const [, loadingOptimizeRoute, , executeOptimizeRoute] = useLazyRequest({
    request: optimizeRoute,
    withPostSuccess: () => {
      setEnabledStepper(true);
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      setIsInProcessOptimize(false);

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const handleOptimize = useCallback(async () => {
    setIsInProcessOptimize(true);
    await executeOptimizeRoute(routeId);
  }, [executeOptimizeRoute, routeId]);

  const [activeStep, setActiveStep] = useState(0);
  const [workProgressId, setWorkProgressId] =
    useState<NotificationApiTransactionWorkProgressId>(1);
  const [errorsStepper, setErrorsStepper] = useState<
    NotificationApiTransactionRejectOperation[]
  >([]);

  const updateStepper = useCallback(
    (notification: string) => {
      const infoNotification = JSON.parse(
        notification,
      ) as NotificationRouteOptimizeTransaction;
      if (
        infoNotification.RouteId === routeId &&
        infoNotification.RouteTransactionWorkProgressId - 1 !== activeStep
      ) {
        setActiveStep(infoNotification.RouteTransactionWorkProgressId - 1);
        setWorkProgressId(infoNotification.RouteTransactionWorkProgressId);
        setErrorsStepper(infoNotification.RejectOperations);

        if (infoNotification.RouteTransactionWorkProgressId >= 3) {
          setIsInProcessOptimize(false);
        }

        if (infoNotification.RouteTransactionWorkProgressId === 3) {
          onCompletedOptimize?.();
        }
      }
    },
    [routeId, activeStep, onCompletedOptimize],
  );

  useClientOnMethod(
    hubConnection,
    METHODS_NAME.REFRESH_ROUTE_MAPLINK_TRANSACTION,
    updateStepper,
  );

  return (
    <DialogOptimizeRouteContainer open={open} maxWidth="sm">
      <DialogTitle>Optimizar ruta</DialogTitle>

      <DialogOptimizeRouteContent dividers>
        {!enabledStepper && (
          <Stack spacing={2}>
            <DialogContentText>
              Seleccione la opción <strong>OPTIMIZAR</strong> para proceder.
            </DialogContentText>

            <Alert variant="outlined" severity="warning">
              <AlertTitle>Optimizar la ruta actual puede implicar:</AlertTitle>

              <List disablePadding>
                {infoTextList.map((info, index) => (
                  <ListItem
                    key={`dialog-optimize-route-info-${index}`}
                    disablePadding
                    alignItems="flex-start"
                  >
                    <DialogOptimizeRouteInfoListItemListIcon>
                      <DotIcon fontSize="inherit" color="inherit" />
                    </DialogOptimizeRouteInfoListItemListIcon>

                    <DialogOptimizeRouteInfoListItemText
                      primary={info}
                      primaryTypographyProps={{
                        variant: 'caption',
                        color: 'inherit',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Alert>
          </Stack>
        )}

        {enabledStepper && (
          <StepperGenerateRoute
            activeStep={activeStep}
            workProgressId={workProgressId}
            errors={errorsStepper}
            type="optimize"
            light={false}
          />
        )}
      </DialogOptimizeRouteContent>

      <DialogOptimizeRouteActions>
        <Button
          color="secondary"
          onClick={onClose}
          disabled={isInProcessOptimize}
        >
          Cerrar
        </Button>

        {!enabledStepper && (
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loadingOptimizeRoute}
            onClick={handleOptimize}
          >
            Optimizar
          </LoadingButton>
        )}

        {onGenerateNewRoute && errorsStepper.length > 0 && (
          <Button
            color="error"
            onClick={() => onGenerateNewRoute(errorsStepper)}
          >
            Generar nuevo plan de viaje
          </Button>
        )}
      </DialogOptimizeRouteActions>
    </DialogOptimizeRouteContainer>
  );
};

export default DialogOptimizeRoute;

import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const ConfigDepositFormMapContainer = styled('div')({
  width: '100%',
  height: '100%',
  maxHeight: '100%',

  '& .mapboxgl-map': {
    borderRadius: 10,
  },
});

export const ConfigDepositFormSubmitButton = styled(LoadingButton)({
  minWidth: 200,
});

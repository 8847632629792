import { ReactElement, useMemo } from 'react';
import { Typography, Stack } from '@mui/material';
import {
  faLayerGroup as SubscriptionsIcon,
  faHourglassStart as ActiveDateIcon,
  faCalendarTimes as ExpiredDateIcon,
  faRotate as CycleIcon,
  faMoneyBill as MoneyIcon,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';

import { formatterDate, formatCurrencyValue } from '~utils/index';

import {
  SubscriptionPlanCardRoot,
  SubscriptionPlanCardContent,
} from '../../PlanCard/styles';
import SubscriptionPlanCardRowInfo from '../../PlanCard/RowInfo';
import { useSubscriptionPaymentModalContext } from '../SubscriptionPaymentModalContext';
import { getPaymentTypeMultiplier, getPaymentTypeAclaration } from '../utils';

import { SubscriptionPaymentModalDetailsRoot } from './styles';

const currentDate = moment().startOf('day');

const SubscriptionPaymentModalDetails = (): ReactElement => {
  const { paymentType, itemData, isArg, isSinglePayment } =
    useSubscriptionPaymentModalContext();

  const expiredDate = useMemo(() => {
    const monthsToAdd = getPaymentTypeMultiplier(paymentType);

    return currentDate.clone().add(monthsToAdd, 'months').startOf('day');
  }, [paymentType]);

  const activatedText = useMemo(
    () => (isSinglePayment ? 'Fecha de alta:' : 'Inicio del ciclo:'),
    [isSinglePayment],
  );

  const expiredText = useMemo(
    () => (isSinglePayment ? 'Vencimiento:' : 'Cierre de ciclo:'),
    [isSinglePayment],
  );

  return (
    <SubscriptionPaymentModalDetailsRoot>
      <Stack spacing={2}>
        <Typography fontWeight="bold">Detalle de tu selección</Typography>

        <SubscriptionPlanCardRoot>
          <SubscriptionPlanCardContent sx={{ gridTemplateColumns: '1fr' }}>
            <Stack spacing={0.5}>
              <SubscriptionPlanCardRowInfo
                value={itemData.title}
                icon={SubscriptionsIcon}
                textColor="primary"
                iconColor="primary"
              />

              <SubscriptionPlanCardRowInfo
                description={activatedText}
                value={formatterDate(currentDate, {
                  format: 'DD/MM/YYYY',
                })}
                icon={ActiveDateIcon}
                textColor="textSecondary"
              />

              {(!isArg || isSinglePayment || paymentType === 'annual') && (
                <SubscriptionPlanCardRowInfo
                  description={expiredText}
                  value={formatterDate(expiredDate, {
                    format: 'DD/MM/YYYY',
                  })}
                  icon={ExpiredDateIcon}
                  textColor="textSecondary"
                />
              )}

              {!isSinglePayment && (
                <SubscriptionPlanCardRowInfo
                  description="Ciclo de facturación:"
                  value={getPaymentTypeAclaration(paymentType)}
                  icon={CycleIcon}
                  textColor="textSecondary"
                />
              )}

              <SubscriptionPlanCardRowInfo
                description="A pagar:"
                value={formatCurrencyValue(itemData.price.USD)}
                icon={MoneyIcon}
                textColor="textPrimary"
              />
            </Stack>
          </SubscriptionPlanCardContent>
        </SubscriptionPlanCardRoot>
      </Stack>

      <Stack>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="body2" fontWeight={500}>
            TOTAL USD
          </Typography>

          <Typography variant="h6" component="span" fontWeight="bold">
            {formatCurrencyValue(itemData.price.USD, {
              style: 'decimal',
              minimumFractionDigits: 2,
            })}
          </Typography>
        </Stack>
      </Stack>
    </SubscriptionPaymentModalDetailsRoot>
  );
};

export default SubscriptionPaymentModalDetails;

import { ReactElement, memo } from 'react';

import { useOrderOnAgendaStateInfo } from '~hooks/index';

import { OrderOnAgendaStateChipProps } from './types';
import { OrderOnAgendaStateChipRoot } from './styles';

const OrderOnAgendaStateChip = ({
  stateId,
  size = 'medium',
}: OrderOnAgendaStateChipProps): ReactElement => {
  const { description, color } = useOrderOnAgendaStateInfo(stateId);

  return (
    <OrderOnAgendaStateChipRoot
      variant="filled"
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(OrderOnAgendaStateChip);

import { useCallback, memo } from 'react';
import { useControl, ControlPosition, MapRef } from 'react-map-gl';
import { styleSprite } from '~constants/mapStyleSprite';
import { controlLocale } from '~constants/mapControlLocale';

import MapboxGLButtonControl from './MapboxGLButtonControl';

export interface ModeSateliteControlProps {
  position?: ControlPosition;
}

export const ModeSateliteControl = ({
  position,
}: ModeSateliteControlProps): null => {
  const changeMode = useCallback((map: MapRef) => {
    const currentStyle = map.getStyle().sprite;

    const newStyle =
      currentStyle === styleSprite.normal
        ? styleSprite.satelite
        : styleSprite.normal;

    map.getMap().setStyle(newStyle, { diff: false });
  }, []);

  useControl(
    ({ map }) =>
      new MapboxGLButtonControl({
        className: 'mapbox-gl-sat',
        title: controlLocale.ModeSatelite,
        eventHandler: () => changeMode(map),
      }),
    { position },
  );

  return null;
};

export default memo(ModeSateliteControl);

import { ReactElement, useMemo, useCallback, useEffect } from 'react';
import {
  Typography,
  DialogContent,
  Stack,
  TextField,
  DialogActions,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';

import { getSelectedPartnerCompanyInfo } from '~utils/partnerCompany';
import { useSendInvitationPartnerCompany } from '~hooks/index';
import { hasError } from '~utils/index';

import { DialogResendInvitationPartnerCompanyProps } from './types';
import {
  DialogResendInvitationPartnerCompanyContainer,
  DialogResendInvitationPartnerCompanyTitle,
  DialogResendInvitationPartnerCompanyTitleCloseIcon,
} from './styles';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const DialogResendInvitationPartnerCompany = ({
  open,
  onClose,
  onResendSendInvitation,
  partnerCompanyInvited,
}: DialogResendInvitationPartnerCompanyProps): ReactElement => {
  const title = useMemo(() => {
    const currentParterCompanyInvitedDescription =
      getSelectedPartnerCompanyInfo(partnerCompanyInvited);

    return `Reenviar invitación a: ${currentParterCompanyInvitedDescription}`;
  }, [partnerCompanyInvited]);

  const handleSuccessSendInvitation = useCallback(() => {
    onResendSendInvitation?.();
    onClose();
  }, [onResendSendInvitation, onClose]);

  const { loading, handleSendInvitationPartnerCompany } =
    useSendInvitationPartnerCompany(handleSuccessSendInvitation, 'resend');

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await handleSendInvitationPartnerCompany({
        partnerCompanyId: partnerCompanyInvited.partnerCompanyId,
        email: values[FIELDS_NAME.EMAIL],
      });

      setSubmitting(false);
    },
    [partnerCompanyInvited, handleSendInvitationPartnerCompany],
  );

  const {
    submitForm,
    isSubmitting,
    dirty,
    getFieldProps,
    touched,
    errors,
    resetForm,
  } = useFormik({ initialValues, validationSchema, onSubmit });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogResendInvitationPartnerCompanyContainer open={open} maxWidth="md">
      <DialogResendInvitationPartnerCompanyTitle>
        <Typography component="h2" variant="h6">
          {title}
        </Typography>

        <DialogResendInvitationPartnerCompanyTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogResendInvitationPartnerCompanyTitleCloseIcon>
      </DialogResendInvitationPartnerCompanyTitle>

      <DialogContent dividers>
        <Stack spacing={2}>
          <TextField
            label="Para"
            placeholder="nombre@example.com"
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
            {...getFieldProps(FIELDS_NAME.EMAIL)}
            InputLabelProps={{ shrink: true }}
          />

          <Typography variant="caption">
            Puede reenviar la invitación por email, ingresándolo en el campo de
            arriba y cliqueando en Enviar email
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loading || isSubmitting}
          disabled={!dirty}
        >
          Reenviar invitación
        </LoadingButton>
      </DialogActions>
    </DialogResendInvitationPartnerCompanyContainer>
  );
};

export default DialogResendInvitationPartnerCompany;

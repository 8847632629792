import {
  ReactElement,
  ElementRef,
  SyntheticEvent,
  ChangeEvent,
  useMemo,
  useCallback,
  useState,
  useRef,
} from 'react';
import {
  Stack,
  TextField,
  MenuItem,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  FormGroup,
  FormHelperText,
  TextFieldProps,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import { find, throttle } from 'lodash';
import Map, {
  NavigationControl,
  FullscreenControl,
  Marker,
  MarkerDragEvent,
} from 'react-map-gl';

import { PlacesAutocomplete, MapMarkerDragHint } from '~components/index';
import { Marker as MarkerIcon } from '~components/Icons';
import { ModeSateliteControl } from '~components/MapCustomControl';
import { PlaceType } from '~hooks/usePlacesAutocomplete';
import { hasError } from '~utils/index';
import { TOKEN } from '~utils/mapbox';
import { Nullable } from '~globals/types';

import { useDialogAssignCarrierCompanyContext } from '../../DialogAssignCarrierCompanyContext';
import { FIELDS_NAME, getInitialValues } from '../../utils';
import { DialogAssignCarrierCompanyMapContainer } from '../../styles';

const DialogAssignCarrierCompanyCollect = (): ReactElement => {
  const {
    warehouses: { list: warehousesList },
  } = useDialogAssignCarrierCompanyContext();

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    validateForm,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const [
    initialValueRequestPlacesAutocomplete,
    setInitialValueRequestPlacesAutocomplete,
  ] = useState<string | undefined>(values[FIELDS_NAME.ADDRESS] ?? undefined);

  const handleChangeNeedeCollect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      setFieldValue(FIELDS_NAME.NEED_COLLECT, checked);

      setTimeout(() => {
        setFieldValue(FIELDS_NAME.ORIGIN_POINT, '');
        setFieldValue(FIELDS_NAME.ADDRESS, '');
        setFieldValue(FIELDS_NAME.ADDRESS_COORDS, null);
        setFieldValue(FIELDS_NAME.ADDRESS_NOTES, '');

        setInitialValueRequestPlacesAutocomplete(undefined);
      }, 200);
    },
    [setFieldValue, setInitialValueRequestPlacesAutocomplete],
  );

  const getWarehouseData = useCallback(
    (warehouseId: string) => find(warehousesList, { warehouseId }),
    [warehousesList],
  );

  const handleChangeOriginPoint = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { value } = event.target;

      setFieldValue(FIELDS_NAME.ORIGIN_POINT, value);
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, null);

      const currentWarehouseData = getWarehouseData(value);
      const currentAddres = currentWarehouseData?.address ?? undefined;
      const currentCoords: Nullable<google.maps.LatLngLiteral> =
        currentWarehouseData
          ? {
              lat: currentWarehouseData.latitude,
              lng: currentWarehouseData.longitude,
            }
          : null;

      setTimeout(() => {
        setFieldValue(FIELDS_NAME.ADDRESS, currentAddres);
        setFieldValue(FIELDS_NAME.ADDRESS_COORDS, currentCoords);
        setFieldValue(
          FIELDS_NAME.ADDRESS_NOTES,
          currentWarehouseData?.addressNotes ?? '',
        );

        setInitialValueRequestPlacesAutocomplete(currentAddres);
      }, 200);
    },
    [getWarehouseData, setFieldValue, setInitialValueRequestPlacesAutocomplete],
  );

  const placesAutocompleteRef =
    useRef<ElementRef<typeof PlacesAutocomplete>>(null);

  const getCoordAddress = useMemo(
    () =>
      throttle<google.maps.Geocoder['geocode']>((request, callback) => {
        new window.google.maps.Geocoder().geocode(request, callback);
      }, 200),
    [],
  );

  const handleChangeAddress = useCallback(
    (_e: SyntheticEvent<Element, Event>, newValue: PlaceType | null) => {
      setFieldValue(FIELDS_NAME.ADDRESS, newValue?.description ?? '');
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, null);

      getCoordAddress({ placeId: newValue?.place_id }, (results) => {
        if (results) {
          setFieldValue(
            FIELDS_NAME.ADDRESS_COORDS,
            results[0].geometry.location.toJSON(),
          );
        }
      });
    },
    [getCoordAddress, setFieldValue],
  );

  const handleBlurAddress = useCallback((): void => {
    setFieldTouched(FIELDS_NAME.ADDRESS, true);
  }, [setFieldTouched]);

  const handleOnDragEndMapEvent = useCallback(
    (e: MarkerDragEvent): void => {
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
      setFieldTouched(FIELDS_NAME.ADDRESS_COORDS);
    },
    [setFieldValue, setFieldTouched],
  );

  const handleChangeTimeWindowAllDay = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      await setFieldValue(FIELDS_NAME.TIME_WINDOW_ALL_DAY, checked);
      await setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_FROM, null);
      await setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_TO, null);
      await setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_FROM, null);
      await setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_TO, null);

      await validateForm();
    },
    [setFieldValue, validateForm],
  );

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFieldValue(fieldName, value);
      },
    [setFieldValue],
  );

  const handleBlurDate = useCallback(
    (fieldName: string) => (): void => {
      setFieldTouched(fieldName, true);
    },
    [setFieldTouched],
  );

  const isDisabledTimeWindowDates = useMemo(
    () => values[FIELDS_NAME.TIME_WINDOW_ALL_DAY],
    [values],
  );

  const renderInputTimePicker = useCallback(
    (props: TextFieldProps, nameField: string) => (
      <TextField
        {...props}
        onBlur={handleBlurDate(nameField)}
        error={hasError(touched, errors, nameField)}
      />
    ),
    [handleBlurDate, touched, errors],
  );

  return (
    <Stack spacing={2} flex={1}>
      <FormControl>
        <FormGroup row sx={{ alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(FIELDS_NAME.NEED_COLLECT)}
                checked={values[FIELDS_NAME.NEED_COLLECT]}
                onChange={handleChangeNeedeCollect}
              />
            }
            label="Solicitar colecta a transportista"
            sx={{ margin: 0 }}
          />
        </FormGroup>

        <FormHelperText>
          Selecciona esta opción para informarle a la empresa de transporte que
          debe retirar los pedidos por tu depósito. En caso contrario, la
          colecta quedará a tu cargo y deberás coordinar el retiro de forma
          particular.
        </FormHelperText>
      </FormControl>

      {values[FIELDS_NAME.NEED_COLLECT] && (
        <Stack spacing={1}>
          <Typography variant="subtitle1" fontWeight="bold">
            Datos para la colecta
          </Typography>

          <Stack direction="row" spacing={3}>
            <Stack spacing={2} flex={1}>
              <TextField
                label="Punto de origen (opcional)"
                fullWidth
                autoComplete="off"
                error={hasError(touched, errors, FIELDS_NAME.ORIGIN_POINT)}
                {...getFieldProps(FIELDS_NAME.ORIGIN_POINT)}
                onChange={handleChangeOriginPoint}
                select
              >
                {warehousesList.map((warehouse) => (
                  <MenuItem
                    key={`origin-${warehouse.warehouseId}`}
                    value={warehouse.warehouseId}
                  >
                    {warehouse.title}
                  </MenuItem>
                ))}
              </TextField>

              <PlacesAutocomplete
                ref={placesAutocompleteRef}
                id="assign-carrier-company-places-autocomplete"
                onChange={handleChangeAddress}
                onBlur={handleBlurAddress}
                error={hasError(touched, errors, FIELDS_NAME.ADDRESS)}
                initialValueRequest={initialValueRequestPlacesAutocomplete}
              />

              <TextField
                label="Notas de dirección (piso, depto, instrucciones)"
                fullWidth
                multiline
                rows={3}
                error={hasError(touched, errors, FIELDS_NAME.ADDRESS_NOTES)}
                {...getFieldProps(FIELDS_NAME.ADDRESS_NOTES)}
              />

              <Stack spacing={2}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" fontWeight="bold">
                    Ventana horaria
                  </Typography>

                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps(FIELDS_NAME.TIME_WINDOW_ALL_DAY)}
                        checked={values[FIELDS_NAME.TIME_WINDOW_ALL_DAY]}
                        onChange={handleChangeTimeWindowAllDay}
                      />
                    }
                    label="Todo el día"
                  />
                </Stack>

                <Stack direction="row" spacing={4}>
                  <Stack direction="row" spacing={1}>
                    <TimePicker
                      label="Desde"
                      value={values[FIELDS_NAME.TIME_WINDOW_ONE_FROM]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) =>
                        renderInputTimePicker(
                          props,
                          FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                        )
                      }
                    />

                    <TimePicker
                      label="Hasta"
                      value={values[FIELDS_NAME.TIME_WINDOW_ONE_TO]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_ONE_TO,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) =>
                        renderInputTimePicker(
                          props,
                          FIELDS_NAME.TIME_WINDOW_ONE_TO,
                        )
                      }
                    />
                  </Stack>

                  <Stack direction="row" spacing={1}>
                    <TimePicker
                      label="Desde"
                      value={values[FIELDS_NAME.TIME_WINDOW_SECOND_FROM]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) =>
                        renderInputTimePicker(
                          props,
                          FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                        )
                      }
                    />

                    <TimePicker
                      label="Hasta"
                      value={values[FIELDS_NAME.TIME_WINDOW_SECOND_TO]}
                      onChange={handleChangeDate(
                        FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                      )}
                      disabled={isDisabledTimeWindowDates}
                      renderInput={(props) =>
                        renderInputTimePicker(
                          props,
                          FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                        )
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack spacing={2} flex={1} maxWidth={400}>
              {values[FIELDS_NAME.ADDRESS_COORDS] && (
                <DialogAssignCarrierCompanyMapContainer>
                  <Map
                    mapboxAccessToken={TOKEN}
                    mapStyle="mapbox://styles/mapbox/light-v10"
                    initialViewState={{
                      longitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lng,
                      latitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lat,
                      zoom: 14,
                    }}
                    attributionControl={false}
                  >
                    <FullscreenControl position="bottom-left" />
                    <NavigationControl
                      position="bottom-left"
                      showCompass={false}
                    />
                    <ModeSateliteControl position="bottom-left" />

                    <Marker
                      longitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lng}
                      latitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lat}
                      onDragEnd={handleOnDragEndMapEvent}
                      anchor="bottom"
                      draggable
                    >
                      <MarkerIcon color="primary" fontSize="large" />
                    </Marker>

                    <MapMarkerDragHint />
                  </Map>
                </DialogAssignCarrierCompanyMapContainer>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DialogAssignCarrierCompanyCollect;

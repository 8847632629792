import { ReactElement } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube as CubeIcon } from '@fortawesome/free-solid-svg-icons';

import { ItemTaskStatusChip } from '~components/index';

import { ItemTasksListProps } from './types';

const ItemTasksList = ({ list }: ItemTasksListProps): ReactElement => {
  if (!list.length) {
    return (
      <Typography variant="h6" fontWeight="bold" textAlign="center">
        No hay artículos / tareas cargados
      </Typography>
    );
  }

  return (
    <List dense>
      {list.map((listItem) => (
        <ListItem
          key={`task-${listItem.itemId}-${listItem.itemTaskId}`}
          divider
        >
          <ListItemIcon sx={{ minWidth: 0, marginRight: 2 }}>
            <FontAwesomeIcon icon={CubeIcon} />
          </ListItemIcon>

          <ListItemText
            primary={listItem.description}
            secondary={listItem.notes}
            secondaryTypographyProps={{ variant: 'caption' }}
          />

          <ListItemIcon sx={{ minWidth: 0, marginLeft: 2 }}>
            <ItemTaskStatusChip statusId={listItem.itemTaskStatusTypeId} />
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

export default ItemTasksList;

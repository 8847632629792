import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute as RouteIcon } from '@fortawesome/free-solid-svg-icons';

import { formatterDistanceToKm } from '~utils/formatter';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { DistanceInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const DistanceInfo = ({
  distance,
  fontSize = 14,
  fontWeight,
  withTooltip = false,
  useCustomIcon = false,
}: DistanceInfoProps): ReactElement => {
  const Icon = useMemo(
    () =>
      useCustomIcon ? (
        <img src={toAbsoluteUrl('/icons/icon_route.svg')} alt="Route Icon" />
      ) : (
        <FontAwesomeIcon icon={RouteIcon} />
      ),
    [useCustomIcon],
  );

  const text = useMemo(() => formatterDistanceToKm(distance ?? 0), [distance]);

  const tooltipMessage = useMemo(
    () => (withTooltip ? 'Distancia estimada' : undefined),
    [withTooltip],
  );

  return (
    <DescriptionWithIconBox
      icon={Icon}
      description={text}
      size={fontSize}
      iconTooltip={tooltipMessage}
      fontWeight={fontWeight}
    />
  );
};

export default memo(DistanceInfo);

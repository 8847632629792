import { ReactElement, forwardRef, HTMLProps, memo } from 'react';
import { Tooltip } from '@mui/material';
import { isUndefined } from 'lodash';
import classNames from 'classnames';

import { DescriptionWithIconBoxProps } from './types';
import {
  classes,
  DescriptionWithIconBoxContainer,
  DescriptionWithIconBoxIconContainer,
  DescriptionWithIconBoxDescriptionContainer,
  DescriptionWithIconBoxText,
  DescriptionWithIconBoxTextLink,
} from './styles';

export const DescriptionWithIconBoxTooltipIcon = forwardRef<
  HTMLSpanElement,
  HTMLProps<HTMLSpanElement>
>(function DescriptionWithErrorTooltipIcon(props, ref) {
  return <span {...props} ref={ref} />;
});

const DescriptionWithIconBox = ({
  icon,
  iconPosition = 'left',
  iconColor,
  iconTooltip,
  iconClassName,
  description,
  caption,
  size = 16,
  fontWeight,
  isDescriptionLink = false,
  descriptionHref,
}: DescriptionWithIconBoxProps): ReactElement => (
  <DescriptionWithIconBoxContainer
    iconPosition={iconPosition}
    size={size}
    className={classes.container}
  >
    {icon && (
      <DescriptionWithIconBoxIconContainer
        color={iconColor}
        className={classNames(classes.iconContainer, iconClassName)}
        size={size}
        useCaption={!isUndefined(caption)}
      >
        {iconTooltip ? (
          <Tooltip title={iconTooltip} arrow>
            <DescriptionWithIconBoxTooltipIcon>
              {icon}
            </DescriptionWithIconBoxTooltipIcon>
          </Tooltip>
        ) : (
          icon
        )}
      </DescriptionWithIconBoxIconContainer>
    )}

    <DescriptionWithIconBoxDescriptionContainer
      className={classes.descriptionContainer}
    >
      {isDescriptionLink && !!descriptionHref ? (
        <DescriptionWithIconBoxTextLink
          to={descriptionHref}
          color="primary"
          variant="inherit"
          underline="hover"
          fontWeight={fontWeight}
          className={classes.descriptionTextLink}
        >
          {description}
        </DescriptionWithIconBoxTextLink>
      ) : (
        <DescriptionWithIconBoxText
          variant="inherit"
          fontWeight={fontWeight}
          color="inherit"
          className={classes.descriptionText}
        >
          {description}
        </DescriptionWithIconBoxText>
      )}

      {caption && (
        <DescriptionWithIconBoxText
          variant="caption"
          color="text.secondary"
          className={classes.descriptionTextCaption}
        >
          {caption}
        </DescriptionWithIconBoxText>
      )}
    </DescriptionWithIconBoxDescriptionContainer>
  </DescriptionWithIconBoxContainer>
);

export default memo(DescriptionWithIconBox);

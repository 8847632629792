import mapboxgl from 'mapbox-gl';
import _ from 'lodash';

import { RouteItemStateType, RouteStateTypes } from '~globals/types/enums';
import { ApiTransactionRouteItem } from '~services/apiTransaction/types';
import { AuthExtendedUser } from '~services/auth/types';
import { Nullable } from '~globals/types';

import { isAdminUser } from './user';

export const routeStopOrderPendingList = [
  RouteItemStateType.New,
  RouteItemStateType.OnAgenda,
  RouteItemStateType.InProgress,
];

export const routeStopOrderCompleteList = [
  RouteItemStateType.FinalizedSuccess,
  RouteItemStateType.FinalizedError,
  RouteItemStateType.WithoutVisiting,
];

export const routeStopOrderFinalizedList = [
  RouteItemStateType.FinalizedSuccess,
  RouteItemStateType.FinalizedError,
];

export const assignedDriverEnabledList = [
  RouteStateTypes.New,
  RouteStateTypes.Rejected,
];

export const deleteRouteEnabledListForAdmin = [
  RouteStateTypes.InProgress,
  RouteStateTypes.Finalized,
];

export const exportTicketsEnabledList = [
  RouteStateTypes.New,
  RouteStateTypes.Assigned,
  RouteStateTypes.Approve,
  RouteStateTypes.Rejected,
  RouteStateTypes.InProgress,
];

export const updatedRouteEnabledList = [
  RouteStateTypes.Assigned,
  RouteStateTypes.Approve,
  RouteStateTypes.Rejected,
  RouteStateTypes.InProgress,
];

export const showFeedbackEnabledList = [RouteStateTypes.Finalized];

export const isRouteStopOrderPending = (state: RouteItemStateType): boolean =>
  routeStopOrderPendingList.includes(state);

export const isRouteStopOrderComplete = (state: RouteItemStateType): boolean =>
  routeStopOrderCompleteList.includes(state);

export const isRouteStopOrderFinalized = (state: RouteItemStateType): boolean =>
  routeStopOrderFinalizedList.includes(state);

export const isEnabledToAssginedDriver = (state: RouteStateTypes): boolean =>
  assignedDriverEnabledList.includes(state);

export const isEnabledShowFeedback = (state: RouteStateTypes): boolean =>
  showFeedbackEnabledList.includes(state);

export const getRouteItemCoords = (
  routeItem: ApiTransactionRouteItem,
): mapboxgl.LngLat => {
  const longitude = (routeItem.warehouse?.longitude ??
    routeItem.item?.longitude) as number;
  const latitude = (routeItem.warehouse?.latitude ??
    routeItem.item?.latitude) as number;

  return new mapboxgl.LngLat(longitude, latitude);
};

export const validateRouteItemDistanceFromAddress = (
  comparator: 'gt' | 'gte' | 'lt' | 'lte',
  distance: number,
  maxDistance: number,
): boolean => {
  const comparatorFunc = _[comparator];

  return comparatorFunc(distance, maxDistance);
};

export const isRouteEnabledToDelete = (
  user: Nullable<AuthExtendedUser>,
  routeState: RouteStateTypes,
): boolean => {
  if (!isAdminUser(user)) {
    return !deleteRouteEnabledListForAdmin.includes(routeState);
  }

  return true;
};

export const isRouteEnabledToExportTickets = (
  state: RouteStateTypes,
): boolean => exportTicketsEnabledList.includes(state);

export const isRouteEnabldeToUpdate = (state: RouteStateTypes): boolean =>
  updatedRouteEnabledList.includes(state);

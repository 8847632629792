import { ReactElement, useCallback, useEffect } from 'react';
import { Stack, TextField, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useFormik, FormikHelpers } from 'formik';

import { useLazyRequest } from '~hooks/index';
import {
  validateInvitation,
  associatePartnerCompany,
} from '~services/partnerCompany';
import { PartnerCompanyInvitationData } from '~services/partnerCompany/types';
import { hasError } from '~utils/index';

import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import { getProgressValue } from '../../utils';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const InitialSetupWizardLinkAsociatedCompany = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    step: { handleNextStep, currentStep },
    companyType: { isCarrier: isCarrierSelectedCompany },
    relatedAssociatedCompany: { setData: setRelatedAssociatedCompanyData },
  } = useInitialSetupWizardContext();

  const handleSkip = useCallback(() => {
    handleNextStep(isCarrierSelectedCompany ? 'loadVehicle' : 'loadDeposit');
  }, [handleNextStep, isCarrierSelectedCompany]);

  const [, loadingConfirmCompany, , executeConfirmCompany] = useLazyRequest({
    request: associatePartnerCompany,
    withPostSuccess: () => {
      enqueueSnackbar('La vinculación se ha aceptado correctamente', {
        variant: 'success',
      });

      handleNextStep('relatedAssociatedCompanyInfo');
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const [, loadingValidatedInvitation, , executeValidatedInvitation] =
    useLazyRequest({
      request: validateInvitation,
      withPostSuccess: async (response) => {
        const relatedAssociatedCompanyData = response.data
          ?.data as PartnerCompanyInvitationData;

        setRelatedAssociatedCompanyData(relatedAssociatedCompanyData);

        if (relatedAssociatedCompanyData?.invitationCode) {
          await executeConfirmCompany(
            relatedAssociatedCompanyData.invitationCode,
          );
        }
      },
      withPostFailure: (err) => {
        let errorMessage = 'Ha ocurrido un error, intente nuevamente';

        if (err.status === 404) {
          errorMessage = 'Codigo de invitación invalido';
        }

        if (err?.data?.data.message) {
          errorMessage = err.data.data.message;
        }

        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
    });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await executeValidatedInvitation(values[FIELDS_NAME.INVITATION_CODE]);

      setSubmitting(false);
    },
    [executeValidatedInvitation],
  );

  const {
    submitForm,
    isSubmitting,
    getFieldProps,
    touched,
    errors,
    dirty,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <InitialSetupWizardStepContainer
      stepId="linkAssociatedCompany"
      minHeight={550}
      maxWidth={600}
    >
      <StepTitleWithProgress
        progress={getProgressValue(isCarrierSelectedCompany, 1)}
        title="Vincula una empresa"
        // eslint-disable-next-line max-len
        description="Si posees un código de invitación para asociarte a una empresa, puedes ingrasrlos aquí."
      />

      <InitialSetupWizardModalStepContent>
        <Stack spacing={2} flex={1} justifyContent="center" alignItems="center">
          <TextField
            label="Código de Invitación"
            fullWidth
            autoComplete="off"
            autoFocus
            error={hasError(touched, errors, FIELDS_NAME.INVITATION_CODE)}
            {...getFieldProps(FIELDS_NAME.INVITATION_CODE)}
            InputLabelProps={{ shrink: true }}
            sx={{
              maxWidth: 280,

              '& input': {
                fontSize: 24,
                fontWeight: 'bold',
              },
            }}
          />

          <Link
            target="_blank"
            underline="hover"
            fontWeight="bold"
            variant="caption"
            sx={{ cursor: 'pointer' }}
            onClick={handleSkip}
          >
            No tengo un código de invitación
          </Link>
        </Stack>

        <Stack spacing={2} alignItems="center">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={submitForm}
            loading={
              loadingValidatedInvitation ||
              loadingConfirmCompany ||
              isSubmitting
            }
            disabled={!dirty}
            sx={{ width: 280 }}
          >
            Siguiente
          </LoadingButton>
        </Stack>
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardLinkAsociatedCompany;

import { ReactElement } from 'react';

import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import { getProgressValue } from '../../utils';

import BusinessProfileProvider from './BusinessProfileContext';
import BusinessProfileTabs from './Tabs';

const InitialSetupWizardBusinessProfile = (): ReactElement => {
  return (
    <InitialSetupWizardStepContainer
      stepId="businessProfile"
      minHeight={550}
      maxWidth={600}
    >
      <StepTitleWithProgress
        progress={getProgressValue(true, 1)}
        title="Ajusta la interfaz de trabajo"
        // eslint-disable-next-line max-len
        description="Define el perfil de tu negocio, según tus respuestas, ajustaremos nuestra plataforma, comunicación y funcionalidades para brindarte el mejor soporte posible."
      />

      <InitialSetupWizardModalStepContent>
        <BusinessProfileProvider>
          <BusinessProfileTabs />
        </BusinessProfileProvider>
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardBusinessProfile;

import {
  styled,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';

export const classes = {
  expanded: 'ChargeStatusMapLegend-expanded',
  expandIcon: 'ChargeStatusMapLegend-expandIcon',
};

export const ChargeStatusMapLegendContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 200,
  background: theme.palette.common.white,
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1.25),
  boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
  zIndex: 1,
}));

export const ChargeStatusMapLegendListButton = styled(ListItemButton)(
  ({ theme }) => ({
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(
      ['border-radius', 'background-color'],
      { duration: theme.transitions.duration.shortest },
    ),

    [`& .${classes.expandIcon}`]: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.action.active,
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },

    [`&.${classes.expanded}`]: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

      [`& .${classes.expandIcon}`]: {
        transform: 'rotate(180deg)',
      },
    },
  }),
);

export const ChargeStatusMapLegendListItemText = styled(ListItemText)({
  margin: 0,
});

export const ChargeStatusMapLegendContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(0.5, 1),
  paddingTop: theme.spacing(1),
}));

export const ChargeStatusMapLegendProgressLabelContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const ChargeStatusMapLegendProgressLabelText = styled(Typography)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
  }),
);

export const ChargeStatusMapLegendProgressLabelTextSmall = styled(Typography)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
  }),
);

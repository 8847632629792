import * as Yup from 'yup';
import moment from 'moment';

import { Nullable } from '~globals/types';
import { IntegrationDeliveryTypes } from '~globals/types/enums';
import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  DELIVERY_TYPE: 'deliveryType',
} as const;

const defaultDateFrom = moment().startOf('day').subtract(1, 'day');
const defaultDateTo = moment().endOf('day').subtract(59, 'minute');

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (includeDeliveryType = false) => ({
  [FIELDS_NAME.DATE_FROM]: defaultDateFrom.toDate() as Nullable<Date>,
  [FIELDS_NAME.DATE_TO]: defaultDateTo.toDate() as Nullable<Date>,
  [FIELDS_NAME.DELIVERY_TYPE]: includeDeliveryType
    ? IntegrationDeliveryTypes.SelfService
    : '',
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValidationSchema = (includeDeliveryType = false) =>
  Yup.object().shape({
    [FIELDS_NAME.DATE_FROM]: Yup.date()
      .nullable()
      .typeError(VALIDATIONS_ERROR.INVALID_DATE)
      .required(VALIDATIONS_ERROR.REQUIRED),
    [FIELDS_NAME.DATE_TO]: Yup.date()
      .nullable()
      .typeError(VALIDATIONS_ERROR.INVALID_DATE)
      .required(VALIDATIONS_ERROR.REQUIRED),
    [FIELDS_NAME.DELIVERY_TYPE]: includeDeliveryType
      ? Yup.string().required(VALIDATIONS_ERROR.REQUIRED)
      : Yup.string().notRequired(),
  });

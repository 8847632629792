import { Map } from 'mapbox-gl';
import { IControl } from 'react-map-gl';
import MapboxDraw, { MapboxDrawOptions } from '@mapbox/mapbox-gl-draw';
import classNames from 'classnames';

import { Nullable } from '~globals/types';
import { controlLocale } from '~constants/mapControlLocale';

import { SelectionControlButton } from './types';

class SelectionBar implements IControl {
  map: Nullable<Map>;

  container: Nullable<HTMLElement>;

  ctrl: MapboxDraw;

  buttons: SelectionControlButton[];

  constructor(options?: MapboxDrawOptions, buttons?: SelectionControlButton[]) {
    this.map = null;
    this.container = null;
    this.ctrl = new MapboxDraw(options);
    this.buttons = buttons ?? [];
  }

  private addButton(options: SelectionControlButton) {
    const elButton = document.createElement('button');

    elButton.className = classNames(
      'mapbox-gl-draw_ctrl-draw-btn',
      options.className,
    );
    elButton.type = 'button';
    elButton.title = options.title;
    elButton.onclick = (event) => options.eventHandler(event, this);

    this.container?.appendChild(elButton);
  }

  onAdd(map: Map): HTMLElement {
    this.map = map;
    this.container = this.ctrl.onAdd(map);

    this.container
      .querySelector('.mapbox-gl-draw_polygon')
      ?.setAttribute('title', controlLocale['Draw.Polygon']);

    this.buttons.forEach((button) => {
      this.addButton(button);
    });

    return this.container;
  }

  onRemove(map: Map): void {
    this.container?.parentNode?.removeChild(this.container);
    this.map = null;
    this.ctrl.onRemove(map);
  }

  activedButton(selector: string): void {
    this.container?.querySelector(selector)?.classList.add('active');
  }

  desactivedButton(selector: string): void {
    this.container?.querySelector(selector)?.classList.remove('active');
  }

  toggleActiveButton(selector: string): void {
    this.container?.querySelector(selector)?.classList.toggle('active');
  }
}

export default SelectionBar;

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { sendInvitationPartnerCompany } from '~services/partnerCompany';
import { PartnerCompanyAssociationSendEmailBody } from '~services/partnerCompany/types';

import { useLazyRequest } from './useLazyRequest';

export const useSendInvitationPartnerCompany = (
  onSuccessSendInvitation?: () => void,
  type: 'send' | 'resend' = 'send',
): {
  loading: boolean;
  handleSendInvitationPartnerCompany: (
    body: PartnerCompanyAssociationSendEmailBody,
  ) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loading, , executeSendInvitationPartnerCompany] = useLazyRequest({
    request: sendInvitationPartnerCompany,
    withPostSuccess: () => {
      const prefixMessageSuccess = type === 'send' ? 'envió' : 'reenvío';

      enqueueSnackbar(
        `Se ${prefixMessageSuccess} la invitación correctamente`,
        { variant: 'success' },
      );

      onSuccessSendInvitation?.();
    },
    withPostFailure: () => {
      const prefixMessageError = type === 'send' ? 'enviar' : 'reenviar';

      enqueueSnackbar(
        `No se pudo ${prefixMessageError} la invitación, intenté nuevamente`,
        { variant: 'error' },
      );
    },
  });

  const handleSendInvitationPartnerCompany = useCallback(
    async (body: PartnerCompanyAssociationSendEmailBody) => {
      await executeSendInvitationPartnerCompany(body);
    },
    [executeSendInvitationPartnerCompany],
  );

  return {
    loading,
    handleSendInvitationPartnerCompany,
  };
};

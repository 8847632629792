import { ReactElement, useCallback } from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import {
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  FormGroup,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { hasError, getItemValue } from '~utils/index';
import { getDefaultAvatar } from '~utils/user';
import {
  FileUpload,
  PasswordVisibilityAdornment,
  NewFeatureBagde,
  RolesField,
} from '~components/index';
import { useLazyRequest, useToggle, useTypePartnerCompany } from '~hooks/index';
import { uploadImages } from '~services/files/index';
import { FileResponse } from '~services/files/types';
import { createAdminUser } from '~services/admin/users';
import { AdminUser, AdminUserCreateBody } from '~services/admin/users/types';
import { STATUS_CODES, PATHS } from '~constants/index';
import { DataResultsResponse, Nullable } from '~globals/types';

import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import { ConfigUserFormAvatar, ConfigUserFormSubmitButton } from './styles';

const ConfigUserForm = (): ReactElement => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isCarrier } = useTypePartnerCompany();

  const [, loadingCreateUser, , executeCreateUser] = useLazyRequest({
    request: createAdminUser,
    withPostSuccess: (response) => {
      const userResponse = response.data?.data as AdminUser;
      const userName = `${userResponse.lastName}, ${userResponse.firstName}`;
      const message = `El usuario ${userName} fue creado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      navigate(PATHS.CONFIG.USERS.BASE);
    },
    withPostFailure: (error) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (error.data?.data.errorCode === STATUS_CODES.userExist) {
        errorMessage =
          // eslint-disable-next-line max-len
          'El email que ingresado ya se encuentra registrado. Por favor, modifíquelo e intenté nuevamente.';
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      try {
        const avatarValue = values[FIELDS_NAME.AVATAR];
        let avatarFileId: Nullable<string> = null;

        if (avatarValue.length > 0) {
          const dataUploadFile = await uploadImages(avatarValue);
          const {
            data: { results },
          } = dataUploadFile.data as DataResultsResponse<FileResponse[]>;

          avatarFileId = results[0].fileId;
        }

        const body: AdminUserCreateBody = {
          avatarFileId,
          email: values[FIELDS_NAME.EMAIL],
          password: values[FIELDS_NAME.PASSWORD],
          firstName: values[FIELDS_NAME.FIRST_NAME],
          lastName: values[FIELDS_NAME.LAST_NAME],
          idNumber: getItemValue(values, FIELDS_NAME.DOCUMENT_NUMBER),
          code: getItemValue(values, FIELDS_NAME.REFERENCE),
          contactPhone: getItemValue(values, FIELDS_NAME.CONTACT_PHONE),
          contactMobile: getItemValue(values, FIELDS_NAME.CONTACT_MOBILE),
          roleId: Number(values[FIELDS_NAME.ROLE]),
          enabled: values[FIELDS_NAME.ENABLED],
          isDriver: values[FIELDS_NAME.IS_DRIVER],
        };

        await executeCreateUser(body);
      } catch (_err) {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [enqueueSnackbar, executeCreateUser],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { errors, touched, getFieldProps, handleSubmit, values, isSubmitting } =
    formikBag;

  const [showPassword, togglePassword] = useToggle();
  const [showConfirmPassword, toggleConfirmPassword] = useToggle();

  return (
    <FormikProvider value={formikBag}>
      <Stack spacing={2} component="form" onSubmit={handleSubmit}>
        <Stack spacing={2} direction="row">
          <Stack spacing={2} flex={1}>
            <Typography component="h6" fontWeight="bold">
              Información de contacto
            </Typography>

            <Stack spacing={2} direction="row" alignItems="center">
              <FileUpload
                name={FIELDS_NAME.AVATAR}
                label="Subir avatar"
                enabledChipsInfo={false}
              />

              <ConfigUserFormAvatar
                src={getDefaultAvatar(
                  values.avatar.length > 0
                    ? URL.createObjectURL(values.avatar[0].file)
                    : undefined,
                )}
              />
            </Stack>

            <TextField
              label="Email"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
              {...getFieldProps(FIELDS_NAME.EMAIL)}
            />

            <TextField
              label="Contraseña"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.PASSWORD)}
              {...getFieldProps(FIELDS_NAME.PASSWORD)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityAdornment
                    showPassword={showPassword}
                    onClick={() => togglePassword()}
                    position="end"
                  />
                ),
              }}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Confirmar Contraseña"
              error={hasError(touched, errors, FIELDS_NAME.CONFIRM_PASSWORD)}
              {...getFieldProps(FIELDS_NAME.CONFIRM_PASSWORD)}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityAdornment
                    showPassword={showConfirmPassword}
                    onClick={() => toggleConfirmPassword()}
                    position="end"
                  />
                ),
              }}
            />

            <TextField
              label="Nombre"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.FIRST_NAME)}
              {...getFieldProps(FIELDS_NAME.FIRST_NAME)}
            />

            <TextField
              label="Apellido"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.LAST_NAME)}
              {...getFieldProps(FIELDS_NAME.LAST_NAME)}
            />

            <TextField
              label="Número de documento (Opcional)"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.DOCUMENT_NUMBER)}
              {...getFieldProps(FIELDS_NAME.DOCUMENT_NUMBER)}
            />

            <TextField
              label="Referencia (Opcional)"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.REFERENCE)}
              {...getFieldProps(FIELDS_NAME.REFERENCE)}
            />

            <TextField
              label="Teléfono (Opcional)"
              placeholder="Cod de área sin 0 + Nro sin 15"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.CONTACT_PHONE)}
              {...getFieldProps(FIELDS_NAME.CONTACT_PHONE)}
              helperText="Cod de área sin 0 + Nro sin 15"
            />

            <TextField
              label="Celular / Whatsapp (Opcional)"
              placeholder="Cod de área sin 0 + Nro sin 15"
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.CONTACT_MOBILE)}
              {...getFieldProps(FIELDS_NAME.CONTACT_MOBILE)}
              helperText="Cod de área sin 0 + Nro sin 15"
            />
          </Stack>

          <Stack spacing={2} flex={1}>
            <Typography component="h6" fontWeight="bold">
              Información del sistema
            </Typography>

            <RolesField
              label="Rol"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.ROLE)}
              {...getFieldProps(FIELDS_NAME.ROLE)}
            />

            <FormControlLabel
              control={
                <Switch
                  {...getFieldProps(FIELDS_NAME.ENABLED)}
                  checked={values[FIELDS_NAME.ENABLED]}
                />
              }
              label="Activo"
            />

            {isCarrier && (
              <FormControl>
                <FormGroup row sx={{ alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps(FIELDS_NAME.IS_DRIVER)}
                        checked={values[FIELDS_NAME.IS_DRIVER]}
                      />
                    }
                    label="Permitir ser chofer"
                    sx={{ margin: 0 }}
                  />

                  <NewFeatureBagde activeForVersion="1.13.0" />
                </FormGroup>

                <FormHelperText>
                  Al activar esta opción, el usuario podrá utilizar la app para
                  choferes y se permitirá la asignación y realización de viajes
                  de la empresa
                </FormHelperText>
              </FormControl>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2}>
          <ConfigUserFormSubmitButton
            type="submit"
            variant="contained"
            color="primary"
            loading={loadingCreateUser || isSubmitting}
          >
            Crear
          </ConfigUserFormSubmitButton>
        </Stack>
      </Stack>
    </FormikProvider>
  );
};

export default ConfigUserForm;

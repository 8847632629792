import { useMemo } from 'react';
import { get } from 'lodash';
import { green, yellow, red } from '@mui/material/colors';
import { useTheme } from '@mui/material';

import { TransactionStatusTypes } from '~globals/types/enums';

interface TransactionStateInfoItem {
  description: string;
  color: string;
}

type TransactionStateList = {
  [state in TransactionStatusTypes]: TransactionStateInfoItem;
};

export const useTransactionStateInfo = (
  state: TransactionStatusTypes,
): TransactionStateInfoItem => {
  const currentTheme = useTheme();

  const stateList = useMemo<TransactionStateList>(
    () => ({
      [TransactionStatusTypes.New]: {
        description: 'Nueva',
        color: currentTheme.palette.tertiary.main,
      },
      [TransactionStatusTypes.Pending]: {
        description: 'Pendiente',
        color: yellow[700],
      },
      [TransactionStatusTypes.Approved]: {
        description: 'Aprobada',
        color: green[700],
      },
      [TransactionStatusTypes.Rejected]: {
        description: 'Rechazada',
        color: red['500'],
      },
      [TransactionStatusTypes.Processed]: {
        description: 'Procesada',
        color: currentTheme.palette.primary.main,
      },
    }),
    [currentTheme],
  );

  const currentInfo = useMemo<TransactionStateInfoItem>(
    () => get(stateList, state),
    [state, stateList],
  );

  return currentInfo;
};

import { ReactElement, useCallback } from 'react';
import { Typography, IconButton, Link } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Menu } from '~components/index';
import { MenuProps } from '~components/Menu/types';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { useTypePartnerCompany } from '~hooks/index';

import {
  FirstStepsMenuCompanyType,
  FirstStepsMenuStepInfoCompanyType,
} from './types';
import { STEPS_INFO } from './stepsInfo';
import {
  FirstStepsMenuContainer,
  FirstStepsMenuHeader,
  FirstStepsMenuTutorialVideoLink,
  FirstStepsMenuTutorialVideoImg,
  FirstStepsMenuContent,
  FirstStepsMenuContentStep,
  FirstStepsMenuContentStepIndicator,
  FirstStepsMenuContentStepInfo,
  FirstStepsMenuContentStepInfoActions,
  FirstStepsMenuContentStepInfoActionsButton,
} from './styles';

const FirstStepsMenu = ({
  onClose,
  ...restProps
}: Omit<MenuProps, 'children'>): ReactElement => {
  const { isCarrier } = useTypePartnerCompany();

  const getDataByCompanyType = useCallback(
    <D,>(
      data: FirstStepsMenuStepInfoCompanyType<D>,
    ): FirstStepsMenuStepInfoCompanyType<D>['carrier'] => {
      const currentCompanyType: FirstStepsMenuCompanyType = isCarrier
        ? 'carrier'
        : 'giver';

      return data[currentCompanyType];
    },
    [isCarrier],
  );

  return (
    <Menu onClose={onClose} {...restProps}>
      <FirstStepsMenuContainer>
        <FirstStepsMenuHeader>
          <Typography component="h3" variant="subtitle1" fontWeight="medium">
            PRIMEROS PASOS EN ROUTIX
          </Typography>

          <IconButton
            size="small"
            aria-label="Close"
            onClick={onClose}
            color="inherit"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FirstStepsMenuHeader>

        <FirstStepsMenuTutorialVideoLink
          href="https://youtu.be/l6u3Y1gUm90"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FirstStepsMenuTutorialVideoImg
            src={toAbsoluteUrl('/images/video_importer_ordes_pic.png')}
            alt="Import my orders"
          />
        </FirstStepsMenuTutorialVideoLink>

        <FirstStepsMenuContent>
          {STEPS_INFO.map(
            ({ id: stepId, title, description, actions }, stepIndex) => (
              <FirstStepsMenuContentStep
                key={`first-steps-menu-${stepId}-step`}
              >
                <FirstStepsMenuContentStepIndicator>
                  <Typography
                    component="span"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {stepIndex + 1}
                  </Typography>
                </FirstStepsMenuContentStepIndicator>

                <FirstStepsMenuContentStepInfo>
                  <Typography component="h6" variant="body2" fontWeight="bold">
                    {getDataByCompanyType(title)}
                  </Typography>

                  <Typography variant="caption" lineHeight={1.2}>
                    {getDataByCompanyType(description)}
                  </Typography>

                  <FirstStepsMenuContentStepInfoActions>
                    {getDataByCompanyType(actions).map(
                      ({ id: actionId, label, path, defaultButtonProps }) => (
                        <FirstStepsMenuContentStepInfoActionsButton
                          key={`first-steps-menu-${stepId}-step-${actionId}-action`}
                          {...defaultButtonProps}
                          to={path}
                          onClick={onClose}
                        >
                          {label}
                        </FirstStepsMenuContentStepInfoActionsButton>
                      ),
                    )}
                  </FirstStepsMenuContentStepInfoActions>
                </FirstStepsMenuContentStepInfo>
              </FirstStepsMenuContentStep>
            ),
          )}
        </FirstStepsMenuContent>

        <Link
          color="primary"
          variant="caption"
          fontWeight="bold"
          href="http://help.routix.io/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClose}
        >
          Otros temas de ayuda...
        </Link>
      </FirstStepsMenuContainer>
    </Menu>
  );
};

export default FirstStepsMenu;

import { ReactElement, useCallback } from 'react';

import {
  SubscriptionPaymentTypeSelectorProps,
  SubscriptionPaymentTypeSelectorClickHandler,
} from './types';
import {
  SubscriptionPaymentTypeSelectorRoot,
  SubscriptionPaymentTypeSelectorButton,
} from './styles';

const SubscriptionPaymentTypeSelector = ({
  value,
  onChange,
}: SubscriptionPaymentTypeSelectorProps): ReactElement => {
  const handleChangePaymentType =
    useCallback<SubscriptionPaymentTypeSelectorClickHandler>(
      (event, newPaymentType) => {
        onChange(event, newPaymentType);
      },
      [onChange],
    );

  return (
    <SubscriptionPaymentTypeSelectorRoot
      disableElevation
      variant="outlined"
      color="inherit"
    >
      <SubscriptionPaymentTypeSelectorButton
        selected={value === 'monthly'}
        onClick={(event) => handleChangePaymentType(event, 'monthly')}
      >
        Pago mensual
      </SubscriptionPaymentTypeSelectorButton>

      <SubscriptionPaymentTypeSelectorButton
        selected={value === 'annual'}
        onClick={(event) => handleChangePaymentType(event, 'annual')}
      >
        Pago anual <b>Descuento exclusivo</b>
      </SubscriptionPaymentTypeSelectorButton>
    </SubscriptionPaymentTypeSelectorRoot>
  );
};

export default SubscriptionPaymentTypeSelector;

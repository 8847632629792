import { ReactElement, useMemo, memo } from 'react';

import { useUtcOffset } from '~hooks/index';
import { IntegrationAccountInfoProps } from './types';
import {
  IntegrationAccountInfoContainer,
  IntegrationAccountInfoNameTypography,
  IntegrationAccountInfoCreationDateTypography,
} from './styles';

const IntegrationAccountInfo = ({
  name,
  creationDateTime,
  fontSize = 14,
  fontWeight = 'bold',
}: IntegrationAccountInfoProps): ReactElement => {
  const formatterDateUtcOffset = useUtcOffset();

  const parseDate = useMemo(
    () => formatterDateUtcOffset(creationDateTime, 'DD/MM/YYYY HH:mm'),
    [formatterDateUtcOffset, creationDateTime],
  );

  return (
    <IntegrationAccountInfoContainer size={fontSize}>
      <IntegrationAccountInfoNameTypography
        variant="inherit"
        fontWeight={fontWeight}
      >
        {name}
      </IntegrationAccountInfoNameTypography>

      <IntegrationAccountInfoCreationDateTypography size={fontSize}>
        {`Fecha de creación: ${parseDate}`}
      </IntegrationAccountInfoCreationDateTypography>
    </IntegrationAccountInfoContainer>
  );
};

export default memo(IntegrationAccountInfo);

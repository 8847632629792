import { FC, createContext, useContext } from 'react';

import { StepperGenerateRouteContextValue } from './types';

const StepperGenerateRouteContext =
  createContext<StepperGenerateRouteContextValue>({
    activeStep: 0,
    workProgressId: 1,
    errors: [],
    type: 'generate',
    light: true,
    showErrors: true,
  });

export const useStepperGenerateRoutePlanningStepperContext =
  (): StepperGenerateRouteContextValue =>
    useContext(StepperGenerateRouteContext);

const StepperGenerateRouteProvider: FC<{
  value: StepperGenerateRouteContextValue;
}> = ({ children, value }) => (
  <StepperGenerateRouteContext.Provider value={value}>
    {children}
  </StepperGenerateRouteContext.Provider>
);

export default StepperGenerateRouteProvider;

import { styled, Stack, Accordion } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const OrderFormContent = styled(Stack)({
  overflow: 'hidden',
  height: '100%',
  maxHeight: 'calc(100vh - 238px)',
  minHeight: 'calc(100vh - 238px)',
});

export const OrderFormInputsContent = styled(Stack)(({ theme }) => ({
  overflowY: 'auto',
  paddingRight: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

export const OrderFormSectionContainer = styled(Accordion)(({ theme }) => ({
  margin: 0,
  borderRadius: theme.shape.borderRadius,

  '&:first-of-type': {
    marginTop: 0,
  },

  '&:last-of-type': {
    marginBottom: 0,
  },

  '&:before': {
    display: 'none',
  },
}));

export const OrderFormMapContainer = styled('div')({
  // maxHeight: 186,
  width: '100%',
  height: '100%',

  '& .mapboxgl-map': {
    borderRadius: 10,
  },
});

export const OrderFormSubmitButton = styled(LoadingButton)({
  minWidth: 200,
});

import { styled, Box, Typography } from '@mui/material';

interface IntegrationAccountInfoCommonComponentProps {
  size: number;
}

export const IntegrationAccountInfoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})<IntegrationAccountInfoCommonComponentProps>(({ theme, size }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  fontSize: theme.typography.pxToRem(size),
}));

export const IntegrationAccountInfoNameTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

export const IntegrationAccountInfoCreationDateTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'size',
})<IntegrationAccountInfoCommonComponentProps>(({ theme, size }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: theme.typography.pxToRem(size - 3),
  color: theme.palette.text.secondary,
}));

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { Nullable } from '~globals/types';
import { AuthSubscription } from '~services/auth/types';

export const useSubscription = (): Nullable<AuthSubscription> => {
  const { subscription } = useAppSelector(selectAuth);

  return subscription;
};

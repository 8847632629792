import {
  ReactElement,
  ElementRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { Stack } from '@mui/material';
import Map, {
  NavigationControl,
  FullscreenControl,
  Marker,
  MarkerDragEvent,
} from 'react-map-gl';
import { useSnackbar } from 'notistack';

import { TOKEN } from '~utils/mapbox';
import { useLazyRequest, useRequest } from '~hooks/index';
import { createItem } from '~services/item';
import { AuthExtendedCompany } from '~services/auth/types';
import { getGiverCompanies } from '~services/partnerCompany';
import { Marker as MarkerIcon } from '~components/Icons';
import { ModeSateliteControl } from '~components/MapCustomControl';
import { MapMarkerDragHint } from '~components/index';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';

import { OrderFormProvider } from './OrderFormContext';
import {
  OrderFormDestinationInformationSection,
  OrderFormContactInformationSection,
  OrderFormSecurityToolsSection,
  OrderFormVisitDetailsSection,
  OrderFormArticlesSection,
} from './Sections';
import { OrderFormProps } from './types';
import {
  FIELDS_NAME,
  getInitialValues,
  validationSchema,
  getBodyRequestAddOrder,
} from './utils';
import {
  OrderFormContent,
  OrderFormInputsContent,
  OrderFormMapContainer,
  OrderFormSubmitButton,
} from './styles';
import PlacesAutocomplete from '../PlacesAutocomplete';

const OrderForm = ({
  itemData,
  operationsOptions,
  priorityOptions,
  skillsNeededTypes,
}: OrderFormProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentAssociatedCompanies, setCurrentAssociatedCompanies] = useState<
    AuthExtendedCompany[]
  >([]);

  const [, loadingCurrentAssociatedCompanies] = useRequest({
    request: getGiverCompanies,
    payload: null,
    withPostSuccess: (response) => {
      const currentAssociatedCompaniesResponse = response.data?.data
        .results as AuthExtendedCompany[];

      setCurrentAssociatedCompanies(currentAssociatedCompaniesResponse);
    },
  });

  const [, loadingAddOrderRequest, errorAddOrder, executeAddOrder] =
    useLazyRequest({
      request: createItem,
      withPostSuccess: () => {
        enqueueSnackbar('Pedido creado correctamente', { variant: 'success' });
      },
      withPostFailure: () => {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      },
    });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      await executeAddOrder(getBodyRequestAddOrder(values));

      setSubmitting(false);
    },
    [executeAddOrder],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(itemData),
    validationSchema,
    onSubmit,
  });

  const {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = formikBag;

  const placesAutocompleteRef =
    useRef<ElementRef<typeof PlacesAutocomplete>>(null);

  const skillsNeededAutocompleteRef =
    useRef<CustomAutocompleteRef<string, true>>(null);

  const currentAssociatedCompaniesAutocompleteRef =
    useRef<CustomAutocompleteRef<AuthExtendedCompany, false>>(null);

  useEffect(() => {
    if (!loadingAddOrderRequest && !errorAddOrder) {
      resetForm();
      placesAutocompleteRef.current?.resetAutocomplete();
      skillsNeededAutocompleteRef.current?.resetAutocomplete();
      currentAssociatedCompaniesAutocompleteRef.current?.resetAutocomplete();
    }
  }, [loadingAddOrderRequest, errorAddOrder, resetForm]);

  const handleOnDragEndEvent = useCallback(
    (e: MarkerDragEvent): void => {
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
      setFieldTouched(FIELDS_NAME.ADDRESS_COORDS);
    },
    [setFieldValue, setFieldTouched],
  );

  return (
    <FormikProvider value={formikBag}>
      <OrderFormProvider
        value={{
          itemData,
          refs: {
            placesAutocompleteRef,
            skillsNeededAutocompleteRef,
            currentAssociatedCompaniesAutocompleteRef,
          },
          enumSelects: {
            operationTypes: operationsOptions,
            priorityTypes: priorityOptions,
            skillsNeededTypes,
          },
          currentAssociatedCompanies: {
            list: currentAssociatedCompanies,
            loading: loadingCurrentAssociatedCompanies,
          },
        }}
      >
        <Stack spacing={2} component="form" onSubmit={handleSubmit}>
          <OrderFormContent direction="row" spacing={2}>
            <OrderFormInputsContent spacing={2} flex={1}>
              <OrderFormDestinationInformationSection />

              <OrderFormContactInformationSection />

              <OrderFormSecurityToolsSection />

              <OrderFormVisitDetailsSection />

              <OrderFormArticlesSection />
            </OrderFormInputsContent>

            <Stack spacing={2} flex={0.7}>
              {values[FIELDS_NAME.ADDRESS_COORDS] && (
                <OrderFormMapContainer>
                  <Map
                    mapboxAccessToken={TOKEN}
                    mapStyle="mapbox://styles/mapbox/light-v10"
                    initialViewState={{
                      longitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lng,
                      latitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lat,
                      zoom: 14,
                    }}
                    attributionControl={false}
                  >
                    <FullscreenControl position="bottom-left" />
                    <NavigationControl
                      position="bottom-left"
                      showCompass={false}
                    />
                    <ModeSateliteControl position="bottom-left" />

                    <Marker
                      longitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lng}
                      latitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lat}
                      onDragEnd={handleOnDragEndEvent}
                      anchor="bottom"
                      draggable
                    >
                      <MarkerIcon color="primary" fontSize="large" />
                    </Marker>
                    <MapMarkerDragHint />
                  </Map>
                </OrderFormMapContainer>
              )}
            </Stack>
          </OrderFormContent>

          <Stack spacing={2} direction="row">
            <OrderFormSubmitButton
              type="submit"
              variant="contained"
              color="primary"
              loading={loadingAddOrderRequest || isSubmitting}
            >
              Agregar
            </OrderFormSubmitButton>
          </Stack>
        </Stack>
      </OrderFormProvider>
    </FormikProvider>
  );
};

export default OrderForm;

import { find, get, filter } from 'lodash';

import { ActivePlan, SubscriptionActivePlan } from '~services/plan/types';
import { PLANS_PRICE_DATA, PlanPriceData } from '~constants/plansData';
import { CompanyPlanStatusTypes, PlanTypes } from '~globals/types/enums';

export const getPlanGeneralInfo = (
  name: string | undefined,
): PlanPriceData | undefined =>
  name ? find(PLANS_PRICE_DATA, { name }) : undefined;

export const isCancelledCurrentPlan = (
  companyPlanStatusTypeId: CompanyPlanStatusTypes,
): boolean => companyPlanStatusTypeId === CompanyPlanStatusTypes.Cancelled;

export const validateActivePlanType = (
  activePlan: ActivePlan,
  planTypeId: PlanTypes,
): boolean => {
  const currentPlanTypeId = get(activePlan, [
    'contractPlan',
    'plan',
    'planTypeId',
  ]);

  return currentPlanTypeId === planTypeId;
};

export const parseSubscriptionActivePlan = (
  activePlan: ActivePlan,
): SubscriptionActivePlan => {
  let payment: SubscriptionActivePlan['payment'] = undefined;

  if (activePlan.paymentMethod && activePlan.payerCardLast4Digits) {
    payment = {
      method: activePlan.paymentMethod,
      last4Digits: activePlan.payerCardLast4Digits,
    };
  }

  const invoiceFile: SubscriptionActivePlan['invoiceFile'] =
    activePlan.transaction?.invoiceFile ?? undefined;

  const gatewayType: SubscriptionActivePlan['gatewayType'] =
    activePlan.transaction?.paymentGatewayTypeId ?? null;

  return {
    planId: activePlan.contractPlan.planId,
    name: activePlan.contractPlan.plan.description,
    price: activePlan.contractPlan.price,
    statusType: activePlan.companyPlanStatusTypeId,
    activedDate: activePlan.validFromDateTime,
    expiredDate: activePlan.validTillDateTime,
    contractPlanId: activePlan.contractPlanId,
    isTrial: activePlan.contractPlan.isTrial,
    gatewayType,
    frecuencyType: activePlan.contractPlan.paymentFrecuencyTypeId,
    routes: {
      used: activePlan.consumedRoutes,
      max: activePlan.contractPlan.plan.maxRoutes,
    },
    companyPlanId: activePlan.companyPlanId,
    payment,
    invoiceFile,
  };
};

export const getCurrentSubscriptionActivePlan = (
  activePlans: ActivePlan[],
): SubscriptionActivePlan | undefined => {
  const currentActivePlan = find(activePlans, (activePlan) =>
    validateActivePlanType(activePlan, PlanTypes.Plan),
  );

  return currentActivePlan
    ? parseSubscriptionActivePlan(currentActivePlan)
    : undefined;
};

export const getCurrentSubscriptionActivePackages = (
  activePlans: ActivePlan[],
): SubscriptionActivePlan[] =>
  filter(activePlans, (currentActivePlan) =>
    validateActivePlanType(currentActivePlan, PlanTypes.Pack),
  ).map((activePlan) => parseSubscriptionActivePlan(activePlan));

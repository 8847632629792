import { ReactElement, useMemo } from 'react';
import { Card, List } from '@mui/material';

import { formmaterSecondToHumanizeTime, getItemValue } from '~utils/index';

import { PlanningIndicatorsProps, PlanningIndicatorColumn } from './types';
import {
  PlanningIndicatorsListSubHeader,
  PlanningIndicatorsListItem,
  PlanningIndicatorsListText,
} from './styles';

const PlanningIndicators = ({
  data,
  isRouteCollect = false,
}: PlanningIndicatorsProps): ReactElement => {
  const indicatorsColumns = useMemo<PlanningIndicatorColumn[]>(
    () => [
      {
        field: 'totalWorkingTime',
        label: 'Tiempo total de servicio',
        formatter: (val) => formmaterSecondToHumanizeTime(val),
      },
      {
        field: 'totalDrivingTime',
        label: 'Tiempo total de manejo',
        formatter: (val) => formmaterSecondToHumanizeTime(val),
      },
      {
        field: 'dayWorkingTotalTime',
        label: 'Jornada de día',
        formatter: (val) => formmaterSecondToHumanizeTime(val),
      },
      {
        field: 'nightWorkingTotalTime',
        label: 'Jornada de noche',
        formatter: (val) => formmaterSecondToHumanizeTime(val),
      },
      {
        field: 'averageOccupancyRateWeight',
        label:
          'Tasa de ocupación media carga primaria (En KG, L, dinero, bultos)',
      },
      {
        field: 'averageOccupancyRateVolume',
        label:
          'Tasa de ocupación media carga secundaria (En KG, L, dinero, bultos)',
      },
      {
        field: 'rejectOperationsNumber',
        label: 'Paradas rechazadas',
      },
      {
        field: 'routesNumber',
        label: 'Rutas generadas',
      },
    ],
    [],
  );

  return (
    <Card variant="outlined">
      <List
        subheader={
          <PlanningIndicatorsListSubHeader color="primary">
            {`Indicadores de plan de ${isRouteCollect ? 'colecta' : 'viaje'}`}
          </PlanningIndicatorsListSubHeader>
        }
        disablePadding
      >
        {indicatorsColumns.map((indicator) => (
          <PlanningIndicatorsListItem
            key={`planning-indicator-${indicator.field}`}
          >
            <PlanningIndicatorsListText
              primary={indicator.label}
              primaryTypographyProps={{
                variant: 'body2',
              }}
              secondary={getItemValue(
                data,
                indicator.field,
                indicator.formatter,
              )}
              secondaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </PlanningIndicatorsListItem>
        ))}
      </List>
    </Card>
  );
};

export default PlanningIndicators;

import { ReactElement, ChangeEvent, useCallback, MouseEvent } from 'react';
import { Stack, FormLabel } from '@mui/material';

import { useHandleFile } from '~hooks/index';

import { FileUploadV2Props } from './types';
import {
  FileUploadV2Formcontrol,
  FileUploadV2Input,
  FileUploadV2LabelImg,
  FileUploadV2LabelImgText,
  FileUploadV2Chip,
} from './styles';

const FileUploadV2 = ({
  name,
  labelImg: { src, alt, color = 'text.secondary' },
  accept = 'image/*, application/pdf',
  multiple = false,
  disabled = false,
  enabledChipsInfo = true,
}: FileUploadV2Props): ReactElement => {
  const { files, handleAddFile, handleRemoveFile } = useHandleFile({
    nameField: name,
    multiple,
  });

  const resetInput = useCallback((event: MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = '';
  }, []);

  return (
    <Stack spacing={1} alignItems="center">
      <FileUploadV2Formcontrol>
        <FileUploadV2Input
          id={name}
          name={name}
          type="file"
          inputProps={{ accept, multiple, onClick: resetInput }}
          disabled={disabled}
          onChange={(evt) =>
            handleAddFile(evt as ChangeEvent<HTMLInputElement>)
          }
        />

        <FormLabel htmlFor={name}>
          <FileUploadV2LabelImg src={src} alt={alt} />
        </FormLabel>

        {!disabled && (
          <FileUploadV2LabelImgText variant="caption" color={color}>
            Seleccione el archivo...
          </FileUploadV2LabelImgText>
        )}
      </FileUploadV2Formcontrol>

      {enabledChipsInfo && files.length > 0 && (
        <Stack spacing={1} direction="row">
          {files.map(({ id, file }) => (
            <FileUploadV2Chip
              key={id}
              label={file.name}
              onDelete={() => handleRemoveFile(id)}
              size="small"
              disabled={disabled}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default FileUploadV2;

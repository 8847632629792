import { ReactElement, MouseEventHandler, useCallback, useMemo } from 'react';
import { LoadingButton } from '@mui/lab';

import { useAccessFeatureModalContext } from '~app/contexts/AccessFeatureModalContext';
import { useSubscriptionFeatures } from '~hooks/index';

import AccessFeatureBadge from '../Badge';

import { AccessFeatureButtonProps } from './types';

const AccessFeatureButton = ({
  validFeature,
  availableToDate,
  showChip = true,
  openAccessFeatureModalOnClickChip = true,
  onClick,
  fullWidth = false,
  disabled: disabledProps,
  ...rest
}: AccessFeatureButtonProps): ReactElement => {
  const { handleOpen: handleOpenAccesFeatureModal } =
    useAccessFeatureModalContext();
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const isValidFeature = useMemo(
    () => hasAccessToFeature(validFeature, availableToDate),
    [validFeature, hasAccessToFeature, availableToDate],
  );

  const disabled = useMemo(
    () => disabledProps ?? !isValidFeature,
    [disabledProps, isValidFeature],
  );

  const openModalOnClickChip = useMemo(
    () => disabled ?? openAccessFeatureModalOnClickChip,
    [openAccessFeatureModalOnClickChip, disabled],
  );

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      if (!isValidFeature) {
        event.preventDefault();

        if (openAccessFeatureModalOnClickChip) {
          handleOpenAccesFeatureModal({
            validFeature,
            validateAvailableRoutesState: false,
          });
        }
      } else {
        onClick?.(event);
      }
    },
    [
      isValidFeature,
      validFeature,
      openAccessFeatureModalOnClickChip,
      handleOpenAccesFeatureModal,
      onClick,
    ],
  );

  return (
    <AccessFeatureBadge
      validFeature={validFeature}
      availableToDate={availableToDate}
      fullWidth={fullWidth}
      openAccessFeatureModalOnClickChip={openModalOnClickChip}
      showChip={showChip}
    >
      <LoadingButton
        fullWidth={fullWidth}
        onClick={handleClick}
        disabled={disabled}
        {...rest}
      />
    </AccessFeatureBadge>
  );
};

export default AccessFeatureButton;

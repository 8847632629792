import {
  ReactElement,
  ChangeEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Stack,
  IconButton,
  Tooltip,
  TextField,
  Typography,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFormikContext, FieldArray, ArrayHelpers } from 'formik';

import { hasError } from '~utils/index';

import { ListFieldProps } from './types';
import { ListFieldContainer, ListFieldLabel } from './styles';

const ListField = ({
  name,
  label,
  description,
  addLabel,
  addTooltip,
  addItemPosition = 'first',
  itemLabel,
  deleteTooltip,
  inputsMaxWidth = 250,
  disabled = false,
}: ListFieldProps): ReactElement => {
  const { values, errors, touched, getFieldProps } = useFormikContext<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: string;
  }>();

  const toPathField = useCallback(
    (index: number) => `${name}[${index}]`,
    [name],
  );

  const listItems = useMemo(
    () => values[name] as unknown as string[],
    [values, name],
  );

  const [newItem, setNewItem] = useState('');

  const handleChangeNewItem = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target ?? event.currentTarget;

      setNewItem(value);
    },
    [],
  );

  const handleAddItemInList = useCallback(
    (insertHelper: ArrayHelpers['insert']) => {
      if (newItem) {
        const insertPositionIndex =
          addItemPosition === 'first' ? 0 : listItems.length;

        insertHelper(insertPositionIndex, newItem);

        setNewItem('');
      }
    },
    [newItem, addItemPosition, listItems],
  );

  return (
    <FieldArray name={name}>
      {({ insert, remove }) => (
        <ListFieldContainer>
          <ListFieldLabel as="legend">{label}</ListFieldLabel>

          {description && (
            <Typography variant="caption" sx={{ marginTop: -2.5 }}>
              {description}
            </Typography>
          )}

          <Stack spacing={2}>
            <Stack spacing={1} direction="row" alignItems="center">
              <TextField
                label={addLabel}
                InputLabelProps={{ shrink: true }}
                size="small"
                value={newItem}
                onChange={handleChangeNewItem}
                fullWidth
                sx={{ maxWidth: inputsMaxWidth }}
                disabled={disabled}
              />

              <Tooltip title={addTooltip} arrow placement="right">
                <IconButton
                  aria-label="Add item"
                  onClick={() => handleAddItemInList(insert)}
                  color="primary"
                  size="small"
                  disabled={disabled}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            {listItems.length > 0 && (
              <Stack spacing={2}>
                {listItems.map((_item, index) => (
                  <Stack
                    key={toPathField(index)}
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >
                    <TextField
                      label={itemLabel}
                      {...getFieldProps(toPathField(index))}
                      error={hasError(touched, errors, toPathField(index))}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      fullWidth
                      disabled={disabled}
                      sx={{ maxWidth: inputsMaxWidth }}
                    />

                    <Tooltip title={deleteTooltip} arrow placement="right">
                      <IconButton
                        aria-label="Delete item"
                        onClick={() => remove(index)}
                        color="error"
                        size="small"
                        disabled={disabled}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
        </ListFieldContainer>
      )}
    </FieldArray>
  );
};

export default ListField;

import { ReactElement } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube as CubeIcon } from '@fortawesome/free-solid-svg-icons';

import { ItemCollectStatusChip } from '~components/index';
import { ItemCollectStatusTypes } from '~globals/types/enums';

import { ItemCollectsListProps } from './types';

const ItemCollectsList = ({ list }: ItemCollectsListProps): ReactElement => {
  if (!list.length) {
    return (
      <Typography variant="h6" fontWeight="bold" textAlign="center">
        No hay pedidos cargados
      </Typography>
    );
  }

  return (
    <List dense>
      {list.map((listItem) => (
        <ListItem
          key={`item-collect-${listItem.itemId}-${listItem.itemCollectId}`}
          divider
        >
          <ListItemIcon sx={{ minWidth: 0, marginRight: 2 }}>
            <FontAwesomeIcon icon={CubeIcon} />
          </ListItemIcon>

          <ListItemText
            primary={listItem.description}
            secondary={listItem.notes}
            secondaryTypographyProps={{ variant: 'caption' }}
          />

          <ListItemIcon sx={{ minWidth: 0, marginLeft: 2 }}>
            <ItemCollectStatusChip
              statusId={
                listItem.itemCollectStatusTypeId ||
                ItemCollectStatusTypes.Pending
              }
            />
          </ListItemIcon>
        </ListItem>
      ))}
    </List>
  );
};

export default ItemCollectsList;

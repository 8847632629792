import { ReactElement, useState, useMemo, useCallback } from 'react';
import {
  DialogTitle,
  DialogContent,
  Stack,
  Button,
  CircularProgress,
  Avatar,
  createFilterOptions,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { find, chain } from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';

import { ApiTransactionRoutes } from '~services/apiTransaction/types';
import { getRoute, updateRouteConfig } from '~services/route';
import { getDriverCompanyAvailablesByRouteId } from '~services/driver';
import { DriverCompanyBase } from '~services/driver/types';
import { searchVehicles } from '~services/vehicle';
import { VehicleSearchItem } from '~services/vehicle/types';
import { Nullable } from '~globals/types';
import {
  useUtcOffset,
  useRequest,
  useDataTableRequest,
  useSelectedRows,
  useLazyRequest,
} from '~hooks/index';
import {
  CustomAutocomplete,
  UserInfo,
  IsAvailableChip,
  DataTable,
  VehicleInfo,
  SkillsListInfo,
  CapabilitiesInfo,
  RouteErrorsList,
} from '~components/index';
import { hasError } from '~utils/formHelpers';
import { getDefaultAvatar } from '~utils/user';
import { formatterDate } from '~utils/formatter';
import { parseSkillsStringToArray } from '~utils/vehicle';
import {
  validateImportErrorsInOrders,
  convertApiTransactionRouteItemInItemExtended,
} from '~utils/order';

import { DialogUpdateRouteProps, DialogUpdateRouteContentProps } from './types';
import { FIELDS_NAME, getInitialValues, validationSchema } from './utils';
import {
  DialogUpdateRouteContainer,
  DialogUpdateRouteLoadingContainer,
  DialogUpdateRouteActions,
  DialogUpdateRouteDriverAlert,
  DialogUpdateRouteCard,
  DialogUpdateRouteCardContent,
  DialogUpdateRouteCardDataTableContainer,
} from './styles';

const KEY_FIELD_VEHICLES = 'vehicleId';
const MAX_SUPPORT_GENERATE_ORDERS = 200;

const DialogUpdateRouteContent = ({
  onClose,
  routeInfo,
  availablesDrivers,
  vehicleDataTable,
  onSuccessSubmit,
}: DialogUpdateRouteContentProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const formatterDateUtcOffset = useUtcOffset();

  const title = useMemo(() => {
    const currentRouteCode = routeInfo.routeCode.toUpperCase();

    const scheduledDateTime = formatterDateUtcOffset(
      routeInfo.scheduledDateTime,
      'DD/MM/YYYY HH:mm',
    );

    return `Modificar asignación de ruta ${currentRouteCode} (${scheduledDateTime})`;
  }, [formatterDateUtcOffset, routeInfo]);

  const currentOrders = useMemo(
    () =>
      routeInfo.routeItems
        .filter((routeItem) => Boolean(routeItem.item))
        .map((routeItem) =>
          convertApiTransactionRouteItemInItemExtended(
            routeItem,
            routeInfo.apiTransactionId,
          ),
        ),
    [routeInfo.apiTransactionId, routeInfo.routeItems],
  );

  const currentDriver = useMemo(
    () =>
      routeInfo.driverId
        ? find(availablesDrivers, { id: routeInfo.driverId })
        : undefined,
    [routeInfo.driverId, availablesDrivers],
  );

  const getDisabledVehicle = useCallback(
    (row: VehicleSearchItem): boolean =>
      row.vehicleId !== routeInfo?.vehicleId &&
      !(row.isAvailable && row.isOpenForWork),
    [routeInfo?.vehicleId],
  );

  const getDisabledVehicleClasses = useCallback(
    (row: VehicleSearchItem): string =>
      classNames({ 'table-row-disabled': getDisabledVehicle(row) }),
    [getDisabledVehicle],
  );

  const defaultSelectedVehicles = useMemo(() => {
    const currentVehicle = routeInfo.vehicleId
      ? find(vehicleDataTable.data, { vehicleId: routeInfo.vehicleId })
      : undefined;

    return currentVehicle ? [currentVehicle] : [];
  }, [routeInfo.vehicleId, vehicleDataTable.data]);

  const defaultSelectedVehiclesIds = useMemo(
    () => defaultSelectedVehicles.map((vehicle) => vehicle.vehicleId),
    [defaultSelectedVehicles],
  );

  const nonSelectablesVehiclesIds = useMemo(
    () =>
      chain(vehicleDataTable.data)
        .filter(getDisabledVehicle)
        .map(KEY_FIELD_VEHICLES)
        .value(),
    [vehicleDataTable.data, getDisabledVehicle],
  );

  const {
    selectedRowsIds: vehiclesId,
    selectedRows: selectedVehicles,
    selectRowsProps: selectRowsPropsVehicles,
  } = useSelectedRows<VehicleSearchItem>(
    KEY_FIELD_VEHICLES,
    defaultSelectedVehicles,
    defaultSelectedVehiclesIds,
    nonSelectablesVehiclesIds,
    'radio',
  );

  const [, loadingUpdateRoute, , executeGenerateUpdateRoute] = useLazyRequest({
    request: updateRouteConfig,
    withPostSuccess: (response) => {
      const routeCode = response.data?.data.routeCode.toUpperCase();

      enqueueSnackbar(`La ruta ${routeCode} fue actualizada correctamente`, {
        variant: 'success',
      });

      onSuccessSubmit?.();

      onClose();
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      await executeGenerateUpdateRoute({
        routeId: routeInfo.routeId,
        driverId: values[FIELDS_NAME.CURRENT_DRIVER],
        vehicleId: vehiclesId[0] as string,
      });

      setSubmitting(false);
    },
    [executeGenerateUpdateRoute, routeInfo.routeId, vehiclesId],
  );

  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    submitForm,
    dirty,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: getInitialValues(currentDriver),
    validationSchema,
    onSubmit,
  });

  const disabledSubmit = useMemo(() => {
    const isSameInitialVehicle = routeInfo.vehicle.vehicleId === vehiclesId[0];

    return (!dirty && isSameInitialVehicle) || !isValid;
  }, [dirty, isValid, routeInfo.vehicle, vehiclesId]);

  const columnsVehicles = useMemo<ColumnDescription<VehicleSearchItem>[]>(
    () => [
      {
        dataField: 'name',
        text: 'Vehículo',
        formatter: (cell, row) => (
          <VehicleInfo
            vehicle={cell}
            vehicleCode={row.referenceCode ?? ''}
            color={row.color}
            fontWeight="bold"
            useCaption
          />
        ),
      },
      {
        dataField: 'skillsList',
        text: 'Cargas esp.',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 200 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'capacity1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.capacity1}
            capacity2={row.capacity2}
            maximumVisits={row.maximumVisits}
            timeWindow={{ timeFrom: row.timeFrom, timeTo: row.timeTo }}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 200 },
      },
      {
        dataField: 'isAvailable',
        text: 'Estado',
        formatter: (_cell, row) => (
          <IsAvailableChip
            isAvailable={row.isAvailable}
            isOpenForWork={row.isOpenForWork}
            isAssigned={row.vehicleId === routeInfo.vehicleId}
            size="medium"
            variant="filled"
          />
        ),
        headerStyle: { width: 200 },
        headerAlign: 'center',
        align: 'center',
      },
    ],
    [routeInfo.vehicleId],
  );

  const indicatorsOrdersCharge = useMemo(
    () =>
      currentOrders.reduce(
        (acc, selectedOrder) => {
          acc.capacity1 += selectedOrder.unit1 * selectedOrder.amount;
          acc.capacity2 += selectedOrder.unit2 * selectedOrder.amount;
          acc.numberOfPackages += selectedOrder.numberOfPackages;

          return acc;
        },
        { capacity1: 0, capacity2: 0, numberOfPackages: 0 },
      ),
    [currentOrders],
  );

  const indicatorsVehiclesCharge = useMemo(
    () =>
      selectedVehicles.reduce(
        (acc, selectedVehicle) => {
          acc.capacity1 += selectedVehicle.capacity1;
          acc.capacity2 += selectedVehicle.capacity2;
          acc.maximumVisits += selectedVehicle.maximumVisits;

          return acc;
        },
        { capacity1: 0, capacity2: 0, maximumVisits: 0 },
      ),
    [selectedVehicles],
  );

  const skillsTotals = useMemo(() => {
    const skillsVehicles = new Set<string>();
    const skillsOrders = new Set<string>();

    selectedVehicles.forEach((selectedVehicle) => {
      const currentVehiclesSkills = parseSkillsStringToArray(
        selectedVehicle.skillsList,
      );

      currentVehiclesSkills.forEach((vehicleSkill) =>
        skillsVehicles.add(vehicleSkill),
      );
    });

    currentOrders.forEach((selectedOrder) => {
      const currentOrdersSkills = parseSkillsStringToArray(
        selectedOrder.skillsNeeded,
      );

      currentOrdersSkills.forEach((orderSkill) => skillsOrders.add(orderSkill));
    });

    return { vehicles: skillsVehicles, orders: skillsOrders };
  }, [selectedVehicles, currentOrders]);

  const validatePresenceOfSkillsOfOrdersInVehicle = useCallback(() => {
    const currentSelectedSkillsOrders = Array.from(skillsTotals.orders);
    const currentSelectedSkillsVehicles = Array.from(skillsTotals.vehicles);

    const copyCurrentSelectedSkillsVehicles = [
      ...currentSelectedSkillsVehicles,
    ];

    for (
      let orderSkillIndex = 0;
      orderSkillIndex < currentSelectedSkillsOrders.length;
      orderSkillIndex++
    ) {
      const vehicleSkillIndex = copyCurrentSelectedSkillsVehicles.indexOf(
        currentSelectedSkillsOrders[orderSkillIndex],
      );

      if (vehicleSkillIndex === -1) return false;

      copyCurrentSelectedSkillsVehicles.splice(vehicleSkillIndex, 1);
    }

    return true;
  }, [skillsTotals]);

  const alertsRoutes = useMemo(() => {
    const alerts: string[] = [];

    if (vehiclesId.length > 0) {
      if (
        indicatorsOrdersCharge.capacity1 > indicatorsVehiclesCharge.capacity1
      ) {
        alerts.push('Capacidad primaria de carga de vehículos excedida.');
      }

      if (
        indicatorsOrdersCharge.capacity2 > indicatorsVehiclesCharge.capacity2
      ) {
        alerts.push('Capacidad secundaria de carga de vehículos excedida.');
      }

      if (currentOrders.length > indicatorsVehiclesCharge.maximumVisits) {
        alerts.push('Cantidad máxima de paradas de los vehículos excedida.');
      }

      if (!validatePresenceOfSkillsOfOrdersInVehicle()) {
        alerts.push(
          'No se seleccionaron vehiculos para todos los tipos de cargas especiales a programar.',
        );
      }

      if (currentOrders.length > MAX_SUPPORT_GENERATE_ORDERS) {
        alerts.push(
          `Se supera el límite de ${MAX_SUPPORT_GENERATE_ORDERS} pedidos por plan de viaje.`,
        );
      }
    }

    if (currentOrders && validateImportErrorsInOrders(currentOrders)) {
      alerts.push(
        // eslint-disable-next-line max-len
        'Algunos pedidos contienen errores y no se podran incluir en las generación del plan de viaje',
      );
    }

    return alerts;
  }, [
    vehiclesId.length,
    currentOrders,
    indicatorsOrdersCharge.capacity1,
    indicatorsOrdersCharge.capacity2,
    indicatorsVehiclesCharge.capacity1,
    indicatorsVehiclesCharge.capacity2,
    indicatorsVehiclesCharge.maximumVisits,
    validatePresenceOfSkillsOfOrdersInVehicle,
  ]);

  return (
    <>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="subtitle2" fontWeight="bold">
              CHOFER ASIGNADO
            </Typography>

            <CustomAutocomplete
              options={availablesDrivers}
              label="Chofer"
              name={FIELDS_NAME.CURRENT_DRIVER}
              defaultValue={currentDriver}
              onChange={(_e, val) => {
                setFieldValue(FIELDS_NAME.CURRENT_DRIVER, val?.id ?? '');
              }}
              onBlur={() => {
                setFieldTouched(FIELDS_NAME.CURRENT_DRIVER, true);
              }}
              error={hasError(touched, errors, FIELDS_NAME.CURRENT_DRIVER)}
              getOptionLabel={(option) => option.displayName}
              getOptionDisabled={(option) =>
                option.id !== currentDriver?.id && !option.isAvailable
              }
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <UserInfo
                    name={option.displayName}
                    urlAvatar={getDefaultAvatar(option.avatar ?? undefined)}
                  />

                  <IsAvailableChip
                    isAvailable={option.isAvailable as boolean}
                    isAssigned={option.id === currentDriver?.id}
                    size="small"
                    variant="filled"
                  />
                </li>
              )}
              startAdornment={(val) =>
                val && (
                  <Avatar
                    src={getDefaultAvatar(val.avatar ?? undefined)}
                    alt={val?.displayName}
                    sx={{ width: 20, height: 20 }}
                  />
                )
              }
              filterOptions={createFilterOptions({
                ignoreAccents: true,
                ignoreCase: true,
                stringify: (option) => option.displayName.replace(',', ''),
              })}
            />

            <DialogUpdateRouteDriverAlert
              severity="info"
              variant="standard"
              icon={false}
            >
              En caso de modificar el chofer, se notificará al actual que su
              ruta ha sido re-asignada. Adicionalmente, se notificará al nuevo
              chofer que dispone de una nueva ruta asignada.
            </DialogUpdateRouteDriverAlert>
          </Stack>

          <DialogUpdateRouteCard variant="outlined">
            <DialogUpdateRouteCardContent>
              <Typography variant="subtitle2" fontWeight="bold">
                VEHÍCULO ASIGNADO
              </Typography>

              <DialogUpdateRouteCardDataTableContainer>
                <DataTable
                  loading={false}
                  data={vehicleDataTable.data}
                  columns={columnsVehicles}
                  keyField={KEY_FIELD_VEHICLES}
                  pagination={{
                    options: {
                      sizePerPage:
                        vehicleDataTable.queryParams.pagination.sizePerPage,
                      page: vehicleDataTable.queryParams.pagination.page,
                      totalSize: vehicleDataTable.totalSize,
                      showTotal: false,
                      hideSizePerPage: true,
                    },
                  }}
                  remote={{
                    pagination: true,
                  }}
                  onTableChange={(type, newState) =>
                    vehicleDataTable.handleChangeTable(type, {
                      pagination: newState,
                    })
                  }
                  selectRow={selectRowsPropsVehicles}
                  rowClasses={getDisabledVehicleClasses}
                  condensed
                />
              </DialogUpdateRouteCardDataTableContainer>
            </DialogUpdateRouteCardContent>
          </DialogUpdateRouteCard>
        </Stack>
      </DialogContent>

      <DialogUpdateRouteActions>
        <Stack flex={1}>
          <RouteErrorsList
            title="Posibles errores encontrados en el plan de viaje"
            errors={alertsRoutes}
          />

          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button color="secondary" onClick={onClose}>
              Cerrar
            </Button>

            <LoadingButton
              variant="contained"
              color="primary"
              onClick={submitForm}
              loading={loadingUpdateRoute || isSubmitting}
              disabled={disabledSubmit}
            >
              Guardar cambios
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogUpdateRouteActions>
    </>
  );
};

const DialogUpdateRoute = ({
  open,
  onClose,
  routeId,
  onSuccessSubmit,
}: DialogUpdateRouteProps): ReactElement => {
  const [routeInfo, setRouteInfo] =
    useState<Nullable<ApiTransactionRoutes>>(null);

  const [, loadingGetRouteInfo] = useRequest({
    request: getRoute,
    payload: routeId,
    withPostSuccess: (response) => {
      const responseRouteInfo = response.data?.data as ApiTransactionRoutes;

      setRouteInfo(responseRouteInfo);
    },
  });

  const [availablesDrivers, setAvailablesDrivers] = useState<
    DriverCompanyBase[]
  >([]);

  const [, loadingGetAvailablesDrivers] = useRequest({
    request: getDriverCompanyAvailablesByRouteId,
    payload: routeId,
    withPostSuccess: (response) => {
      const responseAvailablesDrivers = response.data?.data
        .results as DriverCompanyBase[];

      setAvailablesDrivers(responseAvailablesDrivers);
    },
  });

  const vehicleDataTable = useDataTableRequest({
    request: searchVehicles,
    payload: {
      pagination: {
        page: 1,
        sizePerPage: 25,
      },
      filters: {
        enabled: null,
        query: null,
        scheduleDateTime: formatterDate(moment(), {
          format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          parseToUtc: true,
        }),
      },
    },
  });

  const loading = useMemo(
    () =>
      loadingGetRouteInfo ||
      loadingGetAvailablesDrivers ||
      vehicleDataTable.loading,
    [
      loadingGetAvailablesDrivers,
      loadingGetRouteInfo,
      vehicleDataTable.loading,
    ],
  );

  return (
    <DialogUpdateRouteContainer open={open}>
      {loading && (
        <DialogUpdateRouteLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogUpdateRouteLoadingContainer>
      )}

      {!loading && routeInfo && (
        <DialogUpdateRouteContent
          onClose={onClose}
          routeInfo={routeInfo}
          availablesDrivers={availablesDrivers}
          vehicleDataTable={vehicleDataTable}
          onSuccessSubmit={onSuccessSubmit}
        />
      )}
    </DialogUpdateRouteContainer>
  );
};

export default DialogUpdateRoute;

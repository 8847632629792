import API from '~config/api';
import {
  ApiPromise,
  DataResponse,
  PaginationRequestParams,
  DataPaginateResponse,
  ErrorResponse,
  DataMessageResponse,
} from '~globals/types';

import {
  AdminDriverCompany,
  AdminDriverCompanyFilter,
  AdminDriverCompanyUpdateState,
} from './types';
import { Driver } from '../../driver/types';

export const ADMIN_DRIVER_COMPANY_ENPOINTS = {
  getAdminDriverCompanyById: (driverCompanyId: string): string =>
    `/api/v1/Admin/DriverCompany/${driverCompanyId}`,
  searchAdminDriversCompany: '/api/v1/Admin/DriverCompany/search',
  updateAdminDriverCompanyState: '/api/v1/Admin/DriverCompany/updateState',
  removeAdminDriverCompany: '/api/v1/Admin/DriverCompany/RemoveDriver',
};

export const getAdminDriverCompanyById = (
  driverCompanyId: string,
): ApiPromise<DataResponse<AdminDriverCompany>, ErrorResponse> =>
  API.get<DataResponse<AdminDriverCompany>, ErrorResponse>(
    ADMIN_DRIVER_COMPANY_ENPOINTS.getAdminDriverCompanyById(driverCompanyId),
  );

export const searchAdminDriversCompany = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<AdminDriverCompanyFilter>): ApiPromise<
  DataPaginateResponse<AdminDriverCompany[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<AdminDriverCompany[]>, ErrorResponse>(
    ADMIN_DRIVER_COMPANY_ENPOINTS.searchAdminDriversCompany,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const updateAdminDriverCompanyState = (
  data: AdminDriverCompanyUpdateState,
): ApiPromise<DataResponse<DataMessageResponse>, ErrorResponse> =>
  API.put<DataResponse<DataMessageResponse>, ErrorResponse>(
    ADMIN_DRIVER_COMPANY_ENPOINTS.updateAdminDriverCompanyState,
    data,
  );

export const removeAdminDriverCompany = (
  driverId: string,
): ApiPromise<DataResponse<Driver>, ErrorResponse> =>
  API.get<DataResponse<Driver>, ErrorResponse>(
    ADMIN_DRIVER_COMPANY_ENPOINTS.removeAdminDriverCompany,
    { driverId },
  );

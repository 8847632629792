import { ReactElement } from 'react';
import { Typography, Stack } from '@mui/material';
import { CheckCircleOutlineRounded as SuccessIcon } from '@mui/icons-material';

const SubscriptionPaymentModalProcessAlert = (): ReactElement => (
  <Stack height="100%" spacing={4} justifyContent="center" alignItems="center">
    <Stack alignItems="center">
      <SuccessIcon color="success" sx={{ fontSize: 140 }} />

      <Typography component="h6" fontWeight="bold">
        Pago en curso
      </Typography>

      <Typography component="h6" fontWeight="bold">
        Hemos recibido tu solicitud correctamente
      </Typography>
    </Stack>

    <Stack textAlign="center">
      <Typography color="text.secondary">
        Puedes consultar el estado de tu transacción en las preferencias de tu
        cuenta.
      </Typography>

      <Typography color="text.secondary">
        El comprobante de pago estará disponible en los próximos 5 días hábiles.
      </Typography>
    </Stack>
  </Stack>
);

export default SubscriptionPaymentModalProcessAlert;

import { ReactElement, forwardRef, HTMLProps, memo } from 'react';
import { Tooltip } from '@mui/material';
import { yellow } from '@mui/material/colors';

import { IconWithTooltipProps } from './types';
import { IconWithTooltipContainer } from './styles';

export const DescriptionWithIconBoxTooltipIcon = forwardRef<
  HTMLSpanElement,
  HTMLProps<HTMLSpanElement>
>(function DescriptionWithErrorTooltipIcon(props, ref) {
  return <span {...props} ref={ref} style={{ display: 'flex' }} />;
});

const IconWithTooltip = ({
  icon,
  fontSize = 14,
  color = yellow['800'],
  tooltip,
}: IconWithTooltipProps): ReactElement => (
  <IconWithTooltipContainer color={color} size={fontSize}>
    <Tooltip title={tooltip} arrow>
      <DescriptionWithIconBoxTooltipIcon>
        {icon}
      </DescriptionWithIconBoxTooltipIcon>
    </Tooltip>
  </IconWithTooltipContainer>
);

export default memo(IconWithTooltip);

import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
} from '@mui/material';

export const DialogImportCustomersFromCSVContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
  },
});

export const DialogImportCustomersFromCSVContent = styled(DialogContent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
);

export const DialogImportCustomersFromCSVActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

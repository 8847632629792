import { Serializer, CamelcaseSerializer } from 'cerealizr';
import { camelCase, upperFirst } from 'lodash';

const toUpperCamelCase = (val: string) => upperFirst(camelCase(val));

export const upperCamelCaseSerializer = new Serializer({
  mapAllValues: true,
  defaultTransform: (key: string, value: unknown) => ({
    [toUpperCamelCase(key)]: value,
  }),
});

export const camelCaseSerializer = new CamelcaseSerializer();

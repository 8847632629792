export const controlLocale = {
  'AttributionControl.ToggleAttribution': 'Alternar atribución',
  'AttributionControl.MapFeedback': 'Comentarios sobre el mapa',
  'FullscreenControl.Enter': 'Ingrese a pantalla completa',
  'FullscreenControl.Exit': 'Salir de pantalla completa',
  'GeolocateControl.FindMyLocation': 'Encuentra mi ubicación',
  'GeolocateControl.LocationNotAvailable': 'Ubicación no disponible',
  'LogoControl.Title': 'Mapbox logo',
  'NavigationControl.ResetBearing': 'Restablecer rumbo al norte',
  'NavigationControl.ZoomIn': 'Aumentar el zoom',
  'NavigationControl.ZoomOut': 'Disminuir el zoom',
  'ScaleControl.Feet': 'ft',
  'ScaleControl.Meters': 'm',
  'ScaleControl.Kilometers': 'km',
  'ScaleControl.Miles': 'mi',
  'ScaleControl.NauticalMiles': 'nm',
  'ScrollZoomBlocker.CtrlMessage':
    'Usa ctrl + scroll para hacer zoom en el mapa',
  'ScrollZoomBlocker.CmdMessage': 'Use ⌘ + scroll para hacer zoom en el mapa',
  'TouchPanBlocker.Message': 'Usa dos dedos para mover el mapa',
  ShowDriver: 'Vista de conductor',
  ShowTrip: 'Vista de recorrido',
  ModeSatelite: 'Modo satelite',
  ModeFreeHand: 'Navegar',
  SelecteMarkers: 'Seleccionar marcadores',
  'Draw.Polygon': 'Dibujar',
};

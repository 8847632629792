import { ReactElement, useCallback, useMemo } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { isCarrierPartnerCompany } from '~utils/partnerCompany';
import { PartnerCompanyTypes } from '~globals/types/enums';

import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import { getProgressValue } from '../../utils';
import {
  InitialSetupWizardRelatedAssociatedCompanyInfoHeader,
  InitialSetupWizardRelatedAssociatedCompanyInfoHeaderAvatar,
  InitialSetupWizardRelatedAssociatedCompanyInfoHeaderIcon,
  InitialSetupWizardRelatedAssociatedCompanyInfoChip,
} from './styles';

const InitialSetupWizardRelatedAssociatedCompanyInfo = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  const {
    step: { handleNextStep },
    companyType: { isCarrier: isCarrierSelectedCompany },
    relatedAssociatedCompany: {
      data: relatedAssociatedCompanyData,
      setData: setRelatedAssociatedCompanyData,
    },
  } = useInitialSetupWizardContext();

  const handleNext = useCallback(() => {
    handleNextStep(isCarrierSelectedCompany ? 'loadVehicle' : 'loadDeposit');
  }, [handleNextStep, isCarrierSelectedCompany]);

  const isCarrierOwnerInvitationCompany = useMemo(
    () =>
      isCarrierPartnerCompany(
        relatedAssociatedCompanyData?.partnerCompanyTypeId as PartnerCompanyTypes,
      ),
    [relatedAssociatedCompanyData],
  );

  const ownerInvitationCompany = useMemo(
    () =>
      isCarrierOwnerInvitationCompany
        ? relatedAssociatedCompanyData?.giverCompany
        : relatedAssociatedCompanyData?.carrierCompany,
    [isCarrierOwnerInvitationCompany, relatedAssociatedCompanyData],
  );

  const ownerInvitationCompanyTypeDescription = useMemo(
    () =>
      isCarrierOwnerInvitationCompany
        ? 'Empresa Dador de carga o vendedor'
        : 'Empresa Transportista o logística',
    [isCarrierOwnerInvitationCompany],
  );

  const handlePreviousStep = useCallback(() => {
    handleNextStep('linkAssociatedCompany');
    setRelatedAssociatedCompanyData(null);
  }, [handleNextStep, setRelatedAssociatedCompanyData]);

  return (
    <InitialSetupWizardStepContainer
      stepId="relatedAssociatedCompanyInfo"
      minHeight={550}
      maxWidth={600}
    >
      <StepTitleWithProgress
        progress={getProgressValue(isCarrierSelectedCompany, 1)}
        title="Empresa vinculada"
        // eslint-disable-next-line max-len
        description="Revisa la información de la empresa vinculada. Podrás ajustar esta información desde tus preferencias."
      />

      <InitialSetupWizardModalStepContent>
        <Stack spacing={2} flex={1} justifyContent="center" alignItems="center">
          <Stack spacing={4} alignItems="center">
            <InitialSetupWizardRelatedAssociatedCompanyInfoHeader>
              <InitialSetupWizardRelatedAssociatedCompanyInfoHeaderAvatar
                src={user?.company.logoFile?.url}
                alt={user?.company.name}
              />

              <InitialSetupWizardRelatedAssociatedCompanyInfoHeaderIcon
                src={toAbsoluteUrl('/icons/icon_plus.svg')}
                alt="Plus Icon"
              />

              <InitialSetupWizardRelatedAssociatedCompanyInfoHeaderAvatar
                src={ownerInvitationCompany?.logoFile?.url}
                alt={ownerInvitationCompany?.name}
              />
            </InitialSetupWizardRelatedAssociatedCompanyInfoHeader>

            <Stack spacing={0.5} alignItems="center">
              <Typography
                component="p"
                variant="body2"
                color="secondary.main"
                fontWeight="800"
              >
                Te vinculaste a
              </Typography>

              <Typography component="p" variant="h5" fontWeight="800">
                {ownerInvitationCompany?.name}
              </Typography>

              <InitialSetupWizardRelatedAssociatedCompanyInfoChip
                label={ownerInvitationCompanyTypeDescription}
                variant="outlined"
                color="primary"
              />
            </Stack>
          </Stack>

          <Link
            target="_blank"
            underline="hover"
            fontWeight="bold"
            variant="caption"
            sx={{ cursor: 'pointer' }}
            onClick={handlePreviousStep}
          >
            Vincular otra empresa
          </Link>
        </Stack>

        <Stack spacing={2} alignItems="center">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleNext}
            sx={{ width: 280 }}
          >
            Siguiente
          </LoadingButton>
        </Stack>
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardRelatedAssociatedCompanyInfo;

import { useMemo } from 'react';
import { get } from 'lodash';
import { grey, red, green } from '@mui/material/colors';

import { PartnerCompanyStatusTypes } from '~globals/types/enums';
import { getPartnerCompanyStatusTypeDescription } from '~utils/partnerCompany';

interface PartnerCompanyStatusInfoItem {
  description: string;
  color: string;
}

type PartnerCompanyStatusList = {
  [state in PartnerCompanyStatusTypes]: PartnerCompanyStatusInfoItem;
};

export const usePartnerCompanyStatusInfo = (
  stateId: PartnerCompanyStatusTypes,
): PartnerCompanyStatusInfoItem => {
  const stateList = useMemo<PartnerCompanyStatusList>(
    () => ({
      [PartnerCompanyStatusTypes.Pending]: {
        description: getPartnerCompanyStatusTypeDescription(
          PartnerCompanyStatusTypes.Pending,
        ),
        color: grey[700],
      },
      [PartnerCompanyStatusTypes.Active]: {
        description: getPartnerCompanyStatusTypeDescription(
          PartnerCompanyStatusTypes.Active,
        ),
        color: green[700],
      },
      [PartnerCompanyStatusTypes.Inactive]: {
        description: getPartnerCompanyStatusTypeDescription(
          PartnerCompanyStatusTypes.Inactive,
        ),
        color: red[700],
      },
    }),
    [],
  );

  const currentInfo = useMemo<PartnerCompanyStatusInfoItem>(
    () => get(stateList, stateId),
    [stateId, stateList],
  );

  return currentInfo;
};

import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen as StopsIcon } from '@fortawesome/free-solid-svg-icons';

import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { pluralize } from '~utils/commons';

import { StopsInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const StopsInfo = ({
  stops,
  fontSize = 14,
  fontWeight,
  withTooltip = false,
  useCustomIcon = false,
}: StopsInfoProps): ReactElement => {
  const Icon = useMemo(
    () =>
      useCustomIcon ? (
        <img src={toAbsoluteUrl('/icons/icon_box.svg')} alt="Stop Icon" />
      ) : (
        <FontAwesomeIcon icon={StopsIcon} />
      ),
    [useCustomIcon],
  );

  const text = useMemo(
    () =>
      pluralize({
        singular: 'Destino',
        count: stops,
        includeCount: true,
      }),
    [stops],
  );

  const tooltipMessage = useMemo(
    () => (withTooltip ? 'Destinos' : undefined),
    [withTooltip],
  );

  return (
    <DescriptionWithIconBox
      icon={Icon}
      description={text}
      size={fontSize}
      iconTooltip={tooltipMessage}
      fontWeight={fontWeight}
    />
  );
};

export default memo(StopsInfo);

import { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dateFnsEsLocale from 'date-fns/locale/es';

import {
  useLoadGoogleApis,
  useLoadGoogleTagManager,
  useLoadSmartLook,
  useLoadTawk,
} from '~hooks/index';
import store, { persitor } from '~store/index';
import { theme } from '~config/index';
import SignalRProvider from '~contexts/SignalRContext';
import { SnackBarProvider } from '~components/index';
import AlertsProvider from '~contexts/AlertsProvider';
import AccessFeatureModalProvider from '~contexts/AccessFeatureModalContext';
import SubscriptionPaymentModalProvider from '~contexts/SubscriptionPaymentModalContext';
import { HUBS } from '~constants/signalR';

import Routes from './routes';

import '~config/momentExtended';
import '~config/yupExtending';

const App: FC = () => {
  useLoadGoogleApis();
  useLoadGoogleTagManager();
  useLoadSmartLook();
  useLoadTawk();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persitor}>
        <BrowserRouter>
          <SignalRProvider url={HUBS.NOTIFICATION_HUB}>
            <ThemeProvider theme={theme}>
              <CssBaseline />

              <SnackBarProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={dateFnsEsLocale}
                >
                  <AlertsProvider>
                    <SubscriptionPaymentModalProvider>
                      <AccessFeatureModalProvider>
                        <Routes />
                      </AccessFeatureModalProvider>
                    </SubscriptionPaymentModalProvider>
                  </AlertsProvider>
                </LocalizationProvider>
              </SnackBarProvider>
            </ThemeProvider>
          </SignalRProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;

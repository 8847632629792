import { styled, Card, Tab, Box } from '@mui/material';
import { TabPanel } from '@mui/lab';

export const GenerateRouteCompletedTabsCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
});

export const GenerateRouteCompletedTabsTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.white,
  minWidth: 180,
  borderRight: `1px solid ${theme.palette.common.white}`,
}));

export const GenerateRouteCompletedTabsTabsPanelContainer = styled(Box)({
  display: 'flex',
  flex: 1,
});

export const GenerateRouteCompletedTabsTabPanel = styled(TabPanel)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    flex: 1,
  }),
);

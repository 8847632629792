import {
  constants,
  lib,
  DrawCustomMode,
  DrawCustomModeThis,
  MapMouseEvent,
  MapTouchEvent,
  DrawPolygon,
} from '@mapbox/mapbox-gl-draw';
import { Feature, Polygon } from 'geojson';

interface DrawState {
  polygon: DrawPolygon;
  currentVertexPosition: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DrawPolygonMode extends DrawCustomMode<DrawState, any> {
  clickOnVertex?: (
    this: DrawCustomModeThis & this,
    state: DrawState,
    event: MapMouseEvent | MapTouchEvent,
  ) => void;
  clickAnywhere?: (
    this: DrawCustomModeThis & this,
    state: DrawState,
    event: MapMouseEvent | MapTouchEvent,
  ) => void;
}

const DrawPolygonCustomMode: DrawPolygonMode = {
  onSetup: function () {
    const polygon = this.newFeature({
      type: constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: constants.geojsonTypes.POLYGON,
        coordinates: [[]],
      },
    }) as DrawPolygon;

    this.addFeature(polygon);

    this.clearSelectedFeatures();

    lib.doubleClickZoom.disable(this);

    this.updateUIClasses({ mouse: constants.cursors.ADD });

    this.activateUIButton(constants.types.POLYGON);

    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    return {
      polygon,
      currentVertexPosition: 0,
    };
  },
  onMouseMove: function (state, event) {
    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      event.lngLat.lng,
      event.lngLat.lat,
    );

    if (lib.CommonSelectors.isVertex(event)) {
      this.updateUIClasses({ mouse: constants.cursors.POINTER });
    }
  },
  clickOnVertex: function (state) {
    return this.changeMode(constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
  },
  clickAnywhere: function (state, event) {
    if (
      state.currentVertexPosition > 0 &&
      lib.isEventAtCoordinates(
        event,
        state.polygon.coordinates[0][state.currentVertexPosition - 1],
      )
    ) {
      return this.changeMode(constants.modes.SIMPLE_SELECT, {
        featureIds: [state.polygon.id],
      });
    }

    this.updateUIClasses({ mouse: constants.cursors.ADD });

    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      event.lngLat.lng,
      event.lngLat.lat,
    );

    state.currentVertexPosition++;

    state.polygon.updateCoordinate(
      `0.${state.currentVertexPosition}`,
      event.lngLat.lng,
      event.lngLat.lat,
    );
  },
  onClick: function (state, event) {
    if (lib.CommonSelectors.isVertex(event))
      return this.clickOnVertex?.(state, event);

    return this.clickAnywhere?.(state, event);
  },
  onTap: function (state, event) {
    if (lib.CommonSelectors.isVertex(event))
      return this.clickOnVertex?.(state, event);

    return this.clickAnywhere?.(state, event);
  },
  onKeyUp: function (state, event) {
    if (lib.CommonSelectors.isEscapeKey(event)) {
      this.deleteFeature([state.polygon.id], { silent: true });

      this.changeMode(constants.modes.SIMPLE_SELECT);
    } else if (lib.CommonSelectors.isEnterKey(event)) {
      this.changeMode(constants.modes.SIMPLE_SELECT, {
        featureIds: [state.polygon.id],
      });
    }
  },
  onStop: function (state) {
    this.updateUIClasses({ mouse: constants.cursors.NONE });

    lib.doubleClickZoom.enable(this);

    this.activateUIButton();

    // check to see if we've deleted this feature
    if (this.getFeature(state.polygon.id) === undefined) return;

    //remove last added coordinate
    state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`);

    if (state.polygon.isValid()) {
      this.map.fire(constants.events.CREATE, {
        features: [state.polygon.toGeoJSON()],
      });
      this.deleteFeature([state.polygon.id]);
    } else {
      this.deleteFeature([state.polygon.id], { silent: true });
      this.changeMode(constants.modes.SIMPLE_SELECT, {}, { silent: true });
    }
  },
  toDisplayFeatures: function (
    state,
    geojson: Feature<
      Polygon,
      { id: string; active: 'true' | 'false'; meta: string }
    >,
    display,
  ) {
    const isActivePolygon = geojson.properties.id === state.polygon.id;

    geojson.properties.active = isActivePolygon
      ? constants.activeStates.ACTIVE
      : constants.activeStates.INACTIVE;

    if (!isActivePolygon) return display(geojson);

    // Don't render a polygon until it has two positions
    // (and a 3rd which is just the first repeated)
    if (geojson.geometry.coordinates?.length === 0) return;

    const coordinateCount = geojson.geometry.coordinates[0].length;
    // 2 coordinates after selecting a draw type
    // 3 after creating the first point
    if (coordinateCount < 3) {
      return;
    }

    geojson.properties.meta = constants.meta.FEATURE;

    display(
      lib.createVertex(
        state.polygon.id,
        geojson.geometry.coordinates[0][0],
        '0.0',
        false,
      ),
    );

    if (coordinateCount > 3) {
      // Add a start position marker to the map, clicking on this will finish the feature
      // This should only be shown when we're in a valid spot
      const endPos = geojson.geometry.coordinates[0].length - 3;
      display(
        lib.createVertex(
          state.polygon.id,
          geojson.geometry.coordinates[0][endPos],
          `0.${endPos}`,
          false,
        ),
      );
    }

    if (coordinateCount <= 4) {
      // If we've only drawn two positions (plus the closer),
      // make a LineString instead of a Polygon
      const lineCoordinates = [
        [
          geojson.geometry.coordinates[0][0][0],
          geojson.geometry.coordinates[0][0][1],
        ],
        [
          geojson.geometry.coordinates[0][1][0],
          geojson.geometry.coordinates[0][1][1],
        ],
      ];
      // create an initial vertex so that we can track the first point on mobile devices
      display({
        type: constants.geojsonTypes.FEATURE,
        properties: geojson.properties,
        geometry: {
          coordinates: lineCoordinates,
          type: constants.geojsonTypes.LINE_STRING,
        },
      });

      if (coordinateCount === 3) {
        return;
      }
    }
    // render the Polygon
    return display(geojson);
  },
  onTrash: function (state) {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(constants.modes.SIMPLE_SELECT);
  },
};

export default DrawPolygonCustomMode;

import {
  styled,
  CardContent,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
} from '@mui/material';

export const PlanningRejectedOperationsErrorsContainer = styled(CardContent, {
  shouldForwardProp: (prop) => prop !== 'dense',
})<{ dense?: boolean }>(({ theme, dense }) => ({
  padding: theme.spacing(1, 2),

  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },

  ...(dense && {
    padding: 0,

    '&:last-child': {
      paddingBottom: 0,
    },
  }),
}));

export const PlanningRejectedOperationsErrorsErrorsListContainer = styled(Box)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(3),
  }),
);

export const classesPlanningRejectedOperationsErrorsListItem = {
  expanded: 'PlanningRejectedOperationsErrorsItem-expanded',
  expandIcon: 'PlanningRejectedOperationsErrorsItem-expandIcon',
};

export const PlanningRejectedOperationsErrorsListItemButton = styled(
  ListItemButton,
)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingTop: theme.spacing(0.5),

  [`& .${classesPlanningRejectedOperationsErrorsListItem.expandIcon}`]: {
    color: theme.palette.action.active,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
    }),
  },

  [`&.${classesPlanningRejectedOperationsErrorsListItem.expanded}`]: {
    [`& .${classesPlanningRejectedOperationsErrorsListItem.expandIcon}`]: {
      transform: 'rotate(180deg)',
    },
  },
}));

export const PlanningRejectedOperationsErrorsListItemIcon = styled(
  ListItemIcon,
)(({ theme }) => ({
  marginTop: 0,
  marginRight: theme.spacing(1),
  minWidth: 'auto',
}));

export const PlanningRejectedOperationsErrorsListItemText = styled(
  ListItemText,
)({
  marginBottom: 0,
  marginTop: 0,
});

export const PlanningRejectedOperationsErrorsListItemSubItemList = styled(List)(
  ({ theme }) => ({
    paddingLeft: 28,
    paddingRight: theme.spacing(3),
  }),
);

export const PlanningRejectedOperationsErrorsListItemSubItemListItem = styled(
  ListItem,
)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const PlanningRejectedOperationsErrorsListItemSubItemListIcon = styled(
  ListItemIcon,
)(({ theme }) => ({
  width: theme.spacing(2),
  marginRight: theme.spacing(0.5),
  minWidth: 'auto',
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(0.5),
}));

import {
  HubConnectionBuilder,
  HubConnection,
  IHttpConnectionOptions,
  LogLevel,
} from '@microsoft/signalr';

import { Nullable } from '~globals/types';

export const buildConnection = (
  connectionUrl: string,
  token: Nullable<string>,
): HubConnection => {
  const optionsConnection: IHttpConnectionOptions = {
    ...(token && { accessTokenFactory: () => token }),
  };

  return new HubConnectionBuilder()
    .withUrl(connectionUrl, optionsConnection)
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();
};

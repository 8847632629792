import { PlanFeaturesTypes } from '~globals/types/enums';
import {
  FEATURES_AVAILABLES_TO_DATES,
  LIMIT_NOT_PRO_VERSION_DATES,
  LIMIT_PAID_VERSION_DATES,
} from '~constants/featuresAvailableToDates';

// eslint-disable-next-line import/extensions
import pck from '../../package.json';

export const getFeatureAvailableToDate = (
  feature: `${PlanFeaturesTypes}`,
): Date | undefined => FEATURES_AVAILABLES_TO_DATES[feature];

export const getLimitNotProByVersion = (version?: string): Date | undefined =>
  LIMIT_NOT_PRO_VERSION_DATES[version ?? pck.version];

export const getLimitPaidVersion = (version?: string): Date | undefined =>
  LIMIT_PAID_VERSION_DATES[version ?? pck.version];

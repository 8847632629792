import { ReactElement, useMemo } from 'react';
import { Stack } from '@mui/material';

import { ItemTasksList, AccessFeature } from '~components/index';
import { PlanFeaturesTypes } from '~globals/types/enums';

import { useDialogDetailsOrderContextContext } from '../../DialogDetailsOrderContext';

const DialogDetailsOrderArticles = (): ReactElement => {
  const { data } = useDialogDetailsOrderContextContext();

  const itemTasks = useMemo(() => data?.itemTasks ?? [], [data]);

  return (
    <Stack spacing={2}>
      <AccessFeature.Alert validFeature={PlanFeaturesTypes.MgmItemTask} />

      <AccessFeature.Hidden
        validFeature={PlanFeaturesTypes.MgmItemTask}
        type="notAccess"
      >
        <ItemTasksList list={itemTasks} />
      </AccessFeature.Hidden>
    </Stack>
  );
};

export default DialogDetailsOrderArticles;

import { useState, useCallback, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import { get } from 'lodash';

import {
  Request,
  DataResponse,
  ErrorResponse,
  Nullable,
  Coordinates,
} from '~globals/types';

import { useLazyRequest } from './useLazyRequest';
import { useToggle } from './useToggle';

type PopupRequest<D> = Request<string, DataResponse<D>, ErrorResponse>;

interface PopupRequestDataParams<D> {
  request: PopupRequest<D>;
}

export type PopupRequestOpenPopupHandler = (
  event: mapboxgl.MapboxEvent<MouseEvent>,
  id: string,
  coordinates: Coordinates,
) => Promise<void>;

type PopupRequestCache<D> = {
  [cacheKey: string]: D;
};

export interface PopupRequestReturn<D> {
  open: boolean;
  data: Nullable<D>;
  coordinates: Nullable<Coordinates>;
  loading: boolean;
  error: boolean;
  handleOpenPopup: PopupRequestOpenPopupHandler;
  handleClosePopup: () => void;
}

export const usePopupRequest = <D>({
  request,
}: PopupRequestDataParams<D>): PopupRequestReturn<D> => {
  const [open, setOpen] = useToggle(false);
  const [data, setData] = useState<Nullable<D>>(null);
  const [coordinates, setCoordinates] = useState<Nullable<Coordinates>>(null);

  const currentIdRef = useRef<Nullable<string>>(null);

  const [cache, setCache] = useState<PopupRequestCache<D>>({});

  const getCacheValue = useCallback(
    (cacheKey: string) => get(cache, cacheKey) as D | undefined,
    [cache],
  );

  const addCacheValue = useCallback((cacheKey: string, newData: D) => {
    setCache((prevCache) => ({ ...prevCache, [cacheKey]: newData }));
  }, []);

  const [, loading, error, getData] = useLazyRequest({
    request,
    withPostSuccess: (response) => {
      const newData = response.data?.data as D;

      if (currentIdRef.current) {
        addCacheValue(currentIdRef.current, newData);
      }

      setData(newData);
    },
  });

  const handleOpenPopup = useCallback<PopupRequestOpenPopupHandler>(
    async (event, id, coords) => {
      event.originalEvent.stopPropagation();
      setOpen(true);

      setCoordinates(coords);

      currentIdRef.current = id;

      const cacheData = getCacheValue(id);

      if (cacheData) {
        setData(cacheData);
      } else {
        await getData(id);
      }
    },
    [getCacheValue, getData, setOpen],
  );

  const handleClosePopup = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      setCoordinates(null);
      setData(null);
      currentIdRef.current = null;
    }, 500);
  }, [setOpen]);

  return {
    open,
    data,
    coordinates,
    loading,
    error: Boolean(error),
    handleOpenPopup,
    handleClosePopup,
  };
};

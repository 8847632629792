import {
  ReactElement,
  ChangeEvent,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  Typography,
  Stack,
  TextField,
  CardActions,
  Button,
  Chip,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass as SearchIcon } from '@fortawesome/free-solid-svg-icons';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { defaultTo, ceil } from 'lodash';

import { useLazyRequest } from '~hooks/index';
import { searchCustomers } from '~services/customer';
import {
  CustomerBase,
  CustomerWithGeoErrorsHead,
} from '~services/customer/types';
import { DataPaginateResponse } from '~globals/types';
import { hasError } from '~utils/index';

import { DialogSearchCustomersProps } from './types';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import {
  DialogSearchCustomersContainer,
  DialogSearchCustomersTitle,
  DialogSearchCustomersTitleCloseIcon,
  DialogSearchCustomersContent,
  DialogSearchCustomersListContainer,
  DialogSearchCustomersListCardItem,
  DialogSearchCustomersListCardItemContent,
  DialogSearchCustomersPagination,
} from './styles';

const sizePerPage = 10;

const DialogSearchCustomers = ({
  open,
  onClose,
  onSelectCustomer,
}: DialogSearchCustomersProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [customers, setCustomers] = useState<CustomerBase[]>([]);
  const [totalCustomersSize, setTotalCustomersSize] = useState<number>(0);
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);

  const [, loadingSearchCustomer, , executeSearchCustomers] = useLazyRequest({
    request: searchCustomers,
    withPostSuccess: (response) => {
      const {
        data: { results, pagination },
      } = response.data as DataPaginateResponse<
        CustomerBase[],
        null,
        CustomerWithGeoErrorsHead
      >;

      setCustomers(results);
      setTotalCustomersSize(defaultTo(pagination.count, results.length));
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      setCurrentSearch('');
      setCurrentPage(1);
      const { query } = values;

      await executeSearchCustomers({
        pagination: { page: 1, sizePerPage },
        filters: { query },
      });

      setCurrentSearch(query);

      setSubmitting(false);
    },
    [executeSearchCustomers],
  );

  const { errors, touched, getFieldProps, submitForm } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const paginationCount = useMemo(
    () => ceil(totalCustomersSize / sizePerPage),
    [totalCustomersSize],
  );

  const handleChangePage = useCallback(
    async (_e: ChangeEvent<unknown>, newPage: number) => {
      setCurrentPage(newPage);
      await executeSearchCustomers({
        pagination: { page: newPage, sizePerPage },
        filters: { query: currentSearch },
      });
    },
    [executeSearchCustomers, currentSearch],
  );

  return (
    <DialogSearchCustomersContainer open={open}>
      <DialogSearchCustomersTitle>
        <Typography component="h2" variant="h6">
          Buscar Contacto Frecuente
        </Typography>

        <DialogSearchCustomersTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogSearchCustomersTitleCloseIcon>
      </DialogSearchCustomersTitle>

      <DialogSearchCustomersContent dividers>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Búsqueda"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.QUERY)}
              {...getFieldProps(FIELDS_NAME.QUERY)}
            />

            <Tooltip title="Buscar..." arrow>
              <Button
                variant="contained"
                color="secondary"
                onClick={submitForm}
                disabled={loadingSearchCustomer}
              >
                <FontAwesomeIcon icon={SearchIcon} />
              </Button>
            </Tooltip>
          </Stack>

          {loadingSearchCustomer && (
            <Stack alignItems="center">
              <CircularProgress color="primary" disableShrink size={60} />
            </Stack>
          )}

          {!loadingSearchCustomer && currentSearch && !!totalCustomersSize && (
            <DialogSearchCustomersListContainer spacing={1}>
              {customers.map((customer) => (
                <DialogSearchCustomersListCardItem
                  key={`customer-${customer.customerId}`}
                  variant="outlined"
                >
                  <DialogSearchCustomersListCardItemContent>
                    <Typography
                      component="p"
                      variant="body2"
                      sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                    >
                      <b>{customer.legalName}</b>{' '}
                      {customer.code && (
                        <Chip
                          label={customer.code}
                          size="small"
                          component="span"
                        />
                      )}
                    </Typography>

                    <Typography component="p" variant="body2">
                      {customer.address}
                    </Typography>
                  </DialogSearchCustomersListCardItemContent>

                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onSelectCustomer(customer)}
                    >
                      Seleccionar
                    </Button>
                  </CardActions>
                </DialogSearchCustomersListCardItem>
              ))}
            </DialogSearchCustomersListContainer>
          )}

          {!loadingSearchCustomer && currentSearch && !totalCustomersSize && (
            <Typography
              component="p"
              variant="body1"
              align="center"
              fontWeight="bold"
            >
              No se encontraron similitudes con la búsqueda, porfavor intente
              nuevamente
            </Typography>
          )}

          {currentSearch && paginationCount > 1 && (
            <DialogSearchCustomersPagination
              count={paginationCount}
              page={currentPage}
              onChange={handleChangePage}
            />
          )}
        </Stack>
      </DialogSearchCustomersContent>
    </DialogSearchCustomersContainer>
  );
};

export default DialogSearchCustomers;

import { SyntheticEvent, useCallback, useMemo } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';
import { Checkbox, Radio } from '@mui/material';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectRowsHandlerOnSelectAll<R extends Record<string, any> = any> =
  (isSelected: boolean, rows: R[], event?: SyntheticEvent) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SelectRowsHandlerOnSelect<R extends Record<string, any> = any> = (
  row: R,
  isSelected: boolean,
  rowIndex: number,
  event?: SyntheticEvent,
) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface UseDataTableSelectedPropsParams<R extends Record<string, any> = any> {
  onSelectAll: SelectRowsHandlerOnSelectAll<R>;
  onSelect: SelectRowsHandlerOnSelect<R>;
  selectedIds?: Array<number | string>;
  defaultNonSelectableIds?: Array<number | string>;
  mode?: 'checkbox' | 'radio';
}

export const useDataTableSelectedProps = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Record<string, any> = any,
>({
  onSelectAll,
  onSelect,
  selectedIds = [],
  defaultNonSelectableIds,
  mode = 'checkbox',
}: UseDataTableSelectedPropsParams<R>): SelectRowProps<R> => {
  const selectionHeaderRenderer = useCallback<
    Required<SelectRowProps<R>>['selectionHeaderRenderer']
  >(
    ({ checked }) => (
      <Checkbox checked={checked} disableRipple sx={{ padding: 0 }} />
    ),
    [],
  );

  const selectionRenderer = useCallback<
    Required<SelectRowProps<R>>['selectionRenderer']
  >(({ checked, disabled, mode: modeRenderer }) => {
    if (modeRenderer === 'radio') {
      return (
        <Radio
          checked={checked}
          disabled={disabled}
          disableRipple
          sx={{ padding: 0 }}
          onChange={() => {}}
        />
      );
    }

    return (
      <Checkbox
        checked={checked}
        disabled={disabled}
        disableRipple
        sx={{ padding: 0 }}
        onChange={() => {}}
      />
    );
  }, []);

  const selectRowsProps = useMemo<SelectRowProps<R>>(
    () => ({
      mode: mode,
      hideSelectAll: mode === 'radio',
      clickToSelect: false,
      selected: selectedIds,
      onSelectAll,
      onSelect,
      selectionHeaderRenderer,
      selectionRenderer,
      nonSelectable: defaultNonSelectableIds as number[] | undefined,
      nonSelectableClasses: 'table-row-disabled',
    }),
    [
      mode,
      selectedIds,
      onSelectAll,
      onSelect,
      selectionHeaderRenderer,
      selectionRenderer,
      defaultNonSelectableIds,
    ],
  );

  return selectRowsProps;
};

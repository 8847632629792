import { styled } from '@mui/material';

export const SubscriptionPaymentModalDetailsRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    backgroundColor: '#eceff1',
    justifyContent: 'space-between',
  }),
);

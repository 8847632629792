import { ReactElement } from 'react';

import { Nullable } from '~globals/types/commons';

import { RenderFieldsProps } from './types';
import { getComponent } from './utils';

const RenderFields = ({
  component,
  componentProps = {},
}: RenderFieldsProps): Nullable<ReactElement> => {
  const Node = getComponent(component);

  if (!Node) return null;

  return <Node {...componentProps} />;
};

export default RenderFields;

import { ReactElement } from 'react';
import { List, Collapse } from '@mui/material';
import {
  FiberManualRecord as DotIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material';
import classNames from 'classnames';

import { useToggle } from '~hooks/index';

import { PlanningRejectedOperationsErrorsItemProps } from './types';
import {
  classesPlanningRejectedOperationsErrorsListItem as classes,
  PlanningRejectedOperationsErrorsListItemButton,
  PlanningRejectedOperationsErrorsListItemIcon,
  PlanningRejectedOperationsErrorsListItemText,
  PlanningRejectedOperationsErrorsListItemSubItemList,
  PlanningRejectedOperationsErrorsListItemSubItemListItem,
  PlanningRejectedOperationsErrorsListItemSubItemListIcon,
} from './styles';

const PlanningRejectedOperationsErrors = ({
  label,
  errors,
}: PlanningRejectedOperationsErrorsItemProps): ReactElement => {
  const [expanded, toogleExpanded] = useToggle(false);

  return (
    <List dense disablePadding>
      <PlanningRejectedOperationsErrorsListItemButton
        onClick={() => toogleExpanded()}
        className={classNames({ [classes.expanded]: expanded })}
        alignItems="flex-start"
      >
        <PlanningRejectedOperationsErrorsListItemIcon>
          <DotIcon fontSize="small" color="error" />
        </PlanningRejectedOperationsErrorsListItemIcon>

        <PlanningRejectedOperationsErrorsListItemText
          primary={label}
          primaryTypographyProps={{
            variant: 'body1',
            fontWeight: 'bold',
            component: 'span',
            display: 'block',
            sx: { lineHeight: (theme) => theme.typography.body2.lineHeight },
          }}
        />

        <KeyboardArrowDownIcon className={classes.expandIcon} />
      </PlanningRejectedOperationsErrorsListItemButton>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <PlanningRejectedOperationsErrorsListItemSubItemList
          dense
          disablePadding
        >
          {errors.map(({ ItemId, errorCode, message }) => (
            <PlanningRejectedOperationsErrorsListItemSubItemListItem
              key={`planning-rejected-operations-errors-${label}-code-${errorCode}-order-${ItemId}`}
              alignItems="flex-start"
            >
              <PlanningRejectedOperationsErrorsListItemSubItemListIcon>
                <HighlightOffIcon fontSize="inherit" />
              </PlanningRejectedOperationsErrorsListItemSubItemListIcon>

              <PlanningRejectedOperationsErrorsListItemText
                primary={message}
                primaryTypographyProps={{
                  variant: 'body2',
                  component: 'span',
                  color: 'text.secondary',
                  display: 'block',
                  sx: {
                    lineHeight: (theme) => theme.typography.body2.lineHeight,
                  },
                }}
              />
            </PlanningRejectedOperationsErrorsListItemSubItemListItem>
          ))}
        </PlanningRejectedOperationsErrorsListItemSubItemList>
      </Collapse>
    </List>
  );
};

export default PlanningRejectedOperationsErrors;

import * as Yup from 'yup';

export const FIELDS_NAME = {
  REJECTED_NOTE: 'rejectedNote',
} as const;

export const initialValues = {
  [FIELDS_NAME.REJECTED_NOTE]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.REJECTED_NOTE]: Yup.string().notRequired(),
});

import API from '~config/api';
import {
  ApiPromise,
  DataPaginateResponse,
  ErrorResponse,
} from '~globals/types';

import {
  DriverCompanyBase,
  DriverCompanyExtended,
  DriverCompanyAvailablesByRouteHead,
} from './types';

export const DRIVER_ENPOINTS = {
  getDriversCompany: '/api/v1/Route/DriverCompany',
  getDriverCompanyAvailables: '/api/v1/Route/DriverCompany/Availables',
  getDriverCompanyAvailablesByRouteId: (routeId: string): string =>
    `/api/v1/Route/DriverCompany/Availables/${routeId}`,
};

export const getDriversCompany = (): ApiPromise<
  DataPaginateResponse<DriverCompanyExtended[]>,
  ErrorResponse
> =>
  API.get<DataPaginateResponse<DriverCompanyExtended[]>, ErrorResponse>(
    DRIVER_ENPOINTS.getDriversCompany,
  );

export const getDriverCompanyAvailables = (): ApiPromise<
  DataPaginateResponse<DriverCompanyBase[]>,
  ErrorResponse
> =>
  API.get<DataPaginateResponse<DriverCompanyBase[]>, ErrorResponse>(
    DRIVER_ENPOINTS.getDriverCompanyAvailables,
  );

export const getDriverCompanyAvailablesByRouteId = (
  routeId: string,
): ApiPromise<
  DataPaginateResponse<
    DriverCompanyBase[],
    null,
    DriverCompanyAvailablesByRouteHead
  >,
  ErrorResponse
> =>
  API.get<
    DataPaginateResponse<
      DriverCompanyBase[],
      null,
      DriverCompanyAvailablesByRouteHead
    >,
    ErrorResponse
  >(DRIVER_ENPOINTS.getDriverCompanyAvailablesByRouteId(routeId));

import {
  styled,
  Dialog,
  dialogClasses,
  IconButton,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DialogSearchOrdersPendingContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 1200,
    width: '100%',
  },
});

export const DialogSearchOrdersPendingTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogSearchOrdersPendingTitleCloseIcon = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.grey[500],
  }),
);

export const DialogSearchOrdersPendingContent = styled(DialogContent)(
  ({ theme }) => ({
    paddingBottom: theme.spacing(4),
  }),
);

export const DialogSearchOrdersPendingDataTableContainer = styled('div')({
  overflowY: 'auto',
  maxHeight: 300,
  minHeight: 180,

  '& .data-table-container': {
    display: 'grid',
    gridTemplateRows: '1fr max-content',

    '& ._loading_overlay_wrapper': {
      height: '100%',
    },
  },
});

export const DialogSearchOrdersPendinActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogSearchOrdersPendinTextFieldNormal = styled(TextField)({
  width: 220,
});

export const DialogSearchOrdersPendinTextFieldLarge = styled(TextField)({
  width: 360,
});

export const DialogSearchOrdersPendinIconButton = styled(Button)(
  ({ theme }) => ({
    minWidth: 0,
    padding: theme.spacing(0, 1.5),
  }),
);

export const DialogSearchOrdersPendinIconButtonIcon = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(17),
  }),
);

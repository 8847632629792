import { styled, Card, CardContent, Button } from '@mui/material';

export const BannerRoot = styled(Card)({
  display: 'flex',
  overflow: 'visible',
  width: '100%',
  position: 'relative',
});

export const BannerBackground = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '60%',
  right: 0,
  borderRadius: theme.shape.borderRadius,
  top: 0,
  bottom: 0,
  height: '100%',
  objectFit: 'cover',
}));

export const BannerContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 1.5),
  paddingRight: theme.spacing(3),
  gap: theme.spacing(0.5),
  zIndex: 1,
}));

export const BannerButton = styled(Button)(({ theme }) => ({
  ...theme.typography.caption,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'uppercase',
}));

export const BannerMedia = styled('img')({
  display: 'block',
  width: 150,
  transform: 'translate(-8px, 10px) scale(1.2, 1.3)',
  transformOrigin: 'bottom right',
  objectFit: 'contain',
  zIndex: 1,
});

import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  IconButton,
  DialogActions,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

export const DialogImportFromAssociatedCompanyContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 930,
    width: '100%',
    minHeight: 608,
  },
});

export const DialogImportFromAssociatedCompanyLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogImportFromAssociatedCompanyHeader = styled('div')(
  ({ theme }) => ({
    flex: 'none',
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

export const DialogImportFromAssociatedCompanyHeaderCloseIcon = styled(
  IconButton,
)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export const DialogImportFromAssociatedCompanyContentContainer = styled(
  DialogContent,
)({
  display: 'flex',
  flex: 1,
});

export const DialogImportFromAssociatedCompanyTabPanel = styled(TabPanel)({
  display: 'flex',
  padding: 0,
  flex: 1,
});

export const DialogImportFromAssociatedCompanyActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogImportFromAssociatedCompanyLabelImg = styled('img', {
  shouldForwardProp: (prop) =>
    prop !== 'size' && prop !== 'rounded' && prop !== 'eleveation',
})<{ size?: 'small' | 'medium'; rounded?: boolean; elevation?: number }>(
  ({ size = 'medium', rounded = false, elevation = 0, theme }) => ({
    width: 150,
    boxShadow: theme.shadows[elevation],

    ...(rounded && {
      borderRadius: '50%',
    }),

    ...(size === 'small' && {
      width: 90,
    }),
  }),
);

import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';
import { Nullable } from '~globals/types';

export const FIELDS_NAME = {
  ADDRESS: 'address',
  ADDRESS_COORDS: 'addressCoords',
} as const;

export const initialValues = {
  [FIELDS_NAME.ADDRESS]: '',
  [FIELDS_NAME.ADDRESS_COORDS]: null as Nullable<google.maps.LatLngLiteral>,
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ADDRESS]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.ADDRESS_COORDS]: Yup.mixed().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
});

import { ReactElement, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { compact } from 'lodash';

import { useRequest } from '~hooks/index';
import { getSkillsTypesCompany } from '~services/enumerations';

import { DialogOrdersMultipeEditionProps } from './types';
import DialogOrdersMultipeEditionContent from './Content';
import {
  DialogOrdersMultipeEditionContainer,
  DialogOrdersMultipeEditionLoadingContainer,
} from './styles';

const DialogOrdersMultipeEdition = ({
  open,
  onClose,
  selectedIds,
  onSuccessSubmit,
}: DialogOrdersMultipeEditionProps): ReactElement => {
  const [skillsTypes, setSkillsTypes] = useState<string[]>([]);
  const [, loadingSkillsTypes] = useRequest({
    request: getSkillsTypesCompany,
    payload: null,
    withPostSuccess: (response) => {
      const skillsListResponse = compact(
        response.data?.data.results as string[],
      );

      setSkillsTypes(skillsListResponse);
    },
  });

  return (
    <DialogOrdersMultipeEditionContainer open={open}>
      {loadingSkillsTypes && (
        <DialogOrdersMultipeEditionLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogOrdersMultipeEditionLoadingContainer>
      )}

      {!loadingSkillsTypes && (
        <DialogOrdersMultipeEditionContent
          onClose={onClose}
          selectedIds={selectedIds}
          skillsNeededOptions={skillsTypes}
          onSuccessSubmit={onSuccessSubmit}
        />
      )}
    </DialogOrdersMultipeEditionContainer>
  );
};

export default DialogOrdersMultipeEdition;

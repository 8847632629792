import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

import { ActionsOrderMenuIconWithLoadingProps } from './types';

const ActionsOrderMenuIconWithLoading = ({
  loading,
  children,
}: ActionsOrderMenuIconWithLoadingProps): ReactElement => {
  if (loading) {
    return <CircularProgress color="primary" disableShrink size={20} />;
  }

  return children;
};

export default ActionsOrderMenuIconWithLoading;

import { ReactElement, MouseEvent, useMemo, useCallback, memo } from 'react';
import { Tooltip } from '@mui/material';
import {
  ContentCopyRounded as CopyIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

import { useCopyToClipboard } from '~hooks/useCopyToClipboard';

import { CopyButtonProps } from './types';
import { CopyButtonIconButton } from './styles';

const CopyButton = ({
  text,
  size = 'small',
  color = 'default',
  onClick,
  ...rest
}: CopyButtonProps): ReactElement => {
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  const tooltipTitle = useMemo(() => {
    if (clipboardState.copied) {
      return 'Copiado';
    }

    return 'Click para copiar';
  }, [clipboardState]);

  const handleCopy = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      copyToClipboard(text);

      onClick?.(event);
    },
    [copyToClipboard, text, onClick],
  );

  return (
    <Tooltip title={tooltipTitle} arrow placement="top">
      <CopyButtonIconButton
        size={size}
        color={color}
        onClick={handleCopy}
        {...rest}
      >
        {clipboardState.copied ? <CheckIcon /> : <CopyIcon />}
      </CopyButtonIconButton>
    </Tooltip>
  );
};

export default memo(CopyButton);

import { get, isBoolean, isNumber, isDate, isEmpty, isObject } from 'lodash';
import moment from 'moment';

type ReturnTypeItemValue<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends Record<string, any>,
  P extends keyof D,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends (item: D[P]) => any,
  DF,
> = F extends (item: D[P]) => D[P] ? D[P] | DF : ReturnType<F> | DF;

export const getItemValue = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends Record<string, any>,
  P extends keyof D,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends (item: D[P]) => any,
  DF extends
    | string
    | number
    | boolean
    | null
    | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Record<string, any> = null,
>(
  data: D,
  pathToFind: P,
  formatter?: F,
  defaultValue = null as DF,
): ReturnTypeItemValue<D, P, F, DF> => {
  const item = get(data, pathToFind);

  if (
    !isBoolean(item) &&
    !isNumber(item) &&
    !isDate(item) &&
    !isObject(item) &&
    isEmpty(item)
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return defaultValue as any;
  }

  if (formatter) return formatter(item);

  return item;
};

interface StartEndDate {
  start: Date;
  end: Date;
}

export const getStartEndDates = (
  unitOfTime: moment.unitOfTime.StartOf,
): StartEndDate => {
  const startDate = moment().startOf(unitOfTime);
  const endDate = moment().endOf(unitOfTime);

  return {
    start: new Date(startDate.format()),
    end: new Date(endDate.format()),
  };
};

export const getRangesDatesForNow = (
  startDay: number,
  endDay: number,
): StartEndDate => {
  const startDate = moment().subtract(startDay, 'day').startOf('day');
  const endDate = moment().add(endDay, 'day').endOf('day');

  return {
    start: new Date(startDate.format()),
    end: new Date(endDate.format()),
  };
};

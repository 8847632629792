import { ReactElement, useMemo, useCallback, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly as WarouseIcon,
  faPeopleArrows as ErrorDistanceIcon,
  faCubes as CubesIcon,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import { Visibility as ShowIcon } from '@mui/icons-material';
import mapboxgl from 'mapbox-gl';
import { chain } from 'lodash';

import { AccessFeature } from '~components/index';
import {
  RouteItemStateType,
  ItemTaskStatusType,
  ItemCollectStatusTypes,
  PlanFeaturesTypes,
} from '~globals/types/enums';
import { formatterDistanceToKm } from '~utils/formatter';
import { validateRouteItemDistanceFromAddress } from '~utils/route';
import { pluralize } from '~utils/commons';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { useToggle, useUtcOffset } from '~hooks/index';

import { RouteItemStopCardProps, RouteItemStopStateTypeList } from './types';
import {
  RouteItemStopCardContainer,
  RouteItemStopCardDivider,
  RouteItemStopCardIconButtonContainer,
  RouteItemStopCardIconText,
  RouteItemStopCardInfoContainer,
  RouteItemStopCardInfoHeader,
  RouteItemStopCardInfoTimeContainer,
  RouteItemStopCardInfoTimeDescriptions,
  RouteItemStopCardInfoTimeDescriptionsText,
  RouteItemStopCardInfoTimeVisited,
  RouteItemStopCardInfoTimeVisitedText,
  RouteItemStopCardInfoPrincipalContainer,
  RouteItemStopTitle,
  RouteItemStopText,
  RouteItemStopIconButton,
  RouteItemStopCardErrorDistanceWrapper,
  RouteItemStopCardItemTaksWrapper,
} from './styles';
import MenuActionsDataTable from '../MenuActionsDataTable';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const routeItemStopState: RouteItemStopStateTypeList = {
  [RouteItemStateType.New]: 'default',
  [RouteItemStateType.OnAgenda]: 'default',
  [RouteItemStateType.InProgress]: 'default',
  [RouteItemStateType.FinalizedSuccess]: 'success',
  [RouteItemStateType.FinalizedError]: 'error',
  [RouteItemStateType.WithoutVisiting]: 'warning',
};

const maxDistance = 500;

const RouteItemStopCard = ({
  routeItemId,
  isWarehouse,
  order,
  routeItemStateTypeId,
  title,
  address,
  estimatedArrivalDateTime,
  serviceDuration,
  referenceId,
  realArrivalDateTime,
  actionsOptions,
  distanceFromAddress,
  longitude,
  latitude,
  mapRef,
  chargeUnit1,
  chargeUnit2,
  itemTasks,
  itemCollects,
  vehicle,
  onOpenFeedbackDialog,
}: RouteItemStopCardProps): ReactElement => {
  const formatterDateUtcOffset = useUtcOffset();

  const hasErrorDistance = useMemo(() => {
    if (distanceFromAddress) {
      return validateRouteItemDistanceFromAddress(
        'gt',
        distanceFromAddress,
        maxDistance,
      );
    }

    return false;
  }, [distanceFromAddress]);

  const errorDistanceText = useMemo(() => {
    let text = `Esta parada se reporto a mas de ${maxDistance} metros`;

    if (distanceFromAddress) {
      text += ` (${formatterDistanceToKm(distanceFromAddress)})`;
    }

    return text;
  }, [distanceFromAddress]);

  const [openTooltipIcon, setOpenTooltipIcon] = useToggle();

  const Icon = useMemo(() => {
    let output = <RouteItemStopCardIconText>{order}</RouteItemStopCardIconText>;

    if (isWarehouse) output = <FontAwesomeIcon icon={WarouseIcon} />;

    return output;
  }, [order, isWarehouse]);

  const handleShowItemInMap = useCallback(() => {
    if (mapRef) {
      const currentItemCoords = new mapboxgl.LngLat(longitude, latitude);
      mapRef.flyTo({ center: currentItemCoords });

      const tripbounds = new mapboxgl.LngLatBounds();
      tripbounds.extend(currentItemCoords);
      mapRef.fitBounds(tripbounds, { maxZoom: 14 });
    }
  }, [mapRef, longitude, latitude]);

  const handleShowItemWithDistanceFromAddressInMap = useCallback(() => {
    if (mapRef && vehicle) {
      const currentItemCoords = new mapboxgl.LngLat(longitude, latitude);

      const vehicleCoords = new mapboxgl.LngLat(
        vehicle.longitude as number,
        vehicle.latitude as number,
      );

      mapRef.fitBounds(
        [vehicleCoords, currentItemCoords],
        { padding: 50 },
        { feedbackStopCoords: vehicleCoords },
      );
    }
  }, [mapRef, longitude, latitude, vehicle]);

  const itemTasksTotalCount = useMemo(() => itemTasks.length, [itemTasks]);

  const itemTasksWithErrorCount = useMemo(
    () =>
      chain(itemTasks)
        .filter({ itemTaskStatusTypeId: ItemTaskStatusType.Error })
        .size()
        .value(),
    [itemTasks],
  );

  const itemTaskTooltip = useMemo(() => {
    if (!!itemTasksWithErrorCount) {
      const sentenceError = pluralize({
        singular: 'artículo / tarea con problemas',
        plural: 'artículos / tareas con problemas',
        count: itemTasksWithErrorCount,
      });

      return `${itemTasksWithErrorCount}/${itemTasksTotalCount} ${sentenceError}`;
    }

    return pluralize({
      singular: 'artículo / tarea completado correctamente',
      plural: 'artículos / tareas completados correctamente',
      count: itemTasksTotalCount,
      includeCount: true,
    });
  }, [itemTasksTotalCount, itemTasksWithErrorCount]);

  const itemCollectsTotalCount = useMemo(
    () => itemCollects.length,
    [itemCollects],
  );

  const itemCollectsWithErrorCount = useMemo(
    () =>
      chain(itemCollects)
        .filter({
          itemCollectStatusTypeId: ItemCollectStatusTypes.NotCollected,
        })
        .size()
        .value(),
    [itemCollects],
  );

  const itemCollectsTooltip = useMemo(() => {
    if (!!itemCollectsWithErrorCount) {
      const sentenceError = pluralize({
        singular: 'pedido con problemas',
        plural: 'pedidos con problemas',
        count: itemCollectsWithErrorCount,
      });

      return `${itemCollectsWithErrorCount}/${itemCollectsTotalCount} ${sentenceError}`;
    }

    return pluralize({
      singular: 'pedido completado correctamente',
      plural: 'pedidos completados correctamente',
      count: itemCollectsTotalCount,
      includeCount: true,
    });
  }, [itemCollectsTotalCount, itemCollectsWithErrorCount]);

  return (
    <RouteItemStopCardContainer>
      <RouteItemStopCardDivider orientation="vertical" />

      <Tooltip
        open={openTooltipIcon}
        onClose={() => setOpenTooltipIcon(false)}
        onOpen={() => setOpenTooltipIcon(true)}
        title="Ver en mapa"
        arrow
        placement="right"
      >
        <RouteItemStopCardIconButtonContainer
          state={routeItemStopState[routeItemStateTypeId]}
          onClick={handleShowItemInMap}
        >
          {openTooltipIcon ? (
            <ShowIcon className="routeItemStopCard-showIcon" />
          ) : (
            Icon
          )}
        </RouteItemStopCardIconButtonContainer>
      </Tooltip>

      <RouteItemStopCardInfoContainer>
        <RouteItemStopCardInfoHeader>
          <RouteItemStopCardInfoTimeContainer>
            <RouteItemStopCardInfoTimeDescriptions>
              <RouteItemStopCardInfoTimeDescriptionsText>
                {formatterDateUtcOffset(estimatedArrivalDateTime, 'HH:mm')}
              </RouteItemStopCardInfoTimeDescriptionsText>

              <RouteItemStopCardInfoTimeDescriptionsText>
                {pluralize({
                  singular: 'min',
                  count: serviceDuration / 60,
                  includeCount: true,
                })}
              </RouteItemStopCardInfoTimeDescriptionsText>

              {!isWarehouse && referenceId && (
                <Tooltip title={`Nro. de Orden: ${referenceId}`} arrow>
                  <RouteItemStopCardInfoTimeDescriptionsText
                    sx={{ maxWidth: 120 }}
                  >
                    {referenceId}
                  </RouteItemStopCardInfoTimeDescriptionsText>
                </Tooltip>
              )}

              {!isWarehouse && chargeUnit1 !== undefined && (
                <RouteItemStopCardInfoTimeDescriptionsText>
                  <DescriptionWithIconBox
                    icon={
                      <img
                        src={toAbsoluteUrl('/icons/icon_capacity_1.svg')}
                        alt="Capacity One Icon"
                        style={{ width: 13, height: 'auto' }}
                      />
                    }
                    description={chargeUnit1.toString()}
                    size={10}
                    iconTooltip="Carga primaria (En KG, L, dinero, bultos)"
                  />
                </RouteItemStopCardInfoTimeDescriptionsText>
              )}

              {!isWarehouse && chargeUnit2 !== undefined && (
                <RouteItemStopCardInfoTimeDescriptionsText>
                  <DescriptionWithIconBox
                    icon={
                      <img
                        src={toAbsoluteUrl('/icons/icon_capacity_2.svg')}
                        alt="Capacity Two Icon"
                        style={{ width: 13, height: 'auto' }}
                      />
                    }
                    description={chargeUnit2.toString()}
                    size={10}
                    iconTooltip="Carga secundaria (En KG, L, dinero, bultos)"
                  />
                </RouteItemStopCardInfoTimeDescriptionsText>
              )}

              {!isWarehouse && itemTasksTotalCount > 0 && (
                <AccessFeature.Hidden
                  validFeature={PlanFeaturesTypes.MgmItemTask}
                  type="notAccess"
                >
                  <RouteItemStopCardInfoTimeDescriptionsText>
                    <Tooltip title={itemTaskTooltip} arrow>
                      <RouteItemStopIconButton
                        onClick={() =>
                          onOpenFeedbackDialog?.(routeItemId, 'articles')
                        }
                      >
                        <RouteItemStopCardItemTaksWrapper
                          icon={CubesIcon}
                          error={!!itemTasksWithErrorCount}
                        />
                      </RouteItemStopIconButton>
                    </Tooltip>
                  </RouteItemStopCardInfoTimeDescriptionsText>
                </AccessFeature.Hidden>
              )}

              {!isWarehouse && itemCollectsTotalCount > 0 && (
                <RouteItemStopCardInfoTimeDescriptionsText>
                  <Tooltip title={itemCollectsTooltip} arrow>
                    <RouteItemStopIconButton
                      onClick={() =>
                        onOpenFeedbackDialog?.(routeItemId, 'orders')
                      }
                    >
                      <RouteItemStopCardItemTaksWrapper
                        icon={CubesIcon}
                        error={!!itemCollectsWithErrorCount}
                      />
                    </RouteItemStopIconButton>
                  </Tooltip>
                </RouteItemStopCardInfoTimeDescriptionsText>
              )}

              {!isWarehouse && hasErrorDistance && (
                <RouteItemStopCardInfoTimeDescriptionsText>
                  <Tooltip title={errorDistanceText} arrow>
                    <RouteItemStopIconButton
                      onClick={handleShowItemWithDistanceFromAddressInMap}
                    >
                      <RouteItemStopCardErrorDistanceWrapper
                        icon={ErrorDistanceIcon}
                      />
                    </RouteItemStopIconButton>
                  </Tooltip>
                </RouteItemStopCardInfoTimeDescriptionsText>
              )}
            </RouteItemStopCardInfoTimeDescriptions>

            {realArrivalDateTime && (
              <RouteItemStopCardInfoTimeVisited
                state={routeItemStopState[routeItemStateTypeId]}
              >
                <RouteItemStopCardInfoTimeVisitedText>
                  {`Terminado ${formatterDateUtcOffset(
                    realArrivalDateTime,
                    'HH:mm',
                  )}`}
                </RouteItemStopCardInfoTimeVisitedText>
              </RouteItemStopCardInfoTimeVisited>
            )}
          </RouteItemStopCardInfoTimeContainer>

          {actionsOptions && <MenuActionsDataTable {...actionsOptions} />}
        </RouteItemStopCardInfoHeader>

        <RouteItemStopCardInfoPrincipalContainer>
          <RouteItemStopTitle>{title}</RouteItemStopTitle>

          {address && <RouteItemStopText>{address}</RouteItemStopText>}
        </RouteItemStopCardInfoPrincipalContainer>
      </RouteItemStopCardInfoContainer>
    </RouteItemStopCardContainer>
  );
};

export default memo(RouteItemStopCard);

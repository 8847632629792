import { ReactElement, useMemo, useCallback } from 'react';
import { Card, Typography, Avatar, Stack, Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  useAppSelector,
  useTypePartnerCompany,
  useEnabledCrossCompany,
} from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import {
  getInitialsNameLetterUpperCase,
  isAdminUser,
  getDefaultAvatar,
} from '~utils/user';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { pluralize } from '~utils/commons';
import { getPartnerCompanyCurrentCompanyInfo } from '~utils/partnerCompany';
import { PATHS } from '~constants/index';
import { Banner, NewFeatureBagde } from '~components/index';

import { MainAccountDashboardProps } from './types';
import {
  MainAccountDashboardCardContent,
  MainAccountDashboardInfo,
  MainAccountDashboardInfoButtonItem,
  MainAccountDashboardInfoItemIconImg,
  MainAccountDashboardInfoAvatarGroup,
} from './styles';

const MainAccountDashboard = ({
  vehiclesCount,
  depositsCount,
  driversCount,
  users,
  partnerCompanies,
  handleOpenDialogInviteDriver,
  handleOpenDialogInvitePartnerCompany,
}: MainAccountDashboardProps): ReactElement => {
  const navigate = useNavigate();
  const { user: currentUser } = useAppSelector(selectAuth);
  const { isCarrier, isGiver } = useTypePartnerCompany();
  const { isEnabledCrossCompany } = useEnabledCrossCompany();

  const isCurrentAdminUser = useMemo(
    () => isAdminUser(currentUser),
    [currentUser],
  );

  const handleRedirectOnClick = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  return (
    <Card>
      <MainAccountDashboardCardContent>
        <MainAccountDashboardInfo>
          {isCarrier && (
            <MainAccountDashboardInfoButtonItem
              variant="text"
              disabled={!isCurrentAdminUser}
              onClick={() => handleRedirectOnClick(PATHS.CONFIG.VEHICLES.BASE)}
            >
              <MainAccountDashboardInfoItemIconImg
                src={toAbsoluteUrl('/icons/icon_truck.svg')}
                alt="Vehicle Icon"
              />

              <Typography variant="body2" fontWeight="bold" color="inherit">
                {pluralize({
                  singular: 'vehículo',
                  count: vehiclesCount,
                  includeCount: true,
                })}
              </Typography>
            </MainAccountDashboardInfoButtonItem>
          )}

          <MainAccountDashboardInfoButtonItem
            variant="text"
            disabled={!isCurrentAdminUser}
            onClick={() => handleRedirectOnClick(PATHS.CONFIG.DEPOSITS.BASE)}
          >
            <MainAccountDashboardInfoItemIconImg
              src={toAbsoluteUrl('/icons/icon_warehouse.svg')}
              alt="Warehouse Icon"
            />

            <Typography variant="body2" fontWeight="bold" color="inherit">
              {pluralize({
                singular: 'depósito',
                count: depositsCount,
                includeCount: true,
              })}
            </Typography>
          </MainAccountDashboardInfoButtonItem>

          {isCarrier && (
            <MainAccountDashboardInfoButtonItem
              variant="text"
              disabled={!isCurrentAdminUser}
              onClick={() => handleRedirectOnClick(PATHS.CONFIG.DRIVERS.BASE)}
            >
              <MainAccountDashboardInfoItemIconImg
                src={toAbsoluteUrl('/icons/icon_user.svg')}
                alt="User Icon"
              />

              <Typography variant="body2" fontWeight="bold" color="inherit">
                {pluralize({
                  singular: 'chofer',
                  plural: 'choferes',
                  count: driversCount,
                  includeCount: true,
                })}
              </Typography>
            </MainAccountDashboardInfoButtonItem>
          )}

          {isEnabledCrossCompany && (
            <MainAccountDashboardInfoButtonItem
              variant="text"
              disabled={!isCurrentAdminUser}
              onClick={() =>
                handleRedirectOnClick(PATHS.CONFIG.PARTNERS_COMPANIES.BASE)
              }
            >
              {partnerCompanies.length === 0 && (
                <>
                  <MainAccountDashboardInfoItemIconImg
                    src={toAbsoluteUrl('/icons/icon_supplychain.svg')}
                    alt="Parnter Companies Icon"
                  />

                  <Typography variant="body2" fontWeight="bold" color="inherit">
                    Sin empresas asociadas
                  </Typography>
                </>
              )}

              {partnerCompanies.length > 0 && (
                <>
                  <MainAccountDashboardInfoAvatarGroup max={5} spacing="small">
                    {partnerCompanies.map((partnerCompany) => {
                      const currentCompany =
                        getPartnerCompanyCurrentCompanyInfo(partnerCompany);

                      return (
                        <Avatar
                          key={`avatar-partner-comapny-${partnerCompany.partnerCompanyId}`}
                          alt={currentCompany?.name}
                          src={currentCompany?.logoFile?.url}
                        />
                      );
                    })}
                  </MainAccountDashboardInfoAvatarGroup>

                  <Typography variant="body2" fontWeight="bold" color="inherit">
                    {pluralize({
                      singular: 'empresa asociada',
                      plural: 'empresas asociadas',
                      count: partnerCompanies.length,
                      includeCount: true,
                    })}
                  </Typography>
                </>
              )}
            </MainAccountDashboardInfoButtonItem>
          )}

          <MainAccountDashboardInfoButtonItem
            variant="text"
            disabled={!isCurrentAdminUser}
            onClick={() => handleRedirectOnClick(PATHS.CONFIG.USERS.BASE)}
          >
            <MainAccountDashboardInfoAvatarGroup max={5} spacing="small">
              {users.map((user) => (
                <Avatar
                  key={`avata-user-${user.userId}`}
                  alt={`${user.lastName}, ${user.firstName}`}
                  src={getDefaultAvatar(user.avatarFile?.url)}
                >
                  {getInitialsNameLetterUpperCase(
                    `${user.firstName} ${user.lastName}`,
                  )}
                </Avatar>
              ))}
            </MainAccountDashboardInfoAvatarGroup>

            <Typography variant="body2" fontWeight="bold" color="inherit">
              {pluralize({
                singular: 'usuario',
                count: users.length,
                includeCount: true,
              })}
            </Typography>
          </MainAccountDashboardInfoButtonItem>
        </MainAccountDashboardInfo>

        {isCurrentAdminUser && (
          <Stack spacing={2} direction="row">
            {isEnabledCrossCompany && (
              <Badge
                badgeContent={
                  <NewFeatureBagde
                    activeForVersion="1.16.0"
                    sx={{
                      margin: 0,
                      marginRight: 3,
                      backgroundColor: 'common.white',
                    }}
                  />
                }
                sx={{ flex: 'auto', width: '100%' }}
              >
                <Banner
                  title="Suma empresas a Routix"
                  description="Dale trazabilidad total a tus clientes"
                  image={{
                    src: toAbsoluteUrl('/icons/icon_plus_company.svg'),
                    alt: 'Plus Company Icon',
                  }}
                  actions={[
                    {
                      text: 'Ver mi red',
                      onClick: () =>
                        handleRedirectOnClick(
                          PATHS.CONFIG.PARTNERS_COMPANIES.BASE,
                        ),
                    },
                    {
                      text: 'Asociar empresa',
                      onClick: handleOpenDialogInvitePartnerCompany,
                    },
                  ]}
                />
              </Badge>
            )}

            {isCarrier && (
              <Banner
                title="Invita a tus choferes"
                description="Seguimiento en vivo, evidencia digital y mucho más..."
                image={{
                  src: toAbsoluteUrl('/icons/icon_plus_driver.svg'),
                  alt: 'Plus Driver Icon',
                }}
                actions={[
                  {
                    text: 'Cargar...',
                    onClick: () =>
                      handleRedirectOnClick(PATHS.CONFIG.DRIVERS.NEW),
                  },
                  {
                    text: 'Invitar chofer',
                    onClick: handleOpenDialogInviteDriver,
                  },
                ]}
              />
            )}

            {isGiver && (
              <Banner
                title="Nos integramos a tus tiendas"
                description="Facilitamos la tarea de importación de tus tiendas favoritas."
                image={{
                  src: toAbsoluteUrl('/icons/icon_plus_integration.svg'),
                  alt: 'Plus Integration Icon',
                }}
                actions={[
                  {
                    text: 'Explorar integraciones',
                    onClick: () =>
                      handleRedirectOnClick(PATHS.CONFIG.INTEGRATIONS.BASE),
                  },
                ]}
              />
            )}
          </Stack>
        )}
      </MainAccountDashboardCardContent>
    </Card>
  );
};

export default MainAccountDashboard;

import {
  styled,
  Dialog,
  dialogClasses,
  DialogActions,
  Card,
  CardContent,
  Alert,
  alertClasses,
} from '@mui/material';

export const DialogUpdateRouteContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 900,
    width: '100%',
    minHeight: 'calc(100% - 64px)',
  },
});

export const DialogUpdateRouteLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogUpdateRouteActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogUpdateRouteDriverAlert = styled(Alert)(
  ({ theme, severity }) => ({
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightMedium,

    ...(severity && {
      border: `1px solid ${theme.palette[severity].light}`,
    }),

    [`& .${alertClasses.message}`]: {
      padding: 0,
    },
  }),
);

export const DialogUpdateRouteCard = styled(Card)({
  display: 'grid',
});

export const DialogUpdateRouteCardContent = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(1, 2),
    display: 'grid',
    gridTemplateRows: 'max-content 1fr',

    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  }),
);

export const DialogUpdateRouteCardDataTableContainer = styled('div')({
  marginTop: 6,
  overflowY: 'auto',
  height: '100%',
  maxHeight: 230,
  minHeight: 180,

  '& .data-table-container': {
    display: 'grid',
    gridTemplateRows: '1fr max-content',

    '& ._loading_overlay_wrapper': {
      height: '100%',
    },
  },
});

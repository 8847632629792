import { ReactElement, useMemo } from 'react';
import { Card, Typography, Stack } from '@mui/material';
import {
  grey,
  lightBlue,
  purple,
  red,
  green,
  yellow,
} from '@mui/material/colors';
import { max } from 'lodash';
import moment from 'moment-timezone';

import { Link } from '~components/index';
import { PATHS } from '~constants/index';
import { RouteItemStateType } from '~globals/types/enums';
import { getStringifyUrlQueryString } from '~utils/router';
import { formatterDate } from '~utils/formatter';

import { DeliveryStatesProps, DeliveryStateListItem } from './types';
import {
  DeliveryStatesCardContent,
  DeliveryStatesLinearProgress,
} from './styles';

const normalizedValue = (
  value: number,
  minVal: number,
  maxVal: number,
): number => ((value - minVal) * 100) / (maxVal - minVal);

const currentDate = moment();

const commonsDates = {
  dateFrom: formatterDate(currentDate, { format: 'MM-DD-YYYY' }),
  dateTo: formatterDate(currentDate, { format: 'MM-DD-YYYY' }),
};

const DeliveryStates = ({
  pending,
  onAgenda,
  inProgress,
  unComplete,
  complete,
  withoutVisiting,
}: DeliveryStatesProps): ReactElement => {
  const maxValue = useMemo(
    () =>
      max([
        pending,
        onAgenda,
        inProgress,
        unComplete,
        complete,
        withoutVisiting,
      ]) || 100,
    [pending, onAgenda, inProgress, unComplete, complete, withoutVisiting],
  );

  const listState = useMemo<DeliveryStateListItem[]>(
    () => [
      {
        label: 'Pendiente',
        color: grey[700],
        value: pending,
        typeId: RouteItemStateType.New,
        currentTab: 'pending',
      },
      {
        label: 'En agenda',
        color: lightBlue[700],
        value: onAgenda,
        typeId: RouteItemStateType.OnAgenda,
        currentTab: 'on-agenda',
        ...commonsDates,
      },
      {
        label: 'En proceso',
        color: purple[700],
        value: inProgress,
        typeId: RouteItemStateType.InProgress,
        currentTab: 'on-agenda',
        ...commonsDates,
      },
      {
        label: 'Incompletas',
        color: red[700],
        value: unComplete,
        typeId: RouteItemStateType.FinalizedError,
        currentTab: 'finalized',
        ...commonsDates,
      },
      {
        label: 'Sin visitar',
        color: yellow[700],
        value: withoutVisiting,
        typeId: RouteItemStateType.WithoutVisiting,
        currentTab: 'finalized',
        ...commonsDates,
      },
      {
        label: 'Completas',
        color: green[700],
        value: complete,
        typeId: RouteItemStateType.FinalizedSuccess,
        currentTab: 'finalized',
        ...commonsDates,
      },
    ],
    [pending, onAgenda, inProgress, unComplete, complete, withoutVisiting],
  );

  return (
    <Card>
      <DeliveryStatesCardContent>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6" fontWeight="bold">
            Estado de pedidos
          </Typography>

          <Link
            to={PATHS.ORDERS.BASE}
            color="primary"
            variant="caption"
            underline="hover"
          >
            Ver pedidos
          </Link>
        </Stack>

        <Stack spacing={2}>
          {listState.map((stateData) => (
            <Stack spacing={1} key={`progress-${stateData.label}`}>
              <Link
                to={getStringifyUrlQueryString({
                  url: PATHS.ORDERS.BASE,
                  query: {
                    state: stateData.typeId,
                    currentTab: stateData.currentTab,
                    dateFrom: stateData.dateFrom,
                    dateTo: stateData.dateTo,
                  },
                })}
                color="text.primary"
                variant="caption"
                underline="hover"
              >
                {stateData.label}
              </Link>

              <Stack direction="row" spacing={1}>
                <DeliveryStatesLinearProgress
                  variant="determinate"
                  value={normalizedValue(stateData.value, 0, maxValue)}
                  customColor={stateData.color}
                />

                <Typography variant="caption">{stateData.value}</Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </DeliveryStatesCardContent>
    </Card>
  );
};

export default DeliveryStates;

import {
  ReactElement,
  SyntheticEvent,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Chip,
  AppBar,
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { isUndefined, isNull } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import { NewFeatureBagde } from '~components/index';
import { useRequest } from '~hooks/index';
import { getItemById } from '~services/item';
import { ItemCreateExtended } from '~services/item/types';
import { Nullable } from '~globals/types';
import { RouteItemStateType } from '~globals/types/enums';
import { PATHS } from '~constants/paths';
import { replaceParamsPath } from '~utils/index';

import {
  DialogDetailsOrderProps,
  DialogDetailsOrderStateTypeList,
  DialogDetailsOrderActiveTab,
  DialogDetailsOrderTabsList,
  DialogDetailsOrderTabsListPanel,
} from './types';
import DialogDetailsOrderProvider from './DialogDetailsOrderContext';
import {
  DialogDetailsOrderData,
  DialogDetailsOrderArticles,
  DialogDetailsOrderOrders,
} from './Tabs';
import {
  DialogDetailsOrderContainer,
  DialogDetailsOrderLoadingContainer,
  DialogDetailsOrderTitle,
  DialogDetailsOrderContentContainer,
  DialogDetailsOrderTab,
  DialogDetailsOrderTabsPanelContainer,
  DialogDetailsOrderTabPanel,
} from './styles';

const itemDetailsState: DialogDetailsOrderStateTypeList = {
  [RouteItemStateType.New]: { type: 'default', description: 'Nuevo' },
  [RouteItemStateType.OnAgenda]: { type: 'default', description: 'En agenda' },
  [RouteItemStateType.InProgress]: {
    type: 'default',
    description: 'En proceso',
  },
  [RouteItemStateType.FinalizedSuccess]: {
    type: 'success',
    description: 'Completa',
  },
  [RouteItemStateType.FinalizedError]: {
    type: 'error',
    description: 'Incompleta',
  },
  [RouteItemStateType.WithoutVisiting]: {
    type: 'warning',
    description: 'Sin visitar',
  },
};

const DialogDetailsOrder = ({
  open,
  onClose,
  orderId,
  enabledChipTitle = true,
  currentTab,
  isCollect: isCollectProp,
}: DialogDetailsOrderProps): ReactElement => {
  const [activeTab, setActiveTab] = useState<DialogDetailsOrderActiveTab>(
    currentTab ?? 'data',
  );

  const [detailsData, setDeatilsData] =
    useState<Nullable<ItemCreateExtended>>(null);

  const [, loadingDetails, , reloadRequest] = useRequest({
    request: getItemById,
    payload: orderId,
    withPostSuccess: (response) => {
      const detailsResponse = response.data?.data as ItemCreateExtended;

      setDeatilsData(detailsResponse);
    },
  });

  const reloadData = useCallback(() => {
    reloadRequest(orderId);
  }, [reloadRequest, orderId]);

  const isCollect = useMemo(() => !!isCollectProp, [isCollectProp]);

  const tabsList = useMemo(
    (): DialogDetailsOrderTabsList[] => [
      {
        id: 'data',
        label: 'Datos de parada',
        enabled: true,
        newActiveForVersion: '',
      },
      {
        id: 'articles',
        label: 'Artículos / Tareas',
        enabled: !isCollect,
        newActiveForVersion: '1.14.0',
      },
      {
        id: 'orders',
        label: 'Pedidos',
        enabled: isCollect,
        newActiveForVersion: '',
      },
    ],
    [isCollect],
  );

  const tabsListPanels = useMemo(
    (): DialogDetailsOrderTabsListPanel[] => [
      {
        id: tabsList[0].id,
        panel: DialogDetailsOrderData,
        enabled: true,
      },
      {
        id: tabsList[1].id,
        panel: DialogDetailsOrderArticles,
        enabled: !isCollect,
      },
      {
        id: tabsList[2].id,
        panel: DialogDetailsOrderOrders,
        enabled: isCollect,
      },
    ],
    [tabsList, isCollect],
  );

  const handleChangeActiveTab = useCallback(
    (_e: SyntheticEvent, newValue: DialogDetailsOrderActiveTab) => {
      setActiveTab(newValue);
    },
    [],
  );

  const routeItemStateTypeId = useMemo(
    () => detailsData?.itemStateTypeId ?? null,
    [detailsData],
  );

  const badgeTitleInfo = useMemo(
    () =>
      !isUndefined(routeItemStateTypeId) && !isNull(routeItemStateTypeId)
        ? itemDetailsState[routeItemStateTypeId]
        : null,
    [routeItemStateTypeId],
  );

  return (
    <DialogDetailsOrderProvider
      value={{ data: detailsData, reloadData, onClose }}
    >
      <DialogDetailsOrderContainer open={open} maxWidth={false}>
        {loadingDetails && (
          <DialogDetailsOrderLoadingContainer>
            <CircularProgress color="primary" disableShrink size={80} />
          </DialogDetailsOrderLoadingContainer>
        )}

        {!loadingDetails && (
          <>
            <DialogDetailsOrderTitle>
              <Typography component="h2" variant="h6">
                {detailsData?.title}
              </Typography>

              {enabledChipTitle && badgeTitleInfo && (
                <Chip
                  variant="filled"
                  color={badgeTitleInfo.type}
                  label={badgeTitleInfo.description}
                  size="small"
                  sx={{ textTransform: 'uppercase' }}
                />
              )}
            </DialogDetailsOrderTitle>

            <DialogDetailsOrderContentContainer>
              <TabContext value={activeTab}>
                <AppBar position="sticky" color="tertiary" elevation={0}>
                  <TabList
                    onChange={handleChangeActiveTab}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    {tabsList.map(
                      ({ label, id, enabled, newActiveForVersion }) =>
                        enabled && (
                          <DialogDetailsOrderTab
                            key={`dialog-details-order-tab-${id}`}
                            label={label}
                            value={id}
                            icon={
                              <NewFeatureBagde
                                activeForVersion={newActiveForVersion}
                              />
                            }
                            iconPosition="end"
                          />
                        ),
                    )}
                  </TabList>
                </AppBar>

                <DialogDetailsOrderTabsPanelContainer>
                  {tabsListPanels.map(
                    ({ id, panel: Panel, enabled }) =>
                      enabled && (
                        <DialogDetailsOrderTabPanel
                          key={`dialog-details-order-panel-${id}`}
                          value={id}
                        >
                          <Panel />
                        </DialogDetailsOrderTabPanel>
                      ),
                  )}
                </DialogDetailsOrderTabsPanelContainer>
              </TabContext>
            </DialogDetailsOrderContentContainer>

            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button color="secondary" variant="outlined" onClick={onClose}>
                Cerrar
              </Button>

              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                to={replaceParamsPath(PATHS.ORDERS.SHOW, {
                  ':orderId': orderId,
                })}
              >
                Ver información completa
              </Button>
            </DialogActions>
          </>
        )}
      </DialogDetailsOrderContainer>
    </DialogDetailsOrderProvider>
  );
};

export default DialogDetailsOrder;

import { ReactElement, useMemo, memo } from 'react';
import { grey, green } from '@mui/material/colors';
import { get } from 'lodash';

import { ActivatedChipProps, ActivatedChipTypeMapping } from './types';
import { ActivatedChipRoot } from './styles';

const defaultVariantMapping: ActivatedChipTypeMapping = {
  active: {
    color: green[700],
    label: 'Activo',
  },
  inactive: {
    color: grey[700],
    label: 'Inactivo',
  },
};

const ActivatedChip = ({
  type = 'active',
  typeMapping = defaultVariantMapping,
  size = 'medium',
  variant = 'outlined',
}: ActivatedChipProps): ReactElement => {
  const currentTypeData = useMemo(
    () => get(typeMapping as ActivatedChipTypeMapping, type),
    [typeMapping, type],
  );

  return (
    <ActivatedChipRoot
      variant={variant}
      label={currentTypeData.label}
      size={size}
      ownerState={{ color: currentTypeData.color as string }}
    />
  );
};

export default memo(ActivatedChip);

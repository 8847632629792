import { ReactElement, useCallback, useMemo } from 'react';
import BootstrapTable, {
  BootstrapTableProps,
} from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import LoadingOverlay from 'react-loading-overlay';

import { TableProps } from './types';

import Pagination from './Pagination';
import NoRecordsFoundMessage from './NoRecordsFoundMessage';
import ColumnToggleProvider from './ColumnToggleContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.css';

LoadingOverlay.propTypes = undefined;

const defaultRemoteObject: BootstrapTableProps['remote'] = {
  pagination: true,
};

export const DataTable = ({
  loading = true,
  data = [],
  columns = [],
  keyField,
  pagination,
  remote: remoteProps,
  onTableChange: onTableChangeProps,
  noDataIndicationComponent: NoDataIndicationComponent,
  toggleHideableColumns = false,
  excludeHideableFieldColumns = [],
  hideableStorageKey,
  ...rest
}: TableProps): ReactElement => {
  const remote = useMemo<BootstrapTableProps['remote']>(() => {
    if (!pagination) return false;

    return {
      ...defaultRemoteObject,
      ...remoteProps,
    };
  }, [pagination, remoteProps]);

  const onTableChange = useMemo<BootstrapTableProps['onTableChange']>(
    () => (!pagination ? undefined : onTableChangeProps),
    [pagination, onTableChangeProps],
  );

  const getNoDataIndication = useCallback(
    () =>
      loading ? (
        <div />
      ) : NoDataIndicationComponent ? (
        <NoDataIndicationComponent />
      ) : (
        <NoRecordsFoundMessage />
      ),
    [loading, NoDataIndicationComponent],
  );

  return (
    <ColumnToggleProvider
      keyField={keyField}
      bootstrap4
      data={data}
      columns={columns}
      toggleHideableColumns={toggleHideableColumns}
      excludeHideableFieldColumns={excludeHideableFieldColumns}
      hideableStorageKey={hideableStorageKey}
    >
      {(tableProps) => (
        <Pagination pagination={pagination}>
          <BootstrapTable
            {...tableProps}
            loading={loading}
            overlay={overlayFactory({
              spinner: true,
              styles: {
                overlay: (base: Record<string, unknown>) => ({
                  ...base,
                  background: 'rgba(0, 0, 0, 0.2)',
                  zIndex: 1,
                }),
                spinner: (base: Record<string, unknown>) => ({
                  ...base,
                  '& svg circle': {
                    stroke: '#5618b6',
                    strokeWidth: 4,
                  },
                }),
              },
            })}
            noDataIndication={getNoDataIndication}
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center overflow-hidden"
            bordered={false}
            hover={!loading && tableProps.data.length > 0}
            onTableChange={onTableChange}
            remote={remote}
            {...rest}
          />
        </Pagination>
      )}
    </ColumnToggleProvider>
  );
};

export default DataTable;

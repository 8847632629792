import { ReactElement, useMemo } from 'react';

import { Table, RenderFields } from '~components/index';
import { TableColumn } from '~components/Table/types';
import { DynamicDataTable } from '~globals/types';

import { DialogOrdersMultipeEditionTableProps } from '../types';
import { EXTRACTOR_KEY_VALUE } from '../utils';

const DialogOrdersMultipeEditionTable = ({
  data,
  selectableRows,
}: DialogOrdersMultipeEditionTableProps): ReactElement => {
  const columns = useMemo<TableColumn<DynamicDataTable>[]>(
    () => [
      {
        dataField: 'label',
        text: 'Nombre',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (cell) => <span>{cell}</span>,
        },
      },
      {
        dataField: 'component',
        text: 'Campo',
        formatters: {
          cell: (_cell, row) => (
            <RenderFields
              component={row.component}
              componentProps={row.componentProps}
            />
          ),
        },
        style: {
          header: { width: 500 },
        },
      },
    ],
    [],
  );

  return (
    <Table
      data={data}
      columns={columns}
      stickyHeader={{ enabled: true, maxHeight: 440 }}
      selectable={selectableRows}
      extractorKeyValue={EXTRACTOR_KEY_VALUE}
    />
  );
};

export default DialogOrdersMultipeEditionTable;

import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  Typography,
  Stack,
  TextField,
  DialogContent,
  DialogActions,
  InputAdornment,
  Box,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';

import { useSendInvitationPartnerCompany } from '~hooks/index';
import { hasError } from '~utils/index';
import { CopyButton } from '~components/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { PartnerCompanyTypes } from '~globals/types/enums';

import { useDialogAssociatePartnerCompanyContex } from '../../DialogAssociatePartnerCompanyContext';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const DialogAssociatePartnerCompanySendInvitation = (): ReactElement => {
  const { open, onClose, onSendInvitation, associateData } =
    useDialogAssociatePartnerCompanyContex();

  const handleSuccessSendInvitation = useCallback(() => {
    onSendInvitation?.();
    onClose();
  }, [onSendInvitation, onClose]);

  const { loading, handleSendInvitationPartnerCompany } =
    useSendInvitationPartnerCompany(handleSuccessSendInvitation, 'send');

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      if (associateData?.partnerCompanyId) {
        await handleSendInvitationPartnerCompany({
          partnerCompanyId: associateData.partnerCompanyId,
          email: values[FIELDS_NAME.EMAIL],
        });

        setSubmitting(false);
      }
    },
    [associateData, handleSendInvitationPartnerCompany],
  );

  const {
    submitForm,
    isSubmitting,
    dirty,
    getFieldProps,
    touched,
    errors,
    resetForm,
  } = useFormik({ initialValues, validationSchema, onSubmit });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const associationOtherCompanyDescription = useMemo(
    () =>
      associateData?.associationType === PartnerCompanyTypes.Carrier
        ? 'transportista'
        : 'dador de carga',
    [associateData],
  );

  return (
    <>
      <DialogContent dividers>
        <Stack spacing={4}>
          <Stack spacing={3} direction="row">
            <Stack spacing={1}>
              <Stack>
                <Typography
                  component="p"
                  variant="body2"
                  color="primary.main"
                  fontWeight="800"
                >
                  ¡Invitación generada exitosamente!
                </Typography>

                <Typography component="p" variant="body2">
                  Se ha generado un código de invitación.
                </Typography>
              </Stack>

              {associateData?.invitationCode && (
                <TextField
                  label="Código de invitación"
                  defaultValue={associateData?.invitationCode}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyButton text={associateData.invitationCode} />
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    maxWidth: 280,

                    '& input': {
                      fontSize: 24,
                      fontWeight: 'bold',
                    },
                  }}
                />
              )}

              <Typography component="p" variant="body2">
                {/* eslint-disable-next-line max-len */}
                {`Comunica este código a tu ${associationOtherCompanyDescription} para completar la asociación entre ambas partes.`}
              </Typography>
            </Stack>

            <Box
              component="img"
              src={toAbsoluteUrl('/icons/icon_supplychain.svg')}
              alt="Parnter Companies Icon"
              sx={{ width: 120 }}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography
              component="p"
              variant="body2"
              color="secondary.main"
              fontWeight="bold"
            >
              Enviar código por correo electrónico
            </Typography>

            <Stack>
              <TextField
                label="Para"
                placeholder="nombre@example.com"
                autoComplete="off"
                error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
                {...getFieldProps(FIELDS_NAME.EMAIL)}
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: 280 }}
              />

              <Typography variant="caption" color="text.secondary">
                {`Puede enviar la invitación por email, ingresándolo en el campo de
                arriba y cliqueando en "Enviar invitación"`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Typography variant="caption" component="span">
          <Link
            href="https://routix.tawk.help/article/asociar-empresas"
            target="_blank"
            underline="hover"
            fontWeight="bold"
          >
            Haz clic aquí
          </Link>{' '}
          para tener ayuda.
        </Typography>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loading || isSubmitting}
          disabled={!dirty}
        >
          Enviar invitación
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DialogAssociatePartnerCompanySendInvitation;

import { ReactElement } from 'react';
import { faTruckFast as VehicleIcon } from '@fortawesome/free-solid-svg-icons';

import { MarkerVehicleProps } from './types';
import {
  MarkerVehicleContainer,
  MarkerVehicleIconMarker,
  MarkerVehicleIconVehicleContainer,
  MarkerVehicleIconVehicle,
} from './styles';

const MarkerVehicle = ({ color }: MarkerVehicleProps): ReactElement => (
  <MarkerVehicleContainer color={color}>
    <MarkerVehicleIconMarker currentColor={color} />

    <MarkerVehicleIconVehicleContainer>
      <MarkerVehicleIconVehicle icon={VehicleIcon} />
    </MarkerVehicleIconVehicleContainer>
  </MarkerVehicleContainer>
);

export default MarkerVehicle;

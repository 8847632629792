import { FC, RefObject, ElementRef, createContext, useContext } from 'react';

import { PlacesAutocomplete } from '~components/index';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';
import { SelectOption, Nullable } from '~globals/types';
import { ItemCreateExtended } from '~services/item/types';
import { AuthExtendedCompany } from '~services/auth/types';

interface OrderFormRefs {
  placesAutocompleteRef: RefObject<ElementRef<typeof PlacesAutocomplete>>;
  skillsNeededAutocompleteRef: RefObject<CustomAutocompleteRef<string, true>>;
  currentAssociatedCompaniesAutocompleteRef: RefObject<
    CustomAutocompleteRef<AuthExtendedCompany, false>
  >;
}

interface OrderFormEnumSelects {
  operationTypes: SelectOption[];
  priorityTypes: SelectOption[];
  skillsNeededTypes: string[];
}

interface OrderFormContextValue {
  itemData: Nullable<ItemCreateExtended>;
  refs: OrderFormRefs;
  enumSelects: OrderFormEnumSelects;
  currentAssociatedCompanies: {
    list: AuthExtendedCompany[];
    loading: boolean;
  };
}

const OrderFormContext = createContext<OrderFormContextValue>({
  itemData: null,
  refs: {
    placesAutocompleteRef: { current: null },
    skillsNeededAutocompleteRef: { current: null },
    currentAssociatedCompaniesAutocompleteRef: { current: null },
  },
  enumSelects: {
    operationTypes: [],
    priorityTypes: [],
    skillsNeededTypes: [],
  },
  currentAssociatedCompanies: {
    list: [],
    loading: true,
  },
});

export const useOrderFormContext = (): OrderFormContextValue =>
  useContext(OrderFormContext);

export const OrderFormProvider: FC<{
  value: OrderFormContextValue;
}> = ({ children, value }) => (
  <OrderFormContext.Provider value={value}>
    {children}
  </OrderFormContext.Provider>
);

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { updateAdminDriverCompanyState } from '~services/admin/driverCompany';
import { DriverCompanyStateTypes } from '~globals/types/enums';

import { useLazyRequest } from './useLazyRequest';

type DriverCompanyInvitationAction = (driverCompanyId: string) => Promise<void>;

interface DriverCompanyInvitationParams {
  onSuccessAcceptInvitation?: () => void;
  onSuccessRejectedInvitation?: () => void;
}

interface DriverCompanyInvitationReturn {
  loadingAcceptInvitation: boolean;
  handleAcceptInvitation: DriverCompanyInvitationAction;
  loadingRejectedInvitation: boolean;
  handleRejectedInvitation: DriverCompanyInvitationAction;
}

export const useDriverCompanyInvitation = ({
  onSuccessAcceptInvitation,
  onSuccessRejectedInvitation,
}: DriverCompanyInvitationParams): DriverCompanyInvitationReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingAccept, , executeAccept] = useLazyRequest({
    request: updateAdminDriverCompanyState,
    withPostSuccess: () => {
      enqueueSnackbar(`La invitacíon fue aceptada correctamente`, {
        variant: 'success',
      });

      onSuccessAcceptInvitation?.();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo aceptar la invitacíon, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const [, loadingRejected, , executeRejected] = useLazyRequest({
    request: updateAdminDriverCompanyState,
    withPostSuccess: () => {
      enqueueSnackbar(`La invitacíon fue rechazada correctamente`, {
        variant: 'success',
      });

      onSuccessRejectedInvitation?.();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo rechazar la invitacíon, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleAcceptInvitation = useCallback<DriverCompanyInvitationAction>(
    async (driverCompanyId) => {
      await executeAccept({
        driverCompanyId,
        driverCompanyStateTypeId: DriverCompanyStateTypes.Approve,
      });
    },
    [executeAccept],
  );

  const handleRejectedInvitation = useCallback<DriverCompanyInvitationAction>(
    async (driverCompanyId) => {
      await executeRejected({
        driverCompanyId,
        driverCompanyStateTypeId: DriverCompanyStateTypes.Rejected,
      });
    },
    [executeRejected],
  );

  return {
    loadingAcceptInvitation: loadingAccept,
    handleAcceptInvitation,
    loadingRejectedInvitation: loadingRejected,
    handleRejectedInvitation,
  };
};

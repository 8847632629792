import { ReactElement } from 'react';
import { ChipProps } from '@mui/material';

import { ComminSoonBadgeRoot } from './styles';

const ComminSoonBadge = ({
  label = 'Pronto',
  variant = 'outlined',
  ...rest
}: ChipProps): ReactElement => (
  <ComminSoonBadgeRoot label={label} variant={variant} {...rest} />
);

export default ComminSoonBadge;

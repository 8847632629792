import { ReactElement, useCallback, useMemo, useRef } from 'react';
import {
  PayPalScriptProvider,
  usePayPalScriptReducer,
  PayPalButtonsComponentProps,
} from '@paypal/react-paypal-js';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import { createPaypalSubscription } from '~services/subscription/payment';

import { useSubscriptionPaymentModalContext } from '../../SubscriptionPaymentModalContext';

import {
  SubscriptionPaymentModalPaypalButtonPaypalButtonRoot,
  SubscriptionPaymentModalPaypalButtonPaypalButtonSpinner,
} from './styles';

const PAYPAL_CLIENT_ID = String(process.env.REACT_APP_PAYPAL_CLIENT_ID);

const style: PayPalButtonsComponentProps['style'] = {
  layout: 'horizontal',
  color: 'blue',
  tagline: false,
  height: 48,
  disableMaxWidth: true,
};

const SubscriptionPaymentModalPaypalButton = (
  props: PayPalButtonsComponentProps,
): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [{ isPending }] = usePayPalScriptReducer();

  const {
    paymentType,
    providersPlansId,
    billingData: { data: billingData },
    processPayment: { setIsProcess: setIsProcessPayment },
    contractPlanIdTest,
  } = useSubscriptionPaymentModalContext();

  const contractPlanId = useMemo(
    () => contractPlanIdTest ?? get(providersPlansId, ['paypal', paymentType]),
    [paymentType, providersPlansId, contractPlanIdTest],
  );

  const billingDataRef = useRef(billingData);
  billingDataRef.current = billingData;

  const handleCreateSubscription = useCallback<
    Required<PayPalButtonsComponentProps>['createSubscription']
  >(
    async (_paymentData, actions) => {
      if (contractPlanId && billingDataRef.current) {
        const response = await createPaypalSubscription({
          contractPlanId,
          billingData: billingDataRef.current,
        });

        if (response.ok && response.data) {
          const { planId, companyPlanId } = response.data.data;

          const orderId = await actions.subscription.create({
            plan_id: planId,
            custom_id: companyPlanId,
            application_context: {
              return_url: 'https://app.routix.io/',
              cancel_url: 'https://app.routix.io/',
              shipping_preference: 'NO_SHIPPING',
            },
          });

          return orderId;
        }
      }

      return '';
    },
    [contractPlanId],
  );

  const handleApprove = useCallback<
    Required<PayPalButtonsComponentProps>['onApprove']
  >(async () => {
    setIsProcessPayment(true);
  }, [setIsProcessPayment]);

  const handleError = useCallback<
    Required<PayPalButtonsComponentProps>['onError']
  >(() => {
    enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
      variant: 'error',
    });
  }, [enqueueSnackbar]);

  if (isPending) {
    return (
      <SubscriptionPaymentModalPaypalButtonPaypalButtonSpinner
        variant="rectangular"
        width={280}
        height={48}
        animation="wave"
      />
    );
  }

  return (
    <SubscriptionPaymentModalPaypalButtonPaypalButtonRoot
      {...props}
      style={style}
      forceReRender={[style]}
      fundingSource={undefined}
      createSubscription={handleCreateSubscription}
      onApprove={handleApprove}
      onError={handleError}
    />
  );
};

const SubscriptionPaymentModalPaypalButtonButtonWrapper = (
  props: PayPalButtonsComponentProps,
): ReactElement => {
  return (
    <PayPalScriptProvider
      options={{
        clientId: PAYPAL_CLIENT_ID,
        components: 'buttons',
        intent: 'subscription',
        vault: true,
        currency: 'USD',
      }}
    >
      <SubscriptionPaymentModalPaypalButton {...props} />
    </PayPalScriptProvider>
  );
};

export default SubscriptionPaymentModalPaypalButtonButtonWrapper;

import {
  styled,
  Box,
  Button,
  ButtonProps,
  buttonClasses,
  Link as MuiLink,
} from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export const FirstStepsMenuContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  maxWidth: 400,

  '&::before': {
    content: '""',
    position: 'absolute',
    height: 130,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const FirstStepsMenuHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  color: theme.palette.common.white,
  paddingBottom: theme.spacing(1.5),
}));

export const FirstStepsMenuTutorialVideoLink = styled(MuiLink)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 200,
  borderRadius: theme.shape.borderRadius,
}));

export const FirstStepsMenuTutorialVideoImg = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
  objectFit: 'cover',
});

export const FirstStepsMenuContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

export const FirstStepsMenuContentStep = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'flex-start',

  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
}));

export const FirstStepsMenuContentStepIndicator = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  height: 20,
  width: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FirstStepsMenuContentStepInfo = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const FirstStepsMenuContentStepInfoActions = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  }),
);

export const FirstStepsMenuContentStepInfoActionsButton = styled(
  (props: ButtonProps<'a'> & RouterLinkProps) => (
    <Button {...props} component={RouterLink} />
  ),
)(({ theme }) => ({
  ...theme.typography.caption,

  [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
    fontSize: theme.typography.caption.fontSize,
  },
}));

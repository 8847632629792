import { useScript } from './useScript';

const smartLookId = 'd1b98837d83cca4ad701b5e70341232c9594a000';

const scriptInitializedId = 'smartlook-initialized';
const scriptInitializedSrc = 'https://web-sdk.smartlook.com/recorder.js';

const scriptCallbackId = 'smartlook-callback';
const scriptCalbackInnerHtml = `
  window.smartlook ||
    (function (d) {
      var o = (smartlook = function () {
          o.api.push(arguments);
        });
      o.api = new Array();
    })();

  smartlook("init", "${smartLookId}", {
    region: "eu",
  });
`;

export const useLoadSmartLook = (): void => {
  useScript({
    id: scriptInitializedId,
    position: document.querySelector('head'),
    src: scriptInitializedSrc,
    async: true,
    type: 'text/javascript',
    charset: 'utf-8',
    validateOnlyProduction: true,
  });

  useScript({
    id: scriptCallbackId,
    position: document.querySelector('head'),
    innerHTML: scriptCalbackInnerHtml,
    type: 'text/javascript',
    validateOnlyProduction: true,
  });
};

import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
} from '@mui/material';

export const SubscriptionPaymentModalRoot = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: 1060,
    height: '100%',
  },
});

export const SubscriptionPaymentModalLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const SubscriptionPaymentModalContent = styled(DialogContent)({
  padding: 0,
});

export const SubscriptionPaymentModalContentForm = styled('div')({
  display: 'grid',
  gridTemplateColumns: '280px 1fr',
  height: '100%',
});

export const SubscriptionPaymentModalActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

import { styled, Box, Avatar, Chip } from '@mui/material';

export const InitialSetupWizardRelatedAssociatedCompanyInfoHeader = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  }),
);

export const InitialSetupWizardRelatedAssociatedCompanyInfoHeaderAvatar =
  styled(Avatar)(({ theme }) => ({
    width: 100,
    height: 100,
    border: `1px solid ${theme.palette.divider}`,
  }));

export const InitialSetupWizardRelatedAssociatedCompanyInfoHeaderIcon = styled(
  'img',
)({
  width: 30,
  objectFit: 'contain',
});

export const InitialSetupWizardRelatedAssociatedCompanyInfoChip = styled(Chip)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  }),
);

import * as Yup from 'yup';

import { SelectOption } from '~globals/types/commons';
import { CardTypes } from '~globals/types/enums';
import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  CARD_NUMBER: 'cardNumber',
  CARD_TYPE: 'cardType',
  CARD_EXPIRATION: 'cardExpiration',
  CARD_CVV: 'cardCVV',
  HOLDERNAME: 'holderName',
  IDENTIFICATION_TYPE: 'identificationTypeId',
  IDENTIFICATION_NUMBER: 'identificationId',
  EMAIL: 'email',
} as const;

export const DOCUMENTS_OPTIONS: SelectOption[] = [
  { value: 'DNI', label: 'DNI' },
  // { value: 1, label: 'Cédula' },
  // { value: 2, label: 'L.C.' },
  // { value: 3, label: 'L.E.' },
  // { value: 4, label: 'Otro' },
];

export const CARD_TYPES_OPTIONS: SelectOption[] = [
  { value: String(CardTypes.Credit), label: 'Crédito' },
  { value: String(CardTypes.Debit), label: 'Débito' },
];

export const INITIAL_VALUES = {
  [FIELDS_NAME.CARD_NUMBER]: '',
  [FIELDS_NAME.CARD_TYPE]: String(CARD_TYPES_OPTIONS[0].value),
  [FIELDS_NAME.CARD_EXPIRATION]: '',
  [FIELDS_NAME.CARD_CVV]: '',
  [FIELDS_NAME.HOLDERNAME]: '',
  [FIELDS_NAME.IDENTIFICATION_TYPE]: String(DOCUMENTS_OPTIONS[0].value),
  [FIELDS_NAME.IDENTIFICATION_NUMBER]: '',
  [FIELDS_NAME.EMAIL]: '',
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  [FIELDS_NAME.CARD_NUMBER]: Yup.string(),
  [FIELDS_NAME.CARD_TYPE]: Yup.string()
    .oneOf(CARD_TYPES_OPTIONS.map((cardType) => String(cardType.value)))
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.CARD_EXPIRATION]: Yup.string(),
  [FIELDS_NAME.CARD_CVV]: Yup.string(),
  [FIELDS_NAME.HOLDERNAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.IDENTIFICATION_TYPE]: Yup.string(),
  [FIELDS_NAME.IDENTIFICATION_NUMBER]: Yup.string()
    .required(VALIDATIONS_ERROR.REQUIRED)
    .min(7, VALIDATIONS_ERROR.MIN(7)),
  [FIELDS_NAME.EMAIL]: Yup.string()
    .email(VALIDATIONS_ERROR.EMAIL)
    .required(VALIDATIONS_ERROR.REQUIRED),
});

export const ERROR_MESSAGES = {
  emptyCardNumber: 'El número de la tarjeta es inválido',
  invalidCardNumber: 'El número de la tarjeta es inválido',
  emptyExpiryDate: 'La fecha de expiración es inválida',
  monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
  yearOutOfRange: 'El año de expiración no puede estar en el pasado',
  dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
  invalidExpiryDate: 'La fecha de expiración es inválida',
  emptyCVC: 'El código de seguridad es inválido',
  invalidCVC: 'El código de seguridad es inválido',
};

export const VALID_CARD_ISSUERS = ['visa', 'mastercard', 'amex'];

export const parseCardIssuer = (cardIssuer: string): string => {
  if (cardIssuer === 'mastercard') return 'master';

  return cardIssuer;
};

export const deleteSpacesString = (str: string): string =>
  str.replaceAll(' ', '');

import { ReactElement, useState, useCallback, useEffect } from 'react';
import { ClickAwayListener, Grow, useTheme } from '@mui/material';

import { MenuProps } from './types';
import { menuClasses, MenuContainer, MenuContent, MenuArrow } from './styles';

const Menu = ({
  open,
  className,
  children,
  arrow = false,
  arrowColor,
  onClose,
  ...restProps
}: MenuProps): ReactElement => {
  const theme = useTheme();

  const handleKeyDown = useCallback(
    (nativeEvent: KeyboardEvent) => {
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (!open) {
      return undefined;
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleKeyDown]);

  const [arrowRef, setArrowRef] = useState<null | HTMLSpanElement>(null);

  return (
    <MenuContainer
      role="presentation"
      className={className}
      transition
      modifiers={[
        {
          name: 'arrow',
          enabled: Boolean(arrowRef),
          options: {
            element: arrowRef,
            padding: 16,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            rootBoundary: 'document',
            padding: { right: 16, left: 16 },
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      open={open}
      disablePortal
      {...restProps}
    >
      {({
        placement: placementInner,
        TransitionProps: TransitionPropsInner,
      }) => (
        <ClickAwayListener onClickAway={onClose}>
          <Grow
            timeout={theme.transitions.duration.shorter}
            {...TransitionPropsInner}
          >
            <MenuContent
              placement={placementInner.split('-')[0]}
              arrow={arrow}
              elevation={8}
            >
              {children}

              {arrow && (
                <MenuArrow
                  className={menuClasses.arrow}
                  ref={setArrowRef}
                  arrowColor={arrowColor}
                />
              )}
            </MenuContent>
          </Grow>
        </ClickAwayListener>
      )}
    </MenuContainer>
  );
};

export default Menu;

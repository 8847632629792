import API from '~config/api';
import {
  ApiPromise,
  PaginationRequestParams,
  DataPaginateResponse,
  DataResponse,
  ErrorResponse,
} from '~globals/types';

import {
  AdminWarehouse,
  AdminWarehouseCreateBody,
  AdminWarehouseFilter,
  AdminWarehouseUpdateBody,
} from './types';

export const ADMIN_WAREHOUSE_ENPOINTS = {
  createAdminWarehouse: '/api/v1/Admin/Warehouse',
  getAdminDepositById: (warehouseId: string): string =>
    `/api/v1/Admin/Warehouse/${warehouseId}`,
  searchAdminWarehouses: '/api/v1/Admin/Warehouse/search',
  updateAdminWarehouse: '/api/v1/Admin/Warehouse',
  deleteAdminWarehouse: (warehouseId: string): string =>
    `/api/v1/Admin/Warehouse/${warehouseId}`,
};

export const createAdminWarehouse = (
  body: AdminWarehouseCreateBody,
): ApiPromise<DataResponse<AdminWarehouse>, ErrorResponse> =>
  API.post<DataResponse<AdminWarehouse>, ErrorResponse>(
    ADMIN_WAREHOUSE_ENPOINTS.createAdminWarehouse,
    body,
  );

export const getAdminDepositById = (
  warehouseId: string,
): ApiPromise<DataResponse<AdminWarehouse>, ErrorResponse> =>
  API.get<DataResponse<AdminWarehouse>, ErrorResponse>(
    ADMIN_WAREHOUSE_ENPOINTS.getAdminDepositById(warehouseId),
  );

export const searchAdminWarehouses = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<AdminWarehouseFilter>): ApiPromise<
  DataPaginateResponse<AdminWarehouse[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<AdminWarehouse[]>, ErrorResponse>(
    ADMIN_WAREHOUSE_ENPOINTS.searchAdminWarehouses,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const updateAdminWarehouse = (
  body: AdminWarehouseUpdateBody,
): ApiPromise<DataResponse<AdminWarehouse>, ErrorResponse> =>
  API.put<DataResponse<AdminWarehouse>, ErrorResponse>(
    ADMIN_WAREHOUSE_ENPOINTS.updateAdminWarehouse,
    body,
  );

export const deleteAdminWarehouse = (
  warehouseId: string,
): ApiPromise<DataResponse<AdminWarehouse>, ErrorResponse> =>
  API.delete<DataResponse<AdminWarehouse>, ErrorResponse>(
    ADMIN_WAREHOUSE_ENPOINTS.deleteAdminWarehouse(warehouseId),
  );

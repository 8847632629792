import { RouteItemStateType } from '~globals/types/enums';

export const ROUTE_ITEM_STATE: Record<RouteItemStateType, string> = {
  [RouteItemStateType.New]: 'Nuevo',
  [RouteItemStateType.OnAgenda]: 'En agenda',
  [RouteItemStateType.InProgress]: 'En progreso',
  [RouteItemStateType.FinalizedSuccess]: 'Visita completa',
  [RouteItemStateType.FinalizedError]: 'Visita incompleta',
  [RouteItemStateType.WithoutVisiting]: 'Sin visitar',
};

export const ROUTE_ITEM_STATE_COLOR: Record<RouteItemStateType, string> = {
  [RouteItemStateType.New]: '#757575',
  [RouteItemStateType.OnAgenda]: '#757575',
  [RouteItemStateType.InProgress]: '#757575',
  [RouteItemStateType.FinalizedSuccess]: '#1DB45A',
  [RouteItemStateType.FinalizedError]: '#EB0014',
  [RouteItemStateType.WithoutVisiting]: '#DEA500',
};

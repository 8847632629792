import { ReactElement, useCallback, useMemo } from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import {
  MoveToInbox as MoveToInboxIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  CheckCircleOutline as ValidateIcon,
  HighlightOff as CancelIcon,
} from '@mui/icons-material';

import {
  useToggleMenu,
  useToggle,
  useValidatePartnerItems,
  useNotValidatedPartnerItems,
} from '~hooks/index';
import { DialogConfimation } from '~components/index';

import { CollectActionsMenuProps } from './types';
import {
  CollectActionsMenuReceivedMainButton,
  CollectActionsMenuContainerMenu,
} from './styles';

const CollectActionsMenu = ({
  selectedOrdersIds,
  onSubmitValidateOrders,
  onSubmitNotValidatedOrders,
}: CollectActionsMenuProps): ReactElement => {
  const [anchorElMenu, openMenu, handleToggleMenu] = useToggleMenu();
  const handleOpenCurrentDialog = useCallback(
    (toggleOpenCurrentDialog: (nextValue?: boolean | undefined) => void) => {
      toggleOpenCurrentDialog(true);
      handleToggleMenu();
    },
    [handleToggleMenu],
  );

  const isDiabledItem = useMemo(
    () => selectedOrdersIds.length === 0,
    [selectedOrdersIds],
  );

  // Validate
  const [openConfirmValidateOrders, toggleOpenConfirmValidateOrders] =
    useToggle();

  const handleSuccessValidateOrders = useCallback(() => {
    toggleOpenConfirmValidateOrders(false);

    onSubmitValidateOrders?.();
  }, [toggleOpenConfirmValidateOrders, onSubmitValidateOrders]);

  const {
    loading: loadingValidateOrders,
    handleValidatePartnerItems: executeValidateOrders,
  } = useValidatePartnerItems(handleSuccessValidateOrders);

  const handleValidateOrders = useCallback(async () => {
    await executeValidateOrders(selectedOrdersIds);
  }, [executeValidateOrders, selectedOrdersIds]);

  // Not Validated
  const [openConfirmNotValidatedOrders, toggleOpenConfirmNotValidatedOrders] =
    useToggle();

  const handleSuccessNotValidatedOrders = useCallback(() => {
    toggleOpenConfirmNotValidatedOrders(false);

    onSubmitNotValidatedOrders?.();
  }, [toggleOpenConfirmNotValidatedOrders, onSubmitNotValidatedOrders]);

  const {
    loading: loadingNotValidatedOrders,
    handleNotValidatedPartnerItems: executeNotValidatedOrders,
  } = useNotValidatedPartnerItems(handleSuccessNotValidatedOrders);

  const handleNotValidateOrders = useCallback(async () => {
    await executeNotValidatedOrders(selectedOrdersIds);
  }, [executeNotValidatedOrders, selectedOrdersIds]);

  return (
    <>
      <CollectActionsMenuReceivedMainButton
        variant="outlined"
        color="secondary"
        startIcon={<MoveToInboxIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        ref={anchorElMenu}
        aria-controls={openMenu ? 'collect-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleToggleMenu}
      >
        Acciones de colecta
      </CollectActionsMenuReceivedMainButton>

      <CollectActionsMenuContainerMenu
        id="collect-actions-menu"
        anchorEl={anchorElMenu.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={openMenu}
        onClose={handleToggleMenu}
        elevation={0}
      >
        <MenuItem
          onClick={() =>
            handleOpenCurrentDialog(toggleOpenConfirmValidateOrders)
          }
          disabled={isDiabledItem}
        >
          <ListItemIcon>
            <ValidateIcon color="success" />
          </ListItemIcon>

          <ListItemText primary="Marcar como colectados" />
        </MenuItem>

        <MenuItem
          onClick={() =>
            handleOpenCurrentDialog(toggleOpenConfirmNotValidatedOrders)
          }
          disabled={isDiabledItem}
        >
          <ListItemIcon>
            <CancelIcon color="error" />
          </ListItemIcon>

          <ListItemText primary="Marcar como no colectados" />
        </MenuItem>
      </CollectActionsMenuContainerMenu>

      <DialogConfimation
        open={openConfirmValidateOrders}
        description="Los pedidos seleccionados serán marcado como colectados"
        onConfirm={handleValidateOrders}
        onCancel={() => toggleOpenConfirmValidateOrders(false)}
        loadingConfirm={loadingValidateOrders}
      />

      <DialogConfimation
        open={openConfirmNotValidatedOrders}
        description="Los pedidos seleccionados serán marcado como no colectados"
        onConfirm={handleNotValidateOrders}
        onCancel={() => toggleOpenConfirmNotValidatedOrders(false)}
        loadingConfirm={loadingNotValidatedOrders}
      />
    </>
  );
};

export default CollectActionsMenu;

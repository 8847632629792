import { useState, useCallback, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { isFunction, isEqual, defaultTo } from 'lodash';
import { TableChangeType } from 'react-bootstrap-table-next';

import {
  Request,
  RequestTransformResponse,
  RequestError,
  Nullable,
  DataPaginateResponse,
  PaginationRequestParams,
} from '~globals/types';
import { useRequest } from './useRequest';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DataTableChangeHandler<S extends PaginationRequestParams<any>> = (
  type: TableChangeType,
  newState: Partial<S>,
) => void;

export interface AsyncDataTableRequestHooks<P, D, E> {
  request: Request<P, D, E>;
  payload: P;
  transformResponse?: RequestTransformResponse<D, E>;
  isValidToRequest?: boolean;
}

export interface AsyncDataTableRequestHooksReturn<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends PaginationRequestParams<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends DataPaginateResponse<any[], any, any>,
  E,
> {
  data: D['data']['results'];
  stats: D['data']['stats'];
  loading: boolean;
  error: Nullable<RequestError<E>>;
  reloadRequest: (newQueryParams?: P) => void;
  totalSize: number;
  queryParams: P;
  handleInitialData: () => void;
  handleChangeTable: DataTableChangeHandler<P>;
  handleInitialTable: () => void;
}

export const useDataTableRequest = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends PaginationRequestParams<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  D extends DataPaginateResponse<any[], any, any>,
  E,
>({
  request,
  payload,
  transformResponse = (response) => response.data,
  isValidToRequest = true,
}: AsyncDataTableRequestHooks<P, D, E>): AsyncDataTableRequestHooksReturn<
  P,
  D,
  E
> => {
  const initialPayload = useRef(payload);
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState<D[]>([]);
  const [stats, setStats] = useState<D['data']['stats']>(null);
  const [totalSize, setTotalSize] = useState<number>(0);
  const [queryParams, setQueryParamsBase] = useState<P>(payload);

  const handleInitialData = useCallback(() => {
    setData([]);
  }, []);

  const setQueryParams = useCallback(
    (nextQueryParams: P | ((params: P) => P)): void => {
      setQueryParamsBase((prevQueryParams) => {
        if (isFunction(nextQueryParams)) {
          nextQueryParams = nextQueryParams(prevQueryParams);
        }

        if (isEqual(prevQueryParams, nextQueryParams)) {
          return prevQueryParams;
        }

        return nextQueryParams;
      });
    },
    [],
  );

  const handleChangeTable = useCallback<DataTableChangeHandler<P>>(
    (_type, { pagination, filters }) => {
      setQueryParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          ...pagination,
          page: (pagination?.page as number) === 0 ? 1 : pagination?.page,
        },
        filters: {
          ...prev.filters,
          ...filters,
        },
      }));
    },
    [setQueryParams],
  );

  const handleInitialTable = useCallback(() => {
    setQueryParams(initialPayload.current);
  }, [setQueryParams]);

  const [, loading, error, sendRequest] = useRequest(
    {
      initialState: [],
      request,
      payload: queryParams,
      isValidToRequest,
      transformResponse,
      withPostSuccess: (response) => {
        const {
          data: { pagination, results, stats: currentStats },
        } = response as unknown as DataPaginateResponse<D[]>;

        setData(results);
        setStats(currentStats);
        setTotalSize(defaultTo(pagination?.count, results.length));
      },
      withPostFailure: () => {
        enqueueSnackbar('No se pudo cargar los datos', { variant: 'error' });
      },
    },
    [queryParams],
  );

  const reloadRequest = useCallback(
    (newQueryParams?: P) => {
      const queryParamsSend: P = {
        ...queryParams,
        pagination: {
          ...queryParams.pagination,
          ...newQueryParams?.pagination,
        },
        filters: {
          ...queryParams.filters,
          ...newQueryParams?.filters,
        },
      };

      return sendRequest(queryParamsSend);
    },
    [queryParams, sendRequest],
  );

  return {
    data,
    stats,
    loading,
    error,
    reloadRequest,
    totalSize,
    queryParams,
    handleInitialData,
    handleChangeTable,
    handleInitialTable,
  };
};

import { styled } from '@mui/material';

import { Marker as MarkerIcon } from '~components/Icons';

const shouldForwardProp = (prop: string): boolean => prop !== 'currentColor';

export const MarkerStopContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 6,
  position: 'relative',
});

export const MarkerStopIconMarker = styled(MarkerIcon, {
  shouldForwardProp: (prop) => prop !== 'currentColor',
})<{ currentColor: string }>(({ theme, currentColor }) => ({
  fontSize: theme.typography.pxToRem(30),
  color: currentColor,
  zIndex: 1,
}));

export const MarkerStopInfoStopContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{ backgroundColor?: string }>(({ theme, backgroundColor: bgProp }) => {
  const backgroundColor = bgProp ?? theme.palette.common.white;
  const color = bgProp
    ? theme.palette.common.white
    : theme.palette.text.primary;

  return {
    backgroundColor,
    color,
    width: 18,
    height: 18,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 4,
    left: 6,
  };
});

export const MarkerStopInfoStopText = styled('span', {
  shouldForwardProp,
})(({ theme }) => ({
  color: 'inherit',
  fontSize: theme.typography.pxToRem(10),
  lineHeight: 20,
  fontWeight: theme.typography.fontWeightBold,
}));

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { deleteAdminWarehouse } from '~services/admin/warehouse';

import { useLazyRequest } from './useLazyRequest';

export const useWarehouseAdminDelete = (
  onSuccessDelete?: () => void,
): {
  loading: boolean;
  handleDeleteWarehouse: (depositId: string) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingDeleteWarehouse, , executeDeleteWarehouse] = useLazyRequest({
    request: deleteAdminWarehouse,
    withPostSuccess: (response) => {
      const warehouseName = response.data?.data.title;
      const message = `El depósito ${warehouseName} fue eliminado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      onSuccessDelete?.();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo elimnar el depósito, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleDeleteWarehouse = useCallback(
    async (depositId: string) => {
      await executeDeleteWarehouse(depositId);
    },
    [executeDeleteWarehouse],
  );

  return { loading: loadingDeleteWarehouse, handleDeleteWarehouse };
};

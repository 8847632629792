import { styled, Card, CardContent, Box } from '@mui/material';

export const RouteCardContainer = styled(Card)({
  minHeight: 88,
});

export const RouteCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5, 2),

  '&:last-child': {
    paddingBottom: theme.spacing(0.5),
  },
}));

export const RouteCardVehicleInfoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  alignItems: 'center',
}));

export const RouteCardVehicleInfoBoxTitleContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    flex: 1,
    fontWeight: theme.typography.fontWeightBold,
  }),
);

export const RouteCardRouteInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'column',
}));

export const RouteCardRouteInfoBoxRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

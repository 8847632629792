import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';
import { DriverCompanyBase } from '~services/driver/types';

export const FIELDS_NAME = {
  CURRENT_DRIVER: 'currentDriver',
} as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (currentDriver?: DriverCompanyBase) => ({
  [FIELDS_NAME.CURRENT_DRIVER]: currentDriver?.id ?? '',
});

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.CURRENT_DRIVER]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
});

import { ReactElement, useMemo, memo } from 'react';
import { useTheme } from '@mui/material';
import { min } from 'lodash';

import { useAvailablesRoutes } from '~hooks/index';

import { getColorState } from '../utils';

import {
  SubscriptionAvailableRoutesStateCircularProgressSizes,
  SubscriptionAvailableRoutesStateCircularProgressProps,
} from './types';
import {
  SubscriptionAvailableRoutesStateCircularProgressContainer,
  SubscriptionAvailableRoutesStateCircularProgressContent,
  SubscriptionAvailableRoutesStateCircularProgressFullProgress,
  SubscriptionAvailableRoutesStateCircularProgressDymanicProgress,
  SubscriptionAvailableRoutesStateCircularProgressLegendContainer,
  SubscriptionAvailableRoutesStateCircularProgressLegendText,
  SubscriptionAvailableRoutesStateCircularProgressLabelText,
} from './styles';

const sizes: Record<
  SubscriptionAvailableRoutesStateCircularProgressSizes,
  number
> = {
  xSmall: 50,
  small: 60,
  medium: 80,
  large: 100,
};

const thickness = 4.5;

const maxPercentage = 100;

const SubscriptionAvailableRoutesStateCircularProgress = ({
  currentRoutes,
  maxRoutes,
  label,
  size = 'small',
  disabled = false,
}: SubscriptionAvailableRoutesStateCircularProgressProps): ReactElement => {
  const currentTheme = useTheme();

  const { progress, itsHalfway, isEnding } = useAvailablesRoutes({
    currentRoutes,
    maxRoutes,
  });

  const legend = useMemo(
    () => `${currentRoutes}/${maxRoutes}`,
    [currentRoutes, maxRoutes],
  );

  const currentSizeVal = useMemo(() => sizes[size], [size]);

  const color = useMemo(() => {
    let currentColor = getColorState(
      itsHalfway,
      isEnding,
      currentTheme.palette.primary.main,
    );

    if (disabled) currentColor = currentTheme.palette.grey[400];

    return currentColor;
  }, [currentTheme, itsHalfway, isEnding, disabled]);

  const progressValue = useMemo(() => min([100, progress]), [progress]);

  return (
    <SubscriptionAvailableRoutesStateCircularProgressContainer>
      <SubscriptionAvailableRoutesStateCircularProgressContent>
        <SubscriptionAvailableRoutesStateCircularProgressFullProgress
          variant="determinate"
          size={currentSizeVal}
          thickness={thickness}
          value={maxPercentage}
          color="inherit"
        />

        <SubscriptionAvailableRoutesStateCircularProgressDymanicProgress
          variant="determinate"
          size={currentSizeVal}
          thickness={thickness}
          value={progressValue}
          currentColor={color}
        />

        <SubscriptionAvailableRoutesStateCircularProgressLegendContainer>
          <SubscriptionAvailableRoutesStateCircularProgressLegendText
            variant="inherit"
            size={size}
            currentColor={color}
          >
            {legend}
          </SubscriptionAvailableRoutesStateCircularProgressLegendText>
        </SubscriptionAvailableRoutesStateCircularProgressLegendContainer>
      </SubscriptionAvailableRoutesStateCircularProgressContent>

      {label && (
        <SubscriptionAvailableRoutesStateCircularProgressLabelText
          variant="inherit"
          size={size}
        >
          {label}
        </SubscriptionAvailableRoutesStateCircularProgressLabelText>
      )}
    </SubscriptionAvailableRoutesStateCircularProgressContainer>
  );
};

export default memo(SubscriptionAvailableRoutesStateCircularProgress);

import { ReactElement, useCallback, useEffect } from 'react';
import { Typography, Stack, TextField, DialogActions } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { useAppSelector, useLazyRequest } from '~hooks/index';
import { sendAdminDriverInvitation } from '~services/admin/driver';
import { selectAuth } from '~store/slices/auth';
import { hasError } from '~utils/index';

import { DialogDriverCompanyInviteProps } from './types';
import {
  DialogDriverCompanyInviteContainer,
  DialogDriverCompanyInviteTitle,
  DialogDriverCompanyInviteTitleCloseIcon,
  DialogDriverCompanyInviteContent,
} from './styles';
import CopyButton from '../CopyButton';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const DialogDriverCompanyInvite = ({
  open,
  onClose,
  onInviteSuccess,
}: DialogDriverCompanyInviteProps): ReactElement => {
  const { user } = useAppSelector(selectAuth);
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingSendInvitation, , executeSendInvitation] = useLazyRequest({
    request: sendAdminDriverInvitation,
    withPostSuccess: () => {
      enqueueSnackbar(`Se ha enviado la invitación correctamente`, {
        variant: 'success',
      });

      onInviteSuccess?.();

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo enviar la invitacíon, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await executeSendInvitation(values[FIELDS_NAME.EMAIL]);

      setSubmitting(false);
    },
    [executeSendInvitation],
  );

  const {
    resetForm,
    submitForm,
    isSubmitting,
    dirty,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogDriverCompanyInviteContainer open={open} maxWidth="md">
      <DialogDriverCompanyInviteTitle>
        <Typography component="h2" variant="h6">
          {`Invitar a choferes a ${user?.company.name}`}
        </Typography>

        <DialogDriverCompanyInviteTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogDriverCompanyInviteTitleCloseIcon>
      </DialogDriverCompanyInviteTitle>

      <DialogDriverCompanyInviteContent dividers>
        <Stack spacing={2}>
          <TextField
            label="Para"
            placeholder="nombre@example.com"
            fullWidth
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
            {...getFieldProps(FIELDS_NAME.EMAIL)}
            InputLabelProps={{ shrink: true }}
          />

          <Typography variant="body2">
            Al invitar a un chofer, le estara llegando un correo con la
            información de la invitación, o puede compartir el código de
            invitación.
          </Typography>

          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="body2">
              Código de Invitación: <b>{user?.company.invitationCode}</b>
            </Typography>

            <CopyButton text={user?.company.invitationCode as string} />
          </Stack>
        </Stack>
      </DialogDriverCompanyInviteContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loadingSendInvitation || isSubmitting}
          disabled={!dirty}
        >
          Enviar
        </LoadingButton>
      </DialogActions>
    </DialogDriverCompanyInviteContainer>
  );
};

export default DialogDriverCompanyInvite;

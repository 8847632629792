import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import {
  Typography,
  Stack,
  TextField,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '~hooks/index';
import { cancelPartnerItems } from '~services/partnerCompany';
import { getItemValue, hasError } from '~utils/index';

import { DialogCancelPartnerItemProps } from './types';
import {
  DialogCancelPartnerItemContainer,
  DialogCancelPartnerItemTitle,
  DialogCancelPartnerItemTitleCloseIcon,
  DialogCancelPartnerItemContent,
} from './styles';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const DialogCancelPartnerItem = ({
  open,
  onClose,
  onCancelSuccess,
  partnerCompany,
}: DialogCancelPartnerItemProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const title = useMemo(() => {
    return `Desasignar pedido: ${partnerCompany.trackingCode}`;
  }, [partnerCompany]);

  const [, loading, , executeCancelPartnerItem] = useLazyRequest({
    request: cancelPartnerItems,
    withPostSuccess: () => {
      const message = `El pedido ${partnerCompany.trackingCode} fue desasignado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      onCancelSuccess?.();

      onClose();
    },
    withPostFailure: (err) => {
      let errorMessage = 'No se pudo desasignar el pedido, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      const rejectedNotes = getItemValue(
        values,
        FIELDS_NAME.REJECTED_NOTE,
        (val) => (val ? [val] : ['']),
        [''],
      );

      await executeCancelPartnerItem({
        itemIds: [partnerCompany.itemId],
        rejectedNotes,
      });

      setSubmitting(false);
    },
    [executeCancelPartnerItem, partnerCompany],
  );

  const {
    resetForm,
    submitForm,
    isSubmitting,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogCancelPartnerItemContainer open={open} maxWidth="md">
      <DialogCancelPartnerItemTitle>
        <Typography component="h2" variant="h6">
          {title}
        </Typography>

        <DialogCancelPartnerItemTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogCancelPartnerItemTitleCloseIcon>
      </DialogCancelPartnerItemTitle>

      <DialogCancelPartnerItemContent dividers>
        <Stack spacing={2}>
          <DialogContentText color="text.secondary" variant="caption">
            Al confirmar esta acción, el pedido seleccionado dejará de estar
            vinculado a la empresa de transporte asignada. Por favor, completa
            el siguiente cuadro con el motivo de desasignación para que podamos
            notificar adecuadamente a la empresa relacionada.
          </DialogContentText>

          <TextField
            label="Motivo de desasignación (opcional)"
            fullWidth
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.REJECTED_NOTE)}
            {...getFieldProps(FIELDS_NAME.REJECTED_NOTE)}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogCancelPartnerItemContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loading || isSubmitting}
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </DialogCancelPartnerItemContainer>
  );
};

export default DialogCancelPartnerItem;

export const VALIDATIONS_ERROR = {
  REQUIRED: 'Campo requerido',
  EMAIL: 'El email es inválido',
  PASSWORD_NOT_MATCH: 'La contraseñas no coinciden',
  INVALID_DATE: 'Fecha invalida',
  MIN: (val: number): string => `Debe ser mayor o igual que ${val}`,
  MORE_THAN: (val: number): string => `Debe ser mayor que ${val}`,
  PHONE: 'El formato de teléfono es inválido',
  URL: 'El formato de URL es inválido',
};

import {
  styled,
  Dialog,
  dialogClasses,
  IconButton,
  DialogContent,
} from '@mui/material';

export const DialogSendTestNotificationContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 600,
    width: '100%',
  },
});

export const DialogSendTestNotificationTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogSendTestNotificationTitleCloseIcon = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.grey[500],
  }),
);

export const DialogSendTestNotificationContent = styled(DialogContent)(
  ({ theme }) => ({
    paddingBottom: theme.spacing(4),
  }),
);

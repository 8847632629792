import { ReactElement, memo } from 'react';
import {
  TableBody as TableBodyMui,
  TableRow,
  TableCell,
  Checkbox,
} from '@mui/material';

import { useTableContext } from '../TableContext';

import { useTableBodySelectable } from './useTableBodySelectable';
import { useTableBody } from './useTableBody';

const TableBody = (): ReactElement => {
  const {
    data,
    columns,
    classes,
    selectable: { enabled: enabledSelectable, ...restSelectableData },
    extractorKeyValue,
  } = useTableContext();

  const { getCheckBoxProps } = useTableBodySelectable(
    extractorKeyValue,
    restSelectableData,
  );

  const { getRowProps, getCellProps } = useTableBody(
    extractorKeyValue,
    restSelectableData.nonSelectableIds ?? [],
    classes,
    restSelectableData.nonSelectableClasses,
  );

  return (
    <TableBodyMui className={classes?.body}>
      {data.map((row, rowIndex) => (
        <TableRow key={`row-${rowIndex}`} {...getRowProps(row, rowIndex)}>
          {enabledSelectable && (
            <TableCell padding="checkbox">
              <Checkbox color="primary" {...getCheckBoxProps(row, rowIndex)} />
            </TableCell>
          )}

          {columns.map((column, columnIndex) => (
            <TableCell
              key={`row-${rowIndex}-cell-${column.dataField}`}
              component="td"
              {...getCellProps(column, columnIndex, row, rowIndex)}
            />
          ))}
        </TableRow>
      ))}
    </TableBodyMui>
  );
};

export default memo(TableBody);

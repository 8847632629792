import { ReactElement } from 'react';
import { Card, Typography, Stack } from '@mui/material';

import { RoutesStatitics, Link } from '~components/index';
import { RoutesStatiticsProps } from '~components/RoutesStatitics/types';
import { PATHS } from '~constants/index';

import { RoutesStatiticsWidgetCardContent } from './styles';

const RoutesStatiticsWidget = ({
  total,
  pending,
  inProgress,
  finish,
  size = 'small',
}: Omit<RoutesStatiticsProps, 'gutterTop'>): ReactElement => (
  <Card>
    <RoutesStatiticsWidgetCardContent>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6" fontWeight="bold">
          Rutas
        </Typography>

        <Link
          to={PATHS.ROUTES.BASE}
          color="primary"
          variant="caption"
          underline="hover"
        >
          Ver rutas
        </Link>
      </Stack>

      <RoutesStatitics
        total={total}
        pending={pending}
        inProgress={inProgress}
        finish={finish}
        size={size}
        gutterTop={false}
        withLinks
      />
    </RoutesStatiticsWidgetCardContent>
  </Card>
);

export default RoutesStatiticsWidget;

import API from '~config/api';
import {
  ApiPromise,
  PaginationRequestParams,
  DataPaginateResponse,
  DataResponse,
  ErrorResponse,
} from '~globals/types';

import {
  AdminVehicle,
  AdminVehicleCreateBody,
  AdminVehicleFilter,
  AdminVehicleUpdateBody,
} from './types';

export const ADMIN_VEHCILE_ENPOINTS = {
  createAdminVehicle: '/api/v1/Admin/Vehicle',
  getAdminVehicleById: (vehicleId: string): string =>
    `/api/v1/Admin/Vehicle/${vehicleId}`,
  searchAdminVehicles: '/api/v1/Admin/Vehicle/search',
  updateAdminVehicle: '/api/v1/Admin/Vehicle',
  deleteAdminVehicle: (vehicleId: string): string =>
    `/api/v1/Admin/Vehicle/${vehicleId}`,
};

export const createAdminVehicle = (
  body: AdminVehicleCreateBody,
): ApiPromise<DataResponse<AdminVehicle>, ErrorResponse> =>
  API.post<DataResponse<AdminVehicle>, ErrorResponse>(
    ADMIN_VEHCILE_ENPOINTS.createAdminVehicle,
    body,
  );

export const getAdminVehicleById = (
  vehicleId: string,
): ApiPromise<DataResponse<AdminVehicle>, ErrorResponse> =>
  API.get<DataResponse<AdminVehicle>, ErrorResponse>(
    ADMIN_VEHCILE_ENPOINTS.getAdminVehicleById(vehicleId),
  );

export const searchAdminVehicles = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<AdminVehicleFilter>): ApiPromise<
  DataPaginateResponse<AdminVehicle[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<AdminVehicle[]>, ErrorResponse>(
    ADMIN_VEHCILE_ENPOINTS.searchAdminVehicles,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const updateAdminVehicle = (
  body: AdminVehicleUpdateBody,
): ApiPromise<DataResponse<AdminVehicle>, ErrorResponse> =>
  API.put<DataResponse<AdminVehicle>, ErrorResponse>(
    ADMIN_VEHCILE_ENPOINTS.updateAdminVehicle,
    body,
  );

export const deleteAdminVehicle = (
  vehicleId: string,
): ApiPromise<DataResponse<AdminVehicle>, ErrorResponse> =>
  API.delete<DataResponse<AdminVehicle>, ErrorResponse>(
    ADMIN_VEHCILE_ENPOINTS.deleteAdminVehicle(vehicleId),
  );

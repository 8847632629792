import { ReactElement } from 'react';
import {
  styled,
  Breadcrumbs as MuiBreadcrumbs,
  breadcrumbsClasses,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

import { Home as HomeIcon } from '~components/Icons';
import { PATHS } from '~constants/index';

import { BreadcrumbsProps } from './types';

const CustomBreadcrumbs = styled(MuiBreadcrumbs)(({ theme }) => ({
  [`.${breadcrumbsClasses.separator}`]: {
    margin: theme.spacing(0, 0.5),
  },
}));

const Breadcrumbs = ({ items }: BreadcrumbsProps): ReactElement => (
  <CustomBreadcrumbs
    aria-label="app-breadcrumbs"
    separator={<NavigateNext fontSize="small" />}
  >
    <MuiLink color="inherit" component={RouterLink} to={PATHS.HOME.BASE}>
      <HomeIcon fontSize="small" />
    </MuiLink>

    {items.map((item, index) => {
      const last = index === items.length - 1;
      const key = `breadcumb-item-${index}`;

      return last ? (
        <Typography key={key} color="inherit" variant="body2" fontWeight="bold">
          {item.title}
        </Typography>
      ) : (
        <MuiLink
          key={key}
          color="inherit"
          variant="body2"
          component={RouterLink}
          to={item.path}
        >
          {item.title}
        </MuiLink>
      );
    })}
  </CustomBreadcrumbs>
);

export default Breadcrumbs;

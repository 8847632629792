import { styled, Dialog, dialogClasses, DialogActions } from '@mui/material';

export const GeoErrorUpateDialogContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 1300,
    width: '100%',
    minHeight: 600,
  },
});

export const GeoErrorUpateDialogLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const GeoErrorUpateDialogActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

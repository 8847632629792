import { styled, Card, CardContent, IconButton } from '@mui/material';
import { lighten, darken } from '@mui/material/styles';

import { getColorState } from '../utils';

export const classes = {
  mainActionButton:
    'SubscriptionAvailablesRoutesStateAlertCard-MainActionButton',
};

export const SubscriptionAvailableRoutesStateAlertCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'itsHalfway' && prop !== 'isEnding',
})<{ itsHalfway: boolean; isEnding: boolean }>(({ itsHalfway, isEnding }) => {
  const currentColor = getColorState(itsHalfway, isEnding);

  return {
    backgroundColor: lighten(currentColor, 0.9),

    [`& .${classes.mainActionButton}`]: {
      backgroundColor: currentColor,

      '&:hover': {
        backgroundColor: darken(currentColor, 0.2),
      },
    },
  };
});

export const SubscriptionAvailableRoutesStateAlertCardContent = styled(
  CardContent,
)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  gap: theme.spacing(2),
  position: 'relative',
  alignItems: 'center',

  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
}));

export const SubscriptionAvailableRoutesStateAlertCardCloseButton = styled(
  IconButton,
)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}));

import { styled, Button, Select, Stack, Typography } from '@mui/material';

export const ToleranceTimePickerContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0.5, 0.7, 1),
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: theme.shape.borderRadius,
  width: '100%',
}));

export const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10.6),
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
}));

export const IncreaseButton = styled(Button)(({ theme }) => ({
  height: 20,
  width: 10,
  minWidth: 0,
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  color: theme.palette.text.primary,
}));

export const DecreaseButton = styled(Button)(({ theme }) => ({
  height: 20,
  width: 10,
  minWidth: 0,
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderTopWidth: 0,
  color: theme.palette.text.primary,
}));

export const TimeFactorSelect = styled(Select)(({ theme }) => ({
  borderWidth: 0,
  backgroundColor: theme.palette.common.white,
}));

import { useCallback, useMemo } from 'react';

import { Countries } from '~globals/types/enums';
import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';

type SampleTemplatesEntity = 'items' | 'customer';

type SampleTemplatesUrls = Record<SampleTemplatesEntity, string>;

const sampleTemplateBaseUrl = 'https://routixstg.blob.core.windows.net/assets';

export const useSampleTemplates = (): SampleTemplatesUrls => {
  const { user } = useAppSelector(selectAuth);

  const currentCountry = useMemo(
    () => user?.company.countryId ?? Countries.Argentina,
    [user?.company.countryId],
  );

  const getSampleTemplateUrl = useCallback(
    (entitySample: string): string =>
      `${sampleTemplateBaseUrl}/${entitySample}_${currentCountry}.xlsx`,
    [currentCountry],
  );

  const sampleTemplatesUrls = useMemo<SampleTemplatesUrls>(
    () => ({
      items: getSampleTemplateUrl('ItemsSample'),
      customer: getSampleTemplateUrl('CustomerSample'),
    }),
    [getSampleTemplateUrl],
  );

  return sampleTemplatesUrls;
};

import {
  styled,
  List,
  ListItem,
  ListItemButton,
  listItemButtonClasses,
  ListItemIcon,
  ListItemText,
  svgIconClasses,
} from '@mui/material';

const classes = {
  listItemIcon: 'NavigationListItemIcon-root',
  listItemText: 'NavigationListItemText-root',
};

export const NavigationListContainer = styled(List)({
  width: '100%',
});

export const NavigationListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,

  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const NavigationListItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme, isActive }) => ({
  [`&.${listItemButtonClasses.disabled}`]: {
    opacity: 1,

    [`& .${classes.listItemIcon}`]: {
      opacity: theme.palette.action.disabledOpacity,
    },

    [`& .${classes.listItemText}`]: {
      opacity: theme.palette.action.disabledOpacity,
    },
  },

  ...(isActive && {
    [`& .${classes.listItemIcon}`]: {
      color: theme.palette.primary.main,
    },

    [`& .${classes.listItemText}`]: {
      color: theme.palette.primary.main,
    },
  }),
}));

export const NavigationListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 18,
  marginRight: theme.spacing(1),
  color: theme.palette.secondary.main,

  '& svg': {
    fontSize: 18,
    width: '1em',
    height: '1em',

    [`&.${svgIconClasses.root}`]: {
      fontSize: 22,
    },
  },
}));
NavigationListItemIcon.defaultProps = {
  className: classes.listItemIcon,
};

export const NavigationListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.main,
}));
NavigationListItemText.defaultProps = {
  className: classes.listItemText,
};

import { ReactElement, useMemo } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';

import { SubscriptionPlanCardRowInfoProps } from './types';
import { SubscriptionPlanCardRowInfoRoot } from './styles';

const TEXT_COLORS = {
  primary: ['primary', 'main'],
  textPrimary: ['text', 'primary'],
  textSecondary: ['text', 'secondary'],
};

const ICON_COLORS = {
  primary: ['primary', 'main'],
  disabled: ['action', 'disabled'],
};

const SubscriptionPlanCardRowInfo = ({
  description,
  value,
  icon,
  textColor: textColorProps = 'textSecondary',
  iconColor: iconColorProps = 'disabled',
}: SubscriptionPlanCardRowInfoProps): ReactElement => {
  const currentTheme = useTheme();

  const textColor = useMemo(
    () => get(currentTheme, ['palette', ...TEXT_COLORS[textColorProps]]),
    [currentTheme, textColorProps],
  );

  const iconColor = useMemo(
    () => get(currentTheme, ['palette', ...ICON_COLORS[iconColorProps]]),
    [currentTheme, iconColorProps],
  );

  return (
    <SubscriptionPlanCardRowInfoRoot>
      <FontAwesomeIcon icon={icon} color={iconColor} />

      <Stack spacing={0.5} direction="row">
        {description && (
          <Typography variant="inherit" color={textColor}>
            {description}
          </Typography>
        )}

        <Typography variant="inherit" color={textColor} fontWeight="bold">
          {value}
        </Typography>
      </Stack>
    </SubscriptionPlanCardRowInfoRoot>
  );
};

export default SubscriptionPlanCardRowInfo;

import { ReactElement, memo } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { FiberManualRecord as DotIcon } from '@mui/icons-material';

interface SkillListProps {
  skillsList: string[];
}

const SkillList = ({ skillsList }: SkillListProps): ReactElement => (
  <List dense disablePadding>
    {skillsList.map((skill, index) => (
      <ListItem key={`skill-${skill}-${index}`} disableGutters disablePadding>
        <ListItemIcon>
          <DotIcon fontSize="inherit" color="inherit" />
        </ListItemIcon>

        <ListItemText primary={skill} />
      </ListItem>
    ))}
  </List>
);

export default memo(SkillList);

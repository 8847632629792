import { ReactElement, useMemo } from 'react';

import { ItemCollectsList } from '~components/index';

import { useDialogDetailsOrderContextContext } from '../../DialogDetailsOrderContext';

const DialogDetailsOrderOrders = (): ReactElement => {
  const { data } = useDialogDetailsOrderContextContext();

  const itemsCollects = useMemo(() => data?.itemCollects ?? [], [data]);

  return <ItemCollectsList list={itemsCollects} />;
};

export default DialogDetailsOrderOrders;

import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock as LockIcon } from '@fortawesome/free-solid-svg-icons';

import { RoleInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const RoleInfo = ({
  roleDescription,
  isOwner,
  fontSize = 14,
}: RoleInfoProps): ReactElement => {
  const Icon = useMemo(
    () => (isOwner ? <FontAwesomeIcon icon={LockIcon} /> : undefined),
    [isOwner],
  );

  const iconTooltip = useMemo(
    () => (isOwner ? 'El propietario no puede ser eliminado' : undefined),
    [isOwner],
  );

  return (
    <DescriptionWithIconBox
      icon={Icon}
      iconTooltip={iconTooltip}
      description={roleDescription}
      size={fontSize}
      iconPosition="left"
    />
  );
};

export default memo(RoleInfo);

import { FC, createContext, useContext } from 'react';

import {
  ToggleDialogConfirmationOnOpen,
  useToggleDialogConfirmation,
} from '~hooks/useToggleDialogConfirmation';
import { AccessFeature } from '~components/index';
import { Nullable } from '~globals/types';
import { PlanFeaturesTypes } from '~globals/types/enums';

export interface AccessFeatureModalContextValueData {
  validFeature: Nullable<`${PlanFeaturesTypes}`>;
  validateAvailableRoutesState: boolean;
}

interface AccessFeatureModalContextValue {
  open: boolean;
  data: AccessFeatureModalContextValueData;
  handleOpen: ToggleDialogConfirmationOnOpen<AccessFeatureModalContextValueData>;
  handleClose: () => void;
}

const AccessFeatureModalContext = createContext<AccessFeatureModalContextValue>(
  {
    open: false,
    data: { validFeature: null, validateAvailableRoutesState: false },
    handleOpen: () => {},
    handleClose: () => {},
  },
);

export const useAccessFeatureModalContext =
  (): AccessFeatureModalContextValue => useContext(AccessFeatureModalContext);

const AccessFeatureModalProvider: FC = ({ children }) => {
  const [open, data, handleOpen, handleClose] =
    useToggleDialogConfirmation<AccessFeatureModalContextValueData>({
      validFeature: null,
      validateAvailableRoutesState: false,
    });

  return (
    <AccessFeatureModalContext.Provider
      value={{ open, data, handleOpen, handleClose }}
    >
      {children}

      <AccessFeature.Modal
        open={open}
        data={data}
        onClose={() => handleClose()}
      />
    </AccessFeatureModalContext.Provider>
  );
};

export default AccessFeatureModalProvider;

import { SelectOption } from '~globals/types';
import { RouteCalculationModeType } from '~globals/types/enums';

export const routeCalculationModesOptions: SelectOption[] = [
  {
    value: String(RouteCalculationModeType.Faster),
    label: 'Recorrido más rapido',
  },
  {
    value: String(RouteCalculationModeType.Shorter),
    label: 'Recorrido más corto',
  },
];

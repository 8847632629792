import { styled, ButtonBase, Divider, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RouteItemStopState } from './types';

export const RouteItemStopCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.action.active}`,
  minHeight: 72,
  position: 'relative',
}));

export const RouteItemStopCardDivider = styled(Divider)(({ theme }) => ({
  borderRightWidth: 3,
  borderColor: theme.palette.text.primary,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 22,
}));

export const RouteItemStopTitle = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: theme.typography.pxToRem(13),
  fontWeight: theme.typography.fontWeightBold,
}));

export const RouteItemStopText = styled('span')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.action.active,
}));

export const RouteItemStopIconButton = styled(IconButton)(({ theme }) => ({
  padding: 2,
  fontSize: theme.typography.pxToRem(18),
}));

export const RouteItemStopCardIconButtonContainer = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'state',
})<{ state: RouteItemStopState }>(({ theme, state }) => ({
  display: 'flex',
  width: 30,
  height: 30,
  border: '2px solid',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.common.white,
  zIndex: 1,

  ...(state === 'default' && {
    borderColor: theme.palette.action.active,
    color: theme.palette.action.active,
  }),

  ...(state === 'success' && {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  }),

  ...(state === 'error' && {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  }),

  ...(state === 'warning' && {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main,
  }),
}));

export const RouteItemStopCardIconText = styled('span')({
  color: 'inherit',
});

export const RouteItemStopCardInfoContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.25),
}));

export const RouteItemStopCardInfoHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const RouteItemStopCardInfoTimeContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(0.75),
    alignItems: 'center',
    flex: 1,
  }),
);

export const RouteItemStopCardInfoTimeDescriptions = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(0.75),
    alignItems: 'center',
  }),
);

export const RouteItemStopCardInfoTimeDescriptionsText = styled(
  RouteItemStopText,
)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: 57,

  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.action.active}`,
    paddingRight: theme.spacing(0.5),
  },
}));

export const RouteItemStopCardInfoTimeVisited = styled('div', {
  shouldForwardProp: (prop) => prop !== 'state',
})<{ state: RouteItemStopState }>(({ theme, state }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 28,
  padding: '1px 10px',

  ...(state === 'default' && {
    backgroundColor: theme.palette.action.active,
  }),

  ...(state === 'success' && {
    backgroundColor: theme.palette.success.main,
  }),

  ...(state === 'error' && {
    backgroundColor: theme.palette.error.main,
  }),

  ...(state === 'warning' && {
    backgroundColor: theme.palette.warning.main,
  }),
}));

export const RouteItemStopCardInfoTimeVisitedText = styled('span')(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(8),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  }),
);

export const RouteItemStopCardInfoPrincipalContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.25),
  }),
);

export const RouteItemStopCardErrorDistanceWrapper = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.warning.light,
  }),
);

export const RouteItemStopCardItemTaksWrapper = styled(FontAwesomeIcon, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.success.main,

  ...(error && {
    color: theme.palette.error.main,
  }),
}));

import { ReactElement, useCallback, useMemo } from 'react';
import { Typography } from '@mui/material';

import {
  SatisfactionRateInfoProps,
  SatisfactionRateInfoRateVariants,
} from './types';
import {
  SatisfactionRateInfoContainer,
  SatisfactionRateInfoCardContent,
  SatisfactionRateInfoRateContainer,
} from './styles';

const SatisfactionRateInfo = ({
  rate,
  trips,
  reviews,
}: SatisfactionRateInfoProps): ReactElement => {
  const hasTrips = useMemo(() => trips > 0, [trips]);

  const rateVariant = useMemo<SatisfactionRateInfoRateVariants>(() => {
    if (!hasTrips) return 'default';

    if (rate < 3) return 'error';

    if (rate >= 3 && rate < 4) return 'warning';

    return 'success';
  }, [hasTrips, rate]);

  const getValueHasTrips = useCallback(
    (value: number | string) => (hasTrips ? value : 'N/A'),
    [hasTrips],
  );

  return (
    <SatisfactionRateInfoContainer variant="outlined">
      <SatisfactionRateInfoCardContent>
        <Typography variant="body1" component="h4" fontWeight="bold">
          Tasa de satisfacción
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Puntaje basándose en clientes que están satisfechos con el servicio
          general brindado por tu empresa, sobre la base de una puntuación
          máxima de 5.0
        </Typography>
      </SatisfactionRateInfoCardContent>

      <SatisfactionRateInfoRateContainer variant={rateVariant}>
        <Typography variant="h3" fontWeight="bold">
          {getValueHasTrips(rate.toFixed(1))}
        </Typography>

        <Typography variant="caption" fontWeight="bold">
          {`${trips} Destinos`}
        </Typography>
        <Typography variant="caption" fontWeight="bold">
          {`${reviews} Reseñas`}
        </Typography>
      </SatisfactionRateInfoRateContainer>
    </SatisfactionRateInfoContainer>
  );
};

export default SatisfactionRateInfo;

import moment from 'moment';
import momentTz, { LocaleSpecification } from 'moment-timezone';

import 'moment/locale/es';

const installedLocales = moment.locales();
const installedTzLocales = momentTz.locales();

for (const locale of installedLocales) {
  if (!installedTzLocales.includes(locale)) {
    const localeData = moment.localeData(locale);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const localeSpec: LocaleSpecification = (localeData as any)._config;

    momentTz.defineLocale(locale, localeSpec);
  }
}

import { lighten, darken } from '@mui/material/styles';

import {
  styled,
  Box,
  ListItemButton,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

export const classes = {
  expanded: 'RouteErrorsList-expanded',
  expandIcon: 'RouteErrorsList-expandIcon',
};

const getLightenColor = (currentColor: string): string =>
  lighten(currentColor, 0.9);
const getDarkenColor = (currentColor: string): string =>
  darken(currentColor, 0.5);

export const RouteErrorsListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: getLightenColor(theme.palette.warning.light),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${getDarkenColor(theme.palette.warning.light)}`,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const RouteErrorsListListButton = styled(ListItemButton)(
  ({ theme }) => ({
    padding: theme.spacing(1, 2),
    paddingTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: getDarkenColor(theme.palette.warning.light),
    transition: theme.transitions.create(
      ['border-radius', 'background-color'],
      { duration: theme.transitions.duration.shortest },
    ),

    [`& .${classes.expandIcon}`]: {
      fontSize: theme.typography.pxToRem(20),
      color: getDarkenColor(theme.palette.warning.light),
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },

    [`&.${classes.expanded}`]: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

      [`& .${classes.expandIcon}`]: {
        transform: 'rotate(180deg)',
      },
    },
  }),
);

export const RouteErrorsListListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0, 2),

  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },
}));

export const RouteErrorsListListItemText = styled(ListItemText)(
  ({ theme }) => ({
    margin: 0,
    color: getDarkenColor(theme.palette.warning.light),
  }),
);

export const RouteErrorsListListIcon = styled(ListItemIcon)(({ theme }) => ({
  width: theme.spacing(2),
  marginRight: theme.spacing(0.5),
  minWidth: 'auto',
  color: getDarkenColor(theme.palette.warning.light),
}));

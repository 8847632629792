import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

import { Nullable } from '~globals/types';

import { DialogImportFromAssociatedCompanyProps } from './types';
import DialogImportFromAssociatedCompanyProvider, {
  useDialogImportFromAssociatedCompanyContext,
} from './DialogImportFromAssociatedCompanyContext';
import DialogImportFromAssociatedCompanyContent from './DialogImportFromAssociatedCompanyContent';
import {
  DialogImportFromAssociatedCompanyContainer,
  DialogImportFromAssociatedCompanyLoadingContainer,
} from './styles';

const DialogImportFromAssociatedCompany = (): ReactElement => {
  const {
    open,
    onClose,
    currentAssociatedCompanies: { loading: loadingCurrentAssociatedCompanies },
  } = useDialogImportFromAssociatedCompanyContext();

  return (
    <DialogImportFromAssociatedCompanyContainer open={open} onClose={onClose}>
      {loadingCurrentAssociatedCompanies && (
        <DialogImportFromAssociatedCompanyLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogImportFromAssociatedCompanyLoadingContainer>
      )}

      {!loadingCurrentAssociatedCompanies && (
        <DialogImportFromAssociatedCompanyContent />
      )}
    </DialogImportFromAssociatedCompanyContainer>
  );
};

const WrapperDialogImportFromAssociatedCompany = ({
  open,
  onClose,
  onSubmitSuccess,
}: DialogImportFromAssociatedCompanyProps): Nullable<ReactElement> => {
  if (!open) return null;

  return (
    <DialogImportFromAssociatedCompanyProvider
      open={open}
      onClose={onClose}
      onSubmitSuccess={onSubmitSuccess}
    >
      <DialogImportFromAssociatedCompany />
    </DialogImportFromAssociatedCompanyProvider>
  );
};

export default WrapperDialogImportFromAssociatedCompany;

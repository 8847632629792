import {
  styled,
  CardContent,
  Box,
  CircularProgress,
  circularProgressClasses,
  Avatar,
  Button,
} from '@mui/material';
import { CheckCircleOutlineOutlined as CheckCircleIcon } from '@mui/icons-material';

export const ConfigAccountDashboardCardContent = styled(CardContent)(
  ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '70% 1fr',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const ConfigAccountDashboardInfo = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const ConfigAccountDashboardInfoRowItemContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
  }),
);

export const ConfigAccountDashboardInfoRowItemIconContainer = styled(Box)({
  position: 'relative',
});

export const ConfigAccountDashboardInfoRowItemIconImg = styled('img')({
  width: 100,
  height: 60,
});

export const ConfigAccountDashboardInfoRowItemIconCheck = styled(
  CheckCircleIcon,
)({
  position: 'absolute',
  right: -10,
  top: -10,
});

export const ConfigAccountDashboardInfoRowItemDescription = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const ConfigAccountDashboardInfoRowItemDescriptionButtonContainer =
  styled(ConfigAccountDashboardInfoRowItemContainer)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
  }));

export const ConfigAccountDashboardInfoRowItemDescriptionButton = styled(
  Button,
)({ minWidth: 200 });

export const ConfigAccountDashboardStats = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

export const ConfigAccountDashboardStatsProggressContainer = styled(Box)({
  position: 'relative',
  maxWidth: 84,
});

export const ConfigAccountDashboardStatsProggressFull = styled(
  CircularProgress,
)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

export const ConfigAccountDashboardStatsProggressDynamic = styled(
  CircularProgress,
)({
  position: 'absolute',
  top: -2.5,
  left: -2.5,

  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: 'round',
  },
});

export const ConfigAccountDashboardStatsProggressAvatar = styled(Avatar)({
  width: 60,
  height: 60,
  position: 'absolute',
  top: 10,
  left: 10,
});

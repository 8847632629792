import { FC, createContext, useContext, useState, useCallback } from 'react';
import moment from 'moment-timezone';

import { useAppSelector, useUtcOffset } from '~hooks/index';
import {
  useDataTableRequest,
  AsyncDataTableRequestHooksReturn,
} from '~hooks/useDataTableRequest';
import {
  useGenerateRoutesSelectedData,
  UseGenerateRoutesSelectedDataReturn,
} from '~hooks/useGenerateRoutesSelectedData';
import {
  useGenerateRoutesChargeIndicatorsData,
  UseGenerateRoutesChargeIndicatorsDataReturn,
} from '~hooks/useGenerateRoutesChargeIndicatorsData';
import { selectAuth } from '~store/slices/auth';
import { searchVehicles } from '~services/vehicle';
import { VehicleFilter, VehicleSearchItem } from '~services/vehicle/types';
import { ItemExtended } from '~services/item/types';
import { ApiTransactionRoutes } from '~services/apiTransaction/types';
import {
  DataPaginateResponse,
  ErrorResponse,
  PaginationRequestParams,
} from '~globals/types/api';
import { Nullable } from '~globals/types/commons';
import { formatterDate } from '~utils/formatter';

import {
  DialogGenerateRoutesActiveTabs,
  DialogGenerateRoutesType,
} from './types';
import {
  TABLE_KEY_FIELDS,
  getDefaultVehiclesRequestPayload,
  getDisabledVehicle,
  getDisabledOrderByType,
  getDefaultScheduleDate,
} from './utils';

export interface DialogGenerateRoutesContextData {
  ordersInfo: ItemExtended[];
  onClose: () => void;
  type: DialogGenerateRoutesType;
  onSuccessComplete?: (
    apiTransactionId: string,
    currentRoutes: ApiTransactionRoutes[],
  ) => void;
}

export interface DialogGenerateRoutesContextFormData {
  vehiclesTable: AsyncDataTableRequestHooksReturn<
    PaginationRequestParams<VehicleFilter>,
    DataPaginateResponse<VehicleSearchItem[], null, null>,
    ErrorResponse
  >;
  selectedData: UseGenerateRoutesSelectedDataReturn;
  chargeIndicators: UseGenerateRoutesChargeIndicatorsDataReturn;
}

interface DialogGenerateRoutesContextStepperData {
  currentApiTransactionId: {
    value: Nullable<string>;
    setCurrentApiTransactionId: (newApiTransactionId: string) => void;
  };
  currentRoutes: {
    value: ApiTransactionRoutes[];
    setCurrentRoutes: (newRoutes: ApiTransactionRoutes[]) => void;
  };
  isSuccesCompleted: {
    value: boolean;
    setIsSuccesCompleted: (newSuccesCompleted: boolean) => void;
  };
}

interface DialogGenerateRoutesContextValue
  extends DialogGenerateRoutesContextData {
  activeTab: {
    tab: DialogGenerateRoutesActiveTabs;
    setActiveTab: (newActiveTab: DialogGenerateRoutesActiveTabs) => void;
  };
  form: DialogGenerateRoutesContextFormData;
  stepper: DialogGenerateRoutesContextStepperData;
}

const DialogGenerateRoutesContext =
  createContext<DialogGenerateRoutesContextValue>({
    type: 'route',
    activeTab: {
      tab: 'form',
      setActiveTab: () => {},
    },
    form: {
      vehiclesTable: {
        data: [],
        stats: null,
        loading: true,
        error: null,
        reloadRequest: () => {},
        totalSize: 0,
        queryParams: getDefaultVehiclesRequestPayload(
          formatterDate(getDefaultScheduleDate(), {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
            parseToUtc: true,
          }),
        ),
        handleInitialData: () => {},
        handleChangeTable: () => {},
        handleInitialTable: () => {},
      },
      selectedData: {
        vehicles: {
          handleOnSelect: () => {},
          handleOnSelectAll: () => {},
          restarSelectedRows: () => {},
          selectedRows: [],
          selectedRowsIds: [],
          selectRowsProps: {
            mode: 'checkbox',
            clickToSelect: false,
            selected: undefined,
            onSelectAll: () => {},
            onSelect: () => {},
            selectionHeaderRenderer: undefined,
            selectionRenderer: undefined,
            nonSelectable: undefined,
            nonSelectableClasses: undefined,
          },
          setSelectedRowsIds: () => {},
          setSelectedRows: () => {},
        },
        orders: {
          handleOnSelect: () => {},
          handleOnSelectAll: () => {},
          restarSelectedRows: () => {},
          selectedRows: [],
          selectedRowsIds: [],
          selectRowsProps: {
            mode: 'checkbox',
            clickToSelect: false,
            selected: undefined,
            onSelectAll: () => {},
            onSelect: () => {},
            selectionHeaderRenderer: undefined,
            selectionRenderer: undefined,
            nonSelectable: undefined,
            nonSelectableClasses: undefined,
          },
          setSelectedRowsIds: () => {},
          setSelectedRows: () => {},
        },
      },
      chargeIndicators: {
        vehicles: { capacity1: 0, capacity2: 0, maximumVisits: 0 },
        orders: { capacity1: 0, capacity2: 0, numberOfPackages: 0 },
      },
    },
    stepper: {
      currentApiTransactionId: {
        value: null,
        setCurrentApiTransactionId: () => {},
      },
      currentRoutes: {
        value: [],
        setCurrentRoutes: () => {},
      },
      isSuccesCompleted: {
        value: false,
        setIsSuccesCompleted: () => {},
      },
    },
    ordersInfo: [],
    onClose: () => {},
    onSuccessComplete: () => {},
  });

export const useDialogGenerateRoutesContext =
  (): DialogGenerateRoutesContextValue =>
    useContext(DialogGenerateRoutesContext);

const DialogGenerateRoutesProvider: FC<{
  value: DialogGenerateRoutesContextData;
}> = ({
  children,
  value: {
    type,
    ordersInfo,
    onClose,
    onSuccessComplete: onSuccessCompleteProps,
  },
}) => {
  const { user } = useAppSelector(selectAuth);
  const formatterDateUtcOffset = useUtcOffset();

  const [activeTab, setActiveTab] =
    useState<DialogGenerateRoutesActiveTabs>('form');

  const vehiclesTable = useDataTableRequest({
    request: searchVehicles,
    payload: getDefaultVehiclesRequestPayload(
      formatterDateUtcOffset(
        getDefaultScheduleDate(),
        moment.HTML5_FMT.DATETIME_LOCAL_MS,
        true,
      ),
    ),
  });

  const selectedData = useGenerateRoutesSelectedData({
    vehiclesData: vehiclesTable.data,
    ordersData: ordersInfo,
    keysFields: TABLE_KEY_FIELDS,
    getDisabledVehicle,
    getDisabledOrder: (currentOrder) =>
      getDisabledOrderByType(type, currentOrder, user),
  });

  const chargeIndicators = useGenerateRoutesChargeIndicatorsData({
    selectedVehicles: selectedData.vehicles.selectedRows,
    selectedOrders: selectedData.orders.selectedRows,
  });

  const [currentApiTransactionId, setCurrentApiTransactionId] =
    useState<Nullable<string>>(null);

  const [currentRoutes, setCurrentRoutes] = useState<ApiTransactionRoutes[]>(
    [],
  );

  const [isSuccesCompleted, setIsSuccesCompleted] = useState(false);

  const handleSuccessComplete = useCallback(
    (apiTransactionId: string, routes: ApiTransactionRoutes[]) => {
      setIsSuccesCompleted(true);
      setCurrentApiTransactionId(apiTransactionId);
      setCurrentRoutes(routes);

      onSuccessCompleteProps?.(apiTransactionId, routes);
    },
    [onSuccessCompleteProps],
  );

  return (
    <DialogGenerateRoutesContext.Provider
      value={{
        type,
        activeTab: {
          tab: activeTab,
          setActiveTab,
        },
        form: {
          vehiclesTable,
          selectedData,
          chargeIndicators,
        },
        stepper: {
          currentApiTransactionId: {
            value: currentApiTransactionId,
            setCurrentApiTransactionId: setCurrentApiTransactionId,
          },
          currentRoutes: {
            value: currentRoutes,
            setCurrentRoutes,
          },
          isSuccesCompleted: {
            value: isSuccesCompleted,
            setIsSuccesCompleted,
          },
        },
        ordersInfo,
        onClose,
        onSuccessComplete: handleSuccessComplete,
      }}
    >
      {children}
    </DialogGenerateRoutesContext.Provider>
  );
};

export default DialogGenerateRoutesProvider;

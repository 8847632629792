import {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import moment from 'moment';
import { reduce, has, filter, isEqual } from 'lodash';

import { useParseLocationQuery, useFiltersValues } from '~hooks/index';
import { getItemValue, formatterDate } from '~utils/index';
import { Nullable } from '~globals/types';
import { PartnerCompanyItemFilter } from '~services/partnerCompany/types';
import { AuthExtendedCompany } from '~services/auth/types';
import { FiltersValuesReturn } from '~hooks/useFiltersValues';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';

interface ReceivedListQueryParamsFilters {
  routeCode?: string;
  expirationDate?: Nullable<Date>;
  partnerCompany?: string;
}

export interface ReceivedListFilters {
  routeCode: string;
  expirationDate: Nullable<Date>;
  partnerCompany: string;
}

interface ReceivedListParams {
  giverCompanies: {
    list: AuthExtendedCompany[];
    loading: boolean;
    refSelect: RefObject<CustomAutocompleteRef<AuthExtendedCompany, false>>;
  };
}

export interface ReceivedListReturn
  extends FiltersValuesReturn<ReceivedListFilters> {
  parseFiltersRequest: PartnerCompanyItemFilter;
}

type ReceivedListFiltersAppliedType = 'queryParams' | 'filters';

type ReceivedListFiltersApplied = Record<
  keyof ReceivedListFilters,
  ReceivedListFiltersAppliedType
>;

export const initialFilters: ReceivedListFilters = {
  routeCode: '',
  expirationDate: null,
  partnerCompany: '',
};

export const useReceivedListFilters = ({
  giverCompanies: {
    list: giverCompaniesList,
    loading: giverCompaniesLoading,
    refSelect: giverCompaniesRefSelect,
  },
}: ReceivedListParams): ReceivedListReturn => {
  const queryFilters = useParseLocationQuery<ReceivedListQueryParamsFilters>();

  const [filtersApplied, setFilterApplied] =
    useState<ReceivedListFiltersApplied>(() => {
      const builder = reduce(
        initialFilters,
        (acc, _, key) => {
          const keyTyped = key as keyof ReceivedListFilters;

          acc[keyTyped] = has(queryFilters, keyTyped)
            ? 'queryParams'
            : 'filters';

          return acc;
        },
        {} as ReceivedListFiltersApplied,
      );

      return builder;
    });

  const getFilterAppliedByKey = useCallback(
    <Key extends keyof ReceivedListFiltersApplied>(
      key: Key,
    ): ReceivedListFiltersApplied[Key] => getItemValue(filtersApplied, key),
    [filtersApplied],
  );

  const { filters, setFilterByKey, clearFilters } =
    useFiltersValues(initialFilters);

  const setFilterAppliedByKey = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      setFilterByKey(key, value);

      const keyTyped = key as keyof ReceivedListFilters;

      setFilterApplied((prevFiltersApplied) => ({
        ...prevFiltersApplied,
        [keyTyped]: 'filters',
      }));
    },
    [setFilterByKey],
  );

  const handleChangeInputFilterApplied = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target ?? event.currentTarget;

      setFilterAppliedByKey(name, value);
    },
    [setFilterAppliedByKey],
  );

  const clearFiltersApplied = useCallback(() => {
    clearFilters();

    setFilterApplied({
      routeCode: 'filters',
      expirationDate: 'filters',
      partnerCompany: 'filters',
    });
  }, [clearFilters, setFilterApplied]);

  const getValueQueryParams = useCallback(
    <Key extends keyof ReceivedListQueryParamsFilters>(
      key: Key,
    ): ReceivedListQueryParamsFilters[Key] => getItemValue(queryFilters, key),
    [queryFilters],
  );

  const getValueInitialFilters = useCallback(
    <Key extends keyof ReceivedListFilters>(
      key: Key,
    ): ReceivedListFilters[Key] => getItemValue(initialFilters, key),
    [],
  );

  const getValueCurrentFilters = useCallback(
    <Key extends keyof ReceivedListFilters>(
      key: Key,
    ): ReceivedListFilters[Key] => getItemValue(filters, key),
    [filters],
  );

  const setRouteCodeByQueryParam = useCallback(() => {
    const currentRouteCodeQueryParam = getValueQueryParams('routeCode');
    const currentRouteCodeDefault = getValueInitialFilters('routeCode');

    const parseRouteCode =
      currentRouteCodeQueryParam || currentRouteCodeDefault;

    setFilterByKey('routeCode', parseRouteCode);
  }, [getValueQueryParams, getValueInitialFilters, setFilterByKey]);

  useEffect(() => {
    setRouteCodeByQueryParam();
  }, [setRouteCodeByQueryParam]);

  const setDateByQueryParam = useCallback(() => {
    const currentDateQueryParam = getValueQueryParams('expirationDate');
    const currentDateDefault = getValueInitialFilters('expirationDate');

    if (currentDateQueryParam) {
      const valDate = new Date(currentDateQueryParam);

      const parseValDate =
        valDate.toString() !== 'Invalid Date' ? valDate : currentDateDefault;

      setFilterByKey('expirationDate', parseValDate);
    }
  }, [getValueQueryParams, getValueInitialFilters, setFilterByKey]);

  useEffect(() => {
    setDateByQueryParam();
  }, [setDateByQueryParam]);

  const setPartnerCompanyByQueryParams = useCallback(() => {
    const currentPartnerCompanyQueryParam =
      getValueQueryParams('partnerCompany');

    if (currentPartnerCompanyQueryParam && !giverCompaniesLoading) {
      const filteredPartnerCompany = filter(
        giverCompaniesList,
        (currentDriver) => {
          const currentDriverId = currentDriver.id;

          return isEqual(currentPartnerCompanyQueryParam, currentDriverId);
        },
      );

      if (filteredPartnerCompany.length > 0) {
        setFilterByKey('', filteredPartnerCompany[0].id);
        giverCompaniesRefSelect.current?.setValue(filteredPartnerCompany[0]);
      }
    }
  }, [
    getValueQueryParams,
    giverCompaniesLoading,
    giverCompaniesList,
    setFilterByKey,
    giverCompaniesRefSelect,
  ]);

  useEffect(() => {
    setPartnerCompanyByQueryParams();
  }, [setPartnerCompanyByQueryParams]);

  const parseRouteCodeRequest = useCallback(() => {
    const currentTypeDateFilterAppliedRouteCode =
      getFilterAppliedByKey('routeCode');

    const currentRouteCodeQueryParam = getValueQueryParams('routeCode');
    const currentRouteCodeFilter = getValueCurrentFilters('routeCode');

    const currentRouteCode =
      currentTypeDateFilterAppliedRouteCode === 'queryParams'
        ? currentRouteCodeQueryParam
        : currentRouteCodeFilter;

    return currentRouteCode || null;
  }, [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters]);

  const parseDateRequest = useCallback(() => {
    const currentTypeDateFilterAppliedCurrentDate =
      getFilterAppliedByKey('expirationDate');

    const currentDateQueryParam = getValueQueryParams('expirationDate');
    const currentDateFilter = getValueCurrentFilters('expirationDate');

    const currentDate =
      currentTypeDateFilterAppliedCurrentDate === 'queryParams'
        ? currentDateQueryParam
        : currentDateFilter;

    const formatDate = formatterDate(currentDate, {
      format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
    });

    return formatDate || null;
  }, [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters]);

  const parseDriversRequest = useCallback(() => {
    const currentTypeDateFilterAppliedPartnerCompany =
      getFilterAppliedByKey('partnerCompany');

    const currentPartnerCompanyQueryParam =
      getValueQueryParams('partnerCompany');
    const currentPartnerCompanyFilter =
      getValueCurrentFilters('partnerCompany');

    const currentPartnerCompany =
      currentTypeDateFilterAppliedPartnerCompany === 'queryParams'
        ? currentPartnerCompanyQueryParam
        : currentPartnerCompanyFilter;

    return currentPartnerCompany ?? null;
  }, [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters]);

  const parseFiltersRequest = useMemo(
    (): PartnerCompanyItemFilter => ({
      dateFrom: null,
      dateTo: null,
      driverId: null,
      itemStateTypeId: null,
      maxDeliveredDateTime: parseDateRequest(),
      routeCode: parseRouteCodeRequest(),
      giverCompanyId: parseDriversRequest(),
    }),
    [parseDateRequest, parseRouteCodeRequest, parseDriversRequest],
  );

  return {
    filters,
    setFilterByKey: setFilterAppliedByKey,
    handleChangeInputFilter: handleChangeInputFilterApplied,
    clearFilters: clearFiltersApplied,
    parseFiltersRequest,
  };
};

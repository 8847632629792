import { ReactElement, useMemo } from 'react';
import { FormGroup, FormControlLabel } from '@mui/material';

import { useSubscriptionFeatures } from '~hooks/index';

import { AccessFeatureFormControlLabelProps } from './types';
import AccessFeatureNextPlanChip from '../NextPlanChip';

const AccessFeatureFormControlLabel = ({
  validFeature,
  availableToDate,
  showChip = true,
  openAccessFeatureModalOnClickChip = false,
  disabled: disabledProps = false,
  ...rest
}: AccessFeatureFormControlLabelProps): ReactElement => {
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const disabled = useMemo(
    () => !hasAccessToFeature(validFeature, availableToDate) || disabledProps,
    [hasAccessToFeature, validFeature, availableToDate, disabledProps],
  );

  return (
    <FormGroup row sx={{ alignItems: 'center' }}>
      <FormControlLabel {...rest} disabled={disabled} />

      {showChip && (
        <AccessFeatureNextPlanChip
          validFeature={validFeature}
          availableToDate={availableToDate}
          openAccessFeatureModalOnClick={openAccessFeatureModalOnClickChip}
        />
      )}
    </FormGroup>
  );
};

export default AccessFeatureFormControlLabel;

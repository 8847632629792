import { ReactElement } from 'react';
import { useFormikContext } from 'formik';
import {
  Stack,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { AccessFeature } from '~components/index';
import { PlanFeaturesTypes } from '~globals/types/enums';

import { FIELDS_NAME, getInitialValues } from '../../utils';
import { OrderFormSectionContainer } from '../../styles';

const OrderFormSecurityToolsSection = (): ReactElement => {
  const { values, getFieldProps } =
    useFormikContext<ReturnType<typeof getInitialValues>>();

  return (
    <OrderFormSectionContainer
      defaultExpanded={false}
      disableGutters
      elevation={0}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel-order-form-security-tools-content"
        id="panel-order-form-security-tools-header"
      >
        <AccessFeature.Typograghy
          validFeature={PlanFeaturesTypes.MgmSecurityTools}
          component="h6"
          fontWeight="bold"
        >
          Herramientas de seguridad
        </AccessFeature.Typograghy>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <AccessFeature.Alert
            validFeature={PlanFeaturesTypes.MgmSecurityTools}
          />

          <AccessFeature.Hidden
            validFeature={PlanFeaturesTypes.MgmSecurityTools}
            type="notAccess"
          >
            <>
              <FormControl>
                <FormGroup row sx={{ alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps(
                          FIELDS_NAME.DELIVERY_APPROVE_DOCUMENT,
                        )}
                        checked={values[FIELDS_NAME.DELIVERY_APPROVE_DOCUMENT]}
                      />
                    }
                    label="Solicitar nro documento para aprobar la entrega"
                    sx={{ margin: 0 }}
                  />
                </FormGroup>

                <FormHelperText>
                  El chofer deberá validar el DNI del contacto para poder
                  completar la entrega. Si no se ingresa el mismo DNI que figura
                  en esta carga, no se podrá completar la misma
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormGroup row sx={{ alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps(
                          FIELDS_NAME.DELIVERY_APPROVE_SECURITY_CODE,
                        )}
                        checked={
                          values[FIELDS_NAME.DELIVERY_APPROVE_SECURITY_CODE]
                        }
                      />
                    }
                    label="Solicitar código de entrega para aprobar la misma"
                    sx={{ margin: 0 }}
                  />
                </FormGroup>

                <FormHelperText>
                  El chofer deberá validar el código enviado al contacto para
                  poder completar la entrega. Si no se ingresa el código
                  correcto, no se podrá completar la misma.
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormGroup row sx={{ alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Switch
                        {...getFieldProps(
                          FIELDS_NAME.REQUEST_TICKET_VALIDATION,
                        )}
                        checked={values[FIELDS_NAME.REQUEST_TICKET_VALIDATION]}
                      />
                    }
                    label="Escanear etiqueta de paquete para aprobar la entrega"
                    sx={{ margin: 0 }}
                  />
                </FormGroup>

                <FormHelperText>
                  El chofer deberá escanear la etiqueta del pedido para
                  verificar que se está entregando el paquete correcto. Si la
                  etiqueta escaneada no coincide con la programada, no podrá
                  completar la visita.
                </FormHelperText>
              </FormControl>
            </>
          </AccessFeature.Hidden>
        </Stack>
      </AccordionDetails>
    </OrderFormSectionContainer>
  );
};

export default OrderFormSecurityToolsSection;

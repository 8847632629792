import moment, { MomentInput } from 'moment-timezone';
import { floor, chain, round } from 'lodash';

export interface OptionFormatterDate {
  format?: string;
  withUtc?: boolean;
  utcOffset?: string;
  withTimeZone?: boolean;
  parseToUtc?: boolean;
  keepLocalTimeUtc?: boolean;
}

export const formatterDate = (
  date: MomentInput,
  options: OptionFormatterDate = {
    format: moment.HTML5_FMT.DATE,
    withUtc: false,
    withTimeZone: false,
    parseToUtc: false,
    keepLocalTimeUtc: false,
  },
): string => {
  let currentDate = moment(date);

  if (options.withUtc) {
    currentDate = moment.utc(date);
  }

  if (options.parseToUtc) {
    currentDate.utc(options.keepLocalTimeUtc);
  }

  if (options.utcOffset) {
    currentDate.utcOffset(options.utcOffset);
  }

  if (options.withTimeZone) {
    currentDate.tz(moment.tz.guess());
  }

  if (currentDate.isValid()) {
    return currentDate.format(options.format);
  }

  return '';
};

export const formatterTime = (
  time: string,
  format = moment.HTML5_FMT.TIME,
): string => {
  const [hours, minutes, seconds] = time.split(':');

  return formatterDate(
    {
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: Number(seconds),
    },
    {
      format,
      withUtc: false,
      withTimeZone: false,
      parseToUtc: false,
    },
  );
};

export const formatterDistanceToKm = (distanceInMeters: number): string => {
  const distanceKm = floor(distanceInMeters / 1000, 1);

  return `${distanceKm} KM`;
};

export const formmaterSecondToHumanizeTime = (seconds: number): string => {
  const numberInHours = seconds / 3600;
  const hours = Math.trunc(numberInHours);
  const minutes = Math.round((numberInHours % 1) * 60);
  const hourString = hours <= 0 && minutes > 0 ? '' : `${hours} h `;
  const minutesString = minutes > 0 ? `${minutes} m` : '';

  return `${hourString}${minutesString}`;
};

export const formatId = (id: string): string =>
  chain(id).split('-').head().toUpper().value();

export const formatPercentage = (
  percentage: number,
  precisionRound = 2,
): string => `${round(percentage * 100, precisionRound)}%`;

export const formatCurrencyValue = (
  value: number,
  options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'code',
  },
  locale = 'es-AR',
): string => new Intl.NumberFormat(locale, options).format(value);

import { ReactElement } from 'react';
import { Typography } from '@mui/material';

interface PaginationTotalResultsProps {
  from: number;
  to: number;
  size: number;
}

const PaginationTotalResults = ({
  from,
  to,
  size,
}: PaginationTotalResultsProps): ReactElement => (
  <Typography component="span" variant="subtitle2" fontWeight="normal" flex={1}>
    {`Mostrando resultados del ${from} al ${to} de un total de ${size} resultados`}
  </Typography>
);

export default PaginationTotalResults;

import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth';
import { useHubConnection } from '~hooks/useSignalR';

import {
  SignalRConnectedProps,
  SignalRHubConnectionData,
  SignalRMethodsListened,
  SignalRAddMethodHandler,
} from './types';

const SignalRContext = createContext<SignalRHubConnectionData>({
  hubConnection: null,
  hubConnectionState: null,
  error: null,
  methodsListened: {},
  addMethodListened: () => {},
});

export const useSignalRContext = (): SignalRHubConnectionData =>
  useContext(SignalRContext);

const SignalRProvider: FC<SignalRConnectedProps> = ({ url, children }) => {
  const { token } = useAppSelector(selectAuth);

  const connectionData = useHubConnection({
    url,
    connectEnabled: !!token,
    token,
  });

  const [methodsListened, setMethodListened] = useState<SignalRMethodsListened>(
    {},
  );

  const addMethodListened = useCallback<SignalRAddMethodHandler>(
    ({ name, prefix, callback }) => {
      setMethodListened((prevMethosListened) => ({
        ...prevMethosListened,
        [name]: {
          ...prevMethosListened[name],
          [prefix]: callback,
        },
      }));
    },
    [],
  );

  useEffect(() => {
    const events = Object.entries(methodsListened);

    events.forEach(([methodName, methodCallbacks]) => {
      connectionData.hubConnection?.on(methodName, (data) => {
        Object.values(methodCallbacks).forEach((methodCallback) => {
          methodCallback(data);
        });
      });
    });

    return () => {
      events.forEach(([methodName, methodCallbacks]) => {
        connectionData.hubConnection?.off(methodName, (data) => {
          Object.values(methodCallbacks).forEach((methodCallback) => {
            methodCallback(data);
          });
        });
      });
    };
  }, [connectionData.hubConnection, methodsListened]);

  return (
    <SignalRContext.Provider
      value={{ ...connectionData, methodsListened, addMethodListened }}
    >
      {children}
    </SignalRContext.Provider>
  );
};

export default SignalRProvider;

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { updateItemTaskInItem } from '~services/item';
import { ItemTask } from '~services/item/types';

import { useLazyRequest } from './useLazyRequest';

interface UpdateItemTaskParams {
  onSuccess?: (itemTask: ItemTask) => void;
  onError?: () => void;
}

interface UpdateItemTaskReturn {
  loading: boolean;
  handleUpdateItemTask: (itemTask: ItemTask) => void;
}

export const useUpdateItemTask = (
  options: UpdateItemTaskParams,
): UpdateItemTaskReturn => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loading, , executeRequest] = useLazyRequest({
    request: updateItemTaskInItem,
    withPostSuccess: (response) => {
      const currentItenTask = response.data?.data;

      if (currentItenTask) {
        options?.onSuccess?.(currentItenTask);

        enqueueSnackbar('El artículo / tarea fue modificado correctamente', {
          variant: 'success',
        });
      }
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo modificar el artículo / tarea, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const handleUpdateItemTask = useCallback(
    async (itemTask: ItemTask) => {
      await executeRequest(itemTask);
    },
    [executeRequest],
  );

  return { loading, handleUpdateItemTask };
};

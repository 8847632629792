import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

export const DialogImportOrdersFromIntegrationContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    minHeight: 400,
  },
});

export const DialogImportOrdersFromIntegrationLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogImportOrdersFromIntegrationContentContainer = styled(
  DialogContent,
)({
  display: 'flex',
  flex: 1,
});

export const DialogImportOrdersFromIntegrationLabelImg = styled('img', {
  shouldForwardProp: (prop) =>
    prop !== 'size' && prop !== 'rounded' && prop !== 'eleveation',
})<{ size?: 'small' | 'medium'; rounded?: boolean; elevation?: number }>(
  ({ size = 'medium', rounded = false, elevation = 0, theme }) => ({
    width: 150,
    boxShadow: theme.shadows[elevation],

    ...(rounded && {
      borderRadius: '50%',
    }),

    ...(size === 'small' && {
      width: 90,
    }),
  }),
);

export const DialogImportOrdersFromIntegrationTabPanel = styled(TabPanel)({
  display: 'flex',
  padding: 0,
  flex: 1,
});

export const DialogImportOrdersFromIntegrationActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

import * as Yup from 'yup';

import { Nullable } from '~globals/types';
import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
} as const;

export const initialValues = {
  [FIELDS_NAME.DATE_FROM]: null as Nullable<Date>,
  [FIELDS_NAME.DATE_TO]: null as Nullable<Date>,
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.DATE_FROM]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.DATE_TO]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .required(VALIDATIONS_ERROR.REQUIRED),
});

import { ReactElement, useCallback, useEffect } from 'react';
import {
  Typography,
  Stack,
  TextField,
  DialogActions,
  Alert,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '~hooks/index';
import { sendAdminTestWaNotification } from '~services/admin/companySetting';
import { hasError } from '~utils/index';

import { DialogSendTestWANotificationProps } from './types';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import {
  DialogSendTestNotificationContainer,
  DialogSendTestNotificationTitle,
  DialogSendTestNotificationTitleCloseIcon,
  DialogSendTestNotificationContent,
} from './styles';

const DialogSendTestWaNotification = ({
  type,
  open,
  onClose,
  onSendSuccess,
}: DialogSendTestWANotificationProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingSendTestNotification, , executeSendTestNotification] =
    useLazyRequest({
      request: sendAdminTestWaNotification,
      withPostSuccess: () => {
        enqueueSnackbar(
          'Se ha enviado la notificación de prueba correctamente',
          { variant: 'success' },
        );

        onSendSuccess?.();

        onClose();
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'No se pudo enviar la notificación de prueba intente nuevamente',
          { variant: 'error' },
        );
      },
    });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await executeSendTestNotification({
        phone: values[FIELDS_NAME.PHONE],
        type,
      });

      setSubmitting(false);
    },
    [executeSendTestNotification, type],
  );

  const {
    resetForm,
    submitForm,
    isSubmitting,
    dirty,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogSendTestNotificationContainer open={open} maxWidth="md">
      <DialogSendTestNotificationTitle>
        <Typography component="h2" variant="h6">
          Enviar notificación de prueba
        </Typography>

        <DialogSendTestNotificationTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogSendTestNotificationTitleCloseIcon>
      </DialogSendTestNotificationTitle>

      <DialogSendTestNotificationContent dividers>
        <Stack spacing={2}>
          <TextField
            label="Para"
            placeholder="Código area sin 0 y número sin 15"
            fullWidth
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.PHONE)}
            {...getFieldProps(FIELDS_NAME.PHONE)}
            InputLabelProps={{ shrink: true }}
          />

          <Typography variant="body2">
            Se enviará un mensaje por whatsapp al número de teléfono
            especificado con información de ejemplo, para que pueda visualizar
            la información que verán sus clientes.
          </Typography>

          <Alert severity="info" variant="standard" icon={false}>
            Para que sus usuarios reciban las notificaciones, los pedidos deben
            contar con el teléfono móvil del contacto cargado.
          </Alert>
        </Stack>
      </DialogSendTestNotificationContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loadingSendTestNotification || isSubmitting}
          disabled={!dirty}
        >
          Enviar
        </LoadingButton>
      </DialogActions>
    </DialogSendTestNotificationContainer>
  );
};

export default DialogSendTestWaNotification;

import { styled } from '@mui/material';

export const SubscriptionPaymentModalMPButtonContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled?: boolean }>(({ disabled }) => ({
  ...(disabled && {
    pointerEvents: 'none',
    cursor: 'default',
    opacity: 0.38,
  }),
}));

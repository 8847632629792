import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf as LeafIcon } from '@fortawesome/free-solid-svg-icons';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { CO2EmssionInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const CO2EmssionInfo = ({
  value,
  fontSize = 14,
  fontWeight,
  withTooltip = false,
  useCustomIcon = false,
}: CO2EmssionInfoProps): ReactElement => {
  const Icon = useMemo(
    () =>
      useCustomIcon ? (
        <img
          src={toAbsoluteUrl('/icons/icon_co2.svg')}
          alt="CO2 Emission Icon"
        />
      ) : (
        <FontAwesomeIcon icon={LeafIcon} />
      ),
    [useCustomIcon],
  );

  const text = useMemo(() => `${value} KG`, [value]);

  const tooltipMessage = useMemo(
    () => (withTooltip ? 'Emision estimada de CO2 (Kg)' : undefined),
    [withTooltip],
  );

  return (
    <DescriptionWithIconBox
      icon={Icon}
      description={text}
      size={fontSize}
      iconTooltip={tooltipMessage}
      fontWeight={fontWeight}
    />
  );
};

export default memo(CO2EmssionInfo);

import { ReactElement, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import { DissatisfiedReviewsCardProps } from './types';
import {
  DissatisfiedReviewsCardContainer,
  DissatisfiedReviewsCardContent,
  DissatisfiedReviewsCardBadge,
  DissatisfiedReviewsCardIconImg,
} from './styles';

const DissatisfiedReviewsCard = ({
  value,
  title,
  icon,
  description,
}: DissatisfiedReviewsCardProps): ReactElement => {
  const badgeInfo = useMemo<{
    label: string;
    color: 'error' | 'warning' | 'success';
  }>(() => {
    if (value <= 15) {
      return { label: 'Óptimo', color: 'success' };
    }

    if (value > 15 && value <= 35) {
      return { label: 'A mejorar', color: 'warning' };
    }

    return { label: 'En riesgo', color: 'error' };
  }, [value]);

  return (
    <DissatisfiedReviewsCardContainer variant="outlined">
      <DissatisfiedReviewsCardContent>
        <DissatisfiedReviewsCardBadge
          variant="outlined"
          size="small"
          {...badgeInfo}
        />

        <Stack direction="row" spacing={1} alignItems="center" marginY={1}>
          <Stack flex={1}>
            <Typography variant="body1" component="h4" fontWeight="bold">
              {title}
            </Typography>

            <Typography variant="h3" fontWeight="bold" color="text.secondary">
              {`${value}%`}
            </Typography>

            <Typography variant="caption" fontWeight="bold">
              USUARIOS DISCONFORMES
            </Typography>
          </Stack>

          <DissatisfiedReviewsCardIconImg {...icon} />
        </Stack>

        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </DissatisfiedReviewsCardContent>
    </DissatisfiedReviewsCardContainer>
  );
};

export default DissatisfiedReviewsCard;

import { styled, Badge, badgeClasses } from '@mui/material';

import AccessFeatureNextPlanChip from '../NextPlanChip';

import { AccessFeatureBadgeStyleProps } from './types';

export const AccessFeatureBadgeRoot = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<AccessFeatureBadgeStyleProps>(({ theme, fullWidth }) => ({
  ...(fullWidth && {
    flex: 'auto',
    width: '100%',
  }),

  [`& .${badgeClasses.badge}:not(.${badgeClasses.invisible})`]: {
    transform: `scale(1) translate(${theme.spacing(2.25)}, -50%)`,
  },
}));

export const AccessFeatureBadgeNextPlanChip = styled(AccessFeatureNextPlanChip)(
  { margin: 0 },
);

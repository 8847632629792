import { selectAuth } from '~store/slices/auth';
import { PartnerCompanyTypes } from '~globals/types/enums';
import {
  isCarrierPartnerCompany,
  isGiverPartnerCompany,
} from '~utils/partnerCompany';

import { useAppSelector } from './useAppSelector';

export interface TypePartnerCompanyReturn {
  isCarrier: boolean;
  isGiver: boolean;
}

export const useTypePartnerCompany = (): TypePartnerCompanyReturn => {
  const { user } = useAppSelector(selectAuth);

  return {
    isCarrier: isCarrierPartnerCompany(
      user?.company.companyTypeId as PartnerCompanyTypes,
    ),
    isGiver: isGiverPartnerCompany(
      user?.company.companyTypeId as PartnerCompanyTypes,
    ),
  };
};

import { ReactElement, useMemo } from 'react';
import { Collapse } from '@mui/material';
import { WarningRounded as WarningRoundedIcon } from '@mui/icons-material';

import { pluralize } from '~utils/commons';

import { GeoErrorAlertAlertProps } from './types';
import {
  GeoErrorAlertAlertContainer,
  GeoErrorAlertAlertButtonAction,
} from './styles';

const GeoErrorAlert = ({
  type,
  errorsCount,
  onEdit,
  sx,
}: GeoErrorAlertAlertProps): ReactElement => {
  const expanded = useMemo(() => errorsCount > 0, [errorsCount]);

  const countText = useMemo(() => {
    let singularPrefix = 'pedido';
    let pluralPrefix = 'pedidos';

    if (type === 'customer') {
      singularPrefix = 'contacto frecuente';
      pluralPrefix = 'contactos frecuentes';
    }

    return pluralize({
      singular: singularPrefix,
      plural: pluralPrefix,
      count: errorsCount,
      includeCount: true,
    });
  }, [errorsCount, type]);

  const descriptionText = useMemo(
    () =>
      pluralize({
        singular: 'tiene problema de geolocalización.',
        plural: 'tienen problemas de geolocalización.',
        count: errorsCount,
      }),
    [errorsCount],
  );

  const actionText = useMemo(
    () =>
      pluralize({
        singular: 'Haga click para corregirlo.',
        plural: 'Haga click para corregirlos.',
        count: errorsCount,
      }),
    [errorsCount],
  );

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit sx={sx}>
      <GeoErrorAlertAlertContainer
        severity="warning"
        variant="standard"
        icon={<WarningRoundedIcon fontSize="inherit" />}
      >
        <b>{countText}</b> {descriptionText}{' '}
        <GeoErrorAlertAlertButtonAction
          variant="text"
          color="inherit"
          size="small"
          onClick={onEdit}
        >
          {actionText}
        </GeoErrorAlertAlertButtonAction>
      </GeoErrorAlertAlertContainer>
    </Collapse>
  );
};

export default GeoErrorAlert;

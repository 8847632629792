import { useRef, useState, useCallback } from 'react';

import { useSignalRContext } from '~contexts/SignalRContext';
import { useClientOnMethod } from '~hooks/useSignalR';
import { Nullable } from '~globals/types';
import { METHODS_NAME } from '~constants/signalR';
import { IntegrationProvidersTypes } from '~globals/types/enums';

interface ImporterOrdersProgressListenerParams {
  runListenerUpdates: boolean;
  onProgress?: () => void;
  onCompleted?: () => void;
}

export type ImporterOrdersProgressListenerStatus = Nullable<
  'inProgress' | 'completed'
>;

export interface ImporterOrdersProgressNotification {
  progress: number;
  step: number;
  total: number;
  integrationType?: IntegrationProvidersTypes;
  storeName?: string;
}

interface ImporterOrdersProgressListenerReturn {
  status: ImporterOrdersProgressListenerStatus;
  updateImporterStatus: (
    newStatus: ImporterOrdersProgressListenerStatus,
  ) => void;
  data: ImporterOrdersProgressNotification;
  reset: () => void;
}

const initialData: ImporterOrdersProgressNotification = {
  progress: 0,
  step: 0,
  total: 0,
  integrationType: undefined,
  storeName: undefined,
};

export const useImporterOrdersProgressListener = ({
  runListenerUpdates = false,
  onProgress,
  onCompleted,
}: ImporterOrdersProgressListenerParams): ImporterOrdersProgressListenerReturn => {
  const { hubConnection } = useSignalRContext();

  const runListenerRef = useRef(runListenerUpdates);
  runListenerRef.current = runListenerUpdates;

  const statusRef = useRef<ImporterOrdersProgressListenerStatus>(null);
  const updateImporterStatus = useCallback(
    (newStatus: ImporterOrdersProgressListenerStatus) => {
      statusRef.current = newStatus;
    },
    [],
  );

  const [data, setData] =
    useState<ImporterOrdersProgressNotification>(initialData);

  const updateImporterProgressInfo = useCallback(
    (notification: string) => {
      const infoNotification = JSON.parse(
        notification,
      ) as ImporterOrdersProgressNotification;

      if (runListenerRef.current) {
        updateImporterStatus('inProgress');
        onProgress?.();

        setData(infoNotification);

        const isCompleted = infoNotification.step >= infoNotification.total;

        if (isCompleted) {
          updateImporterStatus('completed');
          onCompleted?.();
        }
      }
    },
    [updateImporterStatus, onProgress, onCompleted],
  );

  useClientOnMethod(
    hubConnection,
    METHODS_NAME.PROGRESS_IMPORT,
    updateImporterProgressInfo,
  );

  const reset = useCallback(() => {
    runListenerRef.current = false;
    updateImporterStatus(null);
    setData(initialData);
  }, [updateImporterStatus]);

  return {
    status: statusRef.current,
    updateImporterStatus,
    data,
    reset,
  };
};

import { useMemo, useEffect } from 'react';
import {
  IntersectionObserverHookArgs,
  IntersectionObserverHookRefCallback,
  IntersectionObserverHookRootRefCallback,
  useTrackVisibility,
} from 'react-intersection-observer-hook';

import { useMountedState } from './useMountedState';

const DEFAULT_DELAY = 100;

interface InfiniteScrollHookParams
  extends Pick<IntersectionObserverHookArgs, 'rootMargin'> {
  loading: boolean;
  hasNextPage: boolean;
  onLoadMore: VoidFunction;
  disabled?: boolean;
  delay?: number;
}

type InfiniteScrollHookReturn = [
  IntersectionObserverHookRefCallback,
  { rootRef: IntersectionObserverHookRootRefCallback },
];

export const useInfiniteScroll = ({
  loading,
  hasNextPage,
  onLoadMore,
  rootMargin,
  disabled,
  delay = DEFAULT_DELAY,
}: InfiniteScrollHookParams): InfiniteScrollHookReturn => {
  const [ref, { rootRef, isVisible }] = useTrackVisibility({ rootMargin });

  const isMounted = useMountedState();

  const shouldLoadMore = useMemo(
    () => !disabled && !loading && isVisible && hasNextPage,
    [disabled, hasNextPage, isVisible, loading],
  );

  useEffect(() => {
    if (shouldLoadMore && isMounted()) {
      const timer = setTimeout(() => {
        onLoadMore();
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [shouldLoadMore, isMounted, onLoadMore, delay]);

  return [ref, { rootRef }];
};

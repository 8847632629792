import { ReactElement, memo } from 'react';

import { useOrderPendingStateInfo } from '~hooks/index';

import { OrderPendingStateChipProps } from './types';
import { OrderPendingStateChipRoot } from './styles';

const OrderPendingStateChip = ({
  carrierCompanyId,
  collectItemStateTypeId,
  size = 'medium',
}: OrderPendingStateChipProps): ReactElement => {
  const { description, color } = useOrderPendingStateInfo({
    carrierCompanyId,
    collectItemStateTypeId,
  });

  return (
    <OrderPendingStateChipRoot
      variant="filled"
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(OrderPendingStateChip);

import API from '~config/api';
import {
  ApiPromise,
  DataResultsResponse,
  DataResponse,
  ErrorResponse,
} from '~globals/types';

import { AuthSubscription } from '../auth/types';
import { PlanExtended, ActivePlan } from './types';

const PLANS_ENPOINTS = {
  getPlansDetails: '/api/v1/Plans/Plan',
  getPlanSubscription: '/api/v1/Plans/Suscription',
  getActivePlans: '/api/v1/Plans/ActivePlans',
};

export const getPlansDetails = (): ApiPromise<
  DataResultsResponse<PlanExtended[]>,
  ErrorResponse
> =>
  API.get<DataResultsResponse<PlanExtended[]>, ErrorResponse>(
    PLANS_ENPOINTS.getPlansDetails,
  );

export const getPlanSubscription = (): ApiPromise<
  DataResponse<AuthSubscription>,
  ErrorResponse
> =>
  API.get<DataResponse<AuthSubscription>, ErrorResponse>(
    PLANS_ENPOINTS.getPlanSubscription,
  );

export const getActivePlans = (): ApiPromise<
  DataResultsResponse<ActivePlan[]>,
  ErrorResponse
> =>
  API.get<DataResultsResponse<ActivePlan[]>, ErrorResponse>(
    PLANS_ENPOINTS.getActivePlans,
  );

import { ReactElement, useMemo, memo, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import PaymentForm from '~components/PaymentForm';
import { PaymentFormData } from '~components/PaymentForm/types';

import { createMPSubscription } from '~services/subscription/payment';

import { useSubscriptionPaymentModalContext } from '../../../SubscriptionPaymentModalContext';
import { useLazyRequest } from '~app/hooks';

const SubscriptionPaymentModalFormMercadoPago = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    paymentType,
    providersPlansId,
    billingData: { data: billingData },
    processPayment: { setIsProcess: setIsProcessPayment },
    contractPlanIdTest,
  } = useSubscriptionPaymentModalContext();

  const contractPlanId = useMemo(
    () =>
      contractPlanIdTest ?? get(providersPlansId, ['mercadopago', paymentType]),
    [contractPlanIdTest, paymentType, providersPlansId],
  );

  const [, loadingCreateSubscription, , executeCreateSubscription] =
    useLazyRequest({
      request: createMPSubscription,
      withPostSuccess: () => {
        setIsProcessPayment(true);
      },
      withPostFailure: (err) => {
        const message =
          err.data?.data?.message ?? 'Ha ocurrido un error, intente nuevamente';

        enqueueSnackbar(message, { variant: 'error' });
      },
    });

  const onSubmit = useCallback(
    async ({ cardIssuer, cardType, ...paymentData }: PaymentFormData) => {
      if (contractPlanId && billingData) {
        await executeCreateSubscription({
          contractPlanId,
          billingData: {
            ...billingData,
            ...paymentData,
            cardTypeId: cardType,
            cardCredit: cardIssuer,
          },
        });
      }
    },
    [billingData, contractPlanId, executeCreateSubscription],
  );

  return (
    <PaymentForm onSubmit={onSubmit} loading={loadingCreateSubscription} />
  );
};

export default memo(SubscriptionPaymentModalFormMercadoPago);

import {
  Home as HomeIcon,
  Vehicle as RoutesIcon,
  Order as OrdersIcon,
  Report as ReportIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  Received as ReceivedIcon,
} from '~components/Icons';
import { Subscription } from '~components/index';
import { PATHS } from '~constants/index';
import { Roles, PartnerCompanyTypes } from '~globals/types/enums';

import { LateralMenuItem } from './types';

export const MENU_PRINCIPAL: LateralMenuItem[] = [
  {
    data: {
      title: 'Escritorio',
      path: PATHS.HOME.BASE,
      icon: HomeIcon,
    },
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Colecta',
      path: PATHS.RECEIVED.BASE,
      icon: ReceivedIcon,
      chips: {
        activeVersion: '1.16.0',
      },
    },
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier],
      validateCrossCompanyEnabled: true,
    },
  },
  {
    data: {
      title: 'Pedidos',
      path: PATHS.ORDERS.BASE,
      icon: OrdersIcon,
    },
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Rutas',
      path: PATHS.ROUTES.BASE,
      icon: (props) => (
        <Subscription.AvailableRoutesState.Badge {...props}>
          <RoutesIcon />
        </Subscription.AvailableRoutesState.Badge>
      ),
    },
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier],
      validateCrossCompanyEnabled: false,
    },
    subItem: () => <Subscription.AvailableRoutesState.Info />,
  },
];

export const MENU_SECONDARIES: LateralMenuItem[] = [
  {
    data: {
      title: 'Reportes',
      path: PATHS.REPORTS.BASE,
      icon: ReportIcon,
    },
    permissions: {
      roles: [Roles.Admin],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Configuración',
      path: PATHS.CONFIG.BASE,
      icon: SettingsIcon,
    },
    permissions: {
      roles: [Roles.Admin],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
  {
    data: {
      title: 'Ayuda',
      path: 'http://help.routix.io/',
      target: '_blank',
      icon: HelpIcon,
    },
    permissions: {
      roles: [Roles.Admin, Roles.Operator, Roles.Reader],
      companyType: [PartnerCompanyTypes.Carrier, PartnerCompanyTypes.Giver],
      validateCrossCompanyEnabled: false,
    },
  },
];

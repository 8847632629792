import { ReactElement, useMemo, memo } from 'react';

import { getIntegrationProviderData } from '~utils/integrations';

import { IntegrationTypeInfoProps } from './types';
import {
  IntegrationTypeInfoContainer,
  IntegrationTypeInfoLogo,
  IntegrationTypeInfoTypography,
} from './styles';

const IntegrationTypeInfo = ({
  integrationType,
  fontSize = 14,
  fontWeight = 'bold',
}: IntegrationTypeInfoProps): ReactElement => {
  const providerInfo = useMemo(
    () => getIntegrationProviderData(integrationType),
    [integrationType],
  );

  return (
    <IntegrationTypeInfoContainer size={fontSize}>
      <IntegrationTypeInfoLogo
        size={fontSize}
        src={providerInfo?.logoSmall.url}
        alt={providerInfo?.logoSmall.alt}
      />

      <IntegrationTypeInfoTypography variant="inherit" fontWeight={fontWeight}>
        {providerInfo?.name}
      </IntegrationTypeInfoTypography>
    </IntegrationTypeInfoContainer>
  );
};

export default memo(IntegrationTypeInfo);

import { styled, Alert, alertClasses, darken, Button } from '@mui/material';

export const GeoErrorAlertAlertContainer = styled(Alert)(
  ({ theme, severity }) => ({
    ...theme.typography.caption,
    padding: theme.spacing(1),
    alignItems: 'center',

    ...(severity && {
      border: `1px solid ${darken(theme.palette[severity].light, 0.6)}`,

      [`& .${alertClasses.icon}`]: {
        padding: 0,
        fontSize: theme.typography.pxToRem(20),
        marginRight: theme.spacing(1),
        color: darken(theme.palette[severity].light, 0.6),
      },
    }),

    [`& .${alertClasses.message}`]: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
  }),
);

export const GeoErrorAlertAlertButtonAction = styled(Button)(({ theme }) => ({
  fontSize: 'inherit',
  fontWeight: theme.typography.fontWeightBold,
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

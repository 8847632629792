import { ChartProps } from 'react-chartjs-2';

type OrdersReportChartProps = ChartProps<'line', number[], string>;

export const getPropsOrdersReportsChart = (
  previousYearVisitCompleted: number[],
  currentYearVisitCompleted: number[],
): OrdersReportChartProps => {
  const labels = [
    'ENE',
    'FEB',
    'MAR',
    'ABR',
    'MAY',
    'JUN',
    'JUL',
    'AGO',
    'SEP',
    'OCT',
    'NOV',
    'DIC',
  ];

  const data: OrdersReportChartProps['data'] = {
    labels,
    datasets: [
      {
        label: 'Año actual',
        data: currentYearVisitCompleted,
        backgroundColor: '#5618B7',
        borderColor: '#5618B7',
      },
      {
        label: 'Año anterior',
        data: previousYearVisitCompleted,
        backgroundColor: '#000',
        borderColor: '#000',
      },
    ],
  };

  const options: OrdersReportChartProps['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: { stepSize: 10 },
        beginAtZero: true,
      },
    },
  };

  return { data, options, type: 'line' };
};

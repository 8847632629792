import { some } from 'lodash';
import moment from 'moment';

import {
  ItemExtended,
  ItemCreateExtended,
  ItemsImporter,
  ItemTask,
} from '~services/item/types';
import { ApiTransactionRouteItem } from '~services/apiTransaction/types';
import {
  ItemImportStateTypes,
  PriorityStateTypes,
  ItemTypes,
  RouteItemStateType,
  ItemTaskStatusType,
  SecurityDeliveryLevel,
} from '~globals/types/enums';
import { CustomerBase } from '~services/customer/types';
import { pluralize } from '~utils/commons';
import { Nullable } from '~globals/types';
import { DEFAULT_UUIID_EMPTY } from '~constants/commons';

export const validateImportErrorsInOrders = (orders: ItemExtended[]): boolean =>
  some(
    orders,
    (order) => order.itemImportStateTypeId === ItemImportStateTypes.Error,
  );

export const getDataByCustomer = (
  customer: CustomerBase,
): ItemCreateExtended => ({
  accessCode: customer.accessCode,
  address: customer.address,
  addressNotes: customer.addressNotes,
  amount: 1,
  amountPaid: null,
  bookClient: false,
  cashOnDeliveryAmount: 0,
  code: customer.code,
  contactEmail: customer.contactEmail,
  contactIdentification: customer.contactIdentification,
  contactMobile: customer.contactMobile,
  contactName: customer.contactName,
  contactPhone: customer.contactPhone,
  customField1: customer.customField1,
  customField2: customer.customField2,
  customField3: customer.customField3,
  deliveryNotes: null,
  invoice: '',
  itemId: DEFAULT_UUIID_EMPTY,
  itemStateTypeId: RouteItemStateType.New,
  itemTypeId: ItemTypes.Discharge,
  latitude: customer.latitude,
  longitude: customer.longitude,
  maxDeliveryDateTime: null,
  order: null,
  priorityTypeId: PriorityStateTypes.Normal,
  ratio: null,
  referenceId: '',
  requestPaymentOnDestiny: false,
  sellerMail: '',
  serviceDuration: 600,
  shipment: '',
  tags: '',
  timeWindowAllDay: customer.timeWindowAllDay,
  timeWindowFrom1: customer.timeWindowFrom1,
  timeWindowFrom2: customer.timeWindowFrom2,
  timeWindowTo1: customer.timeWindowTo1,
  timeWindowTo2: customer.timeWindowTo2,
  title: customer.legalName,
  trackingCode: DEFAULT_UUIID_EMPTY,
  unit1: 1,
  unit2: 0,
  validateContactIdentification: customer.validateContactIdentification,
  skillsNeeded: '',
  itemTasks: [],
  itemCollects: [],
  securityDeliveryLevel:
    customer.securityDeliveryLevel ?? SecurityDeliveryLevel.Low,
  numberOfPackages: 1,
  requestTicketValidation: false,
  routeItemStateTypeId: RouteItemStateType.New,
  zipCode: customer.zipCode,
  collectCompanyId: null,
});

const getTimeNumber = (dateStr: Nullable<string>): number =>
  dateStr ? new Date(dateStr).getTime() : Number.MAX_SAFE_INTEGER;

export const sortOrderPendingByExpirationDate = (
  orders: ItemExtended[],
): ItemExtended[] =>
  orders.sort(
    (a, b) =>
      getTimeNumber(a.maxDeliveryDateTime) -
      getTimeNumber(b.maxDeliveryDateTime),
  );

export const getImporterOrderFromCSVAlertDescription = ({
  importedRows,
  totalRows,
  errorRows,
}: ItemsImporter): string | string[] => {
  let text: string | string[] = `Se ${pluralize({
    singular: 'importó',
    plural: 'importaron',
    count: importedRows,
  })} ${pluralize({
    singular: 'pedido',
    count: importedRows,
    includeCount: true,
  })} correctamente`;

  if (errorRows >= 1) {
    const sendOrdersText = `Se ${pluralize({
      singular: 'envió',
      plural: 'enviaron',
      count: totalRows,
    })} ${pluralize({
      singular: 'pedido',
      count: totalRows,
      includeCount: true,
    })}`;

    const successOrdersImported = `${importedRows} se ${pluralize({
      singular: 'importó',
      plural: 'importaron',
      count: importedRows,
    })} correctamente`;

    const errorsOrdersImported = `${errorRows} se ${pluralize({
      singular: 'importó',
      plural: 'importaron',
      count: errorRows,
    })} con errores`;

    text = [sendOrdersText, successOrdersImported, errorsOrdersImported];
  }

  return text;
};

export const convertApiTransactionRouteItemInItemExtended = (
  routeItem: ApiTransactionRouteItem,
  apiTransactionId: Nullable<string>,
): ItemExtended => ({
  address: routeItem.item?.address ?? '',
  apiTransactionId,
  contactMobile: routeItem.item?.contactMobile ?? '',
  contactPhone: routeItem.item?.contactPhone ?? '',
  customField1: routeItem.item?.customField1 ?? '',
  customField2: routeItem.item?.customField2 ?? '',
  customField3: routeItem.item?.customField3 ?? '',
  creationDateTime: routeItem.item?.creationDateTime ?? '',
  estimatedArrivalDateTime: routeItem.estimatedArrivalDateTime,
  hasFeedback: routeItem.hasFeedback,
  itemId: routeItem.itemId,
  itemImportStateType: {
    itemImportStateTypeId: ItemImportStateTypes.Successfully,
    description: 'Satisfactorio',
  },
  itemImportStateTypeId: ItemImportStateTypes.Successfully,
  itemTypeId: routeItem.item?.itemTypeId ?? ItemTypes.Charge,
  maxDeliveryDateTime: routeItem.item?.maxDeliveryDateTime ?? null,
  realArrivalDateTime: routeItem.realArrivalDateTime,
  referenceId: routeItem.item?.referenceId ?? '',
  routeCode: '',
  routeId: routeItem.routeId,
  routeItemId: routeItem.routeItemId,
  routeItemStateTypeId: routeItem.routeItemStateTypeId,
  skillsNeeded: routeItem.item?.skillsNeeded ?? null,
  tags: routeItem.item?.tags ?? '',
  timeWindowAllDay: routeItem.item?.timeWindowAllDay ?? true,
  timeWindowFrom1: routeItem.item?.timeWindowFrom1 ?? null,
  timeWindowFrom2: routeItem.item?.timeWindowFrom2 ?? null,
  timeWindowTo1: routeItem.item?.timeWindowTo1 ?? null,
  timeWindowTo2: routeItem.item?.timeWindowTo2 ?? null,
  title: routeItem.item?.title ?? '',
  trackingCode: routeItem.item?.trackingCode ?? '',
  unit1: routeItem.item?.unit1 ?? 1,
  unit2: routeItem.item?.unit2 ?? 0,
  amount: routeItem.item?.amount ?? 0,
  carrierCompanyName: null,
  partnerItemCarrierStatusType: null,
  partnerItemCarrierStatusTypeId: null,
  partnerItemGiverStatusType: null,
  partnerItemGiverStatusTypeId: null,
  quantityArticles: null,
  quantityCompletedArticles: null,
  quantityWithErrorsArticles: null,
  securityDeliveryLevel: null,
  giverCompanyName: null,
  isCollected: false,
  originAddress: null,
  carrierCompanyId: null,
  collectItemStateTypeId: null,
  giverCompanyId: null,
  readOnly: false,
  companyId: (routeItem.item?.company.id ?? routeItem.warehouse?.id) as string,
  numberOfPackages: routeItem.item?.numberOfPackages ?? 1,
  zipCode: routeItem.item?.zipCode ?? '',
  canDelete: true,
  canModify: true,
});

export const isDelayedOrder = (
  estimatedArrivalDateTime: Nullable<string>,
): boolean => {
  if (estimatedArrivalDateTime) {
    return moment(estimatedArrivalDateTime).isBefore(moment(), 'minutes');
  }

  return false;
};

export const parseItemTasksToArray = (itemTasks?: ItemTask[]): string[] =>
  itemTasks ? itemTasks.map((itemTask) => itemTask.description) : [];

export const parseItemTasksToListArray = (itemTasks: string[]): ItemTask[] =>
  itemTasks.map((itemTask) => ({
    description: itemTask,
    itemId: null,
    itemTaskId: null,
    itemTaskStatusType: null,
    itemTaskStatusTypeId: ItemTaskStatusType.Pending,
    notes: null,
  }));

import { styled, Chip } from '@mui/material';

export const SubscriptionTransactionStatusChipRoot = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{
  ownerState: { color: string };
}>(({ ownerState, theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: ownerState.color,
  minWidth: 80,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'uppercase',
}));

import { ReactElement, useMemo, useCallback } from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import mapboxgl from 'mapbox-gl';

import { useDownloadEvidencesItemPDF } from '~hooks/index';
import {
  validateRouteItemDistanceFromAddress,
  isRouteStopOrderFinalized,
} from '~utils/route';
import {
  formatterDistanceToKm,
  formmaterSecondToHumanizeTime,
} from '~utils/formatter';
import { RouteItemStateType, PlanFeaturesTypes } from '~globals/types/enums';
import { AccessFeature } from '~components/index';

import GalleryLightBox from '../../../GalleryLightBox';
import { GalleryLightBoxImg } from '../../../GalleryLightBox/types';
import {
  DialogFeedbackStopContentRowItem,
  DialogFeedbackStopSignImg,
} from '../../styles';
import { useDialogFeedbackStopContext } from '../../DialogFeedbackStopContext';

const DialogFeebackStopFeedback = (): ReactElement => {
  const { data, routeItemStateTypeId, mapRef, onClose } =
    useDialogFeedbackStopContext();

  const isStateFinalizedSuccess = useMemo(
    () => routeItemStateTypeId === RouteItemStateType.FinalizedSuccess,
    [routeItemStateTypeId],
  );

  const routeItem = useMemo(() => data?.routeItem, [data]);

  const realArrivalTime = useMemo(() => {
    let output = 'N/A';

    if (data?.minutesInSite) {
      output = formmaterSecondToHumanizeTime(data.minutesInSite * 60);
    }

    return output;
  }, [data]);

  const distanceFromDriver = useMemo(() => {
    const distanceVal = data?.distanceFromAddress as number;
    const maxDistance = 500;

    const distanceText = validateRouteItemDistanceFromAddress(
      'lte',
      distanceVal,
      maxDistance,
    )
      ? `< ${maxDistance} metros`
      : formatterDistanceToKm(distanceVal);

    return `${distanceText} de punto de entrega`;
  }, [data]);

  const feedbackFiles = useMemo(
    () =>
      data?.routeItemFeedbackFiles.map(
        ({ fileId, file }): GalleryLightBoxImg => ({
          id: fileId,
          url: file.url,
          alt: file.description,
        }),
      ),
    [data],
  );

  const feedbackDocumentations = useMemo(
    () =>
      data?.routeItemFeedbackDocs.map(
        ({ fileId, file }): GalleryLightBoxImg => ({
          id: fileId,
          url: file.url,
          alt: file.description,
        }),
      ),
    [data],
  );

  const goToMap = useCallback(() => {
    if (mapRef) {
      const currentItem =
        routeItem?.[!!routeItem?.warehouseId ? 'warehouse' : 'item'];

      const currentItemCoords = new mapboxgl.LngLat(
        currentItem?.longitude as number,
        currentItem?.latitude as number,
      );

      const stopCoords = new mapboxgl.LngLat(
        data?.longitude as number,
        data?.latitude as number,
      );

      mapRef.fitBounds(
        [stopCoords, currentItemCoords],
        { padding: 50 },
        { feedbackStopCoords: stopCoords },
      );
      onClose();
    }
  }, [mapRef, onClose, routeItem, data]);

  const {
    loading: loadingDowloadPDF,
    onDownloadPDF: handleDowloadEvidencesOrderPDF,
  } = useDownloadEvidencesItemPDF();

  const trackingCode = useMemo(
    () => data?.routeItem?.item?.trackingCode,
    [data],
  );

  return (
    <Stack spacing={2}>
      <DialogFeedbackStopContentRowItem>
        <Typography variant="body2" fontWeight="bold">
          Tiempo real de espera
        </Typography>

        <Typography variant="body2">{realArrivalTime}</Typography>
      </DialogFeedbackStopContentRowItem>

      {routeItem?.item?.referenceId && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Nro de pedido
          </Typography>

          <Typography variant="body2">{routeItem.item.referenceId}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      <DialogFeedbackStopContentRowItem>
        <Typography variant="body2" fontWeight="bold">
          Ubicación de chofer respecto a parada
        </Typography>

        <Typography variant="body2" gutterBottom>
          {distanceFromDriver}
        </Typography>

        {mapRef && (
          <Button
            variant="outlined"
            color="primary"
            sx={{ maxWidth: 200 }}
            onClick={goToMap}
          >
            Ver Mapa
          </Button>
        )}
      </DialogFeedbackStopContentRowItem>

      {data?.rejectedReason && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Motivo de rechazo
          </Typography>

          <Typography variant="body2">{data.rejectedReason}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      {feedbackFiles && !!feedbackFiles.length && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Evidencia de visita
          </Typography>

          <GalleryLightBox images={feedbackFiles} maxWidth={500} />
        </DialogFeedbackStopContentRowItem>
      )}

      {feedbackDocumentations && !!feedbackDocumentations.length && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Documentación
          </Typography>

          <GalleryLightBox images={feedbackDocumentations} maxWidth={500} />
        </DialogFeedbackStopContentRowItem>
      )}

      {isStateFinalizedSuccess && data?.receptorName && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Recibió
          </Typography>

          <Typography variant="body2">{data.receptorName}</Typography>

          {data?.receptorId && (
            <Typography variant="body2">{`Documento: ${data.receptorId}`}</Typography>
          )}
        </DialogFeedbackStopContentRowItem>
      )}

      {data?.signFile && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Firma
          </Typography>

          <DialogFeedbackStopSignImg
            src={data.signFile.url}
            alt={data.signFile.description}
          />
        </DialogFeedbackStopContentRowItem>
      )}

      {routeItem?.item?.requestPaymentOnDestiny && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Monto cobrado
          </Typography>

          <Typography variant="body2">{data?.amountPaid ?? 'N/A'}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      {data?.notes && (
        <DialogFeedbackStopContentRowItem>
          <Typography variant="body2" fontWeight="bold">
            Notas
          </Typography>

          <Typography variant="body2">{data.notes}</Typography>
        </DialogFeedbackStopContentRowItem>
      )}

      {}

      {isRouteStopOrderFinalized(routeItemStateTypeId as RouteItemStateType) &&
        trackingCode && (
          <DialogFeedbackStopContentRowItem>
            <Stack spacing={1}>
              <AccessFeature.Typograghy
                validFeature={PlanFeaturesTypes.TrackingEPOD}
                variant="body2"
                fontWeight="bold"
              >
                Comprobante de visita
              </AccessFeature.Typograghy>

              <AccessFeature.Alert
                validFeature={PlanFeaturesTypes.TrackingEPOD}
              />

              <AccessFeature.Hidden
                validFeature={PlanFeaturesTypes.TrackingEPOD}
                type="notAccess"
              >
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  onClick={() => handleDowloadEvidencesOrderPDF(trackingCode)}
                  loading={loadingDowloadPDF}
                  sx={{ maxWidth: 300 }}
                >
                  Descargar comprobante de visita
                </LoadingButton>
              </AccessFeature.Hidden>
            </Stack>
          </DialogFeedbackStopContentRowItem>
        )}
    </Stack>
  );
};

export default DialogFeebackStopFeedback;

import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/types';
import { Driver, DriverCreateBody } from './types';

export const ADMIN_DRIVER_ENPOINTS = {
  sendAdminDriverInvitation: '/api/v1/Admin/Driver/SendInvitation',
  createDriver: '/api/v1/Admin/User',
};

export const sendAdminDriverInvitation = (
  email: string,
): ApiPromise<DataResponse<null>, ErrorResponse> =>
  API.post<DataResponse<null>, ErrorResponse>(
    ADMIN_DRIVER_ENPOINTS.sendAdminDriverInvitation,
    { email },
  );

export const createDriver = (
  body: DriverCreateBody,
): ApiPromise<DataResponse<Driver>, ErrorResponse> =>
  API.post<DataResponse<Driver>, ErrorResponse>(
    ADMIN_DRIVER_ENPOINTS.createDriver,
    body,
  );

import { styled, keyframes } from '@mui/material';
import { lighten } from '@mui/material/styles';

const pulseKeyFrames = keyframes`
  0% {
    transform: scale(0, 0); 
    opacity: 0.0;
  }

  50% {
    opacity: 1.0;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
`;

export const PulsingDotContainer = styled('div')({
  position: 'relative',
  zIndex: 20,
});

export const PulsingDotCircle = styled('div')(({ theme }) => ({
  border: `3px solid ${theme.palette.primary.main}`,
  width: 20,
  height: 20,
  backgroundColor: theme.palette.common.white,
  borderRadius: '50%',
  position: 'relative',

  '&::before': {
    content: '""',
    border: `5px solid ${lighten(theme.palette.primary.main, 0.4)}`,
    borderRadius: '50%',
    height: 30,
    width: 30,
    position: 'absolute',
    left: -8,
    top: -8,
    opacity: 0.0,
    animation: `${pulseKeyFrames} 1s ease-out infinite`,
  },
}));

import { ReactElement, useState, useMemo } from 'react';
import { CircularProgress } from '@mui/material';

import { useRequest } from '~hooks/index';
import { getItemsWithGeoErrors } from '~services/item';
import { ItemGeoExtended } from '~services/item/types';
import { getCustomersWithGeoErrors } from '~services/customer';
import { CustomerGeoExtended } from '~services/customer/types';
import { DataPaginateResponse } from '~globals/types';

import { GeoErrorUpateDialogProps, GeoErrorUpateDialogData } from './types';
import GeoErrorUpateDialogContent from './Content';
import {
  GeoErrorUpateDialogContainer,
  GeoErrorUpateDialogLoadingContainer,
} from './styles';

const GeoErrorUpateDialog = ({
  type,
  open,
  onClose,
  onSuccessSubmit,
}: GeoErrorUpateDialogProps): ReactElement => {
  const [data, setData] = useState<GeoErrorUpateDialogData[]>([]);

  const [, loadingGetItemsWithErrors] = useRequest({
    request: getItemsWithGeoErrors,
    initialLoading: type === 'item',
    isValidToRequest: type === 'item',
    payload: null,
    withPostSuccess: (res) => {
      const response = res.data as DataPaginateResponse<ItemGeoExtended[]>;

      setData(response.data.results);
    },
  });

  const [, loadingGetCustomersWithErrors] = useRequest({
    request: getCustomersWithGeoErrors,
    initialLoading: type === 'customer',
    isValidToRequest: type === 'customer',
    payload: null,
    withPostSuccess: (res) => {
      const response = res.data as DataPaginateResponse<CustomerGeoExtended[]>;

      setData(response.data.results);
    },
  });

  const loadingGetDataWithErrors = useMemo(
    () => loadingGetItemsWithErrors || loadingGetCustomersWithErrors,
    [loadingGetItemsWithErrors, loadingGetCustomersWithErrors],
  );

  return (
    <GeoErrorUpateDialogContainer open={open}>
      {loadingGetDataWithErrors && (
        <GeoErrorUpateDialogLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </GeoErrorUpateDialogLoadingContainer>
      )}

      {!loadingGetDataWithErrors && (
        <GeoErrorUpateDialogContent
          onClose={onClose}
          data={data}
          type={type}
          onSuccessSubmit={onSuccessSubmit}
        />
      )}
    </GeoErrorUpateDialogContainer>
  );
};

export default GeoErrorUpateDialog;

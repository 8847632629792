import { ReactElement, useCallback, ChangeEvent } from 'react';
import { SizePerPageRendererOptions } from 'react-bootstrap-table-next';

import { SelectSizes, SelectSizeOptionItem } from './styles';

const PaginationSizePerPageList = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}: SizePerPageRendererOptions): ReactElement => {
  const handleChangeSizePerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault();
      const newSizePerPage = Number(event.target.value);
      onSizePerPageChange(newSizePerPage);
    },
    [onSizePerPageChange],
  );

  return (
    <SelectSizes
      select
      value={currSizePerPage}
      onChange={handleChangeSizePerPage}
      color="secondary"
    >
      {options.map(({ page, text }) => (
        <SelectSizeOptionItem
          key={`pagination-size-per-page-${page}`}
          value={page}
        >
          {text}
        </SelectSizeOptionItem>
      ))}
    </SelectSizes>
  );
};

export default PaginationSizePerPageList;

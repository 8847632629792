import { ReactElement, memo } from 'react';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useItemCollectStatusInfo } from '~hooks/index';
import { Nullable } from '~globals/types';
import { DescriptionWithIconBoxTooltipIcon } from '~components/DescriptionWithIconBox';

import { ItemCollectStatusChipProps } from './types';

const ItemCollectStatusChip = ({
  statusId,
  size = 16,
}: ItemCollectStatusChipProps): Nullable<ReactElement> => {
  const { icon, color, tooltipMessage } = useItemCollectStatusInfo(statusId);

  if (!icon || !color || !tooltipMessage) return null;

  return (
    <Tooltip title={tooltipMessage} arrow>
      <DescriptionWithIconBoxTooltipIcon>
        <FontAwesomeIcon icon={icon} color={color} fontSize={size} />
      </DescriptionWithIconBoxTooltipIcon>
    </Tooltip>
  );
};

export default memo(ItemCollectStatusChip);

import { useState, useEffect } from 'react';

interface HeighLayout {
  header: number;
}

export const useHeightLayout = (): HeighLayout => {
  const [heightHeader, setHeightHeader] = useState<number>(0);

  useEffect(() => {
    const headerElement = document.querySelector('#header-app');

    if (headerElement) {
      const headerStyles = window.getComputedStyle(headerElement);
      const margin =
        parseFloat(headerStyles.marginTop) +
        parseFloat(headerStyles.marginBottom);

      const heightResult = headerElement.scrollHeight + margin;
      setHeightHeader(heightResult);
    }
  }, []);

  return { header: heightHeader };
};

import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';
import { Nullable } from '~globals/types';
import { IntegrationProvidersTypes } from '~globals/types/enums';

export const FIELDS_NAME = {
  DESCRIPTION: 'description',
  URL: 'url',
  EMAIL: 'email',
} as const;

export const initialValues = {
  [FIELDS_NAME.DESCRIPTION]: '',
  [FIELDS_NAME.URL]: '',
  [FIELDS_NAME.EMAIL]: '',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValidationSchema = (
  integrationType: Nullable<IntegrationProvidersTypes>,
) =>
  Yup.object().shape({
    [FIELDS_NAME.DESCRIPTION]:
      integrationType === IntegrationProvidersTypes.WooCommerce
        ? Yup.string().required(VALIDATIONS_ERROR.REQUIRED)
        : Yup.string().notRequired(),
    [FIELDS_NAME.URL]:
      integrationType === IntegrationProvidersTypes.WooCommerce
        ? Yup.string()
            .url(VALIDATIONS_ERROR.URL)
            .required(VALIDATIONS_ERROR.REQUIRED)
        : Yup.string().notRequired(),
  });

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateEmail = (email: string) => {
  const schema = Yup.string()
    .email(VALIDATIONS_ERROR.EMAIL)
    .required(VALIDATIONS_ERROR.REQUIRED);

  const promise = schema.validate(email, { abortEarly: false });

  return new Promise((resolve, reject) => {
    promise.then(
      () => {
        resolve('');
      },
      (err) => {
        if (err.name === 'ValidationError') {
          resolve(err.message);
        } else {
          reject(err);
        }
      },
    );
  });
};

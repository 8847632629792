import ReactDOM from 'react-dom';

import App from '~app/index';

import * as serviceWorker from './serviceWorker';

import 'mapbox-gl/dist/mapbox-gl.css';
// eslint-disable-next-line import/extensions
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { ReactElement } from 'react';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { PasswordVisibilityAdornmentProps } from './types';

const PasswordVisibilityAdornment = ({
  showPassword,
  onClick,
  'aria-label': ariaLabel,
  color = 'secondary',
  position,
  ...rest
}: PasswordVisibilityAdornmentProps): ReactElement => (
  <InputAdornment {...rest} position={position}>
    <IconButton color={color} aria-label={ariaLabel} onClick={onClick}>
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);

export default PasswordVisibilityAdornment;

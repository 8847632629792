import { ReactElement, useState, useMemo, useCallback } from 'react';
import Lightbox from 'react-image-lightbox';

import { useToggle } from '~hooks/index';

import { GalleryLightBoxProps } from './types';
import {
  GalleryLightBoxRoot,
  GalleryLightBoxGalleryScroller,
  GalleryLightBoxGalleryListContainer,
  GalleryLightBoxGalleryImgItem,
} from './styles';

import 'react-image-lightbox/style.css';

const GalleryLightBox = ({
  images,
  maxWidth,
}: GalleryLightBoxProps): ReactElement => {
  const imagesLength = useMemo(() => images.length, [images]);
  const hasManyImage = useMemo(() => imagesLength > 1, [imagesLength]);

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [isOpenLightBox, toogleOpenLightBox] = useToggle();

  const currentImageIndexes = useMemo(
    () => ({
      main: currentImageIndex,
      prev: (currentImageIndex + imagesLength - 1) % imagesLength,
      next: (currentImageIndex + 1) % imagesLength,
    }),
    [currentImageIndex, imagesLength],
  );

  const getCurrentImgByIndex = useCallback(
    (index: number) => images[index].url ?? '',
    [images],
  );

  const handleMove = useCallback(
    (type: 'prev' | 'next') => {
      const currentIndex = currentImageIndexes[type];
      setCurrentImageIndex(currentIndex);
    },
    [currentImageIndexes],
  );

  return (
    <GalleryLightBoxRoot maxWidth={maxWidth}>
      <GalleryLightBoxGalleryScroller>
        <GalleryLightBoxGalleryListContainer>
          {images.map(({ id, alt, url }) => (
            <GalleryLightBoxGalleryImgItem
              key={`gallery-lightbox-gallery-item-${id}`}
              src={url}
              alt={alt}
              onClick={() => toogleOpenLightBox(true)}
            />
          ))}
        </GalleryLightBoxGalleryListContainer>
      </GalleryLightBoxGalleryScroller>

      {isOpenLightBox && (
        <Lightbox
          mainSrc={getCurrentImgByIndex(currentImageIndexes.main)}
          prevSrc={
            hasManyImage
              ? getCurrentImgByIndex(currentImageIndexes.prev)
              : undefined
          }
          nextSrc={
            hasManyImage
              ? getCurrentImgByIndex(currentImageIndexes.next)
              : undefined
          }
          onCloseRequest={() => toogleOpenLightBox(false)}
          onMovePrevRequest={() => handleMove('prev')}
          onMoveNextRequest={() => handleMove('next')}
          reactModalStyle={{ overlay: { zIndex: 4000 } }}
        />
      )}
    </GalleryLightBoxRoot>
  );
};

export default GalleryLightBox;

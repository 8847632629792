import { styled, Menu, menuClasses, Button } from '@mui/material';

export const CollectActionsMenuContainerMenu = styled(Menu)({
  [`& .${menuClasses.paper}`]: {
    border: '1px solid #d3d4d5',
  },
});

export const CollectActionsMenuReceivedMainButton = styled(Button)(
  ({ theme }) => ({
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      inset: 0,
      zIndex: -1,
      borderRadius: 'inherit',
    },
  }),
);

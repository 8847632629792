import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

export const DialogOptimizeRouteContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
  },
});

export const DialogOptimizeRouteContent = styled(DialogContent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
);

export const DialogOptimizeRouteActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogOptimizeRouteInfoListItemListIcon = styled(ListItemIcon)(
  ({ theme }) => ({
    marginRight: theme.spacing(0.5),
    minWidth: 'auto',
    fontSize: theme.typography.pxToRem(10),
    marginTop: 5,
    color: 'inherit',
  }),
);

export const DialogOptimizeRouteInfoListItemText = styled(ListItemText)({
  marginBottom: 0,
  marginTop: 0,
});

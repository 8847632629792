import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { isNull, chain } from 'lodash';

import { useRequest } from '~hooks/index';
import { useSelectedRows, SelectedRowsReturn } from '~hooks/useSelectedRows';
import { getActivePartnerCompanies } from '~services/partnerCompany';
import { AuthExtendedCompany } from '~services/auth/types';
import { getWarehouses } from '~services/warehouse';
import { WarehouseExtended } from '~services/warehouse/types';
import { ItemExtended } from '~services/item/types';

import {
  DialogAssignCarrierCompanysProps,
  DialogAssignCarrierCompanysProviderProps,
} from './types';

export type DialogAssignCarrierCompanyContextPanelsId =
  | 'notCarriers'
  | 'assignCarrier'
  | 'collect'
  | 'confirmation';

export interface DialogAssignCarrierCompanyContextSelectData<L> {
  loading: boolean;
  list: L[];
}

interface DialogAssignCarrierCompanyContextValue
  extends DialogAssignCarrierCompanysProps {
  activeTab: DialogAssignCarrierCompanyContextPanelsId;
  setActiveTab: (
    newActiveTab: DialogAssignCarrierCompanyContextPanelsId,
  ) => void;
  activesPartnerCompanies: DialogAssignCarrierCompanyContextSelectData<AuthExtendedCompany>;
  warehouses: DialogAssignCarrierCompanyContextSelectData<WarehouseExtended>;
  selectedOrders: SelectedRowsReturn<ItemExtended>;
  orderInfo: ItemExtended[];
  loadingOrderInfo: boolean;
}

const keyFieldOrder = 'itemId';

const DialogAssignCarrierCompanyContext =
  createContext<DialogAssignCarrierCompanyContextValue>({
    activeTab: 'notCarriers',
    setActiveTab: () => {},
    activesPartnerCompanies: { loading: true, list: [] },
    warehouses: { loading: true, list: [] },
    open: false,
    onClose: () => {},
    orderIds: [],
    orderInfo: [],
    loadingOrderInfo: true,
    onSuccessAssign: () => {},
    selectedOrders: {
      handleOnSelect: () => {},
      handleOnSelectAll: () => {},
      restarSelectedRows: () => {},
      selectedRows: [],
      selectedRowsIds: [],
      selectRowsProps: {
        mode: 'checkbox',
        clickToSelect: false,
        selected: undefined,
        onSelectAll: () => {},
        onSelect: () => {},
        selectionHeaderRenderer: undefined,
        selectionRenderer: undefined,
        nonSelectable: undefined,
        nonSelectableClasses: undefined,
      },
      setSelectedRowsIds: () => {},
      setSelectedRows: () => {},
    },
  });

export const useDialogAssignCarrierCompanyContext =
  (): DialogAssignCarrierCompanyContextValue =>
    useContext(DialogAssignCarrierCompanyContext);

const DialogAssignCarrierCompanyProvider: FC<
  DialogAssignCarrierCompanysProviderProps
> = ({ children, orderIds, orderInfo, loadingOrderInfo, ...restProps }) => {
  const [activeTab, setActiveTab] =
    useState<DialogAssignCarrierCompanyContextPanelsId>('notCarriers');

  const [activesPartnerCompanies, setActivesPartnerCompanies] = useState<
    AuthExtendedCompany[]
  >([]);
  const [, loadingActivesPartnerCompanies] = useRequest({
    request: getActivePartnerCompanies,
    payload: null,
    withPostSuccess: (response) => {
      const currentActivePartnerCompanies = response.data?.data
        .result as AuthExtendedCompany[];

      setActivesPartnerCompanies(currentActivePartnerCompanies);

      if (currentActivePartnerCompanies.length > 0)
        setActiveTab('assignCarrier');
    },
  });

  const [warehouses, setWarehouses] = useState<WarehouseExtended[]>([]);
  const [, loadingWarehouses] = useRequest({
    request: getWarehouses,
    payload: null,
    withPostSuccess: (response) => {
      const currentWarehouses = response.data?.data
        .results as WarehouseExtended[];

      setWarehouses(currentWarehouses);
    },
  });

  const getDisabledOrder = useCallback(
    (row: ItemExtended) => !isNull(row.carrierCompanyName),
    [],
  );

  const selectablesOrders = useMemo(
    () =>
      chain(orderInfo)
        .filter((row) => !getDisabledOrder(row))
        .value(),
    [orderInfo, getDisabledOrder],
  );

  const selectablesOrdersIds = useMemo(
    () => chain(selectablesOrders).map(keyFieldOrder).value(),
    [selectablesOrders],
  );

  const nonSelectablesOrdersIds = useMemo(
    () => chain(orderInfo).filter(getDisabledOrder).map(keyFieldOrder).value(),
    [getDisabledOrder, orderInfo],
  );

  const selectedOrders = useSelectedRows<ItemExtended>(
    keyFieldOrder,
    selectablesOrders,
    selectablesOrdersIds,
    nonSelectablesOrdersIds,
  );

  useEffect(() => {
    if (!loadingOrderInfo) {
      selectedOrders.setSelectedRows(selectablesOrders);
      selectedOrders.setSelectedRowsIds(selectablesOrdersIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOrderInfo, selectablesOrders, selectablesOrdersIds]);

  return (
    <DialogAssignCarrierCompanyContext.Provider
      value={{
        activeTab,
        setActiveTab,
        activesPartnerCompanies: {
          loading: loadingActivesPartnerCompanies,
          list: activesPartnerCompanies,
        },
        warehouses: {
          loading: loadingWarehouses,
          list: warehouses,
        },
        selectedOrders,
        orderIds,
        orderInfo,
        loadingOrderInfo,
        ...restProps,
      }}
    >
      {children}
    </DialogAssignCarrierCompanyContext.Provider>
  );
};

export default DialogAssignCarrierCompanyProvider;

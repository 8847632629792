import {
  styled,
  TextField,
  outlinedInputClasses,
  inputAdornmentClasses,
  selectClasses,
} from '@mui/material';

export const PaymentFormGroupField = styled(TextField)({
  [`& .${outlinedInputClasses.input}`]: {
    paddingLeft: 0,
  },

  [`& .${inputAdornmentClasses.root}`]: {
    marginTop: 15,
    minWidth: 80,

    [`& .${selectClasses.select}`]: {
      paddingTop: 0,
      paddingRight: '12px !important',
      paddingBottom: 0,
      paddingLeft: 0,
      borderRight: '2px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 0,
    },

    [`& .${outlinedInputClasses.notchedOutline}`]: {
      border: 'none',
    },
  },
});

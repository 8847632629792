import queryString from 'query-string';

import { useScript } from './useScript';

const gtagId = 'G-0CHE0VJRVY';

const scriptInitializedId = 'gtag-initialized';
const scriptInitializedSrc = queryString.stringifyUrl(
  {
    url: 'https://www.googletagmanager.com/gtag/js',
    query: {
      id: gtagId,
    },
  },
  { encode: false },
);

const scriptCallbackId = 'gtag-callback';
const scriptCalbackInnerHtml = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "${gtagId}");
`;

export const useLoadGoogleTagManager = (): void => {
  useScript({
    id: scriptInitializedId,
    position: document.querySelector('head'),
    src: scriptInitializedSrc,
    async: true,
    validateOnlyProduction: true,
  });

  useScript({
    id: scriptCallbackId,
    position: document.querySelector('head'),
    innerHTML: scriptCalbackInnerHtml,
    validateOnlyProduction: true,
  });
};

import { ReactElement, useState, useCallback, useEffect, useMemo } from 'react';
import { DialogTitle, DialogContentText, Button, Link } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { FileUploadV2, ImporterProgress } from '~components/index';
import {
  useLazyRequest,
  useImporterCustomersProgressListener,
  useSampleTemplates,
} from '~hooks/index';
import { importCustomersFromCSV } from '~services/customer';
import { CustomerImporter } from '~services/customer/types';
import { getImporterCustomerFromCSVAlertDescription } from '~utils/customer';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { DialogImportCustomersProps } from './types';
import {
  DialogImportCustomersFromCSVContainer,
  DialogImportCustomersFromCSVContent,
  DialogImportCustomersFromCSVActions,
} from './styles';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const defaultImportedCustomersData: CustomerImporter = {
  errorRows: 0,
  importedRows: 0,
  message: '',
  totalRows: 0,
};

const DialogImportCustomers = ({
  open,
  onClose,
  onSubmitSuccess,
}: DialogImportCustomersProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const { customer: dowloadTemplateUrl } = useSampleTemplates();

  const [runImporterProgressListener, setImporterProgressListener] =
    useState(false);

  const {
    status,
    updateImporterStatus,
    data: { progress, step, total },
    reset: resetImporter,
  } = useImporterCustomersProgressListener({
    runListenerUpdates: open && runImporterProgressListener,
    onCompleted: () => setImporterProgressListener(false),
  });

  const loading = useMemo(() => status === 'inProgress', [status]);

  const isImporting = useMemo(
    () => loading || status === 'completed' || runImporterProgressListener,
    [loading, status, runImporterProgressListener],
  );

  const [isCompletedRequest, setIsCompletedRequest] = useState(false);
  const [importedCustomersData, setImportedCustomersData] =
    useState<CustomerImporter>(defaultImportedCustomersData);

  const [, , , executeImportItems] = useLazyRequest({
    request: importCustomersFromCSV,
    withPostSuccess: (response) => {
      const responseImporter = response.data?.data as CustomerImporter;

      setImportedCustomersData(responseImporter);
      setIsCompletedRequest(true);
      onSubmitSuccess();
    },
    withPostFailure: () => {
      setIsCompletedRequest(true);
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const isCompletedProcessImporter = useMemo(
    () => status === 'completed' && isCompletedRequest,
    [status, isCompletedRequest],
  );

  const importerProgressTitle = useMemo(
    () =>
      isCompletedProcessImporter
        ? 'Archivo importado'
        : 'Importando desde archivo',
    [isCompletedProcessImporter],
  );

  const importerProgressDescription = useMemo(
    () =>
      isCompletedProcessImporter
        ? getImporterCustomerFromCSVAlertDescription(importedCustomersData)
        : `Importando ${step} de ${total}`,
    [isCompletedProcessImporter, importedCustomersData, step, total],
  );

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      setImporterProgressListener(true);
      updateImporterStatus('inProgress');
      setIsCompletedRequest(false);
      await executeImportItems(values[FIELDS_NAME.FILES]);

      setSubmitting(false);
    },
    [updateImporterStatus, executeImportItems],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { resetForm, submitForm, isSubmitting, dirty, isValid, values } =
    formikBag;

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        resetForm();
        resetImporter();
        setImporterProgressListener(false);
        setIsCompletedRequest(false);
        setImportedCustomersData(defaultImportedCustomersData);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const disabledSubmit = useMemo(
    () => !dirty || !isValid || !values[FIELDS_NAME.FILES],
    [dirty, isValid, values],
  );

  return (
    <FormikProvider value={formikBag}>
      <DialogImportCustomersFromCSVContainer
        open={open}
        onClose={onClose}
        maxWidth="sm"
      >
        <DialogTitle>Importar Contactos Frecuentes</DialogTitle>

        <DialogImportCustomersFromCSVContent dividers>
          {!isImporting && (
            <DialogContentText color="text.primary">
              Importar contactos frecuentes desde un archivo de forma masiva
            </DialogContentText>
          )}

          <FileUploadV2
            name={FIELDS_NAME.FILES}
            labelImg={{
              src: toAbsoluteUrl('/images/import_file.png'),
              alt: 'Import File',
            }}
            accept=".csv, .xls, .xlsx"
            disabled={isImporting}
          />

          {!isImporting && (
            <DialogContentText variant="caption">
              Si deseas importar de forma masiva utilizando un archivo, puedes{' '}
              <Link
                href={dowloadTemplateUrl}
                download
                color="primary"
                underline="hover"
                fontWeight="bold"
              >
                descargar y modificar la plantilla de ejemplo.
              </Link>{' '}
              Recuerda respetar el orden de las columnas. Si necesitas
              información consulta la{' '}
              <Link
                href="https://routix.tawk.help/article/cargar-clientes-de-forma-masiva"
                download
                color="primary"
                underline="hover"
                fontWeight="bold"
              >
                ayuda.
              </Link>{' '}
            </DialogContentText>
          )}

          {isImporting && (
            <ImporterProgress
              title={importerProgressTitle}
              description={importerProgressDescription}
              progress={progress}
              warning={importedCustomersData.errorRows >= 1}
            />
          )}
        </DialogImportCustomersFromCSVContent>

        <DialogImportCustomersFromCSVActions>
          <Button color="secondary" onClick={onClose}>
            Cerrar
          </Button>

          {!isImporting && (
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={submitForm}
              loading={loading || isSubmitting}
              disabled={disabledSubmit}
            >
              Importar
            </LoadingButton>
          )}
        </DialogImportCustomersFromCSVActions>
      </DialogImportCustomersFromCSVContainer>
    </FormikProvider>
  );
};

export default DialogImportCustomers;

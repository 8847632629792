import { ReactElement, useMemo } from 'react';
import { Stack } from '@mui/material';

import { ImporterProgress } from '~components/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { getIntegrationProviderData } from '~utils/integrations';

import { IntegrationProvidersTypes } from '~globals/types/enums';

// eslint-disable-next-line max-len
import { useDialogImportOrdersFromIntegrationContext } from '../../DialogImportOrdersFromIntegrationContext';
import { DialogImportOrdersFromIntegrationLabelImg } from '../../styles';

const DialogImportOrdersFromIntegrationProgress = (): ReactElement => {
  const {
    importerRequest: { isCompletedRequest, itemsData },
    importerProgress: {
      status,
      data: { progress, step, total, integrationType, storeName },
    },
  } = useDialogImportOrdersFromIntegrationContext();

  const isCompletedProcessImporter = useMemo(
    () => status === 'completed' && isCompletedRequest,
    [status, isCompletedRequest],
  );

  const progressTitle = useMemo(() => {
    const providerInfo = getIntegrationProviderData(
      integrationType as IntegrationProvidersTypes,
    );

    return isCompletedProcessImporter
      ? 'Operaciones importadas'
      : `Importando operaciones ${providerInfo?.name} (${storeName})`;
  }, [isCompletedProcessImporter, integrationType, storeName]);

  const progressDescriptions = useMemo(
    () =>
      isCompletedProcessImporter
        ? itemsData.descriptions
        : `Importando ${step} de ${total}`,
    [isCompletedProcessImporter, itemsData, step, total],
  );

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <Stack spacing={2} direction="row" justifyContent="center">
        <DialogImportOrdersFromIntegrationLabelImg
          src={toAbsoluteUrl('/images/import_int.png')}
          alt="Import File from integrations"
        />
      </Stack>

      <ImporterProgress
        title={progressTitle}
        description={progressDescriptions}
        progress={progress}
      />
    </Stack>
  );
};

export default DialogImportOrdersFromIntegrationProgress;

import { ReactElement, useMemo, useCallback } from 'react';
import { Typography } from '@mui/material';
import {
  Check as AcceptIcon,
  Close as RejectedIcon,
} from '@mui/icons-material';

import { useToggle, useDriverCompanyInvitation } from '~hooks/index';

import DialogConfimation from '../DialogConfimation';
import { DriverCompanyActionInvitationProps } from './types';
import {
  DriverCompanyActionInvitationCard,
  DriverCompanyActionInvitationCardContent,
  DriverCompanyActionInvitationCardActions,
  DriverCompanyActionInvitationCardActionsButton,
} from './styles';

const DriverCompanyActionInvitation = ({
  driverCompanyId,
  name,
  onSuccessAcceptInvitation,
  onSuccessRejectedInvitation,
}: DriverCompanyActionInvitationProps): ReactElement => {
  const [openConfirmAccept, toggleOpenConfirmAccept] = useToggle();
  const acceptConfirmMessage = useMemo(
    () => `La invitacíon de ${name} será aceptada`,
    [name],
  );
  const handleSuccessAcceptInvitation = useCallback(() => {
    toggleOpenConfirmAccept(false);
    onSuccessAcceptInvitation?.();
  }, [toggleOpenConfirmAccept, onSuccessAcceptInvitation]);

  const [openConfirmRejected, toggleOpenConfirmRejected] = useToggle();
  const rejectedConfirmMessage = useMemo(
    () => `La invitacíon de ${name} será rechazada`,
    [name],
  );
  const handleSuccessRejectdInvitation = useCallback(() => {
    toggleOpenConfirmRejected(false);
    onSuccessRejectedInvitation?.();
  }, [toggleOpenConfirmRejected, onSuccessRejectedInvitation]);

  const {
    loadingAcceptInvitation,
    handleAcceptInvitation,
    loadingRejectedInvitation,
    handleRejectedInvitation,
  } = useDriverCompanyInvitation({
    onSuccessAcceptInvitation: handleSuccessAcceptInvitation,
    onSuccessRejectedInvitation: handleSuccessRejectdInvitation,
  });

  return (
    <DriverCompanyActionInvitationCard>
      <DriverCompanyActionInvitationCardContent>
        <Typography component="h3" variant="h6" color="white">
          {`${name} solicito unirse a tu empresa`}
        </Typography>

        <DriverCompanyActionInvitationCardActions>
          <DriverCompanyActionInvitationCardActionsButton
            variant="outlined"
            color="success"
            size="small"
            startIcon={<AcceptIcon />}
            onClick={() => toggleOpenConfirmAccept(true)}
          >
            Aceptar
          </DriverCompanyActionInvitationCardActionsButton>

          <DriverCompanyActionInvitationCardActionsButton
            variant="outlined"
            color="error"
            size="small"
            startIcon={<RejectedIcon />}
            onClick={() => toggleOpenConfirmRejected(true)}
          >
            Rechazar
          </DriverCompanyActionInvitationCardActionsButton>
        </DriverCompanyActionInvitationCardActions>
      </DriverCompanyActionInvitationCardContent>

      <DialogConfimation
        open={openConfirmAccept}
        description={acceptConfirmMessage}
        onConfirm={() => handleAcceptInvitation(driverCompanyId)}
        onCancel={() => toggleOpenConfirmAccept(false)}
        loadingConfirm={loadingAcceptInvitation}
      />

      <DialogConfimation
        open={openConfirmRejected}
        description={rejectedConfirmMessage}
        onConfirm={() => handleRejectedInvitation(driverCompanyId)}
        onCancel={() => toggleOpenConfirmRejected(false)}
        loadingConfirm={loadingRejectedInvitation}
      />
    </DriverCompanyActionInvitationCard>
  );
};

export default DriverCompanyActionInvitation;

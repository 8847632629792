import { ReactElement, useMemo } from 'react';
import Cards, { Focused } from 'react-credit-cards';

import { FIELDS_NAME, VALID_CARD_ISSUERS } from './utils';

import 'react-credit-cards/es/styles-compiled.css';

interface CardPreviewProps {
  fieldFocused?: string;
  cardValues: {
    number: string;
    expiration: string;
    cvv: string;
    holderName: string;
  };
}

const CardPreview = ({
  fieldFocused,
  cardValues,
}: CardPreviewProps): ReactElement => {
  const focused = useMemo(() => {
    let output: Focused | undefined = undefined;

    if (fieldFocused === FIELDS_NAME.CARD_NUMBER) output = 'number';
    if (fieldFocused === FIELDS_NAME.CARD_EXPIRATION) output = 'expiry';
    if (fieldFocused === FIELDS_NAME.CARD_CVV) output = 'cvc';
    if (fieldFocused === FIELDS_NAME.HOLDERNAME) output = 'name';

    return output;
  }, [fieldFocused]);

  return (
    <Cards
      focused={focused}
      number={cardValues.number}
      expiry={cardValues.expiration}
      cvc={cardValues.cvv}
      name={cardValues.holderName}
      locale={{ valid: 'Expiración' }}
      placeholders={{ name: 'NOMBRE TITULAR' }}
      acceptedCards={VALID_CARD_ISSUERS}
    />
  );
};

export default CardPreview;

import { ReactElement } from 'react';

import DialogGenerateRoutes from '../DialogGenerateRoutes';

import { DialogGenerateRouteCollectProps } from './types';

const DialogGenerateRouteCollect = (
  props: DialogGenerateRouteCollectProps,
): ReactElement => <DialogGenerateRoutes {...props} type="collect" />;

export default DialogGenerateRouteCollect;

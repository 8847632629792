import { styled } from '@mui/material';

const getMaxWidth = (maxWidth?: number): string =>
  maxWidth ? `${maxWidth}px` : '100%';

export const GalleryLightBoxRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'maxWidth',
})<{ maxWidth?: number }>(({ theme, maxWidth }) => ({
  overflow: 'hidden',
  display: 'flex',
  WebkitOverflowScrolling: 'touch',
  width: '100%',
  maxWidth: `calc(${getMaxWidth(maxWidth)} - ${theme.spacing(3 * 2)})`,
  marginTop: theme.spacing(1),
}));

export const GalleryLightBoxGalleryScroller = styled('div')({
  position: 'relative',
  display: 'inline-block',
  flex: '1 1 auto',
  whiteSpace: 'nowrap',
  overflow: 'auto hidden',

  // Hidden Scrollbar
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const GalleryLightBoxGalleryListContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    padding: 4,
  }),
);

export const GalleryLightBoxGalleryImgItem = styled('img')(({ theme }) => ({
  width: 100,
  height: 80,
  display: 'inline-flex',
  position: 'relative',
  flexShrink: 0,
  overflow: 'hidden',
  whiteSpace: 'normal',
  borderRadius: 24,
  cursor: 'pointer',
  opacity: 1,
  transition: theme.transitions.create(['opacity'], {
    duration: theme.transitions.duration.shortest,
  }),

  '&:hover': {
    opacity: 0.7,
  },
}));

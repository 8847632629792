import * as Yup from 'yup';
import { omitBy, isNull, isUndefined, get } from 'lodash';
import moment from 'moment';

import { VALIDATIONS_ERROR } from '~constants/index';
import { formatterDate } from '~utils/index';
import { parseSkillListToString } from '~utils/vehicle';
import { Nullable } from '~globals/types';
import { ItemEditionMultipleFields } from '~services/item/types';

export const EXTRACTOR_KEY_VALUE = 'id';

export const FIELDS_NAME = {
  ROWS_CHECKED: 'rowsChecked',
  CLIENT_NAME: 'clientName',
  ADDRESS_WAIT_TIME: 'addresWaitTime',
  TIME_WINDOW: 'timeWindow',
  TIME_WINDOW_ALL_DAY: 'timeWindowAllDay',
  TIME_WINDOW_ONE_FROM: 'timeWindowOneFrom',
  TIME_WINDOW_ONE_TO: 'timeWindowOneTo',
  TIME_WINDOW_SECOND_FROM: 'timeWindowSecondFrom',
  TIME_WINDOW_SECOND_TO: 'timeWindowSecondTo',
  DELIVERY_EXPIRATION_DATE: 'deliveryExpirationDate',
  DELIVERY_TAGS: 'deliveryTags',
  DELIVERY_SKILLS_NEEDED: 'deliverySkillsNedeed',
} as const;

export const initialValues = {
  [FIELDS_NAME.ROWS_CHECKED]: [] as string[],
  [FIELDS_NAME.CLIENT_NAME]: '',
  [FIELDS_NAME.ADDRESS_WAIT_TIME]: '',
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: false,
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: null as Nullable<Date>,
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: null as Nullable<Date>,
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: null as Nullable<Date>,
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: null as Nullable<Date>,
  [FIELDS_NAME.DELIVERY_EXPIRATION_DATE]: null as Nullable<Date>,
  [FIELDS_NAME.DELIVERY_TAGS]: '',
  [FIELDS_NAME.DELIVERY_SKILLS_NEEDED]: [] as string[],
};

export const validateRowsChecked = (
  currentRowsChecked: string[],
  rowFieldName: string,
): boolean => currentRowsChecked.includes(rowFieldName);

const validateString = (rowFieldName: string) =>
  Yup.string().when(FIELDS_NAME.ROWS_CHECKED, {
    is: (rowsCheckedValue: string[]) =>
      validateRowsChecked(rowsCheckedValue, rowFieldName),
    then: (baseSchema) => baseSchema.required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (baseSchema) => baseSchema.notRequired(),
  });

const validateTimeWindowDate = Yup.date()
  .nullable()
  .when([FIELDS_NAME.ROWS_CHECKED, FIELDS_NAME.TIME_WINDOW_ALL_DAY], {
    is: (
      rowsCheckedValue: string[],
      timeWindowAllDayValue: boolean,
      prevOrNextTimeWindowValue?: Nullable<Date>,
    ) => {
      const presencePrevOrNextTimeWindowValue = isUndefined(
        prevOrNextTimeWindowValue,
      )
        ? true
        : isNull(prevOrNextTimeWindowValue);

      return (
        validateRowsChecked(rowsCheckedValue, FIELDS_NAME.TIME_WINDOW) &&
        !timeWindowAllDayValue &&
        presencePrevOrNextTimeWindowValue
      );
    },
    then: (schema) =>
      schema
        .typeError(VALIDATIONS_ERROR.INVALID_DATE)
        .required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  });

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ROWS_CHECKED]: Yup.array().of(Yup.string()),
  [FIELDS_NAME.CLIENT_NAME]: validateString(FIELDS_NAME.CLIENT_NAME),
  [FIELDS_NAME.ADDRESS_WAIT_TIME]: validateString(
    FIELDS_NAME.ADDRESS_WAIT_TIME,
  ),
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: Yup.boolean(),
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: validateTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: validateTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .notRequired(),
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .notRequired(),
  [FIELDS_NAME.DELIVERY_EXPIRATION_DATE]: Yup.date()
    .nullable()
    .typeError(VALIDATIONS_ERROR.INVALID_DATE)
    .notRequired(),
  [FIELDS_NAME.DELIVERY_TAGS]: Yup.string().notRequired(),
  [FIELDS_NAME.DELIVERY_SKILLS_NEEDED]: Yup.array().of(Yup.string()),
});

export const toPathField = (path: Array<string | number>): string =>
  path.join('.');

export const isVisibleRow = (
  values: typeof initialValues,
  rowFieldName: string,
): boolean => {
  const currentRowsChecked = get(values, FIELDS_NAME.ROWS_CHECKED);

  return validateRowsChecked(currentRowsChecked, rowFieldName);
};

type ReturnTypeGetValueByVisibleRow<
  D extends typeof initialValues,
  P extends keyof D,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends (row: D[P]) => any,
> = F extends (item: D[P]) => D[P]
  ? D[P] | undefined
  : ReturnType<F> | undefined;

const getValueByVisibleRow = <
  D extends typeof initialValues,
  P extends keyof D,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends (row: D[P]) => any,
>(
  values: D,
  rowFieldName: P,
  formatter?: F,
): ReturnTypeGetValueByVisibleRow<D, P, F> => {
  if (!isVisibleRow(values, rowFieldName as string)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return undefined as any;
  }

  const rowValue = get(values, rowFieldName);

  if (formatter) return formatter(rowValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return rowValue as any;
};

const getTimeWindowsValue = (
  values: typeof initialValues,
): {
  timeWindowAllDay?: boolean;
  timeWindowFrom1?: Nullable<string>;
  timeWindowTo1?: Nullable<string>;
  timeWindowFrom2?: Nullable<string>;
  timeWindowTo2?: Nullable<string>;
} => {
  let timeWindowAllDay: boolean | undefined = undefined;
  let timeWindowFrom1: Nullable<string> | undefined = undefined;
  let timeWindowTo1: Nullable<string> | undefined = undefined;
  let timeWindowFrom2: Nullable<string> | undefined = undefined;
  let timeWindowTo2: Nullable<string> | undefined = undefined;

  if (isVisibleRow(values, FIELDS_NAME.TIME_WINDOW)) {
    timeWindowAllDay = get(values, FIELDS_NAME.TIME_WINDOW_ALL_DAY);

    if (!timeWindowAllDay) {
      timeWindowFrom1 = formatterDate(
        get(values, FIELDS_NAME.TIME_WINDOW_ONE_FROM),
        { format: moment.HTML5_FMT.TIME_SECONDS },
      );

      timeWindowTo1 = formatterDate(
        get(values, FIELDS_NAME.TIME_WINDOW_ONE_TO),
        { format: moment.HTML5_FMT.TIME_SECONDS },
      );

      const secondFrom = get(values, FIELDS_NAME.TIME_WINDOW_SECOND_FROM);
      const secondTo = get(values, FIELDS_NAME.TIME_WINDOW_SECOND_TO);

      if (!!secondFrom && !!secondTo) {
        timeWindowFrom2 = formatterDate(secondFrom, {
          format: moment.HTML5_FMT.TIME_SECONDS,
        });

        timeWindowTo2 = formatterDate(secondTo, {
          format: moment.HTML5_FMT.TIME_SECONDS,
        });
      }
    } else {
      timeWindowFrom1 = null;
      timeWindowTo1 = null;
      timeWindowFrom2 = null;
      timeWindowTo2 = null;
    }
  }

  return {
    timeWindowAllDay,
    timeWindowFrom1,
    timeWindowTo1,
    timeWindowFrom2,
    timeWindowTo2,
  };
};

export const getParseVisibleValues = (
  values: typeof initialValues,
): ItemEditionMultipleFields => {
  const {
    timeWindowAllDay,
    timeWindowFrom1,
    timeWindowTo1,
    timeWindowFrom2,
    timeWindowTo2,
  } = getTimeWindowsValue(values);

  const maxDeliveryDateTime = getValueByVisibleRow(
    values,
    FIELDS_NAME.DELIVERY_EXPIRATION_DATE,
    (currentDate) =>
      currentDate
        ? formatterDate(currentDate, {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          })
        : null,
  );

  let sendMaxDeliveryDateTime: boolean | undefined = undefined;

  if (!isUndefined(maxDeliveryDateTime)) {
    sendMaxDeliveryDateTime = true;
  }

  const parseValues = {
    title: getValueByVisibleRow(values, FIELDS_NAME.CLIENT_NAME),
    serviceDuration: getValueByVisibleRow(
      values,
      FIELDS_NAME.ADDRESS_WAIT_TIME,
      (currentValue) => Number(currentValue) * 60,
    ),
    timeWindowAllDay,
    timeWindowFrom1,
    timeWindowTo1,
    timeWindowFrom2,
    timeWindowTo2,
    maxDeliveryDateTime,
    sendMaxDeliveryDateTime,
    tags: getValueByVisibleRow(values, FIELDS_NAME.DELIVERY_TAGS),
    skillsNeeded: getValueByVisibleRow(
      values,
      FIELDS_NAME.DELIVERY_SKILLS_NEEDED,
      (currentSkills) => parseSkillListToString(currentSkills),
    ),
  };

  return omitBy(parseValues, isUndefined);
};

import { PartnerCompanyTypes } from '~globals/types/enums';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

export const COMPANY_TYPE_INFO = [
  {
    companyTypeId: PartnerCompanyTypes.Carrier,
    label: 'Transportista o logística',
    subLabel: '(Creador y gestor de rutas)',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Posee vehículos propios y gestiona las rutas y sus choferes. Puede administrar cargas propias o de otros dadores de carga.',
    scopeList: [
      'Posee vehículos propios.',
      'Generar las rutas de entrega.',
      'Gestiona vehículos y choferes.',
      'Puede ser su propio dador de carga.',
      'Foco en el transporte.',
    ],
    iconImage: {
      src: toAbsoluteUrl('/icons/icon_truck.svg'),
      alt: 'Carrier Icon',
    },
  },
  {
    companyTypeId: PartnerCompanyTypes.Giver,
    label: 'Dador de carga o vendedor',
    subLabel: '(Seguimiento de pedidos)',
    scopeDescription:
      // eslint-disable-next-line max-len
      'No posee ni administra vehículos o choferes. Realiza la carga y seguimientos de sus propias cargas. Gestiona la logística con otras empresas logísticas o trasnportistas',
    scopeList: [
      'Produce o vende los bienes.',
      'Controla la información de los pedidos.',
      'No tiene vehículos.',
      'No gestiona rutas.',
      'Requiere asociarse con un transportista.',
      'Foco en el pedido.',
    ],
    iconImage: {
      src: toAbsoluteUrl('/icons/icon_box.svg'),
      alt: 'Giver Icon',
    },
  },
];

import { ReactElement, useCallback } from 'react';
import { Button, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUpload as ImportOrderIcon,
  faFile as ImportFromFileIcon,
  faPlug as ImportFromIntegrationIcon,
  faProjectDiagram as ImportFromAssociatedCompany,
} from '@fortawesome/free-solid-svg-icons';

import {
  useToggleMenu,
  useTypePartnerCompany,
  useEnabledCrossCompany,
} from '~hooks/index';
import {
  DialogImportOrdersFromCSV,
  DialogImportOrdersFromIntegration,
  DialogImportFromAssociatedCompany,
} from '~components/index';

import { ImportOrdersMenuProps } from './types';
import { ImportOrdersMenuContainerMenu } from './styles';

const ImportOrdersMenu = ({
  onSubmitImportSuccess,
  openDialogImportFromFile,
  toggleOpenDialogImportFromFile,
  openDialogImportFromIntegration,
  toggleOpenDialogImportFromIntegration,
  openDialogImportFromAssociatedCompany,
  toggleOpenDialogImportFromAssociatedCompany,
  onUpdateErrors,
}: ImportOrdersMenuProps): ReactElement => {
  const [anchorElMenu, openMenu, handleToggleMenu] = useToggleMenu();

  const handleOpenCurrentDialog = useCallback(
    (toggleOpenCurrentDialog: (nextValue?: boolean | undefined) => void) => {
      toggleOpenCurrentDialog(true);
      handleToggleMenu();
    },
    [handleToggleMenu],
  );

  const { isCarrier } = useTypePartnerCompany();
  const { isEnabledCrossCompany } = useEnabledCrossCompany();

  const onUpdateErrorByCSV = useCallback(() => {
    toggleOpenDialogImportFromFile(false);

    setTimeout(() => {
      onUpdateErrors();
    }, 500);
  }, [onUpdateErrors, toggleOpenDialogImportFromFile]);

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<FontAwesomeIcon icon={ImportOrderIcon} />}
        endIcon={<KeyboardArrowDownIcon />}
        ref={anchorElMenu}
        aria-controls={openMenu ? 'import-orders-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleToggleMenu}
      >
        Importar Pedidos
      </Button>

      <ImportOrdersMenuContainerMenu
        id="import-orders-menu"
        anchorEl={anchorElMenu.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={openMenu}
        onClose={handleToggleMenu}
        elevation={0}
      >
        <MenuItem
          onClick={() =>
            handleOpenCurrentDialog(toggleOpenDialogImportFromFile)
          }
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={ImportFromFileIcon} />
          </ListItemIcon>

          <ListItemText primary="Desde archivo" />
        </MenuItem>

        <MenuItem
          onClick={() =>
            handleOpenCurrentDialog(toggleOpenDialogImportFromIntegration)
          }
        >
          <ListItemIcon>
            <FontAwesomeIcon icon={ImportFromIntegrationIcon} />
          </ListItemIcon>

          <ListItemText primary="Desde mis integraciones" />
        </MenuItem>

        {isCarrier && isEnabledCrossCompany && (
          <MenuItem
            onClick={() =>
              handleOpenCurrentDialog(
                toggleOpenDialogImportFromAssociatedCompany,
              )
            }
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={ImportFromAssociatedCompany} />
            </ListItemIcon>

            <ListItemText primary="Desde empresa asociada" />
          </MenuItem>
        )}
      </ImportOrdersMenuContainerMenu>

      <DialogImportOrdersFromCSV
        open={openDialogImportFromFile}
        onClose={() => toggleOpenDialogImportFromFile(false)}
        onSubmitSuccess={onSubmitImportSuccess}
        onUpdateErrors={onUpdateErrorByCSV}
      />

      <DialogImportOrdersFromIntegration
        open={openDialogImportFromIntegration}
        onClose={() => toggleOpenDialogImportFromIntegration(false)}
        onSubmitSuccess={onSubmitImportSuccess}
      />

      <DialogImportFromAssociatedCompany
        open={openDialogImportFromAssociatedCompany}
        onClose={() => toggleOpenDialogImportFromAssociatedCompany(false)}
        onSubmitSuccess={onSubmitImportSuccess}
      />
    </>
  );
};

export default ImportOrdersMenu;

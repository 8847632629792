import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { useStepperGenerateRoutePlanningStepperContext } from '../../SteppetGenerateRouteContext';
import { getSetenceByDictionary } from '../../utils';
import { StepperGenerateRouteDictionary } from '../../types';

const dictionary: StepperGenerateRouteDictionary = {
  title: {
    generate: 'Optimizando secuencia de paradas...',
    generateCollect: 'Optimizando secuencia de paradas...',
    optimize: 'Optimizando secuencia de paradas...',
  },
  description: {
    generate:
      'Se están analizando las paradas del plan de viaje para ofrecer un mejor recorrido.',
    generateCollect:
      'Se están analizando las paradas del plan de colecta para ofrecer un mejor recorrido.',
    optimize:
      'Se están analizando las paradas de la ruta para ofrecer un mejor recorrido.',
  },
  error: {
    generate: '',
    generateCollect: '',
    optimize: '',
  },
};

export const StepperGenerateRouteStepOptimizing = (): ReactElement => {
  const { type } = useStepperGenerateRoutePlanningStepperContext();

  return (
    <Stack>
      <Typography component="h4" variant="h6" color="primary">
        {getSetenceByDictionary(dictionary, 'title', type)}
      </Typography>
      <Typography component="p" variant="body2">
        {getSetenceByDictionary(dictionary, 'description', type)}
      </Typography>
    </Stack>
  );
};

export default StepperGenerateRouteStepOptimizing;

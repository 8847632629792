import { ReactElement, useMemo } from 'react';
import { Typography, Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMap as MapIcon } from '@fortawesome/free-regular-svg-icons';
import { faCalendar as CalendarIcon } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import classNames from 'classnames';

import { useHeaderActions } from '~app/contexts/HeaderActionsContext';
import { useAppSelector, useTypePartnerCompany } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { PATHS } from '~constants/index';
import { SCHEDULE_DEMO_URL } from '~constants/commons';
import {
  getInitialsNameLetterUpperCase,
  getDefaultAvatar,
  isAdminUser,
} from '~utils/user';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import {
  ActiveRoutesMenu,
  FirstStepsMenu,
  ProfileMenu,
  Subscription,
} from '~components/index';

import {
  HeaderContainer,
  HeaderCardInfoColumn,
  // HeaderButton,
  HeaderButtonRouterlLink,
  HeaderButtonExternalLink,
  HeaderIconButton,
  HeaderIconButtonIcon,
  HeaderIconButtonImg,
  HeaderButtonAvatar,
} from './styles';

const enabledBeforeDaysFirstSteps = 30;

const Header = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);
  const { isCarrier, isGiver } = useTypePartnerCompany();

  const isCurrentAdminUser = useMemo(() => isAdminUser(user), [user]);

  const { menuActiveRoutes, menuFirstSteps, menuProfile } = useHeaderActions();

  const isCurrentUserActiveFirstSteps = useMemo(() => {
    if (user?.creationDateTime) {
      const now = moment(moment().format(), 'YYYY-MM-DD');
      const creationDateUser = moment(user.creationDateTime, 'YYYY-MM-DD');
      const diffDays = now.diff(creationDateUser, 'days');

      return diffDays <= enabledBeforeDaysFirstSteps;
    }

    return false;
  }, [user]);

  return (
    <HeaderContainer id="header-app">
      <HeaderCardInfoColumn>
        <Avatar
          alt={user?.company.name}
          src={user?.company.logoFile?.url}
          sx={{ width: 50, height: 50 }}
        >
          {getInitialsNameLetterUpperCase(user?.company.name as string)}
        </Avatar>

        <Typography component="p" variant="subtitle1" fontWeight="bold">
          {user?.company.name}
        </Typography>

        {!isGiver && <Subscription.PlanInfoChip />}
      </HeaderCardInfoColumn>

      <HeaderCardInfoColumn>
        {isCurrentAdminUser && (
          <HeaderButtonExternalLink
            variant="outlined"
            color="primary"
            size="small"
            href={SCHEDULE_DEMO_URL}
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<FontAwesomeIcon icon={CalendarIcon} />}
          >
            ¿Tenés dudas? ¡Charlemos!
          </HeaderButtonExternalLink>
        )}

        <HeaderButtonRouterlLink
          variant="outlined"
          color="secondary"
          size="small"
          to={PATHS.CUSTOMERS.BASE}
        >
          Contactos Frecuentes
        </HeaderButtonRouterlLink>

        {isCarrier && (
          <HeaderIconButton
            variant="outlined"
            color="secondary"
            ref={menuActiveRoutes.anchorElRefMenu}
            aria-controls={
              menuActiveRoutes.open ? 'active-routes-menu' : undefined
            }
            aria-haspopup="true"
            aria-expanded={menuActiveRoutes.open ? 'true' : undefined}
            onClick={() => menuActiveRoutes.handleToggleOpenMenu(true)}
          >
            <HeaderIconButtonIcon icon={MapIcon} />
          </HeaderIconButton>
        )}

        {isCurrentAdminUser && (
          <HeaderIconButton
            variant="outlined"
            color="primary"
            withImage
            className={classNames({ pulse: isCurrentUserActiveFirstSteps })}
            ref={menuFirstSteps.anchorElRefMenu}
            aria-controls={menuFirstSteps.open ? 'first-steps-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuFirstSteps.open ? 'true' : undefined}
            onClick={() => menuFirstSteps.handleToggleOpenMenu(true)}
          >
            <HeaderIconButtonImg
              src={toAbsoluteUrl('/icons/icon_compass.svg')}
              alt="Compass Icon"
            />
          </HeaderIconButton>
        )}

        <HeaderButtonAvatar
          size="small"
          ref={menuProfile.anchorElRefMenu}
          aria-controls={menuProfile.open ? 'profile-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuProfile.open ? 'true' : undefined}
          onClick={() => menuProfile.handleToggleOpenMenu(true)}
        >
          <Avatar
            src={getDefaultAvatar(user?.avatarFile?.url)}
            alt={`${user?.lastName}, ${user?.firstName}`}
            sx={{ width: 38, height: 38 }}
          />
        </HeaderButtonAvatar>
      </HeaderCardInfoColumn>

      {menuActiveRoutes.open && (
        <ActiveRoutesMenu
          id="active-routes-menu"
          anchorEl={menuActiveRoutes.anchorElRefMenu.current}
          open={menuActiveRoutes.open}
          onClose={() => menuActiveRoutes.handleToggleOpenMenu(false)}
          arrow
          arrowColor="primary"
          placement="bottom"
        />
      )}

      <FirstStepsMenu
        id="first-steps-menu"
        anchorEl={menuFirstSteps.anchorElRefMenu.current}
        open={menuFirstSteps.open}
        onClose={() => menuFirstSteps.handleToggleOpenMenu(false)}
        arrow
        arrowColor="primary"
        placement="bottom"
      />

      <ProfileMenu
        id="profile-menu"
        anchorEl={menuProfile.anchorElRefMenu.current}
        open={menuProfile.open}
        onClose={() => menuProfile.handleToggleOpenMenu(false)}
        placement="bottom"
      />
    </HeaderContainer>
  );
};

export default Header;

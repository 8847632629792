import { ReactElement } from 'react';

import { NavigationListProps } from './types';
import {
  NavigationListContainer,
  NavigationListItem,
  NavigationListItemButton,
  NavigationListItemIcon,
  NavigationListItemText,
} from './styles';
import ComminSoonBadge from '../ComminSoonBadge';
import NewFeatureBagde from '../NewFeatureBagde';

const NavigationList = ({
  items,
  activeItem,
  onClickItem,
}: NavigationListProps): ReactElement => (
  <NavigationListContainer>
    {items.map(
      ({
        id,
        label,
        icon: Icon,
        path,
        commingSoon,
        newActiveForVersion,
        enabled = true,
      }) =>
        enabled && (
          <NavigationListItem key={`navigation-list-item-${id}`}>
            <NavigationListItemButton
              onClick={() => onClickItem?.({ id, path })}
              isActive={activeItem === id || activeItem === path}
              disabled={commingSoon}
            >
              <NavigationListItemIcon>{Icon}</NavigationListItemIcon>

              <NavigationListItemText
                primary={label}
                primaryTypographyProps={{ variant: 'inherit' }}
              />

              {commingSoon && <ComminSoonBadge />}

              {newActiveForVersion && (
                <NewFeatureBagde
                  activeForVersion={newActiveForVersion}
                  sx={{ marginRight: 0 }}
                />
              )}
            </NavigationListItemButton>
          </NavigationListItem>
        ),
    )}
  </NavigationListContainer>
);

export default NavigationList;

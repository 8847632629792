import { useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';

export interface PopupDataReturn<D> {
  data: D;
  handleOpenPopup: (
    event: mapboxgl.MapboxEvent<MouseEvent>,
    newInfo: D,
  ) => void;
  handleClosePopup: () => void;
}

export const usePopupData = <D>(initialData: D): PopupDataReturn<D> => {
  const [data, setData] = useState<D>(initialData);

  const handleOpenPopup = useCallback(
    (event: mapboxgl.MapboxEvent<MouseEvent>, newData: D) => {
      event.originalEvent.stopPropagation();

      setData(newData);
    },
    [],
  );

  const handleClosePopup = useCallback(() => {
    setData(initialData);
  }, [initialData]);

  return { data, handleOpenPopup, handleClosePopup };
};

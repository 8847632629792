import { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';
import { min } from 'lodash';

import { useSubscriptionRoutes } from '~hooks/index';

import { getColorState } from '../utils';

import SubscriptionAvailableRoutesStateIcon from '../Icon';

import {
  SubscriptionAvailableRoutesStateInfoRoot,
  SubscriptionAvailableRoutesStateInfoHeader,
  SubscriptionAvailableRoutesStateInfoProgress,
} from './styles';

const SubscriptionAvailableRoutesStateInfo = (): ReactElement => {
  const { itsHalfway, isEnding, maxRoutes, currentRoutes, progress } =
    useSubscriptionRoutes();

  const color = useMemo(
    () => getColorState(itsHalfway, isEnding),
    [isEnding, itsHalfway],
  );

  const progressValue = useMemo(() => min([100, progress]), [progress]);

  return (
    <SubscriptionAvailableRoutesStateInfoRoot>
      <SubscriptionAvailableRoutesStateInfoHeader>
        <Typography variant="inherit">
          En uso: <b>{`${currentRoutes} / ${maxRoutes}`}</b>
        </Typography>

        <SubscriptionAvailableRoutesStateIcon fontSize="small" />
      </SubscriptionAvailableRoutesStateInfoHeader>

      <SubscriptionAvailableRoutesStateInfoProgress
        variant="determinate"
        color="inherit"
        customColor={color}
        value={progressValue}
      />
    </SubscriptionAvailableRoutesStateInfoRoot>
  );
};

export default SubscriptionAvailableRoutesStateInfo;

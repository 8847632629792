import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { deletePartnerCompany } from '~services/partnerCompany';
import { PartnerCompany } from '~services/partnerCompany/types';
import { getSelectedPartnerCompanyInfo } from '~utils/partnerCompany';

import { useLazyRequest } from './useLazyRequest';

export const useDeletePartnerCompany = (
  onSuccessDelete?: (currentDeletedPartnerCompany: PartnerCompany) => void,
): {
  loading: boolean;
  handleDeletePartnerCompany: (partnerCompanyId: string) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingDeletePartnerCompany, , executeDeletePartnerCompany] =
    useLazyRequest({
      request: deletePartnerCompany,
      withPostSuccess: (response) => {
        const currentDeletedPartnerCompany = response.data
          ?.data as PartnerCompany;

        const currentParterCompanyDescription = getSelectedPartnerCompanyInfo(
          currentDeletedPartnerCompany,
        );

        // eslint-disable-next-line max-len
        const message = `La asociación ${currentParterCompanyDescription} fue eliminada correctamente`;

        enqueueSnackbar(message, { variant: 'success' });

        onSuccessDelete?.(currentDeletedPartnerCompany);
      },
      withPostFailure: () => {
        enqueueSnackbar(
          'No se pudo eliminar la asociación, intente nuevamente',
          { variant: 'error' },
        );
      },
    });

  const handleDeletePartnerCompany = useCallback(
    async (partnerCompanyId: string) => {
      await executeDeletePartnerCompany(partnerCompanyId);
    },
    [executeDeletePartnerCompany],
  );

  return { loading: loadingDeletePartnerCompany, handleDeletePartnerCompany };
};

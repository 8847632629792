import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

import { LoadingFallbackContainer } from './styles';

const LoadingFallback = ({
  size = 80,
  className,
}: {
  size?: number;
  className?: string;
}): ReactElement => (
  <LoadingFallbackContainer className={className}>
    <CircularProgress color="primary" disableShrink size={size} />
  </LoadingFallbackContainer>
);

export default LoadingFallback;

import {
  styled,
  Button,
  TextField,
  selectClasses,
  MenuItem,
  Checkbox,
  Box,
} from '@mui/material';

export const DataTableContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'withPagination',
})<{ withPagination: boolean }>(({ withPagination }) => ({
  height: '100%',

  '& ._loading_overlay_wrapper': {
    height: withPagination ? 'calc(100% - 53px)' : '100%',
  },

  '& ._loading_overlay_overlay': {
    height: withPagination ? 'calc(100% - 53px) !important' : '100% !important',
  },
}));

export const PaginationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
}));

export const PaginationSizesContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const SelectSizes = styled(TextField)(({ theme }) => ({
  [`& .${selectClasses.select}`]: {
    fontSize: theme.typography.subtitle2.fontSize,
    padding: theme.spacing(0.75, 1.75),
  },

  '& legend': {
    width: 'auto',
  },
}));

export const SelectSizeOptionItem = styled(MenuItem)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

export const PaginationPagesContainer = styled('div')({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',

  '& .pagination': {
    margin: 0,
  },
});

export const ButtonPage = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  fontSize: theme.typography.subtitle2.fontSize,
  minWidth: 0,

  '&:first-of-type': {
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
  },

  '&:last-of-type': {
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
}));

export const NoRecordsFoundMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));

export const NoRecordsFoundMessageImg = styled('img')({
  height: 150,
  objectFit: 'contain',
});

export const ColumnsHideableToggleButton = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  minWidth: 0,
  padding: '5px 4px',
}));

export const ColumnsHideableToggleMenuContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    minWidth: 240,
  }),
);

export const ColumnsHideableToggleMenuContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 3),
  overflowY: 'auto',
  alignItems: 'flex-start',
  maxHeight: 300,
}));

export const ColumnsHideableToggleCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: theme.spacing(0.5),
}));

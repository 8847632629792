import API from '~config/api';
import {
  ApiPromise,
  DataPaginateResponse,
  ErrorResponse,
  DataResponse,
} from '~globals/types';

import {
  WarehouseExtended,
  WarehouseHead,
  WarehouseMap,
  WarehouseInfo,
} from './types';

export const WAREHOUSE_ENPOINTS = {
  getWarehouses: '/api/v1/Route/Warehouse',
  searchWarehouseMap: '/api/v1/Route/WarehouseMap',
  getWarehouseInfo: (warehouseId: string): string =>
    `/api/v1/Route/Warehouse/Info/${warehouseId}`,
};

export const getWarehouses = (): ApiPromise<
  DataPaginateResponse<WarehouseExtended[], null, WarehouseHead>,
  ErrorResponse
> =>
  API.get<
    DataPaginateResponse<WarehouseExtended[], null, WarehouseHead>,
    ErrorResponse
  >(WAREHOUSE_ENPOINTS.getWarehouses);

export const searchWarehouseMap = (): ApiPromise<
  DataPaginateResponse<WarehouseMap[]>,
  ErrorResponse
> =>
  API.get<DataPaginateResponse<WarehouseMap[]>, ErrorResponse>(
    WAREHOUSE_ENPOINTS.searchWarehouseMap,
  );

export const getWarehouseInfo = (
  warehouseId: string,
): ApiPromise<DataResponse<WarehouseInfo>, ErrorResponse> =>
  API.get<DataResponse<WarehouseInfo>, ErrorResponse>(
    WAREHOUSE_ENPOINTS.getWarehouseInfo(warehouseId),
  );

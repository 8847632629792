import { BASE_URL } from '~config/api';

export const HUBS = {
  UPDATE_VEHICLE: `${BASE_URL}/UpdateVehicleHub`,
  NOTIFICATION_HUB: `${BASE_URL}/notification`,
};

export const METHODS_NAME = {
  REFRESH_VEHICLE: 'RefreshVehicle',
  REFRESH_API_TRANSACTION: 'RefreshApiTransaction',
  ADD_ROUTE: 'AddRoute',
  REMOVE_ROUTE: 'RemoveRoute',
  REFRESH_ROUTE: 'RefreshRoute',
  REFRESH_ROUTE_ITEM: 'RefreshRouteItem',
  ALERT: 'Alert',
  REFRESH_INTEGRATION: 'RefreshIntegration',
  PROGRESS_IMPORT: 'Progress_Import',
  REFRESH_ROUTE_MAPLINK_TRANSACTION: 'RefreshRouteMaplinkTransaction',
  REFRESH_PARTNER_COMPANY: 'RefreshPartnerCompany',
  REFRESH_CARRIER_RECEIVE_ORDERS: 'RefreshCarrierReceiveOrders',
  REFRESH_SUBSCRIPTION: 'RefreshSuscription',
};

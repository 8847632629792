import { styled, Typography } from '@mui/material';

import Link from '~components/Link';

import { DescriptionWithIconBoxIconPosition } from './types';

export const classes = {
  container: 'DescriptionWithIconBox-container',
  iconContainer: 'DescriptionWithIconBox-iconContainer',
  descriptionContainer: 'DescriptionWithIconBox-descriptionContainer',
  descriptionText: 'DescriptionWithIconBox-descriptionText',
  descriptionTextCaption: 'DescriptionWithIconBox-descriptionTextCaption',
  descriptionTextLink: 'DescriptionWithIconBox-descriptionTextLink',
};

export const DescriptionWithIconBoxContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'iconPosition' && prop !== 'size',
})<{ iconPosition?: DescriptionWithIconBoxIconPosition; size: number }>(
  ({ theme, iconPosition, size }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontSize: theme.typography.pxToRem(size),

    ...(iconPosition === 'left' && {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    }),

    ...(iconPosition === 'right' && {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    }),
  }),
);

export const DescriptionWithIconBoxIconContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'color' && prop !== 'size' && prop !== 'useCaption',
})<{ size: number; useCaption: boolean }>(
  ({ theme, color, size, useCaption }) => ({
    '& svg': {
      color: color ?? theme.palette.action.active,
      fontSize: size ? (useCaption ? size * 1.6 : size) : 'inherit',
    },

    '& img': {
      width: size * 3,
      height: size * 2,
    },
  }),
);

export const DescriptionWithIconBoxDescriptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DescriptionWithIconBoxText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 'normal',
});

export const DescriptionWithIconBoxTextLink = styled(Link)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  lineHeight: 'normal',
});

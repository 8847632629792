import { ReactElement, useMemo } from 'react';
import { SvgIconProps } from '@mui/material';

import { useSubscriptionRoutes } from '~hooks/index';
import { Nullable } from '~globals/types/commons';

import { getColorState } from '../utils';

import { SubscriptionAvailableRoutesStateIconRoot } from './styles';

export const SubscriptionAvailableRoutesStateIcon = ({
  fontSize = 'small',
  ...rest
}: SvgIconProps): Nullable<ReactElement> => {
  const { itsHalfway, isEnding } = useSubscriptionRoutes();

  const hidden = useMemo(
    () => !itsHalfway && !isEnding,
    [isEnding, itsHalfway],
  );

  const color = useMemo(
    () => getColorState(itsHalfway, isEnding),
    [isEnding, itsHalfway],
  );

  if (hidden) return null;

  return (
    <SubscriptionAvailableRoutesStateIconRoot
      fontSize={fontSize}
      htmlColor={color}
      {...rest}
    />
  );
};

export default SubscriptionAvailableRoutesStateIcon;

import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const OrderIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M15.06,30.71h-.42s0,0,0,0l-11.67-5A1.13,1.13,0,0,1,2.19,25V9.4a1.43,1.43,0,0,1,.74-.67Q8.65,6.3,14.35,3.84a1.19,1.19,0,0,1,1,0L28.47,8.76a.94.94,0,0,1,.73,1.05V24.63a.91.91,0,0,1-.72,1l-11,4.12-2.45.94m.63-2.08.26-.08L27.29,24.3a.3.3,0,0,0,.22-.34V10.84l-.23.08L24.34,12a.28.28,0,0,0-.21.32v4.88a.93.93,0,0,1-.73,1.07l-3,1.15a.87.87,0,0,1-1.28-.9V14l-.3.09c-.94.35-1.87.71-2.81,1.05a.34.34,0,0,0-.27.39V28.63ZM3.88,10.9v.17q0,6.5,0,13c0,.18.07.24.22.3l9.65,4.13.26.1v-.3q0-6.37,0-12.76a.37.37,0,0,0-.27-.4L5.92,11.77l-2-.87M25.93,9.62l-.26-.11-10.55-4a.62.62,0,0,0-.5,0c-3,1.31-6.07,2.6-9.1,3.9l-.31.15.19.1q4.65,2,9.3,4a.5.5,0,0,0,.36,0c.76-.27,1.52-.56,2.27-.84.07,0,.13-.06.23-.1l-.27-.12c-.88-.37-1.76-.72-2.62-1.1a1.16,1.16,0,0,1-.54-.43.82.82,0,0,1,.48-1.21c1.05-.45,2.1-.88,3.15-1.34a1.08,1.08,0,0,1,1,0c1.39.64,2.79,1.24,4.17,1.89a1,1,0,0,0,.84,0c.71-.28,1.42-.54,2.2-.83m-3.49,3.11c-.52.2-1,.39-1.5.56a.25.25,0,0,0-.19.28c0,.85,0,1.69,0,2.53v1.37l1.5-.56a.25.25,0,0,0,.19-.28c0-1,0-2,0-3v-.9M17,10.68l2.76,1.14a.26.26,0,0,0,.17,0c.35-.12.7-.26,1.1-.41l-2.74-1.22a.28.28,0,0,0-.18,0L17,10.68" />
    {/* eslint-disable-next-line max-len */}
    <path d="M6.49,22s.16,0,.25.07l3.37,1.43a.83.83,0,0,1-.26,1.6.81.81,0,0,1-.41-.07c-1.12-.46-2.23-.93-3.35-1.42a.82.82,0,0,1-.5-1,.86.86,0,0,1,.9-.65" />
  </SvgIcon>
);

export default OrderIcon;

import { ReactElement, useCallback } from 'react';
import { Card, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '~hooks/index';
import { getReportFeedbackReviews } from '~services/admin/report';
import { downloadFileByRequest } from '~utils/download';

import { FeedbackReviewsDownloaderProps } from './types';
import { FeedbackReviewsDownloaderCardContent } from './styles';

const FeedbackReviewsDownloader = ({
  filters,
}: FeedbackReviewsDownloaderProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingGetReport, , executeGetReport] = useLazyRequest({
    request: getReportFeedbackReviews,
    withPostSuccess: (response) => {
      if (response.headers && response.data) {
        downloadFileByRequest(response.headers, response.data);

        enqueueSnackbar(
          `Se ha exportado el feedback de usuarios correctamente`,
          { variant: 'success' },
        );
      } else {
        enqueueSnackbar(
          'No se pudo exportar el feedback de usuarios, intente nuevamente',
          { variant: 'error' },
        );
      }
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo exportar el feedback de usuarios, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const handleDowloadReport = useCallback(async () => {
    await executeGetReport(filters);
  }, [executeGetReport, filters]);

  return (
    <Card variant="outlined">
      <FeedbackReviewsDownloaderCardContent>
        <Typography variant="body1" component="h4" fontWeight="bold">
          Reporte de feedback de usuarios
        </Typography>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleDowloadReport}
          loading={loadingGetReport}
          sx={{ alignSelf: 'flex-start' }}
        >
          Descargar reporte
        </LoadingButton>

        <Typography variant="body2" color="text.secondary">
          Descargue el reporte para conocer las sugerencias y experiencias de
          sus usuarios.
        </Typography>
      </FeedbackReviewsDownloaderCardContent>
    </Card>
  );
};

export default FeedbackReviewsDownloader;

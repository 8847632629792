import {
  ReactElement,
  ChangeEvent,
  useMemo,
  useCallback,
  MouseEvent,
} from 'react';
import {
  Stack,
  FormControl,
  FormLabel,
  FormHelperText,
  Chip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useHandleFile } from '~hooks/index';

import { FileUploadProps } from './types';
import { FileInput } from './styles';

const FileUpload = ({
  name,
  label,
  accept = 'image/*, application/pdf',
  multiple = false,
  disabled = false,
  color,
  variant,
  fullWidth = true,
  enabledChipsInfo = true,
}: FileUploadProps): ReactElement => {
  const { loading, files, error, touched, handleAddFile, handleRemoveFile } =
    useHandleFile({
      nameField: name,
      multiple,
    });

  const hasError = useMemo(() => Boolean(error) && touched, [error, touched]);

  const helperText = useMemo(
    () => (hasError ? error : null),
    [error, hasError],
  );

  const resetInput = useCallback((event: MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = '';
  }, []);

  return (
    <Stack spacing={2}>
      <FormControl fullWidth={fullWidth} error={hasError}>
        <FileInput
          id={name}
          name={name}
          type="file"
          inputProps={{ accept, multiple, onClick: resetInput }}
          disabled={disabled}
          onChange={(evt) =>
            handleAddFile(evt as ChangeEvent<HTMLInputElement>)
          }
        />

        <FormLabel htmlFor={name}>
          <LoadingButton
            component="span"
            variant={variant}
            color={hasError ? 'error' : color}
            loading={loading}
          >
            {label}
          </LoadingButton>
        </FormLabel>

        {helperText && (
          <FormHelperText
            id={`${name}-helperText`}
            variant="outlined"
            error={hasError}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>

      {enabledChipsInfo && files.length > 0 && (
        <Stack spacing={2} direction="row">
          {files.map(({ id, file }) => (
            <Chip
              key={id}
              label={file.name}
              onDelete={() => handleRemoveFile(id)}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export default FileUpload;

import {
  ReactElement,
  MouseEvent,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  Tooltip,
  Typography,
  FormControlLabel,
  Stack,
  Button,
} from '@mui/material';
import { FilterAlt as ToggleColumnsIcon } from '@mui/icons-material/';
import { filter } from 'lodash';

import { Menu } from '~components/index';
import { useToggleMenu } from '~hooks/index';

// import { ColumnsHideableToggleProps } from './types';
import { useColumnsToggleContext } from './ColumnToggleContext';
import {
  ColumnsHideableToggleButton,
  ColumnsHideableToggleMenuContainer,
  ColumnsHideableToggleMenuContent,
  ColumnsHideableToggleCheckbox,
} from './styles';

const title = 'Mostrar/Ocultar Columnas';

const ColumnsHideableToggle = (): ReactElement => {
  const [anchorMenuEl, openMenu, , handleToggleOpenMenu] = useToggleMenu();

  const [showTooltip, setShowTooltip] = useState(false);

  const handleShowTooltip = useCallback(() => {
    if (!openMenu) {
      setShowTooltip(true);
    }
  }, [openMenu]);

  useEffect(() => {
    if (openMenu) {
      setShowTooltip(false);
    }
  }, [openMenu]);

  const {
    toggleModel: toggleModalContext,
    setToggleModel: setToggleModalContext,
    columns,
    excludeHideableFieldColumns,
  } = useColumnsToggleContext();

  const [toggleModel, setToggleModel] = useState(toggleModalContext);

  const isDirty = useMemo(
    () => !(JSON.stringify(toggleModalContext) === JSON.stringify(toggleModel)),
    [toggleModalContext, toggleModel],
  );

  const currentColumns = useMemo(
    () => filter(columns, (column) => column.dataField !== 'actions'),
    [columns],
  );

  const getIsDisabled = useCallback(
    (dataField: string) => excludeHideableFieldColumns.includes(dataField),
    [excludeHideableFieldColumns],
  );

  const onToggleHideableColumn = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { name: dataField } = event.target as HTMLInputElement;

      setToggleModel((prevToggleModel) => {
        return {
          ...prevToggleModel,
          [dataField]: !prevToggleModel?.[dataField],
        };
      });
    },
    [setToggleModel],
  );

  const onApllyChanges = useCallback(() => {
    if (toggleModel) {
      setToggleModalContext(toggleModel);

      handleToggleOpenMenu(false);
    }
  }, [handleToggleOpenMenu, setToggleModalContext, toggleModel]);

  const handleClose = useCallback(() => {
    handleToggleOpenMenu(false);

    if (isDirty) {
      setTimeout(() => setToggleModel(toggleModalContext), 500);
    }
  }, [handleToggleOpenMenu, isDirty, toggleModalContext]);

  return (
    <>
      <Tooltip
        title={title}
        arrow
        open={showTooltip}
        onOpen={handleShowTooltip}
        onClose={() => setShowTooltip(false)}
      >
        <ColumnsHideableToggleButton
          variant="outlined"
          color="inherit"
          ref={anchorMenuEl}
          aria-label="hideable-column"
          aria-controls={openMenu ? 'hideable-column-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={() => handleToggleOpenMenu(true)}
        >
          <ToggleColumnsIcon />
        </ColumnsHideableToggleButton>
      </Tooltip>

      <Menu
        id="hideable-column-menu"
        anchorEl={anchorMenuEl.current}
        open={openMenu}
        onClose={handleClose}
        placement="bottom"
        arrow={false}
      >
        <ColumnsHideableToggleMenuContainer>
          <Typography variant="body2" fontWeight="bold" textAlign="center">
            {title}
          </Typography>

          <ColumnsHideableToggleMenuContent>
            {currentColumns.map((column) => (
              <FormControlLabel
                key={`toggle-hideable-column-${column.dataField}`}
                control={
                  <ColumnsHideableToggleCheckbox
                    disabled={getIsDisabled(column.dataField)}
                    checked={!toggleModel?.[column.dataField]}
                    onClick={onToggleHideableColumn}
                    name={column.dataField}
                    size="small"
                  />
                }
                label={column.text}
              />
            ))}
          </ColumnsHideableToggleMenuContent>

          <Stack paddingX={3}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={onApllyChanges}
              disabled={!isDirty}
            >
              Aplicar
            </Button>
          </Stack>
        </ColumnsHideableToggleMenuContainer>
      </Menu>
    </>
  );
};

export default ColumnsHideableToggle;

import { styled } from '@mui/material';

export const PublicContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
}));

export const PublicLogo = styled('img')(({ theme }) => ({
  width: 130,
  objectFit: 'contain',
  marginBottom: theme.spacing(3),
}));

export const PublicContent = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
});

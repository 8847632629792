import { ReactElement, ElementType, useCallback } from 'react';
import { TabContext } from '@mui/lab';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { omit } from 'lodash';

import {
  getBusinessProfileId,
  getCompanyTypeIdByBusinessProfileId,
} from '~utils/businessProfile';
import { isCarrierPartnerCompany } from '~utils/partnerCompany';
import { useLazyRequest, useAppDispatch } from '~hooks/index';
import { updateCompanyType } from '~services/admin/companySetting';
import { AdminCompanySetting } from '~services/admin/companySetting/types';
import { setUserCompanyType } from '~store/slices/auth';

import {
  BusinessProfileContextPanelsId,
  useBusinessProfileContext,
} from '../BusinessProfileContext';
import { useInitialSetupWizardContext } from '../../../InitialSetupWizarContext';

import BusinessProfileDescriptionConfirm from './DescriptionConfirm';
import BusinessProfileOperationType from './OperationType';
import BusinessProfileOrderCentralizationShipment from './OrderCentralizationShipment';
import BusinessProfileOrderCentralizationTransfer from './OrderCentralizationTransfer';
import BusinessProfileOrderCollectedTransfer from './OrderCollectedTransfer';
import BusinessProfileOrderDistributionShipment from './OrderDistributionShipment';
import BusinessProfileOrderManagementCollect from './OrderManagementCollect';
import { initialValues, validationSchema, FIELDS_NAMES } from './utils';
import { BusinessProfileTabPanel } from './styles';

const TABS_PANELS: {
  id: BusinessProfileContextPanelsId;
  panel: ElementType;
}[] = [
  {
    id: 'operationType',
    panel: BusinessProfileOperationType,
  },
  {
    id: 'orderManagementCollect',
    panel: BusinessProfileOrderManagementCollect,
  },
  {
    id: 'orderCollectedTransfer',
    panel: BusinessProfileOrderCollectedTransfer,
  },
  {
    id: 'orderCentralizationTransfer',
    panel: BusinessProfileOrderCentralizationTransfer,
  },
  {
    id: 'orderCentralizationShipment',
    panel: BusinessProfileOrderCentralizationShipment,
  },
  {
    id: 'orderDistributionShipment',
    panel: BusinessProfileOrderDistributionShipment,
  },
  {
    id: 'descriptionConfirm',
    panel: BusinessProfileDescriptionConfirm,
  },
];

const BusinessProfileTabs = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const { activeTab } = useBusinessProfileContext();

  const {
    step: { handleNextStep },
    companyType: { setSelected: setSelectedCompanyTypes },
  } = useInitialSetupWizardContext();

  const [, , , exceuteUpdateCompanyType] = useLazyRequest({
    request: updateCompanyType,
    withPostSuccess: (response) => {
      const settingResponse = response.data?.data as AdminCompanySetting;

      setSelectedCompanyTypes(settingResponse.companyTypeId);

      dispatch(setUserCompanyType(settingResponse.companyTypeId));

      handleNextStep(
        isCarrierPartnerCompany(settingResponse.companyTypeId)
          ? 'loadVehicle'
          : 'linkAssociatedCompany',
      );

      enqueueSnackbar('El perfil de tu negocio fue creado correctamente', {
        variant: 'success',
      });
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo crear el perfil de tu negocio, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      const businessProfileTypeId = getBusinessProfileId(
        omit(values, FIELDS_NAMES.NOTES),
      );

      if (businessProfileTypeId) {
        const companyTypeId = getCompanyTypeIdByBusinessProfileId(
          businessProfileTypeId,
        );

        await exceuteUpdateCompanyType({
          companyTypeId,
          businessProfileTypeId,
          businessProfileNote: values[FIELDS_NAMES.NOTES] ?? null,
        });
      }

      setSubmitting(false);
    },
    [exceuteUpdateCompanyType],
  );

  const formikBag = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <FormikProvider value={formikBag}>
      <TabContext value={activeTab}>
        {TABS_PANELS.map(({ id, panel: Panel }) => (
          <BusinessProfileTabPanel
            key={`business-profile-panel-${id}`}
            value={id}
          >
            <Panel />
          </BusinessProfileTabPanel>
        ))}
      </TabContext>
    </FormikProvider>
  );
};

export default BusinessProfileTabs;

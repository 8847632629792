import SvgIcon from '@mui/material/SvgIcon';
import {
  CheckCircle as CheckIcon,
  Cancel as ErrorIcon,
  ReportProblem as WarningIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { orange, red, blue, green } from '@mui/material/colors';

import { NotificationAlertType } from '~globals/types/enums';

export interface TypeIconVariant {
  icon: typeof SvgIcon;
  color: string;
}

export const typeIconVariants: {
  [type in NotificationAlertType]: TypeIconVariant;
} = {
  [NotificationAlertType.Alert]: { icon: WarningIcon, color: orange[500] },
  [NotificationAlertType.Incidence]: { icon: ErrorIcon, color: red[700] },
  [NotificationAlertType.Info]: { icon: InfoIcon, color: blue[500] },
  [NotificationAlertType.Success]: { icon: CheckIcon, color: green[500] },
};

import { ReactElement, useMemo, useCallback } from 'react';
import {
  Stack,
  DialogContentText,
  Link,
  TextField,
  MenuItem,
  PopperProps,
} from '@mui/material';
import { useFormikContext } from 'formik';
import moment from 'moment';

import { Subscription } from '~components/index';
import { getIntegrationProviderData } from '~utils/integrations';
import { MERCADO_LIBRE_SHIPPING_OPTIONS } from '~constants/integrationsProviders';
import { hasError } from '~utils/formHelpers';

// eslint-disable-next-line max-len
import { useDialogImportOrdersFromIntegrationContext } from '../../DialogImportOrdersFromIntegrationContext';
import { FIELDS_NAME, getInitialValues } from '../../utils';
import { DialogImportOrdersFromIntegrationLabelImg } from '../../styles';

const defaultPickersPoppersProps: Partial<PopperProps> = {
  modifiers: [
    {
      name: 'preventOverflow',
      enabled: true,
      options: {
        altAxis: true,
        altBoundary: true,
        tether: true,
        rootBoundary: 'document',
        padding: 8,
      },
    },
  ],
};

const DialogImportOrdersFromIntegrationPresentation = (): ReactElement => {
  const {
    currentIntegrations: { ids: currentIntegrationsIds, isPresentMeli },
  } = useDialogImportOrdersFromIntegrationContext();

  const providersImgsData = useMemo(() => {
    const imgsData = currentIntegrationsIds.map((currentIntegrationsId) => {
      const providerInfo = getIntegrationProviderData(currentIntegrationsId);

      return providerInfo?.logoSmall;
    });

    return imgsData;
  }, [currentIntegrationsIds]);

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const handleChangeDate = useCallback(
    (fieldName: string, resetFieldName?: string) =>
      (value: Date | null): void => {
        setFieldValue(fieldName, value);

        if (resetFieldName) {
          setFieldValue(resetFieldName, null);
        }
      },
    [setFieldValue],
  );

  const handleBlurDate = useCallback(
    (fieldName: string) => (): void => {
      setFieldTouched(fieldName, true, false);
    },
    [setFieldTouched],
  );

  const minDateToField = useMemo(
    () => values[FIELDS_NAME.DATE_FROM] ?? undefined,
    [values],
  );

  const maxDateToField = useMemo(
    () =>
      minDateToField
        ? moment(minDateToField).endOf('day').add(30, 'day').toDate()
        : undefined,
    [minDateToField],
  );

  const defaultCalendarMonthToField = useMemo(() => {
    const currentDate = moment();

    if (!currentDate.isSameOrBefore(maxDateToField)) {
      return maxDateToField ?? undefined;
    }

    return values[FIELDS_NAME.DATE_TO] ?? undefined;
  }, [values, maxDateToField]);

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <DialogContentText color="text.primary">
        Importar pedidos desde mis integraciones
      </DialogContentText>

      <Stack spacing={2} direction="row" justifyContent="center">
        {providersImgsData.map((providerImg, index) => (
          <DialogImportOrdersFromIntegrationLabelImg
            key={`providers-label-img-${index}`}
            size="small"
            rounded
            elevation={6}
            src={providerImg?.url}
            alt={providerImg?.alt}
          />
        ))}
      </Stack>

      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Subscription.DataRetentionMin.DateTimePicker
            label="Desde"
            value={values[FIELDS_NAME.DATE_FROM]}
            onChange={handleChangeDate(
              FIELDS_NAME.DATE_FROM,
              FIELDS_NAME.DATE_TO,
            )}
            onAccept={handleChangeDate(
              FIELDS_NAME.DATE_FROM,
              FIELDS_NAME.DATE_TO,
            )}
            views={['day', 'hours']}
            PopperProps={defaultPickersPoppersProps}
            disableFuture
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                size="small"
                onBlur={handleBlurDate(FIELDS_NAME.DATE_FROM)}
                error={hasError(touched, errors, FIELDS_NAME.DATE_FROM)}
              />
            )}
          />

          <Subscription.DataRetentionMin.DateTimePicker
            label="Hasta"
            value={values[FIELDS_NAME.DATE_TO]}
            onChange={handleChangeDate(FIELDS_NAME.DATE_TO)}
            onAccept={handleChangeDate(FIELDS_NAME.DATE_TO)}
            views={['day', 'hours']}
            PopperProps={defaultPickersPoppersProps}
            disableFuture
            minDateTime={minDateToField}
            maxDateTime={maxDateToField}
            defaultCalendarMonth={defaultCalendarMonthToField}
            renderInput={(props) => (
              <TextField
                {...props}
                fullWidth
                onBlur={handleBlurDate(FIELDS_NAME.DATE_TO)}
                error={hasError(touched, errors, FIELDS_NAME.DATE_TO)}
              />
            )}
          />
        </Stack>

        {isPresentMeli && (
          <TextField
            label="Tipo de envío (solo mercado libre)"
            fullWidth
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.DELIVERY_TYPE)}
            {...getFieldProps(FIELDS_NAME.DELIVERY_TYPE)}
            select
          >
            {MERCADO_LIBRE_SHIPPING_OPTIONS.map((meliShippingOption) => (
              <MenuItem
                key={`shipping-option-${meliShippingOption.value}`}
                value={meliShippingOption.value}
              >
                {meliShippingOption.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>

      <DialogContentText variant="caption">
        Desde acá podrás importar los pedidos de tus integraciones. Para mayor
        información sobre integraciones, diríjase a este{' '}
        <Link
          href="https://routix.tawk.help/article/integraciones-con-otras-plataformas"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          underline="hover"
          fontWeight="bold"
        >
          artículo
        </Link>
      </DialogContentText>
    </Stack>
  );
};

export default DialogImportOrdersFromIntegrationPresentation;

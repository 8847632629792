import { ReactElement, ReactNode } from 'react';

import ComponentWithSuspense from '../ComponentWithSuspense';

interface PublicRouteProps {
  component: ReactNode;
}

const PublicRoute = ({ component }: PublicRouteProps): ReactElement => (
  <ComponentWithSuspense component={component} />
);

export default PublicRoute;

import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const MarkerIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path
      d="M467.86,215.83C467.86,89.7,369,2.76,255.65,2.76S43.43,89.7,43.43,215.83c0,106.99,71.13,
      185.76,161.89,207.21h-1.32L255.65,509l51.64-85.96h-1.32C396.73,401.59,467.86,322.82,467.86,
      215.83 M255.65,357.31c-77.56,0-133.81-57.95-133.81-141.48S178.09,74.36,255.65,74.36c77.56,0,
      133.81,57.95,133.81,141.48S333.2,357.31,255.65,357.31"
    />
  </SvgIcon>
);

export default MarkerIcon;

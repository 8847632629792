import {
  ReactElement,
  ChangeEvent,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { useFormik, FormikHelpers } from 'formik';
import {
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { find } from 'lodash';

import { hasError } from '~utils/index';
import { parseSkillsStringToArray } from '~utils/vehicle';
import { ColorPicker, CustomAutocomplete } from '~components/index';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';

import { ConfigVehicleFormProps, ConfigVehicleFormValues } from './types';
import { FIELDS_NAME, getInitialValues, validationSchema } from './utils';
import { ConfigVehicleFormActionChip } from './styles';

const ConfigVehicleForm = ({
  onSubmit,
  loadingSubmit,
  data,
  type = 'create',
  vehicleTypes,
  skillsTypes,
}: ConfigVehicleFormProps): ReactElement => {
  const executeSubmit = useCallback(
    async (
      values: ConfigVehicleFormValues,
      { setSubmitting }: FormikHelpers<ConfigVehicleFormValues>,
    ) => {
      await onSubmit(values);

      setSubmitting(false);
    },
    [onSubmit],
  );

  const {
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = useFormik({
    initialValues: getInitialValues(type, data),
    validationSchema,
    onSubmit: executeSubmit,
  });

  const handleChangeVehicleType = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target ?? event.currentTarget;

      setFieldValue(FIELDS_NAME.TYPE, value);

      const currentVehicleTypeData = find(vehicleTypes, [
        'vehicleTypeId',
        Number(value),
      ]);

      setFieldValue(
        FIELDS_NAME.AVERAGE_CONSUPTION,
        String(currentVehicleTypeData?.fuelConsuptiom ?? ''),
      );
    },
    [setFieldValue, vehicleTypes],
  );

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFieldValue(fieldName, value);
      },
    [setFieldValue],
  );

  const handleBlurDate = useCallback(
    (fieldName: string) => (): void => {
      setFieldTouched(fieldName, true);
    },
    [setFieldTouched],
  );

  const handleChangeColor = useCallback(
    (newColor: string) => {
      setFieldValue(FIELDS_NAME.COLOR, newColor);
    },
    [setFieldValue],
  );

  const handleBlurColor = useCallback(() => {
    setFieldTouched(FIELDS_NAME.COLOR, true);
  }, [setFieldTouched]);

  const refSkillsAutocomplete =
    useRef<CustomAutocompleteRef<string, true>>(null);

  const setDefaultSkills = useCallback(() => {
    const defaultSkillList = parseSkillsStringToArray(data?.skillsList);

    if (defaultSkillList.length) {
      refSkillsAutocomplete.current?.setValue(defaultSkillList);
    }
  }, [data?.skillsList]);

  useEffect(() => {
    setDefaultSkills();
  }, [setDefaultSkills]);

  const isCreate = useMemo(() => type === 'create', [type]);

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit}>
      <Typography component="h6" fontWeight="bold">
        Información de unidad
      </Typography>

      <Stack spacing={2} direction="row">
        <FormControlLabel
          control={
            <Switch
              {...getFieldProps(FIELDS_NAME.ENABLED)}
              checked={values[FIELDS_NAME.ENABLED]}
            />
          }
          label="Activo"
        />
      </Stack>

      <Stack spacing={2} direction="row">
        <TextField
          label="Dominio"
          autoComplete="off"
          fullWidth
          error={hasError(touched, errors, FIELDS_NAME.NAME)}
          {...getFieldProps(FIELDS_NAME.NAME)}
        />

        <ColorPicker
          value={values[FIELDS_NAME.COLOR]}
          label="Color de preferencia"
          fullWidth
          onChange={handleChangeColor}
          onBlur={handleBlurColor}
          error={hasError(touched, errors, FIELDS_NAME.COLOR)}
        />

        <TextField
          label="Referencia (Opcional)"
          autoComplete="off"
          fullWidth
          error={hasError(touched, errors, FIELDS_NAME.REFERENCE_CODE)}
          {...getFieldProps(FIELDS_NAME.REFERENCE_CODE)}
        />
      </Stack>

      <Stack spacing={2} direction="row">
        <TextField
          label="Visitas máximas"
          autoComplete="off"
          fullWidth
          error={hasError(touched, errors, FIELDS_NAME.MAXIMUN_VISITS)}
          {...getFieldProps(FIELDS_NAME.MAXIMUN_VISITS)}
        />

        <TextField
          label="Tipo de vehículo"
          fullWidth
          autoComplete="off"
          error={hasError(touched, errors, FIELDS_NAME.TYPE)}
          {...getFieldProps(FIELDS_NAME.TYPE)}
          onChange={handleChangeVehicleType}
          select
        >
          {vehicleTypes.map((vehicleType) => (
            <MenuItem
              key={`vehicle-type-${vehicleType.vehicleTypeId}`}
              value={String(vehicleType.vehicleTypeId)}
            >
              {vehicleType.description}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          label="Consumo promedio L/Km (Opcional)"
          autoComplete="off"
          fullWidth
          error={hasError(touched, errors, FIELDS_NAME.AVERAGE_CONSUPTION)}
          {...getFieldProps(FIELDS_NAME.AVERAGE_CONSUPTION)}
        />
      </Stack>

      <Stack spacing={2} direction="row">
        <TextField
          label="Carga primaria (En KG, L, dinero, bultos)"
          autoComplete="off"
          fullWidth
          type="number"
          error={hasError(touched, errors, FIELDS_NAME.CAPACITY_ONE)}
          {...getFieldProps(FIELDS_NAME.CAPACITY_ONE)}
        />

        <TextField
          label="Carga secundaria (En KG, L, dinero, bultos)"
          autoComplete="off"
          fullWidth
          type="number"
          error={hasError(touched, errors, FIELDS_NAME.CAPACITY_TWO)}
          {...getFieldProps(FIELDS_NAME.CAPACITY_TWO)}
        />
      </Stack>

      <Stack spacing={2} direction="row">
        <CustomAutocomplete
          innerRef={refSkillsAutocomplete}
          multiple
          disableCloseOnSelect
          filterSelectedOptions
          // limitTags={1}
          renderTags={(vals, getTagsProps) =>
            vals.map((val, index) => (
              // eslint-disable-next-line react/jsx-key
              <ConfigVehicleFormActionChip
                label={val}
                size="small"
                {...getTagsProps({ index })}
              />
            ))
          }
          disableClearable
          options={skillsTypes}
          label="Cargas especiales o atributos (Opcional)"
          name={FIELDS_NAME.SKILLS_LIST}
          onChange={(_e, vals) => {
            setFieldValue(FIELDS_NAME.SKILLS_LIST, vals);
          }}
          onBlur={() => {
            setFieldTouched(FIELDS_NAME.SKILLS_LIST, true);
          }}
          fullWidth
          getOptionLabel={(option) => option}
        />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body2" fontWeight="bold">
          Ventana horaria de disponibilidad
        </Typography>

        <Stack spacing={2} direction="row" alignItems="center">
          <TimePicker
            label="Desde"
            value={values[FIELDS_NAME.TIME_FROM]}
            onChange={handleChangeDate(FIELDS_NAME.TIME_FROM)}
            renderInput={(props) => (
              <TextField
                {...props}
                onBlur={handleBlurDate(FIELDS_NAME.TIME_FROM)}
                error={hasError(touched, errors, FIELDS_NAME.TIME_FROM)}
              />
            )}
          />

          <TimePicker
            label="Hasta"
            value={values[FIELDS_NAME.TIME_TO]}
            onChange={handleChangeDate(FIELDS_NAME.TIME_TO)}
            renderInput={(props) => (
              <TextField
                {...props}
                onBlur={handleBlurDate(FIELDS_NAME.TIME_TO)}
                error={hasError(touched, errors, FIELDS_NAME.TIME_TO)}
              />
            )}
          />
        </Stack>
      </Stack>

      <Stack direction="row" spacing={2}>
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loadingSubmit || isSubmitting}
          disabled={!isCreate && !dirty}
          sx={{ minWidth: 200 }}
        >
          {isCreate ? 'Crear' : 'Guardar'}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ConfigVehicleForm;

import { Typography } from '@mui/material';
import { MapMarkerDragHintContainer } from './styles';
import NewFeatureBadge from '../NewFeatureBagde';

const MapMarkerDragHint: () => JSX.Element = () => {
  return (
    <MapMarkerDragHintContainer>
      <Typography variant="caption" mx={1}>
        Arrastre el marcador para ajustar la posición
      </Typography>
      <NewFeatureBadge activeForVersion="1.12.0" />
    </MapMarkerDragHintContainer>
  );
};

export default MapMarkerDragHint;

import { ReactElement } from 'react';
import { Card, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip as ChartTooltip,
  Legend as ChartLegend,
} from 'chart.js';
import { Line as LineChart } from 'react-chartjs-2';

import { getPropsOrdersReportsChart } from './utils';
import { DashboardOrdersReportProps } from './types';
import {
  DashboardOrdersReportCardContent,
  DashboardOrdersReportChartContainer,
} from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  ChartTooltip,
  ChartLegend,
);

const DashboardOrdersReport = ({
  previousYearVisitCompleted,
  currentYearVisitCompleted,
}: DashboardOrdersReportProps): ReactElement => {
  return (
    <Card>
      <DashboardOrdersReportCardContent>
        <Typography variant="h6" fontWeight="bold">
          Demanda histórica
        </Typography>

        <DashboardOrdersReportChartContainer>
          <LineChart
            {...getPropsOrdersReportsChart(
              previousYearVisitCompleted,
              currentYearVisitCompleted,
            )}
          />
        </DashboardOrdersReportChartContainer>
      </DashboardOrdersReportCardContent>
    </Card>
  );
};

export default DashboardOrdersReport;

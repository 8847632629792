import queryString from 'query-string';

import { useScript } from './useScript';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const scriptId = 'google-maps';
const scriptSrc = queryString.stringifyUrl(
  {
    url: 'https://maps.googleapis.com/maps/api/js',
    query: {
      key: API_KEY,
      libraries: 'places',
    },
  },
  { encode: false },
);

export const useLoadGoogleApis = (): void => {
  useScript({
    id: scriptId,
    position: document.querySelector('head'),
    src: scriptSrc,
    async: true,
    validateOnlyProduction: false,
  });
};

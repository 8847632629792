import * as Yup from 'yup';

import { Nullable } from '~globals/types';
import { VALIDATIONS_ERROR } from '~constants/index';
import { AuthExtendedCompany } from '~services/auth/types';

export const FIELDS_NAME = {
  CARRIER_COMPANY_ID: 'carrierCompanyId',
  NEED_COLLECT: 'needCollect',
  ORIGIN_POINT: 'originPoint',
  ADDRESS: 'address',
  ADDRESS_COORDS: 'addressCoords',
  ADDRESS_NOTES: 'addressNotes',
  TIME_WINDOW_ALL_DAY: 'timeWindowAllDay',
  TIME_WINDOW_ONE_FROM: 'timeWindowOneFrom',
  TIME_WINDOW_ONE_TO: 'timeWindowOneTo',
  TIME_WINDOW_SECOND_FROM: 'timeWindowSecondFrom',
  TIME_WINDOW_SECOND_TO: 'timeWindowSecondTo',
} as const;

const getDefaultCarrierCompany = (
  activesPartnerCompanies: AuthExtendedCompany[],
): string => {
  if (activesPartnerCompanies.length === 1) {
    return activesPartnerCompanies[0].id;
  }

  return '';
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (
  activesPartnerCompanies: AuthExtendedCompany[],
) => ({
  [FIELDS_NAME.CARRIER_COMPANY_ID]: getDefaultCarrierCompany(
    activesPartnerCompanies,
  ),
  [FIELDS_NAME.NEED_COLLECT]: true,
  [FIELDS_NAME.ORIGIN_POINT]: '',
  [FIELDS_NAME.ADDRESS]: '',
  [FIELDS_NAME.ADDRESS_COORDS]: null as Nullable<google.maps.LatLngLiteral>,
  [FIELDS_NAME.ADDRESS_NOTES]: '',
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: true,
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: null as Nullable<Date>,
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: null as Nullable<Date>,
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: null as Nullable<Date>,
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: null as Nullable<Date>,
});

export const validationTimeWindowDate = Yup.date()
  .nullable()
  .when(FIELDS_NAME.TIME_WINDOW_ALL_DAY, {
    is: false,
    then: (schema) =>
      schema
        .typeError(VALIDATIONS_ERROR.INVALID_DATE)
        .required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (schema) => schema.notRequired(),
  });

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.CARRIER_COMPANY_ID]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.NEED_COLLECT]: Yup.boolean(),
  [FIELDS_NAME.ORIGIN_POINT]: Yup.string().notRequired(),
  [FIELDS_NAME.ADDRESS]: Yup.string().when(FIELDS_NAME.NEED_COLLECT, {
    is: true,
    then: (scheme) => scheme.required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (scheme) => scheme.notRequired(),
  }),
  [FIELDS_NAME.ADDRESS_COORDS]: Yup.mixed().when(FIELDS_NAME.NEED_COLLECT, {
    is: true,
    then: (scheme) => scheme.required(VALIDATIONS_ERROR.REQUIRED),
    otherwise: (scheme) => scheme.notRequired(),
  }),
  [FIELDS_NAME.ADDRESS_NOTES]: Yup.string().notRequired(),
  [FIELDS_NAME.TIME_WINDOW_ALL_DAY]: Yup.boolean(),
  [FIELDS_NAME.TIME_WINDOW_ONE_FROM]: validationTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_ONE_TO]: validationTimeWindowDate,
  [FIELDS_NAME.TIME_WINDOW_SECOND_FROM]: Yup.date().nullable().notRequired(),
  [FIELDS_NAME.TIME_WINDOW_SECOND_TO]: Yup.date().nullable().notRequired(),
});

import { ReactElement, RefAttributes } from 'react';
import { DateTimePickerProps, DateTimePicker } from '@mui/x-date-pickers';

import { useSubscriptionDataRetentionMinDate } from '~hooks/index';

const SubscriptionDataRetentionMinDateTimePicker = ({
  minDate: defaultMinDate,
  ...rest
}: DateTimePickerProps<Date, Date> &
  RefAttributes<HTMLDivElement>): ReactElement => {
  const minDate = useSubscriptionDataRetentionMinDate(defaultMinDate);

  return <DateTimePicker {...rest} minDate={minDate} />;
};

export default SubscriptionDataRetentionMinDateTimePicker;

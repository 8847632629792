import { useCallback } from 'react';
import { map, isFunction } from 'lodash';

import { TableColumn, TableValidRow } from '../types';
import { UseTableBodyCellProps } from '../Body/useTableBody';

interface UseTableFooterReturn {
  getCellProps: (
    column: TableColumn<TableValidRow>,
    columnIndex: number,
  ) => UseTableBodyCellProps;
}

export const useTableFooter = (data: TableValidRow[]): UseTableFooterReturn => {
  const getColumnData = useCallback(
    (dataField: string) => map(data, dataField),
    [data],
  );

  const getCellClasses = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) => {
      const columnData = getColumnData(column.dataField);

      return isFunction(column.classes?.footer)
        ? column.classes?.footer(column, columnIndex, columnData)
        : column.classes?.footer;
    },
    [getColumnData],
  );

  const getCellStyle = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) => {
      const columnData = getColumnData(column.dataField);

      return isFunction(column.style?.footer)
        ? column.style?.footer(column, columnIndex, columnData)
        : column.style?.footer;
    },
    [getColumnData],
  );

  const getCellAlign = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) => {
      const columnData = getColumnData(column.dataField);

      return isFunction(column.align?.footer)
        ? column.align?.footer(column, columnIndex, columnData)
        : column.align?.footer;
    },
    [getColumnData],
  );

  const getCellContent = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) => {
      const columnData = getColumnData(column.dataField);

      return column.formatters?.footer
        ? column.formatters?.footer(column, columnIndex, columnData)
        : column.text;
    },
    [getColumnData],
  );

  const getCellProps = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) => ({
      className: getCellClasses(column, columnIndex),
      style: getCellStyle(column, columnIndex),
      align: getCellAlign(column, columnIndex),
      children: getCellContent(column, columnIndex),
    }),
    [getCellAlign, getCellClasses, getCellContent, getCellStyle],
  );

  return { getCellProps };
};

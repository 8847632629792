import { styled, Card, CardContent, Box } from '@mui/material';

import { SatisfactionRateInfoRateVariants } from './types';

export const SatisfactionRateInfoContainer = styled(Card)({
  display: 'flex',
});

export const SatisfactionRateInfoCardContent = styled(CardContent)(
  ({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(0.5),
    padding: theme.spacing(1, 2),
  }),
);

export const SatisfactionRateInfoRateContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: SatisfactionRateInfoRateVariants }>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minWidth: 180,
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.grey[500],
  color: theme.palette.common.white,

  ...(variant !== 'default' && {
    backgroundColor: theme.palette[variant].main,
    color: theme.palette[variant].contrastText,
  }),
}));

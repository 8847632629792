import { ReactElement, ReactNode, useCallback } from 'react';
import { TextField, MenuItem, ListItemText } from '@mui/material';

import { RolesFieldProps } from './types';
import { ROLES_FIELD_INFO, getRoleFieldLabel } from './utils';

const RolesField = (props: RolesFieldProps): ReactElement => {
  const renderValue = useCallback((selectedRole: unknown): ReactNode => {
    const currentValue = getRoleFieldLabel(Number(selectedRole));

    return currentValue;
  }, []);

  return (
    <TextField
      {...props}
      select
      SelectProps={{
        renderValue,
        MenuProps: {
          PaperProps: {
            style: {
              width: 250,
            },
          },
        },
      }}
    >
      {ROLES_FIELD_INFO.map((role) => (
        <MenuItem
          key={`${role}-${role.roleId}`}
          value={String(role.roleId)}
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          <ListItemText
            primary={role.label}
            secondary={role.scopeDescription}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </MenuItem>
      ))}
    </TextField>
  );
};

export default RolesField;

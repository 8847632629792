import { styled, CardContent, Typography } from '@mui/material';

export const ReportWidgetDataCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',

  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },
}));

export const ReportWidgetDataImg = styled('img')({
  height: 60,
  width: 90,
});

export const ReportWidgetDataDescriptionText = styled(Typography)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.tertiary.dark,
  }),
);

export const ReportWidgetDataDataText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(26),
}));

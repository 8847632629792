import {
  ChangeEvent,
  useCallback,
  useEffect,
  RefObject,
  useState,
} from 'react';
import moment from 'moment';
import { reduce, filter, isArray, includes, isEqual, has } from 'lodash';

import { useParseLocationQuery, useFiltersValues } from '~hooks/index';
import {
  getRangesDatesForNow,
  getItemValue,
  formatterDate,
} from '~utils/index';

import { Nullable, SelectOption } from '~globals/types';
import { DriverCompanyBase } from '~services/driver/types';
import { VehicleBase } from '~services/vehicle/types';
import { RoutesContextTypeView } from '~modules/Routes/RoutesContext';
import { FiltersValuesReturn } from '~hooks/useFiltersValues';
import { RouteFilter } from '~services/route/types';
import { RouteStateTypes } from '~globals/types/enums';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';

interface RouteListQuerParamsFilters {
  dateFrom?: Nullable<Date>;
  dateTo?: Nullable<Date>;
  routeCode?: string;
  drivers?: string[] | string;
  vehicles?: string[] | string;
  states?: RouteStateTypes[] | RouteStateTypes;
  allDates?: boolean;
}

export interface RoutesListFilters {
  dateFrom: Nullable<Date>;
  dateTo: Nullable<Date>;
  routeCode: string;
  drivers: DriverCompanyBase[];
  vehicles: VehicleBase[];
  states: SelectOption[];
  allDates: boolean;
}

interface RoutesListParams {
  typeView: RoutesContextTypeView;
  driversList: {
    list: DriverCompanyBase[];
    loading: boolean;
    refSelect: RefObject<CustomAutocompleteRef<DriverCompanyBase, true>>;
  };
  vehiclesList: {
    list: VehicleBase[];
    loading: boolean;
    refSelect: RefObject<CustomAutocompleteRef<VehicleBase, true>>;
  };
  statesList: {
    list: SelectOption[];
    refSelect: RefObject<CustomAutocompleteRef<SelectOption, true>>;
  };
}

interface RoutesListReturn extends FiltersValuesReturn<RoutesListFilters> {
  parseFiltersRequest: (
    includeApiTransaction?: boolean,
  ) => RouteFilter<boolean | undefined>;
}

type RoutesListFiltersAppliedType = 'queryParams' | 'filters';

type RoutesListFiltersApplied = Record<
  keyof RoutesListFilters,
  RoutesListFiltersAppliedType
>;

const PREVIOUS_DAYS = 3;
const AFTER_DAYS = 3;

const { start: startDate, end: endDate } = getRangesDatesForNow(
  PREVIOUS_DAYS,
  AFTER_DAYS,
);

export const initialFilters: RoutesListFilters = {
  dateFrom: startDate,
  dateTo: endDate,
  routeCode: '',
  drivers: [],
  vehicles: [],
  states: [],
  allDates: false,
};

const defaultRouteStateTypesId = [
  RouteStateTypes.New,
  RouteStateTypes.Assigned,
  RouteStateTypes.Approve,
  RouteStateTypes.Rejected,
  RouteStateTypes.InProgress,
  RouteStateTypes.Finalized,
  RouteStateTypes.Expired,
];

export const useRoutesListFilters = ({
  typeView,
  driversList: {
    list: driversList,
    loading: loadingDriversList,
    refSelect: refSelectDriversList,
  },
  vehiclesList: {
    list: vehiclesList,
    loading: loadingVehiclesList,
    refSelect: refSelectVehiclesList,
  },
  statesList: { list: statesList, refSelect: refSelectStatesList },
}: RoutesListParams): RoutesListReturn => {
  const queryFilters = useParseLocationQuery<RouteListQuerParamsFilters>();

  const [filtersApplied, setFilterApplied] = useState<RoutesListFiltersApplied>(
    () => {
      const builder = reduce(
        initialFilters,
        (acc, _, key) => {
          const keyTyped = key as keyof RoutesListFilters;
          acc[keyTyped] = has(queryFilters, keyTyped)
            ? 'queryParams'
            : 'filters';

          return acc;
        },
        {} as RoutesListFiltersApplied,
      );

      return builder;
    },
  );

  const getFilterAppliedByKey = useCallback(
    <Key extends keyof RoutesListFiltersApplied>(
      key: Key,
    ): RoutesListFiltersApplied[Key] => getItemValue(filtersApplied, key),
    [filtersApplied],
  );

  const { filters, setFilterByKey, clearFilters } =
    useFiltersValues(initialFilters);

  const setFilterAppliedByKey = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      setFilterByKey(key, value);

      const keyTyped = key as keyof RoutesListFilters;

      setFilterApplied((prevFiltersApplied) => ({
        ...prevFiltersApplied,
        [keyTyped]: 'filters',
      }));
    },
    [setFilterByKey],
  );

  const handleChangeInputFilterApplied = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target ?? event.currentTarget;

      setFilterAppliedByKey(name, value);
    },
    [setFilterAppliedByKey],
  );

  const clearFiltersApplied = useCallback(() => {
    clearFilters();

    setFilterApplied({
      dateFrom: 'filters',
      dateTo: 'filters',
      routeCode: 'filters',
      drivers: 'filters',
      vehicles: 'filters',
      states: 'filters',
      allDates: 'filters',
    });
  }, [clearFilters, setFilterApplied]);

  const getValueQueryParams = useCallback(
    <Key extends keyof RouteListQuerParamsFilters>(
      key: Key,
    ): RouteListQuerParamsFilters[Key] => getItemValue(queryFilters, key),
    [queryFilters],
  );

  const getValueInitialFilters = useCallback(
    <Key extends keyof RoutesListFilters>(key: Key): RoutesListFilters[Key] =>
      getItemValue(initialFilters, key),
    [],
  );

  const getValueCurrentFilters = useCallback(
    <Key extends keyof RoutesListFilters>(key: Key): RoutesListFilters[Key] =>
      getItemValue(filters, key),
    [filters],
  );

  const setDateByQueryParam = useCallback(
    (key: 'dateFrom' | 'dateTo') => {
      const currentDateQueryParam = getValueQueryParams(key);
      const currentDateDefault = getValueInitialFilters(key);

      if (currentDateQueryParam) {
        const valDate = new Date(currentDateQueryParam);

        const parseValDate =
          valDate.toString() !== 'Invalid Date' ? valDate : currentDateDefault;

        setFilterByKey(key, parseValDate);
      }
    },
    [getValueQueryParams, getValueInitialFilters, setFilterByKey],
  );

  useEffect(() => {
    setDateByQueryParam('dateFrom');
  }, [setDateByQueryParam]);

  useEffect(() => {
    setDateByQueryParam('dateTo');
  }, [setDateByQueryParam]);

  const setRouteCodeByQueryParam = useCallback(() => {
    const currentRouteCodeQueryParam = getValueQueryParams('routeCode');
    const currentRouteCodeDefault = getValueInitialFilters('routeCode');

    const parseRouteCode =
      currentRouteCodeQueryParam || currentRouteCodeDefault;

    setFilterByKey('routeCode', parseRouteCode);
  }, [getValueQueryParams, getValueInitialFilters, setFilterByKey]);

  useEffect(() => {
    setRouteCodeByQueryParam();
  }, [setRouteCodeByQueryParam]);

  const setDriversByQueryParams = useCallback(() => {
    const currentDriversCodeQueryParam = getValueQueryParams('drivers');

    if (currentDriversCodeQueryParam && !loadingDriversList) {
      const filteredDrivers = filter(driversList, (currentDriver) => {
        const currentDriverId = currentDriver.id;

        if (isArray(currentDriversCodeQueryParam)) {
          return includes(currentDriversCodeQueryParam, currentDriverId);
        }

        return isEqual(currentDriversCodeQueryParam, currentDriverId);
      });

      if (filteredDrivers.length > 0) {
        setFilterByKey('drivers', filteredDrivers);
        refSelectDriversList.current?.setValue(filteredDrivers);
      }
    }
  }, [
    getValueQueryParams,
    loadingDriversList,
    driversList,
    setFilterByKey,
    refSelectDriversList,
  ]);

  useEffect(() => {
    setDriversByQueryParams();
  }, [setDriversByQueryParams]);

  const setAllDatesByQueryParam = useCallback(() => {
    const currentDateQueryParam = getValueQueryParams('allDates');

    if (currentDateQueryParam) {
      setFilterByKey('dateFrom', null);
      setFilterByKey('dateTo', null);
    }
  }, [getValueQueryParams, setFilterByKey]);

  useEffect(() => {
    setAllDatesByQueryParam();
  }, [setAllDatesByQueryParam]);

  const setVehiclesByQueryParams = useCallback(() => {
    const currentVehiclesCodeQueryParam = getValueQueryParams('vehicles');

    if (currentVehiclesCodeQueryParam && !loadingVehiclesList) {
      const filteredVehicles = filter(vehiclesList, (currentVehicle) => {
        const currentVehicleId = currentVehicle.id;

        if (isArray(currentVehiclesCodeQueryParam)) {
          return includes(currentVehiclesCodeQueryParam, currentVehicleId);
        }

        return isEqual(currentVehiclesCodeQueryParam, currentVehicleId);
      });

      if (filteredVehicles.length > 0) {
        setFilterByKey('vehicles', filteredVehicles);
        refSelectVehiclesList.current?.setValue(filteredVehicles);
      }
    }
  }, [
    getValueQueryParams,
    loadingVehiclesList,
    vehiclesList,
    setFilterByKey,
    refSelectVehiclesList,
  ]);

  useEffect(() => {
    setVehiclesByQueryParams();
  }, [setVehiclesByQueryParams]);

  const setStatesByQueryParams = useCallback(() => {
    const currentStatesCodeQueryParam = getValueQueryParams('states');

    if (currentStatesCodeQueryParam) {
      const filteredStates = filter(statesList, (currentState) => {
        const currentStateId = currentState.value;

        if (isArray(currentStatesCodeQueryParam)) {
          return includes(currentStatesCodeQueryParam, currentStateId);
        }

        return isEqual(currentStatesCodeQueryParam, currentStateId);
      });

      if (filteredStates.length > 0) {
        setFilterByKey('states', filteredStates);
        refSelectStatesList.current?.setValue(filteredStates);
      }
    }
  }, [getValueQueryParams, statesList, setFilterByKey, refSelectStatesList]);

  useEffect(() => {
    setStatesByQueryParams();
  }, [setStatesByQueryParams]);

  const parseDateRequest = useCallback(
    (key: 'dateFrom' | 'dateTo') => {
      const currentAllDateQueryParam = getValueQueryParams('allDates');

      if (currentAllDateQueryParam) return null;

      const currentTypeDateFilterAppliedCurrentDate =
        getFilterAppliedByKey(key);

      const currentDateQueryParam = getValueQueryParams(key);
      const currentDateFilter = getValueCurrentFilters(key);

      const currentDate =
        currentTypeDateFilterAppliedCurrentDate === 'queryParams'
          ? currentDateQueryParam
          : currentDateFilter;

      const formatDate = formatterDate(currentDate, {
        format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
      });

      return formatDate || null;
    },
    [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters],
  );

  const parseRouteCodeRequest = useCallback(() => {
    const currentTypeDateFilterAppliedRouteCode =
      getFilterAppliedByKey('routeCode');

    const currentRouteCodeQueryParam = getValueQueryParams('routeCode');
    const currentRouteCodeFilter = getValueCurrentFilters('routeCode');

    const currentRouteCode =
      currentTypeDateFilterAppliedRouteCode === 'queryParams'
        ? currentRouteCodeQueryParam
        : currentRouteCodeFilter;

    return currentRouteCode || null;
  }, [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters]);

  const parseDriversRequest = useCallback(() => {
    const currentTypeDateFilterAppliedDrivers =
      getFilterAppliedByKey('drivers');

    const currentDriversQueryParam = getValueQueryParams('drivers');
    const currentDriversFilter = getValueCurrentFilters('drivers');

    let currentDrivers: Nullable<string[]> = null;

    if (
      currentTypeDateFilterAppliedDrivers === 'queryParams' &&
      currentDriversQueryParam
    ) {
      currentDrivers = isArray(currentDriversQueryParam)
        ? currentDriversQueryParam
        : [currentDriversQueryParam];
    }

    if (
      currentTypeDateFilterAppliedDrivers === 'filters' &&
      currentDriversFilter.length > 0
    ) {
      currentDrivers = currentDriversFilter.map(
        (currentDriver) => currentDriver.id,
      );
    }

    return currentDrivers;
  }, [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters]);

  const parseVehiclesRequest = useCallback(() => {
    const currentTypeDateFilterAppliedVehicles =
      getFilterAppliedByKey('vehicles');

    const currentVehiclesQueryParam = getValueQueryParams('vehicles');
    const currentVehiclesFilter = getValueCurrentFilters('vehicles');

    let currentVehicles: Nullable<string[]> = null;

    if (
      currentTypeDateFilterAppliedVehicles === 'queryParams' &&
      currentVehiclesQueryParam
    ) {
      currentVehicles = isArray(currentVehiclesQueryParam)
        ? currentVehiclesQueryParam
        : [currentVehiclesQueryParam];
    }

    if (
      currentTypeDateFilterAppliedVehicles === 'filters' &&
      currentVehiclesFilter.length > 0
    ) {
      currentVehicles = currentVehiclesFilter.map(
        (currentVehicle) => currentVehicle.id,
      );
    }

    return currentVehicles;
  }, [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters]);

  const parseStatesRequest = useCallback(() => {
    const currentTypeDateFilterAppliedStates = getFilterAppliedByKey('states');

    const currentStatesQueryParam = getValueQueryParams('states');
    const currentStatesFilter = getValueCurrentFilters('states');

    let currentStates: RouteStateTypes[] = defaultRouteStateTypesId;

    if (
      currentTypeDateFilterAppliedStates === 'queryParams' &&
      currentStatesQueryParam
    ) {
      currentStates = isArray(currentStatesQueryParam)
        ? currentStatesQueryParam
        : [currentStatesQueryParam];
    }

    if (
      currentTypeDateFilterAppliedStates === 'filters' &&
      currentStatesFilter.length > 0
    ) {
      currentStates = currentStatesFilter.map(
        (currentState) => currentState.value as RouteStateTypes,
      );
    }

    return currentStates;
  }, [getFilterAppliedByKey, getValueQueryParams, getValueCurrentFilters]);

  const parseFiltersRequest = useCallback(
    (includeApiTransaction?: boolean): RouteFilter<boolean | undefined> => ({
      dateFrom: parseDateRequest('dateFrom'),
      dateTo: parseDateRequest('dateTo'),
      routeCode: parseRouteCodeRequest(),
      driversId: parseDriversRequest(),
      vehiclesId: parseVehiclesRequest(),
      routeStateTypesId: parseStatesRequest(),
      includeApiTransaction: includeApiTransaction ?? typeView === 'map',
    }),
    [
      parseDateRequest,
      parseRouteCodeRequest,
      parseDriversRequest,
      parseVehiclesRequest,
      parseStatesRequest,
      typeView,
    ],
  );

  return {
    filters,
    setFilterByKey: setFilterAppliedByKey,
    handleChangeInputFilter: handleChangeInputFilterApplied,
    clearFilters: clearFiltersApplied,
    parseFiltersRequest,
  };
};

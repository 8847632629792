import { styled, Card, CardContent, Button } from '@mui/material';

export const DriverCompanyInviteCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
}));

export const DriverCompanyInviteCardContent = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(3, 2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',

    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  }),
);

export const DriverCompanyInviteCardActionsButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.paper,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.45)',
    },
  }),
);

import {
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useState,
  useCallback,
  useImperativeHandle,
  Fragment,
} from 'react';
import {
  Autocomplete,
  AutocompleteValue,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@mui/material';

import { CustomAutocompleteProps } from './types';

function CustomAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
): ReactElement {
  const {
    id,
    name,
    loading,
    loadingText = 'Cargando...',
    noOptionsText = 'Sin opciones',
    disabled,
    readOnly,
    label,
    error,
    helperText,
    onChange: onChangeProps,
    onInputChange: onInputChangeProps,
    innerRef,
    multiple,
    defaultValue = (props.multiple ? [] : null) as AutocompleteValue<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >,
    startAdornment,
    ...rest
  } = props;

  const [value, setValue] =
    useState<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>(
      defaultValue,
    );
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    (
      event: SyntheticEvent,
      newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T>,
    ) => {
      setValue(newValue);

      onChangeProps?.(event, newValue, reason, details);
    },
    [onChangeProps],
  );

  const handleInputChange = useCallback(
    (
      event: SyntheticEvent,
      newValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      setInputValue(newValue);

      onInputChangeProps?.(event, newValue, reason);
    },
    [onInputChangeProps],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): ReactNode => (
      <TextField
        {...params}
        name={name}
        label={label}
        InputLabelProps={{
          ...params.InputLabelProps,
          disabled: disabled || readOnly,
        }}
        error={error}
        helperText={helperText}
        InputProps={{
          ...params.InputProps,
          disabled: disabled || readOnly,
          startAdornment: startAdornment
            ? startAdornment(value)
            : params.InputProps.startAdornment,
          endAdornment: (
            <Fragment>
              {loading && (
                <InputAdornment
                  position="end"
                  sx={{ marginRight: 4, height: 'auto' }}
                >
                  <CircularProgress color="primary" size={20} />
                </InputAdornment>
              )}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
        }}
      />
    ),
    [
      label,
      disabled,
      readOnly,
      error,
      helperText,
      loading,
      name,
      startAdornment,
      value,
    ],
  );

  const resetAutocomplete = useCallback(() => {
    setValue(defaultValue);
    setInputValue('');
  }, [defaultValue]);

  useImperativeHandle(
    innerRef,
    () => ({ resetAutocomplete, setValue, setInputValue }),
    [resetAutocomplete, setValue, setInputValue],
  );

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      defaultValue={defaultValue}
      disabled={disabled || readOnly}
      loading={loading}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      renderInput={renderInput}
      value={value}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
}

export default CustomAutocomplete;

import { styled, Box, Typography } from '@mui/material';

interface IntegrationTypeInfoCommonComponentProps {
  size: number;
}

export const IntegrationTypeInfoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'size',
})<IntegrationTypeInfoCommonComponentProps>(({ theme, size }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  fontSize: theme.typography.pxToRem(size),
}));

export const IntegrationTypeInfoLogo = styled('img', {
  shouldForwardProp: (prop) => prop !== 'size',
})<IntegrationTypeInfoCommonComponentProps>(({ size }) => ({
  width: size * 1.7,
  height: size * 1.7,
  borderRadius: '50%',
}));

export const IntegrationTypeInfoTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

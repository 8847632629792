import { styled, Chip, chipClasses } from '@mui/material';

export const SubscriptionPlanInfoChipRoot = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{ customColor: string }>(({ theme, customColor }) => ({
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(11),
  height: 24,
  borderRadius: 24 / 2,
  backgroundColor: customColor,
  color: theme.palette.common.white,

  [`&.${chipClasses.clickable}:hover`]: {
    backgroundColor: customColor,
    color: theme.palette.common.white,
  },

  [`&.${chipClasses.sizeSmall}`]: {
    fontSize: theme.typography.pxToRem(9),
    height: 16,
  },
}));

import { ReactElement } from 'react';
import { Stack } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';

import { RangeTimePickerProps } from './types';

const RangeTimePicker = ({
  fromProps,
  toProps,
  containerProps,
}: RangeTimePickerProps): ReactElement => {
  return (
    <Stack spacing={2} direction="row" alignItems="center" {...containerProps}>
      <TimePicker label="Desde" {...fromProps} />

      <TimePicker label="Hasta" {...toProps} />
    </Stack>
  );
};

export default RangeTimePicker;

import {
  ReactElement,
  RefObject,
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { find } from 'lodash';

import { useLocalStorage } from '~hooks/index';
import { isEqualArrays } from '~utils/commons';

import { ColumnToggleProviderProps } from './types';
import ColumnsHideableToggle from './ColumnsHideableToggle';

type ColumnsToggleModel = {
  [dataField: string]: boolean;
};

interface ColumnToggleContextValue {
  refTable: RefObject<BootstrapTable>;
  toggleModel: ColumnsToggleModel | undefined;
  setToggleModel: (newModel: ColumnsToggleModel) => void;
  columns: ColumnDescription[];
  excludeHideableFieldColumns: string[];
}

const ColumnToggleContext = createContext<ColumnToggleContextValue>({
  refTable: { current: null },
  toggleModel: undefined,
  setToggleModel: () => {},
  columns: [],
  excludeHideableFieldColumns: [],
});

export const useColumnsToggleContext = (): ColumnToggleContextValue =>
  useContext(ColumnToggleContext);

const initializeToggleModel = (
  columns: ColumnDescription[],
): ColumnsToggleModel =>
  columns.reduce((acc, column) => {
    if (column.dataField !== 'actions') {
      acc[column.dataField] = column.hidden ?? false;
    }
    return acc;
  }, {} as ColumnsToggleModel);

const initializeColumnToggleList = (
  columns: ColumnDescription[],
): ColumnDescription[] => {
  const columnAction = find(columns, { dataField: 'actions' });

  if (columnAction) {
    columnAction.headerFormatter = () => <ColumnsHideableToggle />;
  } else {
    columns.push({
      dataField: 'actions',
      isDummyField: true,
      text: '',
      classes: 'truncated-two-lines',
      headerFormatter: () => <ColumnsHideableToggle />,
      headerStyle: { width: 60 },
    });
  }

  return columns;
};

const ColumnToggleProvider = ({
  keyField,
  columns: columnsProps = [],
  data = [],
  bootstrap4 = true,
  toggleHideableColumns,
  excludeHideableFieldColumns = [],
  hideableStorageKey,
  children,
}: ColumnToggleProviderProps): ReactElement => {
  const refTable = useRef<BootstrapTable>(null);

  const { state: toggleModel, set: setToggleModel } =
    useLocalStorage<ColumnsToggleModel>({
      key: hideableStorageKey,
      initialValue: initializeToggleModel(columnsProps),
    });

  const hasNewColumns = useCallback(() => {
    if (toggleModel) {
      const columnsKeys = columnsProps
        .filter((column) => column.dataField !== 'actions')
        .map((column) => column.dataField);

      const toggleModelKeys = Object.keys(toggleModel);

      return !isEqualArrays(columnsKeys, toggleModelKeys);
    }

    return true;
  }, [columnsProps, toggleModel]);

  useLayoutEffect(() => {
    if (hasNewColumns()) {
      setToggleModel({
        ...initializeToggleModel(columnsProps),
        ...toggleModel,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(() => {
    if (toggleHideableColumns) {
      const transformColumns = initializeColumnToggleList(columnsProps).map(
        (column) => ({
          ...column,
          hidden: toggleModel?.[column.dataField] ?? column.hidden,
        }),
      );

      return transformColumns;
    }

    return columnsProps;
  }, [columnsProps, toggleHideableColumns, toggleModel]);

  return (
    <ColumnToggleContext.Provider
      value={{
        refTable,
        toggleModel,
        setToggleModel,
        columns,
        excludeHideableFieldColumns,
      }}
    >
      {children({ keyField, columns, data, bootstrap4, ref: refTable })}
    </ColumnToggleContext.Provider>
  );
};

export default ColumnToggleProvider;

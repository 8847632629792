import { styled, Dialog, dialogClasses, IconButton } from '@mui/material';

export const DialogResendInvitationPartnerCompanyContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 600,
    width: '100%',
  },
});

export const DialogResendInvitationPartnerCompanyTitle = styled('div')(
  ({ theme }) => ({
    flex: 'none',
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

export const DialogResendInvitationPartnerCompanyTitleCloseIcon = styled(
  IconButton,
)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

import { ReactElement } from 'react';

import { faDolly as WarouseIcon } from '@fortawesome/free-solid-svg-icons';

import { MarkerWarehouseProps } from './types';
import {
  MarkerWarehouseContainer,
  MarkerWarehouseIconMarker,
  MarkerWarehouseIconWarehouseContainer,
  MarkerWarehouseIconWarehouse,
} from './styles';

const MarkerWarehouse = ({
  color,
  includeBackgroundColor = false,
}: MarkerWarehouseProps): ReactElement => (
  <MarkerWarehouseContainer color={color}>
    <MarkerWarehouseIconMarker currentColor={color} />

    <MarkerWarehouseIconWarehouseContainer
      currentColor={includeBackgroundColor ? color : undefined}
    >
      <MarkerWarehouseIconWarehouse
        icon={WarouseIcon}
        currentColor={includeBackgroundColor ? color : undefined}
      />
    </MarkerWarehouseIconWarehouseContainer>
  </MarkerWarehouseContainer>
);

export default MarkerWarehouse;

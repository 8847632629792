import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth';
import plansReducer from './plans';
import homeReducer from './home';

const rootReducers = combineReducers({
  auth: authReducer,
  plans: plansReducer,
  home: homeReducer,
});

export default rootReducers;

import { ReactElement, useMemo, useCallback } from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserTie as DriverIcon,
  faGauge as SpeedIcon,
  faBatteryThreeQuarters as BatteryIcon,
} from '@fortawesome/free-solid-svg-icons';
import { isUndefined, isNull, round } from 'lodash';
import moment from 'moment-timezone';

import { useUtcOffset } from '~hooks/index';

import { VehiclePopupMapProps } from './types';
import {
  VehiclePopupMapContainer,
  VehiclePopupMapTitle,
  VehiclePopupMapRowItem,
  VehiclePopupMapIconContainer,
} from './styles';

const VehiclePopupMap = ({
  vehicle,
  driver,
}: VehiclePopupMapProps): ReactElement => {
  const formatterDateUtcOffset = useUtcOffset();

  const title = useMemo(() => {
    let output = vehicle.name;

    if (vehicle.referenceCode) {
      output += ` (${vehicle.referenceCode})`;
    }

    return output;
  }, [vehicle]);

  const getLastUpatedInfo = useCallback(
    (lastDate: string) => {
      const currentDate = moment();
      const lastUpdatedDate = formatterDateUtcOffset(
        lastDate,
        moment.HTML5_FMT.DATETIME_LOCAL,
      );

      const diffInSeconds = currentDate.diff(lastUpdatedDate, 'seconds');

      const timeDuration = moment.duration(diffInSeconds, 'seconds');

      return `Actualizado hace ${timeDuration.humanize()}`;
    },
    [formatterDateUtcOffset],
  );

  return (
    <VehiclePopupMapContainer>
      <VehiclePopupMapTitle>{title}</VehiclePopupMapTitle>

      {!isUndefined(vehicle.speed) && !isNull(vehicle.speed) && (
        <VehiclePopupMapRowItem>
          <VehiclePopupMapIconContainer>
            <FontAwesomeIcon icon={SpeedIcon} />
          </VehiclePopupMapIconContainer>

          <Typography variant="inherit">{`${round(
            vehicle.speed,
          )} KM/h`}</Typography>
        </VehiclePopupMapRowItem>
      )}

      <VehiclePopupMapRowItem>
        <VehiclePopupMapIconContainer>
          <FontAwesomeIcon icon={DriverIcon} />
        </VehiclePopupMapIconContainer>

        <Typography variant="inherit">{driver.name}</Typography>
      </VehiclePopupMapRowItem>

      {!isUndefined(driver.battery) && !isNull(driver.battery) && (
        <VehiclePopupMapRowItem>
          <VehiclePopupMapIconContainer>
            <FontAwesomeIcon icon={BatteryIcon} />
          </VehiclePopupMapIconContainer>

          <Typography variant="inherit">{`${
            driver.battery * 100
          } %`}</Typography>
        </VehiclePopupMapRowItem>
      )}

      {driver.lastGeoReferenceDateTime && (
        <VehiclePopupMapRowItem>
          <Typography variant="inherit" color="text.disabled">
            {getLastUpatedInfo(driver.lastGeoReferenceDateTime)}
          </Typography>
        </VehiclePopupMapRowItem>
      )}
    </VehiclePopupMapContainer>
  );
};

export default VehiclePopupMap;

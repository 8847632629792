import {
  ReactElement,
  SyntheticEvent,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Chip,
  AppBar,
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { isUndefined, isNull } from 'lodash';

import { NewFeatureBagde } from '~components/index';
import { useRequest } from '~hooks/index';
import { getFeedbackRouteItem } from '~services/route';
import { RouteItemFeedback } from '~services/route/types';
import { Nullable } from '~globals/types';
import { RouteItemStateType } from '~globals/types/enums';
import { isRouteStopOrderFinalized } from '~utils/route';

import {
  DialogFeedbackStopProps,
  DialogFeedbackStopStateTypeList,
  DialogFeedbackStopActiveTab,
  DialogFeedbackStopTabsList,
  DialogFeedbackStopTabsListPanel,
} from './types';
import DialogFeedbackStopProvider from './DialogFeedbackStopContext';
import {
  DialogFeebackStopData,
  DialogFeebackStopFeedback,
  DialogFeebackStopArticles,
  DialogFeebackStopOrders,
} from './Tabs';
import {
  DialogFeedbackStopContainer,
  DialogFeedbackStopLoadingContainer,
  DialogFeedbackStopTitle,
  DialogFeedbackStopContentContainer,
  DialogFeedbackStopTab,
  DialogFeedbackStopTabsPanelContainer,
  DialogFeedbackStopTabPanel,
} from './styles';

const routeItemFeedbackState: DialogFeedbackStopStateTypeList = {
  [RouteItemStateType.New]: { type: 'default', description: 'Nuevo' },
  [RouteItemStateType.OnAgenda]: { type: 'default', description: 'En agenda' },
  [RouteItemStateType.InProgress]: {
    type: 'default',
    description: 'En proceso',
  },
  [RouteItemStateType.FinalizedSuccess]: {
    type: 'success',
    description: 'Visita completa',
  },
  [RouteItemStateType.FinalizedError]: {
    type: 'error',
    description: 'Visita incompleta',
  },
  [RouteItemStateType.WithoutVisiting]: {
    type: 'warning',
    description: 'Sin visitar',
  },
};

const DialogFeedbackStop = ({
  open,
  onClose,
  stopId,
  enabledFeebackPanel = true,
  mapRef,
  enabledChipTitle = true,
  currentTab,
  isCollect: isCollectProp,
}: DialogFeedbackStopProps): ReactElement => {
  const [activeTab, setActiveTab] = useState<DialogFeedbackStopActiveTab>(
    currentTab ?? 'data',
  );

  const [feedbackData, setFeebackData] =
    useState<Nullable<RouteItemFeedback>>(null);

  const [, loadingFeeback, , reloadRequest] = useRequest({
    request: getFeedbackRouteItem,
    payload: stopId,
    withPostSuccess: (response) => {
      const feedbackResponse = response.data?.data as RouteItemFeedback;

      if (
        isRouteStopOrderFinalized(
          feedbackResponse.routeItem.routeItemStateTypeId,
        ) &&
        !currentTab
      ) {
        setActiveTab('feedback');
      }

      setFeebackData(feedbackResponse);
    },
  });

  const reloadData = useCallback(() => {
    reloadRequest(stopId);
  }, [reloadRequest, stopId]);

  const isWarehouse = useMemo(
    () => !!feedbackData?.routeItem.warehouseId,
    [feedbackData],
  );

  const isCollect = useMemo(() => !!isCollectProp, [isCollectProp]);

  const tabsList = useMemo(
    (): DialogFeedbackStopTabsList[] => [
      {
        id: 'data',
        label: 'Datos de parada',
        enabled: true,
        newActiveForVersion: '',
      },
      {
        id: 'feedback',
        label: 'Detalles de visita',
        enabled: enabledFeebackPanel,
        newActiveForVersion: '',
      },
      {
        id: 'articles',
        label: 'Artículos / Tareas',
        enabled: !isWarehouse && !isCollect,
        newActiveForVersion: '1.14.0',
      },
      {
        id: 'orders',
        label: 'Pedidos',
        enabled: !isWarehouse && isCollect,
        newActiveForVersion: '',
      },
    ],
    [enabledFeebackPanel, isWarehouse, isCollect],
  );

  const tabsListPanels = useMemo(
    (): DialogFeedbackStopTabsListPanel[] => [
      {
        id: tabsList[0].id,
        panel: DialogFeebackStopData,
        enabled: true,
      },
      {
        id: tabsList[1].id,
        panel: DialogFeebackStopFeedback,
        enabled: enabledFeebackPanel,
      },
      {
        id: tabsList[2].id,
        panel: DialogFeebackStopArticles,
        enabled: !isWarehouse && !isCollect,
      },
      {
        id: tabsList[3].id,
        panel: DialogFeebackStopOrders,
        enabled: !isWarehouse && isCollect,
      },
    ],
    [tabsList, enabledFeebackPanel, isWarehouse, isCollect],
  );

  const handleChangeActiveTab = useCallback(
    (_e: SyntheticEvent, newValue: DialogFeedbackStopActiveTab) => {
      setActiveTab(newValue);
    },
    [],
  );

  const routeItemStateTypeId = useMemo(
    () => feedbackData?.routeItem.routeItemStateTypeId ?? null,
    [feedbackData],
  );

  const badgeTitleInfo = useMemo(
    () =>
      !isUndefined(routeItemStateTypeId) && !isNull(routeItemStateTypeId)
        ? routeItemFeedbackState[routeItemStateTypeId]
        : null,
    [routeItemStateTypeId],
  );

  return (
    <DialogFeedbackStopProvider
      value={{
        data: feedbackData,
        routeItemStateTypeId,
        reloadData,
        mapRef,
        onClose,
      }}
    >
      <DialogFeedbackStopContainer open={open} maxWidth={false}>
        {loadingFeeback && (
          <DialogFeedbackStopLoadingContainer>
            <CircularProgress color="primary" disableShrink size={80} />
          </DialogFeedbackStopLoadingContainer>
        )}

        {!loadingFeeback && (
          <>
            <DialogFeedbackStopTitle>
              <Typography component="h2" variant="h6">
                {feedbackData?.routeItem.warehouse?.title ??
                  feedbackData?.routeItem.item?.title}
              </Typography>

              {enabledChipTitle && badgeTitleInfo && (
                <Chip
                  variant="filled"
                  color={badgeTitleInfo.type}
                  label={badgeTitleInfo.description}
                  size="small"
                  sx={{ color: 'common.white', textTransform: 'uppercase' }}
                />
              )}
            </DialogFeedbackStopTitle>

            <DialogFeedbackStopContentContainer>
              <TabContext value={activeTab}>
                <AppBar position="sticky" color="tertiary" elevation={0}>
                  <TabList
                    onChange={handleChangeActiveTab}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    {tabsList.map(
                      ({ label, id, enabled, newActiveForVersion }) =>
                        enabled && (
                          <DialogFeedbackStopTab
                            key={`dialog-feeback-stop-tab-${id}`}
                            label={label}
                            value={id}
                            icon={
                              <NewFeatureBagde
                                activeForVersion={newActiveForVersion}
                              />
                            }
                            iconPosition="end"
                          />
                        ),
                    )}
                  </TabList>
                </AppBar>

                <DialogFeedbackStopTabsPanelContainer>
                  {tabsListPanels.map(
                    ({ id, panel: Panel, enabled }) =>
                      enabled && (
                        <DialogFeedbackStopTabPanel
                          key={`dialog-feeback-stop-panel-${id}`}
                          value={id}
                        >
                          <Panel />
                        </DialogFeedbackStopTabPanel>
                      ),
                  )}
                </DialogFeedbackStopTabsPanelContainer>
              </TabContext>
            </DialogFeedbackStopContentContainer>

            <DialogActions>
              <Button color="secondary" variant="outlined" onClick={onClose}>
                Cerrar
              </Button>
            </DialogActions>
          </>
        )}
      </DialogFeedbackStopContainer>
    </DialogFeedbackStopProvider>
  );
};

export default DialogFeedbackStop;

import { ReactElement, memo } from 'react';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { UserInfoProps } from './types';
import {
  UserInfoContainer,
  UserInfoAvatar,
  UserInfoTypography,
} from './styles';

const UserInfo = ({
  name,
  urlAvatar = toAbsoluteUrl('/icons/icon_user.svg'),
  fontSize = 14,
  fontWeight,
}: UserInfoProps): ReactElement => (
  <UserInfoContainer size={fontSize}>
    <UserInfoAvatar size={fontSize} src={urlAvatar} alt={name} />

    <UserInfoTypography variant="inherit" fontWeight={fontWeight}>
      {name}
    </UserInfoTypography>
  </UserInfoContainer>
);

export default memo(UserInfo);

import { get } from 'lodash';

import { NotificationApiTransactionWorkProgressId } from '~globals/types/notifications';

import {
  StepperGenerateRouteDictionary,
  StepperGenerateRouteDictionaryCategory,
  StepperGenerateRouteType,
} from './types';

export const getSetenceByDictionary = (
  dictionary: StepperGenerateRouteDictionary,
  category: StepperGenerateRouteDictionaryCategory,
  type: StepperGenerateRouteType,
): string => get(dictionary, [category, type], '');

const SUCCESS_WORK_PROGRESS_ID: NotificationApiTransactionWorkProgressId = 3;
const FAIL_WORK_PROGRESS_ID: NotificationApiTransactionWorkProgressId = 4;

export const isSuccessWorkProgressId = (
  workProgressId: NotificationApiTransactionWorkProgressId,
): boolean => workProgressId === SUCCESS_WORK_PROGRESS_ID;

export const isFailWorkProgressId = (
  workProgressId: NotificationApiTransactionWorkProgressId,
): boolean => workProgressId === FAIL_WORK_PROGRESS_ID;

export const isCompletedWorkProgressId = (
  workProgressId: NotificationApiTransactionWorkProgressId,
): boolean =>
  isSuccessWorkProgressId(workProgressId) ||
  isFailWorkProgressId(workProgressId);

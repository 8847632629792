import {
  ReactElement,
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  TextFieldProps,
  AutocompleteRenderGetTagProps,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Stack,
  DialogContentText,
} from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import { AccessFeature, DialogConfimation } from '~components/index';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';
import { TimeWindowPickerRef } from '~components/TimeWindowPicker/types';
import { useLazyRequest, useSelectedRows, useToggle } from '~hooks/index';
import { editMultipleItems } from '~services/item';
import {
  SelectRowsHandlerOnSelectAll,
  SelectRowsHandlerOnSelect,
} from '~hooks/useDataTableSelectedProps';
import { DynamicDataTable } from '~globals/types';
import { PlanFeaturesTypes } from '~globals/types/enums';
import { hasError } from '~utils/formHelpers';

import DialogOrdersMultipeEditionTable from '../Table';
import DialogOrdersMultipeEditionPreviewModifications from '../PreviewModifications';

import { DialogOrdersMultipeEditionContentProps } from '../types';
import {
  FIELDS_NAME,
  EXTRACTOR_KEY_VALUE,
  initialValues,
  validationSchema,
  validateRowsChecked,
  getParseVisibleValues,
} from '../utils';
import {
  DialogOrdersMultipeEditionActions,
  DialogOrdersMultipeEditionChip,
} from '../styles';

const DialogOrdersMultipeEditionContent = ({
  onClose,
  selectedIds,
  skillsNeededOptions,
  onSuccessSubmit,
}: DialogOrdersMultipeEditionContentProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const skillsNeededAutocompleteRef =
    useRef<CustomAutocompleteRef<string, true>>(null);

  const timeWindowPickerRef = useRef<TimeWindowPickerRef>(null);

  const [openConfirmModifidyOrders, toggleOpenConfirmModifidyOrders] =
    useToggle();

  const {
    touched,
    errors,
    values,
    setFieldValue,
    setFieldTouched,
    getFieldProps,
    submitForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => toggleOpenConfirmModifidyOrders(true),
  });

  const handleChangeTimeWindowAllDay = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      setFieldValue(FIELDS_NAME.TIME_WINDOW_ALL_DAY, checked);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_FROM, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_TO, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_FROM, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_TO, null);

      if (checked) {
        timeWindowPickerRef.current?.handleDeleteSecondTimeWindow();
      }
    },
    [setFieldValue],
  );

  const handleClearTimeWindowTwo = useCallback(() => {
    setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_FROM, null);
    setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_TO, null);
  }, [setFieldValue]);

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFieldValue(fieldName, value);
      },
    [setFieldValue],
  );

  const handleBlurDate = useCallback(
    (fieldName: string) => (): void => {
      setFieldTouched(fieldName, true);
    },
    [setFieldTouched],
  );

  const renderInputPicker = useCallback(
    (props: TextFieldProps, nameField: string) => (
      <TextField
        {...props}
        autoComplete="off"
        fullWidth
        onBlur={handleBlurDate(nameField)}
        error={hasError(touched, errors, nameField)}
      />
    ),
    [handleBlurDate, touched, errors],
  );

  const handleChangeSkills = useCallback(
    (_e: SyntheticEvent<Element, Event>, newVals: string[]) => {
      setFieldValue(FIELDS_NAME.DELIVERY_SKILLS_NEEDED, newVals);
    },
    [setFieldValue],
  );

  const handleBlurSkills = useCallback(() => {
    setFieldTouched(FIELDS_NAME.DELIVERY_SKILLS_NEEDED, true);
  }, [setFieldTouched]);

  const renderTagsSkills = useCallback(
    (vals: string[], getTagsProps: AutocompleteRenderGetTagProps) =>
      vals.map((val, index) => (
        // eslint-disable-next-line react/jsx-key
        <DialogOrdersMultipeEditionChip
          label={val}
          size="small"
          {...getTagsProps({ index })}
        />
      )),
    [],
  );

  const rowsCheckedValue = useMemo(
    () => get(values, FIELDS_NAME.ROWS_CHECKED),
    [values],
  );

  const getDisabledRow = useCallback(
    (rowFieldName: string) =>
      !validateRowsChecked(rowsCheckedValue, rowFieldName),
    [rowsCheckedValue],
  );

  const isDisabledTimeWindowDates = useMemo(
    () =>
      getDisabledRow(FIELDS_NAME.TIME_WINDOW) ||
      get(values, FIELDS_NAME.TIME_WINDOW_ALL_DAY),
    [getDisabledRow, values],
  );

  const data = useMemo<DynamicDataTable[]>(
    () => [
      {
        id: FIELDS_NAME.CLIENT_NAME,
        component: 'Input',
        label: 'Nombre de contacto',
        componentProps: {
          label: 'Ingrese el valor',
          fullWidth: true,
          autoComplete: 'off',
          error: hasError(touched, errors, FIELDS_NAME.CLIENT_NAME),
          disabled: getDisabledRow(FIELDS_NAME.CLIENT_NAME),
          ...getFieldProps(FIELDS_NAME.CLIENT_NAME),
        },
      },
      {
        id: FIELDS_NAME.ADDRESS_WAIT_TIME,
        label: 'Tiempo estimado en parada (minutos)',
        component: 'Input',
        componentProps: {
          label: 'Ingrese el valor',
          fullWidth: true,
          autoComplete: 'off',
          type: 'number',
          error: hasError(touched, errors, FIELDS_NAME.ADDRESS_WAIT_TIME),
          disabled: getDisabledRow(FIELDS_NAME.ADDRESS_WAIT_TIME),
          ...getFieldProps(FIELDS_NAME.ADDRESS_WAIT_TIME),
        },
      },
      {
        id: FIELDS_NAME.TIME_WINDOW,
        label: 'Ventana horaria de visita',
        component: 'TimeWindowPicker',
        componentProps: {
          ref: timeWindowPickerRef,
          windowAllDayProps: {
            ...getFieldProps(FIELDS_NAME.TIME_WINDOW_ALL_DAY),
            checked: get(values, FIELDS_NAME.TIME_WINDOW_ALL_DAY),
            onChange: handleChangeTimeWindowAllDay,
            disabled: getDisabledRow(FIELDS_NAME.TIME_WINDOW),
          },
          windowOneProps: {
            fromProps: {
              value: get(values, FIELDS_NAME.TIME_WINDOW_ONE_FROM),
              onChange: handleChangeDate(FIELDS_NAME.TIME_WINDOW_ONE_FROM),
              disabled: isDisabledTimeWindowDates,
              renderInput: (props: TextFieldProps) =>
                renderInputPicker(props, FIELDS_NAME.TIME_WINDOW_ONE_FROM),
            },
            toProps: {
              value: get(values, FIELDS_NAME.TIME_WINDOW_ONE_TO),
              onChange: handleChangeDate(FIELDS_NAME.TIME_WINDOW_ONE_TO),
              disabled: isDisabledTimeWindowDates,
              renderInput: (props: TextFieldProps) =>
                renderInputPicker(props, FIELDS_NAME.TIME_WINDOW_ONE_TO),
            },
          },
          windowTwoProps: {
            fromProps: {
              value: get(values, FIELDS_NAME.TIME_WINDOW_SECOND_FROM),
              onChange: handleChangeDate(FIELDS_NAME.TIME_WINDOW_SECOND_FROM),
              disabled: isDisabledTimeWindowDates,
              renderInput: (props: TextFieldProps) =>
                renderInputPicker(props, FIELDS_NAME.TIME_WINDOW_SECOND_FROM),
            },
            toProps: {
              value: get(values, FIELDS_NAME.TIME_WINDOW_SECOND_TO),
              onChange: handleChangeDate(FIELDS_NAME.TIME_WINDOW_SECOND_TO),
              disabled: isDisabledTimeWindowDates,
              renderInput: (props: TextFieldProps) =>
                renderInputPicker(props, FIELDS_NAME.TIME_WINDOW_SECOND_TO),
            },
          },
          onClearWindowTwoProps: handleClearTimeWindowTwo,
          disabledActions: isDisabledTimeWindowDates,
        },
      },
      {
        id: FIELDS_NAME.DELIVERY_EXPIRATION_DATE,
        label: 'Fecha de vencimiento (Opcional)',
        component: 'DatePicker',
        componentProps: {
          label: 'Ingrese la fecha',
          value: get(values, FIELDS_NAME.DELIVERY_EXPIRATION_DATE),
          onChange: handleChangeDate(FIELDS_NAME.DELIVERY_EXPIRATION_DATE),
          disabled: getDisabledRow(FIELDS_NAME.DELIVERY_EXPIRATION_DATE),
          disablePast: true,
          renderInput: (props: TextFieldProps) =>
            renderInputPicker(props, FIELDS_NAME.DELIVERY_EXPIRATION_DATE),
        },
      },
      {
        id: FIELDS_NAME.DELIVERY_TAGS,
        component: 'Input',
        label: 'Grupo (Opcional)',
        componentProps: {
          label: 'Ingrese el valor',
          fullWidth: true,
          autoComplete: 'off',
          error: hasError(touched, errors, FIELDS_NAME.DELIVERY_TAGS),
          disabled: getDisabledRow(FIELDS_NAME.DELIVERY_TAGS),
          ...getFieldProps(FIELDS_NAME.DELIVERY_TAGS),
        },
      },
      {
        id: FIELDS_NAME.DELIVERY_SKILLS_NEEDED,
        label: 'Cargas especiales o atributos requeridos (Opcional)',
        component: 'Autocomplete',
        componentProps: {
          innerRef: skillsNeededAutocompleteRef,
          multiple: true,
          disableCloseOnSelect: true,
          filterSelectedOptions: true,
          renderTags: renderTagsSkills,
          disableClearable: true,
          options: skillsNeededOptions,
          label: 'Seleccione todas las opciones posibles',
          name: FIELDS_NAME.DELIVERY_SKILLS_NEEDED,
          onChange: handleChangeSkills,
          onBlur: handleBlurSkills,
          error: hasError(touched, errors, FIELDS_NAME.DELIVERY_SKILLS_NEEDED),
          fullWidth: true,
          getOptionLabel: (option: string) => option,
          disabled: getDisabledRow(FIELDS_NAME.DELIVERY_SKILLS_NEEDED),
        },
      },
    ],
    [
      errors,
      getFieldProps,
      handleBlurSkills,
      handleChangeTimeWindowAllDay,
      handleChangeDate,
      handleChangeSkills,
      renderInputPicker,
      renderTagsSkills,
      skillsNeededOptions,
      touched,
      values,
      getDisabledRow,
      isDisabledTimeWindowDates,
      handleClearTimeWindowTwo,
    ],
  );

  const selectableRows = useSelectedRows<DynamicDataTable>(
    EXTRACTOR_KEY_VALUE,
    [],
    [],
  );

  const onSelectAll = useCallback<
    SelectRowsHandlerOnSelectAll<DynamicDataTable>
  >(
    async (isSelected, rows, event) => {
      let rowsCheckedIds: string[] = [];

      if (isSelected) {
        const currentSelectedIds = rows.map((row) => row[EXTRACTOR_KEY_VALUE]);

        rowsCheckedIds = rowsCheckedValue.concat(currentSelectedIds);
      }

      setFieldValue(FIELDS_NAME.ROWS_CHECKED, rowsCheckedIds);

      selectableRows.handleOnSelectAll(
        isSelected,
        rows,
        event as SyntheticEvent,
      );
    },
    [setFieldValue, selectableRows, rowsCheckedValue],
  );

  const onSelect = useCallback<SelectRowsHandlerOnSelect<DynamicDataTable>>(
    (row, isSelected, rowIndex, event) => {
      const currentCheckedId = row[EXTRACTOR_KEY_VALUE];

      let rowsCheckedIds = rowsCheckedValue.filter(
        (currentId) => currentId !== currentCheckedId,
      );

      if (isSelected) {
        rowsCheckedIds = rowsCheckedValue.concat(currentCheckedId);
      }

      setFieldValue(FIELDS_NAME.ROWS_CHECKED, rowsCheckedIds);

      selectableRows.handleOnSelect(
        row,
        isSelected,
        rowIndex,
        event as SyntheticEvent,
      );
    },
    [rowsCheckedValue, selectableRows, setFieldValue],
  );

  const [, loadingModifyOrders, , executeModifyOrders] = useLazyRequest({
    request: editMultipleItems,
    withPostSuccess: () => {
      enqueueSnackbar('Pedidos modificados correctamente', {
        variant: 'success',
      });

      toggleOpenConfirmModifidyOrders(false);
      onClose();

      onSuccessSubmit?.();
    },
    withPostFailure: (err) => {
      let errorMessage =
        'No se pudieron modificar los pedidos, intenté nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const handleModifyOrders = useCallback(async () => {
    const visiblesValues = getParseVisibleValues(values);

    await executeModifyOrders({
      itemIds: selectedIds,
      ...visiblesValues,
    });
  }, [executeModifyOrders, selectedIds, values]);

  return (
    <>
      <DialogTitle>
        Edición múltiple de pedidos{' '}
        <AccessFeature.NextPlanChip
          validFeature={PlanFeaturesTypes.LimitNotPro}
          openAccessFeatureModalOnClick={false}
        />
      </DialogTitle>

      <DialogContent dividers>
        <AccessFeature.Alert validFeature={PlanFeaturesTypes.LimitNotPro} />

        <AccessFeature.Hidden
          validFeature={PlanFeaturesTypes.LimitNotPro}
          type="notAccess"
        >
          <Stack spacing={2}>
            <DialogContentText color="text.secondary">
              Seleccione los campos que desea modificar y establezca los nuevos
              valores. Los campos opcionales pueden dejarse en blanco para
              eliminar su contenido.
            </DialogContentText>

            <DialogOrdersMultipeEditionTable
              data={data}
              selectableRows={{
                selectedIds: selectableRows.selectedRowsIds,
                onSelectAll,
                onSelect,
              }}
            />
          </Stack>
        </AccessFeature.Hidden>
      </DialogContent>

      <DialogOrdersMultipeEditionActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <AccessFeature.Hidden
          validFeature={PlanFeaturesTypes.LimitNotPro}
          type="notAccess"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={submitForm}
            disabled={selectableRows.selectedRowsIds.length === 0}
          >
            Guardar cambios
          </Button>
        </AccessFeature.Hidden>
      </DialogOrdersMultipeEditionActions>

      <DialogConfimation
        open={openConfirmModifidyOrders}
        description="Las siguientes modificaciones se aplicarán a los pedidos"
        onConfirm={handleModifyOrders}
        onCancel={() => toggleOpenConfirmModifidyOrders(false)}
        loadingConfirm={loadingModifyOrders}
        maxWidth="sm"
      >
        <DialogOrdersMultipeEditionPreviewModifications values={values} />
      </DialogConfimation>
    </>
  );
};

export default DialogOrdersMultipeEditionContent;

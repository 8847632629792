export const STATE_COLORS = {
  progress: '#45FFFF',
  halfway: '#DEA500',
  ending: '#EB0014',
};

export const FONT_SIZES = {
  inherit: 'inherit',
  small: 14,
  medium: 18,
  large: 22,
};

export const getColorState = (
  itsHalfway: boolean,
  isEnding: boolean,
  defaultColor = STATE_COLORS.progress,
): string => {
  let color = defaultColor;

  if (itsHalfway) color = STATE_COLORS.halfway;

  if (isEnding) color = STATE_COLORS.ending;

  return color;
};

import {
  ReactElement,
  ReactNode,
  SyntheticEvent,
  useState,
  useCallback,
  useMemo,
  useImperativeHandle,
  Fragment,
} from 'react';
import {
  Autocomplete,
  AutocompleteValue,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  CircularProgress,
  InputAdornment,
} from '@mui/material';

import { useSubscriptionFeatures } from '~hooks/index';

import AccessFeatureTextField from '../TextField';

import { AccessFeatureAutoCompleteProps } from './types';

const AccessFeatureAutoComplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  validFeature,
  availableToDate,
  showChip = true,
  openAccessFeatureModalOnClickChip = false,
  id,
  name,
  loading,
  loadingText = 'Cargando...',
  noOptionsText = 'Sin opciones',
  disabled: disabledProps,
  readOnly,
  label,
  error,
  helperText,
  onChange: onChangeProps,
  onInputChange: onInputChangeProps,
  innerRef,
  multiple,
  defaultValue = (multiple ? [] : null) as AutocompleteValue<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >,
  startAdornment,
  ...rest
}: AccessFeatureAutoCompleteProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>): ReactElement => {
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const [value, setValue] =
    useState<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>(
      defaultValue,
    );
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    (
      event: SyntheticEvent,
      newValue: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T>,
    ) => {
      setValue(newValue);

      onChangeProps?.(event, newValue, reason, details);
    },
    [onChangeProps],
  );

  const handleInputChange = useCallback(
    (
      event: SyntheticEvent,
      newValue: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      setInputValue(newValue);

      onInputChangeProps?.(event, newValue, reason);
    },
    [onInputChangeProps],
  );

  const disabled = useMemo(
    () => !hasAccessToFeature(validFeature, availableToDate) || disabledProps,
    [hasAccessToFeature, validFeature, availableToDate, disabledProps],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): ReactNode => (
      <AccessFeatureTextField
        validFeature={validFeature}
        availableToDate={availableToDate}
        showChip={showChip}
        openAccessFeatureModalOnClickChip={openAccessFeatureModalOnClickChip}
        {...params}
        name={name}
        label={label}
        InputLabelProps={{
          ...params.InputLabelProps,
          disabled: disabled || readOnly,
        }}
        error={error}
        helperText={helperText}
        InputProps={{
          ...params.InputProps,
          disabled: disabled || readOnly,
          startAdornment: startAdornment
            ? startAdornment(value)
            : params.InputProps.startAdornment,
          endAdornment: (
            <Fragment>
              {!disabled && loading && (
                <InputAdornment
                  position="end"
                  sx={{ marginRight: 4, height: 'auto' }}
                >
                  <CircularProgress color="primary" size={20} />
                </InputAdornment>
              )}
              {params.InputProps.endAdornment}
            </Fragment>
          ),
        }}
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
        }}
      />
    ),
    [
      validFeature,
      availableToDate,
      showChip,
      openAccessFeatureModalOnClickChip,
      name,
      label,
      disabled,
      readOnly,
      error,
      helperText,
      startAdornment,
      value,
      loading,
    ],
  );

  const resetAutocomplete = useCallback(() => {
    setValue(defaultValue);
    setInputValue('');
  }, [defaultValue]);

  useImperativeHandle(
    innerRef,
    () => ({ resetAutocomplete, setValue, setInputValue }),
    [resetAutocomplete, setValue, setInputValue],
  );

  return (
    <Autocomplete
      id={id}
      multiple={multiple}
      defaultValue={defaultValue}
      disabled={disabled || readOnly}
      loading={loading}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      renderInput={renderInput}
      value={value}
      inputValue={inputValue}
      onChange={handleChange}
      onInputChange={handleInputChange}
      {...rest}
    />
  );
};

export default AccessFeatureAutoComplete;

import { useCallback } from 'react';
import moment, { MomentInput } from 'moment-timezone';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { formatterDate } from '~utils/formatter';

type FormatterDateUtcOffset = (
  date: MomentInput,
  format?: string,
  keepLocalTimeUtc?: boolean,
) => string;

const REPLACER_SIGN_TIMEZONE: Record<string, string> = {
  '-': '+',
  '+': '-',
};

export const useUtcOffset = (): FormatterDateUtcOffset => {
  const { user } = useAppSelector(selectAuth);

  const getUtcOffset = useCallback(
    (transformSign: boolean) => {
      const currentTimeZone = user?.company.timeZone ?? '-03:00';
      const signTimeZone = currentTimeZone[0];

      if (
        transformSign &&
        Object.values(REPLACER_SIGN_TIMEZONE).includes(signTimeZone)
      ) {
        return currentTimeZone.replace(
          signTimeZone,
          REPLACER_SIGN_TIMEZONE[signTimeZone],
        );
      }

      return currentTimeZone;
    },
    [user],
  );

  const formatterDateUtcOffset = useCallback<FormatterDateUtcOffset>(
    (date, format = moment.HTML5_FMT.DATE, keepLocalTimeUtc = false) => {
      const utcOffset = getUtcOffset(keepLocalTimeUtc);

      return formatterDate(date, {
        format,
        utcOffset,
        withUtc: !keepLocalTimeUtc,
        withTimeZone: false,
        parseToUtc: keepLocalTimeUtc,
        keepLocalTimeUtc,
      });
    },
    [getUtcOffset],
  );

  return formatterDateUtcOffset;
};

import { ChangeEvent, useMemo, useCallback } from 'react';
import { TableSelectable, TableValidRow } from '../types';

interface UseTableHeaderSelectableReturn {
  hidden: boolean;
  getCheckBoxProps: () => {
    indeterminate: boolean;
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  };
}

export const useTableHeaderSelectable = (
  extractorKeyValue: string,
  selectable: TableSelectable<TableValidRow>,
  rows: TableValidRow[],
): UseTableHeaderSelectableReturn => {
  const { hideSelectAll, mode, selectedIds, onSelectAll, nonSelectableIds } =
    selectable;

  const hidden = useMemo(
    () => hideSelectAll || mode !== 'checkbox',
    [hideSelectAll, mode],
  );

  const validRows = useMemo(
    () =>
      rows.filter((row) => !nonSelectableIds?.includes(row[extractorKeyValue])),
    [extractorKeyValue, nonSelectableIds, rows],
  );

  const indeterminate = useMemo(
    () => selectedIds.length > 0 && selectedIds.length < rows.length,
    [rows, selectedIds],
  );

  const checked = useMemo(
    () => validRows.length > 0 && selectedIds.length === validRows.length,
    [validRows, selectedIds],
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let selectedRows: TableValidRow[] = [];

      if (event.target.checked) {
        if (indeterminate) {
          selectedRows = validRows.filter(
            (row) => !selectedIds.includes(row[extractorKeyValue]),
          );
        } else {
          selectedRows = validRows;
        }
      }

      onSelectAll(event.target.checked, selectedRows, event);
    },
    [extractorKeyValue, indeterminate, onSelectAll, selectedIds, validRows],
  );

  const getCheckBoxProps = useCallback(
    () => ({
      indeterminate,
      checked,
      onChange,
    }),
    [checked, indeterminate, onChange],
  );

  return {
    hidden,
    getCheckBoxProps,
  };
};

import { styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Marker as MarkerIcon } from '~components/Icons';

interface PropsCommon {
  currentColor?: string;
}

const shouldForwardProp = (prop: string): boolean => prop !== 'currentColor';

export const MarkerVehicleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 6,
  position: 'relative',
});

export const MarkerVehicleIconMarker = styled(MarkerIcon, {
  shouldForwardProp,
})<PropsCommon>(({ theme, currentColor }) => ({
  fontSize: theme.typography.pxToRem(30),
  color: currentColor,
  zIndex: 1,
}));

export const MarkerVehicleIconVehicleContainer = styled('div', {
  shouldForwardProp,
})(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  width: 18,
  height: 18,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 4,
  left: 6,
}));

export const MarkerVehicleIconVehicle = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.text.primary,
  }),
);

import { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';

import { ImporterProgressProps } from './types';
import {
  ImporterProgressContainer,
  ImporterProgressProgressContainer,
  ImporterProgressLinearProgress,
  ImporterProgressLinearProgressText,
} from './styles';

const ImporterProgress = ({
  title,
  description: descriptionProp,
  progress,
  warning = false,
}: ImporterProgressProps): ReactElement => {
  const isCompleted = useMemo(() => progress >= 100, [progress]);

  const descriptions = useMemo(
    () =>
      typeof descriptionProp === 'string'
        ? [descriptionProp]
        : [...descriptionProp],
    [descriptionProp],
  );

  const color = useMemo(
    () => (warning ? 'warning' : isCompleted ? 'success' : 'primary'),
    [isCompleted, warning],
  );

  return (
    <ImporterProgressContainer>
      <Typography>{title}</Typography>

      {descriptions.map((description, index) => (
        <Typography
          key={`importer-progress-description-${index}`}
          variant="caption"
          color="text.secondary"
        >
          {description}
        </Typography>
      ))}

      <ImporterProgressProgressContainer>
        <ImporterProgressLinearProgress
          variant="determinate"
          value={progress}
          color={color}
        />

        <ImporterProgressLinearProgressText variant="body2" fontWeight={500}>
          {`${progress}%`}
        </ImporterProgressLinearProgressText>
      </ImporterProgressProgressContainer>
    </ImporterProgressContainer>
  );
};

export default ImporterProgress;

import { Map } from 'mapbox-gl';
import { IControl, ControlPosition } from 'react-map-gl';
import classNames from 'classnames';

import { Nullable } from '~globals/types';

export interface MapboxGLButtonControlOptions {
  className: string;
  title: string;
  eventHandler: (event: MouseEvent) => void;
}

class MapboxGLButtonControl implements IControl {
  options: MapboxGLButtonControlOptions;

  _map: Nullable<Map>;

  _container: HTMLElement;

  _btn: HTMLButtonElement;

  constructor(options: MapboxGLButtonControlOptions) {
    this.options = options;
    this._map = null;
    this._container = document.createElement('div');
    this._btn = document.createElement('button');
  }

  onAdd(map: Map): HTMLElement {
    this._map = map;

    this._btn.className = classNames(
      'mapboxgl-ctrl-icon',
      this.options.className,
    );
    this._btn.type = 'button';
    this._btn.title = this.options.title;
    this._btn.onclick = this.options.eventHandler;

    const element = document.getElementById('displaytrip-group');

    if (element == null) {
      this._container.id = 'displaytrip-group';
    } else {
      this._container = element;
    }

    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove(): void {
    this._container.parentNode?.removeChild(this._container);
    this._map = null;
  }

  getDefaultPosition(): ControlPosition {
    return 'bottom-left';
  }
}

export default MapboxGLButtonControl;

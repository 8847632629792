import { ReactElement, MouseEventHandler, useCallback, useMemo } from 'react';
import { MenuItem, ListItemText } from '@mui/material';

import { useAccessFeatureModalContext } from '~contexts/AccessFeatureModalContext';

import { useSubscriptionFeatures } from '~hooks/index';

import { AccessFeatureMenuItemProps } from './types';
import AccessFeatureNextPlanChip from '../NextPlanChip';

const AccessFeatureMenuItem = ({
  validFeature,
  availableToDate,
  onClick,
  children,
  ...rest
}: AccessFeatureMenuItemProps): ReactElement => {
  const { handleOpen: handleOpenAccesFeatureModal } =
    useAccessFeatureModalContext();
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const isValidFeature = useMemo(
    () => hasAccessToFeature(validFeature, availableToDate),
    [validFeature, hasAccessToFeature, availableToDate],
  );

  const handleClick = useCallback<MouseEventHandler<HTMLLIElement>>(
    (event) => {
      if (!isValidFeature) {
        event.preventDefault();

        handleOpenAccesFeatureModal({
          validFeature,
          validateAvailableRoutesState: false,
        });
      } else {
        onClick?.(event);
      }
    },
    [isValidFeature, handleOpenAccesFeatureModal, validFeature, onClick],
  );

  return (
    <MenuItem {...rest} onClick={handleClick}>
      <ListItemText>{children}</ListItemText>

      <AccessFeatureNextPlanChip
        validFeature={validFeature}
        availableToDate={availableToDate}
      />
    </MenuItem>
  );
};

export default AccessFeatureMenuItem;

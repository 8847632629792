import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { SelectRowProps } from 'react-bootstrap-table-next';

import {
  useDataTableSelectedProps,
  SelectRowsHandlerOnSelectAll,
  SelectRowsHandlerOnSelect,
} from './useDataTableSelectedProps';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SelectedRowsReturn<R extends Record<string, any> = any> {
  selectedRows: R[];
  setSelectedRows: Dispatch<SetStateAction<R[]>>;
  selectedRowsIds: Array<number | string>;
  setSelectedRowsIds: Dispatch<SetStateAction<Array<number | string>>>;
  selectRowsProps: SelectRowProps<R>;
  handleOnSelectAll: SelectRowsHandlerOnSelectAll<R>;
  handleOnSelect: SelectRowsHandlerOnSelect<R>;
  restarSelectedRows: () => void;
}

export const useSelectedRows = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends Record<string, any> = any,
>(
  extractorKeyValue: string,
  defaultRows: R[] = [],
  defaultRowsIds: Array<number | string> = [],
  defaultNonSelectable?: Array<number | string>,
  mode: 'checkbox' | 'radio' = 'checkbox',
): SelectedRowsReturn<R> => {
  const [selectedRows, setSelectedRows] = useState<R[]>(defaultRows);
  const [selectedRowsIds, setSelectedRowsIds] =
    useState<Array<number | string>>(defaultRowsIds);

  const handleOnSelectAll = useCallback<SelectRowsHandlerOnSelectAll<R>>(
    (isSelected, rows) => {
      setSelectedRows((prevRows) => {
        let rowsSelected: R[] = [];

        if (mode === 'radio') return prevRows;

        if (isSelected) {
          rowsSelected = prevRows.concat(rows);
        }

        return rowsSelected;
      });

      setSelectedRowsIds((prevSelectedRowsIds) => {
        let ids: Array<number | string> = [];

        if (mode === 'radio') return prevSelectedRowsIds;

        if (isSelected) {
          const selectedIds = rows.map(
            (row) => row[extractorKeyValue] as string | number,
          );

          ids = prevSelectedRowsIds.concat(selectedIds);
        }

        return ids;
      });
    },
    [extractorKeyValue, mode],
  );

  const handleOnSelect = useCallback<SelectRowsHandlerOnSelect<R>>(
    (row, isSelected) => {
      setSelectedRows((prevRows) => {
        if (mode === 'radio') return [row];

        if (isSelected) {
          return prevRows.concat(row);
        } else {
          return prevRows.filter(
            (prevRow) => prevRow[extractorKeyValue] !== row[extractorKeyValue],
          );
        }
      });

      setSelectedRowsIds((prevSelectedRowsIds) => {
        const currentId = row[extractorKeyValue] as string | number;

        if (mode === 'radio') return [currentId];

        if (isSelected) {
          return prevSelectedRowsIds.concat(currentId);
        } else {
          return prevSelectedRowsIds.filter((prevRow) => prevRow !== currentId);
        }
      });
    },
    [extractorKeyValue, mode],
  );

  const selectRowsProps = useDataTableSelectedProps({
    onSelectAll: handleOnSelectAll,
    onSelect: handleOnSelect,
    selectedIds: selectedRowsIds,
    defaultNonSelectableIds: defaultNonSelectable,
    mode,
  });

  const restarSelectedRows = useCallback(() => {
    setSelectedRowsIds([]);
    setSelectedRows([]);
  }, []);

  return {
    selectedRows,
    setSelectedRows,
    selectedRowsIds,
    setSelectedRowsIds,
    selectRowsProps,
    handleOnSelectAll,
    handleOnSelect,
    restarSelectedRows,
  };
};

import moment from 'moment';
import { isNull } from 'lodash';

import { VehicleFilter, VehicleSearchItem } from '~services/vehicle/types';
import { AuthUserSubscription } from '~services/auth/types';
import { ItemExtended } from '~services/item/types';
import { Nullable } from '~globals/types/commons';
import { PaginationRequestParams } from '~globals/types/api';
import { CollectItemStateTypes } from '~globals/types/enums';
import { isCurrentLoggedCompany } from '~utils/partnerCompany';
import { validatePartnerItemCollectStateType } from '~utils/partnerItem';

import { DialogGenerateRoutesType } from './types';

export const TABLE_KEY_FIELDS = {
  VEHICLE: 'vehicleId',
  ORDER: 'itemId',
};

export const MAX_SUPPORT_GENERATE_ORDERS = 200;

export const getDefaultScheduleDate = (): Date => new Date(moment().format());

export const getDefaultVehiclesRequestPayload = (
  scheduleDateTime: string,
): PaginationRequestParams<VehicleFilter> => ({
  pagination: {
    page: 1,
    sizePerPage: 25,
  },
  filters: {
    enabled: null,
    query: null,
    scheduleDateTime,
  },
});

export const isRouteCollect = (type: DialogGenerateRoutesType): boolean =>
  type === 'collect';

export const getDisabledVehicle = (vehicle: VehicleSearchItem): boolean =>
  !(vehicle.isAvailable && vehicle.isOpenForWork);

export const getDisabledOrderByType = (
  type: DialogGenerateRoutesType,
  order: ItemExtended,
  user: Nullable<AuthUserSubscription>,
): boolean => {
  if (isRouteCollect(type)) {
    return validatePartnerItemCollectStateType(order.collectItemStateTypeId, [
      CollectItemStateTypes.ToValidate,
      CollectItemStateTypes.InCollect,
      CollectItemStateTypes.Validated,
      CollectItemStateTypes.NotValidated,
    ]);
  }

  return (
    !isNull(order.carrierCompanyId) &&
    !isCurrentLoggedCompany(order.carrierCompanyId, user?.companyId ?? null)
  );
};

import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast as VehicleIcon } from '@fortawesome/free-solid-svg-icons';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { VehicleInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const VehicleInfo = ({
  vehicle,
  vehicleCode,
  fontSize = 14,
  fontWeight,
  color,
  withTooltip = false,
  useCustomIcon = false,
  useCaption = false,
}: VehicleInfoProps): ReactElement => {
  const Icon = useMemo(
    () =>
      useCustomIcon ? (
        <img src={toAbsoluteUrl('/icons/icon_truck.svg')} alt="Vehicle Icon" />
      ) : (
        <FontAwesomeIcon icon={VehicleIcon} />
      ),
    [useCustomIcon],
  );

  const text = useMemo(
    () =>
      !useCaption && vehicleCode ? `${vehicle} (${vehicleCode})` : vehicle,
    [useCaption, vehicle, vehicleCode],
  );

  const caption = useMemo(
    () => (useCaption ? vehicleCode : undefined),
    [useCaption, vehicleCode],
  );

  const tooltipMessage = useMemo(
    () => (withTooltip ? 'Vehículo' : undefined),
    [withTooltip],
  );

  return (
    <DescriptionWithIconBox
      icon={Icon}
      description={text}
      caption={caption}
      size={fontSize}
      iconColor={color}
      iconTooltip={tooltipMessage}
      fontWeight={fontWeight}
    />
  );
};

export default memo(VehicleInfo);

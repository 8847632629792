import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  Tab,
  Stack,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

export const DialogDetailsOrderContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: 500,
    minHeight: 550,
  },
});

export const DialogDetailsOrderLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogDetailsOrderTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogDetailsOrderContentContainer = styled(DialogContent)(
  ({ theme }) => ({
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    overflowX: 'hidden',
  }),
);

export const DialogDetailsOrderTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.white,
  flex: 1,
  borderRight: `1px solid ${theme.palette.common.white}`,
}));

export const DialogDetailsOrderTabsPanelContainer = styled('div')({
  display: 'flex',
  flex: 1,
});

export const DialogDetailsOrderTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: theme.spacing(3),
  flex: 1,
}));

export const DialogDetailsOrderContentRowItem = styled(Stack)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,

  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },
}));

import API from '~config/api';
import { ApiPromise, DataResponse, ErrorResponse } from '~globals/types';

import { ApiTransactionExtended } from './types';

export const API_TRANSACTION_ENPOINTS = {
  getApiTransaction: (apiTransaction: string): string =>
    `/api/v1/Route/ApiTransaction/${apiTransaction}`,
};

export const getApiTransaction = (
  apiTransaction: string,
): ApiPromise<DataResponse<ApiTransactionExtended>, ErrorResponse> =>
  API.get<DataResponse<ApiTransactionExtended>, ErrorResponse>(
    API_TRANSACTION_ENPOINTS.getApiTransaction(apiTransaction),
  );

import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { includes } from 'lodash';
import { useTabContext } from '@mui/lab/TabContext';

import { useParseLocationQuery, useFiltersValues } from '~hooks/index';
import { getItemValue, formatterDate } from '~utils/index';

import { RouteItemStateType, ItemStateTypes } from '~globals/types/enums';
import { FiltersValuesReturn } from '~hooks/useFiltersValues';
import { ItemFilter } from '~services/item/types';
import { Nullable } from '~globals/types';
import { OrdersPanelsId } from '~modules/Orders/types';

export interface OrdersListFilters {
  dateFrom: Nullable<Date>;
  dateTo: Nullable<Date>;
  driver: string;
  search: string;
  expirationDate: Nullable<Date>;
  state: Nullable<RouteItemStateType>;
  itemState: Nullable<ItemStateTypes>;
}

interface OrderListQueryFilters extends OrdersListFilters {
  currentTab: OrdersPanelsId;
}

interface OrdersListParams {
  initialFilters: OrdersListFilters;
  defaultStates: { routeItemState: RouteItemStateType[] };
}

export interface OrdersListReturn
  extends FiltersValuesReturn<OrdersListFilters> {
  parseFiltersRequest: ItemFilter;
}

export const useOrdersListFilters = ({
  initialFilters,
  defaultStates,
}: OrdersListParams): OrdersListReturn => {
  const tabContext = useTabContext();

  const activeTab = useMemo(
    () => tabContext?.value as OrdersPanelsId,
    [tabContext],
  );

  const queryFilters = useParseLocationQuery<OrderListQueryFilters>();

  const mergeInitialFilters = useMemo<OrdersListFilters>(
    () => ({ ...initialFilters, ...queryFilters }),
    [queryFilters, initialFilters],
  );

  const { filters, ...restFiltersData } = useFiltersValues(mergeInitialFilters);

  const validateTabs = useCallback(
    (tabs: OrdersPanelsId[]) => includes(tabs, activeTab),
    [activeTab],
  );

  const parseDate = useCallback(
    (
      key: 'dateFrom' | 'dateTo' | 'expirationDate',
      excludeTabs: OrdersPanelsId[],
    ) => {
      const date = getItemValue(filters, key, (val): Nullable<string> => {
        if (validateTabs(excludeTabs) || !val) return null;

        const formatDate = formatterDate(new Date(val), {
          format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
        });

        return formatDate || null;
      });

      return date;
    },
    [filters, validateTabs],
  );

  const parseSearch = useCallback(() => {
    const search = getItemValue(
      filters,
      'search',
      (val): Nullable<string> => val || null,
    );

    return search;
  }, [filters]);

  const parseRouteStates = useCallback(() => {
    const states = getItemValue(
      filters,
      'state',
      (val): RouteItemStateType[] => {
        if (val && includes(defaultStates.routeItemState, val)) {
          return [val];
        }

        return defaultStates.routeItemState;
      },
      defaultStates.routeItemState,
    );

    return states;
  }, [filters, defaultStates]);

  const parseItemState = useCallback(() => {
    const itemState = getItemValue(
      filters,
      'itemState',
      (val): Nullable<ItemStateTypes> => val || null,
    );

    return itemState;
  }, [filters]);

  const parseFiltersRequest = useMemo((): ItemFilter => {
    return {
      dateFrom: parseDate('dateFrom', ['pending']),
      dateTo: parseDate('dateTo', ['pending']),
      driverId: null,
      routeCode: parseSearch(),
      maxDeliveredDateTime: parseDate('expirationDate', [
        'on-agenda',
        'finalized',
      ]),
      routeItemStateTypesId: parseRouteStates(),
      itemStateTypeId: parseItemState(),
    };
  }, [parseDate, parseSearch, parseRouteStates, parseItemState]);

  return {
    filters,
    ...restFiltersData,
    parseFiltersRequest,
  };
};

import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  PHONE: 'phone',
} as const;

export const initialValues = {
  [FIELDS_NAME.PHONE]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.PHONE]: Yup.number().required(VALIDATIONS_ERROR.REQUIRED),
});

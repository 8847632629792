import {
  styled,
  Modal,
  Backdrop,
  Box,
  Paper,
  Avatar,
  CircularProgress,
  circularProgressClasses,
} from '@mui/material';

import { InitialSetupWizardLengthStyles } from './types';

export const InitialSetupWizardModalRoot = styled(Modal)({
  '@media print': {
    position: 'absolute',
  },
});

export const InitialSetupWizardModalBackDrop = styled(Backdrop)({
  zIndex: -1,
  background: 'rgba(60, 16, 127, 0.5)',
  backdropFilter: 'blur(8px)',
});

export const InitialSetupWizardModalModalContainer = styled(Box)({
  height: '100%',
  outline: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@media print': {
    height: 'auto',
  },
});

export const InitialSetupWizardModalModalPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{ ownerState: InitialSetupWizardLengthStyles }>(({ ownerState }) => ({
  margin: 32,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: ownerState.minHeight,
  maxHeight: 'calc(100% - 64px)',
  width: '100%',
  maxWidth: ownerState.maxWidth,

  '@media print': {
    overflowY: 'visible',
    boxShadow: 'none',
  },
}));

export const InitialSetupWizardModalPaperContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  WebkitOverflowScrolling: 'touch',
  padding: '32px 24px',
  gap: 16,
  // alignItems: 'center',
});

export const InitialSetupWizardModalStepContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gutterLeft',
})<{ gutterLeft?: boolean }>(({ gutterLeft = true }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  gap: 16,
  // width: '100%',
  // maxWidth: '70%',

  ...(gutterLeft && {
    marginLeft: 91,
  }),
}));

export const InitialSetupWizardStepTitleWithProgressProggressContainer = styled(
  Box,
)({
  position: 'relative',
  maxWidth: 84,
});

export const InitialSetupWizardStepTitleWithProgressProggressFull = styled(
  CircularProgress,
)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

export const InitialSetupWizardStepTitleWithProgressProggressDynamic = styled(
  CircularProgress,
)({
  position: 'absolute',
  top: -2.5,
  left: -2.5,

  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: 'round',
  },
});

export const InitialSetupWizardStepTitleWithProgressProggressAvatar = styled(
  Avatar,
)(({ theme }) => ({
  width: 50,
  height: 50,
  position: 'absolute',
  top: 12.5,
  left: 12.5,
  boxShadow: theme.shadows[3],
}));

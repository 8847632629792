import { ReactElement, MouseEvent, useMemo, useCallback } from 'react';
import { Stack, Divider, Typography } from '@mui/material';

import { formatCurrencyValue } from '~utils/index';
import { PaymentFrecuencyTypes, PlanPaymentTypes } from '~globals/types/enums';
import { useSubscription } from '~hooks/index';

import {
  getAclarationText,
  getPaymentTypeNoteText,
} from '../PlanPriceCard/utils';
import { SubscriptionPaymentModalData } from '../PaymentModal/types';

import { SubscriptionPackagePriceCardProps } from './types';
import {
  PackagePriceCardRoot,
  PackagePriceCardHeader,
  PackagePriceCardCheckIcon,
  PackagePriceCardContent,
  PackagePriceCardActions,
  PackagePriceCardActionsButton,
} from './styles';

const SubscriptionPackagePriceCard = ({
  name,
  icon: Icon,
  iconColor,
  description,
  currentPrice,
  paymentType: paymentTypeProp = 'monthly',
  features,
  commingSoon = false,
  onSubscribe,
  frecuencyType,
  providersPlansId,
  enabledForPlans = [],
  isPurchased = false,
}: SubscriptionPackagePriceCardProps): ReactElement => {
  const subscription = useSubscription();

  const isSinglePayment = useMemo(
    () => frecuencyType === PaymentFrecuencyTypes.SINGLE,
    [frecuencyType],
  );

  const paymentType = useMemo<`${PlanPaymentTypes}`>(
    () => (isSinglePayment ? 'monthly' : paymentTypeProp),
    [isSinglePayment, paymentTypeProp],
  );

  const price = useMemo(
    () => currentPrice[paymentType],
    [currentPrice, paymentType],
  );

  const aclaration = useMemo(() => getAclarationText(false), []);

  const paymentNote = useMemo(
    () =>
      isSinglePayment ? 'Pago Unico' : getPaymentTypeNoteText(paymentType),
    [isSinglePayment, paymentType],
  );

  const hasSubscribedToEnabledPlans = useMemo(() => {
    const currentPlanName = subscription?.description
      ?.replace('ANUAL', '')
      .trimEnd();

    return enabledForPlans.includes(currentPlanName ?? '');
  }, [enabledForPlans, subscription?.description]);

  const disabledSubscribe = useMemo(() => {
    return commingSoon || !hasSubscribedToEnabledPlans || isPurchased;
  }, [hasSubscribedToEnabledPlans, commingSoon, isPurchased]);

  const handleSubscribe = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!disabledSubscribe) {
        const subscribeData: SubscriptionPaymentModalData = {
          name,
          currentPrice: price,
          paymentType,
          providersPlansId,
          isSinglePayment,
          isPack: true,
        };

        onSubscribe(event, subscribeData);
      }
    },
    [
      name,
      price,
      paymentType,
      onSubscribe,
      providersPlansId,
      disabledSubscribe,
      isSinglePayment,
    ],
  );

  const subscribeButtonText = useMemo(() => {
    let text = isPurchased ? 'Suscripto' : 'Suscribirse';

    if (isSinglePayment) text = isPurchased ? 'Adquirido' : 'Adquirir';

    if (!hasSubscribedToEnabledPlans) text = 'No disponible';

    if (commingSoon) text = 'Próximamente';

    return text;
  }, [commingSoon, isSinglePayment, hasSubscribedToEnabledPlans, isPurchased]);

  return (
    <PackagePriceCardRoot>
      <PackagePriceCardHeader>
        <Stack spacing={0}>
          <Stack spacing={0.5} direction="row">
            <Typography variant="h6" fontWeight="bold">
              {name}
            </Typography>

            {Icon && (
              <Icon
                sx={(currenTheme) => ({
                  fontSize: currenTheme.typography.pxToRem(28),
                })}
                htmlColor={iconColor}
              />
            )}
          </Stack>

          <Typography variant="caption">{description}</Typography>
        </Stack>

        {!commingSoon && (
          <Stack spacing={0.5}>
            <Stack
              direction="row"
              spacing={1}
              divider={<Divider orientation="vertical" />}
              alignItems="center"
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="h5" fontWeight="bold" component="span">
                  $
                </Typography>

                <Typography
                  variant="h2"
                  fontWeight="bold"
                  component="span"
                  lineHeight={0.85}
                >
                  {formatCurrencyValue(price, {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </Stack>

              <Typography
                variant="body1"
                fontWeight="bold"
                component="span"
                lineHeight={1}
              >
                {aclaration}
              </Typography>
            </Stack>

            <Typography variant="caption" color="text.secondary">
              {paymentNote}
            </Typography>
          </Stack>
        )}
      </PackagePriceCardHeader>

      <PackagePriceCardContent>
        {features.map((feature, index) => (
          <Stack
            key={`package-price-card-${name}-feature-${index}`}
            spacing={1}
            direction="row"
            alignItems="flex-start"
          >
            <PackagePriceCardCheckIcon />

            <Typography variant="caption">{feature}</Typography>
          </Stack>
        ))}
      </PackagePriceCardContent>

      <PackagePriceCardActions>
        <PackagePriceCardActionsButton
          variant="contained"
          size="small"
          fullWidth
          onClick={handleSubscribe}
          disabled={disabledSubscribe}
        >
          {subscribeButtonText}
        </PackagePriceCardActionsButton>

        {enabledForPlans.length > 0 && (
          <Typography variant="caption">
            <span>Solo disponible para plan </span>

            <span>
              {enabledForPlans.map((planName, index) => (
                <span key={`enabled-element-${planName}`}>
                  {index > 0 && index !== enabledForPlans.length - 1 && (
                    <span>, </span>
                  )}

                  {enabledForPlans.length > 1 &&
                    index === enabledForPlans.length - 1 && <span> y </span>}

                  <b>{planName}</b>
                </span>
              ))}
            </span>
          </Typography>
        )}
      </PackagePriceCardActions>
    </PackagePriceCardRoot>
  );
};

export default SubscriptionPackagePriceCard;

import fileDownload from 'js-file-download';

export const downloadFileByRequest = (
  headers: Record<string, string>,
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  defaultFileName?: string,
): void => {
  const fileName = headers['content-disposition']
    .split('; ')[1]
    .split(/filename=(.+)/)[1]
    .replaceAll('"', '');

  fileDownload(data, fileName ?? defaultFileName);
};

import { chain, forEach, replace, toString } from 'lodash';
import queryString, { UrlObject } from 'query-string';

type TReplacementParams = {
  [pattern: string]: string | number | boolean;
};

export const replaceParamsPath = (
  path: string,
  replacements: TReplacementParams,
): string => {
  const keysPatterns = chain(replacements).keys().value();

  let output = path;

  forEach(keysPatterns, (pattern) => {
    output = replace(output, pattern, toString(replacements[pattern]));
  });

  return output;
};

export const parseQueryString = <T>(query: string): T =>
  queryString.parse(query, {
    arrayFormat: 'comma',
    parseBooleans: true,
    parseNumbers: true,
  }) as unknown as T;

export const getStringifyUrlQueryString = ({
  url,
  query,
  fragmentIdentifier,
}: UrlObject): string =>
  queryString.stringifyUrl(
    { url, query, fragmentIdentifier },
    { arrayFormat: 'comma', skipEmptyString: true, skipNull: true },
  );

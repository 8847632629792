import { ReactElement, ChangeEvent, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  Typography,
  Stack,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControlLabel,
  Switch,
  TextFieldProps,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';

import { hasError } from '~utils/formHelpers';

import { FIELDS_NAME, getInitialValues } from '../../utils';
import { OrderFormSectionContainer } from '../../styles';

const OrderFormContactInformationSection = (): ReactElement => {
  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const handleChangeTimeWindowAllDay = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      setFieldValue(FIELDS_NAME.TIME_WINDOW_ALL_DAY, checked);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_FROM, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_TO, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_FROM, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_TO, null);
    },
    [setFieldValue],
  );

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFieldValue(fieldName, value);
      },
    [setFieldValue],
  );

  const handleBlurDate = useCallback(
    (fieldName: string) => (): void => {
      setFieldTouched(fieldName, true);
    },
    [setFieldTouched],
  );

  const isDisabledTimeWindowDates = useMemo(
    () => values[FIELDS_NAME.TIME_WINDOW_ALL_DAY],
    [values],
  );

  const renderInputPicker = useCallback(
    (props: TextFieldProps, nameField: string) => (
      <TextField
        {...props}
        onBlur={handleBlurDate(nameField)}
        error={hasError(touched, errors, nameField)}
      />
    ),
    [handleBlurDate, touched, errors],
  );

  return (
    <OrderFormSectionContainer
      defaultExpanded={false}
      disableGutters
      elevation={0}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel-order-form-contact-information-content"
        id="panel-order-form-contact-information-header"
      >
        <Typography component="h6" fontWeight="bold">
          Información de contacto receptor
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <TextField
              label="Nombre completo"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.CONTAC_FULL_NAME)}
              {...getFieldProps(FIELDS_NAME.CONTAC_FULL_NAME)}
            />

            <TextField
              label="Nro. Documento"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.CONTACT_DOCUMENT)}
              {...getFieldProps(FIELDS_NAME.CONTACT_DOCUMENT)}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Teléfono"
              placeholder="Cod de área sin 0 + Nro sin 15"
              fullWidth
              type="tel"
              error={hasError(touched, errors, FIELDS_NAME.CONTACT_PHONE)}
              {...getFieldProps(FIELDS_NAME.CONTACT_PHONE)}
              helperText="Cod de área sin 0 + Nro sin 15"
            />

            <TextField
              label="Celular / Whatsapp"
              placeholder="Cod de área sin 0 + Nro sin 15"
              fullWidth
              type="tel"
              error={hasError(touched, errors, FIELDS_NAME.CONTACT_CELL_PHONE)}
              {...getFieldProps(FIELDS_NAME.CONTACT_CELL_PHONE)}
              helperText="Cod de área sin 0 + Nro sin 15"
            />
          </Stack>

          <TextField
            label="Email"
            fullWidth
            error={hasError(touched, errors, FIELDS_NAME.CONTACT_EMAIL)}
            {...getFieldProps(FIELDS_NAME.CONTACT_EMAIL)}
          />

          <Stack spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2" fontWeight="bold">
                Ventana horaria de visita
              </Typography>

              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps(FIELDS_NAME.TIME_WINDOW_ALL_DAY)}
                    checked={values[FIELDS_NAME.TIME_WINDOW_ALL_DAY]}
                    onChange={handleChangeTimeWindowAllDay}
                  />
                }
                label="Todo el día"
              />
            </Stack>

            <Stack direction="row" spacing={8}>
              <Stack direction="row" spacing={1}>
                <TimePicker
                  label="Desde"
                  value={values[FIELDS_NAME.TIME_WINDOW_ONE_FROM]}
                  onChange={handleChangeDate(FIELDS_NAME.TIME_WINDOW_ONE_FROM)}
                  disabled={isDisabledTimeWindowDates}
                  renderInput={(props) =>
                    renderInputPicker(props, FIELDS_NAME.TIME_WINDOW_ONE_FROM)
                  }
                />

                <TimePicker
                  label="Hasta"
                  value={values[FIELDS_NAME.TIME_WINDOW_ONE_TO]}
                  onChange={handleChangeDate(FIELDS_NAME.TIME_WINDOW_ONE_TO)}
                  disabled={isDisabledTimeWindowDates}
                  renderInput={(props) =>
                    renderInputPicker(props, FIELDS_NAME.TIME_WINDOW_ONE_TO)
                  }
                />
              </Stack>

              <Stack direction="row" spacing={1}>
                <TimePicker
                  label="Desde"
                  value={values[FIELDS_NAME.TIME_WINDOW_SECOND_FROM]}
                  onChange={handleChangeDate(
                    FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                  )}
                  disabled={isDisabledTimeWindowDates}
                  renderInput={(props) =>
                    renderInputPicker(
                      props,
                      FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                    )
                  }
                />

                <TimePicker
                  label="Hasta"
                  value={values[FIELDS_NAME.TIME_WINDOW_SECOND_TO]}
                  onChange={handleChangeDate(FIELDS_NAME.TIME_WINDOW_SECOND_TO)}
                  disabled={isDisabledTimeWindowDates}
                  renderInput={(props) =>
                    renderInputPicker(props, FIELDS_NAME.TIME_WINDOW_SECOND_TO)
                  }
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Typography variant="body2" fontWeight="bold">
              Fecha de vencimiento
            </Typography>

            <DatePicker
              label="Fecha (opcional)"
              value={values[FIELDS_NAME.DELIVERY_EXPIRATION_DATE]}
              onChange={handleChangeDate(FIELDS_NAME.DELIVERY_EXPIRATION_DATE)}
              disablePast
              renderInput={(props) =>
                renderInputPicker(
                  { ...props, sx: { maxWidth: 300 } },
                  FIELDS_NAME.DELIVERY_EXPIRATION_DATE,
                )
              }
            />
          </Stack>
        </Stack>
      </AccordionDetails>
    </OrderFormSectionContainer>
  );
};

export default OrderFormContactInformationSection;

import { styled, buttonClasses } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

export const SubscriptionPaymentModalFormBillingDataLoadingButton = styled(
  LoadingButton,
)(({ theme, color }) => ({
  ...(color &&
    color !== 'inherit' && {
      [`&.${buttonClasses.disabled}`]: {
        color: theme.palette[color].main,
        border: `1px solid ${alpha(theme.palette[color].main, 0.5)}`,
        backgroundColor: 'transparent',
        opacity: 0.38,
      },
    }),
}));

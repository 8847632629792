import { useRef, useMemo, useEffect } from 'react';

import { Nullable } from '~globals/types';
import { isDevelopment } from '~utils/env';

interface ScriptOptions {
  id: string;
  position: Nullable<HTMLElement>;
  src?: string;
  innerHTML?: string;
  async?: boolean;
  type?: string;
  charset?: string;
  crossOrigin?: string;
}

const loadScript = ({
  id,
  position,
  src,
  innerHTML,
  async = false,
  type,
  charset,
  crossOrigin,
}: ScriptOptions): void => {
  if (!position) return;

  const script = document.createElement('script');

  script.setAttribute('id', id);

  if (src) script.src = src;

  if (innerHTML) script.innerHTML = innerHTML;

  if (async) script.setAttribute('async', '');

  if (type) script.setAttribute('type', type);

  if (charset) script.setAttribute('charset', charset);

  if (crossOrigin) script.setAttribute('crossorigin', crossOrigin);

  position.appendChild(script);
};

interface UseScriptParams extends ScriptOptions {
  validateOnlyProduction: boolean;
}

interface UseScriptReturn {
  loaded: boolean;
}

export const useScript = ({
  id,
  position,
  src,
  innerHTML,
  async = false,
  type,
  charset,
  crossOrigin,
  validateOnlyProduction = false,
}: UseScriptParams): UseScriptReturn => {
  const loaded = useRef<boolean>(false);

  const isValid = useMemo(
    () => (validateOnlyProduction ? !isDevelopment : true),
    [validateOnlyProduction],
  );

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current && isValid) {
      if (!document.querySelector(`#${id}`)) {
        loadScript({
          id,
          position,
          src,
          innerHTML,
          async,
          type,
          charset,
          crossOrigin,
        });
      }

      loaded.current = true;
    }

    return () => {
      loaded.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { loaded: loaded.current };
};

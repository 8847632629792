import { useState, useCallback } from 'react';

import { useToggle } from './useToggle';

export type ToggleDialogConfirmationOnOpen<D> = (
  newData: D,
  delay?: number,
) => void;
export type ToggleDialogConfirmationOnClose = (delay?: number) => void;

export type UseToggleDialogConfirmationReturn<D> = [
  boolean,
  D,
  ToggleDialogConfirmationOnOpen<D>,
  ToggleDialogConfirmationOnClose,
];

export const useToggleDialogConfirmation = <D>(
  initialData: D,
  initialToggle = false,
): UseToggleDialogConfirmationReturn<D> => {
  const [openDialog, toggleOpenDialog] = useToggle(initialToggle);
  const [data, setData] = useState<D>(initialData);

  const handleOpen = useCallback<ToggleDialogConfirmationOnOpen<D>>(
    (newData, delay) => {
      setData(newData);

      setTimeout(() => {
        toggleOpenDialog(true);
      }, delay);
    },
    [toggleOpenDialog],
  );

  const handleClose = useCallback<ToggleDialogConfirmationOnClose>(
    (delay = 500) => {
      toggleOpenDialog(false);

      setTimeout(() => {
        setData(initialData);
      }, delay);
    },
    [toggleOpenDialog, initialData],
  );

  return [openDialog, data, handleOpen, handleClose];
};

import { styled, Dialog, dialogClasses, DialogActions } from '@mui/material';

export const DialogUploadItemFilesContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
  },
});

export const DialogUploadItemFilesActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

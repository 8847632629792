import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  QUERY: 'query',
} as const;

export const initialValues = {
  [FIELDS_NAME.QUERY]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.QUERY]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
});

import { ReactElement, memo } from 'react';

import { useDriverCompanyStateInfo } from '~hooks/index';

import { DriverCompanyStateChipProps } from './types';
import { DriverCompanyStateChippRoot } from './styles';

const DriverCompanyStateChip = ({
  stateId,
  size = 'medium',
  variant = 'filled',
}: DriverCompanyStateChipProps): ReactElement => {
  const { description, color } = useDriverCompanyStateInfo(stateId);

  return (
    <DriverCompanyStateChippRoot
      variant={variant}
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(DriverCompanyStateChip);

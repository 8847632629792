import { ReactElement, useMemo, useState, useCallback } from 'react';
import {
  DialogContent,
  Stack,
  Typography,
  Avatar,
  createFilterOptions,
  Link,
  Button,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { LoadingButton } from '@mui/lab';
import QRCode from 'react-qr-code';

import {
  CustomAutocomplete,
  UserInfo,
  IsAvailableChip,
} from '~components/index';
import { hasError } from '~utils/index';
import { getDefaultAvatar } from '~utils/user';
import { APP_STORES_INFO } from '~constants/appStoresInfo';

import { useDialogAssignDriverContext } from '../../DialogAssignDriverContext';
import { FIELDS_NAME, initialValues } from '../../utils';
import { DialogAssignDriverStoreImg } from '../../styles';

const DialogAssignDriverNormal = (): ReactElement => {
  const {
    refDriverAutocomplete,
    availablesDrivers: { data: availablesDriversOptions },
    selectedDriverAvailable: { selectedLastDriver, selectedUserDriver },
    loadingSubmit,
    disabledSubmit,
    lastDriverId,
    tabs: { setActiveTab },
    setCurrentAvailableDriver,
    handleOpenDialogInviteDriver,
  } = useDialogAssignDriverContext();

  const {
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    submitForm,
    values,
  } = useFormikContext<typeof initialValues>();

  const [hasChangeDriverInput, setHasChangeDriverInput] = useState(false);

  const lastDriverHelperText = useMemo(() => {
    const isLastDriverSelected =
      lastDriverId === values[FIELDS_NAME.CURRENT_DRIVER];

    if (!isLastDriverSelected || hasChangeDriverInput) return undefined;

    return 'Se sugiere el último chofer asignado al vehículo de la ruta.';
  }, [lastDriverId, values, hasChangeDriverInput]);

  const handleChangeToFirstRouteTab = useCallback(() => {
    setActiveTab('firstRoute');
    setCurrentAvailableDriver(selectedUserDriver, 'user');
  }, [selectedUserDriver, setActiveTab, setCurrentAvailableDriver]);

  return (
    <>
      <DialogContent dividers>
        <Stack spacing={1}>
          <Stack textAlign="center">
            <Typography variant="body2" color="text.secondary">
              Selecciona un chofer de la lista para enviarle la ruta
            </Typography>
          </Stack>

          <CustomAutocomplete
            innerRef={refDriverAutocomplete}
            options={availablesDriversOptions}
            label="Chofer"
            name={FIELDS_NAME.CURRENT_DRIVER}
            defaultValue={selectedLastDriver}
            onChange={(_e, val) => {
              setHasChangeDriverInput(true);
              setFieldValue(FIELDS_NAME.CURRENT_DRIVER, val?.id ?? '');
            }}
            onBlur={() => {
              setFieldTouched(FIELDS_NAME.CURRENT_DRIVER, true);
            }}
            error={hasError(touched, errors, FIELDS_NAME.CURRENT_DRIVER)}
            helperText={lastDriverHelperText}
            getOptionLabel={(option) => option.displayName}
            getOptionDisabled={(option) => !option.isAvailable as boolean}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <UserInfo
                  name={option.displayName}
                  urlAvatar={getDefaultAvatar(option.avatar ?? undefined)}
                />

                <IsAvailableChip
                  isAvailable={option.isAvailable as boolean}
                  size="small"
                  variant="filled"
                />
              </li>
            )}
            startAdornment={(val) =>
              val && (
                <Avatar
                  src={getDefaultAvatar(val.avatar ?? undefined)}
                  alt={val?.displayName}
                  sx={{ width: 20, height: 20 }}
                />
              )
            }
            filterOptions={createFilterOptions({
              ignoreAccents: true,
              ignoreCase: true,
              stringify: (option) => option.displayName.replace(',', ''),
            })}
          />

          <Stack
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              href="https://routix.tawk.help/article/asignar-ruta-a-un-chofer"
              target="_blank"
              underline="hover"
              fontWeight="bold"
              variant="caption"
            >
              Necesito ayuda sobre esto
            </Link>

            <LoadingButton
              variant="contained"
              color="primary"
              onClick={submitForm}
              loading={loadingSubmit}
              disabled={disabledSubmit}
            >
              Asignar
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogContent dividers>
        <Stack spacing={1} alignItems="center">
          <Typography variant="body2" color="text.secondary">
            También puedes
          </Typography>

          <Stack spacing={2} direction="row">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleChangeToFirstRouteTab}
            >
              Simular ser el chofer
            </Button>

            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleOpenDialogInviteDriver}
            >
              Invitar a tus choferes
            </Button>
          </Stack>

          <Typography variant="caption" flex={1}>
            <b>Descarga</b> la app e <b>inicia sesión</b> con tu usuario y
            contraseña.
          </Typography>

          <QRCode
            value="https://app.routix.io/public/device_redirect"
            size={120}
          />

          <Stack spacing={1} direction="row" justifyContent="center">
            <Link
              href={APP_STORES_INFO.PLAY_STORE.URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DialogAssignDriverStoreImg
                src={APP_STORES_INFO.PLAY_STORE.IMAGE_URL}
                alt={APP_STORES_INFO.PLAY_STORE.NAME}
              />
            </Link>

            <Link
              href={APP_STORES_INFO.APP_STORE.URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DialogAssignDriverStoreImg
                src={APP_STORES_INFO.APP_STORE.IMAGE_URL}
                alt={APP_STORES_INFO.APP_STORE.NAME}
              />
            </Link>
          </Stack>
        </Stack>
      </DialogContent>
    </>
  );
};

export default DialogAssignDriverNormal;

import { ReactElement, useMemo, memo } from 'react';
import { round } from 'lodash';

import {
  StatisticsProgressSizes,
  StatisticsProgressParseLegend,
  StatisticsProgressProps,
  StatisticsProgressOwnerState,
} from './types';
import {
  StatisticsProgressContainer,
  StatisticsProgressContent,
  StatisticsProgressFullProgress,
  StatisticsProgressDymanicProgress,
  StatisticsProgressLegendContainer,
  StatisticsProgressLegendText,
  StatisticsProgressLabelText,
  StatisticsProgressLabelLink,
} from './styles';

const sizes: Record<StatisticsProgressSizes, number> = {
  xSmall: 50,
  small: 70,
  medium: 90,
  large: 110,
};

const thickness = {
  full: 2,
  dynamic: 4,
};

const maxPercentage = 100;

const defaultParseLabel: StatisticsProgressParseLegend = (val, max) =>
  `${val}/${max}`;

const StatisticsProgress = ({
  val,
  max,
  size = 'medium',
  themeColor = 'inherit',
  customColor,
  label: labelProps,
  labelOptions = { isLink: false, path: '' },
  disabledLabelTypography = false,
  parseLegend = defaultParseLabel,
  completedColor,
}: StatisticsProgressProps): ReactElement => {
  const currentProgressVal = useMemo(
    () => round((val * maxPercentage) / (max || maxPercentage)),
    [val, max],
  );

  const isCompleted = useMemo(
    () => currentProgressVal >= maxPercentage,
    [currentProgressVal],
  );

  const currentSizeVal = useMemo(() => sizes[size], [size]);

  const currentSizeWithThickness = useMemo(
    () => currentSizeVal + thickness.dynamic,
    [currentSizeVal],
  );

  const ownerState: StatisticsProgressOwnerState['ownerState'] = {
    size,
    themeColor,
    customColor,
    completedColor,
    isCompleted,
  };

  let label = labelProps;

  if (label !== null && typeof label === 'string' && !disabledLabelTypography) {
    if (!labelOptions.isLink) {
      label = (
        <StatisticsProgressLabelText ownerState={ownerState}>
          {label}
        </StatisticsProgressLabelText>
      );
    } else {
      label = (
        <StatisticsProgressLabelLink
          to={labelOptions.path}
          color="inherit"
          underline="hover"
          ownerState={ownerState}
        >
          {label}
        </StatisticsProgressLabelLink>
      );
    }
  }

  return (
    <StatisticsProgressContainer>
      <StatisticsProgressContent style={{ position: 'relative' }}>
        <StatisticsProgressFullProgress
          variant="determinate"
          size={currentSizeVal}
          thickness={thickness.full}
          value={maxPercentage}
          color="inherit"
        />

        <StatisticsProgressDymanicProgress
          variant="determinate"
          size={currentSizeWithThickness}
          thickness={thickness.dynamic}
          value={isCompleted ? 100 : currentProgressVal}
          color="inherit"
          ownerState={ownerState}
        />

        <StatisticsProgressLegendContainer
          width={currentSizeWithThickness}
          height={currentSizeWithThickness}
        >
          <StatisticsProgressLegendText ownerState={ownerState}>
            {parseLegend(val, max, currentProgressVal)}
          </StatisticsProgressLegendText>
        </StatisticsProgressLegendContainer>
      </StatisticsProgressContent>

      {label}
    </StatisticsProgressContainer>
  );
};

export default memo(StatisticsProgress);

import { includes } from 'lodash';

import { Nullable } from '~globals/types';
import {
  PartnerItemCarrierStatusTypes,
  PartnerItemGiverStatusTypes,
  CollectItemStateTypes,
} from '~globals/types/enums';

export const validatePartnerItemCarrierStatusType = (
  typeId: Nullable<PartnerItemCarrierStatusTypes>,
  validTypes: PartnerItemCarrierStatusTypes[],
): boolean => includes(validTypes, typeId);

export const isPartnerItemCarrierBooked = (
  typeId: Nullable<PartnerItemCarrierStatusTypes>,
): boolean =>
  validatePartnerItemCarrierStatusType(typeId, [
    PartnerItemCarrierStatusTypes.Booked,
  ]);

export const validatePartnerItemGiverStatusType = (
  typeId: Nullable<PartnerItemGiverStatusTypes>,
  validTypes: PartnerItemGiverStatusTypes[],
): boolean => includes(validTypes, typeId);

export const validatePartnerItemCollectStateType = (
  typeId: Nullable<CollectItemStateTypes>,
  validTypes: CollectItemStateTypes[],
): boolean => includes(validTypes, typeId);

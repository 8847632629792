import { ReactElement, useMemo, useCallback, useRef, useEffect } from 'react';
import { Stack, Avatar, createFilterOptions, ListItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { find } from 'lodash';

import {
  DataTable,
  SkillsListInfo,
  CapabilitiesInfo,
  OrderClientInfo,
  CustomAutocomplete,
  UserInfo,
  OrderPendingStateChip,
} from '~components/index';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';
import { ItemExtended } from '~services/item/types';
import { AuthExtendedCompany } from '~services/auth/types';
import { hasError } from '~utils/index';
import { pluralize } from '~utils/commons';

import { useDialogAssignCarrierCompanyContext } from '../../DialogAssignCarrierCompanyContext';
import { FIELDS_NAME, getInitialValues } from '../../utils';
import {
  DialogAssignCarrierCompanyCard,
  DialogAssignCarrierCompanyCardContent,
  DialogAssignCarrierCompanyCardHeaderText,
  DialogAssignCarrierCompanyCardHeaderIndicators,
  DialogAssignCarrierCompanyCardDataTableContainer,
} from '../../styles';

const DialogAssignCarrierCompanyAssignCarrier = (): ReactElement => {
  const {
    activesPartnerCompanies: { list: activesPartnerCompaniesList },
    orderInfo,
    selectedOrders: {
      selectedRowsIds: selectedOrdesId,
      selectedRows: selectedOrders,
      selectRowsProps: selectRowsPropsOrders,
    },
  } = useDialogAssignCarrierCompanyContext();

  const { errors, touched, setFieldValue, setFieldTouched, values } =
    useFormikContext<ReturnType<typeof getInitialValues>>();

  const refCarriersAutocomplete =
    useRef<CustomAutocompleteRef<AuthExtendedCompany, false>>(null);

  const setDefaultCarrier = useCallback(() => {
    const carrierCompanyId = values[FIELDS_NAME.CARRIER_COMPANY_ID];
    const currentCarrierCompanyInfo = find(activesPartnerCompaniesList, {
      id: carrierCompanyId,
    });

    if (currentCarrierCompanyInfo) {
      refCarriersAutocomplete.current?.setValue(currentCarrierCompanyInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setDefaultCarrier();
  }, [setDefaultCarrier]);

  const columnsOrders = useMemo<ColumnDescription<ItemExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        formatter: (_cell, row) => (
          <OrderClientInfo
            name={row.title}
            itemTypeId={row.itemTypeId}
            trackingCode={row.trackingCode}
          />
        ),
      },
      {
        dataField: 'tags',
        text: 'Grupo',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 180 },
      },
      {
        dataField: 'skillsNeeded',
        text: 'Cargas especiales',
        classes: 'truncated-two-lines',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 160 },
        align: 'center',
      },
      {
        dataField: 'collectItemStateTypeId',
        isDummyField: true,
        text: 'Estado',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => (
          <OrderPendingStateChip
            carrierCompanyId={row.carrierCompanyId}
            collectItemStateTypeId={row.collectItemStateTypeId}
          />
        ),
        headerStyle: { width: 160 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'unit1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.unit1}
            capacity2={row.unit2}
            maximumVisits={null}
            numberOfPackages={row.numberOfPackages}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 120 },
      },
    ],
    [],
  );

  const getSelectedEntity = useCallback(
    (selecteds: Array<number | string>, entity: string) => {
      const countSelecteds = selecteds.length;
      const entityPluralize = pluralize({
        singular: entity,
        count: countSelecteds,
        includeCount: true,
      });

      const selectedText = pluralize({
        singular: 'seleccionado',
        count: countSelecteds,
        includeCount: false,
      });

      return `(${entityPluralize} ${selectedText})`;
    },
    [],
  );

  const indicatorsOrdersCharge = useMemo(
    () =>
      selectedOrders.reduce(
        (acc, selectedOrder) => {
          acc.capacity1 += selectedOrder.unit1;
          acc.capacity2 += selectedOrder.unit2;
          acc.numberOfPackages += selectedOrder.numberOfPackages;

          return acc;
        },
        { capacity1: 0, capacity2: 0, numberOfPackages: 0 },
      ),
    [selectedOrders],
  );

  return (
    <Stack spacing={2} flex={1}>
      <CustomAutocomplete
        innerRef={refCarriersAutocomplete}
        options={activesPartnerCompaniesList}
        label="Empresa transportista"
        name={FIELDS_NAME.CARRIER_COMPANY_ID}
        onChange={(_e, val) => {
          setFieldValue(FIELDS_NAME.CARRIER_COMPANY_ID, val?.id ?? '');
        }}
        onBlur={() => {
          setFieldTouched(FIELDS_NAME.CARRIER_COMPANY_ID, true);
        }}
        error={hasError(touched, errors, FIELDS_NAME.CARRIER_COMPANY_ID)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <UserInfo name={option.name} urlAvatar={option.logoFile?.url} />
          </li>
        )}
        startAdornment={(val) =>
          val && (
            <Avatar
              src={val.logoFile?.url}
              alt={val?.name}
              sx={{ width: 20, height: 20 }}
            />
          )
        }
        filterOptions={createFilterOptions({
          ignoreAccents: true,
          ignoreCase: true,
          stringify: (option) => option.name.replace(',', ''),
        })}
      />

      <DialogAssignCarrierCompanyCard variant="outlined">
        <DialogAssignCarrierCompanyCardContent>
          <ListItem disablePadding disableGutters>
            <DialogAssignCarrierCompanyCardHeaderText
              primary="PEDIDOS A ASIGNAR"
              secondary={getSelectedEntity(selectedOrdesId, 'pedido')}
            />

            <DialogAssignCarrierCompanyCardHeaderIndicators
              capacity1={indicatorsOrdersCharge.capacity1}
              capacity2={indicatorsOrdersCharge.capacity2}
              numberOfPackages={indicatorsOrdersCharge.numberOfPackages}
              maximumVisits={null}
              fontSize={14}
              fontWeight="bold"
              autoFlow="row"
              rows={1}
            />
          </ListItem>

          <DialogAssignCarrierCompanyCardDataTableContainer>
            <DataTable
              loading={false}
              data={orderInfo}
              columns={columnsOrders}
              keyField="itemId"
              selectRow={selectRowsPropsOrders}
              condensed
            />
          </DialogAssignCarrierCompanyCardDataTableContainer>
        </DialogAssignCarrierCompanyCardContent>
      </DialogAssignCarrierCompanyCard>
    </Stack>
  );
};

export default DialogAssignCarrierCompanyAssignCarrier;

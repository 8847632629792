import { SelectOption } from '~globals/types';
import {
  PartnerCompanyTypes,
  PartnerCompanyStatusTypes,
} from '~globals/types/enums';

export const PARTNER_COMPANY_TYPES: SelectOption[] = [
  {
    value: PartnerCompanyTypes.Carrier,
    label: 'Transportista',
  },
  {
    value: PartnerCompanyTypes.Giver,
    label: 'Dador de carga',
  },
];

export const PARTNER_COMPANY_STATUS_TYPES: SelectOption[] = [
  {
    value: PartnerCompanyStatusTypes.Pending,
    label: 'Pendiente',
  },
  {
    value: PartnerCompanyStatusTypes.Active,
    label: 'Activo',
  },
  {
    value: PartnerCompanyStatusTypes.Inactive,
    label: 'Inactivo',
  },
];

import { styled, Card, CardProps, CardContent, Box } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { alpha } from '@mui/material/styles';

export const HEIGHT_ACTIVE_ROUTE_CARD = 75;

export const ActiveRouteCardContainer = styled(
  (props: CardProps<'a'> & RouterLinkProps) => (
    <Card {...props} component={RouterLink} />
  ),
)(({ theme }) => ({
  minHeight: HEIGHT_ACTIVE_ROUTE_CARD,
  transition: theme.transitions.create(['background-color', 'border-color'], {
    duration: theme.transitions.duration.short,
  }),

  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.focusOpacity,
    ),
  },
}));

export const ActiveRouteCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0.5, 2),

  '&:last-child': {
    paddingBottom: theme.spacing(0.5),
  },
}));

export const ActiveRouteCardVehicleInfoBox = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  alignItems: 'center',
}));

export const ActiveRouteCardVehicleInfoBoxTitleContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1.5),
    alignItems: 'center',
    flex: 1,
    fontWeight: theme.typography.fontWeightBold,
  }),
);

export const ActiveRouteCardRouteInfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'column',
}));

export const ActiveRouteCardRouteInfoBoxRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

import { ReactElement, useMemo } from 'react';
import { Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { Link } from '~components/index';
import { PATHS } from '~constants/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { getStringifyUrlQueryString } from '~utils/router';
import { formatterDate } from '~utils/formatter';
import { RouteItemStateType } from '~globals/types/enums';

import {
  AlertOrdersProps,
  AlertOrdersVariantMapping,
  AlertOrdersVariantSeverity,
} from './types';
import {
  AlertOrdersCard,
  AlertOrdersCardContent,
  AlertOrdersCardHeader,
  AlertOrdersCardImg,
  AlertOrdersNoExpiredActions,
  AlertOrdersNoExpiredActionsButton,
  AlertOrdersNoExpiredLabel,
} from './styles';

const currentDate = moment();
const previousDayDate = currentDate.clone().subtract(1, 'day');

const variantsAlerts: AlertOrdersVariantMapping = {
  expired: {
    getTitle: (totalItemExpired: number) =>
      `${totalItemExpired} pedidos han vencido`,
    path: getStringifyUrlQueryString({
      url: PATHS.ORDERS.BASE,
      query: {
        state: RouteItemStateType.New,
        currentTab: 'pending',
        expirationDate: formatterDate(previousDayDate, {
          format: 'MM-DD-YYYY',
        }),
      },
    }),
    iconImg: {
      url: toAbsoluteUrl('/icons/icon_calendar_alert.png'),
      alt: 'Expired Items Icon',
    },
  },
  expiredToday: {
    getTitle: (totalItemExpiredToday: number) =>
      `${totalItemExpiredToday} pedidos vencen hoy`,
    path: getStringifyUrlQueryString({
      url: PATHS.ORDERS.BASE,
      query: {
        state: RouteItemStateType.New,
        currentTab: 'pending',
        expirationDate: formatterDate(currentDate, { format: 'MM-DD-YYYY' }),
      },
    }),
    iconImg: {
      url: toAbsoluteUrl('/icons/icon_clock_2.png'),
      alt: 'Expired Today Items Icon',
    },
  },
  notExpired: {
    getTitle: () => 'Agrega más pedidos',
    path: PATHS.ORDERS.NEW,
    altPath: getStringifyUrlQueryString({
      url: PATHS.ORDERS.BASE,
      query: {
        currentTab: 'pending',
        openImporterFromFile: true,
      },
    }),
    iconImg: {
      url: toAbsoluteUrl('/icons/icon_package_add.png'),
      alt: 'Not Expired Items Icon',
    },
  },
};

const AlertOrders = ({
  totalItemExpired,
  totalItemExpiredToday,
}: AlertOrdersProps): ReactElement => {
  const severity = useMemo<AlertOrdersVariantSeverity>(
    () =>
      totalItemExpired
        ? 'expired'
        : totalItemExpiredToday
        ? 'expiredToday'
        : 'notExpired',
    [totalItemExpired, totalItemExpiredToday],
  );

  const currentVariantData = useMemo(
    () => get(variantsAlerts, severity),
    [severity],
  );

  const currentVal = useMemo(
    () => totalItemExpired ?? totalItemExpiredToday ?? 0,
    [totalItemExpired, totalItemExpiredToday],
  );

  return (
    <AlertOrdersCard severity={severity}>
      <AlertOrdersCardContent>
        <AlertOrdersCardHeader>
          <Typography
            component="h6"
            color={
              severity === 'expiredToday' ? 'text.primary' : 'common.white'
            }
            variant="body2"
            fontWeight="bold"
          >
            {currentVariantData.getTitle(currentVal)}
          </Typography>

          {(severity === 'expired' || severity === 'expiredToday') && (
            <Link
              to={currentVariantData.path}
              color={severity === 'expiredToday' ? 'primary' : 'common.white'}
              variant="caption"
              sx={{ maxWidth: 'max-content' }}
              underline="hover"
            >
              Ver pedidos
            </Link>
          )}

          {severity === 'notExpired' && (
            <AlertOrdersNoExpiredActions>
              <AlertOrdersNoExpiredActionsButton
                variant="contained"
                color="inherit"
                size="small"
                startIcon={<AddIcon />}
                to={currentVariantData.path}
              >
                <AlertOrdersNoExpiredLabel>Nuevo</AlertOrdersNoExpiredLabel>
              </AlertOrdersNoExpiredActionsButton>

              <AlertOrdersNoExpiredActionsButton
                variant="contained"
                color="inherit"
                size="small"
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                to={currentVariantData.altPath!}
              >
                <AlertOrdersNoExpiredLabel>Importar</AlertOrdersNoExpiredLabel>
              </AlertOrdersNoExpiredActionsButton>
            </AlertOrdersNoExpiredActions>
          )}
        </AlertOrdersCardHeader>

        <AlertOrdersCardImg
          src={currentVariantData.iconImg.url}
          alt={currentVariantData.iconImg.alt}
        />
      </AlertOrdersCardContent>
    </AlertOrdersCard>
  );
};

export default AlertOrders;

import { useMemo, useCallback } from 'react';
import { chain, reduce, forEach, hasIn } from 'lodash';

import { useAppSelector } from '~hooks/index';
import { selectPlans } from '~store/slices/plans/index';
import { PlanTypes, PlanFeaturesTypes } from '~globals/types/enums';
import { PlanExtended } from '~services/plan/types';

type FeaturesByPlan = Record<`${PlanFeaturesTypes}`, string[]>;

interface PlanDetails {
  plans: PlanExtended[];
  featuresByPlan: FeaturesByPlan;
  packs: PlanExtended[];
  // featuresByPack: FeaturesByPlan;
}

export const usePlansDetails = (): PlanDetails => {
  const { data } = useAppSelector(selectPlans);

  const getResultByTypeId = useCallback(
    (planTypeId: PlanTypes): PlanExtended[] =>
      chain(data)
        .filter({ planTypeId })
        .sortBy(['priority', 'description'])
        .value(),
    [data],
  );

  const plans = useMemo(
    () => getResultByTypeId(PlanTypes.Plan),
    [getResultByTypeId],
  );

  const featuresByPlan = useMemo(
    () =>
      reduce(
        plans,
        (acc, plan) => {
          forEach(plan.planFeatures, (feature) => {
            if (!hasIn(acc, feature.feature.description)) {
              acc[feature.feature.description] = [];
            }
            acc[feature.feature.description].push(plan.description);
          });

          return acc;
        },
        {} as FeaturesByPlan,
      ),
    [plans],
  );

  const packs = useMemo(
    () => getResultByTypeId(PlanTypes.Pack),
    [getResultByTypeId],
  );

  return { plans, featuresByPlan, packs };
};

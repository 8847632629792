import { ReactElement, useMemo } from 'react';

import { useSubscriptionFeatures } from '~hooks/index';

import { AccessFeatureBadgeProps } from './types';
import {
  AccessFeatureBadgeRoot,
  AccessFeatureBadgeNextPlanChip,
} from './styles';

const AccessFeatureBadge = ({
  validFeature,
  availableToDate,
  showChip = true,
  openAccessFeatureModalOnClickChip = false,
  fullWidth = false,
  children,
}: AccessFeatureBadgeProps): ReactElement => {
  const {
    validateFeatureAccess,
    validateFeatureAvailableToDate,
    // hasAccessToFeature,
  } = useSubscriptionFeatures();

  const isDemo = useMemo(
    () =>
      !validateFeatureAccess(validFeature) &&
      validateFeatureAvailableToDate(availableToDate),
    [
      availableToDate,
      validFeature,
      validateFeatureAccess,
      validateFeatureAvailableToDate,
    ],
  );

  const invisble = useMemo(() => {
    if (!showChip) return true;

    if (isDemo) return false;

    return validateFeatureAccess(validFeature);
  }, [isDemo, showChip, validFeature, validateFeatureAccess]);

  return (
    <AccessFeatureBadgeRoot
      fullWidth={fullWidth}
      badgeContent={
        <AccessFeatureBadgeNextPlanChip
          validFeature={validFeature}
          availableToDate={availableToDate}
          openAccessFeatureModalOnClick={openAccessFeatureModalOnClickChip}
        />
      }
      invisible={invisble}
    >
      {children}
    </AccessFeatureBadgeRoot>
  );
};

export default AccessFeatureBadge;

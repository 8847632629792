import { styled, Box } from '@mui/material';

import { HEIGHT_ACTIVE_ROUTE_CARD } from '../ActiveRouteCard/styles';

export { HEIGHT_ACTIVE_ROUTE_CARD } from '../ActiveRouteCard/styles';

export const VISIBILITY_ITEMS = 3.5;

export const ActiveRoutesMenuContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: 400,

  '&::before': {
    content: '""',
    position: 'absolute',
    height: 54,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
}));

export const ActiveRoutesMenuHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  color: theme.palette.common.white,
  paddingBottom: theme.spacing(1.5),
}));

export const ActiveRoutesMenuContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2),
}));

export const ActiveRoutesMenuContentList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: theme.spacing(1),
  overflowY: 'auto',
  height: '100%',
  maxHeight: (HEIGHT_ACTIVE_ROUTE_CARD + 8) * VISIBILITY_ITEMS,
  minHeight: HEIGHT_ACTIVE_ROUTE_CARD,
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

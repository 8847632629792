import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

import { DialogAssignDriverProps } from './types';
import DialogAssignDriverProvider, {
  useDialogAssignDriverContext,
} from './DialogAssignDriverContext';
import DialogAssignDriverContent from './DialogAssignDriverContent';
import {
  DialogAssignDriverContainer,
  DialogAssignDriverLoadingContainer,
} from './styles';

const DialogAssignDriver = (): ReactElement => {
  const {
    open,
    onClose,
    availablesDrivers: { loading },
  } = useDialogAssignDriverContext();

  return (
    <DialogAssignDriverContainer open={open} onClose={onClose} maxWidth="xs">
      {loading && (
        <DialogAssignDriverLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogAssignDriverLoadingContainer>
      )}

      {!loading && <DialogAssignDriverContent />}
    </DialogAssignDriverContainer>
  );
};

const WrappedDialogAssignDriver = ({
  open,
  onClose,
  routeId,
  lastDriverId,
  showSnackBarSuccess = false,
  onSuccesSubmit,
  handleOpenDialogInviteDriver,
}: DialogAssignDriverProps): ReactElement => (
  <DialogAssignDriverProvider
    open={open}
    onClose={onClose}
    routeId={routeId}
    lastDriverId={lastDriverId}
    showSnackBarSuccess={showSnackBarSuccess}
    onSuccesSubmit={onSuccesSubmit}
    handleOpenDialogInviteDriver={handleOpenDialogInviteDriver}
  >
    <DialogAssignDriver />
  </DialogAssignDriverProvider>
);

export default WrappedDialogAssignDriver;

import { styled } from '@mui/material';

export const IconWithTooltipContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'size',
})<{ size: number }>(({ theme, color, size }) => ({
  '& svg': {
    color: color ?? theme.palette.action.active,
    fontSize: size ? size : 'inherit',
  },

  '& img': {
    width: size * 3,
    height: size * 2,
  },
}));

import { styled } from '@mui/material';

export const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  marginLeft: theme.maxWidths.lateralMenu,
  justifyContent: 'space-between',
  display: 'flex',
}));

export const FooterNameContainer = styled('footer')(({ theme }) => ({
  gap: theme.spacing(1),
  display: 'flex',
}));

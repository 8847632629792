import { chain } from 'lodash';

const hasData = (dataCount: number): boolean => dataCount > 0;

export const getOcurrencesDataCompany = (
  vehiclesCount: number,
  depositsCount: number,
  driversCount: number,
): number => {
  const ocurrencesData = [
    hasData(vehiclesCount),
    hasData(depositsCount),
    hasData(driversCount),
  ];

  const occurencesCount = chain(ocurrencesData).filter().size().value();

  return occurencesCount;
};

import { styled, Chip, chipClasses } from '@mui/material';

export const PartnerCompanyStatusChipChippRoot = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{
  ownerState: { color: string };
}>(({ ownerState, theme }) => ({
  minWidth: 100,
  fontWeight: theme.typography.fontWeightBold,
  textTransform: 'uppercase',

  [`&.${chipClasses.outlined}`]: {
    backgroundColor: 'transparent',
    borderColor: ownerState.color,
    color: ownerState.color,
  },

  [`&.${chipClasses.filled}`]: {
    backgroundColor: ownerState.color,
    color: theme.palette.common.white,
  },
}));

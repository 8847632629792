import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const ReportIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M2.49,25.61H25.93a1.85,1.85,0,0,1,.41,0,.94.94,0,0,1,.75,1,1,1,0,0,1-.89.87H1.78C.93,27.5.6,27.16.6,26.32V2.16a2.07,2.07,0,0,1,.05-.5.94.94,0,0,1,1-.65,1,1,0,0,1,.84.87c0,.11,0,.23,0,.35V25.61Z" />
    {/* eslint-disable-next-line max-len */}
    <path d="M23.79,5.74H22.44a.95.95,0,1,1,0-1.89h3.61a1,1,0,0,1,1,1V8.51a.95.95,0,1,1-1.89,0V7.09l-.27.25c-2.13,2.13-4.27,4.26-6.39,6.41a1.57,1.57,0,0,1-1.22.51c-2.43,0-4.85,0-7.28,0a.36.36,0,0,0-.4.28Q8,18.75,6.27,23a1,1,0,0,1-1,.74,1,1,0,0,1-.76-1.34L5.82,19C6.6,17,7.38,15.08,8.17,13.13a1,1,0,0,1,1.14-.77h7.63a.65.65,0,0,0,.51-.2L23.6,6l.22-.2,0-.07" />
  </SvgIcon>
);

export default ReportIcon;

import { includes, chain } from 'lodash';

import {
  PartnerCompanyTypes,
  PartnerCompanyStatusTypes,
} from '~globals/types/enums';
import {
  PARTNER_COMPANY_TYPES,
  PARTNER_COMPANY_STATUS_TYPES,
} from '~constants/partnerCompany';
import { Nullable } from '~globals/types';
import { PartnerCompany } from '~services/partnerCompany/types';
import { AuthExtendedCompany } from '~services/auth/types';

export const getPartnerCompanyTypeDescription = (
  typeId: PartnerCompanyTypes,
): string =>
  chain(PARTNER_COMPANY_TYPES)
    .find({ value: typeId })
    .get('label', '')
    .value() as string;

export const validatePartnerCompanyType = (
  typeId: PartnerCompanyTypes,
  validStates: PartnerCompanyTypes[],
): boolean => includes(validStates, typeId);

export const isCarrierPartnerCompany = (typeId: PartnerCompanyTypes): boolean =>
  validatePartnerCompanyType(typeId, [PartnerCompanyTypes.Carrier]);

export const isGiverPartnerCompany = (typeId: PartnerCompanyTypes): boolean =>
  validatePartnerCompanyType(typeId, [PartnerCompanyTypes.Giver]);

export const getPartnerCompanyStatusTypeDescription = (
  statusTypeId: PartnerCompanyStatusTypes,
): string =>
  chain(PARTNER_COMPANY_STATUS_TYPES)
    .find({ value: statusTypeId })
    .get('label', '')
    .value() as string;

export const validatePartnerCompanyStatusType = (
  statusTypeId: PartnerCompanyStatusTypes,
  validStates: PartnerCompanyStatusTypes[],
): boolean => includes(validStates, statusTypeId);

export const isActivedPartnerCompany = (
  statusTypeId: PartnerCompanyStatusTypes,
): boolean =>
  validatePartnerCompanyStatusType(statusTypeId, [
    PartnerCompanyStatusTypes.Active,
  ]);

export const getPartnerCompanyCurrentCompanyInfo = (
  currentPartnerCompany: PartnerCompany,
): Nullable<AuthExtendedCompany> => {
  let currentCompany: Nullable<AuthExtendedCompany> = null;

  if (isCarrierPartnerCompany(currentPartnerCompany.partnerCompanyTypeId)) {
    currentCompany = currentPartnerCompany.carrierCompany;
  }

  if (isGiverPartnerCompany(currentPartnerCompany.partnerCompanyTypeId)) {
    currentCompany = currentPartnerCompany.giverCompany;
  }

  return currentCompany;
};

export const getSelectedPartnerCompanyInfo = (
  currentSelectdPartnerCompany: Nullable<PartnerCompany>,
): string => {
  let info = '';

  if (currentSelectdPartnerCompany) {
    const currentCompany = getPartnerCompanyCurrentCompanyInfo(
      currentSelectdPartnerCompany,
    );

    const currentParterCompanyTypeDescription =
      getPartnerCompanyTypeDescription(
        currentSelectdPartnerCompany.partnerCompanyTypeId,
      );

    info = `${currentParterCompanyTypeDescription} / ${
      currentCompany?.name ?? currentSelectdPartnerCompany.reference
    }`;
  }

  return info;
};

export const isCurrentLoggedCompany = (
  currentCompanyId: Nullable<string>,
  loggedCompanyId: Nullable<string>,
): boolean => currentCompanyId === loggedCompanyId;

import {
  ReactElement,
  SyntheticEvent,
  useMemo,
  useCallback,
  useState,
  memo,
} from 'react';
import { useFormikContext } from 'formik';
import { throttle } from 'lodash';

import { PlacesAutocomplete } from '~components/index';
import { PlaceType } from '~hooks/usePlacesAutocomplete';
import { hasError } from '~utils/formHelpers';

import { FIELDS_NAME, getInitialValues, toPathField } from '../utils';

const GeoErrorUpateDialogPlacesAutocomplete = ({
  fieldIndex,
}: {
  fieldIndex: number;
}): ReactElement => {
  const { values, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext<ReturnType<typeof getInitialValues>>();

  const [currentValue, setCurrentValue] = useState(
    values[FIELDS_NAME.ITEMS][fieldIndex][FIELDS_NAME.ITEM_ADDRESS],
  );

  const getCoordAddres = useMemo(
    () =>
      throttle<google.maps.Geocoder['geocode']>((request, callback) => {
        new window.google.maps.Geocoder().geocode(request, callback);
      }, 200),
    [],
  );

  const handleChangeAddress = useCallback(
    (_e: SyntheticEvent<Element, Event>, newValue: PlaceType | null) => {
      setFieldValue(
        toPathField(fieldIndex, FIELDS_NAME.ITEM_ADDRESS),
        newValue?.description ?? '',
      );

      setCurrentValue(newValue?.description ?? '');

      getCoordAddres({ placeId: newValue?.place_id }, (results) => {
        if (results) {
          const addresCoords = results[0].geometry.location.toJSON();

          setFieldValue(
            toPathField(fieldIndex, FIELDS_NAME.ITEM_ADDRESS_COORDS),
            addresCoords,
          );

          const zipCode = results[0].address_components
            .find((addressComponent) =>
              addressComponent.types.includes('postal_code'),
            )
            ?.long_name.replace(/\D/g, '');

          if (zipCode) {
            setFieldValue(
              toPathField(fieldIndex, FIELDS_NAME.ITEM_ZIP_CODE),
              zipCode,
            );
          }
        }
      });
    },
    [fieldIndex, getCoordAddres, setFieldValue],
  );

  const handleBlurAddress = useCallback((): void => {
    setFieldTouched(toPathField(fieldIndex, FIELDS_NAME.ITEM_ADDRESS), true);
  }, [fieldIndex, setFieldTouched]);

  const error = useMemo(
    () =>
      hasError(
        touched,
        errors,
        toPathField(fieldIndex, FIELDS_NAME.ITEM_ADDRESS),
      ),
    [errors, fieldIndex, touched],
  );

  return (
    <PlacesAutocomplete
      onChange={handleChangeAddress}
      onBlur={handleBlurAddress}
      error={error}
      initialValueRequest={currentValue}
      fullWidth
    />
  );
};

export default memo(GeoErrorUpateDialogPlacesAutocomplete);

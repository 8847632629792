import { ReactElement, useCallback, useMemo } from 'react';
import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { useFormikContext } from 'formik';

import { ORDER_COLLECTED_TRANSFER_OPTIONS } from '~constants/businessProfiles';
import { BusinessProfileOrderCollectedTransferTypes } from '~globals/types/enums';

import {
  useBusinessProfileContext,
  BusinessProfileContextPanelsId,
} from '../../BusinessProfileContext';
import { FIELDS_NAMES, initialValues } from '../utils';

const BusinessProfileOrderCollectedTransfer = (): ReactElement => {
  const { handleNextTab, handleGoBack } = useBusinessProfileContext();

  const { values, getFieldProps, setFieldValue } =
    useFormikContext<typeof initialValues>();

  const orderCollectedTransferValue = useMemo(
    () => values[FIELDS_NAMES.ORDER_COLLECTED_TRANSFER],
    [values],
  );

  const onGoBack = useCallback(() => {
    setFieldValue(FIELDS_NAMES.ORDER_COLLECTED_TRANSFER, '');
    handleGoBack('orderCollectedTransfer');
  }, [handleGoBack, setFieldValue]);

  const onNextTab = useCallback(() => {
    let nextTab: BusinessProfileContextPanelsId = 'orderCentralizationTransfer';

    if (
      orderCollectedTransferValue ===
      BusinessProfileOrderCollectedTransferTypes.ToBuyers
    )
      nextTab = 'descriptionConfirm';

    handleNextTab(nextTab);
  }, [orderCollectedTransferValue, handleNextTab]);

  const isDisabledNextButton = useMemo(
    () => !orderCollectedTransferValue,
    [orderCollectedTransferValue],
  );

  return (
    <Stack spacing={2} flex={1} justifyContent="space-between">
      <Stack spacing={1}>
        <Typography variant="subtitle1" fontWeight="bold">
          Respecto a la recolección de órdenes:
        </Typography>

        <Typography variant="subtitle2">
          Después de realizar la recolección, ¿a dónde llevan las órdenes?
        </Typography>

        <RadioGroup {...getFieldProps(FIELDS_NAMES.ORDER_COLLECTED_TRANSFER)}>
          {ORDER_COLLECTED_TRANSFER_OPTIONS.map((option) => (
            <FormControlLabel
              key={`order-collected-transfer-${option.value}`}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button variant="outlined" color="secondary" onClick={onGoBack}>
          Regresar
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={onNextTab}
          disabled={isDisabledNextButton}
        >
          Continuar
        </Button>
      </Stack>
    </Stack>
  );
};

export default BusinessProfileOrderCollectedTransfer;

import { ReactElement, useMemo } from 'react';

import { ItemCollectsList } from '~components/index';

import { useDialogFeedbackStopContext } from '../../DialogFeedbackStopContext';

const DialogFeebackStopOrders = (): ReactElement => {
  const { data } = useDialogFeedbackStopContext();

  const itemsCollects = useMemo(
    () => data?.routeItem.item?.itemCollects ?? [],
    [data],
  );

  return <ItemCollectsList list={itemsCollects} />;
};

export default DialogFeebackStopOrders;

import { ReactElement, useMemo } from 'react';
import { Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Nullable } from '~globals/types';
import { getStringifyUrlQueryString } from '~utils/router';
import { PATHS } from '~constants/paths';
import { useSubscriptionFeatures } from '~hooks/index';

import { ACCESS_ALERT_TEXT } from '../utils';
import { AccessFeatureCommonProps } from '../types';
import AccessFeatureHidden from '../Hidden';
import { AccessFeatureHiddenType } from '../Hidden/types';

import {
  AccessFeatureAlertCard,
  AccessFeatureAlertCardContent,
  AccessFeatureAlertCardActions,
} from './styles';

const AccessFeatureAlert = ({
  validFeature,
  availableToDate,
}: AccessFeatureCommonProps): Nullable<ReactElement> => {
  const { validateFeatureAccess, validateFeatureAvailableToDate } =
    useSubscriptionFeatures();

  const isDemo = useMemo(
    () =>
      !validateFeatureAccess(validFeature) &&
      validateFeatureAvailableToDate(availableToDate),
    [
      availableToDate,
      validFeature,
      validateFeatureAccess,
      validateFeatureAvailableToDate,
    ],
  );

  const type = useMemo<AccessFeatureHiddenType>(
    () => (isDemo ? 'notAccess' : 'hasAccess'),
    [isDemo],
  );

  return (
    <AccessFeatureHidden
      validFeature={validFeature}
      availableToDate={availableToDate}
      type={type}
      validDemo={false}
    >
      <AccessFeatureAlertCard>
        <AccessFeatureAlertCardContent>
          <Typography component="h3" variant="body2" fontWeight="bold">
            {ACCESS_ALERT_TEXT}
          </Typography>

          <AccessFeatureAlertCardActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              component={RouterLink}
              to={getStringifyUrlQueryString({
                url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
                query: {
                  currentTab: 'plans-packages',
                },
              })}
            >
              Ver Planes
            </Button>

            <Button
              variant="text"
              color="inherit"
              size="small"
              href="https://routix.io/planes"
              target="_blank"
            >
              Comparar Planes
            </Button>
          </AccessFeatureAlertCardActions>
        </AccessFeatureAlertCardContent>
      </AccessFeatureAlertCard>
    </AccessFeatureHidden>
  );
};

export default AccessFeatureAlert;

import {
  styled,
  Stepper,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  CircularProgress,
} from '@mui/material';

export interface StepperGenerateRouteStyledProps {
  light?: boolean;
}

export const StepperGenerateRouteStepper = styled(Stepper)({
  maxWidth: 600,
});

export const StepperGenerateRouteStep = styled(Step)({
  padding: 0,
});

export const StepperGenerateRouteStepConnector = styled(StepConnector, {
  shouldForwardProp: (prop) => prop !== 'light',
})<StepperGenerateRouteStyledProps>(({ theme, light }) => ({
  backgroundColor: light
    ? theme.palette.common.white
    : theme.palette.background.default,
  padding: '4px 0',

  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: light
      ? theme.palette.common.white
      : theme.palette.background.default,
    borderRadius: 1,
  },

  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },

  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const StepperGenerateRouteStepLabel = styled(StepLabel)({
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
});

export const StepperGenerateRouteStepIconContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'light',
})<StepperGenerateRouteStyledProps>(({ theme, light }) => ({
  backgroundColor: light
    ? theme.palette.common.white
    : theme.palette.background.default,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));

export const StepperGenerateRouteStepIconBoxStatus = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.tertiary.main,
  color: theme.palette.common.white,
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),

  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

export const StepperGenerateRouteStepIconBoxProgress = styled(CircularProgress)(
  ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    color: theme.palette.primary.main,
  }),
);

import { useMemo } from 'react';
import { get } from 'lodash';
import { useTheme } from '@mui/material';
import { red, green } from '@mui/material/colors';

import { Nullable } from '~globals/types';
import { CollectItemStateTypes } from '~globals/types/enums';

interface OrderReceivedStateInfoItem {
  description: string;
  color: string;
}

type OrderReceivedStateList = {
  [state in CollectItemStateTypes]: OrderReceivedStateInfoItem;
};

export const useOrderReceivedStateInfo = (
  collectItemStateTypeId: Nullable<CollectItemStateTypes>,
): OrderReceivedStateInfoItem => {
  const currentTheme = useTheme();

  const stateList = useMemo<OrderReceivedStateList>(
    () => ({
      [CollectItemStateTypes.ToValidate]: {
        description: 'Sin colecta',
        color: currentTheme.palette.secondary.main,
      },
      [CollectItemStateTypes.ToCollect]: {
        description: 'A colectar',
        color: currentTheme.palette.secondary.main,
      },
      [CollectItemStateTypes.InCollect]: {
        description: 'En colecta',
        color: currentTheme.palette.primary.main,
      },
      [CollectItemStateTypes.Validated]: {
        description: 'Colectado',
        color: green[700],
      },
      [CollectItemStateTypes.NotValidated]: {
        description: 'No colectado',
        color: red[700],
      },
    }),
    [currentTheme],
  );

  const currentInfo = useMemo<OrderReceivedStateInfoItem>(
    () =>
      get(
        stateList,
        collectItemStateTypeId ?? CollectItemStateTypes.ToValidate,
      ),
    [stateList, collectItemStateTypeId],
  );

  return currentInfo;
};

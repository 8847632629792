import { ReactElement, useMemo } from 'react';
import { Card, Typography } from '@mui/material';
import { groupBy } from 'lodash';

import { PlanningRejectedOperationsErrorsProps } from './types';
import {
  PlanningRejectedOperationsErrorsContainer,
  PlanningRejectedOperationsErrorsErrorsListContainer,
} from './styles';
import PlanningRejectedOperationsErrorsItem from './PlanningRejectedOperationsErrorsItem';

const PlanningRejectedOperationsErrors = ({
  title = 'Se encontraron los siguientes errores',
  errors,
  dense = false,
}: PlanningRejectedOperationsErrorsProps): ReactElement | null => {
  const groupedErrors = useMemo(() => groupBy(errors, 'Title'), [errors]);

  if (!errors.length) return null;

  return (
    <Card>
      <PlanningRejectedOperationsErrorsContainer dense={dense}>
        <Typography component="h4" variant="subtitle1" color="error">
          {title}
        </Typography>

        <PlanningRejectedOperationsErrorsErrorsListContainer>
          {Object.entries(groupedErrors).map(([errorKey, currentErrors]) => (
            <PlanningRejectedOperationsErrorsItem
              key={`planning-rejected-operations-errors-group-${errorKey}`}
              label={errorKey}
              errors={currentErrors}
            />
          ))}
        </PlanningRejectedOperationsErrorsErrorsListContainer>
      </PlanningRejectedOperationsErrorsContainer>
    </Card>
  );
};

export default PlanningRejectedOperationsErrors;

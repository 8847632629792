import { ReactElement, useMemo, memo } from 'react';

import { parseSkillsStringToArray } from '~utils/vehicle';

import SkillList from './SkillList';
import { SkillsListInfoProps } from './types';
import { SkillsListInfoTooltip, SkillsListInfoChip } from './styles';

const SkillsListInfo = ({
  skills,
  fontSize = 14,
  fontWeight = 500,
}: SkillsListInfoProps): ReactElement | null => {
  const skillsList = useMemo(() => parseSkillsStringToArray(skills), [skills]);

  if (!skillsList.length) return null;

  return (
    <SkillsListInfoTooltip
      title={<SkillList skillsList={skillsList} />}
      arrow
      placement="right"
    >
      <SkillsListInfoChip
        label={`+${skillsList.length}`}
        ownerState={{ fontSize, fontWeight }}
      />
    </SkillsListInfoTooltip>
  );
};

export default memo(SkillsListInfo);

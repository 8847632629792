import { ReactElement, useCallback, useEffect } from 'react';
import { Collapse, Stack, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Close as CloseIcon } from '@mui/icons-material';

import {
  useSubscriptionRoutes,
  useToggle,
  useTypePartnerCompany,
} from '~hooks/index';
import { getStringifyUrlQueryString } from '~utils/router';
import { PATHS } from '~constants/paths';

import SubscriptionAvailableRoutesStateCircularProgress from '../CircularProgress';

import { SubscriptionAvailableRoutesStateAlertProps } from './types';
import {
  classes,
  SubscriptionAvailableRoutesStateAlertCard,
  SubscriptionAvailableRoutesStateAlertCardContent,
  SubscriptionAvailableRoutesStateAlertCardCloseButton,
} from './styles';

const TEXTS = {
  title: {
    itsHalfway: 'Estás llegando al límite de creación de rutas',
    isEnding: 'Estás llegando al límite de creación de rutas',
    isFull: 'Llegaste al límite de creación de rutas',
  },
  description: {
    itsHalfway:
      // eslint-disable-next-line max-len
      'Pronto no podrás generar nuevas rutas. Te recomendamos adquirir un plan superior o un pack de rutas para evitar interrupciones en el servicio de planificación de rutas.',
    isEnding:
      // eslint-disable-next-line max-len
      'Pronto no podrás generar nuevas rutas. Te recomendamos adquirir un plan superior o un pack de rutas para evitar interrupciones en el servicio de planificación de rutas.',
    isFull:
      // eslint-disable-next-line max-len
      'No puedes generar nuevas rutas. Te recomendamos adquirir un plan superior o un pack de rutas para evitar interrupciones en el servicio de planificación de rutas.',
  },
};

export const SubscriptionAvailableRoutesStateAlert = ({
  withCloseAction = true,
  withButtons = true,
  ...rest
}: SubscriptionAvailableRoutesStateAlertProps): ReactElement => {
  const { itsHalfway, isEnding, isFull, currentRoutes, maxRoutes } =
    useSubscriptionRoutes();
  const { isGiver } = useTypePartnerCompany();

  const [expanded, toggleExpanded] = useToggle(false);

  useEffect(() => {
    if (!isGiver) {
      toggleExpanded(itsHalfway || isEnding);
    }
  }, [isGiver, isEnding, itsHalfway, toggleExpanded]);

  const getCurrentText = useCallback(
    (key: 'title' | 'description') => {
      let text = '';

      if (itsHalfway) text = TEXTS[key].itsHalfway;

      if (isEnding) text = TEXTS[key].isEnding;

      if (isFull) text = TEXTS[key].isFull;

      return text;
    },
    [isEnding, itsHalfway, isFull],
  );

  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit {...rest}>
      <SubscriptionAvailableRoutesStateAlertCard
        itsHalfway={itsHalfway}
        isEnding={isEnding}
      >
        <SubscriptionAvailableRoutesStateAlertCardContent>
          {withCloseAction && (
            <SubscriptionAvailableRoutesStateAlertCardCloseButton
              onClick={() => toggleExpanded(false)}
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </SubscriptionAvailableRoutesStateAlertCardCloseButton>
          )}

          <SubscriptionAvailableRoutesStateCircularProgress
            currentRoutes={currentRoutes}
            maxRoutes={maxRoutes}
            size="medium"
          />

          <Stack spacing={1}>
            <Stack spacing={0.5}>
              <Typography component="h3" variant="body2" fontWeight="bold">
                {getCurrentText('title')}
              </Typography>

              <Typography variant="caption">
                {getCurrentText('description')}
              </Typography>
            </Stack>

            {withButtons && (
              <Stack spacing={1} direction="row">
                <Button
                  variant="contained"
                  size="small"
                  component={RouterLink}
                  to={getStringifyUrlQueryString({
                    url: PATHS.CONFIG.SUBSCRIPTIONS.BASE,
                    query: {
                      currentTab: 'plans-packages',
                    },
                  })}
                  className={classes.mainActionButton}
                >
                  Ver Planes
                </Button>

                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  href="https://routix.io/planes"
                  target="_blank"
                >
                  Comparar Planes
                </Button>
              </Stack>
            )}
          </Stack>
        </SubscriptionAvailableRoutesStateAlertCardContent>
      </SubscriptionAvailableRoutesStateAlertCard>
    </Collapse>
  );
};

export default SubscriptionAvailableRoutesStateAlert;

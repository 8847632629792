import { useMemo } from 'react';
import { round } from 'lodash';

export interface UseAvailablesRoutesParams {
  currentRoutes: number;
  maxRoutes: number;
}

export interface AvailablesRoutes {
  availablesRoutes: number;
  progress: number;
  itsHalfway: boolean;
  isEnding: boolean;
  isFull: boolean;
}

const PROGRESS_ROUTES = {
  HALFWAY: 75,
  ENDING: 90,
  FULL: 100,
};

export const useAvailablesRoutes = ({
  currentRoutes,
  maxRoutes,
}: UseAvailablesRoutesParams): AvailablesRoutes => {
  const availablesRoutes = useMemo(
    () => maxRoutes - currentRoutes,
    [currentRoutes, maxRoutes],
  );

  const progress = useMemo(() => {
    const currentProgress = (currentRoutes / maxRoutes) * 100;

    return round(currentProgress, 2);
  }, [currentRoutes, maxRoutes]);

  const itsHalfway = useMemo(
    () => progress >= PROGRESS_ROUTES.HALFWAY,
    [progress],
  );

  const isEnding = useMemo(
    () => progress >= PROGRESS_ROUTES.ENDING,
    [progress],
  );

  const isFull = useMemo(() => progress >= PROGRESS_ROUTES.FULL, [progress]);

  return { availablesRoutes, progress, itsHalfway, isEnding, isFull };
};

import { ReactElement } from 'react';
import { grey, blue, green } from '@mui/material/colors';
import moment from 'moment-timezone';

import { StatisticsProgress } from '~components/index';
import { PATHS } from '~constants/index';
import { getStringifyUrlQueryString } from '~utils/router';
import { formatterDate } from '~utils/formatter';
import { RouteStateTypes } from '~globals/types/enums';

import { RoutesStatiticsProps } from './types';
import { RoutesStatiticsContainer } from './styles';

const currentDate = moment();

const commonsDates = {
  dateFrom: formatterDate(currentDate, { format: 'MM-DD-YYYY' }),
  dateTo: formatterDate(currentDate, { format: 'MM-DD-YYYY' }),
};

const pathsRedirects = {
  pending: getStringifyUrlQueryString({
    url: PATHS.ROUTES.BASE,
    query: {
      states: [
        RouteStateTypes.New,
        RouteStateTypes.Assigned,
        RouteStateTypes.Approve,
      ],
      ...commonsDates,
    },
  }),
  inProgress: getStringifyUrlQueryString({
    url: PATHS.ROUTES.BASE,
    query: {
      states: [RouteStateTypes.InProgress],
      ...commonsDates,
    },
  }),
  finish: getStringifyUrlQueryString({
    url: PATHS.ROUTES.BASE,
    query: {
      states: [RouteStateTypes.Finalized, RouteStateTypes.Rejected],
      ...commonsDates,
    },
  }),
};

const RoutesStatitics = ({
  total,
  pending,
  inProgress,
  finish,
  size = 'small',
  gutterTop = true,
  withLinks = false,
}: RoutesStatiticsProps): ReactElement => (
  <RoutesStatiticsContainer gutterTop={gutterTop}>
    <StatisticsProgress
      max={total}
      val={pending}
      size={size}
      customColor={grey[700]}
      label="A REALIZAR"
      labelOptions={{ isLink: withLinks, path: pathsRedirects.pending }}
    />

    <StatisticsProgress
      max={total}
      val={inProgress}
      size={size}
      customColor={blue[700]}
      label="EN CAMINO"
      labelOptions={{ isLink: withLinks, path: pathsRedirects.inProgress }}
    />

    <StatisticsProgress
      max={total}
      val={finish}
      size={size}
      customColor={green[700]}
      label="COMPLETADOS"
      labelOptions={{ isLink: withLinks, path: pathsRedirects.finish }}
    />
  </RoutesStatiticsContainer>
);

export default RoutesStatitics;

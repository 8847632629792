import { LoadingButton } from '@mui/lab';
import { Avatar, styled } from '@mui/material';

export const ConfigPartnerCompanyLogo = styled(Avatar)({
  width: 100,
  height: 100,
});

export const ConfigPartnetCompanyNewSubmitButton = styled(LoadingButton)({
  minWidth: 200,
});

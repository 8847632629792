import { RefObject, useRef, useState, useCallback } from 'react';

export const useToggleMenu = (): [
  RefObject<HTMLButtonElement>,
  boolean,
  () => void,
  (nextValue: boolean) => void,
] => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), []);

  const handleToggleWithValue = useCallback(
    (nextValue: boolean) => setOpen(nextValue),
    [],
  );

  return [anchorEl, open, handleToggle, handleToggleWithValue];
};

import { ReactElement, useCallback, useEffect, useRef } from 'react';
import {
  Stack,
  TextField,
  DialogContent,
  DialogActions,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { useLazyRequest, useTypePartnerCompany } from '~hooks/index';
import { addPartnerCompany } from '~services/partnerCompany';
import { PartnerCompanyAssociationData } from '~services/partnerCompany/types';
import { hasError } from '~utils/index';
import { PartnerCompanyTypes } from '~globals/types/enums';
import { COMPANY_TYPE_INFO } from '~constants/companyTypesInfo';

import { useDialogAssociatePartnerCompanyContex } from '../../DialogAssociatePartnerCompanyContext';
import { FIELDS_NAME, getInitialValues, validationSchema } from './utils';

const DialogAssociatePartnerCompanyAssociation = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    open,
    onAssociate,
    setAssociateData,
    setActiveTab,
    onClose,
    onOpenDialogInvitationCompany,
  } = useDialogAssociatePartnerCompanyContex();

  const { isCarrier } = useTypePartnerCompany();

  const currentAssociationType = useRef<PartnerCompanyTypes>(
    isCarrier ? PartnerCompanyTypes.Giver : PartnerCompanyTypes.Carrier,
  );

  const [, loading, , executeAssociatePartnerCompany] = useLazyRequest({
    request: addPartnerCompany,
    withPostSuccess: (response) => {
      const currentData = response.data?.data as PartnerCompanyAssociationData;

      setAssociateData({
        ...currentData,
        associationType: currentAssociationType.current,
      });
      onAssociate?.(currentData);
      setActiveTab('sendInvitation');
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo asociar la empresa, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      await executeAssociatePartnerCompany({
        partnerCompanyType: values[FIELDS_NAME.COMPANY_TYPE],
        reference: values[FIELDS_NAME.REFERENCE],
      });

      setSubmitting(false);
    },
    [executeAssociatePartnerCompany],
  );

  const {
    submitForm,
    isSubmitting,
    getFieldProps,
    touched,
    errors,
    resetForm,
    values,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    initialValues: getInitialValues(currentAssociationType.current),
    validationSchema,
    onSubmit,
  });

  const handleOpenDialogInvitationCompany = useCallback(() => {
    onClose();

    setTimeout(() => {
      onOpenDialogInvitationCompany?.();
    }, 500);
  }, [onClose, onOpenDialogInvitationCompany]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Typography component="p" variant="body2">
            Esta acción te permite enviar o recibir pedidos de otras empresas
            que utilizan Routix para gestionar su logística
          </Typography>

          {isCarrier && (
            <Stack spacing={1}>
              <Typography
                component="p"
                variant="body2"
                color="secondary.main"
                fontWeight="bold"
              >
                Selecciona el rol que deseas asignar a la empresa invitada:
              </Typography>

              <List disablePadding dense>
                {COMPANY_TYPE_INFO.map((companyType) => (
                  <ListItemButton
                    key={`company-type-${companyType.companyTypeId}`}
                    disableGutters
                    selected={
                      values[FIELDS_NAME.COMPANY_TYPE] ===
                      companyType.companyTypeId
                    }
                    onClick={() => {
                      currentAssociationType.current =
                        companyType.companyTypeId;

                      setFieldValue(
                        FIELDS_NAME.COMPANY_TYPE,
                        companyType.companyTypeId,
                      );
                    }}
                    sx={{ borderRadius: 0.5 }}
                  >
                    <ListItemIcon sx={{ marginRight: 1 }}>
                      <Box
                        component="img"
                        {...companyType.iconImage}
                        style={{ width: 70, height: 50 }}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={`${companyType.label} ${companyType.subLabel}`}
                      primaryTypographyProps={{
                        variant: 'body2',
                        fontWeight: '800',
                        color: 'primary',
                      }}
                      secondary={companyType.scopeDescription}
                      secondaryTypographyProps={{
                        variant: 'caption',
                      }}
                      sx={{ margin: 0 }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Stack>
          )}

          <Stack spacing={1}>
            <Typography
              component="p"
              variant="body2"
              color="secondary.main"
              fontWeight="bold"
            >
              Agrega una nota de referencia para identificar tu invitación
            </Typography>

            <Stack>
              <TextField
                label="Referencia"
                fullWidth
                autoComplete="off"
                error={hasError(touched, errors, FIELDS_NAME.REFERENCE)}
                {...getFieldProps(FIELDS_NAME.REFERENCE)}
                InputLabelProps={{ shrink: true }}
              />

              <Typography variant="caption" color="text.secondary">
                Nombre para identificar la asociación
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Link
          color="primary"
          variant="caption"
          fontWeight="bold"
          underline="hover"
          sx={{ cursor: 'pointer' }}
          onClick={handleOpenDialogInvitationCompany}
        >
          Ya tengo un código de invitación
        </Link>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loading || isSubmitting}
          disabled={!dirty || !isValid}
        >
          Generar invitación
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DialogAssociatePartnerCompanyAssociation;

import { ReactElement, useCallback, MouseEvent } from 'react';
import { PageButtonRendererOptions } from 'react-bootstrap-table-next';

import { ButtonPage } from './styles';

export interface PaginationButtonPageProps extends PageButtonRendererOptions {
  onPageChange: (page: string | number) => void;
}

const PaginationButtonPage = ({
  page,
  title,
  active,
  disabled,
  onPageChange,
}: PaginationButtonPageProps): ReactElement => {
  const handleChangePage = useCallback(
    (newPage: string | number) => (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onPageChange(newPage);
    },
    [onPageChange],
  );

  return (
    <ButtonPage
      variant={active ? 'contained' : 'outlined'}
      title={title}
      disabled={disabled}
      onClick={handleChangePage(page)}
    >
      {page}
    </ButtonPage>
  );
};

export default PaginationButtonPage;

import { ReactElement, useMemo, useCallback } from 'react';
import { Stack, Typography, ListItem } from '@mui/material';
import { useFormikContext } from 'formik';
import { find } from 'lodash';
import Map, {
  NavigationControl,
  FullscreenControl,
  Marker,
} from 'react-map-gl';
import { ColumnDescription } from 'react-bootstrap-table-next';

import {
  UserInfo,
  DataTable,
  SkillsListInfo,
  CapabilitiesInfo,
  OrderClientInfo,
  OrderPendingStateChip,
} from '~components/index';
import { Marker as MarkerIcon } from '~components/Icons';
import { ModeSateliteControl } from '~components/MapCustomControl';
import { TOKEN } from '~utils/mapbox';
import { pluralize } from '~utils/commons';
import { ItemExtended } from '~services/item/types';

import { useDialogAssignCarrierCompanyContext } from '../../DialogAssignCarrierCompanyContext';
import { FIELDS_NAME, getInitialValues } from '../../utils';
import {
  DialogAssignCarrierCompanyMapContainer,
  DialogAssignCarrierCompanyCard,
  DialogAssignCarrierCompanyCardContent,
  DialogAssignCarrierCompanyCardHeaderText,
  DialogAssignCarrierCompanyCardHeaderIndicators,
  DialogAssignCarrierCompanyCardDataTableContainer,
} from '../../styles';

const DialogAssignCarrierCompanyConfirmation = (): ReactElement => {
  const { values } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const {
    activesPartnerCompanies: { list: activesPartnerCompaniesList },
    warehouses: { list: warehousesList },
    selectedOrders: {
      selectedRowsIds: selectedOrdesId,
      selectedRows: selectedOrders,
    },
  } = useDialogAssignCarrierCompanyContext();

  const currentCarrierCompanyInfo = useMemo(() => {
    const carrierCompanyId = values[FIELDS_NAME.CARRIER_COMPANY_ID];

    return find(activesPartnerCompaniesList, { id: carrierCompanyId });
  }, [activesPartnerCompaniesList, values]);

  const needCollectValue = useMemo(
    () => values[FIELDS_NAME.NEED_COLLECT],
    [values],
  );

  const originPointInfo = useMemo(() => {
    const originPointId = values[FIELDS_NAME.ORIGIN_POINT];

    return find(warehousesList, { warehouseId: originPointId });
  }, [warehousesList, values]);

  const addresCoordsValue = useMemo(
    () => values[FIELDS_NAME.ADDRESS_COORDS],
    [values],
  );

  const addresNotesValue = useMemo(
    () => values[FIELDS_NAME.ADDRESS_NOTES],
    [values],
  );

  const columnsOrders = useMemo<ColumnDescription<ItemExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        formatter: (_cell, row) => (
          <OrderClientInfo
            name={row.title}
            itemTypeId={row.itemTypeId}
            trackingCode={row.trackingCode}
          />
        ),
      },
      {
        dataField: 'tags',
        text: 'Grupo',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 180 },
      },
      {
        dataField: 'skillsNeeded',
        text: 'Cargas especiales',
        classes: 'truncated-two-lines',
        formatter: (cell) => <SkillsListInfo skills={cell} />,
        headerStyle: { width: 160 },
        align: 'center',
      },
      {
        dataField: 'collectItemStateTypeId',
        isDummyField: true,
        text: 'Estado',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => (
          <OrderPendingStateChip
            carrierCompanyId={row.carrierCompanyId}
            collectItemStateTypeId={row.collectItemStateTypeId}
          />
        ),
        headerStyle: { width: 160 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'unit1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.unit1}
            capacity2={row.unit2}
            numberOfPackages={row.numberOfPackages}
            maximumVisits={null}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 120 },
      },
    ],
    [],
  );

  const getSelectedEntity = useCallback(
    (selecteds: Array<number | string>, entity: string) => {
      const countSelecteds = selecteds.length;
      const entityPluralize = pluralize({
        singular: entity,
        count: countSelecteds,
        includeCount: true,
      });

      const selectedText = pluralize({
        singular: 'seleccionado',
        count: countSelecteds,
        includeCount: false,
      });

      return `(${entityPluralize} ${selectedText})`;
    },
    [],
  );

  const indicatorsOrdersCharge = useMemo(
    () =>
      selectedOrders.reduce(
        (acc, selectedOrder) => {
          acc.capacity1 += selectedOrder.unit1;
          acc.numberOfPackages += selectedOrder.numberOfPackages;

          return acc;
        },
        { capacity1: 0, capacity2: 0, numberOfPackages: 0 },
      ),
    [selectedOrders],
  );

  return (
    <Stack spacing={2} flex={1}>
      <Typography variant="body1">
        Confirma los datos para la asignación
      </Typography>

      <Stack direction="row" spacing={3}>
        <Stack spacing={1} flex={1}>
          {currentCarrierCompanyInfo && (
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body2" fontWeight="bold">
                Empresa transportista:
              </Typography>

              <UserInfo
                name={currentCarrierCompanyInfo.name}
                urlAvatar={currentCarrierCompanyInfo.logoFile?.url}
                fontSize={13}
              />
            </Stack>
          )}

          <Stack spacing={1} direction="row">
            <Typography variant="body2" fontWeight="bold">
              Mis pedidos necesitan colecta:
            </Typography>

            <Typography variant="body2">
              {needCollectValue ? 'Si' : 'No'}
            </Typography>
          </Stack>

          {needCollectValue && (
            <Stack spacing={1}>
              {originPointInfo && (
                <Stack spacing={1} direction="row">
                  <Typography variant="body2" fontWeight="bold">
                    Punto de origen:
                  </Typography>

                  <Typography variant="body2">
                    {originPointInfo.title}
                  </Typography>
                </Stack>
              )}

              <Stack spacing={1} direction="row">
                <Typography variant="body2" fontWeight="bold">
                  Direccíon:
                </Typography>

                <Typography variant="body2">
                  {values[FIELDS_NAME.ADDRESS]}
                </Typography>
              </Stack>

              {addresNotesValue && (
                <Stack spacing={1} direction="row">
                  <Typography variant="body2" fontWeight="bold">
                    Notas de dirección:
                  </Typography>

                  <Typography variant="body2">{addresNotesValue}</Typography>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>

        {needCollectValue && (
          <Stack spacing={1} flex={1} minHeight={200}>
            {addresCoordsValue && (
              <DialogAssignCarrierCompanyMapContainer>
                <Map
                  mapboxAccessToken={TOKEN}
                  mapStyle="mapbox://styles/mapbox/light-v10"
                  initialViewState={{
                    longitude: addresCoordsValue.lng,
                    latitude: addresCoordsValue.lat,
                    zoom: 14,
                  }}
                  attributionControl={false}
                >
                  <FullscreenControl position="bottom-left" />
                  <NavigationControl
                    position="bottom-left"
                    showCompass={false}
                  />
                  <ModeSateliteControl position="bottom-left" />

                  <Marker
                    longitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lng}
                    latitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lat}
                    anchor="bottom"
                  >
                    <MarkerIcon color="primary" fontSize="large" />
                  </Marker>
                </Map>
              </DialogAssignCarrierCompanyMapContainer>
            )}
          </Stack>
        )}
      </Stack>

      <DialogAssignCarrierCompanyCard variant="outlined">
        <DialogAssignCarrierCompanyCardContent>
          <ListItem disablePadding disableGutters>
            <DialogAssignCarrierCompanyCardHeaderText
              primary="PEDIDOS A ASIGNAR"
              secondary={getSelectedEntity(selectedOrdesId, 'pedido')}
            />

            <DialogAssignCarrierCompanyCardHeaderIndicators
              capacity1={indicatorsOrdersCharge.capacity1}
              capacity2={indicatorsOrdersCharge.capacity2}
              numberOfPackages={indicatorsOrdersCharge.numberOfPackages}
              maximumVisits={null}
              fontSize={14}
              fontWeight="bold"
              autoFlow="row"
              rows={1}
            />
          </ListItem>

          <DialogAssignCarrierCompanyCardDataTableContainer>
            <DataTable
              loading={false}
              data={selectedOrders}
              columns={columnsOrders}
              keyField="itemId"
              condensed
            />
          </DialogAssignCarrierCompanyCardDataTableContainer>
        </DialogAssignCarrierCompanyCardContent>
      </DialogAssignCarrierCompanyCard>
    </Stack>
  );
};

export default DialogAssignCarrierCompanyConfirmation;

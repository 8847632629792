import { PlanPaymentTypes } from '~globals/types/enums';

const DISCOUNTS_PERCENTAGES: Record<`${PlanPaymentTypes}`, number> = {
  monthly: 0,
  annual: 20,
};

export const getDiscountPrice = (
  price: number,
  paymentType: `${PlanPaymentTypes}`,
): number => {
  const discount = DISCOUNTS_PERCENTAGES[paymentType];

  return (price * (100 - discount)) / 100;
};

export const getAclarationText = (isFree: boolean): JSX.Element => {
  if (isFree) {
    return (
      <>
        GRATIS <br /> PARA SIEMPRE
      </>
    );
  }

  return (
    <>
      USD <br /> FINAL POR MES
    </>
  );
};

export const getPaymentTypeNoteText = (
  paymentType: `${PlanPaymentTypes}`,
): string => {
  const prefix = paymentType === 'monthly' ? 'mensualmente' : 'anualmente';

  return `Facturado ${prefix}`;
};

import { styled, Card } from '@mui/material';

export const GenerateRouteCompletedTabsSummaryCardMainInfo = styled(Card)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    padding: 30,
    paddingBottom: 10,
    width: 150,
  }),
);

export const GenerateRouteCompletedTabsSummaryCardMainImgIcon = styled('img')({
  height: 60,
  width: 90,
});

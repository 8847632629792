import { IndustryType } from '~globals/types/enums';

export const industryTypes = [
  { value: IndustryType.AlimmentosYBebidas, label: 'Alimentos y bebidas' },
  {
    value: IndustryType.LogisticaCadenaSuministros,
    label: 'Logística y cadena de suministros',
  },
  { value: IndustryType.Courier, label: 'Courier' },
  { value: IndustryType.Retail, label: 'Retail' },
  {
    value: IndustryType.TelecomunicacionesCable,
    label: 'Telecomunicaciones y cable',
  },
  { value: IndustryType.ServiciosDomicilio, label: 'Servicios a domicilio' },
  { value: IndustryType.TransportePersonas, label: 'Transporte de personas' },
  { value: IndustryType.Otra, label: 'Otra' },
];

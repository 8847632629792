import { styled, Button, Link } from '@mui/material';

import { InitialSetupWizardModalStepContent } from '../../styles';

export const InitialSetupWizardFinishModalStepContent = styled(
  InitialSetupWizardModalStepContent,
)(({ theme }) => ({
  maxWidth: '84%',
  gap: theme.spacing(5),
}));

export const InitialSetupWizardFinishTutorialVideoLink = styled(Link)(
  ({ theme }) => ({
    width: 532,
    height: 312,
    borderRadius: theme.shape.borderRadius,
  }),
);

export const InitialSetupWizardFinishTutorialVideoImg = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

export const InitialSetupWizardFinishStoreImg = styled('img')({
  height: 35,
});

export const InitialSetupWizardFinishButton = styled(Button)({
  maxWidth: '30%',
});

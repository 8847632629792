import { SelectOption } from '~globals/types';
import {
  BusinessProfileManagementOperationTypes,
  BusinessProfileOrderManagementCollectTypes,
  BusinessProfileOrderCollectedTransferTypes,
  BusinessProfileOrderCentralizationTransferTypes,
  BusinessProfileOrderCentralizationnShipmentTypes,
  BusinessProfileOrderDistributionShipmentTypes,
  BusinessProfileTypes,
} from '~globals/types/enums';

export const MANAGEMENT_OPERATIONS_OPTIONS: SelectOption[] = [
  {
    value: BusinessProfileManagementOperationTypes.OnlySell,
    label:
      'Solo vendemos; dejamos la entrega a cargo de una empresa de transporte.',
  },
  {
    value: BusinessProfileManagementOperationTypes.NotSell,
    label:
      'No vendemos; solo nos encargamos del transporte y la distribución de productos y órdenes.',
  },
  {
    value: BusinessProfileManagementOperationTypes.SellAndManagement,
    label:
      'Vendemos productos y, adicionalmente, gestionamos toda o parte de la distribución.',
  },
];

export const ORDER_MANAGEMENT_COLLECT_OPTIONS: SelectOption[] = [
  {
    value: BusinessProfileOrderManagementCollectTypes.Collect,
    label:
      'Realizamos la recolección de pedidos con alguno o todos los vendedores.',
  },
  {
    value: BusinessProfileOrderManagementCollectTypes.NotCollect,
    label: 'No realizamos la recolección de pedidos.',
  },
];

export const ORDER_COLLECTED_TRANSFER_OPTIONS: SelectOption[] = [
  {
    value: BusinessProfileOrderCollectedTransferTypes.ToBuyers,
    label: 'Las llevan directamente a los compradores.',
  },
  {
    value: BusinessProfileOrderCollectedTransferTypes.ToWarehouse,
    label: 'Las llevan a su almacén para centralizar los pedidos.',
  },
  {
    value: BusinessProfileOrderCollectedTransferTypes.Both,
    label: 'Ambas opciones, dependiendo del caso.',
  },
];

export const ORDER_CENTRALIZATION_TRANSFER_OPTIONS: SelectOption[] = [
  {
    value: BusinessProfileOrderCentralizationTransferTypes.OnlyBuyers,
    label: 'Solo a compradores directos.',
  },
  {
    value: BusinessProfileOrderCentralizationTransferTypes.BuyersAndCarries,
    label:
      'A compradores directos y, en algunos casos, derivamos a otra empresa de transporte.',
  },
];

export const ORDER_CENTRALIZATION_SHIPMENT_OPTIONS: SelectOption[] = [
  {
    value: BusinessProfileOrderCentralizationnShipmentTypes.Buyers,
    label: 'A compradores directos.',
  },
  {
    value: BusinessProfileOrderCentralizationnShipmentTypes.BuyersAndCarries,
    label:
      // eslint-disable-next-line max-len
      'A compradores directos y, en algunos casos, derivamos las órdenes a otra empresa de transporte para que las retire de nuestro depósito.',
  },
];

export const ORDER_DISTRIBUTION_SHIPMENT_OPTIONS: SelectOption[] = [
  {
    value: BusinessProfileOrderDistributionShipmentTypes.Buyers,
    label: 'Directamente a los compradores.',
  },
  {
    value: BusinessProfileOrderDistributionShipmentTypes.ThirdParty,
    label: 'Almacén de un tercero, que se encarga de entregar las órdenes.',
  },
  {
    value: BusinessProfileOrderDistributionShipmentTypes.MyStore,
    label:
      'Dejo algunas órdenes en mi tienda para que sean recolectadas por un servicio de transporte.',
  },
];

export const BUSINESS_PROFILES: Record<
  BusinessProfileTypes,
  { name: string; description: string }
> = {
  [BusinessProfileTypes.SalesOnly]: {
    name: 'Solo ventas.',
    description:
      // eslint-disable-next-line max-len
      'Vendo y dejo las órdenes en mi depósito o tienda para que una empresa de transporte o distribución se encargue de la recolección y distribución.',
  },
  [BusinessProfileTypes.SalesAndDirectDistribution]: {
    name: 'Ventas y distribución directa.',
    description: 'Vendo y distribuyo directamente a los compradores.',
  },
  [BusinessProfileTypes.SalesAndIndirectDistribution]: {
    name: 'Ventas y distribución indirecta.',
    description:
      'Vendo y envío las órdenes al almacén de la empresa de transporte o distribución.',
  },
  [BusinessProfileTypes.SalesAndDistributionBothDirectAndIndirect]: {
    name: 'Ventas y distribución tanto directa como indirecta.',
    description:
      // eslint-disable-next-line max-len
      'Vendo, distribuyo directamente a algunos compradores y envío otras órdenes al almacén de la empresa de transporte.',
  },
  [BusinessProfileTypes.SalesDirectDistributionAndCollection]: {
    name: 'Ventas, distribución directa y recolección.',
    description:
      // eslint-disable-next-line max-len
      'Vendo, distribuyo directamente a algunos compradores y, para otras órdenes, las dejo en mi depósito para que una empresa de transporte o distribución se encargue de la recolección.',
  },
  [BusinessProfileTypes.SalesIndirectDistributionAndCollection]: {
    name: 'Ventas, distribución indirecta y recolección.',
    description:
      // eslint-disable-next-line max-len
      'Vendo, distribuyo algunas órdenes al almacén de la empresa de transporte, y para otras órdenes, las dejo en mi depósito para que una empresa de transporte o distribución se encargue de la recolección.',
  },
  [BusinessProfileTypes.SalesDirectAndIndirectDistributionAndCollection]: {
    name: 'Ventas, distribución directa e indirecta, y recolección.',
    description:
      // eslint-disable-next-line max-len
      'Vendo, distribuyo directamente a algunos compradores, dejo otras órdenes en mi depósito y envío las restantes al almacén de la empresa de transporte.',
  },
  [BusinessProfileTypes.CollectionAndDirectDistribution]: {
    name: 'Recolección y distribución directa.',
    description:
      // eslint-disable-next-line max-len
      'Realizamos la recolección en el punto de venta y luego distribuimos directamente a los compradores desde allí.',
  },
  [BusinessProfileTypes.CollectionAndIndirectDistribution]: {
    name: 'Recolección y distribución indirecta.',
    description:
      // eslint-disable-next-line max-len
      'Realizamos la recolección en el vendedor, llevamos las órdenes a nuestro almacén propio para centralizarlas y, desde allí, distribuimos a los compradores.',
  },
  [BusinessProfileTypes.CollectionIndirectDistributionAndOutsourcingOfSomeOrders]:
    {
      name: 'Recolección, distribución indirecta y tercerización de algunas órdenes.',
      description:
        // eslint-disable-next-line max-len
        'Hacemos la recolección en el vendedor, llevamos las órdenes a nuestro almacén propio, distribuimos a los compradores desde nuestro almacén y tercerizamos algunas órdenes con otra empresa de transporte o distribución.',
    },
  [BusinessProfileTypes.CollectionDirectAndIndirectDistributionAndOutsourcingOfSomeOrders]:
    {
      name: 'Recolección, distribución directa e indirecta, y tercerización de algunas órdenes.',
      description:
        // eslint-disable-next-line max-len
        'Hacemos la recolección en el vendedor, llevamos algunas órdenes a nuestro almacén propio y distribuimos desde allí. Otras órdenes las recolectamos en el vendedor y, desde su tienda, hacemos la distribución directamente a los compradores. Además, tercerizamos algunas órdenes con otra empresa de transporte o distribución.',
    },
  [BusinessProfileTypes.DirectDistributionWithoutCollection]: {
    name: 'Distribución directa sin recolección.',
    description:
      'No hacemos recolección; distribuimos desde nuestro almacén propio a los compradores.',
  },
  [BusinessProfileTypes.DirectDistributionWithoutCollectionAndOutsourcingOfSomeOrders]:
    {
      name: 'Distribución directa sin recolección y tercerización de algunas órdenes.',
      description:
        // eslint-disable-next-line max-len
        'No hacemos recolección; distribuimos desde nuestro almacén propio a los compradores y tercerizamos algunas órdenes con otra empresa de transporte o distribución.',
    },
};

import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { useStepperGenerateRoutePlanningStepperContext } from '../../SteppetGenerateRouteContext';
import { getSetenceByDictionary } from '../../utils';
import { StepperGenerateRouteDictionary } from '../../types';

const dictionary: StepperGenerateRouteDictionary = {
  title: {
    generate: 'Generando rutas...',
    generateCollect: 'Generando rutas...',
    optimize: 'Generando ruta...',
  },
  description: {
    generate: 'El sistema está generando las rutas óptimas para cada vehículo.',
    generateCollect:
      'El sistema está generando las rutas óptimas para cada vehículo.',
    optimize:
      'El sistema está actualizando el recorrido y los tiempos estimados.',
  },
  error: {
    generate: '',
    generateCollect: '',
    optimize: '',
  },
};

export const StepperGenerateRouteStepGenerating = (): ReactElement => {
  const { type } = useStepperGenerateRoutePlanningStepperContext();

  return (
    <Stack>
      <Typography component="h4" variant="h6" color="primary">
        {getSetenceByDictionary(dictionary, 'title', type)}
      </Typography>
      <Typography component="p" variant="body2">
        {getSetenceByDictionary(dictionary, 'description', type)}
      </Typography>
    </Stack>
  );
};

export default StepperGenerateRouteStepGenerating;

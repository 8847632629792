import { ReactElement, SyntheticEvent, useMemo, useCallback } from 'react';
import { DialogTitle, DialogContent, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';

import { AccessFeature } from '~components/index';
import { useLazyRequest, useSelectedRows } from '~hooks/index';
import {
  SelectRowsHandlerOnSelectAll,
  SelectRowsHandlerOnSelect,
} from '~hooks/useDataTableSelectedProps';
import { updatetItemsWithGeoErrors } from '~services/item';
import { ItemGeoBase } from '~services/item/types';
import { updatetCustomersWithGeoErrors } from '~services/customer';
import { CustomerGeoBase } from '~services/customer/types';
import { PlanFeaturesTypes } from '~globals/types/enums';
import { getLimitPaidVersion } from '~utils/feature';

import {
  GeoErrorUpateDialogContentProps,
  GeoErrorUpateDialogData,
} from '../types';
import {
  FIELDS_NAME,
  getInitialValues,
  validationSchema,
  toPathField,
  getExtractorKey,
} from '../utils';
import GeoErrorUpateDialogTableBody from '../Table';
import { GeoErrorUpateDialogActions } from '../styles';

const GeoErrorUpateDialogContent = ({
  onClose,
  data,
  type,
  onSuccessSubmit,
}: GeoErrorUpateDialogContentProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const currentExtractorKeyValue = useMemo(() => getExtractorKey(type), [type]);

  const defaultSelectedIds = useMemo<string[]>(
    // @ts-expect-error: Let's ignore a compile error like this unreachable code
    () => data.map((row) => row[currentExtractorKeyValue]),
    [data, currentExtractorKeyValue],
  );

  const {
    selectedRowsIds: selectedIds,
    handleOnSelectAll,
    handleOnSelect,
  } = useSelectedRows<GeoErrorUpateDialogData>(
    currentExtractorKeyValue,
    data,
    defaultSelectedIds,
  );

  const [, loadingUpdateItems, , executeUpdateItems] = useLazyRequest({
    request: updatetItemsWithGeoErrors,
    withPostSuccess: () => {
      enqueueSnackbar('Pedidos actualizados correctamente', {
        variant: 'success',
      });

      onSuccessSubmit?.();

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo actualizar los pedidos, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const [, loadingUpdateCustomers, , executeUpdateCustomers] = useLazyRequest({
    request: updatetCustomersWithGeoErrors,
    withPostSuccess: () => {
      enqueueSnackbar('Contacos frecuentes actualizados correctamente', {
        variant: 'success',
      });

      onSuccessSubmit?.();

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo actualizar los contactos frecuentes, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      if (type === 'item') {
        const itemsData: ItemGeoBase[] = values[FIELDS_NAME.ITEMS]
          .filter((item) => selectedIds.includes(item[FIELDS_NAME.ITEM_ID]))
          .map((item) => ({
            itemId: item[FIELDS_NAME.ITEM_ID],
            address: item[FIELDS_NAME.ITEM_ADDRESS],
            latitude: item[FIELDS_NAME.ITEM_ADDRESS_COORDS]?.lat ?? null,
            longitude: item[FIELDS_NAME.ITEM_ADDRESS_COORDS]?.lng ?? null,
            zipCode: item[FIELDS_NAME.ITEM_ZIP_CODE],
          }));

        await executeUpdateItems(itemsData);
      }

      if (type === 'customer') {
        const customersData: CustomerGeoBase[] = values[FIELDS_NAME.ITEMS]
          .filter((item) => selectedIds.includes(item[FIELDS_NAME.ITEM_ID]))
          .map((item) => ({
            customerId: item[FIELDS_NAME.ITEM_ID],
            address: item[FIELDS_NAME.ITEM_ADDRESS],
            latitude: item[FIELDS_NAME.ITEM_ADDRESS_COORDS]?.lat ?? null,
            longitude: item[FIELDS_NAME.ITEM_ADDRESS_COORDS]?.lng ?? null,
            zipCode: item[FIELDS_NAME.ITEM_ZIP_CODE],
          }));

        await executeUpdateCustomers(customersData);
      }

      setSubmitting(false);
    },
    [executeUpdateItems, executeUpdateCustomers, selectedIds, type],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues(data, type),
    validationSchema,
    onSubmit,
  });

  const { submitForm, isSubmitting, setFieldValue, validateForm } = formikBag;

  const loadingSubmit = useMemo(
    () => loadingUpdateItems || loadingUpdateCustomers || isSubmitting,
    [loadingUpdateItems, loadingUpdateCustomers, isSubmitting],
  );

  const onSelectAll = useCallback<
    SelectRowsHandlerOnSelectAll<GeoErrorUpateDialogData>
  >(
    async (isSelected, rows, event) => {
      const rowsData = isSelected ? rows : data;

      await Promise.all(
        rowsData.map((_row, rowIndex) => {
          return setFieldValue(
            toPathField(rowIndex, FIELDS_NAME.ITEM_CHECKED),
            isSelected,
            false,
          );
        }),
      );

      await validateForm();

      handleOnSelectAll(isSelected, rows, event as SyntheticEvent);
    },
    [handleOnSelectAll, data, setFieldValue, validateForm],
  );

  const onSelect = useCallback<
    SelectRowsHandlerOnSelect<GeoErrorUpateDialogData>
  >(
    (row, isSelected, rowIndex, event) => {
      setFieldValue(
        toPathField(rowIndex, FIELDS_NAME.ITEM_CHECKED),
        isSelected,
      );

      handleOnSelect(row, isSelected, rowIndex, event as SyntheticEvent);
    },
    [handleOnSelect, setFieldValue],
  );

  return (
    <FormikProvider value={formikBag}>
      <DialogTitle>
        Corregir problemas de geolocalización{' '}
        <AccessFeature.NextPlanChip
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
          openAccessFeatureModalOnClick={false}
        />
      </DialogTitle>

      <DialogContent dividers>
        <AccessFeature.Alert
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
        />

        <AccessFeature.Hidden
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
          type="notAccess"
          validDemo
        >
          <GeoErrorUpateDialogTableBody
            type={type}
            data={data}
            selectable={{
              selectedIds,
              onSelectAll,
              onSelect,
            }}
          />
        </AccessFeature.Hidden>
      </DialogContent>

      <GeoErrorUpateDialogActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <AccessFeature.Hidden
          validFeature={PlanFeaturesTypes.LimitPaid}
          availableToDate={getLimitPaidVersion('1.18.2')}
          type="notAccess"
          validDemo
        >
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={submitForm}
            loading={loadingSubmit}
            disabled={selectedIds.length === 0}
          >
            Guardar cambios
          </LoadingButton>
        </AccessFeature.Hidden>
      </GeoErrorUpateDialogActions>
    </FormikProvider>
  );
};

export default GeoErrorUpateDialogContent;

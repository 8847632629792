import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  Button,
} from '@mui/material';

export const DialogMobileNotPerformanceRoot = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 362,
  },
});

export const DialogMobileNotPerformanceContent = styled(DialogContent)(
  ({ theme }) => ({
    padding: theme.spacing(2.5, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  }),
);

export const DialogMobileNotPerformanceMedia = styled('img')({
  width: '100%',
  objectFit: 'contain',
});

export const DialogMobileNotPerformanceActionButton = styled(Button)({
  maxWidth: 270,
});

export const DialogMobileNotPerformanceStoreImg = styled('img')({
  height: 30,
});

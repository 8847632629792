import { ReactElement, useMemo } from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';
import classNames from 'classnames';

const Link = ({
  to,
  className,
  ...rest
}: Omit<MuiLinkProps, 'component'> &
  Omit<RouterLinkProps, 'color'>): ReactElement => {
  const { pathname: locationPathName } = useLocation();
  const { pathname: toPathname } = useResolvedPath(to);

  const isActive = useMemo(
    () =>
      locationPathName === toPathname ||
      (locationPathName.startsWith(toPathname) &&
        locationPathName.charAt(toPathname.length) === '/'),
    [locationPathName, toPathname],
  );

  return (
    <MuiLink
      component={RouterLink}
      to={to}
      className={classNames(className, { linkActive: isActive })}
      {...rest}
    />
  );
};

export default Link;

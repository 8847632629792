import { styled, Avatar } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const ConfigUserFormAvatar = styled(Avatar)({
  width: 100,
  height: 100,
});

export const ConfigUserFormSubmitButton = styled(LoadingButton)({
  minWidth: 200,
});

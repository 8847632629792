import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

export const LoadingContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
}));

export const Content = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  marginLeft: theme.maxWidths.lateralMenu,
  minHeight: '100vh',
  minWidth: 1280,
  padding: theme.spacing(3, 2),
}));

export const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'heightHeader',
})<{ heightHeader: number }>(({ heightHeader, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: `calc(100vh - ${heightHeader}px - ${theme.spacing(4)})`,
}));

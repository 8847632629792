import * as Yup from 'yup';

import { AuthExtendedCompany } from '~services/auth/types';
import { getBillingAddresByCompany } from '~utils/billing';
import { VALIDATIONS_ERROR } from '~constants/index';
import { BillingData } from '~globals/types';
import { parsePhoneInPlainText } from '~utils/user';
import { getItemValue } from '~utils/helpers';

export const FIELDS_NAME = {
  BUSINESS_NAME: 'businessName',
  TAX_ID_NUMBER: 'taxIdNumber',
  COUNTRY_ID: 'countryId',
  PHONE: 'phone',
  ADDRESS: 'address',
  TAX_TYPE_ID: 'taxTypeId',
  INVOICE_TYPE_ID: 'invoiceTypeId',
} as const;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (company?: AuthExtendedCompany) => ({
  [FIELDS_NAME.BUSINESS_NAME]: company?.name ?? '',
  [FIELDS_NAME.TAX_ID_NUMBER]: company?.cuit ?? '',
  [FIELDS_NAME.COUNTRY_ID]: String(company?.countryId ?? ''),
  [FIELDS_NAME.PHONE]: company?.contactMobile ?? company?.contactPhone ?? '',
  [FIELDS_NAME.ADDRESS]: getBillingAddresByCompany(company ?? null),
  // @TODO: Change for correct key o parse
  [FIELDS_NAME.TAX_TYPE_ID]: '',
  // @TODO: Change for correct key o parse
  [FIELDS_NAME.INVOICE_TYPE_ID]: '',
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getValidationSchema = (isArg: boolean) =>
  Yup.object().shape({
    [FIELDS_NAME.BUSINESS_NAME]: Yup.string().required(
      VALIDATIONS_ERROR.REQUIRED,
    ),
    [FIELDS_NAME.TAX_ID_NUMBER]: Yup.string().required(
      VALIDATIONS_ERROR.REQUIRED,
    ),
    [FIELDS_NAME.COUNTRY_ID]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
    [FIELDS_NAME.PHONE]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
    // .phone(VALIDATIONS_ERROR.PHONE),
    [FIELDS_NAME.ADDRESS]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
    [FIELDS_NAME.TAX_TYPE_ID]: isArg
      ? Yup.string().required(VALIDATIONS_ERROR.REQUIRED)
      : Yup.string().notRequired(),
    [FIELDS_NAME.INVOICE_TYPE_ID]: isArg
      ? Yup.string().required(VALIDATIONS_ERROR.REQUIRED)
      : Yup.string().notRequired(),
  });

export const parseBillingData = (
  values: ReturnType<typeof getInitialValues>,
): BillingData => ({
  businessName: values[FIELDS_NAME.BUSINESS_NAME],
  taxIdNumber: values[FIELDS_NAME.TAX_ID_NUMBER],
  countryId: Number(values[FIELDS_NAME.COUNTRY_ID]),
  phone: parsePhoneInPlainText(values[FIELDS_NAME.PHONE]),
  address: values[FIELDS_NAME.ADDRESS],
  taxTypeId: getItemValue(values, FIELDS_NAME.TAX_TYPE_ID, Number),
  invoiceTypeId: getItemValue(values, FIELDS_NAME.INVOICE_TYPE_ID, Number),
});

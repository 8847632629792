import { ReactElement } from 'react';

import { MarkerStopProps } from './types';
import {
  MarkerStopContainer,
  MarkerStopIconMarker,
  MarkerStopInfoStopContainer,
  MarkerStopInfoStopText,
} from './styles';

const MarkerStop = ({
  color,
  numberStop,
  backgroundColor,
}: MarkerStopProps): ReactElement => (
  <MarkerStopContainer>
    <MarkerStopIconMarker currentColor={color} />

    <MarkerStopInfoStopContainer backgroundColor={backgroundColor}>
      <MarkerStopInfoStopText>{numberStop}</MarkerStopInfoStopText>
    </MarkerStopInfoStopContainer>
  </MarkerStopContainer>
);

export default MarkerStop;

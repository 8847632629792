import { ReactElement } from 'react';

import { PulsingDotContainer, PulsingDotCircle } from './styles';

const PulsingDot = (): ReactElement => (
  <PulsingDotContainer>
    <PulsingDotCircle />
  </PulsingDotContainer>
);

export default PulsingDot;

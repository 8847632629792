import {
  ReactElement,
  MouseEvent,
  useState,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { MoreVert as MoreIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { startsWith } from 'lodash';

import { Nullable } from '~globals/types';
import { getStringifyUrlQueryString } from '~utils/router';
import {
  NewFeatureBagde,
  PaidMembershipBadge,
  AccessFeature,
} from '~components/index';
import { useAccessFeatureModalContext } from '~app/contexts/AccessFeatureModalContext';
import { useSubscriptionFeatures } from '~hooks/index';

import {
  MenuActionsDataTableProps,
  MenuActionsDataTableOptionItem,
  MenuActionsDataTableOnClick,
  MenuActionsDataTableResouce,
  MenuActionsDataTableOptionsButton,
  MenuActionsDataTableValidSubscriptionFeature,
} from './types';
import {
  MenuActionsDataTableButton,
  MenuActionsDataTableMenu,
  ShowIcon,
  EditIcon,
  DeleteIcon,
} from './styles';

const MenuActionsDataTable = ({
  resource,
  items,
  extraItems,
  extraData: extraDataProps,
  optionsButton,
}: MenuActionsDataTableProps): ReactElement => {
  const navigate = useNavigate();
  const { handleOpen: handleOpenAccesFeatureModal } =
    useAccessFeatureModalContext();
  const { hasAccessToFeature } = useSubscriptionFeatures();

  const [anchorEl, setAnchorEl] = useState<Nullable<Element>>(null);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const transformPath = useCallback(
    (path: string) => (startsWith(path, '/') ? path : `/${path}`),
    [],
  );

  const getCurrentPath = useCallback(
    (currentResource: MenuActionsDataTableResouce) => {
      const path = transformPath(currentResource.path);

      return currentResource.id ? `${path}/${currentResource.id}` : path;
    },
    [transformPath],
  );

  const getPathWithPrefix = useCallback(
    (currentResource: MenuActionsDataTableResouce, prefix?: string) => {
      const currentPath = getCurrentPath(currentResource);
      const currentPathWithPrefix = prefix
        ? `${currentPath}${transformPath(prefix)}`
        : currentPath;

      return getStringifyUrlQueryString({
        url: currentPathWithPrefix,
        query: currentResource.query,
      });
    },
    [getCurrentPath, transformPath],
  );

  const handleRedirectOnClick = useCallback(
    (path: string) => {
      navigate(path, { state: extraDataProps });
    },
    [navigate, extraDataProps],
  );

  const hasValidFeature = useCallback(
    (validSubscriptionFeature?: MenuActionsDataTableValidSubscriptionFeature) =>
      validSubscriptionFeature
        ? hasAccessToFeature(
            validSubscriptionFeature.validFeature,
            validSubscriptionFeature.availableToDate,
          )
        : false,
    [hasAccessToFeature],
  );

  const handleOnClickItem = useCallback(
    (
        path: string,
        callback?: MenuActionsDataTableOnClick,
        validSubscriptionFeature?: MenuActionsDataTableValidSubscriptionFeature,
      ) =>
      async (event: MouseEvent<HTMLLIElement>) => {
        event.preventDefault();
        if (
          validSubscriptionFeature &&
          !hasValidFeature(validSubscriptionFeature)
        ) {
          handleCloseMenu();
          handleOpenAccesFeatureModal({
            validFeature: validSubscriptionFeature.validFeature,
            validateAvailableRoutesState: false,
          });
          return;
        }

        if (callback) {
          await callback(path, extraDataProps);
          handleCloseMenu();
        } else {
          handleRedirectOnClick(path);
        }
      },
    [
      hasValidFeature,
      handleCloseMenu,
      handleOpenAccesFeatureModal,
      extraDataProps,
      handleRedirectOnClick,
    ],
  );

  const itemsDefault = useMemo<Record<string, MenuActionsDataTableOptionItem>>(
    () => ({
      show: {
        enabled: true,
        Icon: <ShowIcon />,
        label: 'Ver',
        ...items?.show,
      },
      edit: {
        enabled: false,
        Icon: <EditIcon />,
        label: 'Editar',
        prefix: '/edit',
        ...items?.edit,
      },
      delete: {
        enabled: false,
        Icon: <DeleteIcon />,
        label: 'Eliminar',
        prefix: '/delete',
        ...items?.delete,
      },
    }),
    [items],
  );

  const optionsButtonDefault = useMemo<MenuActionsDataTableOptionsButton>(
    () => ({
      Icon: <MoreIcon />,
      show: true,
      ...optionsButton,
    }),
    [optionsButton],
  );

  return (
    <div>
      <MenuActionsDataTableButton
        aria-label="more"
        aria-controls="long-menu"
        onClick={handleOpenMenu}
        endIcon={optionsButtonDefault.Icon}
        iconButton={!optionsButtonDefault?.label}
        size={optionsButtonDefault?.size}
        color={optionsButtonDefault?.color}
        variant={optionsButtonDefault.variant}
        show={optionsButtonDefault.show ?? true}
      >
        {optionsButtonDefault?.label}
      </MenuActionsDataTableButton>

      <MenuActionsDataTableMenu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="menu-actions-data-table"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {Object.entries<MenuActionsDataTableOptionItem>(itemsDefault).map(
          ([key, item]) =>
            item.enabled && (
              <div key={`menu-actions-data-table-item-${key}`}>
                <MenuItem
                  onClick={handleOnClickItem(
                    getPathWithPrefix(resource, item.prefix),
                    item.onClick,
                    item.validSubscriptionFeature,
                  )}
                  disabled={item.disabled}
                >
                  {item.Icon && <ListItemIcon>{item.Icon}</ListItemIcon>}

                  <ListItemText primary={item.label} />

                  {item.newActiveForVersion && (
                    <NewFeatureBagde
                      activeForVersion={item.newActiveForVersion}
                    />
                  )}

                  {item.paidMembershipBadge && (
                    <PaidMembershipBadge visible={true} />
                  )}

                  {item.validSubscriptionFeature && (
                    <AccessFeature.NextPlanChip
                      validFeature={item.validSubscriptionFeature.validFeature}
                      availableToDate={
                        item.validSubscriptionFeature.availableToDate
                      }
                    />
                  )}
                </MenuItem>

                {item.divider && <Divider />}
              </div>
            ),
        )}

        {extraItems?.map(
          (extraItem, index) =>
            extraItem.enabled && (
              <div key={`menu-actions-data-table-item-extra-${index}`}>
                <MenuItem
                  onClick={handleOnClickItem(
                    getPathWithPrefix(
                      extraItem.resource ?? resource,
                      extraItem.prefix,
                    ),
                    extraItem.onClick,
                    extraItem.validSubscriptionFeature,
                  )}
                  disabled={extraItem.disabled}
                >
                  {extraItem.Icon && (
                    <ListItemIcon>{extraItem.Icon}</ListItemIcon>
                  )}

                  <ListItemText primary={extraItem.label} />

                  {extraItem.newActiveForVersion && (
                    <NewFeatureBagde
                      activeForVersion={extraItem.newActiveForVersion}
                    />
                  )}

                  {extraItem.paidMembershipBadge && (
                    <PaidMembershipBadge visible={true} />
                  )}

                  {extraItem.validSubscriptionFeature && (
                    <AccessFeature.NextPlanChip
                      validFeature={
                        extraItem.validSubscriptionFeature.validFeature
                      }
                      availableToDate={
                        extraItem.validSubscriptionFeature.availableToDate
                      }
                    />
                  )}
                </MenuItem>

                {extraItem.divider && <Divider />}
              </div>
            ),
        )}
      </MenuActionsDataTableMenu>
    </div>
  );
};

export default memo(MenuActionsDataTable);

import { FC, createContext, useContext, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { AuthExtendedCompany } from '~services/auth/types';
import { ItemExtended } from '~services/item/types';
import {
  getGiverCompanies,
  generatePartnerItem,
} from '~services/partnerCompany';
import { PartnerCompanyGenerateItem } from '~services/partnerCompany/types';
import { useRequest, useLazyRequest } from '~hooks/index';
import { useSelectedRows, SelectedRowsReturn } from '~hooks/useSelectedRows';

import { DialogImportFromAssociatedCompanyProps } from './types';

export type DialogImportFromAssociatedCompanyContextPanelsId =
  | 'notAssociatedCompanies'
  | 'importer';
interface DialogImportFromAssociatedCompanyContextCurrentAssociatedCompanies {
  list: AuthExtendedCompany[];
  loading: boolean;
}

interface DialogImportFromAssociatedCompanyContextCurrentOrders {
  data: ItemExtended[];
  handleAddOrder: (newOrder: ItemExtended) => void;
}

interface DialogImportFromAssociatedCompanyContextImporterRequest {
  executeImport: () => Promise<void>;
  loading: boolean;
}

interface DialogImportFromAssociatedCompanyContextValue {
  open: boolean;
  onClose: () => void;
  activeTab: DialogImportFromAssociatedCompanyContextPanelsId;
  currentAssociatedCompanies: DialogImportFromAssociatedCompanyContextCurrentAssociatedCompanies;
  currentOrders: DialogImportFromAssociatedCompanyContextCurrentOrders;
  selectedOrders: SelectedRowsReturn<ItemExtended>;
  importRequest: DialogImportFromAssociatedCompanyContextImporterRequest;
}

const DialogImportFromAssociatedCompanyContext =
  createContext<DialogImportFromAssociatedCompanyContextValue>({
    open: false,
    onClose: () => {},
    activeTab: 'notAssociatedCompanies',
    currentAssociatedCompanies: {
      list: [],
      loading: true,
    },
    currentOrders: {
      data: [],
      handleAddOrder: () => {},
    },
    selectedOrders: {
      handleOnSelect: () => {},
      handleOnSelectAll: () => {},
      restarSelectedRows: () => {},
      selectedRows: [],
      selectedRowsIds: [],
      selectRowsProps: {
        mode: 'checkbox',
        clickToSelect: false,
        selected: undefined,
        onSelectAll: () => {},
        onSelect: () => {},
        selectionHeaderRenderer: undefined,
        selectionRenderer: undefined,
        nonSelectable: undefined,
        nonSelectableClasses: undefined,
      },
      setSelectedRowsIds: () => {},
      setSelectedRows: () => {},
    },
    importRequest: {
      executeImport: () => Promise.resolve(),
      loading: false,
    },
  });

export const useDialogImportFromAssociatedCompanyContext =
  (): DialogImportFromAssociatedCompanyContextValue =>
    useContext(DialogImportFromAssociatedCompanyContext);

const DialogImportFromAssociatedCompanyProvider: FC<
  DialogImportFromAssociatedCompanyProps
> = ({ open, onClose, onSubmitSuccess, children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [activeTab, setActiveTab] =
    useState<DialogImportFromAssociatedCompanyContextPanelsId>(
      'notAssociatedCompanies',
    );

  const [currentAssociatedCompanies, setCurrentAssociatedCompanies] = useState<
    AuthExtendedCompany[]
  >([]);

  const [, loadingCurrentAssociatedCompanies] = useRequest({
    request: getGiverCompanies,
    payload: null,
    withPostSuccess: (response) => {
      const currentAssociatedCompaniesResponse = response.data?.data
        .results as AuthExtendedCompany[];

      setCurrentAssociatedCompanies(currentAssociatedCompaniesResponse);

      if (currentAssociatedCompaniesResponse.length > 0)
        setActiveTab('importer');
    },
  });

  const [currentOrders, setCurrentCurrentOrders] = useState<ItemExtended[]>([]);

  const handleAddOrder = useCallback((newOrder: ItemExtended) => {
    setCurrentCurrentOrders((previousOrders) => [newOrder, ...previousOrders]);
  }, []);

  const selectedOrders = useSelectedRows<ItemExtended>('itemId');

  const [, loadingImport, , executeImportItems] = useLazyRequest({
    request: generatePartnerItem,
    withPostSuccess: () => {
      enqueueSnackbar('Los Pedidos fueron importados correctamente', {
        variant: 'success',
      });

      onSubmitSuccess();

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo importar los pedidos, intente nuevamente', {
        variant: 'success',
      });
    },
  });

  const handleImport = useCallback(async () => {
    if (selectedOrders.selectedRows.length > 0) {
      const itemsExport: PartnerCompanyGenerateItem[] =
        selectedOrders.selectedRows.map((row) => ({
          itemId: row.itemId,
          associatedCompanyId: row.companyId,
        }));

      await executeImportItems({ items: itemsExport });
    }
  }, [selectedOrders.selectedRows, executeImportItems]);

  return (
    <DialogImportFromAssociatedCompanyContext.Provider
      value={{
        open,
        onClose,
        activeTab,
        currentAssociatedCompanies: {
          list: currentAssociatedCompanies,
          loading: loadingCurrentAssociatedCompanies,
        },
        currentOrders: {
          data: currentOrders,
          handleAddOrder,
        },
        selectedOrders,
        importRequest: {
          executeImport: handleImport,
          loading: loadingImport,
        },
      }}
    >
      {children}
    </DialogImportFromAssociatedCompanyContext.Provider>
  );
};

export default DialogImportFromAssociatedCompanyProvider;

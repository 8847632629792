import { useState, useCallback, useMemo, useEffect } from 'react';
import { some, chain } from 'lodash';

import { isSuccessWorkProgressId } from '~components/StepperGenerateRoute/utils';
import { useRequest } from '~hooks/index';
import { getApiTransaction } from '~services/apiTransaction';
import {
  ApiTransactionExtended,
  ApiTransactionRoutes,
  ApiTransactionPlanningIndicators,
} from '~services/apiTransaction/types';
import { Nullable } from '~globals/types';
import {
  NotificationApiTransactionWorkProgressId,
  NotificationApiTransactionRejectOperation,
  NotificationApiTransaction,
  NotificationRoute,
} from '~globals/types/notifications';
import { useSignalRContext } from '~contexts/SignalRContext';
import { useClientOnMethod } from '~hooks/useSignalR';
import { METHODS_NAME } from '~constants/signalR';

interface UseGenerateRouteStepperDataParams {
  apiTransactionId: string;
  onSuccessComplete?: (
    currentApiTransactionId: string,
    currentRoutes: ApiTransactionRoutes[],
  ) => void;
}

interface UseGenerateRouteStepperDataReturn {
  data: Nullable<ApiTransactionExtended>;
  loadingData: boolean;
  activeStep: number;
  workProgressId: NotificationApiTransactionWorkProgressId;
  indicators: Nullable<ApiTransactionPlanningIndicators>;
  loadingIndicators: boolean;
  totalStops: number;
  totalVehicles: number;
  errors: NotificationApiTransactionRejectOperation[];
}

export const useGenerateRouteStepperData = ({
  apiTransactionId,
  onSuccessComplete,
}: UseGenerateRouteStepperDataParams): UseGenerateRouteStepperDataReturn => {
  const { hubConnection } = useSignalRContext();

  const [data, setData] = useState<Nullable<ApiTransactionExtended>>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [workProgressId, setWorkProgressId] =
    useState<NotificationApiTransactionWorkProgressId>(1);
  const [indicators, setIndicators] =
    useState<Nullable<ApiTransactionPlanningIndicators>>(null);
  const [errors, setErrors] = useState<
    NotificationApiTransactionRejectOperation[]
  >([]);
  const [loadingData, setLoadingData] = useState(true);

  const [, loadingGlobalData, , reloadRequest] = useRequest({
    request: getApiTransaction,
    payload: apiTransactionId,
    withPostSuccess: (response) => {
      const responseData = response.data?.data as ApiTransactionExtended;

      responseData?.routes.forEach((route) => {
        route.routeItems.sort((a, b) => a.order - b.order);
      });

      setData(responseData);

      const parseCurrentErrorsStepper: NotificationApiTransactionRejectOperation[] =
        responseData.rejectOperations
          ? JSON.parse(responseData.rejectOperations)
          : [];

      if (!!parseCurrentErrorsStepper.length) {
        setErrors(parseCurrentErrorsStepper);
      }

      if (responseData.apiTransactionStateTypeId === 3) {
        setActiveStep(2);
      }

      setWorkProgressId(
        (responseData.apiTransactionStateTypeId +
          1) as NotificationApiTransactionWorkProgressId,
      );

      const currentIndictorsData = JSON.parse(
        responseData?.planningIndicators as string,
      ) as ApiTransactionPlanningIndicators;

      setIndicators(currentIndictorsData);

      setLoadingData(false);
    },
    withPostFailure: () => {
      setLoadingData(false);
    },
  });

  const currentRoutes = useMemo(() => data?.routes ?? [], [data]);

  const totalStops = useMemo(
    () =>
      currentRoutes.reduce((acc, route) => (acc += route.routeItems.length), 0),
    [currentRoutes],
  );

  const totalVehicles = useMemo(
    () => chain(currentRoutes).groupBy('vehicleId').keys().size().value(),
    [currentRoutes],
  );

  const reloadData = useCallback(() => {
    reloadRequest(apiTransactionId);
  }, [reloadRequest, apiTransactionId]);

  const updateStepper = useCallback(
    (notification: string) => {
      const infoNotification = JSON.parse(
        notification,
      ) as NotificationApiTransaction;

      if (
        infoNotification.ApiTransactionId === apiTransactionId &&
        infoNotification.ApiTransactionWorkProgressId - 1 > activeStep
      ) {
        reloadData();
        setActiveStep(infoNotification.ApiTransactionWorkProgressId - 1);
        setWorkProgressId(infoNotification.ApiTransactionWorkProgressId);
        setErrors(infoNotification.RejectOperations);
      }
    },
    [apiTransactionId, activeStep, reloadData],
  );

  useClientOnMethod(
    hubConnection,
    METHODS_NAME.REFRESH_API_TRANSACTION,
    updateStepper,
  );

  const updatePlanning = useCallback(
    (notification: string) => {
      const infoNotification = JSON.parse(notification) as NotificationRoute;

      if (some(currentRoutes, { routeId: infoNotification.RouteId })) {
        reloadData();
      }
    },
    [currentRoutes, reloadData],
  );

  useClientOnMethod(hubConnection, METHODS_NAME.REMOVE_ROUTE, updatePlanning);

  useClientOnMethod(hubConnection, METHODS_NAME.REFRESH_ROUTE, updatePlanning);

  useEffect(() => {
    if (isSuccessWorkProgressId(workProgressId) && !loadingGlobalData) {
      onSuccessComplete?.(apiTransactionId, currentRoutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workProgressId, currentRoutes, loadingGlobalData]);

  return {
    data,
    loadingData,
    activeStep,
    workProgressId,
    indicators,
    loadingIndicators: loadingGlobalData,
    totalStops,
    totalVehicles,
    errors,
  };
};

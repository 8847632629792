import { styled, Box } from '@mui/material';

import { CapabilitiesInfoDirection } from './types';

export const CapabilitiesInfoContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{ ownerState: CapabilitiesInfoDirection }>(({ theme, ownerState }) => ({
  display: 'grid',
  gridAutoFlow: ownerState.autoFlow,
  gap: theme.spacing(0.5, 1),
  gridTemplateRows: `repeat(${ownerState.rows}, 1fr)`,
  gridTemplateColumns: `repeat(${ownerState.columns}, 1fr)`,
}));

export const CapabilitiesInfoItemImg = styled('img')({
  width: '16px !important',
  height: 'auto !important',
});

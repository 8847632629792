import {
  styled,
  Dialog,
  dialogClasses,
  dialogContentClasses,
  DialogActions,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

export const DialogAssignDriverContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    minHeight: 580,
  },
});

export const DialogAssignDriverLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogAssignDriverTabPanel = styled(TabPanel)({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  flex: 1,
  height: '100%',

  [`& .${dialogContentClasses.dividers}:nth-of-type(even)`]: {
    borderTop: 'none',
  },
});

export const DialogAssignDriverStoreImg = styled('img')({
  height: 25,
});

export const DialogAssignDriverActions = styled(DialogActions)({
  justifyContent: 'flex-start',
});

import { chain } from 'lodash';

import { Roles } from '~globals/types/enums';

export const ROLES_FIELD_INFO = [
  {
    roleId: Roles.Admin,
    label: 'Administrador',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Nivel de permiso más alto. Puede administrar la configuración y preferencias de la cuenta. Tiene acceso a reportes y estadísticas y maneja la gestión de vehículos, depositos, usuarios, choferes.',
  },
  {
    roleId: Roles.Operator,
    label: 'Operador',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Puede crear/editar/eliminar pedidos, rutas y contactos frecuentes. No tiene permisos para cambiar la configuración de la cuenta.',
  },
  {
    roleId: Roles.Reader,
    label: 'Lector',
    scopeDescription:
      // eslint-disable-next-line max-len
      'Puede ver todos los pedidos, rutas y contactos frecuentes, incluidos todos sus datos, pero no puede crear/editar/eliminar nada.',
  },
];

export const getRoleFieldLabel = (roleId: Roles): string =>
  chain(ROLES_FIELD_INFO).find({ roleId }).get('label', '').value();

import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip as AttachIcon } from '@fortawesome/free-solid-svg-icons';

import { formatId } from '~utils/formatter';

import { FileIdInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const FileIdInfo = ({
  fileId,
  fontSize = 14,
  withTooltip = false,
}: FileIdInfoProps): ReactElement => {
  const text = useMemo(() => formatId(fileId), [fileId]);

  const tooltipMessage = useMemo(
    () => (withTooltip ? 'Documento' : undefined),
    [withTooltip],
  );

  return (
    <DescriptionWithIconBox
      icon={<FontAwesomeIcon icon={AttachIcon} />}
      description={text}
      size={fontSize}
      iconTooltip={tooltipMessage}
    />
  );
};

export default memo(FileIdInfo);

import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { getTaxIdLabel } from '~utils/billing';

import { SubscriptionBillinDataInfoProps } from './types';

const SubscriptionBillinDataInfo = ({
  isArg,
  data: { businessName, taxId, country, phone, address, taxType, invoiceType },
}: SubscriptionBillinDataInfoProps): ReactElement => {
  return (
    <Stack>
      <Typography variant="caption" color="text.secondary">
        Razón social: <b>{businessName}</b>
      </Typography>

      <Typography variant="caption" color="text.secondary">
        {getTaxIdLabel(isArg)}: <b>{taxId}</b>
      </Typography>

      <Typography variant="caption" color="text.secondary">
        País: <b>{country}</b>
      </Typography>

      <Typography variant="caption" color="text.secondary">
        Teléfono: <b>{phone}</b>
      </Typography>

      <Typography variant="caption" color="text.secondary">
        Domicilio de facturación: <b>{address}</b>
      </Typography>

      {isArg && (
        <>
          <Typography variant="caption" color="text.secondary">
            Condición de IVA: <b>{taxType}</b>
          </Typography>

          <Typography variant="caption" color="text.secondary">
            Tipo de comprobante: <b>{invoiceType}</b>
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default SubscriptionBillinDataInfo;

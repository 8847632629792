import { styled, Skeleton } from '@mui/material';
import { PayPalButtons } from '@paypal/react-paypal-js';

export const SubscriptionPaymentModalPaypalButtonPaypalButtonRoot = styled(
  PayPalButtons,
)(({ theme }) => ({
  minWidth: 280,
  height: 48,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),

  '&.paypal-buttons-disabled': {
    pointerEvents: 'none',
    cursor: 'default',
  },
}));

export const SubscriptionPaymentModalPaypalButtonPaypalButtonSpinner = styled(
  Skeleton,
)(({ theme }) => ({
  borderRadius: Number(theme.shape.borderRadius) / 2,
}));

import {
  styled,
  TextField,
  inputBaseClasses,
  InputAdornment,
  Select,
  SelectProps,
  selectClasses,
} from '@mui/material';
import { CountryIso2, FlagEmoji } from 'react-international-phone';

export const PhoneFieldTextField = styled(TextField)({
  [`& .${inputBaseClasses.inputAdornedStart}`]: {
    paddingLeft: 0,
  },
});

export const PhoneFieldCountryAdorment = styled(InputAdornment)(
  ({ theme }) => ({
    marginRight: '2px',
    marginLeft: theme.spacing(-1),
  }),
);

export const PhoneFieldCountrySelect = styled(
  (props: SelectProps<CountryIso2>) => <Select {...props} />,
)({
  width: 'max-content',

  fieldset: {
    display: 'none',
  },

  [`& .${selectClasses.select}`]: {
    paddingTop: 18,
    paddingBottom: 3,
    paddingRight: '24px !important',
    paddingLeft: 8,
  },

  [`& .${selectClasses.icon}`]: {
    right: 0,
    top: 'calc(50% - 5px)',
  },
});

export const PhoneFieldCountrySelectEmoji = styled(FlagEmoji)({
  display: 'flex',
});

export const PhoneFieldCountrySelectListItemEmoji = styled(FlagEmoji)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  }),
);

import { ReactElement, useRef, useCallback, useMemo, useEffect } from 'react';
import {
  createFilterOptions,
  Stack,
  DialogContentText,
  Avatar,
  TextField,
  Collapse,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useFormik, FormikHelpers } from 'formik';
import { ColumnDescription } from 'react-bootstrap-table-next';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { find } from 'lodash';

import {
  CustomAutocomplete,
  UserInfo,
  OrderClientInfo,
  CapabilitiesInfo,
  DataTable,
} from '~components/index';
import { CustomAutocompleteRef } from '~components/CustomAutocomplete/types';
import { AuthExtendedCompany } from '~services/auth/types';
import { ItemExtended } from '~services/item/types';
import { searchItemByAssociateCompany } from '~services/item';
import { useToggle, useLazyRequest } from '~hooks/index';
import { hasError } from '~utils/formHelpers';
import { pluralize } from '~utils/commons';

// eslint-disable-next-line max-len
import { useDialogImportFromAssociatedCompanyContext } from '../../DialogImportFromAssociatedCompanyContext';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import {
  classes,
  DialogImportFromAssociatedCompanyImporterCard,
  DialogImportFromAssociatedCompanyImporterCardContent,
  DialogImportFromAssociatedCompanyImporterCardHeader,
  DialogImportFromAssociatedCompanyImporterCardHeaderText,
  DialogImportFromAssociatedCompanyImporterCardHeaderIndicators,
  DialogImportFromAssociatedCompanyImporterCardDataTableContainer,
} from './styles';

const DialogImportFromAssociatedCompanyImporter = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    currentAssociatedCompanies: { list: currentAssociatedCompanies },
    currentOrders: { data: ordersInfo, handleAddOrder },
    selectedOrders: {
      selectedRowsIds: selectedOrdersIds,
      selectedRows: selectedOrders,
      selectRowsProps: selectRowsPropsOrders,
      handleOnSelect: handleOnSelectOrder,
    },
  } = useDialogImportFromAssociatedCompanyContext();

  const currentAssociatedCompaniesAutocomplete =
    useRef<CustomAutocompleteRef<AuthExtendedCompany, false>>(null);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const [, loadingSearchItem, , executeSearchItem] = useLazyRequest({
    request: searchItemByAssociateCompany,
    withPostSuccess: (response) => {
      const itemSearchResponse = response.data?.data as ItemExtended;

      const currenItemAdded = find(ordersInfo, {
        itemId: itemSearchResponse.itemId,
      });

      if (!currenItemAdded) {
        handleAddOrder(itemSearchResponse);
        handleOnSelectOrder(itemSearchResponse, true, 0);
      } else {
        enqueueSnackbar('Este pedido ya fue agregado', {
          variant: 'error',
        });
      }
    },
    withPostFailure: () => {
      enqueueSnackbar('Pedido no encontrado', {
        variant: 'error',
      });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      formHelpers: FormikHelpers<typeof initialValues>,
    ) => {
      await executeSearchItem({
        associatedCompanyId: values[FIELDS_NAME.ASSOCIATED_COMPANY_ID],
        searchText: values[FIELDS_NAME.SEARCH],
      });

      formHelpers.resetForm({
        values: {
          associatedCompanyId: values[FIELDS_NAME.ASSOCIATED_COMPANY_ID],
          search: '',
        },
      });

      searchInputRef.current?.focus();
      formHelpers.setSubmitting(false);
    },
    [executeSearchItem],
  );

  const {
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
    getFieldProps,
    dirty,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (currentAssociatedCompanies.length === 1) {
      const currentAssociatedCompany = currentAssociatedCompanies[0];

      setFieldValue(
        FIELDS_NAME.ASSOCIATED_COMPANY_ID,
        currentAssociatedCompany.id,
      );

      currentAssociatedCompaniesAutocomplete.current?.setValue(
        currentAssociatedCompany,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsOrders = useMemo<ColumnDescription<ItemExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        formatter: (_cell, row) => (
          <OrderClientInfo
            name={row.title}
            itemTypeId={row.itemTypeId}
            trackingCode={row.trackingCode}
          />
        ),
      },
      {
        dataField: 'referenceId',
        text: 'Nro. de Orden',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 170 },
      },
      {
        dataField: 'giverCompanyName',
        text: 'Empresa',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 150 },
      },
      {
        dataField: 'unit1',
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.unit1 * row.amount}
            capacity2={row.unit2 * row.amount}
            numberOfPackages={row.numberOfPackages}
            maximumVisits={null}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 120 },
      },
    ],
    [],
  );

  const [expandedOrdes, toogleExpandedOrders] = useToggle(true);

  const selectedOrdersLabel = useMemo(() => {
    const countSelecteds = selectedOrdersIds.length;

    const entityPluralize = pluralize({
      singular: 'pedido',
      count: countSelecteds,
      includeCount: true,
    });

    const selectedText = pluralize({
      singular: 'seleccionado',
      count: countSelecteds,
      includeCount: false,
    });

    return `(${entityPluralize} ${selectedText})`;
  }, [selectedOrdersIds]);

  const indicatorsOrdersCharge = useMemo(
    () =>
      selectedOrders.reduce(
        (acc, selectedOrder) => {
          acc.capacity1 += selectedOrder.unit1 * selectedOrder.amount;
          acc.capacity2 += selectedOrder.unit2 * selectedOrder.amount;
          acc.numberOfPackages += selectedOrder.numberOfPackages;

          return acc;
        },
        { capacity1: 0, capacity2: 0, numberOfPackages: 0 },
      ),
    [selectedOrders],
  );

  return (
    <Stack spacing={3} flex={1}>
      <DialogContentText color="text.secondary">
        Selecciona la empresa de origen del pedido e ingresa los pedidos para
        realizar la colecta de forma manual. Los pedidos se marcarán como{' '}
        <b>colectados</b>.
      </DialogContentText>

      <Stack spacing={2} component="form" onSubmit={handleSubmit}>
        <CustomAutocomplete
          innerRef={currentAssociatedCompaniesAutocomplete}
          options={currentAssociatedCompanies}
          label="Seleccione la empresa"
          name={FIELDS_NAME.ASSOCIATED_COMPANY_ID}
          onChange={(_e, currentVal) => {
            setFieldValue(
              FIELDS_NAME.ASSOCIATED_COMPANY_ID,
              currentVal?.id ?? '',
            );
          }}
          onBlur={() => {
            setFieldTouched(FIELDS_NAME.ASSOCIATED_COMPANY_ID, true);
          }}
          error={hasError(touched, errors, FIELDS_NAME.ASSOCIATED_COMPANY_ID)}
          fullWidth
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <li
              {...props}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <UserInfo name={option.name} urlAvatar={option.logoFile?.url} />
            </li>
          )}
          startAdornment={(val) =>
            val && (
              <Avatar
                src={val.logoFile?.url}
                alt={val?.name}
                sx={{ width: 20, height: 20 }}
              />
            )
          }
          filterOptions={createFilterOptions({
            ignoreAccents: true,
            ignoreCase: true,
            stringify: (option) => option.name.replace(',', ''),
          })}
        />

        <Stack spacing={2} direction="row">
          <TextField
            label="Nro. Orden / Nro. Tracking"
            autoComplete="off"
            fullWidth
            error={hasError(touched, errors, FIELDS_NAME.SEARCH)}
            inputRef={searchInputRef}
            {...getFieldProps(FIELDS_NAME.SEARCH)}
          />

          <LoadingButton
            type="submit"
            variant="outlined"
            color="primary"
            disabled={!dirty || !isValid}
            loading={loadingSearchItem || isSubmitting}
            sx={{
              minWidth: 150,
            }}
          >
            Agregar
          </LoadingButton>
        </Stack>
      </Stack>

      {ordersInfo.length > 0 && (
        <Stack>
          <DialogImportFromAssociatedCompanyImporterCard variant="outlined">
            <DialogImportFromAssociatedCompanyImporterCardContent>
              <DialogImportFromAssociatedCompanyImporterCardHeader
                onClick={() => toogleExpandedOrders()}
                className={classNames({
                  [classes.expanded]: expandedOrdes,
                })}
                disableRipple
                disableTouchRipple
              >
                <DialogImportFromAssociatedCompanyImporterCardHeaderText
                  primary="PEDIDOS EN COLECTA"
                  secondary={selectedOrdersLabel}
                />

                <DialogImportFromAssociatedCompanyImporterCardHeaderIndicators
                  capacity1={indicatorsOrdersCharge.capacity1}
                  capacity2={indicatorsOrdersCharge.capacity2}
                  numberOfPackages={indicatorsOrdersCharge.numberOfPackages}
                  maximumVisits={null}
                  fontSize={14}
                  fontWeight="bold"
                  autoFlow="row"
                  rows={1}
                />

                <KeyboardArrowDown className={classes.expandIcon} />
              </DialogImportFromAssociatedCompanyImporterCardHeader>

              <Collapse
                in={expandedOrdes}
                timeout="auto"
                unmountOnExit
                sx={{ height: '100%' }}
              >
                <DialogImportFromAssociatedCompanyImporterCardDataTableContainer>
                  <DataTable
                    loading={false}
                    data={ordersInfo}
                    columns={columnsOrders}
                    keyField="itemId"
                    selectRow={selectRowsPropsOrders}
                    condensed
                  />
                </DialogImportFromAssociatedCompanyImporterCardDataTableContainer>
              </Collapse>
            </DialogImportFromAssociatedCompanyImporterCardContent>
          </DialogImportFromAssociatedCompanyImporterCard>
        </Stack>
      )}
    </Stack>
  );
};

export default DialogImportFromAssociatedCompanyImporter;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AuthExtendedCompany } from '~services/auth/types';

import { AppThunk, RootState } from '../../types';
import { AuthState } from './types';

const initialState: AuthState = {
  token: null,
  user: null,
  userRemember: null,
  subscription: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<AuthState['token']>) => {
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<AuthState['user']>) => {
      state.user = action.payload;
    },
    setUserCompany: (state, action: PayloadAction<AuthExtendedCompany>) => {
      if (state.user && state.user.company) {
        state.user.company = action.payload;
      }
    },
    setUserCompanyType: (
      state,
      action: PayloadAction<AuthExtendedCompany['companyTypeId']>,
    ) => {
      if (state.user && state.user.company) {
        state.user.company.companyTypeId = action.payload;
      }
    },
    setUserRemember: (state, action: PayloadAction<string>) => {
      state.userRemember = action.payload;
    },
    resetUserRemember: (state) => {
      state.userRemember = initialState.userRemember;
    },
    setIsOwner: (state, action: PayloadAction<boolean>) => {
      if (state.user?.isOwner) {
        state.user.isOwner = action.payload;
      }
    },
    setSubscription: (
      state,
      action: PayloadAction<AuthState['subscription']>,
    ) => {
      state.subscription = action.payload;
    },
    resetAuth: (state) => ({
      ...initialState,
      userRemember: state.userRemember,
    }),
  },
});

export const {
  setToken,
  setUser,
  setUserCompany,
  setUserCompanyType,
  resetAuth,
  setUserRemember,
  resetUserRemember,
  setIsOwner,
  setSubscription,
} = authSlice.actions;

export const login =
  (auth: Omit<AuthState, 'userRemember' | 'subscription'>): AppThunk =>
  (dispatch) => {
    dispatch(setToken(auth.token));
    dispatch(setUser(auth.user));
    dispatch(setSubscription(auth.user?.suscription ?? null));

    // Mock crossCompanyEnabled
    if (auth.user?.company) {
      dispatch(
        setUserCompany({ ...auth.user.company, crossCompanyEnabled: true }),
      );
    }
  };

const persistAuthConfig: PersistConfig<AuthState> = {
  key: 'routix-auth',
  storage,
};

const persitAuthReducer = persistReducer(persistAuthConfig, authSlice.reducer);

export const selectAuth = (state: RootState): AuthState => state.auth;

export default persitAuthReducer;

import { ReactElement, memo, useMemo, useCallback } from 'react';
import { Typography } from '@mui/material';

import { formatterTime } from '~utils/formatter';

import { TimeWindowInfoProps } from './types';

const TimeWindowInfo = ({
  allDay,
  windowFrom1,
  windowTo1,
  windowFrom2,
  windowTo2,
}: TimeWindowInfoProps): ReactElement => {
  const parseWindowTimes = useCallback(
    (from: string, to: string): string =>
      `${formatterTime(from)} - ${formatterTime(to)}`,
    [],
  );

  const text = useMemo(() => {
    if (allDay) return 'Todo el día';

    if (!allDay && !windowFrom1 && !windowTo1 && !windowFrom2 && !windowTo2)
      return 'Sin especificar';

    let time = '';

    if (windowFrom1 && windowTo1)
      time += parseWindowTimes(windowFrom1, windowTo1);

    if (windowFrom2 && windowTo2)
      time += ` / ${parseWindowTimes(windowFrom2, windowTo2)}`;

    return time;
  }, [
    allDay,
    parseWindowTimes,
    windowFrom1,
    windowFrom2,
    windowTo1,
    windowTo2,
  ]);

  return <Typography variant="inherit">{text}</Typography>;
};

export default memo(TimeWindowInfo);

import { ReactElement, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth';
import { PATHS } from '~constants/index';

import ComponentWithSuspense from '../ComponentWithSuspense';

interface AuthRouteProps {
  component: ReactNode;
}

const AuthRoute = ({ component }: AuthRouteProps): ReactElement => {
  const navigate = useNavigate();
  const { token, user } = useAppSelector(selectAuth);

  useEffect(() => {
    if (!!token && !!user) {
      navigate(PATHS.HOME.BASE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ComponentWithSuspense component={component} />;
};

export default AuthRoute;

import * as Yup from 'yup';
import { VALIDATIONS_ERROR } from '~constants/validationsError';
import { FileObj } from '~globals/types';

export const FIELDS_NAME = {
  LOGO: 'logo',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  CONTACT_PHONE: 'contactPhone',
  COMPANY_NAME: 'companyName',
  COMPANY_DOCUMENT: 'companyDocument',
  COUNTRY: 'country',
  INDUSTRY: 'industry',
} as const;

export const initialValues = {
  [FIELDS_NAME.LOGO]: [] as FileObj[],
  [FIELDS_NAME.FIRST_NAME]: '',
  [FIELDS_NAME.LAST_NAME]: '',
  [FIELDS_NAME.EMAIL]: '',
  [FIELDS_NAME.PASSWORD]: '',
  [FIELDS_NAME.CONFIRM_PASSWORD]: '',
  [FIELDS_NAME.CONTACT_PHONE]: '',
  [FIELDS_NAME.COMPANY_NAME]: '',
  [FIELDS_NAME.COMPANY_DOCUMENT]: '',
  [FIELDS_NAME.COUNTRY]: '0',
  [FIELDS_NAME.INDUSTRY]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.LOGO]: Yup.array()
    .of(Yup.mixed())
    .min(1, VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.FIRST_NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.LAST_NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.EMAIL]: Yup.string()
    .email(VALIDATIONS_ERROR.EMAIL)
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.PASSWORD]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.CONFIRM_PASSWORD]: Yup.string()
    .required(VALIDATIONS_ERROR.REQUIRED)
    .oneOf(
      [Yup.ref(FIELDS_NAME.PASSWORD), null],
      VALIDATIONS_ERROR.PASSWORD_NOT_MATCH,
    ),
  [FIELDS_NAME.CONTACT_PHONE]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.COMPANY_NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.COMPANY_DOCUMENT]: Yup.string(),
  [FIELDS_NAME.COUNTRY]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.INDUSTRY]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
});

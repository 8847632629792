import { ReactElement, useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  Typography,
  Stack,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { ListField, AccessFeature } from '~components/index';
import { PlanFeaturesTypes } from '~globals/types/enums';

import { FIELDS_NAME, getInitialValues } from '../../utils';
import { OrderFormSectionContainer } from '../../styles';

const OrderFormArticlesSection = (): ReactElement => {
  const { values } = useFormikContext<ReturnType<typeof getInitialValues>>();

  const articles = useMemo(() => values[FIELDS_NAME.ARTICLES], [values]);

  return (
    <OrderFormSectionContainer
      defaultExpanded={false}
      disableGutters
      elevation={0}
      variant="outlined"
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel-order-form-articles-content"
        id="panel-order-form-articles-header"
      >
        <AccessFeature.Typograghy
          validFeature={PlanFeaturesTypes.MgmItemTask}
          component="h6"
          fontWeight="bold"
        >
          Artículos / Tareas
        </AccessFeature.Typograghy>
      </AccordionSummary>

      <AccordionDetails>
        <Stack spacing={2}>
          <AccessFeature.Alert validFeature={PlanFeaturesTypes.MgmItemTask} />

          <AccessFeature.Hidden
            validFeature={PlanFeaturesTypes.MgmItemTask}
            type="notAccess"
          >
            <>
              {!articles.length && (
                <Typography variant="h6" fontWeight="bold" textAlign="center">
                  No hay artículos / tareas cargados
                </Typography>
              )}

              <ListField
                name={FIELDS_NAME.ARTICLES}
                label="Artículos / Tareas"
                addLabel="Nuevo artículo / tarea"
                addTooltip="Agregar artículo / tarea"
                addItemPosition="last"
                itemLabel="Descripción"
                deleteTooltip="Eliminar artículo / tarea"
                inputsMaxWidth={600}
              />
            </>
          </AccessFeature.Hidden>
        </Stack>
      </AccordionDetails>
    </OrderFormSectionContainer>
  );
};

export default OrderFormArticlesSection;

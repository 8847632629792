import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const VehicleIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M.6,13.12a1.07,1.07,0,0,1,1.17-.69c.55,0,1.1,0,1.66,0V10.63h-.6a.92.92,0,0,1-1-.91.91.91,0,0,1,1-.9h.59c0-.13,0-.24,0-.34V6.22a.91.91,0,0,1,1-1h16.6a.9.9,0,0,1,1,1V10h3.17a1.14,1.14,0,0,1,.88.37q2.49,2.58,5,5.15a1.25,1.25,0,0,1,.37.91c0,2,0,4,0,6a.92.92,0,0,1-1,1c-.31,0-.62,0-.93,0s-.32.07-.41.28a3.72,3.72,0,0,1-5.75,1.58,3.58,3.58,0,0,1-1.19-1.6.32.32,0,0,0-.36-.26H13.69a.32.32,0,0,0-.35.24,3.72,3.72,0,0,1-7,0A.3.3,0,0,0,6,23.45c-.51,0-1,0-1.54,0a.93.93,0,0,1-1-1.05V14.25H2.63c-.29,0-.58,0-.87,0A1,1,0,0,1,.6,13.54Zm4.68,1.13v7.37h.91c0-.08,0-.15.07-.21a3.72,3.72,0,0,1,7.19,0c.06.22.16.27.37.27h6.4V7H5.28V8.82h5.29a.9.9,0,0,1,.94,1.11.93.93,0,0,1-1,.7H5.63l-.35,0v1.79H10a.9.9,0,0,1,.2,1.77,1.93,1.93,0,0,1-.42,0H5.28Zm16.78,2.93v4a3.77,3.77,0,0,1,7.26.43h.32V17.18Zm0-1.85h6.34l-3.3-3.41a.56.56,0,0,0-.32-.11H22.05Zm-12.18,9a1.92,1.92,0,1,0-1.93-1.9,1.92,1.92,0,0,0,1.93,1.9m17.69-1.93a1.93,1.93,0,1,0-1.93,1.93,1.91,1.91,0,0,0,1.93-1.93" />
  </SvgIcon>
);

export default VehicleIcon;

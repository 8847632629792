import {
  ReactElement,
  MouseEvent,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { TextField, Popover, InputAdornment } from '@mui/material';
import { ColorButton, createColor, Color, ColorBox } from 'mui-color';

import { useToggle } from '~hooks/index';

import { getColorText } from './helpers';
import { ColorPickerProps } from './types';

const defaultPalette = {
  red: '#ff0000',
  blue: '#0000ff',
  green: '#00ff00',
  yellow: 'yellow',
  cyan: 'cyan',
  lime: 'lime',
  gray: 'gray',
  orange: 'orange',
  purple: 'purple',
  black: 'black',
  pink: 'pink',
  darkblue: 'darkblue',
};

const ColorPicker = ({
  value,
  onChange,
  onBlur,
  label,
  error = false,
  fullWidth = false,
}: ColorPickerProps): ReactElement => {
  const refPicker = useRef(null);

  const [openPicker, setOpenPicker] = useToggle();

  const handleOpenPicker = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setOpenPicker(true);
    },
    [setOpenPicker],
  );

  const [color, setColor] = useState(createColor(value));

  const handleChangeColor = useCallback(
    (newColor: Color) => {
      setColor(newColor);

      onChange(getColorText(newColor, true));
    },
    [onChange],
  );

  const inputVal = useMemo(() => getColorText(color, true), [color]);

  const shrink = useMemo(
    () => Boolean(inputVal) || openPicker,
    [inputVal, openPicker],
  );

  return (
    <>
      <TextField
        ref={refPicker}
        label={label}
        autoComplete="off"
        onClick={handleOpenPicker}
        value={inputVal}
        onBlur={onBlur}
        InputLabelProps={{ shrink }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              className="color-picker-button"
              position="end"
              onClick={handleOpenPicker}
            >
              <ColorButton color={color} />
            </InputAdornment>
          ),
        }}
        fullWidth={fullWidth}
        error={error}
      />

      <Popover
        id="color-popover"
        open={openPicker}
        onClose={() => setOpenPicker(false)}
        anchorEl={refPicker.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ColorBox
          value={color}
          onChange={handleChangeColor}
          deferred={false}
          palette={defaultPalette}
          disableAlpha
          hslGradient={false}
        />
      </Popover>
    </>
  );
};

export default ColorPicker;

import { ReactElement } from 'react';
import { Card, Stack } from '@mui/material';

import { ReportWidgetDataProps } from './types';
import {
  ReportWidgetDataCardContent,
  ReportWidgetDataImg,
  ReportWidgetDataDescriptionText,
  ReportWidgetDataDataText,
} from './styles';

const ReportWidgetData = ({
  description,
  data,
  iconSrc,
  iconAlt,
}: ReportWidgetDataProps): ReactElement => (
  <Card variant="outlined">
    <ReportWidgetDataCardContent>
      <ReportWidgetDataImg src={iconSrc} alt={iconAlt} />

      <Stack flex={1} textAlign="right">
        <ReportWidgetDataDescriptionText>
          {description}
        </ReportWidgetDataDescriptionText>
        <ReportWidgetDataDataText fontWeight="bold">
          {data}
        </ReportWidgetDataDataText>
      </Stack>
    </ReportWidgetDataCardContent>
  </Card>
);

export default ReportWidgetData;

import {
  ReactElement,
  SyntheticEvent,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { AppBar } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';

import { isSuccessWorkProgressId } from '~components/StepperGenerateRoute/utils';

import { GenerateRouteCompletedTabsContextValue } from './types';
import {
  GenerateRouteCompletedTabsSummary,
  GenerateRouteCompletedTabsErrors,
} from './Tabs';
import GenerateRouteCompletedTabsProvider from './GenerateRouteCompletedTabsContext';
import {
  GenerateRouteCompletedTabsCard,
  GenerateRouteCompletedTabsTab,
  GenerateRouteCompletedTabsTabsPanelContainer,
  GenerateRouteCompletedTabsTabPanel,
} from './styles';

const GenerateRouteCompletedTabs = ({
  workProgressId,
  indicators,
  loadingIndicators,
  errors,
  totalStops,
  totalVehicles,
  type,
}: Omit<
  GenerateRouteCompletedTabsContextValue,
  'setActiveTab'
>): ReactElement => {
  const enabledSummary = useMemo(
    () => isSuccessWorkProgressId(workProgressId),
    [workProgressId],
  );

  const enabledErrors = useMemo(() => Boolean(errors.length), [errors]);

  const tabsList = useMemo(
    () => [
      {
        id: 'summary',
        label: 'Resumen',
        enabled: enabledSummary,
      },
      {
        id: 'errors',
        label: 'Errores',
        enabled: enabledErrors,
      },
    ],
    [enabledSummary, enabledErrors],
  );

  const tabsListPanels = useMemo(
    () => [
      {
        id: tabsList[0].id,
        panel: GenerateRouteCompletedTabsSummary,
        enabled: enabledSummary,
      },
      {
        id: tabsList[1].id,
        panel: GenerateRouteCompletedTabsErrors,
        enabled: enabledErrors,
      },
    ],
    [tabsList, enabledSummary, enabledErrors],
  );

  const defaultActiveTab = useMemo(() => {
    const defaultIndex = enabledSummary ? 0 : 1;

    return tabsList[defaultIndex].id;
  }, [enabledSummary, tabsList]);

  const [activeTab, setActiveTab] = useState<string>(defaultActiveTab);

  const handleChangeActiveTab = useCallback(
    (_e: SyntheticEvent, newValue: string) => {
      setActiveTab(newValue);
    },
    [],
  );

  return (
    <GenerateRouteCompletedTabsProvider
      value={{
        workProgressId,
        indicators,
        errors,
        totalStops,
        totalVehicles,
        loadingIndicators,
        setActiveTab,
        type,
      }}
    >
      <GenerateRouteCompletedTabsCard>
        <TabContext value={activeTab}>
          <AppBar position="static" color="tertiary" elevation={0}>
            <TabList
              onChange={handleChangeActiveTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabsList.map(
                ({ label, id, enabled }) =>
                  enabled && (
                    <GenerateRouteCompletedTabsTab
                      key={`generate-route-completed-tab-${id}`}
                      label={label}
                      value={id}
                    />
                  ),
              )}
            </TabList>
          </AppBar>

          <GenerateRouteCompletedTabsTabsPanelContainer>
            {tabsListPanels.map(
              ({ id, panel: Panel, enabled }) =>
                enabled && (
                  <GenerateRouteCompletedTabsTabPanel
                    key={`generate-route-completed-panel-${id}`}
                    value={id}
                  >
                    <Panel />
                  </GenerateRouteCompletedTabsTabPanel>
                ),
            )}
          </GenerateRouteCompletedTabsTabsPanelContainer>
        </TabContext>
      </GenerateRouteCompletedTabsCard>
    </GenerateRouteCompletedTabsProvider>
  );
};

export default GenerateRouteCompletedTabs;

import { styled, Box, Avatar, Chip } from '@mui/material';

export const DialogInvitationPartnerCompanyConfirmationHeader = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  }),
);

export const DialogInvitationPartnerCompanyConfirmationHeaderAvatar = styled(
  Avatar,
)(({ theme }) => ({
  width: 100,
  height: 100,
  border: `1px solid ${theme.palette.divider}`,
}));

export const DialogInvitationPartnerCompanyConfirmationHeaderIcon = styled(
  'img',
)({
  width: 30,
  objectFit: 'contain',
});

export const DialogInvitationPartnerCompanyConfirmationChip = styled(Chip)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  }),
);

import {
  styled,
  Button,
  buttonClasses,
  Menu,
  menuClasses,
} from '@mui/material';
import { Visibility, Edit, Delete } from '@mui/icons-material';

export const MenuActionsDataTableButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'iconButton' && prop !== 'show',
})<{ iconButton: boolean; show: boolean }>(
  ({ theme, iconButton, size, show }) => ({
    fontSize: theme.typography.pxToRem(12),

    ...(iconButton && {
      minWidth: 0,
      padding: '5px 4px',

      ...(size === 'small' && {
        padding: '3px 2px',
      }),

      ...(size === 'large' && {
        padding: '12px 10px',
      }),

      [`& .${buttonClasses.endIcon}`]: {
        margin: 0,
      },
    }),

    ...(!show && {
      visibility: 'hidden',
    }),
  }),
);

export const MenuActionsDataTableMenu = styled(Menu)({
  [`& .${menuClasses.paper}`]: {
    border: '1px solid #d3d4d5',
  },
});

export const ShowIcon = styled(Visibility)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export const EditIcon = styled(Edit)(({ theme }) => ({
  color: theme.palette.info.main,
}));

export const DeleteIcon = styled(Delete)(({ theme }) => ({
  color: theme.palette.error.main,
}));

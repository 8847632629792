import { StringSchema } from 'yup';
import { validatePhone } from 'react-international-phone';

import { VALIDATIONS_ERROR } from '~constants/index';

export function validPhone(
  this: StringSchema,
  message = VALIDATIONS_ERROR.PHONE,
): StringSchema {
  return this.test('valid-phone', message, (value) => {
    if (value) {
      const { isValid } = validatePhone(value);

      return isValid;
    }

    return false;
  });
}

import { styled, Typography, typographyClasses } from '@mui/material';

export const VehiclePopupMapContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  minWidth: 140,
}));

export const VehiclePopupMapTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightBold,
}));

export const VehiclePopupMapRowItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(0.5),
  fontSize: theme.typography.pxToRem(11),
  lineHeight: 1.2,

  [`& .${typographyClasses.root}`]: {
    flex: 1,
  },
}));

export const VehiclePopupMapIconContainer = styled('div')(({ theme }) => ({
  width: 15,

  '& svg': {
    color: theme.palette.action.active,
  },
}));

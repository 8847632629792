import { styled, Chip, chipClasses, Stack } from '@mui/material';

export const OrderFormActionChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 80,
  },
  [`& .${chipClasses.icon}`]: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

export const OrderEmailsChipWrapper = styled(Stack)(() => ({
  marginTop: 16,
  marginBottom: 8,
  flexWrap: 'wrap',
  maxWidth: 400,
  gap: 4,
}));

export const OrderEmailsChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    overflow: 'hidden',
  },
  [`& .${chipClasses.icon}`]: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

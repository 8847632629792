import { useMemo } from 'react';

import { selectAuth } from '~store/slices/auth';

import { useAppSelector } from './useAppSelector';
import { useTypePartnerCompany } from './useTypePartnerCompany';

export interface EnabledCrossCompanyReturn {
  isEnabledCrossCompany: boolean;
}

export const useEnabledCrossCompany = (): EnabledCrossCompanyReturn => {
  const { user } = useAppSelector(selectAuth);
  const { isGiver } = useTypePartnerCompany();

  const crossCompanyEnabled = useMemo(
    () => user?.company.crossCompanyEnabled ?? false,
    [user],
  );

  return {
    isEnabledCrossCompany: crossCompanyEnabled || isGiver,
  };
};

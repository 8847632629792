import {
  HTMLAttributes,
  ReactElement,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import { LocationOn as LocationIcon } from '@mui/icons-material';
import parse from 'autosuggest-highlight/parse';

import { usePlacesAutocomplete, PlaceType } from '~hooks/usePlacesAutocomplete';

import { PlacesAutocompleteRef, PlacesAutocompleteProps } from './types';

const defaultGetOptionLabel: Required<PlacesAutocompleteProps>['getOptionLabel'] =
  (option) => (typeof option === 'string' ? option : option.description);

const defaultFilterOptions: Required<PlacesAutocompleteProps>['filterOptions'] =
  (option) => option;

const PlacesAutocomplete = forwardRef<
  PlacesAutocompleteRef,
  PlacesAutocompleteProps
>(function PlacesAutocomplete(inProps, ref): ReactElement {
  const {
    id = 'places-autocomplete',
    getOptionLabel = defaultGetOptionLabel,
    filterOptions = defaultFilterOptions,
    onChange: onChangeProps,
    onInputChange: onInputChangeProps,
    label = 'Direccíon',
    error = false,
    helperText,
    initialValueRequest,
    ...rest
  } = inProps;

  const { value, setValue, inputValue, setInputValue, options, setOptions } =
    usePlacesAutocomplete(initialValueRequest);

  const onChange = useCallback<Required<PlacesAutocompleteProps>['onChange']>(
    (event, newValue, ...restParams) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);

      onChangeProps?.(event, newValue, ...restParams);
    },
    [options, setOptions, setValue, onChangeProps],
  );

  const onInputChange = useCallback<
    Required<PlacesAutocompleteProps>['onInputChange']
  >(
    (event, newInputValue, ...restParams) => {
      setInputValue(newInputValue);

      onInputChangeProps?.(event, newInputValue, ...restParams);
    },
    [setInputValue, onInputChangeProps],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField
        {...params}
        label={label}
        error={error}
        helperText={helperText}
        fullWidth
      />
    ),
    [label, error, helperText],
  );

  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLLIElement>, option: PlaceType): JSX.Element => {
      const matches = option.matched_substrings;
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match) => [match.offset, match.offset + match.length]),
      );

      return (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid item>
              <Box
                component={LocationIcon}
                sx={{ color: 'text.secondary', mr: 2 }}
              />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="text.secondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        </li>
      );
    },
    [],
  );

  const resetAutocomplete = useCallback((): void => {
    setValue(null);
    setInputValue('');
  }, [setValue, setInputValue]);

  useImperativeHandle(
    ref,
    () => ({
      resetAutocomplete,
    }),
    [resetAutocomplete],
  );

  return (
    <Autocomplete
      id={id}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      loadingText="Cargando..."
      noOptionsText="Sin opciones"
      {...rest}
    />
  );
});

export default PlacesAutocomplete;

import { styled, Box, CircularProgress, Typography } from '@mui/material';

import { SubscriptionAvailableRoutesStateCircularProgressSizes } from './types';

export const SubscriptionAvailableRoutesStateCircularProgressContainer = styled(
  Box,
)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

export const SubscriptionAvailableRoutesStateCircularProgressContent = styled(
  Box,
)({
  display: 'flex',
  position: 'relative',
});

export const SubscriptionAvailableRoutesStateCircularProgressFullProgress =
  styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.grey[400],
  }));

export const SubscriptionAvailableRoutesStateCircularProgressDymanicProgress =
  styled(CircularProgress, {
    shouldForwardProp: (prop) => prop !== 'currentColor',
  })<{ currentColor: string }>(({ currentColor }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    color: currentColor,
  }));

export const SubscriptionAvailableRoutesStateCircularProgressLegendContainer =
  styled(Box)({
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

export const SubscriptionAvailableRoutesStateCircularProgressLegendText =
  styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'size' && prop !== 'currentColor',
  })<{
    size: SubscriptionAvailableRoutesStateCircularProgressSizes;
    currentColor: string;
  }>(({ theme, size, currentColor }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: currentColor,
    fontWeight: theme.typography.fontWeightBold,

    ...(size === 'xSmall' && {
      fontSize: theme.typography.pxToRem(8),
    }),

    ...(size === 'small' && {
      fontSize: theme.typography.pxToRem(10),
    }),

    ...(size === 'large' && {
      fontSize: theme.typography.pxToRem(16),
    }),
  }));

export const SubscriptionAvailableRoutesStateCircularProgressLabelText = styled(
  Typography,
  {
    shouldForwardProp: (prop) => prop !== 'size',
  },
)<{ size: SubscriptionAvailableRoutesStateCircularProgressSizes }>(
  ({ theme, size }) => ({
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,

    ...(size === 'xSmall' && {
      fontSize: theme.typography.pxToRem(10),
    }),

    ...(size === 'small' && {
      fontSize: theme.typography.pxToRem(12),
    }),

    ...(size === 'large' && {
      fontSize: theme.typography.pxToRem(16),
    }),
  }),
);

import { ReactElement, useMemo } from 'react';
import { List, Collapse } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import classNames from 'classnames';

import {
  ROUTE_ITEM_STATE,
  ROUTE_ITEM_STATE_COLOR,
} from '~constants/routeItemState';
import { useToggle } from '~hooks/index';

import {
  classes,
  VehicleMapLegendContainer,
  VehicleMapLegendListButton,
  VehicleMapLegendListItem,
  VehicleMapLegendListItemText,
  VehicleMapLegendListIcon,
  VehicleMapLegendMarkerIcon,
  VehicleMapLegendSuggestedRouteIcon,
  VehicleMapLegendCurrentRouteIcon,
} from './styles';

const VehicleMapLegend = (): ReactElement => {
  const [expanded, toogleExpanded] = useToggle();

  const legendItems = useMemo(
    () => ({
      markerInProgress: {
        label: ROUTE_ITEM_STATE[2],
        icon: (
          <VehicleMapLegendMarkerIcon stateColor={ROUTE_ITEM_STATE_COLOR[2]} />
        ),
      },
      markerFinalizedSuccess: {
        label: ROUTE_ITEM_STATE[3],
        icon: (
          <VehicleMapLegendMarkerIcon stateColor={ROUTE_ITEM_STATE_COLOR[3]} />
        ),
      },
      markerFinalizedError: {
        label: ROUTE_ITEM_STATE[4],
        icon: (
          <VehicleMapLegendMarkerIcon stateColor={ROUTE_ITEM_STATE_COLOR[4]} />
        ),
      },
      markerWithoutVisiting: {
        label: ROUTE_ITEM_STATE[5],
        icon: (
          <VehicleMapLegendMarkerIcon stateColor={ROUTE_ITEM_STATE_COLOR[5]} />
        ),
      },
      suggestedRoute: {
        label: 'Ruta sugerida',
        icon: <VehicleMapLegendSuggestedRouteIcon />,
      },
      execRoute: {
        label: 'Ruta realizada',
        icon: <VehicleMapLegendCurrentRouteIcon />,
      },
    }),
    [],
  );

  return (
    <VehicleMapLegendContainer>
      <List disablePadding>
        <VehicleMapLegendListButton
          onClick={() => toogleExpanded()}
          className={classNames({ [classes.expanded]: expanded })}
        >
          <VehicleMapLegendListItemText
            primary="Referencias"
            primaryTypographyProps={{ variant: 'caption', fontWeight: 'bold' }}
          />

          <KeyboardArrowDown className={classes.expandIcon} />
        </VehicleMapLegendListButton>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List disablePadding>
            {Object.entries(legendItems).map(([key, item]) => (
              <VehicleMapLegendListItem key={`vehicle-map-legend-item-${key}`}>
                <VehicleMapLegendListIcon>{item.icon}</VehicleMapLegendListIcon>

                <VehicleMapLegendListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    variant: 'caption',
                  }}
                />
              </VehicleMapLegendListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </VehicleMapLegendContainer>
  );
};

export default VehicleMapLegend;

import { ReactElement, ElementType, useMemo } from 'react';
import { Stack } from '@mui/material';

import { NotificationApiTransactionWorkProgressId } from '~globals/types/notifications';

import PlanningRejectedOperationsErrors from '../PlanningRejectedOperationsErrors';
import StepperGenerateRouteProvider, {
  useStepperGenerateRoutePlanningStepperContext,
} from './SteppetGenerateRouteContext';
import StepperGenerateRouteStepIcon from './StepIcon';
import { StepperGenerateRouteContextValue } from './types';
import { isCompletedWorkProgressId } from './utils';
import {
  StepperGenerateRouteStepper,
  StepperGenerateRouteStepConnector,
  StepperGenerateRouteStep,
  StepperGenerateRouteStepLabel,
} from './styles';
import {
  StepperGenerateRouteStepOptimizing,
  StepperGenerateRouteStepGenerating,
  StepperGenerateRouteStepGenerated,
  StepperGenerateRouteStepError,
} from './Steps';

const STEPS = [{ id: 'optimizing' }, { id: 'generating' }, { id: 'generated' }];

const STEPS_INFO_COMPONENTS: Record<
  NotificationApiTransactionWorkProgressId,
  ElementType
> = {
  1: StepperGenerateRouteStepOptimizing,
  2: StepperGenerateRouteStepGenerating,
  3: StepperGenerateRouteStepGenerated,
  4: StepperGenerateRouteStepError,
};

const StepperGenerateRoute = (): ReactElement => {
  const { activeStep, workProgressId, light, errors, showErrors } =
    useStepperGenerateRoutePlanningStepperContext();

  const InfoComponent = useMemo(
    () => STEPS_INFO_COMPONENTS[workProgressId],
    [workProgressId],
  );

  return (
    <Stack spacing={2}>
      <StepperGenerateRouteStepper
        activeStep={activeStep}
        orientation="horizontal"
        connector={<StepperGenerateRouteStepConnector light={light} />}
      >
        {STEPS.map(({ id }) => (
          <StepperGenerateRouteStep key={`stepper-generate-route-step-${id}`}>
            <StepperGenerateRouteStepLabel
              StepIconComponent={StepperGenerateRouteStepIcon}
            />
          </StepperGenerateRouteStep>
        ))}
      </StepperGenerateRouteStepper>

      <InfoComponent />

      {isCompletedWorkProgressId(workProgressId) && showErrors && (
        <PlanningRejectedOperationsErrors errors={errors} />
      )}
    </Stack>
  );
};

const StepperGenerateRouteWrapper = ({
  activeStep,
  workProgressId,
  errors,
  type,
  light = true,
  showErrors = true,
}: StepperGenerateRouteContextValue): ReactElement => (
  <StepperGenerateRouteProvider
    value={{
      activeStep,
      workProgressId,
      errors,
      type,
      light,
      showErrors,
    }}
  >
    <StepperGenerateRoute />
  </StepperGenerateRouteProvider>
);

export default StepperGenerateRouteWrapper;

import { ReactElement, MouseEvent, useMemo, useCallback } from 'react';
import { Stack, Divider, Typography, Link } from '@mui/material';

import { formatCurrencyValue } from '~utils/index';
import { useSubscription } from '~hooks/index';

import { SubscriptionPaymentModalData } from '../PaymentModal/types';

import { SubscriptionPlanPriceCardProps } from './types';
import { getAclarationText, getPaymentTypeNoteText } from './utils';
import {
  SubscriptionPlanPriceCardRoot,
  SubscriptionPlanPriceCardHeader,
  SubscriptionPlanPriceCardRatingIcon,
  SubscriptionPlanPriceCardRecommendedChip,
  SubscriptionPlanPriceCardContent,
  SubscriptionPlanPriceCardCheckIcon,
  SubscriptionPlanPriceCardFeatureTypography,
  SubscriptionPlanPriceCardActions,
  SubscriptionPlanPriceCardActionsButton,
} from './styles';

const defaultHighlightColor = '#00b0ff';

const SubscriptionPlanPriceCard = ({
  direction = 'vertical',
  rating,
  name,
  featureTitle,
  features,
  currentPrice,
  isFree = false,
  highlightColor = defaultHighlightColor,
  starsColors,
  aclarationColor,
  isRecommended = false,
  withShadow = true,
  onSubscribe,
  paymentType = 'monthly',
  providersPlansId,
  disabledByPlans = [],
}: SubscriptionPlanPriceCardProps): ReactElement => {
  const subscription = useSubscription();

  const price = useMemo(
    () => (isFree || !currentPrice ? 0 : currentPrice[paymentType]),
    [currentPrice, isFree, paymentType],
  );

  const aclaration = useMemo(() => getAclarationText(isFree), [isFree]);

  const paymentNote = useMemo(() => {
    if (isFree)
      return (
        <>
          Sin tarjetas ni cargos ocultos <b>de por vida</b>
        </>
      );

    return getPaymentTypeNoteText(paymentType);
  }, [isFree, paymentType]);

  const isHorizontal = useMemo(() => direction === 'horizontal', [direction]);

  const currentPlanName = useMemo(
    () => subscription?.description?.replace('ANUAL', '').trimEnd(),
    [subscription?.description],
  );

  const hasSubscribePlan = useMemo(
    () => currentPlanName === name,
    [name, currentPlanName],
  );

  const disabledSubscribe = useMemo(() => {
    const hasPlanAssociated = disabledByPlans.includes(currentPlanName ?? '');

    const isCurrentSubscribePlan = hasSubscribePlan || hasPlanAssociated;

    return isCurrentSubscribePlan && !subscription?.isTrial;
  }, [
    disabledByPlans,
    currentPlanName,
    subscription?.isTrial,
    hasSubscribePlan,
  ]);

  const subscribeLabel = useMemo(
    () =>
      hasSubscribePlan && !subscription?.isTrial ? 'Suscripto' : 'Suscribirse',
    [hasSubscribePlan, subscription?.isTrial],
  );

  const handleSubscribe = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!disabledSubscribe) {
        const subscribeData: SubscriptionPaymentModalData = {
          name: `PLAN ${name}`,
          currentPrice: price,
          paymentType,
          providersPlansId,
          isSinglePayment: false,
          isPack: false,
        };

        onSubscribe(event, subscribeData);
      }
    },
    [
      disabledSubscribe,
      price,
      name,
      paymentType,
      providersPlansId,
      onSubscribe,
    ],
  );

  const aboutPlanUrlLink = useMemo(() => {
    return `https://routix.io/planes`;
  }, []);

  return (
    <SubscriptionPlanPriceCardRoot
      direction={direction}
      isRecommended={isRecommended}
      highlightColor={highlightColor}
      withShadow={withShadow}
    >
      <SubscriptionPlanPriceCardHeader>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack spacing={0}>
            <Stack direction="row">
              {Array.from({ length: rating }).map((_, index) => (
                <SubscriptionPlanPriceCardRatingIcon
                  key={`plan-price-card-${name}-rating-${index}`}
                  htmlColor={starsColors ?? highlightColor}
                />
              ))}
            </Stack>

            <Typography variant="h5" fontWeight="bold" lineHeight={1}>
              {name}
            </Typography>
          </Stack>

          {isRecommended && (
            <SubscriptionPlanPriceCardRecommendedChip
              label="RECOMENDADO"
              variant="filled"
              customColor={highlightColor}
              isBadge={isHorizontal}
            />
          )}
        </Stack>

        <Stack spacing={0.5}>
          <Stack
            direction="row"
            spacing={1}
            divider={<Divider orientation="vertical" />}
            alignItems="center"
          >
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography variant="h5" fontWeight="bold" component="span">
                $
              </Typography>

              <Typography
                variant="h2"
                fontWeight="bold"
                component="span"
                lineHeight={0.85}
              >
                {formatCurrencyValue(price, {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Stack>

            <Typography
              variant="body1"
              fontWeight="bold"
              component="span"
              lineHeight={1}
              color={aclarationColor}
            >
              {aclaration}
            </Typography>
          </Stack>

          <Typography variant="caption" color="text.secondary">
            {paymentNote}
          </Typography>
        </Stack>
      </SubscriptionPlanPriceCardHeader>

      <SubscriptionPlanPriceCardContent direction={direction}>
        {featureTitle && (
          <Typography variant="caption" fontWeight="bold">
            {featureTitle}
          </Typography>
        )}

        <Stack spacing={0}>
          {features.map((feature, index) => (
            <Stack
              key={`plan-price-card-${name}-feature-${index}`}
              spacing={1}
              direction="row"
              alignItems="flex-start"
            >
              <SubscriptionPlanPriceCardCheckIcon htmlColor={highlightColor} />

              <SubscriptionPlanPriceCardFeatureTypography
                isLarge={isHorizontal}
              >
                {feature}
              </SubscriptionPlanPriceCardFeatureTypography>
            </Stack>
          ))}
        </Stack>
      </SubscriptionPlanPriceCardContent>

      <SubscriptionPlanPriceCardActions hidden={isFree}>
        <SubscriptionPlanPriceCardActionsButton
          customColor={highlightColor}
          variant="contained"
          size="small"
          fullWidth
          onClick={handleSubscribe}
          disabled={disabledSubscribe}
        >
          {subscribeLabel}
        </SubscriptionPlanPriceCardActionsButton>

        <Link
          href={aboutPlanUrlLink}
          target="_blank"
          variant="body2"
          color={highlightColor}
        >
          Conocer más del plan
        </Link>
      </SubscriptionPlanPriceCardActions>
    </SubscriptionPlanPriceCardRoot>
  );
};

export default SubscriptionPlanPriceCard;

import { ReactElement, useCallback, useEffect } from 'react';
import {
  Stack,
  TextField,
  DialogContent,
  DialogActions,
  Typography,
  Link,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';

import { useLazyRequest } from '~hooks/index';
import { validateInvitation } from '~services/partnerCompany';
import { PartnerCompanyInvitationData } from '~services/partnerCompany/types';
import { hasError } from '~utils/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

// eslint-disable-next-line max-len
import { useDialogInvitationPartnerCompanyContext } from '../../DialogInvitationPartnerCompanyContext';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const DialogInvitationPartnerCompanyValidated = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    open,
    defaultInvitationCode,
    setActiveTab,
    setValidatedData,
    onValidated,
  } = useDialogInvitationPartnerCompanyContext();

  const [, loading, , executeValidatedInvitation] = useLazyRequest({
    request: validateInvitation,
    withPostSuccess: (response) => {
      const invitationData = response.data
        ?.data as PartnerCompanyInvitationData;

      setValidatedData(invitationData);
      onValidated?.(invitationData);
      setActiveTab('confirmation');
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err.status === 404) {
        errorMessage = 'Codigo de invitación invalido';
      }

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await executeValidatedInvitation(values[FIELDS_NAME.INVITATION_CODE]);

      setSubmitting(false);
    },
    [executeValidatedInvitation],
  );

  const {
    submitForm,
    isSubmitting,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setFieldValue,
    isValid,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (defaultInvitationCode)
      setFieldValue(FIELDS_NAME.INVITATION_CODE, defaultInvitationCode);
  }, [defaultInvitationCode, setFieldValue]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <DialogContent dividers>
        <Stack spacing={2} alignItems="center">
          <Typography component="p" variant="body2">
            Si posees un código de invitación para asociarte a una empresa,
            puedes ingresarlo aquí.
          </Typography>

          <Box
            component="img"
            src={toAbsoluteUrl('/icons/icon_supplychain.svg')}
            alt="Parnter Companies Icon"
            sx={{ width: 120 }}
          />

          <TextField
            label="Código de Invitación"
            fullWidth
            autoComplete="off"
            autoFocus
            error={hasError(touched, errors, FIELDS_NAME.INVITATION_CODE)}
            {...getFieldProps(FIELDS_NAME.INVITATION_CODE)}
            InputLabelProps={{ shrink: true }}
            disabled={!!defaultInvitationCode}
            sx={{
              maxWidth: 200,

              '& input': {
                fontSize: 24,
                fontWeight: 'bold',
              },
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Typography variant="caption" component="span">
          <Link
            href="https://routix.tawk.help/article/asociar-empresas"
            target="_blank"
            underline="hover"
            fontWeight="bold"
          >
            Haz clic aquí
          </Link>{' '}
          para tener ayuda.
        </Typography>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loading || isSubmitting}
          disabled={!dirty || !isValid}
        >
          Validar
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DialogInvitationPartnerCompanyValidated;

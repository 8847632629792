import { useState } from 'react';

import { SelectOption, DataPaginateResponse } from '~globals/types';
import { EnumerationData } from '~services/enumerations/types';
import { getEnumerationData } from '~services/enumerations';
import { useRequest } from './useRequest';

interface EnumerationParams {
  resource: string;
  extractorKeyValue?: string;
  extractorKeyLabel?: string;
}

export const useEnumerationData = ({
  resource,
  extractorKeyValue = 'id',
  extractorKeyLabel = 'description',
}: EnumerationParams): [SelectOption[], boolean] => {
  const [data, setData] = useState<SelectOption[]>([]);

  const [, loading] = useRequest({
    request: getEnumerationData,
    payload: resource,
    withPostSuccess: (response) => {
      const { data: responseData } =
        response.data as DataPaginateResponse<EnumerationData>;

      const parseData = responseData.results.map((item: EnumerationData) => ({
        value: item[extractorKeyValue],
        label: item[extractorKeyLabel],
      }));

      setData(parseData);
    },
  });

  return [data, loading];
};

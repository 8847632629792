export enum Countries {
  Argentina = 0,
  Brazil = 1,
  Uruguay = 2,
  Peru = 3,
  Chile = 4,
  Mexico = 5,
  Ecuador = 6,
  Paraguay = 7,
  Colombia = 8,
}

export enum IndustryType {
  AlimmentosYBebidas = 1,
  LogisticaCadenaSuministros = 2,
  Courier = 3,
  Retail = 4,
  TelecomunicacionesCable = 5,
  ServiciosDomicilio = 6,
  TransportePersonas = 7,
  Otra = 8,
}

export enum FleetSize {
  None = 0,
  OneToFive = 1,
  SixToFifteen = 2,
  SixteenToThirty = 3,
  ThirtyToHundred = 4,
  MoreThanHundred = 5,
}

export enum DriverCompanyStateTypes {
  Pending = 0,
  Approve = 1,
  Rejected = 2,
}

export enum PriorityStateTypes {
  Normal = 0,
  Urgent = 1,
}

export enum ItemTypes {
  Charge = 0,
  Discharge = 1,
  Task = 2,
}

export enum ItemImportStateTypes {
  New = 0,
  InProgress = 1,
  Successfully = 2,
  Error = 3,
}

export enum ItemTaskStatusType {
  Pending = 0,
  Success = 1,
  Error = 2,
}

export enum ItemCollectStatusTypes {
  Pending = 0,
  Collected = 1,
  NotCollected = 2,
}

export enum RouteStateTypes {
  New = 0,
  Assigned = 1,
  Approve = 2,
  Rejected = 3,
  InProgress = 4,
  Finalized = 5,
  Expired = 6,
}

export enum ApiTransactionStateTypes {
  New = 0,
  InProgress = 1,
  Finalized = 2,
  Error = 3,
}

export enum RouteItemStateType {
  New = 0,
  OnAgenda = 1,
  InProgress = 2,
  FinalizedSuccess = 3,
  FinalizedError = 4,
  WithoutVisiting = 5,
}

export enum RouteCalculationModeType {
  Faster = 0,
  Shorter = 1,
}

export enum NotificationAlertSeverity {
  Low = 0,
  Mid = 1,
  High = 2,
  Exception = 3,
}

export enum NotificationAlertType {
  Alert = 1,
  Incidence = 2,
  Info = 3,
  Success = 4,
}

export enum Roles {
  Admin = 0,
  Operator = 1,
  Drive = 2,
  Reader = 3,
}

export enum IntegrationProvidersTypes {
  MercadoLibre = 1,
  TiendaNube = 2,
  WooCommerce = 3,
}

export enum IntegrationDeliveryTypes {
  DropOff = 'drop_off',
  XdDropOff = 'xd_drop_off',
  CrossDocking = 'cross_docking',
  SelfService = 'self_service',
  Fulfillment = 'fulfillment',
  Custom = 'custom',
}

export enum IntegrationStatusTypes {
  New = 0,
  InProccess = 1,
  Completed = 2,
  Error = 3,
}

export enum PreferencesNotificationMailTypes {
  OnAgenda = 0,
  InProgress = 1,
  Completed = 2,
  Incompleted = 3,
}

export enum PreferencesNotificationWaTypes {
  OnAgenda = 0,
  InProgress = 1,
  Completed = 2,
  Incompleted = 3,
  OnTheWay = 4,
}

export enum SecurityDeliveryLevel {
  Low = 0,
  High = 1,
}

export enum UTCoffSet {
  GMT_MINUS_12 = '-12:00',
  GMT_MINUS_11 = '-11:00',
  GMT_MINUS_10 = '-10:00',
  GMT_MINUS_9_5 = '-09:30',
  GMT_MINUS_9 = '-09:00',
  GMT_MINUS_8 = '-08:00',
  GMT_MINUS_7 = '-07:00',
  GMT_MINUS_6 = '-06:00',
  GMT_MINUS_5 = '-05:00',
  GMT_MINUS_4_5 = '-04:30',
  GMT_MINUS_4 = '-04:00',
  GMT_MINUS_3_5 = '-03:30',
  GMT_MINUS_3 = '-03:00',
  GMT_MINUS_2 = '-02:00',
  GMT_MINUS_1 = '-01:00',
  GMT_0 = '00:00',
  GMT_PLUS_1 = '+01:00',
  GMT_PLUS_2 = '+02:00',
  GMT_PLUS_3 = '+03:00',
  GMT_PLUS_3_07 = '+03:07',
  GMT_PLUS_3_5 = '+03:30',
  GMT_PLUS_4 = '+04:00',
  GMT_PLUS_4_5 = '+04:30',
  GMT_PLUS_5 = '+05:00',
  GMT_PLUS_5_5 = '+05:30',
  GMT_PLUS_6 = '+06:00',
  GMT_PLUS_6_5 = '+06:30',
  GMT_PLUS_7 = '+07:00',
  GMT_PLUS_8 = '+08:00',
  GMT_PLUS_8_75 = '+08:45',
  GMT_PLUS_9 = '+09:00',
  GMT_PLUS_9_5 = '+09:30',
  GMT_PLUS_10 = '+10:00',
  GMT_PLUS_10_5 = '+10:30',
  GMT_PLUS_11 = '+11:00',
  GMT_PLUS_11_5 = '+11:30',
  GMT_PLUS_12 = '+12:00',
}

export enum PartnerCompanyStatusTypes {
  Pending = 0,
  Active = 1,
  Inactive = 2,
}

export enum PartnerCompanyTypes {
  Carrier = 0,
  Giver = 1,
}

export enum PreferencesTrackingComplaintChannelTypeId {
  None = 0,
  Email = 1,
  Whatsapp = 2,
}

export enum PartnerItemCarrierStatusTypes {
  OnRequest = 0,
  Pending = 1,
  Booked = 2,
  InProgress = 3,
  Completed = 4,
  Rejected = 5,
}

export enum PartnerItemGiverStatusTypes {
  Pending = 0,
  OnRequest = 1,
  Received = 2,
  Booked = 3,
  InProgress = 4,
  Completed = 5,
  Rejected = 6,
}

export enum ItemStateTypes {
  New = 0,
  ToValidate = 1,
  Validated = 2,
}

export enum CollectItemStateTypes {
  ToValidate = 0,
  ToCollect = 1,
  InCollect = 2,
  Validated = 3,
  NotValidated = 4,
}

export enum PlanTypes {
  Plan = 0,
  Pack = 1,
}

export enum PlanFeaturesTypes {
  MgmCrossCompanyModule = 'Mgm_CrossCompanyModule',
  MgmIntegrationModule = 'Mgm_IntegrationModule',
  MgmGenerateTickets = 'Mgm_GenerateTickets',
  MgmItemTask = 'Mgm_ItemTask',
  MgmSecurityTools = 'Mgm_SecurityTools',
  MgmExternalGPS = 'Mgm_ExternalGPS',
  MgmAPIAccess = 'Mgm_APIAccess',
  MgmWhiteLabel = 'Mgm_WhiteLabel',
  MgmItemMap = 'Mgm_ItemMap',
  MgmChangeOrder = 'Mgm_ChangeOrder',
  TrackingPortalModule = 'Tracking_PortalModule',
  TrackingEPOD = 'Tracking_EPOD',
  ReportAdvanced = 'Report_Advanced',
  ReportCustomSatisfaction = 'Report_CustomSatisfaction',
  ReportHeatmap = 'Report_Heatmap',
  ReportCrossCompany = 'Report_CrossCompany',
  NotifCustomSatisfaction = 'Notif_CustomSatisfaction',
  NotifEmail = 'Notif_Email',
  NotifWhatsapp = 'Notif_Whatsapp',
  NotifWhatappBusiness = 'Notif_WhatappBusiness',
  LimitNotPro = 'Limit_NotPro',
  LimitPaid = 'Limit_Paid',
}

export enum PlanPaymentTypes {
  Monthly = 'monthly',
  Annual = 'annual',
}

export enum TransactionStatusTypes {
  New = 0,
  Pending = 1,
  Approved = 2,
  Rejected = 3,
  Processed = 4,
}

export enum TransactionMoneyTypes {
  USD = 0,
  ARS = 1,
}

export enum CompanyPlanStatusTypes {
  Active = 0,
  Inactive = 1,
  Cancelled = 2,
}

export enum PaymentGatewayTypes {
  MERCADOPAGO = 0,
  PAYPAL = 1,
}

export enum PaymentFrecuencyTypes {
  SINGLE = 0,
  RECURRENT = 1,
}

export enum TaxTypes {
  Monotributist = 0,
  RegisteredResponsible = 1,
  Exempt = 2,
  FinalConsumer = 3,
}

export enum InvoiceTypes {
  A = 0,
  B = 1,
}

export enum CardTypes {
  Credit = 0,
  Debit = 1,
}

export enum BusinessProfileManagementOperationTypes {
  OnlySell = '1',
  NotSell = '2',
  SellAndManagement = '3',
}

export enum BusinessProfileOrderManagementCollectTypes {
  Collect = '1',
  NotCollect = '2',
}

export enum BusinessProfileOrderCollectedTransferTypes {
  ToBuyers = '1',
  ToWarehouse = '2',
  Both = '3',
}

export enum BusinessProfileOrderCentralizationTransferTypes {
  OnlyBuyers = '1',
  BuyersAndCarries = '2',
}

export enum BusinessProfileOrderCentralizationnShipmentTypes {
  Buyers = '1',
  BuyersAndCarries = '2',
}

export enum BusinessProfileOrderDistributionShipmentTypes {
  Buyers = '1',
  ThirdParty = '2',
  MyStore = '3',
}

export enum BusinessProfileTypes {
  SalesOnly = 1,
  SalesAndDirectDistribution = 2,
  SalesAndIndirectDistribution = 3,
  SalesAndDistributionBothDirectAndIndirect = 4,
  SalesDirectDistributionAndCollection = 5,
  SalesIndirectDistributionAndCollection = 6,
  SalesDirectAndIndirectDistributionAndCollection = 7,
  CollectionAndDirectDistribution = 8,
  CollectionAndIndirectDistribution = 9,
  CollectionIndirectDistributionAndOutsourcingOfSomeOrders = 10,
  CollectionDirectAndIndirectDistributionAndOutsourcingOfSomeOrders = 11,
  DirectDistributionWithoutCollection = 12,
  DirectDistributionWithoutCollectionAndOutsourcingOfSomeOrders = 13,
}

import { CSSProperties, ReactNode, useCallback } from 'react';
import { isFunction, get } from 'lodash';
import classNames from 'classnames';

import {
  TableClasses,
  TableColumn,
  TableValidRow,
  TableAlign,
  TableSelectableModel,
  TableSelectableGetClasses,
} from '../types';

interface UseTableBodyRowProps {
  className?: string;
  tabIndex?: number;
}

export interface UseTableBodyCellProps {
  className?: string;
  style?: CSSProperties;
  align?: TableAlign;
  children?: ReactNode;
}

interface UseTableBodyReturn {
  getRowProps: (row: TableValidRow, rowIndex: number) => UseTableBodyRowProps;
  getCellProps: (
    column: TableColumn<TableValidRow>,
    columnIndex: number,
    row: TableValidRow,
    rowIndex: number,
  ) => UseTableBodyCellProps;
}

export const useTableBody = (
  extractorKeyValue: string,
  nonSelectableIds: TableSelectableModel,
  classes?: TableClasses<TableValidRow>,
  nonSelectableClasses?: string | TableSelectableGetClasses<TableValidRow>,
): UseTableBodyReturn => {
  const getRowClasses = useCallback(
    (row: TableValidRow, rowIndex: number) => {
      const rowClasses = isFunction(classes?.bodyRow)
        ? classes?.bodyRow(row, rowIndex)
        : classes?.bodyRow;

      let rowNotSelectableClasses: string | undefined = undefined;

      if (nonSelectableClasses) {
        const isNotSelectable = (nonSelectableIds ?? [])?.includes(
          row[extractorKeyValue],
        );

        rowNotSelectableClasses = isFunction(nonSelectableClasses)
          ? nonSelectableClasses?.(row, rowIndex)
          : classNames({ [nonSelectableClasses]: isNotSelectable });
      }

      return classNames(rowClasses, rowNotSelectableClasses);
    },
    [classes, extractorKeyValue, nonSelectableClasses, nonSelectableIds],
  );

  const getRowProps = useCallback(
    (row: TableValidRow, rowIndex: number) => ({
      className: getRowClasses(row, rowIndex),
      tabIndex: -1,
    }),
    [getRowClasses],
  );

  const getCellCurrentValue = useCallback(
    (column: TableColumn<TableValidRow>, row: TableValidRow) =>
      column.isDummyField ? null : get(row, column.dataField),
    [],
  );

  const getCellClasses = useCallback(
    (
      column: TableColumn<TableValidRow>,
      columnIndex: number,
      row: TableValidRow,
      rowIndex: number,
    ) => {
      const currentValue = getCellCurrentValue(column, row);

      return isFunction(column.classes?.cell)
        ? column.classes?.cell(currentValue, row, rowIndex, columnIndex)
        : column.classes?.cell;
    },
    [getCellCurrentValue],
  );

  const getCellStyle = useCallback(
    (
      column: TableColumn<TableValidRow>,
      columnIndex: number,
      row: TableValidRow,
      rowIndex: number,
    ) => {
      const currentValue = getCellCurrentValue(column, row);

      return isFunction(column.style?.cell)
        ? column.style?.cell(currentValue, row, rowIndex, columnIndex)
        : column.style?.cell;
    },
    [getCellCurrentValue],
  );

  const getCellAlign = useCallback(
    (
      column: TableColumn<TableValidRow>,
      columnIndex: number,
      row: TableValidRow,
      rowIndex: number,
    ) => {
      const currentValue = getCellCurrentValue(column, row);

      return isFunction(column.align?.cell)
        ? column.align?.cell(currentValue, row, rowIndex, columnIndex)
        : column.align?.cell;
    },
    [getCellCurrentValue],
  );

  const getCellContent = useCallback(
    (
      column: TableColumn<TableValidRow>,
      row: TableValidRow,
      rowIndex: number,
    ) => {
      const currentValue = getCellCurrentValue(column, row);

      return column.formatters?.cell
        ? column.formatters?.cell(currentValue, row, rowIndex)
        : currentValue;
    },
    [getCellCurrentValue],
  );

  const getCellProps = useCallback(
    (
      column: TableColumn<TableValidRow>,
      columnIndex: number,
      row: TableValidRow,
      rowIndex: number,
    ) => ({
      className: getCellClasses(column, columnIndex, row, rowIndex),
      style: getCellStyle(column, columnIndex, row, rowIndex),
      align: getCellAlign(column, columnIndex, row, rowIndex),
      children: getCellContent(column, row, rowIndex),
    }),
    [getCellAlign, getCellClasses, getCellContent, getCellStyle],
  );

  return { getRowProps, getCellProps };
};

import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { removeAdminDriverCompany } from '~services/admin/driverCompany';

import { useLazyRequest } from './useLazyRequest';

export const useDriverCompanyUnlink = (
  onSuccessUnlink?: () => void,
): {
  loading: boolean;
  handleUnlinkDriver: (driverId: string) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingUnlinkDriver, , executeUnlinkDriver] = useLazyRequest({
    request: removeAdminDriverCompany,
    withPostSuccess: (response) => {
      const responseDriver = response.data?.data;
      const currentDriverName = `${responseDriver?.lastName}, ${responseDriver?.firstName}`;
      const message = `El chofer ${currentDriverName} fue desvinculado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      onSuccessUnlink?.();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo desvincular el chofer, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleUnlinkDriver = useCallback(
    async (driverId: string) => {
      await executeUnlinkDriver(driverId);
    },
    [executeUnlinkDriver],
  );

  return { loading: loadingUnlinkDriver, handleUnlinkDriver };
};

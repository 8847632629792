import { ReactElement, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { getItemByIds } from '~services/item';
import { useRequest } from '~hooks/index';
import { ItemExtended } from '~services/item/types';

import { DialogAssignCarrierCompanysProps } from './types';
import DialogAssignCarrierCompanyProvider, {
  useDialogAssignCarrierCompanyContext,
} from './DialogAssignCarrierCompanyContext';
import DialogAssignCarrierCompanyContent from './DialogAssignCarrierCompanyContent';
import {
  DialogAssignCarrierCompanyContainer,
  DialogAssignCarrierCompanyLoadingContainer,
} from './styles';

const DialogAssignCarrierCompany = (): ReactElement => {
  const {
    open,
    activesPartnerCompanies: { loading: loadingActivesPartnerCompanies },
    warehouses: { loading: loadingWarehouses },
    loadingOrderInfo,
  } = useDialogAssignCarrierCompanyContext();

  const loading = useMemo(
    () =>
      loadingActivesPartnerCompanies || loadingWarehouses || loadingOrderInfo,
    [loadingActivesPartnerCompanies, loadingWarehouses, loadingOrderInfo],
  );

  return (
    <DialogAssignCarrierCompanyContainer open={open}>
      {loading && (
        <DialogAssignCarrierCompanyLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogAssignCarrierCompanyLoadingContainer>
      )}

      {!loading && <DialogAssignCarrierCompanyContent />}
    </DialogAssignCarrierCompanyContainer>
  );
};

const WrappedDialogAssignCarrierCompany = ({
  open,
  onClose,
  orderIds,
  onSuccessAssign,
}: DialogAssignCarrierCompanysProps): ReactElement => {
  const [ordersInfo, setOrdersInfo] = useState<ItemExtended[]>([]);

  const [, loadingOrdersInfo] = useRequest({
    request: getItemByIds,
    payload: orderIds,
    withPostSuccess: (response) => {
      const currentOrderInfo = response.data?.data.results as ItemExtended[];

      setOrdersInfo(currentOrderInfo);
    },
  });

  return (
    <DialogAssignCarrierCompanyProvider
      open={open}
      onClose={onClose}
      orderIds={orderIds}
      onSuccessAssign={onSuccessAssign}
      orderInfo={ordersInfo}
      loadingOrderInfo={loadingOrdersInfo}
    >
      <DialogAssignCarrierCompany />
    </DialogAssignCarrierCompanyProvider>
  );
};

export default WrappedDialogAssignCarrierCompany;

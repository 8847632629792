import { Add as AddIcon } from '@mui/icons-material';

import { PATHS } from '~constants/index';
import { getStringifyUrlQueryString } from '~utils/router';

import { FirstStepsMenuStepInfo } from './types';

export const STEPS_INFO: FirstStepsMenuStepInfo[] = [
  {
    id: 'first',
    title: {
      carrier: 'Configura tu empresa',
      giver: 'Configura tu empresa',
    },
    description: {
      carrier:
        // eslint-disable-next-line max-len
        'Cargue en el sistema las unidades que transportarán los pedidos, los depósitos de carga/descarga y su equipo de conductores.',
      giver:
        // eslint-disable-next-line max-len
        'Cargue en el sistema los depósitos o puntos de colecta, y asocia empresas de logisticas o transportistas para despachar sus pedidos.',
    },
    actions: {
      carrier: [
        {
          id: 'add-vehicle',
          label: 'Vehículo',
          path: PATHS.CONFIG.VEHICLES.NEW,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
            startIcon: <AddIcon />,
          },
        },
        {
          id: 'add-deposit',
          label: 'Depósito',
          path: PATHS.CONFIG.DEPOSITS.NEW,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
            startIcon: <AddIcon />,
          },
        },
        {
          id: 'add-driver',
          label: 'Chofer',
          path: PATHS.CONFIG.DRIVERS.NEW,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
            startIcon: <AddIcon />,
          },
        },
      ],
      giver: [
        {
          id: 'add-deposit',
          label: 'Depósito',
          path: PATHS.CONFIG.DEPOSITS.NEW,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
            startIcon: <AddIcon />,
          },
        },
        {
          id: 'asociate-carrier',
          label: 'Asociar Transportista',
          path: getStringifyUrlQueryString({
            url: PATHS.CONFIG.PARTNERS_COMPANIES.BASE,
            query: {
              openAssociateModal: true,
            },
          }),
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
            startIcon: <AddIcon />,
          },
        },
      ],
    },
  },
  {
    id: 'second',
    title: {
      carrier: 'Importa tus pedidos',
      giver: 'Importa tus pedidos',
    },
    description: {
      carrier:
        // eslint-disable-next-line max-len
        'Puedes crear pedidos de forma manual o importar operaciones de forma masiva a través de un archivo o de alguna de nuestras integraciones.',
      giver:
        // eslint-disable-next-line max-len
        'Puedes crear pedidos de forma manual o importar operaciones de forma masiva a través de un archivo o de alguna de nuestras integraciones.',
    },
    actions: {
      carrier: [
        {
          id: 'add-order',
          label: 'Nuevo',
          path: PATHS.ORDERS.NEW,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
            startIcon: <AddIcon />,
          },
        },
        {
          id: 'import-file',
          label: 'Importar...',
          path: getStringifyUrlQueryString({
            url: PATHS.ORDERS.BASE,
            query: {
              currentTab: 'pending',
              openImporterFromFile: true,
            },
          }),
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
          },
        },
        {
          id: 'show-integrations',
          label: 'Ver integraciones...',
          path: PATHS.CONFIG.INTEGRATIONS.BASE,
          defaultButtonProps: {
            variant: 'outlined',
            color: 'primary',
            size: 'small',
          },
        },
      ],
      giver: [
        {
          id: 'add-order',
          label: 'Nuevo',
          path: PATHS.ORDERS.NEW,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
            startIcon: <AddIcon />,
          },
        },
        {
          id: 'import-file',
          label: 'Importar...',
          path: getStringifyUrlQueryString({
            url: PATHS.ORDERS.BASE,
            query: {
              currentTab: 'pending',
              openImporterFromFile: true,
            },
          }),
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
          },
        },
        {
          id: 'show-integrations',
          label: 'Ver integraciones...',
          path: PATHS.CONFIG.INTEGRATIONS.BASE,
          defaultButtonProps: {
            variant: 'outlined',
            color: 'primary',
            size: 'small',
          },
        },
      ],
    },
  },
  {
    id: 'third',
    title: {
      carrier: 'Genera rutas y supervisa tu actividad',
      giver: 'Supervisa tu actividad',
    },
    description: {
      carrier:
        // eslint-disable-next-line max-len
        'Rutea tus entregas con nuestro asistente de generación de rutas, sigue las visitas en tiempo real y obtiene métricas e indicadores de calidad.',
      giver:
        // eslint-disable-next-line max-len
        'Envía tus pedidos a tus empresas de logísticas o de transporte, sigue las visitas en tiempo real y obtiene métricas e indicadores de calidad',
    },
    actions: {
      carrier: [
        {
          id: 'my-routes',
          label: 'Mis rutas',
          path: PATHS.ROUTES.BASE,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
          },
        },
        {
          id: 'show-reports',
          label: 'Métricas y reportes',
          path: PATHS.REPORTS.BASE,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
          },
        },
      ],
      giver: [
        {
          id: 'my-orders',
          label: 'Mis pedidos',
          path: PATHS.ORDERS.BASE,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
          },
        },
        {
          id: 'show-reports',
          label: 'Métricas y reportes',
          path: PATHS.REPORTS.BASE,
          defaultButtonProps: {
            variant: 'contained',
            color: 'primary',
            size: 'small',
          },
        },
      ],
    },
  },
];

import { styled, ButtonGroup, buttonGroupClasses, Button } from '@mui/material';

export const SubscriptionPaymentTypeSelectorRoot = styled(ButtonGroup)(() => ({
  [`& .${buttonGroupClasses.grouped}`]: {
    minWidth: 140,
  },
}));

export const SubscriptionPaymentTypeSelectorButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  borderColor: '#E9E1F5',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px 15px',

  ...(selected && {
    backgroundColor: '#E9E1F5',

    '&:hover': {
      backgroundColor: '#E9E1F5',
    },
  }),

  '& b': {
    lineHeight: 0.5,
    fontSize: theme.typography.pxToRem(9),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
  },
}));

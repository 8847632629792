import { PlanPaymentTypes } from '~globals/types/enums';

const PAYMENT_TYPES_MULTIPLIER = {
  monthly: 1,
  annual: 12,
};

export const getPaymentTypeMultiplier = (
  paymentType: `${PlanPaymentTypes}`,
): number => PAYMENT_TYPES_MULTIPLIER[paymentType];

const PAYMENT_TYPES_TEXT = {
  monthly: 'Mensual',
  annual: 'Anual',
};

export const getPaymentTypeAclaration = (
  paymentType: `${PlanPaymentTypes}`,
): string => PAYMENT_TYPES_TEXT[paymentType];

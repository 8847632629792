import { ReactElement, useMemo } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { CheckCircleOutline as CheckIcon } from '@mui/icons-material';

import { APP_STORES_INFO } from '~constants/appStoresInfo';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import {
  InitialSetupWizardFinishModalStepContent,
  InitialSetupWizardFinishTutorialVideoLink,
  InitialSetupWizardFinishTutorialVideoImg,
  InitialSetupWizardFinishStoreImg,
  InitialSetupWizardFinishButton,
} from './styles';
import { getProgressValue } from '../../utils';

const InitialSetupWizardFinish = (): ReactElement => {
  const {
    handleClose,
    companyType: { isCarrier: isCarrierSelectedCompany },
  } = useInitialSetupWizardContext();

  const helpTopics = useMemo(
    () => [
      {
        description: 'Qué puedo hacer con Routix',
        link: 'https://routix.tawk.help/article/configurando-mi-empresa',
        hidden: false,
      },
      {
        description: 'Crear y organizar pedidos',
        link: 'https://routix.tawk.help/article/importar-y-organizar-pedidos',
        hidden: false,
      },
      {
        description: 'Generando mis primeras rutas',
        link: 'https://routix.tawk.help/article/generando-mis-primeras-rutas',
        hidden: !isCarrierSelectedCompany,
      },
      {
        description: 'Coordinando ruta con chofer',
        link: 'https://routix.tawk.help/article/asignar-ruta-a-un-chofer',
        hidden: !isCarrierSelectedCompany,
      },
      {
        description: 'Enviar pedidos a transportista',
        link: 'https://routix.tawk.help/article/gestionar-pedidos-de-otras-empresas',
        hidden: isCarrierSelectedCompany,
      },
      {
        description: 'Seguimiento del recorrido',
        link: 'https://routix.tawk.help/article/seguir-el-recorrido',
        hidden: false,
      },
    ],
    [isCarrierSelectedCompany],
  );

  return (
    <InitialSetupWizardStepContainer
      stepId="finish"
      minHeight={550}
      maxWidth={1200}
    >
      <StepTitleWithProgress
        progress={getProgressValue(true, 4)}
        title="Listo para cargar tus pedidos"
        description="Aprende cómo cargar pedidos en la plataforma"
      />

      <InitialSetupWizardFinishModalStepContent>
        <Stack direction="row" spacing={8} flex={1}>
          <InitialSetupWizardFinishTutorialVideoLink
            href="https://youtu.be/l6u3Y1gUm90"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InitialSetupWizardFinishTutorialVideoImg
              src={toAbsoluteUrl('/images/video_importer_ordes_pic.png')}
              alt="Import my orders"
            />
          </InitialSetupWizardFinishTutorialVideoLink>

          <Stack spacing={2}>
            <Typography component="h5" variant="h6" fontWeight="bold">
              Otros temas de ayuda...
            </Typography>

            {helpTopics.map((topic, index) => (
              <Stack
                key={`help-topic-${index}`}
                direction="row"
                spacing={1}
                alignItems="center"
                component={Link}
                href={topic.link}
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                sx={{
                  display: topic.hidden ? 'none' : 'flex',
                }}
              >
                <CheckIcon color="success" />

                <Typography
                  component="p"
                  variant="body1"
                  color="text.secondary"
                >
                  {topic.description}
                </Typography>
              </Stack>
            ))}

            {isCarrierSelectedCompany && (
              <Stack spacing={1}>
                <Typography
                  component="p"
                  variant="caption"
                  color="text.secondary"
                >
                  Descarga la app para choferes
                </Typography>

                <Stack direction="row" spacing={1}>
                  <Link
                    href={APP_STORES_INFO.PLAY_STORE.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InitialSetupWizardFinishStoreImg
                      src={APP_STORES_INFO.PLAY_STORE.IMAGE_URL}
                      alt={APP_STORES_INFO.PLAY_STORE.NAME}
                    />
                  </Link>

                  <Link
                    href={APP_STORES_INFO.APP_STORE.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InitialSetupWizardFinishStoreImg
                      src={APP_STORES_INFO.APP_STORE.IMAGE_URL}
                      alt={APP_STORES_INFO.APP_STORE.NAME}
                    />
                  </Link>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>

        <InitialSetupWizardFinishButton
          variant="contained"
          color="primary"
          onClick={handleClose}
          fullWidth
        >
          Comenzar
        </InitialSetupWizardFinishButton>
      </InitialSetupWizardFinishModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardFinish;

import API from '~config/api';
import {
  ApiPromise,
  PaginationRequestParams,
  DataPaginateResponse,
  DataResponse,
  ErrorResponse,
} from '~globals/types';

import {
  AdminUser,
  AdminUserCreateBody,
  AdminUserFilter,
  AdminUserUpdateBody,
  AdminUserNewPassword,
} from './types';

export const ADMIN_USERS_ENPOINTS = {
  createAdminUser: '/api/v1/Admin/User',
  getAdminUserById: (userId: string): string => `/api/v1/Admin/User/${userId}`,
  searchAdminUsers: '/api/v1/Admin/User/search',
  updateAdminUser: '/api/v1/Admin/User',
  changePasswordAdminUser: '/api/v1/Admin/User/ChangeUserPassword',
  deleteAdminUser: (userId: string): string => `/api/v1/Admin/User/${userId}`,
  changeAdminUserOwner: '/api/v1/Admin/User/ChangeOwner',
};

export const createAdminUser = (
  body: AdminUserCreateBody,
): ApiPromise<DataResponse<AdminUser>, ErrorResponse> =>
  API.post<DataResponse<AdminUser>, ErrorResponse>(
    ADMIN_USERS_ENPOINTS.createAdminUser,
    body,
  );

export const getAdminUserById = (
  userId: string,
): ApiPromise<DataResponse<AdminUser>, ErrorResponse> =>
  API.get<DataResponse<AdminUser>, ErrorResponse>(
    ADMIN_USERS_ENPOINTS.getAdminUserById(userId),
  );

export const searchAdminUsers = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<AdminUserFilter>): ApiPromise<
  DataPaginateResponse<AdminUser[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<AdminUser[]>, ErrorResponse>(
    ADMIN_USERS_ENPOINTS.searchAdminUsers,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const updateAdminUser = (
  body: AdminUserUpdateBody,
): ApiPromise<DataResponse<AdminUser>, ErrorResponse> =>
  API.put<DataResponse<AdminUser>, ErrorResponse>(
    ADMIN_USERS_ENPOINTS.updateAdminUser,
    body,
  );

export const changePasswordAdminUser = (
  body: AdminUserNewPassword,
): ApiPromise<DataResponse<AdminUser>, ErrorResponse> =>
  API.put<DataResponse<AdminUser>, ErrorResponse>(
    ADMIN_USERS_ENPOINTS.changePasswordAdminUser,
    body,
  );

export const deleteAdminUser = (
  userId: string,
): ApiPromise<DataResponse<AdminUser>, ErrorResponse> =>
  API.delete<DataResponse<AdminUser>, ErrorResponse>(
    ADMIN_USERS_ENPOINTS.deleteAdminUser(userId),
  );

export const changeAdminUserOwner = (
  userId: string,
): ApiPromise<DataResponse<AdminUser>, ErrorResponse> =>
  API.put<DataResponse<AdminUser>, ErrorResponse>(
    ADMIN_USERS_ENPOINTS.changeAdminUserOwner,
    null,
    { params: { userId } },
  );

import { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLocationPin as LocationIcon,
  faUserClock as UserTimeIcon,
  faStopwatch as IntervalIcon,
  faTag as TagIcon,
} from '@fortawesome/free-solid-svg-icons';

import { useUtcOffset } from '~hooks/index';

import TimeWindowInfo from '../TimeWindowInfo';

import { StopsPopupMapProps } from './types';
import {
  StopsPopupMapContainer,
  StopsPopupMapTitle,
  StopsPopupMapRowItem,
  StopsPopupMapIconContainer,
} from './styles';

const StopsPopupMap = ({
  title,
  address,
  estimatedArrivalDateTime,
  timeWindow,
  trackingCode,
}: StopsPopupMapProps): ReactElement => {
  const formatterDateUtcOffset = useUtcOffset();

  return (
    <StopsPopupMapContainer>
      <StopsPopupMapTitle>{title}</StopsPopupMapTitle>

      <StopsPopupMapRowItem>
        <StopsPopupMapIconContainer>
          <FontAwesomeIcon icon={LocationIcon} />
        </StopsPopupMapIconContainer>

        <Typography variant="inherit">{address}</Typography>
      </StopsPopupMapRowItem>

      {estimatedArrivalDateTime && (
        <StopsPopupMapRowItem>
          <StopsPopupMapIconContainer>
            <FontAwesomeIcon icon={UserTimeIcon} />
          </StopsPopupMapIconContainer>

          <Typography variant="inherit">
            {formatterDateUtcOffset(estimatedArrivalDateTime, 'HH:mm')}
          </Typography>
        </StopsPopupMapRowItem>
      )}

      {timeWindow && (
        <StopsPopupMapRowItem>
          <StopsPopupMapIconContainer>
            <FontAwesomeIcon icon={IntervalIcon} />
          </StopsPopupMapIconContainer>

          <TimeWindowInfo {...timeWindow} />
        </StopsPopupMapRowItem>
      )}

      {trackingCode && (
        <StopsPopupMapRowItem>
          <StopsPopupMapIconContainer>
            <FontAwesomeIcon icon={TagIcon} />
          </StopsPopupMapIconContainer>

          <Typography variant="inherit">{trackingCode}</Typography>
        </StopsPopupMapRowItem>
      )}
    </StopsPopupMapContainer>
  );
};

export default StopsPopupMap;

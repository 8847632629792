import {
  ReactElement,
  ChangeEvent,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

import { DialogConfirmDeleteRouteProps } from './types';
import DialogConfimation from '../DialogConfimation';

const initialRemoveItems = false;

const warningText = `Esta acción eliminará de forma permanente la ruta, su progreso,
  historial y todos los registros de visita. Los pedidos relacionados
  volverán a estado pendiente en caso de no seleccionar la siguiente
  casilla`;

const DialogConfirmDeleteRoute = ({
  open,
  onConfirm,
  onCancel,
  loadingConfirm = false,
  routeCode,
}: DialogConfirmDeleteRouteProps): ReactElement => {
  const description = useMemo(
    () => `La ruta #${routeCode?.toUpperCase()} será eliminada`,
    [routeCode],
  );

  const [removeItems, setRemoveItems] = useState(initialRemoveItems);

  const handleChangeRemoveItems = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRemoveItems(event.target.checked);
    },
    [],
  );

  const handleConfirm = useCallback(() => {
    onConfirm(removeItems);
  }, [onConfirm, removeItems]);

  useEffect(() => {
    if (!open) {
      setRemoveItems(initialRemoveItems);
    }
  }, [open]);

  return (
    <DialogConfimation
      open={open}
      description={description}
      warningText={warningText}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      loadingConfirm={loadingConfirm}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={removeItems}
            onChange={handleChangeRemoveItems}
            size="small"
          />
        }
        label="Eliminar pedidos relacionados"
      />
    </DialogConfimation>
  );
};

export default DialogConfirmDeleteRoute;

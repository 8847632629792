import { ReactElement, ElementType, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TabContext } from '@mui/lab';

import { DialogInvitationPartnerCompanyProps } from './types';
import DialogInvitationPartnerCompanyContexProvider, {
  useDialogInvitationPartnerCompanyContext,
  DialogInvitationPartnerCompanyContextPanelsId,
} from './DialogInvitationPartnerCompanyContext';
import {
  DialogInvitationPartnerCompanyValidated,
  DialogInvitationPartnerCompanyConfirmation,
} from './Tabs';
import {
  DialogInvitationPartnerCompanyContainer,
  DialogInvitationPartnerCompanyTitle,
  DialogInvitationPartnerCompanyTitleCloseIcon,
  DialogInvitationPartnerCompanyTabPanel,
} from './styles';

interface DialogInvitationPartnerCompanyTabPanelItem {
  id: DialogInvitationPartnerCompanyContextPanelsId;
  panel: ElementType;
}

const TABS_PANELS: DialogInvitationPartnerCompanyTabPanelItem[] = [
  {
    id: 'validated',
    panel: DialogInvitationPartnerCompanyValidated,
  },
  { id: 'confirmation', panel: DialogInvitationPartnerCompanyConfirmation },
];

const DialogInvitationPartnerCompany = (): ReactElement => {
  const { open, onClose, activeTab, setActiveTab, setValidatedData } =
    useDialogInvitationPartnerCompanyContext();

  useEffect(() => {
    if (!open) {
      setActiveTab('validated');
      setValidatedData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogInvitationPartnerCompanyContainer open={open} maxWidth="md">
      <DialogInvitationPartnerCompanyTitle>
        <Typography component="h2" variant="h6">
          Aceptar invitación de empresa
        </Typography>

        <DialogInvitationPartnerCompanyTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogInvitationPartnerCompanyTitleCloseIcon>
      </DialogInvitationPartnerCompanyTitle>

      <TabContext value={activeTab}>
        {TABS_PANELS.map(({ id, panel: Panel }) => (
          <DialogInvitationPartnerCompanyTabPanel
            key={`dialog-invitation-partner-company-panel-${id}`}
            value={id}
          >
            <Panel />
          </DialogInvitationPartnerCompanyTabPanel>
        ))}
      </TabContext>
    </DialogInvitationPartnerCompanyContainer>
  );
};

const WrapperDialogInvitationPartnerCompany = ({
  open,
  onClose,
  onValidated,
  onConfirmation,
  companyName,
  defaultInvitationCode,
  onOpenDialogAssociateCompany,
}: DialogInvitationPartnerCompanyProps): ReactElement => (
  <DialogInvitationPartnerCompanyContexProvider
    open={open}
    onClose={onClose}
    onValidated={onValidated}
    onConfirmation={onConfirmation}
    companyName={companyName}
    defaultInvitationCode={defaultInvitationCode}
    onOpenDialogAssociateCompany={onOpenDialogAssociateCompany}
  >
    <DialogInvitationPartnerCompany />
  </DialogInvitationPartnerCompanyContexProvider>
);

export default WrapperDialogInvitationPartnerCompany;

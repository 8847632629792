import { useMemo } from 'react';

import { VehicleSearchItem } from '~services/vehicle/types';
import { ItemExtended } from '~services/item/types';

export interface UseGenerateRoutesChargeIndicatorsDataParams {
  selectedVehicles: VehicleSearchItem[];
  selectedOrders: ItemExtended[];
}

export interface UseGenerateRoutesChargeIndicatorsDataReturn {
  vehicles: { capacity1: number; capacity2: number; maximumVisits: number };
  orders: { capacity1: number; capacity2: number; numberOfPackages: number };
}

export const useGenerateRoutesChargeIndicatorsData = ({
  selectedVehicles,
  selectedOrders,
}: UseGenerateRoutesChargeIndicatorsDataParams): UseGenerateRoutesChargeIndicatorsDataReturn => {
  const vehiclesCharge = useMemo(
    () =>
      selectedVehicles.reduce(
        (acc, selectedVehicle) => {
          acc.capacity1 += selectedVehicle.capacity1;
          acc.capacity2 += selectedVehicle.capacity2;
          acc.maximumVisits += selectedVehicle.maximumVisits;

          return acc;
        },
        { capacity1: 0, capacity2: 0, maximumVisits: 0 },
      ),
    [selectedVehicles],
  );

  const ordersCharge = useMemo(
    () =>
      selectedOrders.reduce(
        (acc, selectedOrder) => {
          acc.capacity1 += selectedOrder.unit1 * selectedOrder.amount;
          acc.capacity2 += selectedOrder.unit2 * selectedOrder.amount;
          acc.numberOfPackages += selectedOrder.numberOfPackages;

          return acc;
        },
        { capacity1: 0, capacity2: 0, numberOfPackages: 0 },
      ),
    [selectedOrders],
  );

  return { vehicles: vehiclesCharge, orders: ordersCharge };
};

import { ReactElement } from 'react';
import { Typography, Stack, Button } from '@mui/material';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { getInitialsNameLetterUpperCase } from '~utils/user';

import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import { InitialSetupWizardWelcomeAvatar } from './styles';

const InitialSetupWizardWelcome = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);
  const {
    step: { handleNextStep },
  } = useInitialSetupWizardContext();

  return (
    <InitialSetupWizardStepContainer
      stepId="welcome"
      minHeight={550}
      maxWidth={600}
    >
      <InitialSetupWizardModalStepContent gutterLeft={false}>
        <Stack spacing={1} alignItems="center" justifyContent="center" flex={1}>
          <InitialSetupWizardWelcomeAvatar
            alt={user?.company.name}
            src={user?.company.logoFile?.url}
          >
            {getInitialsNameLetterUpperCase(user?.company.name as string)}
          </InitialSetupWizardWelcomeAvatar>

          <Typography component="h4" variant="h5" fontWeight="bold">
            {user?.company.name}
          </Typography>

          <Typography component="p" variant="body1" color="text.secondary">
            ¡Vamos a terminar de configurar tu cuenta!
          </Typography>
        </Stack>

        <Stack spacing={2} alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNextStep('businessProfile')}
            sx={{ width: 280 }}
          >
            Siguiente
          </Button>
        </Stack>
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardWelcome;

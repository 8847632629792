import { useCallback } from 'react';
import { isFunction } from 'lodash';

import { TableColumn, TableValidRow } from '../types';
import { UseTableBodyCellProps } from '../Body/useTableBody';

interface UseTableHeaderReturn {
  getCellProps: (
    column: TableColumn<TableValidRow>,
    columnIndex: number,
  ) => UseTableBodyCellProps;
}

export const useTableHeader = (): UseTableHeaderReturn => {
  const getCellClasses = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) =>
      isFunction(column.classes?.header)
        ? column.classes?.header(column, columnIndex)
        : column.classes?.header,
    [],
  );

  const getCellStyle = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) =>
      isFunction(column.style?.header)
        ? column.style?.header(column, columnIndex)
        : column.style?.header,
    [],
  );

  const getCellAlign = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) =>
      isFunction(column.align?.header)
        ? column.align?.header(column, columnIndex)
        : column.align?.header,
    [],
  );

  const getCellContent = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) =>
      column.formatters?.header
        ? column.formatters?.header(column, columnIndex)
        : column.text,
    [],
  );

  const getCellProps = useCallback(
    (column: TableColumn<TableValidRow>, columnIndex: number) => ({
      className: getCellClasses(column, columnIndex),
      style: getCellStyle(column, columnIndex),
      align: getCellAlign(column, columnIndex),
      children: getCellContent(column, columnIndex),
    }),
    [getCellAlign, getCellClasses, getCellContent, getCellStyle],
  );

  return { getCellProps };
};

import { ReactElement, memo } from 'react';

import { useRouteStateInfo } from '~hooks/index';

import { RouteStateChipProps } from './types';
import { RouteStateChipRoot } from './styles';

const RouteStateChip = ({
  stateId,
  size = 'medium',
}: RouteStateChipProps): ReactElement => {
  const { description, color } = useRouteStateInfo(stateId);

  return (
    <RouteStateChipRoot
      variant="filled"
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(RouteStateChip);

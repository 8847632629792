import {
  styled,
  Dialog,
  dialogClasses,
  DialogActions,
  Chip,
  chipClasses,
} from '@mui/material';

export const DialogOrdersMultipeEditionContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 900,
    width: '100%',
    minHeight: 655,
  },
});

export const DialogOrdersMultipeEditionLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogOrdersMultipeEditionActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const DialogOrdersMultipeEditionChip = styled(Chip)(({ theme }) => ({
  [`& .${chipClasses.label}`]: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 80,
  },
  [`& .${chipClasses.icon}`]: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

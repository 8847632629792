import { default as SubscriptionAvailableRoutesStateAlert } from './Alert';
import { default as SubscriptionAvailableRoutesStateBadge } from './Badge';
import { default as SubscriptionAvailableRoutesStateCircularProgress } from './CircularProgress';
import { default as SubscriptionAvailableRoutesStateIcon } from './Icon';
import { default as SubscriptionAvailableRoutesStateInfo } from './Info';

export const AvailableRoutesStateComponents = {
  Alert: SubscriptionAvailableRoutesStateAlert,
  Badge: SubscriptionAvailableRoutesStateBadge,
  CircularProgress: SubscriptionAvailableRoutesStateCircularProgress,
  Icon: SubscriptionAvailableRoutesStateIcon,
  Info: SubscriptionAvailableRoutesStateInfo,
};

import { useMemo } from 'react';
import moment from 'moment';

import { useSubscription } from './useSubscription';
import { useTypePartnerCompany } from './useTypePartnerCompany';

export const useSubscriptionDataRetentionMinDate = (
  defaultMinDate?: Date,
): Date | undefined => {
  const subscription = useSubscription();
  const { isGiver } = useTypePartnerCompany();

  const retentionMinDate = useMemo(
    () =>
      moment(
        subscription?.dataRetentionMinDate,
        moment.HTML5_FMT.DATETIME_LOCAL,
      ).toDate(),
    [subscription?.dataRetentionMinDate],
  );

  const minDate = useMemo(() => {
    const isAfterDefaultMinDate =
      defaultMinDate && moment(defaultMinDate).isAfter(retentionMinDate, 'day');

    if (isGiver || isAfterDefaultMinDate) return defaultMinDate;

    return retentionMinDate;
  }, [defaultMinDate, retentionMinDate, isGiver]);

  return minDate;
};

import {
  createTheme,
  lighten,
  darken,
  SimplePaletteColorOptions,
  getContrastRatio,
  alpha,
} from '@mui/material/styles';
import {
  PaletteTonalOffset,
  light,
  dark,
} from '@mui/material/styles/createPalette';
import { grey, common } from '@mui/material/colors';
import {
  switchClasses,
  outlinedInputClasses,
  autocompleteClasses,
  inputBaseClasses,
  inputAdornmentClasses,
  tableCellClasses,
} from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import { esES } from '@mui/material/locale';

import {
  CustomTheme,
  CustomThemePalette,
  CustomThemePaletteOptions,
} from '~globals/types';
import {
  showDriver,
  showTrip,
  modeSatelite,
  modeFreeHand,
} from '~constants/mapCustomControlIcons';
// eslint-disable-next-line max-len
import { classes as classesDescriptionWithIconBox } from '~components/DescriptionWithIconBox/styles';

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ThemeOptions extends CustomTheme {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomThemePalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomThemePaletteOptions {}
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    subscribe: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    tertiary: true;
    subscribe: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    tertiary: true;
    subscribe: true;
  }
}

const tonalOffset: PaletteTonalOffset = 0.2;
const contrastThreshold = 3;

const createAugmentColor = ({
  mainColor,
  tonalOffset: tonalOffsetParam,
  contrastText: contrastTextParam,
  contrastThreshold: contrastThresholdParam,
}: {
  mainColor: string;
  tonalOffset: PaletteTonalOffset;
  contrastThreshold: number;
  contrastText?: string;
}): SimplePaletteColorOptions => {
  let tonalOffsetLight = 0;
  let tonalOffsetDark = 0;
  let contrastText = contrastTextParam;

  if (typeof tonalOffsetParam === 'number') {
    tonalOffsetLight = tonalOffsetParam;
    tonalOffsetDark = tonalOffsetParam * 1.5;
  }

  if (typeof tonalOffsetParam === 'object') {
    tonalOffsetLight = tonalOffsetParam.light;
    tonalOffsetDark = tonalOffsetParam.dark;
  }

  if (!contrastText) {
    contrastText =
      getContrastRatio(mainColor, dark.text.primary) >= contrastThresholdParam
        ? dark.text.primary
        : light.text.primary;
  }

  return {
    main: mainColor,
    light: lighten(mainColor, tonalOffsetLight),
    dark: darken(mainColor, tonalOffsetDark * 1.5),
    contrastText,
  };
};

const theme = createTheme(
  {
    palette: {
      tonalOffset,
      contrastThreshold,
      // Principals
      primary: { main: '#5618b6', contrastText: common.white },
      secondary: { main: '#455a64', contrastText: common.white },
      success: { main: '#1DB45A', contrastText: common.white },
      warning: { main: '#DEA500', contrastText: common.white },
      error: { main: '#EB0014', contrastText: common.white },
      background: { default: '#e1e5ec' },
      // Customs
      tertiary: createAugmentColor({
        mainColor: '#b1b8bf',
        tonalOffset,
        contrastText: common.white,
        contrastThreshold,
      }),
      subscribe: createAugmentColor({
        mainColor: '#ff6d00',
        tonalOffset,
        contrastText: common.white,
        contrastThreshold,
      }),
    },
    typography: {
      fontFamily: [
        '"Montserrat"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif',
      ].join(','),
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '::-webkit-scrollbar': {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            position: 'absolute',
            height: '0.475em',
            width: '0.375em',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#646464',
            borderRadius: '0.313em',
          },
          legend: {
            maxWidth: 0,
          },
          label: {
            marginBottom: 0,
          },
          a: {
            '&:hover': {
              color: 'inherit',
              textDecoration: 'none',
            },
          },
          table: {
            fontSize: 14,
          },
          th: {
            color: '#455a64',
            fontWeight: 'bold !important',
            backgroundColor: '#fff !important',

            '&[data-row-selection]': {
              width: '48px !important',
            },
          },
          td: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            borderBottom: 'none !important',

            '&.truncated-two-lines span': {
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box',
              whiteSpace: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },

            [`&.truncated-two-lines .${classesDescriptionWithIconBox.descriptionText}`]:
              {
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                display: '-webkit-box',
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
          },
          'th, td': {
            verticalAlign: 'middle !important',

            '&.truncated-two-lines': {
              verticalAlign: 'baseline !important',
            },
          },
          '.SnackbarContent-root': {
            '@media (min-width: 600px)': {
              minWidth: '320px !important',
              maxWidth: '320px !important',
            },
          },
          '.SnackbarItem-message': {
            flex: 1,
          },
          '.mapboxgl-ctrl button:not(:disabled):hover': {
            backgroundColor: 'rgb(177 184 191 / 50%)',
          },
          '.mapbox-gl-draw_ctrl-draw-btn.active, .mapbox-gl-draw_ctrl-draw-btn.active:hover':
            {
              backgroundColor: 'rgb(177 184 191 / 50%)',
            },
          '.mapbox-gl-driver': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 20,
            pointerEvents: 'auto',
            backgroundImage: `url(${showDriver})`,
          },
          '.mapbox-gl-trip': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 20,
            pointerEvents: 'auto',
            backgroundImage: `url(${showTrip})`,
          },
          '.mapbox-gl-sat': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 20,
            pointerEvents: 'auto',
            backgroundImage: `url(${modeSatelite})`,
          },
          '.mapbox-gl-free-hand': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 15,
            pointerEvents: 'auto',
            backgroundImage: `url(${modeFreeHand})`,
          },
          '.mapboxgl-popup': {
            zIndex: 1,
          },
          '.mapboxgl-popup-tip': {
            position: 'relative',

            '&:before': {
              content: '""',
              backgroundColor: '#fff',
              width: 20,
              height: 3,
              position: 'absolute',
            },
          },
          '.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip:before': {
            bottom: 9,
            left: -10,
          },
          '.mapboxgl-popup-anchor-top .mapboxgl-popup-tip:before': {
            top: 9,
            left: -10,
          },
          '.mapboxgl-popup-anchor-right .mapboxgl-popup-tip:before': {
            left: -19,
          },
          '.mapboxgl-popup-content': {
            paddingTop: 14,
            paddingBottom: 10,
            borderRadius: 10,
          },
          '.mapboxgl-popup-close-button': {
            fontSize: 15,
            top: 2,
            borderRadius: 10,
            right: 2,

            '&:focus-visible': {
              outline: 'none',
            },
          },
          '.table-row-disabled': {
            color: `${grey[400]} !important`,
          },
          '.color-picker-button button': {
            borderRadius: '50%',

            '& > div': {
              borderRadius: '50%',
            },

            '&:hover': {
              backgroundColor: 'inherit',
            },

            '&:focus': {
              boxShadow: 'none',
            },
          },
          '.muicc-palette-button': {
            '&:hover': {
              backgroundColor: 'inherit !important',
            },
          },
          '.pincode-input-text': {
            width: '40px !important',
            height: '40px !important',
            borderRadius: '8px !important',
            borderColor: 'rgba(0, 0, 0, 0.23) !important',

            '&:focus': {
              borderColor: 'rgba(0, 0, 0, 0.87) !important',
              borderWidth: '2px !important',
            },

            '&:hover': {
              borderColor: 'rgba(0, 0, 0, 0.87) !important',
            },
          },
          '#walletBrick_container': {
            minWidth: 280,
          },
        },
      },
      MuiAppBar: {
        defaultProps: {
          color: 'tertiary',
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'medium',
          variant: 'outlined',
          disableElevation: true,
        },
        styleOverrides: {
          root: ({ ownerState, theme: currentTheme }) => ({
            textTransform: 'none',

            ...(ownerState.color &&
              ownerState.color !== 'inherit' && {
                '&.pulse': {
                  '&:before, &:after': {
                    content: '""',
                    boxShadow: `0 0 0 0 ${
                      currentTheme.palette[ownerState.color].light
                    }`,
                    opacity: 0.7,
                    animation: 'pulseKeyFrames 1.3s infinite',
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    borderRadius: 'inherit',
                  },

                  '&:after': {
                    animationDelay: '0.3s',
                  },
                },

                '@keyframes pulseKeyFrames': {
                  '100%': {
                    boxShadow: `0 0 0 10px currentColor`,
                    opacity: 0,
                  },
                },
              }),
          }),
          text: ({ ownerState, theme: currentTheme }) => ({
            ...(ownerState.color &&
              ownerState.color !== 'inherit' && {
                'a&:hover': {
                  color: currentTheme.palette[ownerState.color].main,
                },
              }),
          }),
          outlined: ({ ownerState, theme: currentTheme }) => ({
            ...(ownerState.color &&
              ownerState.color !== 'inherit' && {
                'a&:hover': {
                  color: currentTheme.palette[ownerState.color].main,
                },
              }),
          }),
          contained: ({ ownerState, theme: currentTheme }) => ({
            ...(ownerState.color &&
              ownerState.color !== 'inherit' && {
                'a&:hover': {
                  color: currentTheme.palette[ownerState.color].contrastText,
                },
              }),
          }),
        },
      },
      MuiLoadingButton: {
        defaultProps: {
          size: 'medium',
          variant: 'outlined',
          disableElevation: true,
        },
      },
      MuiTextField: {
        defaultProps: {
          size: 'small',
          variant: 'outlined',
          color: 'secondary',
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
          },
          outlined: ({ ownerState }) => ({
            zIndex: 1,
            pointerEvents: 'none',
            transform: 'translate(12px, 16px) scale(1)',
            maxWidth: 'calc(100% - 24px)',

            ...(ownerState.size === 'small' && {
              transform: 'translate(12px, 13px) scale(1)',
            }),

            ...(ownerState.shrink && {
              userSelect: 'none',
              pointerEvents: 'auto',
              transform: 'translate(12px, 7px) scale(0.75)',
              maxWidth: 'calc(133% - 24px)',

              ...(ownerState.size === 'small' && {
                transform: 'translate(12px, 4px) scale(0.75)',
              }),
            }),
          }),
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ theme: currentTheme, ownerState }) => ({
            fontSize: currentTheme.typography.pxToRem(14),

            ...(ownerState?.multiline && {
              paddingTop: 25,
              paddingRight: 12,
              paddingBottom: 8,
              paddingLeft: 12,

              ...(ownerState?.size === 'small' && {
                paddingTop: 21,
                paddingBottom: 4,
              }),
            }),
          }),
          notchedOutline: {
            '& legend': {
              width: 0,
            },
          },
          input: ({ ownerState }) => ({
            paddingTop: 25,
            paddingRight: 12,
            paddingBottom: 8,
            paddingLeft: 12,

            ...(ownerState?.size === 'small' && {
              paddingTop: 21,
              paddingBottom: 4,
            }),

            ...(ownerState?.multiline && {
              padding: 0,
            }),

            '&[type="password"]': {
              font: 'small-caption',
              fontSize: 'inherit',
            },
          }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            [`& .${autocompleteClasses.inputRoot}`]: {
              padding: 0,

              [`&.${autocompleteClasses.hasPopupIcon}&, .${autocompleteClasses.clearIndicator}&`]:
                {
                  paddingRight: 0,
                },
            },
            [`& .${outlinedInputClasses.root}`]: {
              padding: 0,
              paddingTop: 25,
              paddingRight: 12,
              paddingBottom: 8,
              paddingLeft: 12,

              [`&.${inputBaseClasses.sizeSmall}`]: {
                paddingTop: 21,
                paddingRight: 12,
                paddingBottom: 4,
                paddingLeft: 12,
                gap: 3,

                [`& .${autocompleteClasses.input}`]: {
                  padding: 0,
                },
              },

              [`&.${autocompleteClasses.hasPopupIcon}&, .${autocompleteClasses.clearIndicator}&`]:
                {
                  paddingRight: 0,
                },

              [`& .${autocompleteClasses.input}`]: {
                padding: 0,
              },

              [`& .${autocompleteClasses.tag}`]: {
                margin: 0,
                height: 20,
              },

              [`& .${inputAdornmentClasses.positionEnd}`]: {
                position: 'absolute',
                top: 'calc(50% - 9px)',
                right: 9,
              },
            },
          },
          input: ({ ownerState }) => ({
            paddingTop: 25,
            paddingRight: 12,
            paddingBottom: 8,
            paddingLeft: 12,

            ...(ownerState?.size === 'small' && {
              paddingTop: 21,
              paddingBottom: 4,
            }),
          }),
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.underline === 'always' && {
              '&:hover': {
                textDecoration: 'underline',
              },
            }),

            ...(ownerState.underline === 'hover' && {
              '&:hover': {
                textDecoration: 'underline',
              },
            }),
          }),
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1, 2),
          }),
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1, 2),
          }),
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1, 2),
          }),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            borderRadius: currentTheme.shape.borderRadius,
          }),
          sizeSmall: ({ theme: currentTheme }) => ({
            height: 22,
            fontSize: currentTheme.typography.pxToRem(10),
          }),
          labelSmall: ({ theme: currentTheme }) => ({
            paddingLeft: currentTheme.spacing(1),
            paddingRight: currentTheme.spacing(1),
          }),
          sizeMedium: ({ theme: currentTheme }) => ({
            height: 26,
            fontSize: currentTheme.typography.pxToRem(13),
          }),
          labelMedium: ({ theme: currentTheme }) => ({
            paddingLeft: currentTheme.spacing(1.5),
            paddingRight: currentTheme.spacing(1.5),
          }),
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            width: 54,
            height: 34,
            padding: 7,
          },
          switchBase: ({ theme: currentTheme }) => ({
            margin: 1,
            padding: 0,
            top: 4,
            transform: 'translateX(6px)',
            transitionDuration: '300ms',

            [`&.${switchClasses.checked}`]: {
              transform: 'translateX(22px)',
              color: currentTheme.palette.common.white,

              [`& + .${switchClasses.track}`]: {
                opacity: 1,
                backgroundColor: currentTheme.palette.grey['200'],
              },

              [`& .${switchClasses.thumb}`]: {
                backgroundColor: currentTheme.palette.success.main,
              },

              [`&.${switchClasses.disabled} .${switchClasses.thumb}`]: {
                backgroundColor: lighten(
                  currentTheme.palette.success.main,
                  0.62,
                ),
              },
            },

            [`&.${switchClasses.disabled}`]: {
              [`+ .${switchClasses.track}`]: {
                opacity: 0.12,
                backgroundColor: currentTheme.palette.common.black,
              },

              [`& .${switchClasses.thumb}`]: {
                backgroundColor: currentTheme.palette.grey['100'],
              },
            },
          }),
          thumb: ({ theme: currentTheme }) => ({
            backgroundColor: currentTheme.palette.tertiary.main,
            width: 24,
            height: 24,
            transition: currentTheme.transitions.create('background-color', {
              duration: 500,
            }),
          }),
          track: ({ theme: currentTheme }) => ({
            opacity: 1,
            backgroundColor: currentTheme.palette.grey['200'],
            borderRadius: 20 / 2,
            boxSizing: 'border-box',
          }),
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            padding: currentTheme.spacing(1.5, 3),
          }),
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            backgroundColor: currentTheme.palette.common.white,
          }),
          colorDefault: ({ theme: currentTheme }) => ({
            backgroundColor: currentTheme.palette.grey[400],
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 3,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            fontWeight: currentTheme.typography.fontWeightBold,
          }),
          labelIcon: ({ theme: currentTheme }) => ({
            minHeight: currentTheme.spacing(6),
          }),
        },
      },
      MuiFormControlLabel: {
        defaultProps: {
          componentsProps: {
            typography: { variant: 'body2' },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({ theme: currentTheme }) => ({
            borderBottom: `1px solid ${lighten(
              alpha(currentTheme.palette.divider, 1),
              0.88,
            )} !important`,
            padding: 10,

            '&.truncated-two-lines': {
              verticalAlign: 'inherit !important',
            },
          }),
          head: {
            borderWidth: '2px !important',
          },
          paddingCheckbox: {
            padding: 0,

            [`& + .${tableCellClasses.root}`]: {
              paddingLeft: 0,
            },
          },
        },
      },
    },
    maxWidths: {
      lateralMenu: 56,
      lateralMenuExpanded: 190,
    },
  },
  esES,
);

export default theme;

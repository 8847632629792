import {
  styled,
  Dialog,
  dialogClasses,
  IconButton,
  DialogContent,
} from '@mui/material';

export const DialogCancelPartnerItemsContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 600,
    width: '100%',
  },
});

export const DialogCancelPartnerItemsTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogCancelPartnerItemsTitleCloseIcon = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.grey[500],
  }),
);

export const DialogCancelPartnerItemsContent = styled(DialogContent)(
  ({ theme }) => ({
    paddingBottom: theme.spacing(4),
  }),
);

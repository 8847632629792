import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
} from '@mui/material';

export const AccessFeatureModalContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: 750,
  },
});

export const AccessFeatureModalHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError: boolean }>(({ theme, isError }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  padding: theme.spacing(2, 3),
  flex: '0 0 auto',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  color: '#00e5ff',

  ...(isError && {
    backgroundColor: '#EB0014',
    color: theme.palette.common.white,
  }),
}));

export const AccessFeatureModalContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const AccessFeatureModalActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

import { ReactElement } from 'react';
import { Stack, DialogContentText, Link } from '@mui/material';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { DialogImportOrdersFromIntegrationLabelImg } from '../../styles';

const DialogImportOrdersFromIntegrationNotIntegrations = (): ReactElement => (
  <Stack spacing={2} flex={1} justifyContent="space-between">
    <DialogContentText color="text.primary">
      No se encontraron integraciones activas
    </DialogContentText>

    <Stack spacing={2} direction="row" justifyContent="center">
      <DialogImportOrdersFromIntegrationLabelImg
        src={toAbsoluteUrl('/images/not_import_int.png')}
        alt="Not import integrations"
      />
    </Stack>

    <DialogContentText variant="caption">
      Para mayor información sobre integraciones, diríjase a este{' '}
      <Link
        href="https://routix.tawk.help/article/integraciones-con-otras-plataformas"
        target="_blank"
        rel="noopener noreferrer"
        color="primary"
        underline="hover"
        fontWeight="bold"
      >
        artículo
      </Link>
    </DialogContentText>
  </Stack>
);

export default DialogImportOrdersFromIntegrationNotIntegrations;

import { ReactElement, useCallback, useMemo } from 'react';
import { Typography, Stack, Button, Card, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  faMagnifyingGlass as SearchIcon,
  faEraser as ClearIcon,
} from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import { ColumnDescription } from 'react-bootstrap-table-next';
import moment from 'moment';
import { isNull, chain } from 'lodash';
import classNames from 'classnames';

import {
  OrderClientInfo,
  DataTable,
  ExpirationDateInfo,
  CapabilitiesInfo,
  OrderPendingStateChip,
  Subscription,
} from '~components/index';
import {
  useFiltersValues,
  useDataTableRequest,
  useLazyRequest,
  useSelectedRows,
  useAppSelector,
} from '~hooks/index';
import { selectAuth } from '~store/slices/auth';
import { searchItems } from '~services/item';
import { addRouteItemsInRoute } from '~services/route';
import {
  ItemExtended,
  ItemFilter,
  ItemsWithGeoErrorsHead,
} from '~services/item/types';
import { DataPaginateResponse } from '~globals/types';
import { RouteItemStateType } from '~globals/types/enums';
import { getItemValue, formatterDate } from '~utils/index';
import { sortOrderPendingByExpirationDate } from '~utils/order';
import { isCurrentLoggedCompany } from '~utils/partnerCompany';

import { DialogSearchOrdersPendingProps } from './types';
import {
  DialogSearchOrdersPendingContainer,
  DialogSearchOrdersPendingTitle,
  DialogSearchOrdersPendingContent,
  DialogSearchOrdersPendingDataTableContainer,
  DialogSearchOrdersPendinActions,
  DialogSearchOrdersPendinTextFieldLarge,
  DialogSearchOrdersPendinTextFieldNormal,
  DialogSearchOrdersPendinIconButton,
  DialogSearchOrdersPendinIconButtonIcon,
} from './styles';

const defaultSizePerPage = 50;

const initialFilters: ItemFilter = {
  dateFrom: null,
  dateTo: null,
  driverId: null,
  routeCode: null,
  routeItemStateTypesId: [RouteItemStateType.New],
  maxDeliveredDateTime: null,
};

const keyFieldOrder = 'itemId';

const DialogSearchOrdersPending = ({
  open,
  onClose,
  routeId,
  onCompleteAddOrder,
}: DialogSearchOrdersPendingProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAppSelector(selectAuth);

  const { filters, handleChangeInputFilter, setFilterByKey, clearFilters } =
    useFiltersValues(initialFilters);

  const {
    data: orders,
    loading: loadingSearchOrders,
    totalSize,
    queryParams: { pagination },
    handleChangeTable,
    handleInitialTable,
  } = useDataTableRequest({
    request: searchItems,
    payload: {
      pagination: {
        page: 1,
        sizePerPage: defaultSizePerPage,
      },
      filters: initialFilters,
    },
    transformResponse: (res) => {
      const response = res.data as DataPaginateResponse<
        ItemExtended[],
        null,
        ItemsWithGeoErrorsHead
      >;

      if (response.data.results) {
        sortOrderPendingByExpirationDate(response.data.results);
      }

      return response;
    },
  });

  const handleApplyFilter = useCallback(() => {
    const parseFilters: ItemFilter = {
      ...filters,
      routeCode: getItemValue(filters, 'routeCode', (item) => item, null),
      maxDeliveredDateTime: getItemValue(
        filters,
        'maxDeliveredDateTime',
        (item) =>
          formatterDate(item, {
            format: moment.HTML5_FMT.DATETIME_LOCAL_MS,
          }),
      ),
    };

    handleChangeTable('filter', { pagination, filters: parseFilters });
  }, [handleChangeTable, pagination, filters]);

  const handleClearFilters = useCallback(() => {
    clearFilters();
    handleInitialTable();
  }, [clearFilters, handleInitialTable]);

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFilterByKey(fieldName, value);
      },
    [setFilterByKey],
  );

  const columns = useMemo<ColumnDescription<ItemExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        formatter: (_cell, row) => (
          <OrderClientInfo
            name={row.title}
            itemTypeId={row.itemTypeId}
            trackingCode={row.trackingCode}
          />
        ),
      },
      {
        dataField: 'referenceId',
        text: 'Nro. de Orden',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 170 },
      },
      {
        dataField: 'tags',
        text: 'Grupo',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 170 },
      },
      {
        dataField: 'carrierCompanyName',
        text: 'Empresa',
        classes: 'truncated-two-lines',
        formatter: (cell) => <span>{cell}</span>,
        headerStyle: { width: 150 },
      },
      {
        dataField: 'maxDeliveryDateTime',
        text: 'Fecha de vencimiento',
        classes: 'truncated-two-lines',
        formatter: (cell) => <ExpirationDateInfo expirationDate={cell} />,
        headerStyle: { width: 150 },
      },
      {
        dataField: 'collectItemStateTypeId',
        isDummyField: true,
        text: 'Estado',
        classes: 'truncated-two-lines',
        formatter: (_cell, row) => (
          <OrderPendingStateChip
            carrierCompanyId={row.carrierCompanyId}
            collectItemStateTypeId={row.collectItemStateTypeId}
          />
        ),
        headerStyle: { width: 160 },
        headerAlign: 'center',
        align: 'center',
      },
      {
        dataField: 'capacity1', // change
        text: 'Capacidades',
        formatter: (_cell, row) => (
          <CapabilitiesInfo
            capacity1={row.unit1 * row.amount}
            capacity2={row.unit2 * row.amount}
            numberOfPackages={row.numberOfPackages}
            maximumVisits={null}
            fontSize={12}
            fontWeight="bold"
          />
        ),
        headerStyle: { width: 120 },
      },
    ],
    [],
  );

  const getDisabledOrder = useCallback(
    (row: ItemExtended) =>
      !isNull(row.carrierCompanyId) &&
      !isCurrentLoggedCompany(row.carrierCompanyId, user?.companyId ?? null),
    [user],
  );

  const getDisabledOrderClasses = useCallback(
    (row: ItemExtended): string =>
      classNames({ 'table-row-disabled': getDisabledOrder(row) }),
    [getDisabledOrder],
  );

  const nonSelectablesOrdersIds = useMemo(
    () => chain(orders).filter(getDisabledOrder).map(keyFieldOrder).value(),
    [orders, getDisabledOrder],
  );

  const { selectedRowsIds, selectRowsProps, restarSelectedRows } =
    useSelectedRows<ItemExtended>(
      keyFieldOrder,
      [],
      [],
      nonSelectablesOrdersIds,
    );

  const [, loadingAddOrders, , executeAddOrders] = useLazyRequest({
    request: addRouteItemsInRoute,
    withPostSuccess: () => {
      onCompleteAddOrder?.();
      restarSelectedRows();

      enqueueSnackbar('Las paradas fueron agregadas correctamente de la ruta', {
        variant: 'success',
      });

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar(
        'No se pudo agregar las paradas de la ruta, intente nuevamente',
        { variant: 'error' },
      );
    },
  });

  const handleAddOrders = useCallback(async () => {
    await executeAddOrders({
      routeId,
      itemIds: selectedRowsIds as string[],
    });
  }, [executeAddOrders, selectedRowsIds, routeId]);

  return (
    <DialogSearchOrdersPendingContainer open={open}>
      <DialogSearchOrdersPendingTitle>
        <Typography component="h2" variant="h6">
          Buscar pedidos pendientes
        </Typography>
      </DialogSearchOrdersPendingTitle>

      <DialogSearchOrdersPendingContent dividers>
        <Stack spacing={2}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <DialogSearchOrdersPendinTextFieldLarge
              label="Nro. Orden / Nro. Tracking / Grupo"
              name="routeCode"
              value={filters.routeCode ?? ''}
              onChange={handleChangeInputFilter}
            />

            <Subscription.DataRetentionMin.DatePicker
              label="Fecha de vencimiento"
              value={
                filters.maxDeliveredDateTime
                  ? new Date(filters.maxDeliveredDateTime)
                  : null
              }
              onChange={handleChangeDate('maxDeliveredDateTime')}
              renderInput={(props) => (
                <DialogSearchOrdersPendinTextFieldNormal {...props} />
              )}
            />

            <Tooltip title="Buscar..." arrow>
              <DialogSearchOrdersPendinIconButton
                color="secondary"
                variant="contained"
                onClick={handleApplyFilter}
              >
                <DialogSearchOrdersPendinIconButtonIcon icon={SearchIcon} />
              </DialogSearchOrdersPendinIconButton>
            </Tooltip>

            <Tooltip title="Borrar filtros" arrow>
              <DialogSearchOrdersPendinIconButton
                color="primary"
                variant="contained"
                onClick={handleClearFilters}
              >
                <DialogSearchOrdersPendinIconButtonIcon icon={ClearIcon} />
              </DialogSearchOrdersPendinIconButton>
            </Tooltip>
          </Stack>

          <Stack>
            <Card variant="outlined" sx={{ display: 'grid' }}>
              <DialogSearchOrdersPendingDataTableContainer>
                <DataTable
                  loading={loadingSearchOrders}
                  data={orders}
                  columns={columns}
                  keyField={keyFieldOrder}
                  pagination={{
                    options: {
                      sizePerPage: pagination.sizePerPage,
                      page: pagination.page,
                      totalSize,
                      showTotal: false,
                      hideSizePerPage: true,
                    },
                  }}
                  remote={{
                    pagination: true,
                  }}
                  onTableChange={(type, newState) =>
                    handleChangeTable(type, { pagination: newState })
                  }
                  selectRow={selectRowsProps}
                  rowClasses={getDisabledOrderClasses}
                />
              </DialogSearchOrdersPendingDataTableContainer>
            </Card>
          </Stack>
        </Stack>
      </DialogSearchOrdersPendingContent>

      <DialogSearchOrdersPendinActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleAddOrders}
          loading={loadingAddOrders}
          disabled={selectedRowsIds.length === 0}
        >
          Agregar
        </LoadingButton>
      </DialogSearchOrdersPendinActions>
    </DialogSearchOrdersPendingContainer>
  );
};

export default DialogSearchOrdersPending;

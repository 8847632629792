import { styled, Box, LinearProgress, Typography } from '@mui/material';

const heightProgress = 10;
const marginTopProgress = 12;

export const ImporterProgressContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const ImporterProgressProgressContainer = styled(Box)({
  marginTop: marginTopProgress,
  position: 'relative',
});

export const ImporterProgressLinearProgress = styled(LinearProgress)(
  ({ theme }) => ({
    width: '100%',
    height: heightProgress,
    borderRadius: theme.shape.borderRadius,
  }),
);

export const ImporterProgressLinearProgressText = styled(Typography)({
  position: 'absolute',
  right: 0,
  bottom: marginTopProgress + heightProgress,
});

import { useMemo } from 'react';

import { useSubscription } from '~hooks/index';
import {
  AvailablesRoutes,
  useAvailablesRoutes,
} from '~hooks/useAvailablesRoutes';

interface SubscriptionRoutes extends AvailablesRoutes {
  currentRoutes: number;
  maxRoutes: number;
}

export const useSubscriptionRoutes = (): SubscriptionRoutes => {
  const subscription = useSubscription();

  const currentRoutes = useMemo(
    () => Number(subscription?.currentRoutes),
    [subscription?.currentRoutes],
  );

  const maxRoutes = useMemo(
    () => Number(subscription?.maxRoutes),
    [subscription?.maxRoutes],
  );

  const availablesRoutes = useAvailablesRoutes({ currentRoutes, maxRoutes });

  return {
    currentRoutes,
    maxRoutes,
    ...availablesRoutes,
  };
};

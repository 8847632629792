import { ReactElement, useCallback, useMemo } from 'react';
import { IconButton, Tooltip, Button, CircularProgress } from '@mui/material';
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserClock as AssignDriverIcon,
  faTag as TagIcon,
  faCalendar as CalendarIcon,
  faBarcode as BardCodeIcon,
} from '@fortawesome/free-solid-svg-icons';

import {
  VehicleInfo,
  StopsProgressInfo,
  DistanceInfo,
  RouteStateChip,
  MenuActionsDataTable,
  DescriptionWithIconBox,
  UserInfo,
} from '~components/index';
import { replaceParamsPath } from '~utils/router';
import { PATHS } from '~constants/index';
import {
  isEnabledToAssginedDriver,
  isRouteEnabledToDelete,
  isRouteStopOrderComplete,
  isRouteEnabledToExportTickets,
  isRouteEnabldeToUpdate,
} from '~utils/route';
import { isOnlyReadUser } from '~utils/user';
import { getFeatureAvailableToDate, getLimitPaidVersion } from '~utils/feature';
import {
  useAppSelector,
  useToggle,
  useUtcOffset,
  useExportTickets,
} from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { PlanFeaturesTypes } from '~globals/types/enums';

import { RouteCardProps } from './types';
import {
  RouteCardContainer,
  RouteCardContent,
  RouteCardVehicleInfoBox,
  RouteCardVehicleInfoBoxTitleContainer,
  RouteCardRouteInfoBox,
  RouteCardRouteInfoBoxRow,
} from './styles';

const RouteCard = ({
  route,
  isRouteCollect = false,
  handleToggleShowRoute,
  handleOpenDialogAssignedDriver,
  handleOpenDialogUpdateRoute,
  handleOpenConfirmDeleteRoute,
}: RouteCardProps): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  const formatterDateUtcOffset = useUtcOffset();

  const isUserOnlyReader = useMemo(() => isOnlyReadUser(user), [user]);

  const isEnabledToAssignedDriver = useMemo(
    () =>
      !isUserOnlyReader && isEnabledToAssginedDriver(route.routeStateTypeId),
    [isUserOnlyReader, route.routeStateTypeId],
  );

  const isEnabledToDeleteRoute = useMemo(
    () =>
      !isUserOnlyReader && isRouteEnabledToDelete(user, route.routeStateTypeId),
    [isUserOnlyReader, user, route.routeStateTypeId],
  );

  const isRouteEnabledToExportTicketsRoute = useMemo(
    () =>
      isRouteEnabledToExportTickets(route.routeStateTypeId) && !isRouteCollect,
    [route.routeStateTypeId, isRouteCollect],
  );

  const isEnabledToUpdateRoute = useMemo(
    () => !isUserOnlyReader && isRouteEnabldeToUpdate(route.routeStateTypeId),
    [isUserOnlyReader, route.routeStateTypeId],
  );

  const fullNameDriver = useMemo((): string => {
    const driver = route.driver;

    if (!driver) {
      return 'Sin Asignar';
    }

    return `${driver.lastName}, ${driver.firstName}`;
  }, [route.driver]);

  const stopsProgressData = useMemo(() => {
    return route.routeItems.reduce(
      (acc, routeItem) => {
        if (isRouteStopOrderComplete(routeItem.routeItemStateTypeId))
          acc.completed += 1;

        acc.total += 1;

        return acc;
      },
      { total: 0, completed: 0 },
    );
  }, [route.routeItems]);

  const [showRouteInMap, toggleRouteInMap] = useToggle(true);

  const handleToggleRouteInMap = useCallback(() => {
    handleToggleShowRoute(!showRouteInMap, route.routeId);
    toggleRouteInMap();
  }, [handleToggleShowRoute, showRouteInMap, route.routeId, toggleRouteInMap]);

  const { loading: loadingExportTickets, onExportTickets } = useExportTickets();

  const handleExportTickets = useCallback(async () => {
    await onExportTickets({ routeId: route.routeId });
  }, [onExportTickets, route.routeId]);

  return (
    <RouteCardContainer variant="outlined">
      <RouteCardContent>
        <RouteCardVehicleInfoBox>
          <RouteCardVehicleInfoBoxTitleContainer>
            <VehicleInfo
              vehicle={route.vehicle.name}
              fontSize={14}
              color={route.vehicle.color}
            />
          </RouteCardVehicleInfoBoxTitleContainer>

          <RouteStateChip stateId={route.routeStateTypeId} size="small" />

          <Tooltip title={`${showRouteInMap ? 'Ocultar' : 'Mostrar'} en mapa`}>
            <IconButton onClick={handleToggleRouteInMap} size="small">
              {showRouteInMap ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </Tooltip>

          <MenuActionsDataTable
            resource={{
              path: replaceParamsPath(PATHS.ROUTES.VEHICLE_PLANNING, {
                ':apiTransactionId': route.apiTransactionId,
                ':routeId': route.routeId,
              }),
            }}
            items={{
              show: {
                label: 'Ver ruta',
                divider:
                  isRouteEnabledToExportTicketsRoute ||
                  isEnabledToAssignedDriver ||
                  isEnabledToDeleteRoute,
              },
            }}
            extraItems={[
              {
                enabled: isRouteEnabledToExportTicketsRoute,
                label: 'Exportar etiquetas',
                Icon: loadingExportTickets ? (
                  <CircularProgress color="primary" disableShrink size={20} />
                ) : (
                  <FontAwesomeIcon icon={BardCodeIcon} />
                ),
                onClick: handleExportTickets,
                divider: isEnabledToAssignedDriver || isEnabledToDeleteRoute,
                validSubscriptionFeature: {
                  validFeature: PlanFeaturesTypes.MgmGenerateTickets,
                  availableToDate: getFeatureAvailableToDate(
                    PlanFeaturesTypes.MgmGenerateTickets,
                  ),
                },
              },
              {
                enabled: isEnabledToAssignedDriver,
                label: 'Asignar chofer',
                Icon: <FontAwesomeIcon icon={AssignDriverIcon} />,
                onClick: handleOpenDialogAssignedDriver,
                divider: isEnabledToAssignedDriver,
              },
              {
                enabled: isEnabledToUpdateRoute,
                label: 'Modificar asignación',
                Icon: <EditIcon color="info" />,
                onClick: handleOpenDialogUpdateRoute,
                divider: true,
                validSubscriptionFeature: {
                  validFeature: PlanFeaturesTypes.LimitPaid,
                  availableToDate: getLimitPaidVersion('1.18.2'),
                },
              },
              {
                enabled: isEnabledToDeleteRoute,
                label: 'Eliminar',
                Icon: <DeleteIcon color="error" />,
                onClick: handleOpenConfirmDeleteRoute,
              },
            ]}
            optionsButton={{ size: 'small' }}
          />
        </RouteCardVehicleInfoBox>

        <RouteCardRouteInfoBox>
          <RouteCardRouteInfoBoxRow>
            <DescriptionWithIconBox
              icon={<FontAwesomeIcon icon={TagIcon} />}
              description={`#${route.routeCode.toUpperCase()}`}
              isDescriptionLink
              descriptionHref={replaceParamsPath(
                PATHS.ROUTES.VEHICLE_PLANNING,
                {
                  ':apiTransactionId': route.apiTransactionId,
                  ':routeId': route.routeId,
                },
              )}
              size={11}
              fontWeight={500}
            />

            <DistanceInfo distance={route.totalKm} fontSize={11} />

            <DescriptionWithIconBox
              icon={<FontAwesomeIcon icon={CalendarIcon} />}
              description={formatterDateUtcOffset(
                route.scheduledDateTime,
                'DD/MM/YYYY HH:mm',
              )}
              size={11}
            />
          </RouteCardRouteInfoBoxRow>

          <RouteCardRouteInfoBoxRow justifyContent="space-between">
            {isEnabledToAssignedDriver ? (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                className="pulse"
                sx={{
                  fontSize: (theme) => theme.typography.pxToRem(10),
                  padding: '0 6px',
                }}
                startIcon={
                  <FontAwesomeIcon
                    icon={AssignDriverIcon}
                    style={{ fontSize: 10 }}
                  />
                }
                onClick={handleOpenDialogAssignedDriver}
              >
                Asignar Chofer
              </Button>
            ) : (
              <UserInfo
                name={fullNameDriver}
                urlAvatar={route.driver?.avatarFile?.url}
                fontSize={11}
              />
            )}

            <StopsProgressInfo
              max={stopsProgressData.total}
              value={stopsProgressData.completed}
              enabledIcon
              fontSize={11}
            />
          </RouteCardRouteInfoBoxRow>
        </RouteCardRouteInfoBox>
      </RouteCardContent>
    </RouteCardContainer>
  );
};

export default RouteCard;

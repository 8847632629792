import { ReactElement } from 'react';
import { Card, Typography } from '@mui/material';

import { StatisticsProgress } from '~components/index';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { FleetStatisticsWidgetProps } from './types';
import {
  FleetStatisticsWidgetCardContent,
  FleetStatisticsContentStatistics,
  FleetStatisticsItemWithImageContainer,
  FleetStatisticsItemWithImageContainerImg,
} from './styles';

const parseLegendPercentage = (val: number, prefix: string): string =>
  `${val}${prefix}`;

const FleetStatisticsWidget = ({
  avgLoadByVehicle,
  estimatedFuelConsumed,
  estimatedCo2Emission,
}: FleetStatisticsWidgetProps): ReactElement => (
  <Card>
    <FleetStatisticsWidgetCardContent>
      <Typography variant="h6" fontWeight="bold">
        Flota
      </Typography>

      <FleetStatisticsContentStatistics>
        <StatisticsProgress
          max={100}
          val={avgLoadByVehicle}
          size="medium"
          themeColor="primary"
          label="CARGA USADA"
          parseLegend={(val) => parseLegendPercentage(val, '%')}
        />

        <FleetStatisticsItemWithImageContainer>
          <FleetStatisticsItemWithImageContainerImg
            src={toAbsoluteUrl('/icons/icon_fuel.svg')}
            alt="Fuel Icon"
          />

          <Typography component="h6" variant="body2" fontWeight="bold">
            {parseLegendPercentage(estimatedFuelConsumed, ' L')}
          </Typography>
        </FleetStatisticsItemWithImageContainer>

        <FleetStatisticsItemWithImageContainer>
          <FleetStatisticsItemWithImageContainerImg
            src={toAbsoluteUrl('/icons/icon_co2.svg')}
            alt="CO2 Emission Icon"
          />

          <Typography component="h6" variant="body2" fontWeight="bold">
            {parseLegendPercentage(estimatedCo2Emission, ' KG')}
          </Typography>
        </FleetStatisticsItemWithImageContainer>
      </FleetStatisticsContentStatistics>
    </FleetStatisticsWidgetCardContent>
  </Card>
);

export default FleetStatisticsWidget;

import { FC, createContext, useContext } from 'react';

import {
  ToggleDialogConfirmationOnOpen,
  useToggleDialogConfirmation,
} from '~hooks/useToggleDialogConfirmation';
import { Nullable } from '~globals/types';
import { Subscription } from '~components/index';
import { SubscriptionPaymentModalData } from '~components/Subscription/PaymentModal/types';

interface SubscriptionPaymentModalContextValue {
  open: boolean;
  data: Nullable<SubscriptionPaymentModalData>;
  handleOpen: ToggleDialogConfirmationOnOpen<SubscriptionPaymentModalData>;
  handleClose: () => void;
}

export const SubscriptionPaymentModalContext =
  createContext<SubscriptionPaymentModalContextValue>({
    open: false,
    data: null,
    handleOpen: () => {},
    handleClose: () => {},
  });

export const useSubscriptionPaymentModalContext =
  (): SubscriptionPaymentModalContextValue =>
    useContext(SubscriptionPaymentModalContext);

const SubscriptionPaymentModalProvider: FC = ({ children }) => {
  const [open, data, handleOpen, handleClose] =
    useToggleDialogConfirmation<Nullable<SubscriptionPaymentModalData>>(null);

  return (
    <SubscriptionPaymentModalContext.Provider
      value={{ open, data, handleOpen, handleClose }}
    >
      {children}

      {data && (
        <Subscription.PaymentModal
          open={open}
          data={data}
          onClose={() => handleClose()}
        />
      )}
    </SubscriptionPaymentModalContext.Provider>
  );
};

export default SubscriptionPaymentModalProvider;

import { FC, createContext, useContext, useState, useCallback } from 'react';

export type BusinessProfileContextPanelsId =
  | 'operationType'
  | 'orderManagementCollect'
  | 'orderCollectedTransfer'
  | 'orderCentralizationTransfer'
  | 'orderCentralizationShipment'
  | 'orderDistributionShipment'
  | 'descriptionConfirm';

interface BusinessProfileContextValue {
  activeTab: BusinessProfileContextPanelsId;
  handleNextTab: (nextTabId: BusinessProfileContextPanelsId) => void;
  handleGoBack: (currentTabId: BusinessProfileContextPanelsId) => void;
}

const BusinessProfileContext = createContext<BusinessProfileContextValue>({
  activeTab: 'operationType',
  handleNextTab: () => {},
  handleGoBack: () => {},
});

export const useBusinessProfileContext = (): BusinessProfileContextValue =>
  useContext(BusinessProfileContext);

const BusinessProfileProvider: FC = ({ children }) => {
  const [activeTab, setActiveTab] =
    useState<BusinessProfileContextPanelsId>('operationType');
  const [previousActivesTabs, setPreviousActivesTabs] = useState<
    Record<
      BusinessProfileContextPanelsId,
      BusinessProfileContextPanelsId | undefined
    >
  >({
    operationType: undefined,
    orderManagementCollect: undefined,
    orderCollectedTransfer: undefined,
    orderCentralizationTransfer: undefined,
    orderCentralizationShipment: undefined,
    orderDistributionShipment: undefined,
    descriptionConfirm: undefined,
  });

  const handleNextTab = useCallback(
    (nextTabId: BusinessProfileContextPanelsId) => {
      setPreviousActivesTabs((prevPreviousActivesTabs) => ({
        ...prevPreviousActivesTabs,
        [nextTabId]: activeTab,
      }));

      setActiveTab(nextTabId);
    },
    [activeTab],
  );

  const handleGoBack = useCallback(
    (currentTabId: BusinessProfileContextPanelsId) => {
      const currentPreviousActivesTabs = previousActivesTabs[currentTabId];

      if (currentPreviousActivesTabs) {
        setPreviousActivesTabs((prevPreviousActivesTabs) => ({
          ...prevPreviousActivesTabs,
          [currentTabId]: undefined,
        }));

        setActiveTab(currentPreviousActivesTabs);
      }
    },
    [previousActivesTabs],
  );

  return (
    <BusinessProfileContext.Provider
      value={{ activeTab, handleNextTab, handleGoBack }}
    >
      {children}
    </BusinessProfileContext.Provider>
  );
};

export default BusinessProfileProvider;

import API from '~config/api';
import {
  ApiPromise,
  ErrorResponse,
  DataPaginateResponse,
  DataResponse,
  PaginationRequestParams,
} from '~globals/types';

import {
  VehicleBase,
  VehicleSearchItem,
  VehicleType,
  VehicleFilter,
} from './types';

export const VEHICLE_ENPOINTS = {
  getVehicles: '/api/v1/Route/Vehicle',
  searchVehicles: '/api/v1/Route/Vehicle/search',
  getVehicleTypes: '/api/v1/Enumeration/vehicleTypes',
};

export const getVehicles = (): ApiPromise<
  DataResponse<VehicleBase[]>,
  ErrorResponse
> =>
  API.get<DataResponse<VehicleBase[]>, ErrorResponse>(
    VEHICLE_ENPOINTS.getVehicles,
  );

export const searchVehicles = ({
  pagination: { page, sizePerPage },
  filters,
}: PaginationRequestParams<VehicleFilter>): ApiPromise<
  DataPaginateResponse<VehicleSearchItem[]>,
  ErrorResponse
> =>
  API.post<DataPaginateResponse<VehicleSearchItem[]>, ErrorResponse>(
    VEHICLE_ENPOINTS.searchVehicles,
    { ...filters, pagination: { offset: page, size: sizePerPage } },
  );

export const getVehicleTypes = (): ApiPromise<
  DataPaginateResponse<VehicleType[]>,
  ErrorResponse
> =>
  API.get<DataPaginateResponse<VehicleType[]>, ErrorResponse>(
    VEHICLE_ENPOINTS.getVehicleTypes,
  );

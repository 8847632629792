import { ReactElement, useMemo, useCallback } from 'react';
import { Card, Typography, Divider, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { setSkipInitialConfig } from '~store/slices/home/index';
import { getInitialsNameLetterUpperCase } from '~utils/user';
import { getOcurrencesDataCompany } from '~utils/home';
import { toAbsoluteUrl } from '~utils/assetsHelpers';
import { PATHS } from '~constants/index';

import { ConfigAccountDashboardProps } from './types';
import {
  ConfigAccountDashboardCardContent,
  ConfigAccountDashboardInfo,
  ConfigAccountDashboardInfoRowItemContainer,
  ConfigAccountDashboardInfoRowItemIconContainer,
  ConfigAccountDashboardInfoRowItemIconImg,
  ConfigAccountDashboardInfoRowItemIconCheck,
  ConfigAccountDashboardInfoRowItemDescription,
  ConfigAccountDashboardInfoRowItemDescriptionButtonContainer,
  ConfigAccountDashboardInfoRowItemDescriptionButton,
  ConfigAccountDashboardStats,
  ConfigAccountDashboardStatsProggressContainer,
  ConfigAccountDashboardStatsProggressFull,
  ConfigAccountDashboardStatsProggressDynamic,
  ConfigAccountDashboardStatsProggressAvatar,
} from './styles';

const progressInfo = {
  size: 80,
  thickness: {
    full: 2,
    dynamic: 4,
  },
};

const multiplierValueProgress = 33.34;

const ConfigAccountDashboard = ({
  vehiclesCount,
  depositsCount,
  driversCount,
  handleOepnDialogInviteDriver,
}: ConfigAccountDashboardProps): ReactElement => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const hasVehicles = useMemo(() => vehiclesCount > 0, [vehiclesCount]);
  const hasDeposits = useMemo(() => depositsCount > 0, [depositsCount]);
  const hasDrivers = useMemo(() => driversCount > 0, [driversCount]);

  const currentValueDynamicProgress = useMemo(() => {
    const occurencesCount = getOcurrencesDataCompany(
      vehiclesCount,
      depositsCount,
      driversCount,
    );

    return Math.floor(multiplierValueProgress * occurencesCount);
  }, [vehiclesCount, depositsCount, driversCount]);

  const handleRedirectOnClick = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate],
  );

  const handleSkipStep = useCallback(() => {
    dispatch(setSkipInitialConfig());
  }, [dispatch]);

  return (
    <Card>
      <ConfigAccountDashboardCardContent>
        <ConfigAccountDashboardInfo>
          <Typography variant="h6" fontWeight="bold">
            Termina de configurar tu cuenta
          </Typography>

          <ConfigAccountDashboardInfoRowItemContainer>
            <ConfigAccountDashboardInfoRowItemIconContainer>
              <ConfigAccountDashboardInfoRowItemIconImg
                src={toAbsoluteUrl('/icons/icon_truck.svg')}
                alt="Vehicle Icon"
              />

              {hasVehicles && (
                <ConfigAccountDashboardInfoRowItemIconCheck color="success" />
              )}
            </ConfigAccountDashboardInfoRowItemIconContainer>

            <ConfigAccountDashboardInfoRowItemDescription>
              <Typography variant="body2" fontWeight="bold">
                Carga tu flota
              </Typography>

              <Typography variant="body2">
                Carga los vehículos con los que envías mercadería.
              </Typography>

              {!hasVehicles && (
                <ConfigAccountDashboardInfoRowItemDescriptionButtonContainer>
                  <ConfigAccountDashboardInfoRowItemDescriptionButton
                    size="small"
                    onClick={() =>
                      handleRedirectOnClick(PATHS.CONFIG.VEHICLES.NEW)
                    }
                  >
                    Cargar...
                  </ConfigAccountDashboardInfoRowItemDescriptionButton>
                </ConfigAccountDashboardInfoRowItemDescriptionButtonContainer>
              )}
            </ConfigAccountDashboardInfoRowItemDescription>
          </ConfigAccountDashboardInfoRowItemContainer>

          <Divider />

          <ConfigAccountDashboardInfoRowItemContainer>
            <ConfigAccountDashboardInfoRowItemIconContainer>
              <ConfigAccountDashboardInfoRowItemIconImg
                src={toAbsoluteUrl('/icons/icon_warehouse.svg')}
                alt="Warehouse Icon"
              />

              {hasDeposits && (
                <ConfigAccountDashboardInfoRowItemIconCheck color="success" />
              )}
            </ConfigAccountDashboardInfoRowItemIconContainer>

            <ConfigAccountDashboardInfoRowItemDescription>
              <Typography variant="body2" fontWeight="bold">
                Carga tus depósitos o puntos de entrega
              </Typography>

              <Typography variant="body2">
                Configura los puntos de carga y salida de tu flota. Las rutas
                podrán iniciar y/o terminar en cualquiera de estos puntos.
              </Typography>

              {!hasDeposits && (
                <ConfigAccountDashboardInfoRowItemDescriptionButtonContainer>
                  <ConfigAccountDashboardInfoRowItemDescriptionButton
                    size="small"
                    onClick={() =>
                      handleRedirectOnClick(PATHS.CONFIG.DEPOSITS.NEW)
                    }
                  >
                    Cargar...
                  </ConfigAccountDashboardInfoRowItemDescriptionButton>
                </ConfigAccountDashboardInfoRowItemDescriptionButtonContainer>
              )}
            </ConfigAccountDashboardInfoRowItemDescription>
          </ConfigAccountDashboardInfoRowItemContainer>

          <Divider />

          <ConfigAccountDashboardInfoRowItemContainer>
            <ConfigAccountDashboardInfoRowItemIconContainer>
              <ConfigAccountDashboardInfoRowItemIconImg
                src={toAbsoluteUrl('/icons/icon_user.svg')}
                alt="User Icon"
              />

              {hasDrivers && (
                <ConfigAccountDashboardInfoRowItemIconCheck color="success" />
              )}
            </ConfigAccountDashboardInfoRowItemIconContainer>

            <ConfigAccountDashboardInfoRowItemDescription>
              <Typography variant="body2" fontWeight="bold">
                Sumá choferes a tu empresa
              </Typography>

              <Typography variant="body2">
                Crea o invita usuarios registrados como choferes en la app de
                Routix, para poder enviar rutas y hacer el seguimiento en tiempo
                real.
              </Typography>

              <ConfigAccountDashboardInfoRowItemDescriptionButtonContainer>
                <ConfigAccountDashboardInfoRowItemDescriptionButton
                  size="small"
                  onClick={() =>
                    handleRedirectOnClick(PATHS.CONFIG.DRIVERS.NEW)
                  }
                >
                  Cargar...
                </ConfigAccountDashboardInfoRowItemDescriptionButton>

                <ConfigAccountDashboardInfoRowItemDescriptionButton
                  variant="contained"
                  size="small"
                  onClick={handleOepnDialogInviteDriver}
                  color="primary"
                >
                  Invitar chofer
                </ConfigAccountDashboardInfoRowItemDescriptionButton>
              </ConfigAccountDashboardInfoRowItemDescriptionButtonContainer>
            </ConfigAccountDashboardInfoRowItemDescription>
          </ConfigAccountDashboardInfoRowItemContainer>

          <Divider />

          <Link
            component="button"
            variant="body2"
            sx={{ maxWidth: 'max-content' }}
            onClick={handleSkipStep}
            underline="hover"
          >
            Saltar este paso
          </Link>
        </ConfigAccountDashboardInfo>

        <ConfigAccountDashboardStats>
          <ConfigAccountDashboardStatsProggressContainer>
            <ConfigAccountDashboardStatsProggressFull
              variant="determinate"
              value={100}
              size={progressInfo.size}
              thickness={progressInfo.thickness.full}
              color="inherit"
            />

            <ConfigAccountDashboardStatsProggressDynamic
              variant="determinate"
              value={currentValueDynamicProgress}
              size={progressInfo.size + progressInfo.thickness.dynamic}
              thickness={progressInfo.thickness.dynamic}
              color="primary"
            />

            <ConfigAccountDashboardStatsProggressAvatar
              alt={user?.company.name}
              src={user?.company.logoFile?.url}
              sx={{
                width: 60,
                height: 60,
                position: 'absolute',
                top: 10,
                left: 10,
              }}
            >
              {getInitialsNameLetterUpperCase(user?.company.name as string)}
            </ConfigAccountDashboardStatsProggressAvatar>

            <Typography variant="body2" fontWeight="bold" textAlign="center">
              {`${currentValueDynamicProgress}% tareas completas`}
            </Typography>
          </ConfigAccountDashboardStatsProggressContainer>
        </ConfigAccountDashboardStats>
      </ConfigAccountDashboardCardContent>
    </Card>
  );
};

export default ConfigAccountDashboard;

import { ReactElement, useMemo, memo } from 'react';
import { Typography } from '@mui/material';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { isCurrentLoggedCompany } from '~utils/partnerCompany';
import { replaceParamsPath } from '~utils/router';
import { Link } from '~components/index';
import { PATHS } from '~constants/paths';

import { OrderRouteInfoProps } from './types';

const OrderRouteInfo = ({
  apiTransactionId,
  routeId,
  routeCode,
  carrierCompanyId,
  carrierCompanyName,
}: OrderRouteInfoProps): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  const isOwnCompany = useMemo(
    () => isCurrentLoggedCompany(carrierCompanyId, user?.companyId ?? null),
    [carrierCompanyId, user],
  );

  if (!isOwnCompany && Boolean(carrierCompanyName))
    return (
      <Typography variant="body2" component="span" color="primary">
        {`Gestionada por ${carrierCompanyName}`}
      </Typography>
    );

  return (
    <Link
      to={replaceParamsPath(PATHS.ROUTES.VEHICLE_PLANNING, {
        ':apiTransactionId': apiTransactionId,
        ':routeId': routeId,
      })}
      color="primary"
      variant="inherit"
      underline="hover"
      fontWeight={500}
    >
      {`#${routeCode.toUpperCase()}`}
    </Link>
  );
};

export default memo(OrderRouteInfo);

import { styled, Box } from '@mui/material';

export const RoutesStatiticsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'gutterTop',
})<{ gutterTop: boolean }>(({ theme, gutterTop }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  justifyContent: 'center',

  ...(gutterTop && {
    paddingTop: theme.spacing(2),
  }),
}));

import { ReactElement, useCallback } from 'react';
import { List, Collapse } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import classNames from 'classnames';

import { useToggle } from '~hooks/index';
import { StatisticsProgress } from '~components/index';

import { ChargeStatusMapLegendProps, ChargeStatusMapLegendUnit } from './types';
import {
  classes,
  ChargeStatusMapLegendContainer,
  ChargeStatusMapLegendListButton,
  ChargeStatusMapLegendListItemText,
  ChargeStatusMapLegendContent,
  ChargeStatusMapLegendProgressLabelContainer,
  ChargeStatusMapLegendProgressLabelText,
  ChargeStatusMapLegendProgressLabelTextSmall,
} from './styles';

const ChargeStatusMapLegend = ({
  unit1,
  unit2,
}: ChargeStatusMapLegendProps): ReactElement => {
  const [expanded, toogleExpanded] = useToggle(true);

  const getLabel = useCallback(
    (label: string, { busy, capacity }: ChargeStatusMapLegendUnit) => (
      <ChargeStatusMapLegendProgressLabelContainer>
        <ChargeStatusMapLegendProgressLabelText>
          {label}
        </ChargeStatusMapLegendProgressLabelText>

        <ChargeStatusMapLegendProgressLabelTextSmall>
          {`${busy} / ${capacity}`}
        </ChargeStatusMapLegendProgressLabelTextSmall>
      </ChargeStatusMapLegendProgressLabelContainer>
    ),
    [],
  );

  const parseLegendPercentage = useCallback(
    (_val: number, _max: number, currentProgressVal: number) =>
      `${currentProgressVal >= 100 ? 100 : currentProgressVal}%`,
    [],
  );

  const getColorByUnit = useCallback(
    ({ busy, capacity }: ChargeStatusMapLegendUnit) =>
      busy > capacity ? 'error' : 'primary',
    [],
  );

  return (
    <ChargeStatusMapLegendContainer>
      <List disablePadding>
        <ChargeStatusMapLegendListButton
          onClick={() => toogleExpanded()}
          className={classNames({ [classes.expanded]: expanded })}
        >
          <ChargeStatusMapLegendListItemText
            primary="Estado de carga"
            primaryTypographyProps={{ variant: 'caption', fontWeight: 'bold' }}
          />

          <KeyboardArrowDown className={classes.expandIcon} />
        </ChargeStatusMapLegendListButton>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ChargeStatusMapLegendContent>
            <StatisticsProgress
              max={unit1.capacity}
              val={unit1.busy}
              size="xSmall"
              themeColor={getColorByUnit(unit1)}
              label={getLabel('Primaria', unit1)}
              parseLegend={parseLegendPercentage}
            />

            <StatisticsProgress
              max={unit2.capacity}
              val={unit2.busy}
              size="xSmall"
              themeColor={getColorByUnit(unit2)}
              label={getLabel('Secundaria', unit2)}
              parseLegend={parseLegendPercentage}
            />
          </ChargeStatusMapLegendContent>
        </Collapse>
      </List>
    </ChargeStatusMapLegendContainer>
  );
};

export default ChargeStatusMapLegend;

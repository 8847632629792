import {
  styled,
  Dialog,
  dialogClasses,
  IconButton,
  Stack,
  Card,
  CardContent,
  DialogContent,
  Pagination,
} from '@mui/material';

export const DialogSearchCustomersContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 600,
    width: '100%',
    overflow: 'hidden',
  },
});

export const DialogSearchCustomersTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogSearchCustomersTitleCloseIcon = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.grey[500],
  }),
);

export const DialogSearchCustomersContent = styled(DialogContent)(
  ({ theme }) => ({
    paddingBottom: theme.spacing(4),
    overflowY: 'initial',
  }),
);

export const DialogSearchCustomersListContainer = styled(Stack)(
  ({ theme }) => ({
    padding: theme.spacing(1, 0),
    overflowY: 'auto',
    maxHeight: 450,

    scrollbarWidth: 'none',
    msOverflowStyle: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
);

export const DialogSearchCustomersListCardItem = styled(Card)({
  display: 'flex',
  overflow: 'initial',
});

export const DialogSearchCustomersListCardItemContent = styled(CardContent)({
  flex: 1,
});

export const DialogSearchCustomersPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center',
});

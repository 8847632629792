import {
  styled,
  ListItemButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
} from '@mui/material';

import { Marker } from '~components/Icons';

export const classes = {
  expanded: 'VehicleMapLegend-expanded',
  expandIcon: 'VehicleMapLegend-expandIcon',
};

export const VehicleMapLegendContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 150,
  background: theme.palette.common.white,
  outline: 'none',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1.25),
  boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
  zIndex: 1,
}));

export const VehicleMapLegendListButton = styled(ListItemButton)(
  ({ theme }) => ({
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(
      ['border-radius', 'background-color'],
      { duration: theme.transitions.duration.shortest },
    ),

    [`& .${classes.expandIcon}`]: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.action.active,
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
      }),
    },

    [`&.${classes.expanded}`]: {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,

      [`& .${classes.expandIcon}`]: {
        transform: 'rotate(180deg)',
      },
    },
  }),
);

export const VehicleMapLegendListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

export const VehicleMapLegendListItemText = styled(ListItemText)({
  margin: 0,
});

export const VehicleMapLegendListIcon = styled(ListItemIcon)(({ theme }) => ({
  width: theme.spacing(2),
  marginRight: theme.spacing(1),
  minWidth: 'auto',
}));

export const VehicleMapLegendMarkerIcon = styled(Marker, {
  shouldForwardProp: (prop) => prop !== 'stateColor',
})<{ stateColor: string }>(({ theme, stateColor }) => ({
  fontSize: theme.typography.pxToRem(15),
  color: stateColor,
}));

export const VehicleMapLegendSuggestedRouteIcon = styled(Box)(({ theme }) => ({
  height: theme.spacing(0.2),
  borderStyle: 'solid',
  borderWidth: theme.spacing(0.2),
  width: theme.spacing(2),
  borderColor: '#212121',
}));

export const VehicleMapLegendCurrentRouteIcon = styled(Box)(({ theme }) => ({
  height: theme.spacing(0.2),
  borderStyle: 'dashed',
  borderWidth: theme.spacing(0.2),
  width: theme.spacing(2),
  borderColor: '#5618B6',
}));

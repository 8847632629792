import { ReactElement, RefAttributes } from 'react';
import { DatePickerProps, DatePicker } from '@mui/x-date-pickers';

import { useSubscriptionDataRetentionMinDate } from '~hooks/index';

const SubscriptionDataRetentionMinDatePicker = ({
  minDate: defaultMinDate,
  ...rest
}: DatePickerProps<Date, Date> &
  RefAttributes<HTMLDivElement>): ReactElement => {
  const minDate = useSubscriptionDataRetentionMinDate(defaultMinDate);

  return <DatePicker {...rest} minDate={minDate} />;
};

export default SubscriptionDataRetentionMinDatePicker;

import { ReactElement } from 'react';
import { Typography } from '@mui/material';

import { NotAccessPermissionContainer } from './styles';

const NotAccessPermission = (): ReactElement => (
  <NotAccessPermissionContainer>
    <Typography component="h1" variant="h3">
      No tienes permisos para acceder
    </Typography>
  </NotAccessPermissionContainer>
);

export default NotAccessPermission;

import {
  ReactElement,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Stack,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { useToggle } from '~hooks/index';

import RangeTimePicker from '../RangeTimePicker';

import { TimeWindowPickerRef, TimeWindowPickerProps } from './types';

const TimeWindowPicker = forwardRef<TimeWindowPickerRef, TimeWindowPickerProps>(
  function TimeWindowPicker(inProps, ref): ReactElement {
    const {
      windowAllDayProps,
      windowOneProps,
      windowTwoProps,
      onClearWindowTwoProps,
      disabledActions = false,
    } = inProps;

    const [enabledSecondTimeWindow, toogleEnabledSecondTimeWindow] =
      useToggle(false);

    const handleDeleteSecondTimeWindow = useCallback(() => {
      toogleEnabledSecondTimeWindow(false);
      onClearWindowTwoProps();
    }, [onClearWindowTwoProps, toogleEnabledSecondTimeWindow]);

    useImperativeHandle(
      ref,
      () => ({
        toogleEnabledSecondTimeWindow,
        handleDeleteSecondTimeWindow,
      }),
      [handleDeleteSecondTimeWindow, toogleEnabledSecondTimeWindow],
    );

    return (
      <Stack spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <FormControlLabel
            control={<Switch {...windowAllDayProps} />}
            label="Todo el día"
          />

          <RangeTimePicker {...windowOneProps} />

          <Tooltip
            title="Agregar segunda ventana horaria"
            arrow
            placement="right"
          >
            <span>
              <IconButton
                aria-label="Add second time window"
                onClick={() => toogleEnabledSecondTimeWindow(true)}
                color="primary"
                size="small"
                disabled={disabledActions || enabledSecondTimeWindow}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Stack>

        {enabledSecondTimeWindow && (
          <Stack direction="row" spacing={1} alignItems="center">
            <RangeTimePicker
              {...windowTwoProps}
              containerProps={{ sx: { marginLeft: '141px' } }}
            />

            <Tooltip
              title="Eliminar segunda ventana horaria"
              arrow
              placement="right"
            >
              <span>
                <IconButton
                  aria-label="Delete second time window"
                  onClick={handleDeleteSecondTimeWindow}
                  color="error"
                  size="small"
                  disabled={disabledActions}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        )}
      </Stack>
    );
  },
);

export default TimeWindowPicker;

export const PATHS = {
  HOME: {
    BASE: '/',
  },
  AUTH: {
    BASE: '/auth',
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    RECOVER_PASSWORD: '/auth/recover-password',
    VALIDATE_ACCOUNT: '/auth/validate-account',
    FORCE_LOGIN: '/auth/force',
    CHANGE_PASSWORD: '/auth/change-password',
  },
  PROFILE: {
    BASE: '/profile',
  },
  CUSTOMERS: {
    BASE: '/customers',
    NEW: '/customers/new',
    EDIT: '/customers/:customerId/edit',
  },
  RECEIVED: {
    BASE: '/received',
  },
  ORDERS: {
    BASE: '/orders',
    NEW: '/orders/new',
    SHOW: '/orders/:orderId',
    EDIT: '/orders/:orderId/edit',
  },
  TOURS: {
    BASE: '/tours',
  },
  ROUTES: {
    BASE: '/routes',
    PLANNING: '/routes/:apiTransactionId/planning',
    VEHICLE_PLANNING: '/routes/:apiTransactionId/planning/route/:routeId',
  },
  REPORTS: {
    BASE: '/reports',
  },
  CONFIG: {
    BASE: '/config',
    COMPANY: {
      BASE: '/config/company',
    },
    SUBSCRIPTIONS: {
      BASE: '/config/subscriptions',
    },
    USERS: {
      BASE: '/config/users',
      NEW: '/config/users/new',
      EDIT: '/config/users/:userId/edit',
    },
    DRIVERS: {
      BASE: '/config/drivers',
      NEW: '/config/drivers/new',
      SHOW: '/config/drivers/:driverId',
    },
    VEHICLES: {
      BASE: '/config/vehicles',
      NEW: '/config/vehicles/new',
      EDIT: '/config/vehicles/:vehicleId/edit',
    },
    DEPOSITS: {
      BASE: '/config/deposits',
      NEW: '/config/deposits/new',
      EDIT: '/config/deposits/:depositId/edit',
    },
    INTEGRATIONS: {
      BASE: '/config/integrations',
    },
    PARTNERS_COMPANIES: {
      BASE: '/config/partners-companies',
      NEW: '/config/partners-companies/new',
    },
    PREFERENCES: {
      BASE: '/config/preferences',
    },
  },
  QUESTIONS: {
    BASE: '/questions',
  },
  PUBLIC: {
    BASE: '/public',
    PRIVACY_TERMS: '/public/privacy-terms',
    DEVICE_REDIRECT: '/public/device_redirect',
    DELETE_ACCOUNT: '/public/delete-account',
  },
};

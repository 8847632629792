import { ReactElement, useMemo } from 'react';

import { useSubscriptionRoutes } from '~hooks/index';

import SubscriptionAvailableRoutesStateIcon from '../Icon';

import { SubscriptionAvailableRoutesStateBadgeProps } from './types';
import { SubscriptionAvailableRoutesStateBadgeRoot } from './styles';

const SubscriptionAvailableRoutesStateBadge = ({
  children,
  className,
  size = 'small',
}: SubscriptionAvailableRoutesStateBadgeProps): ReactElement => {
  const { itsHalfway, isEnding } = useSubscriptionRoutes();

  const invisble = useMemo(
    () => !itsHalfway && !isEnding,
    [isEnding, itsHalfway],
  );

  return (
    <SubscriptionAvailableRoutesStateBadgeRoot
      badgeContent={<SubscriptionAvailableRoutesStateIcon fontSize={size} />}
      invisible={invisble}
      className={className}
      size={size}
    >
      {children}
    </SubscriptionAvailableRoutesStateBadgeRoot>
  );
};

export default SubscriptionAvailableRoutesStateBadge;

import { FC, createContext, useContext } from 'react';

import { useToggleMenu } from '~hooks/index';

import { HeaderActions } from './types';

const HeaderActionsContext = createContext<HeaderActions>({
  menuFirstSteps: {
    anchorElRefMenu: { current: null },
    open: false,
    handleToggleOpenMenu: () => {},
  },
  menuActiveRoutes: {
    anchorElRefMenu: { current: null },
    open: false,
    handleToggleOpenMenu: () => {},
  },
  menuProfile: {
    anchorElRefMenu: { current: null },
    open: false,
    handleToggleOpenMenu: () => {},
  },
});

export const useHeaderActions = (): HeaderActions =>
  useContext(HeaderActionsContext);

const HeaderActionsProvider: FC = ({ children }) => {
  const [
    anchorElMenuFirstSteps,
    openMenuFirstSteps,
    ,
    handleToggleMenuFirstSteps,
  ] = useToggleMenu();

  const [
    anchorElMenuActiveRoutes,
    openMenuActiveRoutes,
    ,
    handleToggleActiveRoutes,
  ] = useToggleMenu();

  const [anchorElMenuProfile, openMenuProfile, , handleToggleMenuProfile] =
    useToggleMenu();

  return (
    <HeaderActionsContext.Provider
      value={{
        menuFirstSteps: {
          anchorElRefMenu: anchorElMenuFirstSteps,
          open: openMenuFirstSteps,
          handleToggleOpenMenu: handleToggleMenuFirstSteps,
        },
        menuActiveRoutes: {
          anchorElRefMenu: anchorElMenuActiveRoutes,
          open: openMenuActiveRoutes,
          handleToggleOpenMenu: handleToggleActiveRoutes,
        },
        menuProfile: {
          anchorElRefMenu: anchorElMenuProfile,
          open: openMenuProfile,
          handleToggleOpenMenu: handleToggleMenuProfile,
        },
      }}
    >
      {children}
    </HeaderActionsContext.Provider>
  );
};

export default HeaderActionsProvider;

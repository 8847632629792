import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  DialogActions,
} from '@mui/material';

export const DialogAddIntegrationContainer = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: 620,
    maxHeight: `calc(100% - ${theme.spacing(4)})`,
  },
}));

export const DialogAddIntegrationContentContainer = styled(DialogContent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignItems: 'center',
  }),
);

export const DialogAddIntegrationProviderImgPropmt = styled('img')({
  height: 180,
});

export const DialogAddIntegrationActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

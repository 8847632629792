import { ReactElement } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { DialogConfimationProps } from './types';

const DialogConfimation = ({
  open,
  title = '¿Estás seguro/a?',
  description,
  warningText,
  onConfirm,
  onCancel,
  loadingConfirm = false,
  maxWidth = 'xs',
  children,
}: DialogConfimationProps): ReactElement => (
  <Dialog
    open={open}
    aria-labelledby="dialog-confirmation-title"
    aria-describedby="dialog-confirmation-description"
    maxWidth={maxWidth}
    fullWidth
    scroll="body"
  >
    <DialogTitle id="dialog-confirmation-title">{title}</DialogTitle>

    <DialogContent>
      <Stack spacing={1}>
        <DialogContentText id="dialog-confirmation-description">
          {description}
        </DialogContentText>

        {warningText && (
          <Alert variant="outlined" severity="warning">
            {warningText}
          </Alert>
        )}

        {children}
      </Stack>
    </DialogContent>

    <DialogActions>
      <Button color="secondary" onClick={onCancel}>
        Cancelar
      </Button>

      <LoadingButton
        variant="contained"
        color="primary"
        onClick={onConfirm}
        loading={loadingConfirm}
      >
        Confirmar
      </LoadingButton>
    </DialogActions>
  </Dialog>
);

export default DialogConfimation;

import {
  styled,
  CardContent,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material';

export const DeliveryStatesCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3),

  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

export const DeliveryStatesLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<{ customColor: string }>(({ theme, customColor }) => ({
  width: 'calc(100% - 25px)',
  height: 20,
  borderRadius: 10,
  backgroundColor: theme.palette.grey[200],

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: customColor,
  },
}));

import { ReactElement, useMemo, memo } from 'react';
import { green, yellow } from '@mui/material/colors';
import { get } from 'lodash';

import { ActivatedChipRoot } from '~components/ActivatedChip/styles';

import {
  IsAvailableChipProps,
  IsAvailableChipTypeMapping,
  IsAvailableChipType,
} from './types';

const typeMapping: IsAvailableChipTypeMapping = {
  available: {
    color: green[700],
    label: 'Libre',
  },
  inTravel: {
    color: yellow[700],
    label: 'En viaje',
  },
  outOfWork: {
    color: yellow[700],
    label: 'Fuera de jornada',
  },
  assigned: {
    color: '#5618b6',
    label: 'Asignado a ruta',
  },
};

const IsAvailableChip = ({
  isAvailable,
  isOpenForWork = true,
  isAssigned = false,
  size = 'medium',
  variant = 'outlined',
}: IsAvailableChipProps): ReactElement => {
  const type = useMemo<IsAvailableChipType>(() => {
    if (isAssigned) return 'assigned';

    if (!isOpenForWork) return 'outOfWork';

    if (isAvailable) return 'available';

    return 'inTravel';
  }, [isAssigned, isOpenForWork, isAvailable]);

  const currentTypeData = useMemo(() => get(typeMapping, type), [type]);

  return (
    <ActivatedChipRoot
      variant={variant}
      label={currentTypeData.label}
      size={size}
      ownerState={{ color: currentTypeData.color }}
    />
  );
};

export default memo(IsAvailableChip);

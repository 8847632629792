import API from '~config/api';
import { ApiError, ApiPromise, DataPaginateResponse } from '~globals/types';

import { EnumerationData } from './types';

export const ENUMS_ENPOINTS = {
  getCountries: '/api/v1/Enumeration/countries',
  getItemStateTypes: '/api/v1/Enumeration/ItemStateTypes',
  getDriverCompanyStateTypes: '/api/v1/Enumeration/driverCompanyStateTypes',
  getPriorityTypes: '/api/v1/Enumeration/priorityTypes',
  getItemTypes: '/api/v1/Enumeration/itemTypes',
  getItemImportStateTypes: '/api/v1/Enumeration/itemImportStateTypes',
  getRouteStateTypes: '/api/v1/Enumeration/routeStateTypes',
  getSkillsTypes: '/api/v1/Enumeration/SkillTypes',
  getTaxTypes: '/api/v1/Enumeration/TaxTypes',
  getInvoiceTypes: '/api/v1/Enumeration/InvoiceTypes',
};

export const getEnumerationData = (
  resource: string,
): ApiPromise<DataPaginateResponse<EnumerationData>, ApiError> =>
  API.get<DataPaginateResponse<EnumerationData>, ApiError>(resource);

export const getSkillsTypesCompany = (): ApiPromise<
  DataPaginateResponse<string[]>,
  ApiError
> =>
  API.get<DataPaginateResponse<string[]>, ApiError>(
    ENUMS_ENPOINTS.getSkillsTypes,
  );

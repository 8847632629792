import { ReactElement, useMemo } from 'react';
import { Stack } from '@mui/material';

import { ItemTasksList, AccessFeature } from '~components/index';
import { PlanFeaturesTypes } from '~globals/types/enums';

import { useDialogFeedbackStopContext } from '../../DialogFeedbackStopContext';

const DialogFeebackStopArticles = (): ReactElement => {
  const { data } = useDialogFeedbackStopContext();

  const itemTasks = useMemo(
    () => data?.routeItem.item?.itemTasks ?? [],
    [data],
  );

  return (
    <Stack spacing={2}>
      <AccessFeature.Alert validFeature={PlanFeaturesTypes.MgmItemTask} />

      <AccessFeature.Hidden
        validFeature={PlanFeaturesTypes.MgmItemTask}
        type="notAccess"
      >
        <ItemTasksList list={itemTasks} />
      </AccessFeature.Hidden>
    </Stack>
  );
};

export default DialogFeebackStopArticles;

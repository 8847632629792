import { FC, createContext, useContext, useState } from 'react';

import { Nullable } from '~globals/types';
import { PartnerCompanyAssociationData } from '~services/partnerCompany/types';
import { PartnerCompanyTypes } from '~globals/types/enums';

import { DialogAssociatePartnerCompanyProps } from './types';

export type DialogAssociatePartnerCompanyContextPanelsId =
  | 'association'
  | 'sendInvitation';

export interface DialogAssociatePartnerCompanyContextAssociateData
  extends PartnerCompanyAssociationData {
  associationType: PartnerCompanyTypes;
}

interface DialogAssociatePartnerCompanyContextValue
  extends DialogAssociatePartnerCompanyProps {
  activeTab: DialogAssociatePartnerCompanyContextPanelsId;
  setActiveTab: (newTab: DialogAssociatePartnerCompanyContextPanelsId) => void;
  associateData: Nullable<DialogAssociatePartnerCompanyContextAssociateData>;
  setAssociateData: (
    newData: Nullable<DialogAssociatePartnerCompanyContextAssociateData>,
  ) => void;
}

const DialogAssociatePartnerCompanyContext =
  createContext<DialogAssociatePartnerCompanyContextValue>({
    open: false,
    onClose: () => {},
    activeTab: 'association',
    setActiveTab: () => {},
    associateData: null,
    setAssociateData: () => {},
    onAssociate: undefined,
    onSendInvitation: undefined,
    onOpenDialogInvitationCompany: undefined,
  });

export const useDialogAssociatePartnerCompanyContex =
  (): DialogAssociatePartnerCompanyContextValue =>
    useContext(DialogAssociatePartnerCompanyContext);

const DialogAssociatePartnerCompanyContexProvider: FC<
  DialogAssociatePartnerCompanyProps
> = ({
  open,
  onClose,
  onAssociate,
  onSendInvitation,
  onOpenDialogInvitationCompany,
  children,
}) => {
  const [activeTab, setActiveTab] =
    useState<DialogAssociatePartnerCompanyContextPanelsId>('association');

  const [associateData, setAssociateData] =
    useState<Nullable<DialogAssociatePartnerCompanyContextAssociateData>>(null);

  return (
    <DialogAssociatePartnerCompanyContext.Provider
      value={{
        open,
        onClose,
        activeTab,
        setActiveTab,
        associateData,
        setAssociateData,
        onAssociate,
        onSendInvitation,
        onOpenDialogInvitationCompany,
      }}
    >
      {children}
    </DialogAssociatePartnerCompanyContext.Provider>
  );
};

export default DialogAssociatePartnerCompanyContexProvider;

import { useMemo, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { validatePartnerItems } from '~services/partnerCompany';
import { PartnerCompanyItem } from '~services/partnerCompany/types';
import { Nullable } from '~globals/types';

import { useLazyRequest } from './useLazyRequest';

export const useValidatePartnerItems = (
  onSuccessValidate?: () => void,
  currentPartnerItem?: Nullable<PartnerCompanyItem>,
): {
  loading: boolean;
  handleValidatePartnerItems: (
    itemsIds: Array<string | number>,
  ) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const messageSuccess = useMemo(() => {
    if (currentPartnerItem) {
      // eslint-disable-next-line max-len
      return `El pedido ${currentPartnerItem.trackingCode} fue marcado como colectado correctamente`;
    }

    return 'Pedidos marcados como colectados correctamente';
  }, [currentPartnerItem]);

  const messageError = useMemo(() => {
    if (currentPartnerItem) {
      return 'No se pudo marcar como colectado el pedido, intente nuevamente';
    }

    return 'No se pudo marcar como colectados los pedidos, intente nuevamente';
  }, [currentPartnerItem]);

  const [, loadingNotValidatePartnerItem, , executeValidatePartnerItem] =
    useLazyRequest({
      request: validatePartnerItems,
      withPostSuccess: () => {
        enqueueSnackbar(messageSuccess, { variant: 'success' });

        onSuccessValidate?.();
      },
      withPostFailure: () => {
        enqueueSnackbar(messageError, { variant: 'error' });
      },
    });

  const handleValidatePartnerItems = useCallback(
    async (itemsIds: Array<string | number>) => {
      await executeValidatePartnerItem(itemsIds);
    },
    [executeValidatePartnerItem],
  );

  return {
    loading: loadingNotValidatePartnerItem,
    handleValidatePartnerItems,
  };
};

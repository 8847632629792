import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { AccessFeatureTypograhphyProps } from './types';
import AccessFeatureNextPlanChip from '../NextPlanChip';

const AccessFeatureTypograhpy = ({
  validFeature,
  availableToDate,
  component = 'span',
  spacing = 1,
  openAccessFeatureModalOnClickChip = false,
  ...rest
}: AccessFeatureTypograhphyProps): ReactElement => (
  <Stack spacing={spacing} direction="row" alignItems="center">
    <Typography {...rest} component={component} />

    <AccessFeatureNextPlanChip
      validFeature={validFeature}
      availableToDate={availableToDate}
      openAccessFeatureModalOnClick={openAccessFeatureModalOnClickChip}
    />
  </Stack>
);

export default AccessFeatureTypograhpy;

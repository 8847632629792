import { FC, createContext, useContext } from 'react';

import { RouteItemFeedback } from '~services/route/types';
import { Nullable, MapRefVal } from '~globals/types';
import { RouteItemStateType } from '~globals/types/enums';

interface DialogFeedbackStopContextValue {
  data: Nullable<RouteItemFeedback>;
  routeItemStateTypeId: Nullable<RouteItemStateType>;
  reloadData: () => void;
  onClose: () => void;
  mapRef?: MapRefVal;
}

const DialogFeedbackStopContext = createContext<DialogFeedbackStopContextValue>(
  {
    data: null,
    routeItemStateTypeId: null,
    reloadData: () => {},
    onClose: () => {},
    mapRef: null,
  },
);

export const useDialogFeedbackStopContext =
  (): DialogFeedbackStopContextValue => useContext(DialogFeedbackStopContext);

const DialogFeedbackStopProvider: FC<{
  value: DialogFeedbackStopContextValue;
}> = ({ children, value }) => (
  <DialogFeedbackStopContext.Provider value={value}>
    {children}
  </DialogFeedbackStopContext.Provider>
);

export default DialogFeedbackStopProvider;

import { ReactElement } from 'react';
import {
  DialogContent,
  Stack,
  Typography,
  Avatar,
  createFilterOptions,
  Link,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { LoadingButton } from '@mui/lab';
import QRCode from 'react-qr-code';

import {
  CustomAutocomplete,
  UserInfo,
  IsAvailableChip,
} from '~components/index';
import { hasError } from '~utils/index';
import { getDefaultAvatar } from '~utils/user';
import { APP_STORES_INFO } from '~constants/appStoresInfo';

import { useDialogAssignDriverContext } from '../../DialogAssignDriverContext';
import { FIELDS_NAME, initialValues } from '../../utils';
import {
  DialogAssignDriverFirstRouteStepTypography,
  DialogAssignDriverFirstRouteBannerImg,
} from './styles';
import { DialogAssignDriverStoreImg } from '../../styles';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

const DialogAssignDriverFirstRoute = (): ReactElement => {
  const {
    refDriverAutocomplete,
    availablesDrivers: { data: availablesDriversOptions },
    selectedDriverAvailable: { selectedUserDriver },
    loadingSubmit,
    disabledSubmit,
  } = useDialogAssignDriverContext();

  const { errors, touched, setFieldValue, setFieldTouched, submitForm } =
    useFormikContext<typeof initialValues>();

  return (
    <DialogContent dividers sx={{ position: 'relative' }}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body2" fontWeight="bold">
            ¿ES TU PRIMERA RUTA?
          </Typography>

          <Typography variant="body1" fontWeight="bold" color="primary.main">
            SIMULA SER EL CHOFER
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Stack spacing={1} direction="row">
            <DialogAssignDriverFirstRouteStepTypography>
              1
            </DialogAssignDriverFirstRouteStepTypography>

            <Stack flex={1}>
              <Typography variant="caption">
                <b>Asígnate</b> como chofer de esta ruta.
              </Typography>

              <CustomAutocomplete
                innerRef={refDriverAutocomplete}
                options={availablesDriversOptions}
                label="Chofer"
                name={FIELDS_NAME.CURRENT_DRIVER}
                defaultValue={selectedUserDriver}
                onChange={(_e, val) => {
                  setFieldValue(FIELDS_NAME.CURRENT_DRIVER, val?.id ?? '');
                }}
                onBlur={() => {
                  setFieldTouched(FIELDS_NAME.CURRENT_DRIVER, true);
                }}
                error={hasError(touched, errors, FIELDS_NAME.CURRENT_DRIVER)}
                getOptionLabel={(option) => option.displayName}
                getOptionDisabled={(option) => !option.isAvailable as boolean}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <UserInfo
                      name={option.displayName}
                      urlAvatar={getDefaultAvatar(option.avatar ?? undefined)}
                    />

                    <IsAvailableChip
                      isAvailable={option.isAvailable as boolean}
                      size="small"
                      variant="filled"
                    />
                  </li>
                )}
                startAdornment={(val) =>
                  val && (
                    <Avatar
                      src={getDefaultAvatar(val.avatar ?? undefined)}
                      alt={val?.displayName}
                      sx={{ width: 20, height: 20 }}
                    />
                  )
                }
                filterOptions={createFilterOptions({
                  ignoreAccents: true,
                  ignoreCase: true,
                  stringify: (option) => option.displayName.replace(',', ''),
                })}
              />
            </Stack>
          </Stack>

          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={submitForm}
              loading={loadingSubmit}
              disabled={disabledSubmit}
            >
              Asignar
            </LoadingButton>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Stack spacing={1} direction="row">
            <DialogAssignDriverFirstRouteStepTypography>
              2
            </DialogAssignDriverFirstRouteStepTypography>

            <Stack spacing={1} flex={1}>
              <Typography variant="caption" flex={1}>
                <b>Descarga</b> la app e <b>inicia sesión</b> con tu usuario y
                contraseña (no es necesario que te vuelvas a registrar).
              </Typography>

              <Stack spacing={2} direction="row">
                <QRCode
                  value="https://app.routix.io/public/device_redirect"
                  size={130}
                />

                <Stack spacing={1} direction="row" justifyContent="center">
                  <Link
                    href={APP_STORES_INFO.PLAY_STORE.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DialogAssignDriverStoreImg
                      src={APP_STORES_INFO.PLAY_STORE.IMAGE_URL}
                      alt={APP_STORES_INFO.PLAY_STORE.NAME}
                    />
                  </Link>

                  <Link
                    href={APP_STORES_INFO.APP_STORE.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DialogAssignDriverStoreImg
                      src={APP_STORES_INFO.APP_STORE.IMAGE_URL}
                      alt={APP_STORES_INFO.APP_STORE.NAME}
                    />
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <Stack spacing={1} direction="row">
            <DialogAssignDriverFirstRouteStepTypography>
              3
            </DialogAssignDriverFirstRouteStepTypography>

            <Stack spacing={1} flex={1}>
              <Typography variant="caption" flex={1} maxWidth={202}>
                <b>Acepta y realiza</b> la ruta asignada como si fueras el
                chofer.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <DialogAssignDriverFirstRouteBannerImg
        src={toAbsoluteUrl('/images/banner_firstroute.svg')}
        alt="First Route"
      />
    </DialogContent>
  );
};

export default DialogAssignDriverFirstRoute;

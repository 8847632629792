import { ReactElement } from 'react';
import { Stack, DialogContentText, Button, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { isAdminUser } from '~utils/user';
import { getStringifyUrlQueryString } from '~utils/router';
import { PATHS } from '~constants/paths';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { DialogImportFromAssociatedCompanyLabelImg } from '../../styles';

const DialogImportFromAssociatedCompanyNotAssociatedCompanies =
  (): ReactElement => {
    const { user } = useAppSelector(selectAuth);

    return (
      <Stack spacing={2} flex={1} justifyContent="center" alignItems="center">
        <DialogContentText color="text.secondary">
          No se encontraron empresas asociadas activas
        </DialogContentText>

        <Stack spacing={2} direction="row" justifyContent="center">
          <DialogImportFromAssociatedCompanyLabelImg
            src={toAbsoluteUrl('/images/not_import_int.png')}
            alt="Not import associated company"
          />
        </Stack>

        <Stack spacing={2} direction="row">
          {isAdminUser(user) && (
            <Button
              color="primary"
              variant="outlined"
              component={RouterLink}
              to={getStringifyUrlQueryString({
                url: PATHS.CONFIG.PARTNERS_COMPANIES.BASE,
                query: {
                  openAssociateModal: true,
                },
              })}
            >
              Asociar una nueva
            </Button>
          )}
        </Stack>

        <DialogContentText variant="caption">
          Para mayor información sobre asignación de empresas, diríjasea este{' '}
          <Link
            href="https://routix.tawk.help/article/asociar-empresas"
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            underline="hover"
            fontWeight="bold"
          >
            artículo
          </Link>
        </DialogContentText>
      </Stack>
    );
  };

export default DialogImportFromAssociatedCompanyNotAssociatedCompanies;

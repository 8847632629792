import { FC, createContext, useContext } from 'react';

import { PlanPaymentTypes } from '~globals/types/enums';
import {
  SelectOption,
  BillingData,
  Nullable,
  SubscriptionPaymentProviders,
  SubscriptionPaymentProvidersPlans,
} from '~globals/types/commons';

interface SubscriptionPaymentModalContextItemPrice {
  USD: number;
  ARS: number;
}

interface SubscriptionPaymentModalContextItem {
  title: string;
  price: SubscriptionPaymentModalContextItemPrice;
}

interface SubscriptionPaymentModalContextBillingData {
  data: Nullable<BillingData>;
  setData: (billingData: BillingData) => void;
}

export type SubscriptionPaymentModalContextActiveFormPanelsId =
  | 'billingData'
  | SubscriptionPaymentProviders;

interface SubscriptionPaymentModalContextForm {
  activeForm: SubscriptionPaymentModalContextActiveFormPanelsId;
  setActiveForm: (
    newActiveForm: SubscriptionPaymentModalContextActiveFormPanelsId,
  ) => void;
}

interface SubscriptionPaymentModalContextProcessPayment {
  isProcess: boolean;
  setIsProcess: (newIsProcess: boolean) => void;
}

interface SubscriptionPaymentModalContextValue {
  isArg: boolean;
  paymentType: `${PlanPaymentTypes}`;
  providersPlansId?: SubscriptionPaymentProvidersPlans;
  isSinglePayment?: boolean;
  itemData: SubscriptionPaymentModalContextItem;
  selectsData: {
    countries: SelectOption[];
    taxTypes: SelectOption[];
    invoiceTypes: SelectOption[];
  };
  billingData: SubscriptionPaymentModalContextBillingData;
  form: SubscriptionPaymentModalContextForm;
  processPayment: SubscriptionPaymentModalContextProcessPayment;
  isPack?: boolean;
  contractPlanIdTest?: string;
}

const SubscriptionPaymentModalContext =
  createContext<SubscriptionPaymentModalContextValue>({
    isArg: true,
    paymentType: 'monthly',
    itemData: { title: '', price: { USD: 0, ARS: 0 } },
    selectsData: { countries: [], invoiceTypes: [], taxTypes: [] },
    billingData: { data: null, setData: () => {} },
    form: { activeForm: 'billingData', setActiveForm: () => {} },
    processPayment: { isProcess: false, setIsProcess: () => {} },
    providersPlansId: undefined,
    isSinglePayment: false,
    isPack: false,
  });

export const useSubscriptionPaymentModalContext =
  (): SubscriptionPaymentModalContextValue =>
    useContext(SubscriptionPaymentModalContext);

const SubscriptionPaymentModalProvider: FC<{
  value: SubscriptionPaymentModalContextValue;
}> = ({ value, children }) => (
  <SubscriptionPaymentModalContext.Provider value={value}>
    {children}
  </SubscriptionPaymentModalContext.Provider>
);

export default SubscriptionPaymentModalProvider;

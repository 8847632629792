import { styled, Card, Button } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { darken } from '@mui/material/styles';

export const PackagePriceCardRoot = styled(Card)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 337,
}));

export const PackagePriceCardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  paddingBottom: 0,
}));

export const PackagePriceCardCheckIcon = styled(CheckIcon)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(17),
  color: '#000',
  marginTop: 2,
}));

export const PackagePriceCardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  padding: theme.spacing(2),
  minWidth: 0,
  flex: 1,
}));

export const PackagePriceCardActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  paddingTop: 0,
  visibility: 'visible',
}));

export const PackagePriceCardActionsButton = styled(Button)(() => ({
  backgroundColor: '#000',

  '&:hover': {
    backgroundColor: darken('#000', 0.2),
  },
}));

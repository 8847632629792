import { ReactElement, ReactNode, useMemo, useEffect } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { isUndefined, isNull } from 'lodash';

import { useAppSelector, useAppDispatch } from '~hooks/index';
import { selectAuth, resetAuth } from '~store/slices/auth';
import { PATHS } from '~constants/index';
import { Roles, PartnerCompanyTypes } from '~globals/types/enums';
import { validateRoles } from '~utils/user';
import { validatePartnerCompanyType } from '~utils/partnerCompany';

import ComponentWithSuspense from '../ComponentWithSuspense';
import NotAccessPermission from '../NotAccessPermission';

interface ProtectedRouteProps {
  component: ReactNode;
  rolesPermission: Roles[];
  companyTypePermissions: PartnerCompanyTypes[];
  validateCrossCompanyEnabled?: boolean;
}

const ProtectedRoute = ({
  component: componentProp,
  rolesPermission,
  companyTypePermissions,
  validateCrossCompanyEnabled = false,
}: ProtectedRouteProps): ReactElement => {
  const location = useLocation();
  const { token, user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      isUndefined(user?.company.companyTypeId) ||
      isNull(user?.company.companyTypeId)
    ) {
      dispatch(resetAuth());
    }
  }, [user?.company.companyTypeId, dispatch]);

  const component = useMemo(() => {
    const isValidRole = validateRoles(user, rolesPermission);
    const isValidCompanyType = validatePartnerCompanyType(
      user?.company.companyTypeId as PartnerCompanyTypes,
      companyTypePermissions,
    );

    let isEnabledCrossCompany = true;

    if (validateCrossCompanyEnabled) {
      const isGiver = validatePartnerCompanyType(
        user?.company.companyTypeId as PartnerCompanyTypes,
        [PartnerCompanyTypes.Giver],
      );

      isEnabledCrossCompany =
        Boolean(user?.company.crossCompanyEnabled) || isGiver;
    }

    return isValidRole && isValidCompanyType && isEnabledCrossCompany ? (
      componentProp
    ) : (
      <NotAccessPermission />
    );
  }, [
    user,
    rolesPermission,
    companyTypePermissions,
    componentProp,
    validateCrossCompanyEnabled,
  ]);

  if (!token && !user) {
    return (
      <Navigate to={PATHS.AUTH.LOGIN} state={{ from: location }} replace />
    );
  }

  return <ComponentWithSuspense component={component} />;
};

export default ProtectedRoute;

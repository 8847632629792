import { useMemo } from 'react';
import { get } from 'lodash';
import { green, red, yellow } from '@mui/material/colors';

import { RouteItemStateType } from '~globals/types/enums';

interface OrderFinalizedStateInfoItem {
  description: string;
  color: string;
}

type OrderFinalizedStateType =
  | RouteItemStateType.FinalizedSuccess
  | RouteItemStateType.FinalizedError
  | RouteItemStateType.WithoutVisiting;

type OrderFinalizedStateList = {
  [state in OrderFinalizedStateType]: OrderFinalizedStateInfoItem;
};

export const useOrderFinalizedStateInfo = (
  stateId: OrderFinalizedStateType,
): OrderFinalizedStateInfoItem => {
  const stateList = useMemo<OrderFinalizedStateList>(
    () => ({
      [RouteItemStateType.FinalizedSuccess]: {
        description: 'Completa',
        color: green['500'],
      },
      [RouteItemStateType.FinalizedError]: {
        description: 'Incompleta',
        color: red['500'],
      },
      [RouteItemStateType.WithoutVisiting]: {
        description: 'Sin visitar',
        color: yellow['500'],
      },
    }),
    [],
  );

  const currentInfo = useMemo<OrderFinalizedStateInfoItem>(
    () => get(stateList, stateId),
    [stateId, stateList],
  );

  return currentInfo;
};

import { FC } from 'react';
import { Fade } from '@mui/material';

import { useInitialSetupWizardContext } from './InitialSetupWizarContext';
import { InitialSetupWizardStepContainerProps } from './types';
import { getAriaDescribedBy } from './utils';
import {
  InitialSetupWizardModalModalContainer,
  InitialSetupWizardModalModalPaper,
  InitialSetupWizardModalPaperContent,
} from './styles';

const InitialSetupWizardStepContainer: FC<
  InitialSetupWizardStepContainerProps
> = ({ stepId, minHeight, maxWidth, children }) => {
  const {
    step: { currentStep },
    transitionDuration,
  } = useInitialSetupWizardContext();

  return (
    <Fade
      appear
      in={currentStep === stepId}
      timeout={transitionDuration}
      role="presentation"
      unmountOnExit
    >
      <InitialSetupWizardModalModalContainer>
        <InitialSetupWizardModalModalPaper
          elevation={24}
          role="dialog"
          aria-describedby={getAriaDescribedBy(stepId)}
          ownerState={{ minHeight, maxWidth }}
        >
          <InitialSetupWizardModalPaperContent id={getAriaDescribedBy(stepId)}>
            {children}
          </InitialSetupWizardModalPaperContent>
        </InitialSetupWizardModalModalPaper>
      </InitialSetupWizardModalModalContainer>
    </Fade>
  );
};

export default InitialSetupWizardStepContainer;

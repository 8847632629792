import {
  styled,
  Dialog,
  dialogClasses,
  DialogContent,
  Tab,
  Stack,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

export const DialogFeedbackStopContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: 765,
    minHeight: 550,
  },
});

export const DialogFeedbackStopLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  flex: 1,
});

export const DialogFeedbackStopTitle = styled('div')(({ theme }) => ({
  flex: 'none',
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const DialogFeedbackStopContentContainer = styled(DialogContent)(
  ({ theme }) => ({
    padding: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    overflowX: 'hidden',
  }),
);

export const DialogFeedbackStopTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.common.white,
  flex: 1,
  borderRight: `1px solid ${theme.palette.common.white}`,
  maxWidth: '100%',
}));

export const DialogFeedbackStopTabsPanelContainer = styled('div')({
  display: 'flex',
  flex: 1,
});

export const DialogFeedbackStopTabPanel = styled(TabPanel)(({ theme }) => ({
  padding: theme.spacing(3),
  flex: 1,
}));

export const DialogFeedbackStopContentRowItem = styled(Stack)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,

  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(2),
  },
}));

export const DialogFeedbackStopSignImg = styled('img')({
  width: 250,
  height: 100,
});

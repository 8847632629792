import { useRef, memo } from 'react';
import mapboxgl from 'mapbox-gl';
import { useControl, ControlPosition } from 'react-map-gl';
import { controlLocale } from '~constants/mapControlLocale';

import { MapCurrentVehicle } from '~globals/types';

import MapboxGLButtonControl from './MapboxGLButtonControl';

export interface ShowDriverViewControlProps {
  currentDriver: MapCurrentVehicle;
  position?: ControlPosition;
}

export const ShowDriverViewControl = ({
  currentDriver,
  position,
}: ShowDriverViewControlProps): null => {
  const currentDriverRef = useRef(currentDriver);

  useControl(
    ({ map }) =>
      new MapboxGLButtonControl({
        className: 'mapbox-gl-driver',
        title: controlLocale.ShowDriver,
        eventHandler: () => {
          const driverLngLat = new mapboxgl.LngLat(
            currentDriverRef.current.longitude,
            currentDriverRef.current.latitude,
          );
          map.flyTo({ center: driverLngLat });

          const tripbounds = new mapboxgl.LngLatBounds();
          tripbounds.extend(driverLngLat);
          map.fitBounds(tripbounds, { maxZoom: 14 });
        },
      }),
    { position },
  );

  currentDriverRef.current = currentDriver;

  return null;
};

export default memo(ShowDriverViewControl);

import {
  styled,
  Card,
  CardActions,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { NotificationAlertType } from '~globals/types/enums';
import { typeIconVariants } from './utils';

export const AlertSnackbarContentCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: NotificationAlertType }>(({ theme, type }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  boxShadow: theme.shadows[6],
  backgroundColor: typeIconVariants[type].color,
  color: theme.palette.common.white,
}));

export const AlertSnackbarContentCardActions = styled(CardActions)(
  ({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0.75, 2),
    paddingLeft: theme.spacing(2.5),
  }),
);

export const AlertSnackbarContentCardActionsTitle = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  }),
);

export const AlertSnackbarContentCardActionsButtons = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto !important',
    marginRight: theme.spacing(-1),
    paddingLeft: theme.spacing(2),
  }),
);

export const AlertSnackbarContentCardExpandIconWrapper = styled(IconButton)(
  ({ theme }) => ({
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),

    '&.expanded': {
      transform: 'rotate(180deg)',
    },
  }),
);

export const AlertSnackbarContentCollapsePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(2.5),
}));

export const AlertSnackbarContentButtonLink = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'alertType',
})<{ alertType: NotificationAlertType }>(({ theme, alertType }) => {
  const currentColor = typeIconVariants[alertType].color;

  return {
    padding: theme.spacing(0, 1),
    textTransform: 'none',
    backgroundColor: 'transparent',
    border: `1px solid ${alpha(currentColor, 0.5)}`,
    color: currentColor,

    '&:hover': {
      backgroundColor: alpha(currentColor, theme.palette.action.hoverOpacity),
      border: `1px solid ${currentColor}`,
    },
  };
});

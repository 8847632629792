import { ReactElement, useCallback, useEffect } from 'react';
import {
  Typography,
  Stack,
  TextField,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { fill } from 'lodash';

import { useLazyRequest } from '~hooks/index';
import { cancelPartnerItems } from '~services/partnerCompany';
import { getItemValue, hasError } from '~utils/index';

import { DialogCancelPartnerItemsProps } from './types';
import {
  DialogCancelPartnerItemsContainer,
  DialogCancelPartnerItemsTitle,
  DialogCancelPartnerItemsTitleCloseIcon,
  DialogCancelPartnerItemsContent,
} from './styles';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';

const DialogCancelPartnerItems = ({
  open,
  onClose,
  onCancelSuccess,
  partnerCompaniesIds,
}: DialogCancelPartnerItemsProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loading, , executeCancelPartnerItem] = useLazyRequest({
    request: cancelPartnerItems,
    withPostSuccess: () => {
      enqueueSnackbar('Pedidos desasignados correctamente', {
        variant: 'success',
      });

      onCancelSuccess?.();

      onClose();
    },
    withPostFailure: (err) => {
      let errorMessage =
        'No se pudo desasignar los pedidos, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      const rejectedNotes = getItemValue(
        values,
        FIELDS_NAME.REJECTED_NOTE,
        (val) => fill(Array(partnerCompaniesIds.length), val),
        [''],
      );

      await executeCancelPartnerItem({
        itemIds: partnerCompaniesIds,
        rejectedNotes,
      });

      setSubmitting(false);
    },
    [executeCancelPartnerItem, partnerCompaniesIds],
  );

  const {
    resetForm,
    submitForm,
    isSubmitting,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogCancelPartnerItemsContainer open={open} maxWidth="md">
      <DialogCancelPartnerItemsTitle>
        <Typography component="h2" variant="h6">
          Desasignar pedidos
        </Typography>

        <DialogCancelPartnerItemsTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogCancelPartnerItemsTitleCloseIcon>
      </DialogCancelPartnerItemsTitle>

      <DialogCancelPartnerItemsContent dividers>
        <Stack spacing={2}>
          <DialogContentText color="text.secondary" variant="caption">
            Al confirmar esta acción, los pedidos seleccionados dejarán de estar
            vinculados a la empresa de transporte asignada. Por favor, completa
            el siguiente cuadro con el motivo de desasignación para que podamos
            notificar adecuadamente a la empresa relacionada.
          </DialogContentText>

          <TextField
            label="Motivo de desasignación (opcional)"
            fullWidth
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.REJECTED_NOTE)}
            {...getFieldProps(FIELDS_NAME.REJECTED_NOTE)}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogCancelPartnerItemsContent>

      <DialogActions>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={submitForm}
          loading={loading || isSubmitting}
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </DialogCancelPartnerItemsContainer>
  );
};

export default DialogCancelPartnerItems;

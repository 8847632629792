import { ReactElement } from 'react';
import { Stack, DialogContentText, Link, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { isAdminUser } from '~utils/user';
import { getStringifyUrlQueryString } from '~utils/router';
import { PATHS } from '~constants/paths';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { DialogAssignCarrierCompanyLabelImg } from '../../styles';

const DialogAssignCarrierCompanyNotCarriersCompany = (): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  return (
    <Stack spacing={2} flex={1} justifyContent="center" alignItems="center">
      <DialogContentText color="text.primary">
        No se encontraron empresas transportistas asociadas
      </DialogContentText>

      <Stack spacing={2} direction="row" justifyContent="center">
        <DialogAssignCarrierCompanyLabelImg
          src={toAbsoluteUrl('/images/not_import_int.png')}
          alt="Not import integrations"
        />
      </Stack>

      <Stack spacing={2} direction="row">
        {isAdminUser(user) && (
          <Button
            color="primary"
            variant="outlined"
            component={RouterLink}
            to={getStringifyUrlQueryString({
              url: PATHS.CONFIG.PARTNERS_COMPANIES.BASE,
              query: {
                openAssociateModal: true,
              },
            })}
          >
            Asociar una nueva
          </Button>
        )}
      </Stack>

      <DialogContentText variant="caption">
        Para mayor información sobre asignación de empresa de transporte,
        diríjasea este{' '}
        <Link
          href="https://routix.tawk.help/article/asociar-empresas"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          underline="hover"
          fontWeight="bold"
        >
          artículo
        </Link>
      </DialogContentText>
    </Stack>
  );
};

export default DialogAssignCarrierCompanyNotCarriersCompany;

import { styled, CardContent } from '@mui/material';

export const RoutesStatiticsWidgetCardContent = styled(CardContent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  }),
);

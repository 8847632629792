import { toAbsoluteUrl } from '~utils/assetsHelpers';

export const APP_STORES_INFO = {
  PLAY_STORE: {
    URL: 'https://play.google.com/store/apps/details?id=com.routix',
    NAME: 'Play Store',
    IMAGE_URL: toAbsoluteUrl('/icons/icon_google_play.svg'),
  },
  APP_STORE: {
    URL: 'https://apps.apple.com/us/app/routix/id1659539509',
    NAME: 'App Store',
    IMAGE_URL: toAbsoluteUrl('/icons/icon_app_store.svg'),
  },
};

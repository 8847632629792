import { styled, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const OrderFormIconButton = styled(Button)({
  flex: 'none',
});

export const OrderFormIconButtonIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(17),
}));

import {
  ReactElement,
  ChangeEvent,
  useCallback,
  useMemo,
  useRef,
  ElementRef,
} from 'react';
import { useFormik, FormikHelpers } from 'formik';
import {
  Typography,
  Stack,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import Map, {
  NavigationControl,
  FullscreenControl,
  Marker,
  MarkerDragEvent,
} from 'react-map-gl';
import { throttle } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { hasError } from '~utils/formHelpers';
import { useAppSelector } from '~hooks/index';
import { PlaceType } from '~hooks/usePlacesAutocomplete';
import { selectAuth } from '~store/slices/auth/index';
import { TOKEN } from '~utils/mapbox';
import { isOnlyReadUser } from '~utils/user';
import { Marker as MarkerIcon } from '~components/Icons';
import { ModeSateliteControl } from '~components/MapCustomControl';
import { MapMarkerDragHint, NewFeatureBagde } from '~components/index';
import { PATHS } from '~constants/index';

import { CustomerFormProps, CustomerFormValues } from './types';
import { FIELDS_NAME, getInitialValues, validationSchema } from './utils';
import PlacesAutocomplete from '../PlacesAutocomplete';
import { MapContainer, CustomerButtonSubmitButton } from './styles';

const CustomerForm = ({
  data,
  onSubmit,
  loadingSubmit = false,
  redirectToList = true,
  isEdit = false,
}: CustomerFormProps): ReactElement => {
  const navigate = useNavigate();
  const { user } = useAppSelector(selectAuth);

  const isUserOnlyReader = useMemo(() => isOnlyReadUser(user), [user]);

  const refAutocomplete = useRef<ElementRef<typeof PlacesAutocomplete>>(null);

  const getCoordAddres = useMemo(
    () =>
      throttle<google.maps.Geocoder['geocode']>((request, callback) => {
        new window.google.maps.Geocoder().geocode(request, callback);
      }, 200),
    [],
  );

  const executeSubmit = useCallback(
    async (
      values: CustomerFormValues,
      { resetForm, setSubmitting }: FormikHelpers<CustomerFormValues>,
    ) => {
      await onSubmit(values);

      setSubmitting(false);

      if (redirectToList) {
        resetForm();
        refAutocomplete.current?.resetAutocomplete();

        navigate(PATHS.CUSTOMERS.BASE);
      }
    },
    [onSubmit, redirectToList, navigate],
  );

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isSubmitting,
    dirty,
  } = useFormik({
    initialValues: getInitialValues(data, isEdit),
    validationSchema,
    onSubmit: executeSubmit,
  });

  const handleChangeAddress = useCallback(
    (newValue: PlaceType | null) => {
      setFieldValue(FIELDS_NAME.ADDRESS, newValue?.description ?? '');
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, null);

      getCoordAddres({ placeId: newValue?.place_id }, (results) => {
        if (results) {
          const addresCoords = results[0].geometry.location.toJSON();

          setFieldValue(FIELDS_NAME.ADDRESS_COORDS, addresCoords);

          const zipCode = results[0].address_components
            .find((addressComponent) =>
              addressComponent.types.includes('postal_code'),
            )
            ?.long_name.replace(/\D/g, '');

          if (zipCode) {
            setFieldValue(FIELDS_NAME.ZIP_CODE, zipCode);
          }
        }
      });
    },
    [getCoordAddres, setFieldValue],
  );

  const handleBlurAddress = useCallback((): void => {
    setFieldTouched(FIELDS_NAME.ADDRESS, true);
  }, [setFieldTouched]);

  const handleChangeDate = useCallback(
    (fieldName: string) =>
      (value: Date | null): void => {
        setFieldValue(fieldName, value);
      },
    [setFieldValue],
  );

  const handleBlurDate = useCallback(
    (fieldName: string) => (): void => {
      setFieldTouched(fieldName, true);
    },
    [setFieldTouched],
  );

  const isDisabledTimeWindowDates = useMemo(
    () => values[FIELDS_NAME.TIME_WINDOW_ALL_DAY],
    [values],
  );

  const handleChangeTimeWindowAllDay = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      setFieldValue(FIELDS_NAME.TIME_WINDOW_ALL_DAY, checked);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_FROM, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_ONE_TO, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_FROM, null);
      setFieldValue(FIELDS_NAME.TIME_WINDOW_SECOND_TO, null);
    },
    [setFieldValue],
  );

  const handleOnDragEndEvent = useCallback(
    (e: MarkerDragEvent) => {
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
      setFieldTouched(FIELDS_NAME.ADDRESS_COORDS);
    },
    [setFieldValue, setFieldTouched],
  );

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit}>
      <Stack direction="row" spacing={2}>
        <Stack spacing={2} flex={1}>
          <Stack spacing={2}>
            <Typography component="h6" fontWeight="bold">
              Información del contacto frecuente
            </Typography>

            <TextField
              label="Nombre de contacto"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.LEGAL_NAME)}
              {...getFieldProps(FIELDS_NAME.LEGAL_NAME)}
              disabled={isUserOnlyReader}
            />

            <TextField
              label="Código de contacto"
              fullWidth
              autoComplete="off"
              error={hasError(touched, errors, FIELDS_NAME.CODE)}
              {...getFieldProps(FIELDS_NAME.CODE)}
              disabled={isUserOnlyReader}
            />

            <Stack direction="row" spacing={2}>
              <PlacesAutocomplete
                ref={refAutocomplete}
                id="customer-places-autocomplete"
                onChange={(_e, newVal) => handleChangeAddress(newVal)}
                onBlur={handleBlurAddress}
                error={hasError(touched, errors, FIELDS_NAME.ADDRESS)}
                initialValueRequest={data?.address}
                disabled={isUserOnlyReader}
                fullWidth
              />

              <TextField
                label="Código postal (opcional)"
                sx={{ width: '40%' }}
                error={hasError(touched, errors, FIELDS_NAME.ZIP_CODE)}
                {...getFieldProps(FIELDS_NAME.ZIP_CODE)}
                disabled={isUserOnlyReader}
              />
            </Stack>

            <TextField
              label="Notas de dirección (piso, depto, instrucciones)"
              fullWidth
              multiline
              rows={3}
              error={hasError(touched, errors, FIELDS_NAME.ADDRESS_NOTES)}
              {...getFieldProps(FIELDS_NAME.ADDRESS_NOTES)}
              disabled={isUserOnlyReader}
            />

            <TextField
              label="Código de acceso (opcional)"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.ACCESS_CODE)}
              {...getFieldProps(FIELDS_NAME.ACCESS_CODE)}
              disabled={isUserOnlyReader}
            />

            <Stack direction="row" spacing={2}>
              <TextField
                label="Personalizado 1 (opcional)"
                fullWidth
                error={hasError(touched, errors, FIELDS_NAME.CUSTOM_FIELD1)}
                {...getFieldProps(FIELDS_NAME.CUSTOM_FIELD1)}
                disabled={isUserOnlyReader}
              />

              <TextField
                label="Personalizado 2 (opcional)"
                fullWidth
                error={hasError(touched, errors, FIELDS_NAME.CUSTOM_FIELD2)}
                {...getFieldProps(FIELDS_NAME.CUSTOM_FIELD2)}
                disabled={isUserOnlyReader}
              />

              <TextField
                label="Personalizado 3 (opcional)"
                fullWidth
                error={hasError(touched, errors, FIELDS_NAME.CUSTOM_FIELD3)}
                {...getFieldProps(FIELDS_NAME.CUSTOM_FIELD3)}
                disabled={isUserOnlyReader}
              />
            </Stack>
          </Stack>

          <Stack spacing={2}>
            <Typography component="h6" fontWeight="bold">
              Información de contacto receptor
            </Typography>

            <Stack direction="row" spacing={2}>
              <TextField
                label="Nombre completo"
                fullWidth
                error={hasError(touched, errors, FIELDS_NAME.CONTACT_NAME)}
                {...getFieldProps(FIELDS_NAME.CONTACT_NAME)}
                disabled={isUserOnlyReader}
              />

              <TextField
                label="Nro. Documento"
                fullWidth
                error={hasError(
                  touched,
                  errors,
                  FIELDS_NAME.CONTACT_IDENTIFICATION,
                )}
                {...getFieldProps(FIELDS_NAME.CONTACT_IDENTIFICATION)}
                disabled={isUserOnlyReader}
              />
            </Stack>

            <Stack direction="row" spacing={2}>
              <TextField
                label="Teléfono"
                placeholder="Cod de área sin 0 + Nro sin 15"
                fullWidth
                type="tel"
                error={hasError(touched, errors, FIELDS_NAME.CONTACT_PHONE)}
                {...getFieldProps(FIELDS_NAME.CONTACT_PHONE)}
                disabled={isUserOnlyReader}
                helperText="Cod de área sin 0 + Nro sin 15"
              />

              <TextField
                label="Celular / Whatsapp (Opcional)"
                placeholder="Cod de área sin 0 + Nro sin 15"
                fullWidth
                type="tel"
                error={hasError(touched, errors, FIELDS_NAME.CONTACT_MOBILE)}
                {...getFieldProps(FIELDS_NAME.CONTACT_MOBILE)}
                disabled={isUserOnlyReader}
                helperText="Cod de área sin 0 + Nro sin 15"
              />
            </Stack>

            <TextField
              label="Email"
              fullWidth
              error={hasError(touched, errors, FIELDS_NAME.CONTACT_EMAIL)}
              {...getFieldProps(FIELDS_NAME.CONTACT_EMAIL)}
              disabled={isUserOnlyReader}
            />

            <FormControl>
              <FormGroup row sx={{ alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Switch
                      {...getFieldProps(
                        FIELDS_NAME.VALIDATE_CONTACT_IDENTIFICATION,
                      )}
                      checked={
                        values[FIELDS_NAME.VALIDATE_CONTACT_IDENTIFICATION]
                      }
                    />
                  }
                  label="Solicitar nro documento para aprobar la entrega"
                  disabled={isUserOnlyReader}
                  sx={{ margin: 0 }}
                />
              </FormGroup>

              <FormHelperText>
                El chofer deberá validar el DNI del contacto para poder
                completar la entrega. Si no se ingresa el mismo DNI que figura
                en esta carga, no se podrá completar la misma.
              </FormHelperText>
            </FormControl>

            <FormControl>
              <FormGroup row sx={{ alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Switch
                      {...getFieldProps(
                        FIELDS_NAME.VALIDATE_CONTACT_SECURITY_CODE,
                      )}
                      checked={
                        values[FIELDS_NAME.VALIDATE_CONTACT_SECURITY_CODE]
                      }
                    />
                  }
                  label="Solicitar código de entrega para aprobar la misma"
                  disabled={isUserOnlyReader}
                  sx={{ margin: 0 }}
                />

                <NewFeatureBagde activeForVersion="1.14.0" />
              </FormGroup>

              <FormHelperText>
                El chofer deberá validar el código enviado al contacto para
                poder completar la entrega. Si no se ingresa el código correcto,
                no se podrá completar la misma.
              </FormHelperText>
            </FormControl>

            <Stack spacing={2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2" fontWeight="bold">
                  Ventana horaria de visita
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      {...getFieldProps(FIELDS_NAME.TIME_WINDOW_ALL_DAY)}
                      checked={values[FIELDS_NAME.TIME_WINDOW_ALL_DAY]}
                      onChange={handleChangeTimeWindowAllDay}
                    />
                  }
                  label="Todo el día"
                />
              </Stack>

              <Stack direction="row" spacing={8}>
                <Stack direction="row" spacing={1}>
                  <TimePicker
                    label="Desde"
                    value={values[FIELDS_NAME.TIME_WINDOW_ONE_FROM]}
                    onChange={handleChangeDate(
                      FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                    )}
                    disabled={isDisabledTimeWindowDates}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        onBlur={handleBlurDate(
                          FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                        )}
                        error={hasError(
                          touched,
                          errors,
                          FIELDS_NAME.TIME_WINDOW_ONE_FROM,
                        )}
                      />
                    )}
                  />

                  <TimePicker
                    label="Hasta"
                    value={values[FIELDS_NAME.TIME_WINDOW_ONE_TO]}
                    onChange={handleChangeDate(FIELDS_NAME.TIME_WINDOW_ONE_TO)}
                    disabled={isDisabledTimeWindowDates}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        onBlur={handleBlurDate(FIELDS_NAME.TIME_WINDOW_ONE_TO)}
                        error={hasError(
                          touched,
                          errors,
                          FIELDS_NAME.TIME_WINDOW_ONE_TO,
                        )}
                      />
                    )}
                  />
                </Stack>

                <Stack direction="row" spacing={1}>
                  <TimePicker
                    label="Desde"
                    value={values[FIELDS_NAME.TIME_WINDOW_SECOND_FROM]}
                    onChange={handleChangeDate(
                      FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                    )}
                    disabled={isDisabledTimeWindowDates}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        onBlur={handleBlurDate(
                          FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                        )}
                        error={hasError(
                          touched,
                          errors,
                          FIELDS_NAME.TIME_WINDOW_SECOND_FROM,
                        )}
                      />
                    )}
                  />

                  <TimePicker
                    label="Hasta"
                    value={values[FIELDS_NAME.TIME_WINDOW_SECOND_TO]}
                    onChange={handleChangeDate(
                      FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                    )}
                    disabled={isDisabledTimeWindowDates}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        onBlur={handleBlurDate(
                          FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                        )}
                        error={hasError(
                          touched,
                          errors,
                          FIELDS_NAME.TIME_WINDOW_SECOND_TO,
                        )}
                      />
                    )}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={2} flex={0.7}>
          {values[FIELDS_NAME.ADDRESS_COORDS] && (
            <MapContainer>
              <Map
                mapboxAccessToken={TOKEN}
                mapStyle="mapbox://styles/mapbox/light-v10"
                initialViewState={{
                  longitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lng,
                  latitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lat,
                  zoom: 14,
                }}
                attributionControl={false}
              >
                <FullscreenControl position="bottom-left" />
                <NavigationControl position="bottom-left" showCompass={false} />
                <ModeSateliteControl position="bottom-left" />
                <MapMarkerDragHint />

                <Marker
                  longitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lng}
                  latitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lat}
                  onDragEnd={handleOnDragEndEvent}
                  draggable
                  anchor="bottom"
                >
                  <MarkerIcon color="primary" fontSize="large" />
                </Marker>
              </Map>
            </MapContainer>
          )}
        </Stack>
      </Stack>

      {!isUserOnlyReader && (
        <Stack direction="row" spacing={2}>
          <CustomerButtonSubmitButton
            type="submit"
            variant="contained"
            color="primary"
            loading={loadingSubmit || isSubmitting}
            disabled={!dirty}
          >
            Guardar
          </CustomerButtonSubmitButton>
        </Stack>
      )}
    </Stack>
  );
};

export default CustomerForm;

import {
  ReactElement,
  useMemo,
  useCallback,
  memo,
  useRef,
  useEffect,
} from 'react';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { initBrick } from '@mercadopago/sdk-react/bricks/util/renderBrick';
import { useSnackbar } from 'notistack';
import { get } from 'lodash';

import { createMPPreferencePayment } from '~services/subscription/payment';

import { useSubscriptionPaymentModalContext } from '../../SubscriptionPaymentModalContext';

import {
  SubscriptionPaymentModalMPButtonProps,
  SubscriptionPaymentModalMPButtonConfigSetting,
} from './types';
import { SubscriptionPaymentModalMPButtonContainer } from './styles';

const MERCADO_PAGO_PUBLIC_KEY = String(
  process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY,
);

initMercadoPago(MERCADO_PAGO_PUBLIC_KEY, { locale: 'es-AR' });

export const CONTAINER_ID = 'walletBrick_container';
export const DEBOUNCE_TIME_RENDER = 200;

const SubscriptionPaymentModalMPButton = ({
  disabled = false,
}: SubscriptionPaymentModalMPButtonProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    paymentType,
    providersPlansId,
    billingData: { data: billingData },
    contractPlanIdTest,
  } = useSubscriptionPaymentModalContext();

  const contractPlanId = useMemo(
    () =>
      contractPlanIdTest ?? get(providersPlansId, ['mercadopago', paymentType]),
    [contractPlanIdTest, paymentType, providersPlansId],
  );

  const billingDataRef = useRef(billingData);
  billingDataRef.current = billingData;

  const onSubmit = useCallback(() => {
    return new Promise<string>(async (resolve, reject) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (contractPlanId && billingDataRef.current) {
        const response = await createMPPreferencePayment({
          contractPlanId,
          billingData: billingDataRef.current,
        });

        if (response.ok) {
          resolve(response.data?.id ?? '');
        } else {
          reject();

          if (response.data?.data?.message)
            errorMessage = response.data.data.message;

          enqueueSnackbar(errorMessage, {
            variant: 'error',
          });
        }
      } else {
        reject();

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }
    });
  }, [contractPlanId, enqueueSnackbar]);

  useEffect(() => {
    const brickSettings: SubscriptionPaymentModalMPButtonConfigSetting = {
      initialization: {
        redirectMode: 'modal',
      },
      customization: {
        visual: {
          hideValueProp: true,
          borderRadius: '4px',
          buttonBackground: 'default',
        },
      },
      locale: 'es-AR',
      callbacks: {
        onReady: () => {},
        onError: (err) => console.log(err),
        onSubmit,
      },
    };

    const timer = setTimeout(() => {
      initBrick({
        settings: brickSettings,
        name: 'wallet',
        divId: CONTAINER_ID,
        controller: 'walletBrickController',
      });
    }, DEBOUNCE_TIME_RENDER);

    return () => {
      clearTimeout(timer);
      // @ts-expect-error: Let's ignore a compile error like this unreachable code
      window.walletBrickController?.unmount();
    };
  }, [onSubmit]);

  return (
    <SubscriptionPaymentModalMPButtonContainer
      id={CONTAINER_ID}
      disabled={disabled}
    />
  );
};

export default memo(SubscriptionPaymentModalMPButton);

import { addMethod, string } from 'yup';

import { validPhone } from '~utils/yupValidation';

declare module 'yup' {
  interface StringSchema {
    phone(message?: string): StringSchema;
  }
}

addMethod(string, 'phone', validPhone);

import {
  Dispatch,
  SetStateAction,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { throttle } from 'lodash';

import { Countries } from '~globals/types/enums';
import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';

type GoogleAutocompleteService = google.maps.places.AutocompleteService;

export type PlaceType = google.maps.places.AutocompletePrediction;

export interface PlacesAutocompleteReturn {
  value: PlaceType | null;
  setValue: Dispatch<SetStateAction<PlaceType | null>>;
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  options: readonly PlaceType[];
  setOptions: Dispatch<SetStateAction<readonly PlaceType[]>>;
}

const availableCountries: Record<Countries, string> = {
  [Countries.Argentina]: 'AR',
  [Countries.Brazil]: 'BR',
  [Countries.Uruguay]: 'UY',
  [Countries.Peru]: 'PE',
  [Countries.Chile]: 'CL',
  [Countries.Mexico]: 'MX',
  [Countries.Ecuador]: 'EC',
  [Countries.Paraguay]: 'PY',
  [Countries.Colombia]: 'CO',
};

export const usePlacesAutocomplete = (
  initialValueRequest?: string,
): PlacesAutocompleteReturn => {
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const autocompleteServicesRef = useRef<GoogleAutocompleteService | null>(
    null,
  );

  const { user } = useAppSelector(selectAuth);

  const selectedAvailableCountry = useMemo(() => {
    const userCompanyCountryId = user?.company.countryId ?? Countries.Argentina;

    return availableCountries[userCompanyCountryId];
  }, [user]);

  const fetch = useMemo(
    () =>
      throttle<GoogleAutocompleteService['getPlacePredictions']>(
        (request, callback) => {
          autocompleteServicesRef.current?.getPlacePredictions(
            {
              ...request,
              componentRestrictions: {
                country: selectedAvailableCountry,
              },
            },
            callback,
          );
        },
        200,
      ),
    [selectedAvailableCountry],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteServicesRef.current && window.google) {
      autocompleteServicesRef.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteServicesRef.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (initialValueRequest) {
      setInputValue(initialValueRequest);
      fetch({ input: initialValueRequest }, (results) => {
        let newOptions: readonly PlaceType[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
          setValue(results[0]);
        }

        setOptions(newOptions);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValueRequest]);

  return {
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setOptions,
  };
};

import { ElementType } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import RangeTimePicker from '../RangeTimePicker';
import CustomAutocomplete from '../CustomAutocomplete';
import TimeWindowPicker from '../TimeWindowPicker';

export const COMPONENTS_LIST = {
  Input: TextField,
  RangeTimePicker,
  DatePicker,
  Autocomplete: CustomAutocomplete,
  TimeWindowPicker,
};

export const getComponent = (
  name: keyof typeof COMPONENTS_LIST,
): ElementType | undefined => COMPONENTS_LIST[name] ?? undefined;

import { ReactElement, useMemo } from 'react';

import { ItemGeoExtended } from '~services/item/types';
import { CustomerGeoExtended } from '~services/customer/types';
import { Table, OrderClientInfo } from '~components/index';
import { TableColumn } from '~components/Table/types';

import {
  SelectRowsHandlerOnSelectAll,
  SelectRowsHandlerOnSelect,
} from '~hooks/useDataTableSelectedProps';

import { GeoErrorCommonProps } from '../../types';

import GeoErrorUpateDialogPlacesAutocomplete from '../PlaceAutcomplete';
import { GeoErrorUpateDialogData } from '../types';
import { getExtractorKey } from '../utils';

interface GeoErrorUpateDialogTableProps extends GeoErrorCommonProps {
  data: GeoErrorUpateDialogData[];
  selectable: {
    selectedIds: Array<number | string>;
    onSelectAll: SelectRowsHandlerOnSelectAll<GeoErrorUpateDialogData>;
    onSelect: SelectRowsHandlerOnSelect<GeoErrorUpateDialogData>;
  };
}

const GeoErrorUpateDialogTable = ({
  type,
  data,
  selectable,
}: GeoErrorUpateDialogTableProps): ReactElement => {
  const columnsItems = useMemo<TableColumn<ItemGeoExtended>[]>(
    () => [
      {
        dataField: 'title',
        text: 'Nombre de contacto',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (_cell, row) => (
            <OrderClientInfo name={row.title} trackingCode={row.trackingCode} />
          ),
        },
        style: {
          header: { width: 200 },
        },
      },
      {
        dataField: 'originalAddress',
        text: 'Dirección original',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (cell) => <span>{cell}</span>,
        },
      },
      {
        dataField: 'address',
        text: 'Dirección geodificada',
        formatters: {
          cell: (_cell, _row, rowIndex) => (
            <GeoErrorUpateDialogPlacesAutocomplete fieldIndex={rowIndex} />
          ),
        },
        style: {
          header: { width: 500 },
        },
      },
    ],
    [],
  );

  const columnsCustomers = useMemo<TableColumn<CustomerGeoExtended>[]>(
    () => [
      {
        dataField: 'legalName',
        text: 'Nombre',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (cell) => <span>{cell}</span>,
        },
        style: {
          header: { width: 200 },
        },
      },
      {
        dataField: 'originalAddress',
        text: 'Dirección original',
        classes: {
          cell: 'truncated-two-lines',
        },
        formatters: {
          cell: (cell) => <span>{cell}</span>,
        },
      },
      {
        dataField: 'address',
        text: 'Dirección geodificada',
        formatters: {
          cell: (_cell, _row, rowIndex) => (
            <GeoErrorUpateDialogPlacesAutocomplete fieldIndex={rowIndex} />
          ),
        },
        style: {
          header: { width: 500 },
        },
      },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      (type === 'customer'
        ? columnsCustomers
        : columnsItems) as TableColumn<GeoErrorUpateDialogData>[],
    [columnsCustomers, columnsItems, type],
  );

  return (
    <Table
      data={data}
      columns={columns}
      stickyHeader={{ enabled: true, maxHeight: 440 }}
      selectable={selectable}
      extractorKeyValue={getExtractorKey(type)}
    />
  );
};

export default GeoErrorUpateDialogTable;

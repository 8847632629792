import { ChangeEvent, useCallback } from 'react';
import { TableSelectable, TableValidRow } from '../types';

interface UseTableBodySelectableReturn {
  getCheckBoxProps: (
    row: TableValidRow,
    rowIndex: number,
  ) => {
    checked: boolean;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
  };
}

export const useTableBodySelectable = (
  extractorKeyValue: string,
  selectable: TableSelectable<TableValidRow>,
): UseTableBodySelectableReturn => {
  const { selectedIds, onSelect, nonSelectableIds } = selectable;

  const getCheckboxChecked = useCallback(
    (row: TableValidRow) => selectedIds.includes(row[extractorKeyValue]),
    [selectedIds, extractorKeyValue],
  );

  const getCheckboxDisabled = useCallback(
    (row: TableValidRow) =>
      (nonSelectableIds ?? []).includes(row[extractorKeyValue]),
    [nonSelectableIds, extractorKeyValue],
  );

  const getCheckoxOnChange = useCallback(
    (row: TableValidRow, rowIndex: number) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        onSelect(row, event.target.checked, rowIndex, event);
      },
    [onSelect],
  );

  const getCheckBoxProps = useCallback(
    (row: TableValidRow, rowIndex: number) => ({
      checked: getCheckboxChecked(row),
      onChange: getCheckoxOnChange(row, rowIndex),
      disabled: getCheckboxDisabled(row),
    }),
    [getCheckboxChecked, getCheckboxDisabled, getCheckoxOnChange],
  );

  return {
    getCheckBoxProps,
  };
};

import API from '~config/api';
import {
  ApiPromise,
  DataPaginateResponse,
  DataResponse,
  ErrorResponse,
} from '~globals/types';

import {
  AdminIntegration,
  AdminIntegrationAuthLink,
  AdminIntegrationCreate,
  AddAdminIntegrationByEmail,
} from './types';

export const ADMIN_INTEGRATIONS_ENPOINTS = {
  getAdminIntegrations: '/api/v1/Admin/CompanyIntegration',
  addAdminIntegration: '/api/v1/Admin/CompanyIntegration',
  sendRequestAdminIntegrationByEmail: '/api/v1/integration/SendRequestByEmail',
  deleteAdminIntegration: (integrationId: string): string =>
    `/api/v1/Admin/CompanyIntegration/${integrationId}`,
};

export const getAdminIntegrations = (): ApiPromise<
  DataPaginateResponse<AdminIntegration[]>,
  ErrorResponse
> =>
  API.get<DataPaginateResponse<AdminIntegration[]>, ErrorResponse>(
    ADMIN_INTEGRATIONS_ENPOINTS.getAdminIntegrations,
  );

export const addAdminIntegration = ({
  integrationTypeId,
  description,
  url,
}: AdminIntegrationCreate): ApiPromise<
  DataResponse<AdminIntegrationAuthLink>,
  ErrorResponse
> =>
  API.post<DataResponse<AdminIntegrationAuthLink>, ErrorResponse>(
    ADMIN_INTEGRATIONS_ENPOINTS.addAdminIntegration,
    { integrationTypeId, description, url },
  );

export const sendRequestAdminIntegrationByEmail = ({
  integrationTypeId,
  email,
  description,
  url,
}: AddAdminIntegrationByEmail): ApiPromise<
  DataResponse<AdminIntegrationAuthLink>,
  ErrorResponse
> =>
  API.post<DataResponse<AdminIntegrationAuthLink>, ErrorResponse>(
    ADMIN_INTEGRATIONS_ENPOINTS.sendRequestAdminIntegrationByEmail,
    { integrationTypeId, email, description, url },
  );

export const deleteAdminIntegration = (
  integrationId: string,
): ApiPromise<DataResponse<AdminIntegration>, ErrorResponse> =>
  API.delete<DataResponse<AdminIntegration>, ErrorResponse>(
    ADMIN_INTEGRATIONS_ENPOINTS.deleteAdminIntegration(integrationId),
  );

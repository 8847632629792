import { ReactElement, ElementType, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { TabContext } from '@mui/lab';

import { DialogAssociatePartnerCompanyProps } from './types';
import DialogAssociatePartnerCompanyContexProvider, {
  useDialogAssociatePartnerCompanyContex,
  DialogAssociatePartnerCompanyContextPanelsId,
} from './DialogAssociatePartnerCompanyContext';
import {
  DialogAssociatePartnerCompanyAssociation,
  DialogAssociatePartnerCompanySendInvitation,
} from './Tabs';
import {
  DialogAssociatePartnerCompanyContainer,
  DialogAssociatePartnerCompanyTitle,
  DialogAssociatePartnerCompanyTitleCloseIcon,
  DialogAssociatePartnerCompanyTabPanel,
} from './styles';

interface DialogAssociatePartnerCompanyTabPanelItem {
  id: DialogAssociatePartnerCompanyContextPanelsId;
  panel: ElementType;
}

const TABS_PANELS: DialogAssociatePartnerCompanyTabPanelItem[] = [
  {
    id: 'association',
    panel: DialogAssociatePartnerCompanyAssociation,
  },
  { id: 'sendInvitation', panel: DialogAssociatePartnerCompanySendInvitation },
];

const DialogAssociatePartnerCompany = (): ReactElement => {
  const { open, onClose, activeTab, setActiveTab, setAssociateData } =
    useDialogAssociatePartnerCompanyContex();

  useEffect(() => {
    if (!open) {
      setActiveTab('association');
      setAssociateData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogAssociatePartnerCompanyContainer open={open} maxWidth="md">
      <DialogAssociatePartnerCompanyTitle>
        <Typography component="h2" variant="h6">
          Asociar una empresa
        </Typography>

        <DialogAssociatePartnerCompanyTitleCloseIcon
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </DialogAssociatePartnerCompanyTitleCloseIcon>
      </DialogAssociatePartnerCompanyTitle>

      <TabContext value={activeTab}>
        {TABS_PANELS.map(({ id, panel: Panel }) => (
          <DialogAssociatePartnerCompanyTabPanel
            key={`dialog-associate-partner-company-panel-${id}`}
            value={id}
          >
            <Panel />
          </DialogAssociatePartnerCompanyTabPanel>
        ))}
      </TabContext>
    </DialogAssociatePartnerCompanyContainer>
  );
};

const WrapperDialogAssociatePartnerCompany = ({
  open,
  onClose,
  onAssociate,
  onSendInvitation,
  onOpenDialogInvitationCompany,
}: DialogAssociatePartnerCompanyProps): ReactElement => (
  <DialogAssociatePartnerCompanyContexProvider
    open={open}
    onClose={onClose}
    onAssociate={onAssociate}
    onSendInvitation={onSendInvitation}
    onOpenDialogInvitationCompany={onOpenDialogInvitationCompany}
  >
    <DialogAssociatePartnerCompany />
  </DialogAssociatePartnerCompanyContexProvider>
);

export default WrapperDialogAssociatePartnerCompany;

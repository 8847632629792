import { FC, createContext, useContext } from 'react';

import { GenerateRouteCompletedTabsContextValue } from './types';

const GenerateRouteCompletedTabsContext =
  createContext<GenerateRouteCompletedTabsContextValue>({
    workProgressId: 1,
    indicators: null,
    errors: [],
    totalStops: 0,
    totalVehicles: 0,
    loadingIndicators: true,
    setActiveTab: () => {},
    type: 'generate',
  });

export const useGenerateRouteCompletedTabsContext =
  (): GenerateRouteCompletedTabsContextValue =>
    useContext(GenerateRouteCompletedTabsContext);

const GenerateRouteCompletedTabsProvider: FC<{
  value: GenerateRouteCompletedTabsContextValue;
}> = ({ children, value }) => (
  <GenerateRouteCompletedTabsContext.Provider value={value}>
    {children}
  </GenerateRouteCompletedTabsContext.Provider>
);

export default GenerateRouteCompletedTabsProvider;

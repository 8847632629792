import * as Yup from 'yup';

import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  ASSOCIATED_COMPANY_ID: 'associatedCompanyId',
  SEARCH: 'search',
} as const;

export const initialValues = {
  [FIELDS_NAME.ASSOCIATED_COMPANY_ID]: '',
  [FIELDS_NAME.SEARCH]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ASSOCIATED_COMPANY_ID]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.SEARCH]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
});

import { Color } from 'mui-color';

export const getCssHexa = (n: number, alpha?: number): string => {
  let hex = `00000000${(n & 0xffffff).toString(16).toUpperCase()}`.substr(-6);

  if (!Number.isNaN(alpha) && alpha !== undefined) {
    let a = alpha.toString().toUpperCase();
    if (a.length === 1) a = `0${a}`;
    hex += a;
  }

  return hex;
};

export const getCssColor = (
  color: Color,
  format: string,
  disableAlpha?: boolean,
): string => {
  let value = '';

  if (format === 'hex') {
    value = `#${getCssHexa(
      color.value,
      disableAlpha || color.alpha === 1
        ? undefined
        : Math.floor(color.alpha * 255),
    )}`;
  }

  return value;
};

export const getColorText = (
  color: Color,
  disablePlainColor: boolean,
): string => {
  if (color.name === 'none') return '';

  let text = disablePlainColor ? `color-${color.hex}` : color.name;

  if (text.startsWith('color-')) {
    if (typeof color.raw !== 'string' || !color.raw.startsWith('#')) {
      text = getCssColor(color, 'hex');
    } else {
      text = color.raw;
    }
  } else if (text === 'none') {
    text = color.raw as string;
  }
  return text;
};

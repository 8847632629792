import type { ReactElement } from 'react';
import { Suspense } from 'react';

import type { IComponentWithSuspenseProps } from './types';
import LoadingFallback from '../LoadingFallback';

const ComponentWithSuspense = ({
  component: Component,
}: IComponentWithSuspenseProps): ReactElement | null => (
  <Suspense fallback={<LoadingFallback />}>{Component}</Suspense>
);

export default ComponentWithSuspense;

import { DependencyList, useEffect } from 'react';

import {
  AsyncRequestHooksParams,
  AsyncRequestHooksReturn,
  useLazyRequest,
} from './useLazyRequest';
import { useMountedState } from './useMountedState';

export interface AsyncRequestHooksParamsWithPayload<P, D, E>
  extends AsyncRequestHooksParams<P, D, E> {
  payload: P;
  isValidToRequest?: boolean;
}

export const useRequest = <P, D, E>(
  {
    request,
    payload,
    initialState = null,
    initialLoading = true,
    transformResponse = (response) => response,
    withPostPrefetch,
    withPostSuccess,
    withPostFailure,
    withPostFetch,
    isValidToRequest = true,
  }: AsyncRequestHooksParamsWithPayload<P, D, E>,
  deps: DependencyList = [],
): Omit<AsyncRequestHooksReturn<P, D, E>, '5'> => {
  const isMounted = useMountedState();

  const [state, loading, error, sendRequest, cancelOutstandingRequest] =
    useLazyRequest({
      request,
      initialState,
      initialLoading,
      transformResponse,
      withPostPrefetch,
      withPostSuccess,
      withPostFailure,
      withPostFetch,
    });

  useEffect(() => {
    if (isMounted() && isValidToRequest) {
      sendRequest(payload);
    }

    return cancelOutstandingRequest;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, isMounted, isValidToRequest, cancelOutstandingRequest]);

  return [state, loading, error, sendRequest];
};

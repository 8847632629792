import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { updateAdminCompanySetting } from '~services/admin/companySetting';
import {
  AdminCompanySetting,
  AdminCompanySettingUpdate,
} from '~services/admin/companySetting/types';

import { useLazyRequest } from './useLazyRequest';

export const useCompanySettingsAdminUpdate = (
  onSuccessUpdate?: (settings: AdminCompanySetting) => void,
): {
  loading: boolean;
  handleUpdateCompanySetting: (
    data: AdminCompanySettingUpdate,
  ) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingUpadteCompanySetting, , executeUpdateCompanySetting] =
    useLazyRequest({
      request: updateAdminCompanySetting,
      withPostSuccess: (response) => {
        const message = 'Preferencias actualizadas correctamente';

        enqueueSnackbar(message, { variant: 'success' });

        onSuccessUpdate?.(response.data?.data as AdminCompanySetting);
      },
      withPostFailure: () => {
        const errorMessage =
          'No se ha podido actualizar la preferencias, intente nuevamente';

        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
    });

  const handleUpdateCompanySetting = useCallback(
    async (data: AdminCompanySettingUpdate) => {
      await executeUpdateCompanySetting(data);
    },
    [executeUpdateCompanySetting],
  );

  return { loading: loadingUpadteCompanySetting, handleUpdateCompanySetting };
};

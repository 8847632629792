import { ReactElement, useMemo, memo } from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen as StopsIcon } from '@fortawesome/free-solid-svg-icons';

import { StopsProgressInfoProps } from './types';
import {
  StopsProgressInfoContainer,
  StopsProgressInfoIcon,
  StopsProgressInfoLinearProgress,
} from './styles';

const StopsProgressInfo = ({
  min = 0,
  max = 100,
  value,
  fontSize = 14,
  enabledIcon = false,
}: StopsProgressInfoProps): ReactElement => {
  const normaliseValue = useMemo(
    () => ((value - min) * 100) / (max - min),
    [value, min, max],
  );

  const isComplete = useMemo(() => value >= max, [value, max]);

  return (
    <StopsProgressInfoContainer size={fontSize}>
      {enabledIcon && (
        <StopsProgressInfoIcon size={fontSize}>
          <FontAwesomeIcon icon={StopsIcon} />
        </StopsProgressInfoIcon>
      )}

      <StopsProgressInfoLinearProgress
        variant="determinate"
        value={normaliseValue}
        color={isComplete ? 'success' : 'secondary'}
        size={fontSize}
      />

      <Typography variant="inherit" component="span">
        {`${value} de ${max}`}
      </Typography>
    </StopsProgressInfoContainer>
  );
};

export default memo(StopsProgressInfo);

import { ReactElement, useCallback, useMemo, memo } from 'react';
import { round, toString, isUndefined, isNaN } from 'lodash';

import { formatterTime } from '~utils/formatter';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { CapabilitiesInfoProps } from './types';
import { CapabilitiesInfoContainer, CapabilitiesInfoItemImg } from './styles';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const CapabilitiesInfo = ({
  capacity1,
  capacity2,
  maximumVisits,
  timeWindow,
  numberOfPackages,
  fontSize = 14,
  fontWeight,
  autoFlow = 'column',
  rows = 2,
  columns = 2,
  className,
}: CapabilitiesInfoProps): ReactElement => {
  const roundCapacity = useCallback((capacity: number): string => {
    const capacityRounded = round(capacity, 2);

    return toString(capacityRounded);
  }, []);

  const parseWindowTimes = useCallback(
    (from: string, to: string): string =>
      `${formatterTime(from)} a ${formatterTime(to)}`,
    [],
  );

  const timeWindowTexts = useMemo(() => {
    if (
      !timeWindow?.allDay &&
      !timeWindow?.timeFrom &&
      !timeWindow?.timeTo &&
      !timeWindow?.timeFrom2 &&
      !timeWindow?.timeTo2
    )
      return null;

    if (timeWindow?.allDay) return 'Todo el día';

    const times: string[] = [];

    if (timeWindow.timeFrom && timeWindow.timeTo)
      times.push(parseWindowTimes(timeWindow.timeFrom, timeWindow.timeTo));

    if (timeWindow.timeFrom2 && timeWindow.timeTo2)
      times.push(parseWindowTimes(timeWindow.timeFrom2, timeWindow.timeTo2));

    return times;
  }, [parseWindowTimes, timeWindow]);

  return (
    <CapabilitiesInfoContainer
      ownerState={{ autoFlow, rows, columns }}
      className={className}
    >
      <DescriptionWithIconBox
        icon={
          <CapabilitiesInfoItemImg
            src={toAbsoluteUrl('/icons/icon_capacity_1.svg')}
            alt="Capacity One Icon"
          />
        }
        description={roundCapacity(capacity1)}
        size={fontSize}
        iconTooltip="Carga primaria (En KG, L, dinero, bultos)"
        fontWeight={fontWeight}
      />

      <DescriptionWithIconBox
        icon={
          <CapabilitiesInfoItemImg
            src={toAbsoluteUrl('/icons/icon_capacity_2.svg')}
            alt="Capacity Two Icon"
          />
        }
        description={roundCapacity(capacity2)}
        size={fontSize}
        iconTooltip="Carga secundaria (En KG, L, dinero, bultos)"
        fontWeight={fontWeight}
      />

      {maximumVisits && (
        <DescriptionWithIconBox
          icon={
            <CapabilitiesInfoItemImg
              src={toAbsoluteUrl('/icons/icon_max_capacity.svg')}
              alt="Max Capacity Icon"
            />
          }
          description={toString(maximumVisits)}
          size={fontSize}
          iconTooltip="Paradas máximas"
          fontWeight={fontWeight}
        />
      )}

      {timeWindowTexts && (
        <DescriptionWithIconBox
          icon={
            <CapabilitiesInfoItemImg
              src={toAbsoluteUrl('/icons/icon_time_vehicle.svg')}
              alt="Time Vehicle Icon"
            />
          }
          description={
            Array.isArray(timeWindowTexts)
              ? timeWindowTexts[0]
              : timeWindowTexts
          }
          caption={
            Array.isArray(timeWindowTexts) && timeWindowTexts.length > 1
              ? timeWindowTexts[1]
              : undefined
          }
          size={fontSize}
          iconTooltip="Ventana horaria de disponibilidad"
          fontWeight={fontWeight}
        />
      )}

      {!isUndefined(numberOfPackages) && !isNaN(numberOfPackages) && (
        <DescriptionWithIconBox
          icon={
            <CapabilitiesInfoItemImg
              src={toAbsoluteUrl('/icons/icon_bulk.svg')}
              alt="Number Of Packages Icon"
            />
          }
          description={toString(numberOfPackages)}
          size={fontSize}
          iconTooltip="Cantidad de bultos"
          fontWeight={fontWeight}
        />
      )}
    </CapabilitiesInfoContainer>
  );
};

export default memo(CapabilitiesInfo);

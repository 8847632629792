import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { deleteAdminVehicle } from '~services/admin/vehicle';

import { useLazyRequest } from './useLazyRequest';

export const useVehicleAdminDelete = (
  onSuccessDelete?: () => void,
): {
  loading: boolean;
  handleDeleteVehicle: (vehicleId: string) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const [, loadingDeleteVehicle, , executeDeleteVehicle] = useLazyRequest({
    request: deleteAdminVehicle,
    withPostSuccess: (response) => {
      const vehicleName = response.data?.data.name;
      const message = `El vehículo ${vehicleName} fue eliminado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      onSuccessDelete?.();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo elimnar el vehículo, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleDeleteVehicle = useCallback(
    async (vehicleId: string) => {
      await executeDeleteVehicle(vehicleId);
    },
    [executeDeleteVehicle],
  );

  return { loading: loadingDeleteVehicle, handleDeleteVehicle };
};

import { styled, Box, Card } from '@mui/material';

export const DailyStatiticsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
}));

export const DailyStatiticsCardItem = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: '30px 20px',
  minWidth: 130,
}));

export const DailyStatiticsCardItemImgIcon = styled('img')({
  height: 60,
  width: 90,
});

import { ReactElement } from 'react';
import { Stack, Typography } from '@mui/material';

import { useAppSelector } from '~hooks/index';
import { selectAuth } from '~store/slices/auth/index';
import { getInitialsNameLetterUpperCase } from '~utils/user';

import { InitialSetupWizardStepTitleWithProgressProps } from './types';
import {
  InitialSetupWizardStepTitleWithProgressProggressContainer,
  InitialSetupWizardStepTitleWithProgressProggressFull,
  InitialSetupWizardStepTitleWithProgressProggressDynamic,
  InitialSetupWizardStepTitleWithProgressProggressAvatar,
} from './styles';

const progressInfo = {
  size: 75,
  thickness: {
    full: 2,
    dynamic: 4,
  },
};

const InitialSetupWizardStepTitleWithProgress = ({
  progress,
  title,
  description,
}: InitialSetupWizardStepTitleWithProgressProps): ReactElement => {
  const { user } = useAppSelector(selectAuth);

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start" width="100%">
      <InitialSetupWizardStepTitleWithProgressProggressContainer>
        <InitialSetupWizardStepTitleWithProgressProggressFull
          variant="determinate"
          value={100}
          size={progressInfo.size}
          thickness={progressInfo.thickness.full}
          color="inherit"
        />

        <InitialSetupWizardStepTitleWithProgressProggressDynamic
          variant="determinate"
          value={progress}
          size={progressInfo.size + progressInfo.thickness.dynamic}
          thickness={progressInfo.thickness.dynamic}
          color="primary"
        />

        <InitialSetupWizardStepTitleWithProgressProggressAvatar
          alt={user?.company.name}
          src={user?.company.logoFile?.url}
        >
          {getInitialsNameLetterUpperCase(user?.company.name as string)}
        </InitialSetupWizardStepTitleWithProgressProggressAvatar>
      </InitialSetupWizardStepTitleWithProgressProggressContainer>

      <Stack spacing={0.5}>
        <Typography component="h4" variant="h5" fontWeight="bold">
          {title}
        </Typography>

        <Typography component="p" variant="body1" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InitialSetupWizardStepTitleWithProgress;

import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

const HomeIcon = (props: SvgIconProps): ReactElement => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M15.44,29.14H7.18a2.87,2.87,0,0,1-2.93-2.26,3.39,3.39,0,0,1-.08-.78q0-4.05,0-8.1a1,1,0,0,1,1-1.11,1,1,0,0,1,.9.86c0,.12,0,.24,0,.37V26c0,.85.34,1.19,1.19,1.19H23.65c.82,0,1.17-.35,1.17-1.18V18a1,1,0,1,1,1.93-.09c0,.32,0,.65,0,1q0,3.65,0,7.29A2.88,2.88,0,0,1,24.62,29a4.12,4.12,0,0,1-1,.11H15.44"
    />
    <path
      // eslint-disable-next-line max-len
      d="M15.54,1.4a2.86,2.86,0,0,1,2,.89L19,3.76Q24,8.7,28.89,13.63a1.67,1.67,0,0,1,.41.67.9.9,0,0,1-.48,1,.94.94,0,0,1-1.12-.15c-.45-.43-.89-.88-1.33-1.32l-10-10.06a1,1,0,0,0-1.73,0L3.38,15a1.51,1.51,0,0,1-.75.39.81.81,0,0,1-.9-.47.94.94,0,0,1,.07-1.05c.06-.08.14-.15.21-.23L13.27,2.37a3.07,3.07,0,0,1,2.27-1"
    />
  </SvgIcon>
);

export default HomeIcon;

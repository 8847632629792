import * as Yup from 'yup';

import { FileObj } from '~globals/types';
import { VALIDATIONS_ERROR } from '~constants/index';

export const FIELDS_NAME = {
  FILE: 'file',
  NOTES: 'notes',
} as const;

export const initialValues = {
  [FIELDS_NAME.FILE]: [] as FileObj[],
  [FIELDS_NAME.NOTES]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.FILE]: Yup.array()
    .of(Yup.mixed())
    .min(1, VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.NOTES]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
});

import * as Yup from 'yup';
import moment from 'moment';

import { VALIDATIONS_ERROR } from '~constants/index';
import { AdminVehicle } from '~services/admin/vehicle/types';
import { Nullable } from '~globals/types';
import { formatterTime } from '~utils/index';
import { parseSkillsStringToArray } from '~utils/vehicle';
import { getRandomColor } from '~utils/commons';
import { VehicleType } from '~services/vehicle/types';

export const FIELDS_NAME = {
  ENABLED: 'enabled',
  NAME: 'name',
  COLOR: 'color',
  REFERENCE_CODE: 'referenceCode',
  MAXIMUN_VISITS: 'maximunVisits',
  TYPE: 'type',
  AVERAGE_CONSUPTION: 'averageConsuption',
  CAPACITY_ONE: 'capacityOne',
  CAPACITY_TWO: 'capacityTwo',
  TIME_FROM: 'timeFrom',
  TIME_TO: 'timeTo',
  SKILLS_LIST: 'skillsList',
} as const;

const parseTimeWindowValue = (value: Nullable<string>): Nullable<Date> =>
  value
    ? new Date(formatterTime(value, moment.HTML5_FMT.DATETIME_LOCAL_MS))
    : null;

const defaultVehicleType: VehicleType = {
  description: 'Vehículo genérico',
  fuelConsuptiom: 9.9,
  vehicleTypeId: 0,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getInitialValues = (
  type: 'create' | 'update',
  data?: Nullable<AdminVehicle>,
) => {
  const defaultFuelConsumption =
    type === 'create' ? defaultVehicleType.fuelConsuptiom : '';

  return {
    [FIELDS_NAME.ENABLED]: data?.enabled ?? true,
    [FIELDS_NAME.NAME]: data?.name ?? '',
    [FIELDS_NAME.COLOR]: data?.color ?? getRandomColor(),
    [FIELDS_NAME.REFERENCE_CODE]: data?.referenceCode ?? '',
    [FIELDS_NAME.MAXIMUN_VISITS]: data?.maximumVisits ?? '30',
    [FIELDS_NAME.TYPE]: String(
      data?.vehicleTypeId ?? defaultVehicleType.vehicleTypeId,
    ),
    [FIELDS_NAME.AVERAGE_CONSUPTION]: String(
      data?.fuelConsuptiom ?? defaultFuelConsumption,
    ),
    [FIELDS_NAME.CAPACITY_ONE]: data?.capacity1 ?? 1000,
    [FIELDS_NAME.CAPACITY_TWO]: data?.capacity2 ?? 1000,
    [FIELDS_NAME.TIME_FROM]: parseTimeWindowValue(data?.timeFrom ?? '06:00:00'),
    [FIELDS_NAME.TIME_TO]: parseTimeWindowValue(data?.timeTo ?? '22:00:00'),
    [FIELDS_NAME.SKILLS_LIST]: parseSkillsStringToArray(data?.skillsList),
  };
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.ENABLED]: Yup.boolean(),
  [FIELDS_NAME.NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.COLOR]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.REFERENCE_CODE]: Yup.string().notRequired(),
  [FIELDS_NAME.MAXIMUN_VISITS]: Yup.string().required(
    VALIDATIONS_ERROR.REQUIRED,
  ),
  [FIELDS_NAME.TYPE]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.AVERAGE_CONSUPTION]: Yup.string().notRequired(),
  [FIELDS_NAME.CAPACITY_ONE]: Yup.number()
    .min(1, VALIDATIONS_ERROR.MIN(1))
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.CAPACITY_TWO]: Yup.number()
    .moreThan(0, VALIDATIONS_ERROR.MORE_THAN(0))
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.TIME_FROM]: Yup.date()
    .nullable()
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.TIME_TO]: Yup.date()
    .nullable()
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.SKILLS_LIST]: Yup.array().of(Yup.string()),
});

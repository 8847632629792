import {
  styled,
  Dialog,
  dialogClasses,
  dialogContentClasses,
  Box,
  Avatar,
  DialogActions,
} from '@mui/material';

export const DialogRouteAssignmentContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
  },

  [`& .${dialogContentClasses.dividers}:first-of-type`]: {
    borderBottom: 'none',
  },
});

export const DialogRouteAssignmentDriverInfoContainer = styled(Box)(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }),
);

export const DialogRouteAssignmentDriverInfoAvatar = styled(Avatar)({
  width: 70,
  height: 70,
});

export const DialogRouteAssignmentDriverInfoTextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const DialogRouteAssignmentListItemIconImage = styled('img')({
  width: 80,
  marginLeft: 10,
  marginRight: 20,
});

export const DialogRouteAssignmentStoreImg = styled('img')({
  height: 30,
});

export const DialogRouteAssignmentActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

import AccessFeatureAlert from './Alert';
import AccessFeatureAutoComplete from './AutoComplete';
import AccessFeatureBadge from './Badge';
import AccessFeatureButton from './Button';
import AccessFeatureFormControlLabel from './FormControlLabel';
import AccessFeatureHidden from './Hidden';
import AccessFeatureMenuItem from './MenuItem';
import AccessFeatureModal from './Modal';
import AccessFeatureNextPlanChip from './NextPlanChip';
import AccessFeatureTextField from './TextField';
import AccessFeatureTypograhpy from './Typograhpy';

const FeatureAccessComponents = {
  Alert: AccessFeatureAlert,
  AutoComplete: AccessFeatureAutoComplete,
  Badge: AccessFeatureBadge,
  Button: AccessFeatureButton,
  FormControlLabel: AccessFeatureFormControlLabel,
  Hidden: AccessFeatureHidden,
  MenuItem: AccessFeatureMenuItem,
  Modal: AccessFeatureModal,
  NextPlanChip: AccessFeatureNextPlanChip,
  TextField: AccessFeatureTextField,
  Typograghy: AccessFeatureTypograhpy,
};

export default FeatureAccessComponents;

import { useMemo } from 'react';
import { get } from 'lodash';
import { useTheme } from '@mui/material';
import { red, blue, green, grey } from '@mui/material/colors';

import { RouteStateTypes } from '~globals/types/enums';

interface RouteStateInfoItem {
  description: string;
  color: string;
}

type RouteStateList = {
  [state in RouteStateTypes]: RouteStateInfoItem;
};

export const useRouteStateInfo = (
  stateId: RouteStateTypes,
): RouteStateInfoItem => {
  const currentTheme = useTheme();

  const stateList = useMemo<RouteStateList>(
    () => ({
      [RouteStateTypes.New]: {
        description: 'A Asignar',
        color: currentTheme.palette.secondary.main,
      },
      [RouteStateTypes.Assigned]: {
        description: 'A Confirmar',
        color: currentTheme.palette.secondary.main,
      },
      [RouteStateTypes.Approve]: {
        description: 'A Realizar',
        color: blue[700],
      },
      [RouteStateTypes.Rejected]: {
        description: 'Rechazada',
        color: red[700],
      },
      [RouteStateTypes.InProgress]: {
        description: 'En Camino',
        color: currentTheme.palette.primary.main,
      },
      [RouteStateTypes.Finalized]: {
        description: 'Completada',
        color: green[700],
      },
      [RouteStateTypes.Expired]: {
        description: 'Expirada',
        color: grey[400],
      },
    }),
    [currentTheme],
  );

  const currentInfo = useMemo<RouteStateInfoItem>(
    () => get(stateList, stateId),
    [stateId, stateList],
  );

  return currentInfo;
};

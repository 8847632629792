import { styled, Card, CardContent } from '@mui/material';
import { LoadingButton, loadingButtonClasses } from '@mui/lab';

export const DriverCompanyActionInvitationCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
}));

export const DriverCompanyActionInvitationCardContent = styled(CardContent)(
  ({ theme }) => ({
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',

    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  }),
);

export const DriverCompanyActionInvitationCardActions = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
  }),
);

export const DriverCompanyActionInvitationCardActionsButton = styled(
  LoadingButton,
)(({ theme, color }) => ({
  backgroundColor: theme.palette.common.white,

  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },

  [`& .${loadingButtonClasses.loadingIndicator}`]: {
    ...(color &&
      color !== 'inherit' && {
        color: theme.palette[color].main,
      }),
  },
}));

import { ReactElement, ElementType } from 'react';
import { TabContext } from '@mui/lab';
import { Typography } from '@mui/material';

import { Link } from '~components/index';
import { PATHS } from '~constants/index';

import {
  useSubscriptionPaymentModalContext,
  SubscriptionPaymentModalContextActiveFormPanelsId,
} from '../SubscriptionPaymentModalContext';
import {
  SubscriptionPaymentModalFormBillingData,
  SubscriptionPaymentModalFormMercadoPago,
} from './Tabs';
import {
  SubscriptionPaymentModalFormPanel,
  SubscriptionPaymentModalFormContainer,
} from './styles';

interface SubscriptionPaymentModalFormTabPanelItem {
  id: SubscriptionPaymentModalContextActiveFormPanelsId;
  panel: ElementType;
}

const TABS_PANELS: SubscriptionPaymentModalFormTabPanelItem[] = [
  {
    id: 'billingData',
    panel: SubscriptionPaymentModalFormBillingData,
  },
  {
    id: 'mercadopago',
    panel: SubscriptionPaymentModalFormMercadoPago,
  },
];

const SubscriptionPaymentModalForm = (): ReactElement => {
  const {
    form: { activeForm },
    isArg,
  } = useSubscriptionPaymentModalContext();

  return (
    <TabContext value={activeForm}>
      {TABS_PANELS.map(({ id, panel: Panel }) => (
        <SubscriptionPaymentModalFormPanel
          key={`subscription-payment-modal-form-panel-${id}`}
          value={id}
        >
          <SubscriptionPaymentModalFormContainer>
            <Panel />

            <Typography
              variant="caption"
              color="text.secondary"
              lineHeight={1.1}
            >
              Al iniciar una membresía de Routix, acepta nuestros{' '}
              <Link
                to={PATHS.PUBLIC.PRIVACY_TERMS}
                target="_blank"
                variant="inherit"
                underline="hover"
                fontWeight="bold"
              >
                Términos de servicio de membresía.
              </Link>{' '}
              A su método de pago se le cobrará la tarifa correspondiente, a
              menos que decida cancelar. No hay reembolsos por membresías
              canceladas entre ciclos de facturación.{' '}
              {isArg && (
                <>
                  Para las suscripciones mensuales por MercadoPago el cargo se
                  procesará el primer día de cada mes. Si te suscribes en una
                  fecha diferente, solo se te cobrará una cantidad proporcional
                  por los días restantes hasta el próximo ciclo de facturación.
                  El precio en pesos Argentinos se toma en base a la cotización
                  del dólar oficial del BNA del cierre del último mes.
                </>
              )}
            </Typography>
          </SubscriptionPaymentModalFormContainer>
        </SubscriptionPaymentModalFormPanel>
      ))}
    </TabContext>
  );
};

export default SubscriptionPaymentModalForm;

import { ReactElement, ElementRef, useRef, useCallback, useMemo } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Map, {
  NavigationControl,
  FullscreenControl,
  Marker,
  MarkerDragEvent,
} from 'react-map-gl';
import { useSnackbar } from 'notistack';
import { throttle } from 'lodash';

import { useLazyRequest } from '~hooks/index';
import { createAdminWarehouse } from '~services/admin/warehouse';
import { AdminWarehouse } from '~services/admin/warehouse/types';
import { hasError } from '~utils/index';
import { PlaceType } from '~hooks/usePlacesAutocomplete';
import { TOKEN } from '~utils/mapbox';

import PlacesAutocomplete from '../../../PlacesAutocomplete';
import { ModeSateliteControl } from '../../../MapCustomControl';
import MapMarkerDragHint from '../../../MapMarkerDragHint';
import { Marker as MarkerIcon } from '../../../Icons';
import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import { InitialSetupWizardLoadDepositMapContainer } from './styles';
import { getProgressValue } from '../../utils';

const InitialSetupWizardLoadDeposit = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    step: { handleNextStep },
    companyType: { isCarrier: isCarrierSelectedCompany },
  } = useInitialSetupWizardContext();

  const [, loadingCreateDeposit, , executeCreateDeposit] = useLazyRequest({
    request: createAdminWarehouse,
    withPostSuccess: (response) => {
      const vehicleResponse = response.data?.data as AdminWarehouse;

      const message = `El depósito ${vehicleResponse.title} fue creado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      handleNextStep('finish');
    },
    withPostFailure: () => {
      const errorMessage = 'Ha ocurrido un error, intente nuevamente';

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const handleSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      const address = values[FIELDS_NAME.ADDRESS];
      const addressCoords = values[
        FIELDS_NAME.ADDRESS_COORDS
      ] as google.maps.LatLngLiteral;

      const title = address.split(',')[0];

      await executeCreateDeposit({
        enabled: true,
        title,
        address: values.address,
        latitude: addressCoords.lat,
        longitude: addressCoords.lng,
        addressNotes: '',
        serviceDuration: 0,
        radius: null,
        contactName: '',
        contactPhone: '',
        timeWindowFrom1: null,
        timeWindowTo1: null,
        timeWindowFrom2: null,
        timeWindowTo2: null,
        timeWindowAllDay: false,
      });

      setSubmitting(false);
    },
    [executeCreateDeposit],
  );

  const {
    errors,
    touched,
    submitForm,
    values,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const refAutocomplete = useRef<ElementRef<typeof PlacesAutocomplete>>(null);

  const getCoordAddres = useMemo(
    () =>
      throttle<google.maps.Geocoder['geocode']>((request, callback) => {
        new window.google.maps.Geocoder().geocode(request, callback);
      }, 200),
    [],
  );

  const handleChangeAddress = useCallback(
    (newValue: PlaceType | null) => {
      setFieldValue(FIELDS_NAME.ADDRESS, newValue?.description ?? '');
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, null);

      getCoordAddres({ placeId: newValue?.place_id }, (results) => {
        if (results) {
          setFieldValue(
            FIELDS_NAME.ADDRESS_COORDS,
            results[0].geometry.location.toJSON(),
          );
        }
      });
    },
    [getCoordAddres, setFieldValue],
  );

  const handleBlurAddress = useCallback((): void => {
    setFieldTouched(FIELDS_NAME.ADDRESS, true);
  }, [setFieldTouched]);

  const handleOnDragEndEvent = useCallback(
    (e: MarkerDragEvent) => {
      setFieldValue(FIELDS_NAME.ADDRESS_COORDS, {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      });
      setFieldTouched(FIELDS_NAME.ADDRESS_COORDS);
    },
    [setFieldValue, setFieldTouched],
  );

  const title = useMemo(() => {
    let output = 'Ingresa un depósito';

    if (!isCarrierSelectedCompany) output += ' o punto de colecta';

    return output;
  }, [isCarrierSelectedCompany]);

  const description = useMemo(() => {
    if (!isCarrierSelectedCompany)
      // eslint-disable-next-line max-len
      return 'Podrás indicar a los transportistas que retiren o trasladen tus pedidos desde esta dirección.';

    return 'Las rutas podrán iniciar y/o terminar en estos puntos.';
  }, [isCarrierSelectedCompany]);

  return (
    <InitialSetupWizardStepContainer
      stepId="loadDeposit"
      minHeight={550}
      maxWidth={600}
    >
      <StepTitleWithProgress
        progress={getProgressValue(
          isCarrierSelectedCompany,
          isCarrierSelectedCompany ? 3 : 2,
        )}
        title={title}
        description={description}
      />

      <InitialSetupWizardModalStepContent>
        <Stack spacing={1} flex={1}>
          <PlacesAutocomplete
            ref={refAutocomplete}
            id="customer-places-autocomplete"
            onChange={(_e, newVal) => handleChangeAddress(newVal)}
            onBlur={handleBlurAddress}
            error={hasError(touched, errors, FIELDS_NAME.ADDRESS)}
          />

          {values[FIELDS_NAME.ADDRESS_COORDS] && (
            <InitialSetupWizardLoadDepositMapContainer>
              <Map
                mapboxAccessToken={TOKEN}
                mapStyle="mapbox://styles/mapbox/light-v10"
                initialViewState={{
                  longitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lng,
                  latitude: values[FIELDS_NAME.ADDRESS_COORDS]?.lat,
                  zoom: 14,
                }}
                attributionControl={false}
              >
                <FullscreenControl position="bottom-left" />
                <NavigationControl position="bottom-left" showCompass={false} />
                <ModeSateliteControl position="bottom-left" />

                <Marker
                  longitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lng}
                  latitude={values[FIELDS_NAME.ADDRESS_COORDS]?.lat}
                  onDragEnd={handleOnDragEndEvent}
                  draggable
                  anchor="bottom"
                >
                  <MarkerIcon color="primary" fontSize="large" />
                </Marker>

                <MapMarkerDragHint />
              </Map>
            </InitialSetupWizardLoadDepositMapContainer>
          )}

          <Typography component="p" variant="caption" color="text.secondary">
            Podrás cambiar y agregar nuevos desde las preferencias de la cuenta.
          </Typography>
        </Stack>

        <Stack spacing={2} alignItems="center">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={submitForm}
            loading={loadingCreateDeposit || isSubmitting}
            disabled={!dirty}
            sx={{ width: 280 }}
          >
            Siguiente
          </LoadingButton>
        </Stack>
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardLoadDeposit;

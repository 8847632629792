import { ReactElement, ReactNode } from 'react';
import { Typography, Link } from '@mui/material';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import {
  NoRecordsFoundMessageContainer,
  NoRecordsFoundMessageImg,
} from './styles';

interface NoRecordsFoundMessageProps {
  title?: string;
  image?: {
    src?: string;
    alt?: string;
  };
  description?: ReactNode;
}

const NoRecordsFoundMessageDescriptionDefault = (): ReactElement => (
  <Typography variant="body2" component="span">
    Puede ajustar los filtros.{' '}
    <Link
      href="http://help.routix.io/"
      target="_blank"
      underline="hover"
      fontWeight="bold"
    >
      Haz click aquí
    </Link>{' '}
    para ver más ayuda.
  </Typography>
);

const NoRecordsFoundMessage = ({
  title = 'Sin resultados para mostrar',
  image = {
    src: toAbsoluteUrl('/icons/icon_empty_search.png'),
    alt: 'Empty Search',
  },
  description = <NoRecordsFoundMessageDescriptionDefault />,
}: NoRecordsFoundMessageProps): ReactElement => (
  <NoRecordsFoundMessageContainer>
    <Typography variant="h6" component="span" fontWeight="bold">
      {title}
    </Typography>

    <NoRecordsFoundMessageImg src={image.src} alt={image.alt} />

    {description}
  </NoRecordsFoundMessageContainer>
);

export default NoRecordsFoundMessage;

import { ReactElement, memo } from 'react';

import { useTransactionStateInfo } from '~hooks/index';

import { SubscriptionTransactionStatusChipProps } from './types';
import { SubscriptionTransactionStatusChipRoot } from './styles';

const SubscriptionTransactionStatusChip = ({
  state,
  size = 'medium',
}: SubscriptionTransactionStatusChipProps): ReactElement => {
  const { description, color } = useTransactionStateInfo(state);

  return (
    <SubscriptionTransactionStatusChipRoot
      variant="filled"
      label={description}
      size={size}
      ownerState={{ color }}
    />
  );
};

export default memo(SubscriptionTransactionStatusChip);

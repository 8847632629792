import {
  styled,
  Card,
  CardContent,
  ListItemButton,
  ListItemText,
  listItemTextClasses,
} from '@mui/material';

import { CapabilitiesInfo } from '~components/index';

export const classes = {
  expanded: 'DialogImportFromAssociatedCompanyImporter-expanded',
  expandIcon: 'DialogImportFromAssociatedCompanyImporter-expandIcon',
};

export const DialogImportFromAssociatedCompanyImporterCard = styled(Card)({
  display: 'grid',
});

export const DialogImportFromAssociatedCompanyImporterCardContent = styled(
  CardContent,
)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: 'grid',
  gridTemplateRows: 'max-content 1fr',

  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },
}));

export const DialogImportFromAssociatedCompanyImporterCardHeader = styled(
  ListItemButton,
)(({ theme }) => ({
  padding: 0,

  '&:hover': {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    // Reset on touch devices, it doesn't add specificity
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },

  [`& .${classes.expandIcon}`]: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.action.active,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
    }),
  },

  [`&.${classes.expanded} .${classes.expandIcon}`]: {
    transform: 'rotate(180deg)',
  },
}));

export const DialogImportFromAssociatedCompanyImporterCardHeaderText = styled(
  ListItemText,
)(({ theme }) => ({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  [`& .${listItemTextClasses.primary}`]: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightBold,
  },

  [`& .${listItemTextClasses.secondary}`]: {
    ...theme.typography.subtitle2,
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const DialogImportFromAssociatedCompanyImporterCardHeaderIndicators =
  styled(CapabilitiesInfo)(({ theme }) => ({
    marginRight: theme.spacing(2),
    minWidth: 90,
    gridTemplateColumns: 'repeat(3, auto)',
  }));

export const DialogImportFromAssociatedCompanyImporterCardDataTableContainer =
  styled('div')({
    marginTop: 6,
    overflowY: 'auto',
    height: '100%',
    maxHeight: 200,
    minHeight: 180,

    '& .data-table-container': {
      display: 'grid',
      gridTemplateRows: '1fr max-content',

      '& ._loading_overlay_wrapper': {
        height: '100%',
      },
    },
  });

import { ReactElement, useCallback, useMemo, useEffect, useState } from 'react';
import {
  DialogTitle,
  DialogContentText,
  Button,
  Stack,
  Divider,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { getIntegrationProviderData } from '~utils/integrations';
import { openFileUrl } from '~utils/file';
import {
  addAdminIntegration,
  sendRequestAdminIntegrationByEmail,
} from '~services/admin/integration';
import { useLazyRequest } from '~hooks/index';
import { hasError } from '~utils/index';
import { IntegrationProvidersTypes } from '~globals/types/enums';

import { DialogAddIntegrationProps, DialogAddIntegrationType } from './types';
import {
  FIELDS_NAME,
  initialValues,
  getValidationSchema,
  validateEmail,
} from './utils';
import {
  DialogAddIntegrationContainer,
  DialogAddIntegrationContentContainer,
  DialogAddIntegrationProviderImgPropmt,
  DialogAddIntegrationActions,
} from './styles';

const DialogAddIntegration = ({
  open,
  onClose,
  integrationType,
  onSuccesSubmit,
}: DialogAddIntegrationProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const providerInfo = useMemo(() => {
    if (!integrationType) return;

    return getIntegrationProviderData(integrationType);
  }, [integrationType]);

  const isWooCommerce = useMemo(
    () => integrationType === IntegrationProvidersTypes.WooCommerce,
    [integrationType],
  );

  const [submitType, setSubmitType] =
    useState<DialogAddIntegrationType>('create');

  const [, loadingExecuteAddIntegration, , executeAddIntegration] =
    useLazyRequest({
      request: addAdminIntegration,
      withPostSuccess: (response) => {
        const currentNewTab = openFileUrl(
          response.data?.data.link as string,
          '_blank',
        );

        enqueueSnackbar(`Integración ${providerInfo?.name} en progreso`, {
          variant: 'info',
        });

        onSuccesSubmit?.(currentNewTab);

        onClose();
      },
      withPostFailure: () => {
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      },
    });

  const [
    ,
    loadingExecuteSendRequestIntegrationByEmail,
    ,
    executeSendRequestIntegrationByEmail,
  ] = useLazyRequest({
    request: sendRequestAdminIntegrationByEmail,
    withPostSuccess: () => {
      enqueueSnackbar('Se han enviado la solicitud correctamente', {
        variant: 'success',
      });

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar('No se pudo enviar la solicitud, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      if (integrationType) {
        if (submitType === 'create') {
          executeAddIntegration({
            integrationTypeId: integrationType,
            description: values[FIELDS_NAME.DESCRIPTION],
            url: values[FIELDS_NAME.URL],
          });
        } else {
          executeSendRequestIntegrationByEmail({
            integrationTypeId: integrationType,
            email: values[FIELDS_NAME.EMAIL],
            description: values[FIELDS_NAME.DESCRIPTION],
            url: values[FIELDS_NAME.URL],
          });
        }
      }
    },
    [
      executeAddIntegration,
      executeSendRequestIntegrationByEmail,
      integrationType,
      submitType,
    ],
  );

  const {
    resetForm,
    dirty,
    getFieldProps,
    touched,
    errors,
    submitForm,
    unregisterField,
    registerField,
  } = useFormik({
    initialValues,
    validationSchema: getValidationSchema(integrationType),
    onSubmit: handleSubmit,
  });

  const handleAuthorizeIntegration = useCallback(
    async (type: DialogAddIntegrationType) => {
      setSubmitType(type);

      if (type === 'create') {
        unregisterField(FIELDS_NAME.EMAIL);
      } else {
        registerField(FIELDS_NAME.EMAIL, { validate: validateEmail });
      }

      await submitForm();
    },
    [registerField, submitForm, unregisterField],
  );

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <DialogAddIntegrationContainer
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <DialogTitle>Agregar Integración</DialogTitle>

      <DialogAddIntegrationContentContainer dividers>
        <DialogContentText color="text.primary" textAlign="center">
          Vincula tu cuenta de <b>{providerInfo?.name}</b> para poder importar
          tus ventas a Routix y facilitar la tarea de planificación de envíos.
        </DialogContentText>

        <DialogAddIntegrationProviderImgPropmt
          src={providerInfo?.imgPrompt.url}
          alt={providerInfo?.imgPrompt.alt}
        />

        <Stack spacing={1}>
          {isWooCommerce && (
            <Stack spacing={1} direction="row">
              <TextField
                label="Nombre de tienda"
                placeholder="Mi Tienda de WooCommerce"
                fullWidth
                autoComplete="off"
                error={hasError(touched, errors, FIELDS_NAME.DESCRIPTION)}
                {...getFieldProps(FIELDS_NAME.DESCRIPTION)}
                InputLabelProps={{ shrink: true }}
              />

              <TextField
                label="URL de acceso"
                placeholder="https://www.mitienda.com"
                fullWidth
                autoComplete="off"
                error={hasError(touched, errors, FIELDS_NAME.URL)}
                {...getFieldProps(FIELDS_NAME.URL)}
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
          )}

          <DialogContentText variant="caption">
            Se te solicitará iniciar sesión en tu cuenta, y autorizar a nuestra
            plataforma a acceder a los pedidos y a parte de la información
            general. No realizaremos ningún cambio en tu cuenta ni en tus
            publicaciones.
          </DialogContentText>

          <Divider flexItem />

          <DialogContentText variant="caption">
            También puedes enviar la solicitud para que otra persona la
            autorice. Ingresa el destinatario y presiona el botón &quot;Enviar
            solicitud por correo&quot;.
          </DialogContentText>

          <TextField
            label="Para"
            placeholder="nombre@example.com"
            fullWidth
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
            {...getFieldProps(FIELDS_NAME.EMAIL)}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
      </DialogAddIntegrationContentContainer>

      <DialogAddIntegrationActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>

        <Stack spacing={2} direction="row">
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={loadingExecuteSendRequestIntegrationByEmail}
            onClick={() => handleAuthorizeIntegration('sendRequestEmail')}
            disabled={!dirty}
          >
            Enviar solicitud por correo
          </LoadingButton>

          <LoadingButton
            variant="contained"
            color="primary"
            loading={loadingExecuteAddIntegration}
            onClick={() => handleAuthorizeIntegration('create')}
          >
            Autorizar
          </LoadingButton>
        </Stack>
      </DialogAddIntegrationActions>
    </DialogAddIntegrationContainer>
  );
};

export default DialogAddIntegration;

import {
  Box,
  Button,
  buttonClasses,
  ButtonProps,
  styled,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

import { AlertOrdersVariantSeverity } from './types';

export const AlertOrdersCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'severity',
})<{ severity: AlertOrdersVariantSeverity }>(({ theme, severity }) => ({
  ...(severity === 'expired' && {
    backgroundColor: theme.palette.error.main,
  }),

  ...(severity === 'expiredToday' && {
    backgroundColor: theme.palette.background.paper,
  }),

  ...(severity === 'notExpired' && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

export const AlertOrdersCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3),

  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

export const AlertOrdersCardHeader = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const AlertOrdersCardImg = styled('img')({
  width: 50,
});

export const AlertOrdersNoExpiredActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export const AlertOrdersNoExpiredLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.typography.button.fontSize,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const AlertOrdersNoExpiredActionsButton = styled(
  (props: ButtonProps<'a'> & RouterLinkProps) => (
    <Button {...props} component={RouterLink} />
  ),
)(({ theme }) => ({
  ...theme.typography.caption,

  [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.caption.fontSize,
  },
}));

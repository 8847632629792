import { ReactElement, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation as ErrorIcon } from '@fortawesome/free-solid-svg-icons';
import { yellow } from '@mui/material/colors';

import { DescriptionWithErrorTooltipProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const DescriptionWithErrorTooltip = ({
  description,
  errorMessage,
  fontSize = 14,
  iconPosition = 'left',
  iconColor = yellow['800'],
}: DescriptionWithErrorTooltipProps): ReactElement => (
  <DescriptionWithIconBox
    icon={<FontAwesomeIcon icon={ErrorIcon} />}
    iconTooltip={errorMessage}
    iconPosition={iconPosition}
    iconColor={iconColor}
    description={description}
    size={fontSize}
  />
);

export default memo(DescriptionWithErrorTooltip);

import { styled, Popper, Paper, ButtonProps } from '@mui/material';
import { round } from 'lodash';

export const menuClasses = {
  arrow: 'Menu-arrow',
};

const arrowWidth = 24;
const arrowHeight = round(arrowWidth / Math.sqrt(2) + 0.1, 2);

export const MenuContainer = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,

  [`&[data-popper-placement*="bottom"] .${menuClasses.arrow}`]: {
    top: 0,
    left: 0,
    marginTop: -arrowHeight,

    '&::before': {
      transformOrigin: '0 100%',
    },
  },

  [`&[data-popper-placement*="top"] .${menuClasses.arrow}`]: {
    bottom: 0,
    left: 0,
    marginBottom: -arrowHeight,

    '&::before': {
      transformOrigin: '100% 0',
    },
  },

  [`&[data-popper-placement*="right"] .${menuClasses.arrow}`]: {
    left: 0,
    marginLeft: -arrowHeight,
    height: arrowWidth,
    width: arrowHeight,

    '&::before': {
      transformOrigin: '100% 100%',
    },
  },

  [`&[data-popper-placement*="left"] .${menuClasses.arrow}`]: {
    right: 0,
    marginRight: -arrowHeight,
    height: arrowWidth,
    width: arrowHeight,

    '&::before': {
      transformOrigin: '0 0',
    },
  },
}));

export const MenuContent = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'placement' && prop !== 'arrow',
})<{ placement: string; arrow: boolean }>(({ placement, arrow }) => ({
  minWidth: 16,
  minHeight: 16,
  outline: 0,
  WebkitOverflowScrolling: 'touch',
  margin: 0,
  padding: 0,
  position: 'relative',

  ...(placement === 'bottom' && {
    transformOrigin: 'center top',

    ...(arrow && {
      marginTop: '20px',
    }),
  }),

  ...(placement === 'left' && {
    transformOrigin: 'right center',

    ...(arrow && {
      marginRight: '20px',
    }),
  }),

  ...(placement === 'right' && {
    transformOrigin: 'left center',

    ...(arrow && {
      marginLeft: '20px',
    }),
  }),

  ...(placement === 'top' && {
    transformOrigin: 'center bottom',

    ...(arrow && {
      marginBottom: '20px',
    }),
  }),
}));

export const MenuArrow = styled('span', {
  shouldForwardProp: (prop) => prop !== 'arrowColor',
})<{ arrowColor: ButtonProps['color'] }>(({ theme, arrowColor }) => ({
  overflow: 'hidden',
  position: 'absolute',
  width: arrowWidth,
  height: arrowHeight,
  boxSizing: 'border-box',
  color: theme.palette.background.paper,

  ...(arrowColor &&
    arrowColor === 'inherit' && {
      color: 'inherit',
    }),

  ...(arrowColor &&
    arrowColor !== 'inherit' && {
      color: theme.palette[arrowColor].main,
    }),

  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: 'currentColor',
    transform: 'rotate(45deg)',
  },
}));

import { ReactElement, ElementType, useMemo, useCallback } from 'react';
import { DialogTitle, Stack, Button } from '@mui/material';
import { TabContext } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
import { useFormik, FormikHelpers, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import { RouteErrorsList } from '~components/index';
import { useLazyRequest, useUtcOffset } from '~hooks/index';
import { generateRoute } from '~services/route';
import { generateRouteCollect } from '~services/partnerCompany';
import { parseSkillsStringToArray } from '~utils/vehicle';
import { validateImportErrorsInOrders } from '~utils/order';
import { getItemValue } from '~utils/helpers';
import { replaceParamsPath } from '~utils/router';
import { RouteCalculationModeType } from '~globals/types/enums';
import { PATHS } from '~constants/index';

import { useDialogGenerateRoutesContext } from './DialogGenerateRoutesContext';
import { DialogGenerateRoutesActiveTabs } from './types';
import {
  DialogGenerateRoutesFormTab,
  DialogGenerateRoutesStepperTab,
} from './Tabs';
import {
  MAX_SUPPORT_GENERATE_ORDERS,
  getDefaultScheduleDate,
  isRouteCollect,
} from './utils';
import {
  FIELDS_NAME,
  getInitialValues,
  validationSchema,
} from './Tabs/Form/utils';
import {
  DialogGenerateRoutesContentContainer,
  DialogGenerateRoutesTabPanel,
  DialogGenerateRoutesActions,
} from './styles';

interface DialogGenerateRoutesTabPanelItem {
  id: DialogGenerateRoutesActiveTabs;
  panel: ElementType;
}

const TABS_PANELS: DialogGenerateRoutesTabPanelItem[] = [
  {
    id: 'form',
    panel: DialogGenerateRoutesFormTab,
  },
  {
    id: 'stepper',
    panel: DialogGenerateRoutesStepperTab,
  },
];

const DialogGenerateRoutesContent = (): ReactElement => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const formatterDateUtcOffset = useUtcOffset();

  const {
    type,
    activeTab: { tab: currentTab, setActiveTab },
    form: {
      selectedData: {
        vehicles: {
          selectedRowsIds: vehiclesId,
          selectedRows: selectedVehicles,
        },
        orders: {
          selectedRowsIds: selectedOrdesId,
          selectedRows: selectedOrders,
        },
      },
      chargeIndicators: {
        vehicles: indicatorsVehiclesCharge,
        orders: indicatorsOrdersCharge,
      },
    },
    stepper: {
      currentApiTransactionId: {
        value: currentApiTransactionId,
        setCurrentApiTransactionId,
      },
      currentRoutes: { value: currentRoutes },
      isSuccesCompleted: { value: isSuccesCompleted },
    },
    ordersInfo,
    onClose,
  } = useDialogGenerateRoutesContext();

  const isRouteCollectType = useMemo(() => isRouteCollect(type), [type]);

  const [, loadingGenerateRoute, , executeGenerateRoute] = useLazyRequest({
    request: isRouteCollectType ? generateRouteCollect : generateRoute,
    withPostSuccess: (response) => {
      const apiTransactionId = response.data?.data.apiTransactionId as string;

      setCurrentApiTransactionId(apiTransactionId);

      setActiveTab('stepper');
    },
    withPostFailure: (err) => {
      let errorMessage = 'Ha ocurrido un error, intente nuevamente';

      if (err?.data?.data.message) {
        errorMessage = err.data.data.message;
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const onSubmit = useCallback(
    async (
      values: ReturnType<typeof getInitialValues>,
      { setSubmitting }: FormikHelpers<ReturnType<typeof getInitialValues>>,
    ) => {
      const destinyId =
        getItemValue(values, FIELDS_NAME.ARRIVAL_POINT) ?? undefined;

      const routeCalculationModeTypeId = getItemValue(
        values,
        FIELDS_NAME.CALCULATION_MODE,
        (val) => Number(val),
      ) as RouteCalculationModeType;

      await executeGenerateRoute({
        apiTransaction: {
          name: getItemValue(values, FIELDS_NAME.NAME),
          scheduledDateTime: getItemValue(
            values,
            FIELDS_NAME.SCHEDULE_DATE,
            (val) =>
              formatterDateUtcOffset(
                val,
                moment.HTML5_FMT.DATETIME_LOCAL_MS,
                true,
              ),
          ),
          originId: getItemValue(values, FIELDS_NAME.STARTING_POINT),
          destinyId,
          routeCalculationModeTypeId,
        },
        itemsId: selectedOrdesId,
        vehiclesId,
        routeLoadBalancing: getItemValue(
          values,
          FIELDS_NAME.LOAD_BALACING_VEHICLES,
        ),
        multipleRoutesPerVehicle: getItemValue(
          values,
          FIELDS_NAME.MULTIPLE_ROUTES_PER_VEHICLE,
        ),
      });

      setSubmitting(false);
    },
    [executeGenerateRoute, formatterDateUtcOffset, selectedOrdesId, vehiclesId],
  );

  const formikBag = useFormik({
    initialValues: getInitialValues({
      scheduleDate: getDefaultScheduleDate(),
    }),
    validationSchema: validationSchema(isRouteCollectType),
    onSubmit,
  });

  const { submitForm, dirty, isValid, isSubmitting } = formikBag;

  const disabledSubmit = useMemo(
    () => !dirty || !isValid || !vehiclesId.length || !selectedOrdesId.length,
    [dirty, isValid, vehiclesId, selectedOrdesId],
  );

  const skillsTotals = useMemo(() => {
    const skillsVehicles = new Set<string>();
    const skillsOrders = new Set<string>();

    selectedVehicles.forEach((selectedVehicle) => {
      const currentVehiclesSkills = parseSkillsStringToArray(
        selectedVehicle.skillsList,
      );

      currentVehiclesSkills.forEach((vehicleSkill) =>
        skillsVehicles.add(vehicleSkill),
      );
    });

    selectedOrders.forEach((selectedOrder) => {
      const currentOrdersSkills = parseSkillsStringToArray(
        selectedOrder.skillsNeeded,
      );

      currentOrdersSkills.forEach((orderSkill) => skillsOrders.add(orderSkill));
    });

    return { vehicles: skillsVehicles, orders: skillsOrders };
  }, [selectedVehicles, selectedOrders]);

  const validatePresenceOfSkillsOfOrdersInVehicle = useCallback(() => {
    const currentSelectedSkillsOrders = Array.from(skillsTotals.orders);
    const currentSelectedSkillsVehicles = Array.from(skillsTotals.vehicles);

    const copyCurrentSelectedSkillsVehicles = [
      ...currentSelectedSkillsVehicles,
    ];

    for (
      let orderSkillIndex = 0;
      orderSkillIndex < currentSelectedSkillsOrders.length;
      orderSkillIndex++
    ) {
      const vehicleSkillIndex = copyCurrentSelectedSkillsVehicles.indexOf(
        currentSelectedSkillsOrders[orderSkillIndex],
      );

      if (vehicleSkillIndex === -1) return false;

      copyCurrentSelectedSkillsVehicles.splice(vehicleSkillIndex, 1);
    }

    return true;
  }, [skillsTotals]);

  const singleRouteId = useMemo(() => {
    if (currentRoutes.length === 1) {
      return currentRoutes[0].routeId;
    }

    return null;
  }, [currentRoutes]);

  const prefixTypeTitle = useMemo(() => {
    if (isRouteCollect(type)) {
      return singleRouteId ? 'ruta de colecta' : 'plan de colecta';
    }

    return singleRouteId ? 'ruta' : 'plan de viaje';
  }, [singleRouteId, type]);

  const alertsRoutes = useMemo(() => {
    const alerts: string[] = [];

    if (vehiclesId.length > 0) {
      if (
        indicatorsOrdersCharge.capacity1 > indicatorsVehiclesCharge.capacity1
      ) {
        alerts.push('Capacidad primaria de carga de vehículos excedida.');
      }

      if (
        indicatorsOrdersCharge.capacity2 > indicatorsVehiclesCharge.capacity2
      ) {
        alerts.push('Capacidad secundaria de carga de vehículos excedida.');
      }

      if (selectedOrdesId.length > indicatorsVehiclesCharge.maximumVisits) {
        alerts.push('Cantidad máxima de paradas de los vehículos excedida.');
      }

      if (!validatePresenceOfSkillsOfOrdersInVehicle()) {
        alerts.push(
          'No se seleccionaron vehiculos para todos los tipos de cargas especiales a programar.',
        );
      }

      if (selectedOrdesId.length > MAX_SUPPORT_GENERATE_ORDERS) {
        alerts.push(
          `Se supera el límite de ${MAX_SUPPORT_GENERATE_ORDERS} pedidos por ${prefixTypeTitle}.`,
        );
      }
    }

    if (validateImportErrorsInOrders(ordersInfo)) {
      alerts.push(
        // eslint-disable-next-line max-len
        `Algunos pedidos contienen errores y no se podran incluir en las generación del ${prefixTypeTitle}`,
      );
    }

    return alerts;
  }, [
    vehiclesId,
    indicatorsOrdersCharge,
    indicatorsVehiclesCharge,
    selectedOrdesId,
    validatePresenceOfSkillsOfOrdersInVehicle,
    ordersInfo,
    prefixTypeTitle,
  ]);

  const handleRedirectCurrentPlanning = useCallback(() => {
    if (currentApiTransactionId) {
      let urlRedirect = replaceParamsPath(PATHS.ROUTES.PLANNING, {
        ':apiTransactionId': currentApiTransactionId,
      });

      if (singleRouteId) {
        urlRedirect = replaceParamsPath(PATHS.ROUTES.VEHICLE_PLANNING, {
          ':apiTransactionId': currentApiTransactionId,
          ':routeId': singleRouteId,
        });
      }

      navigate(urlRedirect);
    }
  }, [currentApiTransactionId, singleRouteId, navigate]);

  return (
    <>
      <DialogTitle>Generar {prefixTypeTitle}</DialogTitle>

      <DialogGenerateRoutesContentContainer dividers>
        <FormikProvider value={formikBag}>
          <TabContext value={currentTab}>
            {TABS_PANELS.map(({ id, panel: Panel }) => (
              <DialogGenerateRoutesTabPanel
                key={`dialog-generate-routes-panel-${id}`}
                value={id}
              >
                <Panel />
              </DialogGenerateRoutesTabPanel>
            ))}
          </TabContext>
        </FormikProvider>
      </DialogGenerateRoutesContentContainer>

      <DialogGenerateRoutesActions>
        <Stack flex={1}>
          {currentTab === 'form' && (
            <RouteErrorsList
              title={`Posibles errores encontrados en el ${prefixTypeTitle}`}
              errors={alertsRoutes}
            />
          )}

          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button color="secondary" onClick={onClose}>
              Cerrar
            </Button>

            {currentTab === 'form' && (
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={submitForm}
                loading={loadingGenerateRoute || isSubmitting}
                disabled={disabledSubmit}
              >
                Generar
              </LoadingButton>
            )}

            {currentTab === 'stepper' && isSuccesCompleted && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleRedirectCurrentPlanning}
              >
                Ver {prefixTypeTitle}
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogGenerateRoutesActions>
    </>
  );
};

export default DialogGenerateRoutesContent;

import { styled, Typography } from '@mui/material';

export const ListFieldContainer = styled('fieldset')(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: theme.shape.borderRadius,
  padding: '18.5px 14px',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const ListFieldLabel = styled(Typography)(({ theme }) => ({
  maxWidth: 'max-content',
  fontSize: 'calc(1rem * 0.75)',
  padding: '0px 6px',
  marginLeft: -6,
  color: theme.palette.text.secondary,
}));

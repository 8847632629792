import { styled, Typography } from '@mui/material';

interface SelectorOptionStyleProps {
  selected: boolean;
}

export const SelectorOptionContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<SelectorOptionStyleProps>(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid`,
  borderRadius: 12,
  borderColor: selected ? theme.palette.primary.main : theme.palette.grey[400],
  height: 160,
  width: 280,
  padding: 8,
}));

export const SelectorOptionTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<SelectorOptionStyleProps>(({ theme, selected }) => ({
  fontWeight: 'bold',
  fontSize: '1.25rem',
  color: selected ? theme.palette.primary.main : theme.palette.grey[600],
}));

export const SelectorOptionSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.85rem',
  textAlign: 'center',
  lineHeight: 1.18,
  marginTop: 6,
  marginBottom: 4,
  color: theme.palette.text.primary,
}));

export const SelectorOptionSeparator = styled('div')({
  height: 74,
});

export const SelectorOptionFooter = styled(Typography)(({ theme }) => ({
  fontSize: '0.65rem',
  color: theme.palette.text.secondary,
}));

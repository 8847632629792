import * as Yup from 'yup';

export const FIELDS_NAMES = {
  OPERATION_TYPE: 'operationType',
  ORDER_MANAGEMENT_COLLECT: 'orderManagementCollect',
  ORDER_COLLECTED_TRANSFER: 'orderCollectedTransfer',
  ORDER_CENTRALIZATION_TRANSFER: 'orderCentralizationTransfer',
  ORDER_CENTRALIZATION_SHIPMENT: 'orderCentralizationShipment',
  ORDER_DISTRIBUTION_SHIPMENT: 'orderDistributionShipment',
  NOTES: 'notes',
} as const;

export const initialValues = {
  [FIELDS_NAMES.OPERATION_TYPE]: '',
  [FIELDS_NAMES.ORDER_MANAGEMENT_COLLECT]: '',
  [FIELDS_NAMES.ORDER_COLLECTED_TRANSFER]: '',
  [FIELDS_NAMES.ORDER_CENTRALIZATION_TRANSFER]: '',
  [FIELDS_NAMES.ORDER_CENTRALIZATION_SHIPMENT]: '',
  [FIELDS_NAMES.ORDER_DISTRIBUTION_SHIPMENT]: [] as string[],
  [FIELDS_NAMES.NOTES]: '',
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAMES.OPERATION_TYPE]: Yup.string().notRequired(),
  [FIELDS_NAMES.ORDER_MANAGEMENT_COLLECT]: Yup.string().notRequired(),
  [FIELDS_NAMES.ORDER_COLLECTED_TRANSFER]: Yup.string().notRequired(),
  [FIELDS_NAMES.ORDER_CENTRALIZATION_TRANSFER]: Yup.string().notRequired(),
  [FIELDS_NAMES.ORDER_CENTRALIZATION_SHIPMENT]: Yup.string().notRequired(),
  [FIELDS_NAMES.ORDER_DISTRIBUTION_SHIPMENT]: Yup.array().notRequired(),
  [FIELDS_NAMES.NOTES]: Yup.string().notRequired(),
});

import { ReactElement } from 'react';
import { Typography } from '@mui/material';

import {
  formmaterSecondToHumanizeTime,
  formatterDistanceToKm,
} from '~utils/index';
import { pluralize } from '~utils/commons';

import { DailyStatiticsProps } from './types';
import {
  DailyStatiticsContainer,
  DailyStatiticsCardItem,
  DailyStatiticsCardItemImgIcon,
} from './styles';
import { toAbsoluteUrl } from '~utils/assetsHelpers';

const DailyStatitics = ({
  stops,
  distance,
  time,
}: DailyStatiticsProps): ReactElement => (
  <DailyStatiticsContainer>
    <DailyStatiticsCardItem>
      <DailyStatiticsCardItemImgIcon
        src={toAbsoluteUrl('/icons/icon_box.svg')}
        alt="Stop Icon"
      />

      <Typography component="h6" variant="body2" fontWeight="bold">
        {pluralize({
          singular: 'pedido',
          count: stops,
          includeCount: true,
        })}
      </Typography>
    </DailyStatiticsCardItem>

    <DailyStatiticsCardItem>
      <DailyStatiticsCardItemImgIcon
        src={toAbsoluteUrl('/icons/icon_route.svg')}
        alt="Route Icon"
      />

      <Typography component="h6" variant="body2" fontWeight="bold">
        {formatterDistanceToKm(distance)}
      </Typography>
    </DailyStatiticsCardItem>

    <DailyStatiticsCardItem>
      <DailyStatiticsCardItemImgIcon
        src={toAbsoluteUrl('/icons/icon_clock.svg')}
        alt="Time Icon"
      />

      <Typography component="h6" variant="body2" fontWeight="bold">
        {formmaterSecondToHumanizeTime(time)}
      </Typography>
    </DailyStatiticsCardItem>
  </DailyStatiticsContainer>
);

export default DailyStatitics;

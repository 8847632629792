import { ReactElement } from 'react';
import { StepIconProps, useStepContext } from '@mui/material';

import { useStepperGenerateRoutePlanningStepperContext } from './SteppetGenerateRouteContext';

import {
  StepperGenerateRouteStepIconContainer,
  StepperGenerateRouteStepIconBoxStatus,
  StepperGenerateRouteStepIconBoxProgress,
} from './styles';

const StepperGenerateRouteIcon = ({
  active,
  completed,
  icon,
  className,
}: StepIconProps): ReactElement => {
  // @ts-expect-error: Let's ignore a compile error like this unreachable code
  const { last } = useStepContext();
  const { light } = useStepperGenerateRoutePlanningStepperContext();

  return (
    <StepperGenerateRouteStepIconContainer light={light}>
      <StepperGenerateRouteStepIconBoxStatus
        ownerState={{ active, completed }}
        className={className}
      >
        {icon}
      </StepperGenerateRouteStepIconBoxStatus>

      {active && !completed && !last && (
        <StepperGenerateRouteStepIconBoxProgress size="inherit" />
      )}
    </StepperGenerateRouteStepIconContainer>
  );
};

export default StepperGenerateRouteIcon;

import { useMemo } from 'react';
import { get } from 'lodash';
import { grey, red, green } from '@mui/material/colors';

import { DriverCompanyStateTypes } from '~globals/types/enums';

interface DriverCompanyStateInfoItem {
  description: string;
  color: string;
}

type DriverCompanyStateList = {
  [state in DriverCompanyStateTypes]: DriverCompanyStateInfoItem;
};

export const useDriverCompanyStateInfo = (
  stateId: DriverCompanyStateTypes,
): DriverCompanyStateInfoItem => {
  const stateList = useMemo<DriverCompanyStateList>(
    () => ({
      [DriverCompanyStateTypes.Pending]: {
        description: 'Pendiente',
        color: grey[700],
      },
      [DriverCompanyStateTypes.Approve]: {
        description: 'Activo',
        color: green[700],
      },
      [DriverCompanyStateTypes.Rejected]: {
        description: 'Rechazada',
        color: red[700],
      },
    }),
    [],
  );

  const currentInfo = useMemo<DriverCompanyStateInfoItem>(
    () => get(stateList, stateId),
    [stateId, stateList],
  );

  return currentInfo;
};

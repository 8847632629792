import { useMemo, useCallback, memo, useRef } from 'react';
import { useControl } from 'react-map-gl';
import { MapboxDrawOptions, modes, constants } from '@mapbox/mapbox-gl-draw';

import { controlLocale } from '~constants/mapControlLocale';
import { Nullable } from '~globals/types';

import DrawPolygonCustomMode from './DrawPolygonMode';
import SelectionBar from './SelectionBar';
import {
  SelectionControlProps,
  SelectionDrawEventHandler,
  SelectioBaseEventHandler,
  SelectionControlButton,
} from './types';

const DEFAULT_SELECTION_BAR_PROPS: MapboxDrawOptions = {
  displayControlsDefault: false,
  controls: { polygon: true },
  defaultMode: 'simple_select',
  keybindings: false,
  modes: { ...modes, draw_polygon: DrawPolygonCustomMode },
};

const SelectionControl = ({
  position,
  actions,
}: SelectionControlProps): null => {
  const selectionBarRef = useRef<Nullable<SelectionBar>>(null);

  const handlerCreateDraw = useCallback<SelectionDrawEventHandler>(
    (event, drawInstance) => {
      actions?.onDraw?.(event, drawInstance);
    },
    [actions],
  );

  const handlerFreeHand = useCallback<SelectioBaseEventHandler>(
    (event, drawInstance) => {
      event.preventDefault();
      drawInstance?.ctrl.trash();

      drawInstance?.desactivedButton('.mapbox-gl-draw_point');

      actions?.onFreHand?.(event, drawInstance);
    },
    [actions],
  );

  const handlerSelectedMarkers = useCallback<SelectioBaseEventHandler>(
    (event, drawInstance) => {
      event.preventDefault();
      drawInstance?.ctrl.trash();

      drawInstance?.toggleActiveButton('.mapbox-gl-draw_point');

      actions?.onSelectedMarkers?.(event, drawInstance);
    },
    [actions],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMode = useCallback<SelectioBaseEventHandler<any>>(
    (event, drawInstance) => {
      if (event.mode === constants.modes.DRAW_POLYGON) {
        drawInstance?.desactivedButton('.mapbox-gl-draw_point');
        actions?.onInitalizeDraw?.(event, drawInstance);
      }
    },
    [actions],
  );

  const buttons = useMemo<SelectionControlButton[]>(
    () => [
      {
        className: 'mapbox-gl-free-hand',
        title: controlLocale.ModeFreeHand,
        eventHandler: handlerFreeHand,
      },
      {
        className: 'mapbox-gl-draw_point',
        title: controlLocale.SelecteMarkers,
        eventHandler: handlerSelectedMarkers,
      },
    ],
    [handlerFreeHand, handlerSelectedMarkers],
  );

  useControl<SelectionBar>(
    ({ map }) => {
      const drawCtrl = new SelectionBar(DEFAULT_SELECTION_BAR_PROPS, buttons);

      selectionBarRef.current = drawCtrl;

      map.on('draw.create', (event) => handlerCreateDraw(event, drawCtrl));
      map.on('draw.modechange', (event) => handleChangeMode(event, drawCtrl));

      return drawCtrl;
    },
    ({ map }) => {
      selectionBarRef.current = null;

      map.off('draw.create', (event) =>
        handlerCreateDraw(event, selectionBarRef.current),
      );

      map.off('draw.modechange', (event) =>
        handleChangeMode(event, selectionBarRef.current),
      );
    },
    { position },
  );

  return null;
};

export default memo(SelectionControl);

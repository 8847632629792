import { compact } from 'lodash';

import { Nullable } from '~globals/types';
import { AdminCompany } from '~services/admin/company/types';
import { BillingPlainData } from '~globals/types/commons';
import { TaxTypes, InvoiceTypes } from '~globals/types/enums';

import { checkIsArg } from './country';

export const getTaxIdLabel = (isArg: boolean): string =>
  isArg ? 'CUIT' : 'Nro de id tributaria';

export const getBillingAddresByCompany = (
  company: Nullable<AdminCompany>,
): string => {
  const floor = company?.floor ? `Piso ${company.floor}` : undefined;
  const depto = company?.department ? `Depto ${company.department}` : undefined;
  const zipCode = company?.zipCode ? `CP ${company.zipCode}` : undefined;

  const direction = compact([
    company?.address,
    company?.number,
    floor,
    depto,
  ]).join(' ');

  const address = compact([
    direction,
    company?.city,
    company?.state,
    zipCode,
    company?.country?.description,
  ]).join(', ');

  return address;
};

export const getBillingDataByCompany = (
  company: Nullable<AdminCompany>,
): BillingPlainData => {
  const isArg = checkIsArg(company?.countryId);

  return {
    businessName: company?.name ?? '',
    taxId: company?.cuit ?? '',
    country: company?.country?.description ?? '',
    phone: company?.contactMobile ?? company?.contactPhone ?? '',
    address: getBillingAddresByCompany(company),
    // @TODO: Change for correct key o parse
    taxType: isArg ? '' : undefined,
    // @TODO: Change for correct key o parse
    invoiceType: isArg ? '' : undefined,
  };
};

const INVOICE_TYPE_BY_TAX_TYPE = {
  [TaxTypes.Monotributist]: InvoiceTypes.A,
  [TaxTypes.RegisteredResponsible]: InvoiceTypes.A,
  [TaxTypes.Exempt]: InvoiceTypes.B,
  [TaxTypes.FinalConsumer]: InvoiceTypes.B,
};

export const getInvoiceTypeByTaxType = (taxType: TaxTypes): InvoiceTypes =>
  INVOICE_TYPE_BY_TAX_TYPE[taxType];

import { ReactElement } from 'react';

// eslint-disable-next-line import/extensions
import pck from '../../../../package.json';

import { NewFeatureBadgeRoot } from './styles';
import { NewFeatureBadgeProps } from './types';

const NewFeatureBadge = ({
  activeForVersion,
  label = 'Nuevo',
  variant = 'outlined',
  ...rest
}: NewFeatureBadgeProps): ReactElement => {
  if (activeForVersion !== pck.version) return <></>;

  return (
    <NewFeatureBadgeRoot
      label={label}
      variant={variant}
      color="primary"
      {...rest}
    />
  );
};

export default NewFeatureBadge;

import {
  styled,
  CardContent,
  Box,
  AvatarGroup,
  avatarClasses,
  Button,
  buttonClasses,
} from '@mui/material';

export const MainAccountDashboardCardContent = styled(CardContent)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 3),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  }),
);

export const MainAccountDashboardInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));

export const MainAccountDashboardInfoButtonItem = styled(Button)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.text.primary,

    [`&.${buttonClasses.disabled}`]: {
      color: theme.palette.text.primary,
    },

    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
);

export const MainAccountDashboardInfoItemIconImg = styled('img')({
  width: 90,
  height: 50,
});

export const MainAccountDashboardInfoAvatarGroup = styled(AvatarGroup)({
  [`& .${avatarClasses.root}`]: {
    width: 50,
    height: 50,
  },
});

export const MainAccountDashboardInviteDriver = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const MainAccountDashboardInviteDriverInfo = styled(Box)({
  maxWidth: '50%',
});

export const MainAccountDashboardInviteButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

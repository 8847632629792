import {
  styled,
  ButtonBase,
  Badge,
  badgeClasses,
  Divider,
  Checkbox,
  IconButton,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RouteItemStopState } from '../RouteItemStopCard/types';

export const RouteItemStopDndCardContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'enabledMove',
})<{ isDragging: boolean; enabledMove: boolean }>(
  ({ theme, isDragging, enabledMove }) => ({
    display: 'flex',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.action.active}`,
    minHeight: 72,
    opacity: isDragging ? 0 : 1,
    cursor: enabledMove ? 'move' : 'auto',
    alignItems: 'flex-start',
    position: 'relative',
  }),
);

export const RouteItemStopDndCardDivider = styled(Divider)(({ theme }) => ({
  borderRightWidth: 3,
  borderColor: theme.palette.text.primary,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 22,
}));

export const RouteItemStopDndTitle = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: theme.typography.pxToRem(13),
  fontWeight: theme.typography.fontWeightBold,
}));

export const RouteItemStopDndText = styled('span')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.action.active,
}));

export const RouteItemStopDndTextWarning = styled(RouteItemStopDndText)(
  ({ theme }) => ({
    color: theme.palette.warning.main,
  }),
);

export const RouteItemStopDndIconButton = styled(IconButton)(({ theme }) => ({
  padding: 2,
  fontSize: theme.typography.pxToRem(18),
}));

export const RouteItemStopDndCardIconButtonContainer = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'state' && prop !== 'enabledBorder',
})<{ state: RouteItemStopState | 'disabled'; enabledBorder: boolean }>(
  ({ theme, state, enabledBorder }) => ({
    display: 'flex',
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.common.white,
    zIndex: 1,

    ...(state === 'default' && {
      borderColor: theme.palette.action.active,
      color: theme.palette.action.active,
    }),

    ...(state === 'success' && {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    }),

    ...(state === 'error' && {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    }),

    ...(state === 'warning' && {
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    }),

    ...(state === 'disabled' && {
      borderColor: 'transparent',
      color: theme.palette.action.disabled,
    }),

    ...(enabledBorder && {
      border: '2px solid',
      borderRadius: '50%',
    }),
  }),
);

export const RouteItemStopDndCardSortableIconContainer = styled(
  FontAwesomeIcon,
)(({ theme }) => ({
  color: theme.palette.action.active,
  fontSize: theme.typography.pxToRem(18),
}));

export const RouteItemStopDndCardCheckbox = styled(Checkbox)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
  padding: 3,
}));

export const RouteItemStopDndCardBadgeButton = styled(Badge, {
  shouldForwardProp: (prop) => prop !== 'visibility',
})<{ visibility: boolean }>(({ theme, visibility }) => ({
  [`& .${badgeClasses.badge}`]: {
    backgroundColor: theme.palette.common.white,
    padding: '0 2px',
    minWidth: 16,
    height: 16,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.warning.main,

    ...(!visibility && {
      display: 'none',
      visibility: 'hidden',
    }),
  },
}));

export const RouteItemStopDndCardIconText = styled('span')({
  color: 'inherit',
});

export const RouteItemStopDndCardInfoContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.25),
}));

export const RouteItemStopDndCardInfoHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
}));

export const RouteItemStopDndCardInfoTimeContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(0.75),
    alignItems: 'center',
    flex: 1,
  }),
);

export const RouteItemStopDndCardInfoTimeDescriptions = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(0.75),
    alignItems: 'center',
  }),
);

export const RouteItemStopDndCardInfoTimeDescriptionsText = styled(
  RouteItemStopDndText,
)(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: 57,

  '&:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.action.active}`,
    paddingRight: theme.spacing(0.5),
  },
}));

export const RouteItemStopDndCardInfoTimeVisited = styled('div', {
  shouldForwardProp: (prop) => prop !== 'state',
})<{ state: RouteItemStopState }>(({ theme, state }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 28,
  padding: '1px 10px',

  ...(state === 'default' && {
    backgroundColor: theme.palette.action.active,
  }),

  ...(state === 'success' && {
    backgroundColor: theme.palette.success.main,
  }),

  ...(state === 'error' && {
    backgroundColor: theme.palette.error.main,
  }),

  ...(state === 'warning' && {
    backgroundColor: theme.palette.warning.main,
  }),
}));

export const RouteItemStopDndCardInfoTimeVisitedText = styled('span')(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(9),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
  }),
);

export const RouteItemStopDndCardInfoPrincipalContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.25),
  }),
);

export const RouteItemStopDndCardErrorDistanceWrapper = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.warning.light,
  }),
);

export const RouteItemStopDndCardInfoWarningsContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
    alignItems: 'center',
  }),
);

export const RouteItemStopDndCardInfoActionsContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
    alignItems: 'center',
  }),
);

export const RouteItemStopDndCardItemTaksWrapper = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.action.active,
  }),
);

import { styled, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TitleWithBreadcrumbsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

export const TitleWithBreadcrumbsTitleContainer = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  }),
);

export const TitleWithBreadcrumbsTitleIconButton = styled(Button)(
  ({ theme }) => ({
    minWidth: 26,
    height: 26,
    padding: 0,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
  }),
);

export const TitleWithBreadcrumbsTitleIconButtonIcon = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    fontSize: theme.typography.pxToRem(17),
  }),
);

import API from '~config/api';
import {
  ApiPromise,
  DataResponse,
  DataMessageResponse,
  ErrorResponse,
} from '~globals/types';

import {
  SubscriptionMercadoPago,
  SubscriptionMercadoPagoCreateBody,
  SubscriptionMercadoPagoEditBody,
  SubscriptionPaypal,
  SubscriptionPaypalCreateBody,
  SubscriptionMercadoPagoPreference,
} from './types';

const SUBSCRIPTION_PAYMENT_ENPOINTS = {
  createMPSubscription: 'api/v1/Payment/MercadoPago/CreatePayment',
  updateMPPayment: 'api/v1/Payment/MercadoPago/ModifyPayment',
  createPaypalSubscription: 'api/v1/Payment/Paypal/CreateSuscription',
  cancelMPPaymentPlanSubscription:
    'api/v1/Payment/MercadoPago/CancelSuscription',
  cancelPaypalPaymentPlanSubscription:
    'api/v1/Payment/Paypal/CancelSuscription',
  createMPPreferencePayment: '/api/v1/Payment/MercadoPago/CreatePreference',
  requestPayment: '/api/v1/Payment/RequestPlan',
};

export const createMPSubscription = (
  body: SubscriptionMercadoPagoCreateBody,
): ApiPromise<SubscriptionMercadoPago, ErrorResponse> =>
  API.post<SubscriptionMercadoPago, ErrorResponse>(
    SUBSCRIPTION_PAYMENT_ENPOINTS.createMPSubscription,
    body,
  );

export const updateMPPayment = (
  body: SubscriptionMercadoPagoEditBody,
): ApiPromise<SubscriptionMercadoPago, ErrorResponse> =>
  API.post<SubscriptionMercadoPago, ErrorResponse>(
    SUBSCRIPTION_PAYMENT_ENPOINTS.updateMPPayment,
    body,
  );

export const createPaypalSubscription = (
  body: SubscriptionPaypalCreateBody,
): ApiPromise<DataResponse<SubscriptionPaypal>, ErrorResponse> =>
  API.post<DataResponse<SubscriptionPaypal>, ErrorResponse>(
    SUBSCRIPTION_PAYMENT_ENPOINTS.createPaypalSubscription,
    body,
  );

export const cancelMPPaymentPlanSubscription = (
  contractPlanId: string,
): ApiPromise<DataMessageResponse, ErrorResponse> =>
  API.post<DataMessageResponse, ErrorResponse>(
    SUBSCRIPTION_PAYMENT_ENPOINTS.cancelMPPaymentPlanSubscription,
    { contractPlanId },
  );

export const cancelPaypalPaymentPlanSubscription = (
  contractPlanId: string,
): ApiPromise<DataMessageResponse, ErrorResponse> =>
  API.post<DataMessageResponse, ErrorResponse>(
    SUBSCRIPTION_PAYMENT_ENPOINTS.cancelPaypalPaymentPlanSubscription,
    { contractPlanId },
  );

export const createMPPreferencePayment = (
  body: SubscriptionPaypalCreateBody,
): ApiPromise<SubscriptionMercadoPagoPreference, ErrorResponse> =>
  API.post<SubscriptionMercadoPagoPreference, ErrorResponse>(
    SUBSCRIPTION_PAYMENT_ENPOINTS.createMPPreferencePayment,
    body,
  );

export const requestPayment = (
  body: SubscriptionPaypalCreateBody,
): ApiPromise<DataMessageResponse, ErrorResponse> =>
  API.post<DataMessageResponse, ErrorResponse>(
    SUBSCRIPTION_PAYMENT_ENPOINTS.requestPayment,
    body,
  );

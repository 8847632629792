import { ElementType, ReactElement } from 'react';
import { DialogTitle, Button } from '@mui/material';
import { TabContext } from '@mui/lab';

import {
  DialogAssignDriverContextTabsId,
  useDialogAssignDriverContext,
} from './DialogAssignDriverContext';
import { DialogAssignDriverFirstRoute, DialogAssignDriverNormal } from './Tabs';
import {
  DialogAssignDriverTabPanel,
  DialogAssignDriverActions,
} from './styles';

interface DialogAssignDriverTabPanelItem {
  id: DialogAssignDriverContextTabsId;
  panel: ElementType;
}

const TABS_PANELS: DialogAssignDriverTabPanelItem[] = [
  {
    id: 'firstRoute',
    panel: DialogAssignDriverFirstRoute,
  },
  { id: 'normal', panel: DialogAssignDriverNormal },
];

const DialogAssignDriverContent = (): ReactElement => {
  const {
    onClose,
    tabs: { activeTab },
  } = useDialogAssignDriverContext();

  return (
    <>
      <DialogTitle>Asignar Chofer</DialogTitle>

      <TabContext value={activeTab}>
        {TABS_PANELS.map(({ id, panel: Panel }) => (
          <DialogAssignDriverTabPanel
            key={`dialog-assign-driver-panel-${id}`}
            value={id}
          >
            <Panel />
          </DialogAssignDriverTabPanel>
        ))}
      </TabContext>

      <DialogAssignDriverActions>
        <Button color="secondary" onClick={onClose}>
          Cerrar
        </Button>
      </DialogAssignDriverActions>
    </>
  );
};

export default DialogAssignDriverContent;

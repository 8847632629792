import * as Yup from 'yup';

import { FileObj } from '~globals/types';
import { VALIDATIONS_ERROR } from '~constants/index';
import { Roles } from '~globals/types/enums';

export const FIELDS_NAME = {
  AVATAR: 'avatar',
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DOCUMENT_NUMBER: 'documentNumber',
  REFERENCE: 'reference',
  CONTACT_PHONE: 'contactPhone',
  CONTACT_MOBILE: 'contactMobile',
  ROLE: 'role',
  ENABLED: 'enabled',
  IS_DRIVER: 'isDriver',
} as const;

export const initialValues = {
  [FIELDS_NAME.AVATAR]: [] as FileObj[],
  [FIELDS_NAME.EMAIL]: '',
  [FIELDS_NAME.PASSWORD]: '',
  [FIELDS_NAME.CONFIRM_PASSWORD]: '',
  [FIELDS_NAME.FIRST_NAME]: '',
  [FIELDS_NAME.LAST_NAME]: '',
  [FIELDS_NAME.DOCUMENT_NUMBER]: '',
  [FIELDS_NAME.REFERENCE]: '',
  [FIELDS_NAME.CONTACT_PHONE]: '',
  [FIELDS_NAME.CONTACT_MOBILE]: '',
  [FIELDS_NAME.ROLE]: String(Roles.Operator),
  [FIELDS_NAME.ENABLED]: true,
  [FIELDS_NAME.IS_DRIVER]: false,
};

export const validationSchema = Yup.object().shape({
  [FIELDS_NAME.AVATAR]: Yup.array().of(Yup.mixed()),
  [FIELDS_NAME.EMAIL]: Yup.string()
    .email(VALIDATIONS_ERROR.EMAIL)
    .required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.PASSWORD]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.CONFIRM_PASSWORD]: Yup.string()
    .required(VALIDATIONS_ERROR.REQUIRED)
    .oneOf(
      [Yup.ref(FIELDS_NAME.PASSWORD), null],
      VALIDATIONS_ERROR.PASSWORD_NOT_MATCH,
    ),
  [FIELDS_NAME.FIRST_NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.LAST_NAME]: Yup.string().required(VALIDATIONS_ERROR.REQUIRED),
  [FIELDS_NAME.DOCUMENT_NUMBER]: Yup.string().notRequired(),
  [FIELDS_NAME.REFERENCE]: Yup.string().notRequired(),
  [FIELDS_NAME.CONTACT_PHONE]: Yup.string().notRequired(),
  [FIELDS_NAME.CONTACT_MOBILE]: Yup.string().notRequired(),
  [FIELDS_NAME.ROLE]: Yup.string().required(VALIDATIONS_ERROR),
  [FIELDS_NAME.ENABLED]: Yup.boolean(),
  [FIELDS_NAME.IS_DRIVER]: Yup.boolean(),
});

import { ReactElement, ChangeEvent, useCallback } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { Stack, TextField, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { find } from 'lodash';

import { useAppSelector, useLazyRequest } from '~hooks/index';
import { selectAuth } from '~app/store/slices/auth';
import { createAdminVehicle } from '~services/admin/vehicle';
import { AdminVehicle } from '~services/admin/vehicle/types';
import { hasError } from '~utils/index';
import { getRandomColor } from '~utils/commons';

import { useInitialSetupWizardContext } from '../../InitialSetupWizarContext';
import InitialSetupWizardStepContainer from '../../StepContainer';
import { InitialSetupWizardModalStepContent } from '../../styles';
import StepTitleWithProgress from '../../StepTitleWithProgress';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import { InitialSetupWizardLoadVehicleInfoAlert } from './styles';
import { getProgressValue } from '../../utils';

const InitialSetupWizardLoadVehicle = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAppSelector(selectAuth);
  const {
    step: { handleNextStep },
    companyType: { isCarrier: isCarrierSelectedCompany },
    vehicleList,
  } = useInitialSetupWizardContext();

  const [, loadingCreateVehicle, , executeCreateVehicle] = useLazyRequest({
    request: createAdminVehicle,
    withPostSuccess: (response) => {
      const vehicleResponse = response.data?.data as AdminVehicle;

      const message = `El vehículo ${vehicleResponse.name} fue creado correctamente`;

      enqueueSnackbar(message, { variant: 'success' });

      handleNextStep('loadDeposit');
    },
    withPostFailure: () => {
      const errorMessage = 'Ha ocurrido un error, intente nuevamente';

      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const handleSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      await executeCreateVehicle({
        enabled: true,
        name: values[FIELDS_NAME.NAME],
        referenceCode: null,
        color: getRandomColor(),
        capacity1: Number(values[FIELDS_NAME.CAPACITY_ONE]),
        capacity2: Number(values[FIELDS_NAME.CAPACITY_TWO]),
        maximumVisits: 30,
        fuelConsuptiom: Number(values[FIELDS_NAME.AVERAGE_CONSUPTION]),
        timeFrom: '06:00:00',
        timeTo: '22:00:00',
        vehicleTypeId: Number(values[FIELDS_NAME.TYPE]),
        skillsList: '',
        userId: user?.userId,
      });

      setSubmitting(false);
    },
    [executeCreateVehicle, user],
  );

  const {
    errors,
    touched,
    getFieldProps,
    submitForm,
    isSubmitting,
    setFieldValue,
    dirty,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangeVehicleType = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target ?? event.currentTarget;

      setFieldValue(FIELDS_NAME.TYPE, value);

      const currentVehicleTypeData = find(vehicleList, [
        'vehicleTypeId',
        Number(value),
      ]);

      setFieldValue(
        FIELDS_NAME.AVERAGE_CONSUPTION,
        String(currentVehicleTypeData?.fuelConsuptiom ?? ''),
      );
    },
    [setFieldValue, vehicleList],
  );

  return (
    <InitialSetupWizardStepContainer
      stepId="loadVehicle"
      minHeight={550}
      maxWidth={600}
    >
      <StepTitleWithProgress
        progress={getProgressValue(isCarrierSelectedCompany, 2)}
        title="Carga tu primer vehículo"
        description="Carga los vehículos con los que envías o cargas mercadería."
      />

      <InitialSetupWizardModalStepContent>
        <Stack spacing={1} flex={1}>
          <TextField
            label="Dominio"
            autoComplete="off"
            fullWidth
            error={hasError(touched, errors, FIELDS_NAME.NAME)}
            {...getFieldProps(FIELDS_NAME.NAME)}
          />

          <TextField
            label="Tipo de vehículo"
            fullWidth
            autoComplete="off"
            error={hasError(touched, errors, FIELDS_NAME.TYPE)}
            {...getFieldProps(FIELDS_NAME.TYPE)}
            onChange={handleChangeVehicleType}
            select
          >
            {vehicleList.map((vehicleType) => (
              <MenuItem
                key={`vehicle-type-${vehicleType.vehicleTypeId}`}
                value={String(vehicleType.vehicleTypeId)}
              >
                {vehicleType.description}
              </MenuItem>
            ))}
          </TextField>

          <Stack direction="row" spacing={2}>
            <TextField
              label="Carga primaria"
              autoComplete="off"
              fullWidth
              type="number"
              error={hasError(touched, errors, FIELDS_NAME.CAPACITY_ONE)}
              {...getFieldProps(FIELDS_NAME.CAPACITY_ONE)}
            />

            <TextField
              label="Carga secundaria"
              autoComplete="off"
              fullWidth
              type="number"
              error={hasError(touched, errors, FIELDS_NAME.CAPACITY_TWO)}
              {...getFieldProps(FIELDS_NAME.CAPACITY_TWO)}
            />
          </Stack>

          <InitialSetupWizardLoadVehicleInfoAlert
            variant="outlined"
            icon={false}
          >
            Recuerda que la carga (primaria o secundaria) no posse un tipo de
            medida fijo, sin embargo debes utilizar el mismo concepto a la hora
            de indicar estos valores en tus pedidos.
          </InitialSetupWizardLoadVehicleInfoAlert>

          <Typography component="p" variant="caption" color="text.secondary">
            Podrás cambiar y agregar nuevos desde las preferencias de la cuenta.
          </Typography>
        </Stack>

        <Stack spacing={2} alignItems="center">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={submitForm}
            loading={loadingCreateVehicle || isSubmitting}
            disabled={!dirty}
            sx={{ width: 280 }}
          >
            Siguiente
          </LoadingButton>
        </Stack>
      </InitialSetupWizardModalStepContent>
    </InitialSetupWizardStepContainer>
  );
};

export default InitialSetupWizardLoadVehicle;

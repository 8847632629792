import {
  FC,
  RefObject,
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useFormik, FormikHelpers, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';

import { useRequest, useLazyRequest, useAppSelector } from '~hooks/index';
import { selectAuth } from '~app/store/slices/auth';
import { assignDriver as assignDriverInRoutes } from '~services/route';
import { RouteAssignDriverResponse } from '~services/route/types';
import { getDriverCompanyAvailablesByRouteId } from '~services/driver';
import {
  DriverCompanyBase,
  DriverCompanyAvailablesByRouteHead,
} from '~services/driver/types';
import { DataPaginateResponse } from '~globals/types';

import { CustomAutocompleteRef } from '../CustomAutocomplete/types';
import { DialogAssignDriverProps } from './types';
import {
  FIELDS_NAME,
  initialValues,
  validationSchema,
  findSelectedDriver,
} from './utils';

export type DialogAssignDriverContextTabsId = 'firstRoute' | 'normal';

export interface DialogAssignDriverContextTab {
  activeTab: DialogAssignDriverContextTabsId;
  setActiveTab: (newActiveTab: DialogAssignDriverContextTabsId) => void;
}

export interface DialogAssignDriverContextAvailablesDrivers {
  loading: boolean;
  data: DriverCompanyBase[];
}

export interface DialogAssignDriverContextSelectedDriverAvailable {
  selectedUserDriver?: DriverCompanyBase;
  selectedLastDriver?: DriverCompanyBase;
}

export interface DialogAssignDriverContextValue
  extends DialogAssignDriverProps {
  tabs: DialogAssignDriverContextTab;
  availablesDrivers: DialogAssignDriverContextAvailablesDrivers;
  refDriverAutocomplete: RefObject<
    CustomAutocompleteRef<DriverCompanyBase, false>
  >;
  selectedDriverAvailable: DialogAssignDriverContextSelectedDriverAvailable;
  setCurrentAvailableDriver: (
    selectedDriver: DriverCompanyBase | undefined,
    entity: 'user' | 'lastDriver',
  ) => void;
  loadingSubmit: boolean;
  disabledSubmit: boolean;
}

const DialogAssignDriverContext = createContext<DialogAssignDriverContextValue>(
  {
    open: false,
    onClose: () => {},
    routeId: null,
    lastDriverId: null,
    showSnackBarSuccess: false,
    onSuccesSubmit: () => {},
    handleOpenDialogInviteDriver: () => {},
    tabs: {
      activeTab: 'firstRoute',
      setActiveTab: () => {},
    },
    refDriverAutocomplete: { current: null },
    availablesDrivers: {
      loading: true,
      data: [],
    },
    selectedDriverAvailable: {
      selectedUserDriver: undefined,
      selectedLastDriver: undefined,
    },
    setCurrentAvailableDriver: () => {},
    loadingSubmit: false,
    disabledSubmit: false,
  },
);

export const useDialogAssignDriverContext =
  (): DialogAssignDriverContextValue => useContext(DialogAssignDriverContext);

const DialogAssignDriverProvider: FC<DialogAssignDriverProps> = ({
  open,
  onClose,
  routeId,
  lastDriverId,
  showSnackBarSuccess = false,
  onSuccesSubmit,
  handleOpenDialogInviteDriver,
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user: currentLoggedUser } = useAppSelector(selectAuth);

  const [activeTab, setActiveTab] =
    useState<DialogAssignDriverContextTabsId>('firstRoute');

  const [, loadingAssignDriver, , executeAssignDriver] = useLazyRequest({
    request: assignDriverInRoutes,
    withPostSuccess: (response) => {
      if (showSnackBarSuccess) {
        enqueueSnackbar('Se ha asignado el chofer correctamente', {
          variant: 'success',
        });
      }

      const responseData = response.data?.data as RouteAssignDriverResponse;

      onSuccesSubmit?.(responseData);

      onClose();
    },
    withPostFailure: () => {
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    },
  });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      if (routeId) {
        await executeAssignDriver({
          routeId,
          driverId: values[FIELDS_NAME.CURRENT_DRIVER],
        });
      }

      setSubmitting(false);
    },
    [executeAssignDriver, routeId],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { setFieldValue, dirty, isValid, isSubmitting } = formikBag;

  const disabledSubmit = useMemo(
    () => !dirty || !isValid || !routeId,
    [dirty, isValid, routeId],
  );

  const refDriverAutocomplete =
    useRef<CustomAutocompleteRef<DriverCompanyBase, false>>(null);

  const [availablesDrivers, setAvailablesDrivers] = useState<
    DriverCompanyBase[]
  >([]);

  const [selectedUserDriver, setSelectedUserDriver] = useState<
    DriverCompanyBase | undefined
  >(undefined);

  const [selectedLastDriver, setSelectedLastDriver] = useState<
    DriverCompanyBase | undefined
  >(undefined);

  const setCurrentAvailableDriver = useCallback(
    (
      selectedDriver: DriverCompanyBase | undefined,
      entity: 'user' | 'lastDriver',
    ) => {
      if (selectedDriver && selectedDriver.isAvailable) {
        setFieldValue(FIELDS_NAME.CURRENT_DRIVER, selectedDriver.id);

        const setterSelectedDriver =
          entity === 'user' ? setSelectedUserDriver : setSelectedLastDriver;

        setterSelectedDriver(selectedDriver);
      }
    },
    [setFieldValue],
  );

  const [, loadingAvailablesDrivers] = useRequest({
    request: getDriverCompanyAvailablesByRouteId,
    payload: routeId as string,
    withPostSuccess: (response) => {
      const {
        data: { results: responseAvailablesDrivers },
        head: { isFirstRoute },
      } = response.data as DataPaginateResponse<
        DriverCompanyBase[],
        null,
        DriverCompanyAvailablesByRouteHead
      >;

      setAvailablesDrivers(responseAvailablesDrivers);

      if (currentLoggedUser?.userId) {
        const currentSelectedDriverLogged = findSelectedDriver(
          responseAvailablesDrivers,
          currentLoggedUser.userId,
        );

        setCurrentAvailableDriver(currentSelectedDriverLogged, 'user');
      }

      if (!isFirstRoute) {
        setActiveTab('normal');

        if (lastDriverId) {
          const currentSelectedLastDriver = findSelectedDriver(
            responseAvailablesDrivers,
            lastDriverId,
          );

          setCurrentAvailableDriver(currentSelectedLastDriver, 'lastDriver');
        }
      }
    },
  });

  return (
    <DialogAssignDriverContext.Provider
      value={{
        open,
        onClose,
        routeId,
        lastDriverId,
        showSnackBarSuccess,
        onSuccesSubmit,
        handleOpenDialogInviteDriver,
        tabs: {
          activeTab,
          setActiveTab,
        },
        refDriverAutocomplete,
        availablesDrivers: {
          loading: loadingAvailablesDrivers,
          data: availablesDrivers,
        },
        selectedDriverAvailable: {
          selectedUserDriver,
          selectedLastDriver,
        },
        setCurrentAvailableDriver,
        loadingSubmit: loadingAssignDriver || isSubmitting,
        disabledSubmit,
      }}
    >
      <FormikProvider value={formikBag}>{children}</FormikProvider>
    </DialogAssignDriverContext.Provider>
  );
};

export default DialogAssignDriverProvider;

import {
  Dialog,
  DialogActions,
  DialogContent,
  dialogClasses,
  styled,
} from '@mui/material';

export const DialogExportRouteToPDFContainer = styled(Dialog)({
  [`&.${dialogClasses.paper}`]: {
    width: '100%',
  },
});

export const DialogExportToPDFContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

export const DialogExportToPDFActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

export const ListItemIconImage = styled('img')({
  width: 80,
  marginLeft: 10,
  marginRight: 20,
});

import { styled } from '@mui/material';

export const InitialSetupWizardLoadDepositMapContainer = styled('div')({
  width: '100%',
  height: 200,

  '& .mapboxgl-map': {
    borderRadius: 10,
  },
});

import { ReactElement, useMemo, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGasPump as FuelIcon } from '@fortawesome/free-solid-svg-icons';

import { toAbsoluteUrl } from '~utils/assetsHelpers';

import { FuelConsumendInfoProps } from './types';
import DescriptionWithIconBox from '../DescriptionWithIconBox';

const FuelConsumendInfo = ({
  value,
  fontSize = 14,
  fontWeight,
  withTooltip = false,
  useCustomIcon = false,
}: FuelConsumendInfoProps): ReactElement => {
  const Icon = useMemo(
    () =>
      useCustomIcon ? (
        <img src={toAbsoluteUrl('/icons/icon_fuel.svg')} alt="Fuel Icon" />
      ) : (
        <FontAwesomeIcon icon={FuelIcon} />
      ),
    [useCustomIcon],
  );

  const text = useMemo(() => `${value} L`, [value]);

  const tooltipMessage = useMemo(
    () => (withTooltip ? 'Combustible estimado (L)' : undefined),
    [withTooltip],
  );

  return (
    <DescriptionWithIconBox
      icon={Icon}
      description={text}
      size={fontSize}
      iconTooltip={tooltipMessage}
      fontWeight={fontWeight}
    />
  );
};

export default memo(FuelConsumendInfo);

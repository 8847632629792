import { styled, Typography } from '@mui/material';

export const DialogAssignDriverFirstRouteStepTypography = styled(Typography)(
  ({ theme }) => ({
    ...theme.typography.caption,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 22,
    width: 22,
    borderRadius: '100%',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  }),
);

export const DialogAssignDriverFirstRouteBannerImg = styled('img')(
  ({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(3),
    width: 113,
  }),
);

import { styled } from '@mui/material';
import { WarningRounded as WarningIcon } from '@mui/icons-material';

import { FONT_SIZES } from '../utils';

export const SubscriptionAvailableRoutesStateIconRoot = styled(WarningIcon)(
  ({ fontSize }) => ({
    fontSize: FONT_SIZES[fontSize ?? 'small'],
  }),
);

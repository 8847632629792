import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FIELDS_NAME, initialValues, validationSchema } from './utils';
import { FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useEnumerationData, useLazyRequest, useToggle } from '~app/hooks';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { FileUpload, PasswordVisibilityAdornment } from '~components/index';
import { getHelperText, hasError } from '~utils/formHelpers';
import {
  ConfigPartnerCompanyLogo,
  ConfigPartnetCompanyNewSubmitButton,
} from './styles';
import { getDefaultAvatar, parsePhoneInPlainText } from '~utils/user';
import { ENUMS_ENPOINTS } from '~services/enumerations';
import { industryTypes } from '~constants/industryTypes';
import { createAndAssociateCompany } from '~services/partnerCompany';
import { PATHS } from '~constants/paths';
import { STATUS_CODES } from '~constants/statusCodes';
import { DataResultsResponse } from '~globals/types';
import { uploadImages } from '~services/files';
import { FileResponse } from '~services/files/types';
import { PartnerCompanyRegistrationBody } from '~services/partnerCompany/types';

const ConfigPartnerCompanyNewForm: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [countries] = useEnumerationData({
    resource: ENUMS_ENPOINTS.getCountries,
    extractorKeyValue: 'countryId',
  });

  const [showPassword, togglePassword] = useToggle();
  const [showConfirmPassword, toggleConfirmPassword] = useToggle();

  const [, loadingCreatePartnerCompany, , executeCreatePartnerCompany] =
    useLazyRequest({
      request: createAndAssociateCompany,
      withPostSuccess: (response) => {
        console.log(response);
        const message = `La empresa se ha creado y asociado correctamente`;

        enqueueSnackbar(message, { variant: 'success' });

        navigate(PATHS.CONFIG.PARTNERS_COMPANIES.BASE);
      },
      withPostFailure: (error) => {
        let errorMessage = 'Ha ocurrido un error, intente nuevamente';

        if (error.data?.data.errorCode === STATUS_CODES.userExist) {
          errorMessage =
            // eslint-disable-next-line max-len
            'El email que ingresado ya se encuentra registrado. Por favor, modifíquelo e intenté nuevamente.';
        }

        enqueueSnackbar(errorMessage, { variant: 'error' });
      },
    });

  const onSubmit = useCallback(
    async (
      values: typeof initialValues,
      { setSubmitting }: FormikHelpers<typeof initialValues>,
    ) => {
      try {
        const logoValue = values[FIELDS_NAME.LOGO];
        let logoFileId = '';

        if (logoValue.length > 0) {
          const logoUploadFile = await uploadImages(logoValue);
          const {
            data: { results },
          } = logoUploadFile.data as DataResultsResponse<FileResponse[]>;
          logoFileId = results[0].fileId;
        }

        const body: PartnerCompanyRegistrationBody = {
          userData: {
            email: values[FIELDS_NAME.EMAIL],
            firstName: values[FIELDS_NAME.FIRST_NAME],
            lastName: values[FIELDS_NAME.LAST_NAME],
            password: values[FIELDS_NAME.PASSWORD],
            contactPhone: parsePhoneInPlainText(
              values[FIELDS_NAME.CONTACT_PHONE],
            ),
          },
          companyData: {
            logoFileId,
            name: values[FIELDS_NAME.COMPANY_NAME],
            cuit: values[FIELDS_NAME.COMPANY_DOCUMENT],
            countryId: Number(values[FIELDS_NAME.COUNTRY]),
            industryTypeId: Number(values[FIELDS_NAME.INDUSTRY]),
            fleetSizeTypeId: 0,
          },
        };

        await executeCreatePartnerCompany(body);
      } catch (error) {
        console.error('Failed to create and associate company:', error);
        enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
          variant: 'error',
        });
      } finally {
        setSubmitting(false);
      }
    },
    [enqueueSnackbar, executeCreatePartnerCompany],
  );

  const formikBag = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { errors, touched, getFieldProps, handleSubmit, values, isSubmitting } =
    formikBag;

  return (
    <FormikProvider value={formikBag}>
      <Stack spacing={2} component="form" onSubmit={handleSubmit}>
        <Stack spacing={2} direction="row">
          <Stack spacing={2} flex={1}>
            <Typography component="h6" fontWeight="bold">
              Información de la empresa asociada
            </Typography>

            <Stack spacing={2} direction="row" alignItems="center">
              <FileUpload
                name={FIELDS_NAME.LOGO}
                label="Subir logo"
                enabledChipsInfo={false}
              />

              <ConfigPartnerCompanyLogo
                src={getDefaultAvatar(
                  values.logo.length > 0
                    ? URL.createObjectURL(values.logo[0].file)
                    : undefined,
                )}
              />
            </Stack>

            <TextField
              fullWidth
              autoComplete="off"
              label="Nombre"
              error={hasError(touched, errors, FIELDS_NAME.FIRST_NAME)}
              helperText={getHelperText(
                touched,
                errors,
                FIELDS_NAME.FIRST_NAME,
              )}
              {...getFieldProps(FIELDS_NAME.FIRST_NAME)}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Apellido"
              error={hasError(touched, errors, FIELDS_NAME.LAST_NAME)}
              helperText={getHelperText(touched, errors, FIELDS_NAME.LAST_NAME)}
              {...getFieldProps(FIELDS_NAME.LAST_NAME)}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Celular / Whatsapp"
              placeholder="Cod de área sin 0 + Nro sin 15"
              error={hasError(touched, errors, FIELDS_NAME.CONTACT_PHONE)}
              helperText={getHelperText(
                touched,
                errors,
                FIELDS_NAME.CONTACT_PHONE,
                'Cod de área sin 0 + Nro sin 15',
              )}
              {...getFieldProps(FIELDS_NAME.CONTACT_PHONE)}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Email"
              error={hasError(touched, errors, FIELDS_NAME.EMAIL)}
              helperText={getHelperText(touched, errors, FIELDS_NAME.EMAIL)}
              {...getFieldProps(FIELDS_NAME.EMAIL)}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Contraseña"
              error={hasError(touched, errors, FIELDS_NAME.PASSWORD)}
              helperText={getHelperText(touched, errors, FIELDS_NAME.PASSWORD)}
              {...getFieldProps(FIELDS_NAME.PASSWORD)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityAdornment
                    showPassword={showPassword}
                    onClick={() => togglePassword()}
                    position="end"
                  />
                ),
              }}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Confirmar Contraseña"
              error={hasError(touched, errors, FIELDS_NAME.CONFIRM_PASSWORD)}
              helperText={getHelperText(
                touched,
                errors,
                FIELDS_NAME.CONFIRM_PASSWORD,
              )}
              {...getFieldProps(FIELDS_NAME.CONFIRM_PASSWORD)}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityAdornment
                    showPassword={showConfirmPassword}
                    onClick={() => toggleConfirmPassword()}
                    position="end"
                  />
                ),
              }}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Nombre de empresa"
              error={hasError(touched, errors, FIELDS_NAME.COMPANY_NAME)}
              helperText={getHelperText(
                touched,
                errors,
                FIELDS_NAME.COMPANY_NAME,
              )}
              {...getFieldProps(FIELDS_NAME.COMPANY_NAME)}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="Identificador fiscal"
              error={hasError(touched, errors, FIELDS_NAME.COMPANY_DOCUMENT)}
              helperText={getHelperText(
                touched,
                errors,
                FIELDS_NAME.COMPANY_DOCUMENT,
              )}
              {...getFieldProps(FIELDS_NAME.COMPANY_DOCUMENT)}
            />

            <TextField
              fullWidth
              autoComplete="off"
              label="País"
              error={hasError(touched, errors, FIELDS_NAME.COUNTRY)}
              helperText={getHelperText(touched, errors, FIELDS_NAME.COUNTRY)}
              {...getFieldProps(FIELDS_NAME.COUNTRY)}
              select
            >
              {countries.map((country) => (
                <MenuItem key={country.value} value={String(country.value)}>
                  {country.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              autoComplete="off"
              label="Industria"
              error={hasError(touched, errors, FIELDS_NAME.INDUSTRY)}
              helperText={getHelperText(touched, errors, FIELDS_NAME.INDUSTRY)}
              {...getFieldProps(FIELDS_NAME.INDUSTRY)}
              select
            >
              {industryTypes.map((type) => (
                <MenuItem key={type.value} value={String(type.value)}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>

          <Stack flex={1} />
        </Stack>

        <Stack spacing={2} direction="row">
          <ConfigPartnetCompanyNewSubmitButton
            type="submit"
            variant="contained"
            color="primary"
            loading={loadingCreatePartnerCompany || isSubmitting}
          >
            Crear empresa
          </ConfigPartnetCompanyNewSubmitButton>
        </Stack>
      </Stack>
    </FormikProvider>
  );
};

export default ConfigPartnerCompanyNewForm;

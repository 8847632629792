import { styled, Dialog, dialogClasses, IconButton } from '@mui/material';
import { TabPanel } from '@mui/lab';

export const DialogInvitationPartnerCompanyContainer = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    maxWidth: 600,
    width: '100%',
  },
});

export const DialogInvitationPartnerCompanyTitle = styled('div')(
  ({ theme }) => ({
    flex: 'none',
    padding: theme.spacing(2, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
);

export const DialogInvitationPartnerCompanyTitleCloseIcon = styled(IconButton)(
  ({ theme }) => ({
    color: theme.palette.grey[500],
  }),
);

export const DialogInvitationPartnerCompanyTabPanel = styled(TabPanel)({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  flex: 1,
});

import { ReactElement, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';

import { useGenerateRouteCompletedTabsContext } from '../../GenerateRouteCompletedTabsContext';
import PlanningRejectedOperationsErrors from '../../../PlanningRejectedOperationsErrors';
// eslint-disable-next-line max-len
import { dictionary as dictionaryGenerated } from '../../../StepperGenerateRoute/Steps/Generated/index';
import { dictionary as dictionaryError } from '../../../StepperGenerateRoute/Steps/Error/index';
import {
  getSetenceByDictionary,
  isSuccessWorkProgressId,
} from '../../../StepperGenerateRoute/utils';

const GenerateRouteCompletedTabsErrors = (): ReactElement => {
  const { workProgressId, errors, type } =
    useGenerateRouteCompletedTabsContext();

  const title = useMemo(
    () =>
      isSuccessWorkProgressId(workProgressId)
        ? getSetenceByDictionary(dictionaryGenerated, 'error', type)
        : getSetenceByDictionary(dictionaryError, 'description', type),
    [workProgressId, type],
  );

  return (
    <Stack spacing={1}>
      <Typography component="h3" variant="body2" fontWeight="bold">
        {title}
      </Typography>

      <PlanningRejectedOperationsErrors errors={errors} dense />
    </Stack>
  );
};

export default GenerateRouteCompletedTabsErrors;

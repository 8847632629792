import { styled, Dialog, dialogClasses, DialogActions } from '@mui/material';

export const SubscriptionEditPaymentModalRoot = styled(Dialog)({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    maxWidth: 800,
    height: '100%',
  },
});

export const SubscriptionEditPaymentModalActions = styled(DialogActions)({
  justifyContent: 'space-between',
});

import { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';

import { DialogImportOrdersFromIntegrationProps } from './types';
import DialogImportOrdersFromIntegrationProvider, {
  useDialogImportOrdersFromIntegrationContext,
} from './DialogImportOrdersFromIntegrationContext';
import DialogImportOrdersFromIntegrationContent from './DialogImportOrdersFromIntegrationContent';
import {
  DialogImportOrdersFromIntegrationContainer,
  DialogImportOrdersFromIntegrationLoadingContainer,
} from './styles';

const DialogImportOrdersFromIntegration = ({
  open,
  onClose,
}: Omit<
  DialogImportOrdersFromIntegrationProps,
  'onSubmitSuccess'
>): ReactElement => {
  const {
    currentIntegrations: { loading: loadingCurrentIntegrations },
  } = useDialogImportOrdersFromIntegrationContext();

  return (
    <DialogImportOrdersFromIntegrationContainer
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      {loadingCurrentIntegrations && (
        <DialogImportOrdersFromIntegrationLoadingContainer>
          <CircularProgress color="primary" disableShrink size={80} />
        </DialogImportOrdersFromIntegrationLoadingContainer>
      )}

      {!loadingCurrentIntegrations && (
        <DialogImportOrdersFromIntegrationContent onClose={onClose} />
      )}
    </DialogImportOrdersFromIntegrationContainer>
  );
};

const WrappedDialogImportOrdersFromIntegration = ({
  open,
  onClose,
  onSubmitSuccess,
}: DialogImportOrdersFromIntegrationProps): ReactElement | null => {
  if (!open) return null;

  return (
    <DialogImportOrdersFromIntegrationProvider
      onSubmitSuccess={onSubmitSuccess}
      onClose={onClose}
    >
      <DialogImportOrdersFromIntegration open={open} onClose={onClose} />
    </DialogImportOrdersFromIntegrationProvider>
  );
};

export default WrappedDialogImportOrdersFromIntegration;
